import { useEffect, useState } from "react";
import { Button, Row, Col, Card, Modal, Form, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import * as XLSX from "xlsx";
import 'moment/locale/pt-br';
import moment from 'moment';
import { ListarManutencoesPorMesRequest, InserirManutencoesPorMesRequest, 
    BaixarPadraoImportacaoManutencoesPorMesRequest, ExcluirManutencoesPorMesRequest } from "../../../store/modules/manutencoesPorMes/actions";
import { ListarMesesRequest } from "../../../store/modules/meses/actions";
import { ListarRegiaoRequest } from '../../../store/modules/regiao/actions';
import Header from "../../../components/Header/header";
import { RiTodoLine } from "react-icons/ri";
import Select from 'react-select';

export default function RelatorioManutencoesPorMes() {

    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaManutencoesPorMes = useSelector(state => state.manutencoesPorMes.listaManutencoesPorMes);
    const listaMeses = useSelector(state => state.meses.listaMeses);
    const listaRegiao = useSelector(state => state.regiao.listaRegiao);
    const usuarioCompleto = useSelector(state => state.usuario.usuario);

    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [showError, setShowError] = useState(false);
    const [ano, setAno] = useState([]);
    const [showMensagem, setShowMensagem] = useState(false);
    const [showExcluir, setShowExcluir] = useState(false);
    const [anoManutencoes, setAnoManutencoes] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const handleCloseMensagem = () => setShowMensagem(false);
    const handleShowMensagem = () => setShowMensagem(true);

    const handleCloseExcluir = () => setShowExcluir(false);
    const handleShowExcluir = () => setShowExcluir(true);

    const selectAnoManutencoes = [];
    const optionsAnoManutencoes = listaManutencoesPorMes ?
        [...listaManutencoesPorMes].reduce(function(opcoes, index){
            let key = index['ano'];
            if(!opcoes[key]){
                opcoes[key] = [];
            }
            if(opcoes[key].length === 0){
                opcoes[key].push({value: index.ano, label: index.ano});
                selectAnoManutencoes.push({value: index.ano, label: index.ano});
                return opcoes;
            }
            else{
                return opcoes;
            }
        }, {})
    :
    [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setItems(d);
        });

    };

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarManutencoesPorMesRequest());
        dispatch(ListarMesesRequest());
        dispatch(ListarRegiaoRequest());

    }, [])

    function Validar(e){

        if (items.length > 0 && ano.length > 0) {

            e.preventDefault();

            const propertyValues = Object.keys(items[0]);
    
            if(listaMeses){
                var mesesComparados = [];
                var listasComparadas = propertyValues.map(function(l, m){
                    if(l.trim() !== 'Descrição' && listaMeses.filter(e => e.descricao.trim().toLowerCase() == l.trim().toLowerCase()).length === 0){
                        mesesComparados.push(` ${l}`);
                    }
                })
    
                if(mesesComparados.length > 0){
    
                    handleShowMensagem();
                    setMessageError(`O mês: " ${mesesComparados.map(l => {return l ? l : null})} " está presente no Excel de importação, porém, ` +
                    `não faz parte do banco atual. Caso continue esta ação, este mês não será listado com o respectivo valor. Deseja continuar?`);
    
                }
                else{
                    ImportarArquivo(e);
                }
            }
        }
        else{
            handleShowError();
            setMessageError("Selecione o Excel e o Ano antes de Importar!");
            setShowError(true);
        }

    }

    function ImportarArquivo(e) {

        if (listaMeses && ano.length > 0) {

            let lista = [];
            var data = [];
            var idRegiao = null;

            try {

                if (items.length > 0) {

                    items.forEach(function(item){

                        const propertyValues = Object.keys(item);

                        propertyValues.map(function(l, m){
                            if(l.trim() === 'Descrição'){
                                var descricaoRegiao = item[l];
                                idRegiao = listaRegiao.filter(r => r.descricao == descricaoRegiao).length > 0 ?
                                    listaRegiao.filter(r => r.descricao == descricaoRegiao)[0].id : null;
                            }
                            else{
                                if(listaMeses.filter(m => m.descricao.trim().toLowerCase() == l.trim().toLowerCase()).length > 0 && idRegiao !== null){
                                    data.push({
                                        'Id': 0,
                                        'IdMes': listaMeses.filter(m => m.descricao.trim().toLowerCase() == l.trim().toLowerCase())[0].id,
                                        'Valor': item[l] ? item[l] : 0,
                                        'IdRegiao': idRegiao,
                                        'DataImportacao': moment((new Date())).add('hours', 2),
                                        'Ano': parseInt(moment(ano).format("YYYY")),
                                    })
                                }
                            }
                        })
                    });

                    if(idRegiao === null && data.length === 0){
                        handleShowError();
                        handleCloseMensagem();
                        setMessageError("Existem regiões no arquivo de importação que não fazem parte do banco atual, " + 
                            "para prosseguir insira as regiões que estão pendentes.");
                        setShowError(true);
                    }

                    lista.push(data);

                    if (lista.length > 0 && data.length > 0) {
                        dispatch(InserirManutencoesPorMesRequest(lista));
                        handleClose();
                        //handleCloseImport();
                    }
                    else {
                        toast.error('Não foi possível importar a lista');
                        return;
                    }

                } else {
                    handleShowError();
                    setMessageError("Selecione o Excel antes de Importar!");
                    setShowError(true);
                }

            } catch (error) {
                handleShowError();
                setMessageError("CAMPOS IMPORTANTES ESTÃO FALTANDO NO EXCEL, VERIFIQUE NOVAMENTE ANTES DE IMPORTAR");
                setShowError(true);
            }
        }
        else{
            handleShowError();
            setMessageError("Selecione o Excel e o Ano antes de Importar!");
            setShowError(true);
        }

        handleCloseMensagem();

    }

    function exportarPadraoImportacao(){
        dispatch(BaixarPadraoImportacaoManutencoesPorMesRequest())
    }

    
    function ExcluirManutencao(ano) {

        if(anoManutencoes && anoManutencoes !== 0){
            if(anoManutencoes.length === 0){
                toast.error('Selecione o ano!');
                return;
            }
            else{
                dispatch(ExcluirManutencoesPorMesRequest(ano));
                handleCloseExcluir();
            }
        }
        else{
            toast.error('Selecione o ano!');
            return;
        }

    }

    return (
        <>
           <Header/>
             <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className ='divHeader'>
                            <Row>
                                <Col md={{ span:10}}>
                                    <span>
                                    < RiTodoLine size={25} color={"#000"} /> Manutenções Por Mês
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className='divBody'>
                                <Row>
                                    <Col>
                                        {
                                            //carregando ?
                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title={
                                                        inserir == true ?
                                                            <Row>
                                                                <Col md='auto'>
                                                                    <Button variant="success" onClick={handleShow}>Importar</Button>
                                                                </Col>
                                                                <Col md='auto'>
                                                                    <Button variant="success" onClick={() => {exportarPadraoImportacao()}}>Exportar Padrão de Importação</Button>
                                                                </Col>
                                                                <Col md='auto'>
                                                                    <Button variant="danger" disabled={listaManutencoesPorMes && listaManutencoesPorMes.length > 0 ? false : true}
                                                                    onClick={() => {handleShowExcluir()}}>Excluir Manutenção</Button>
                                                                </Col>
                                                            </Row>
                                                        :
                                                        ''
                                                    }
                                                    columns={[
                                                        {
                                                            title: 'id', field: 'id', hidden: true,
                                                        },
                                                        {
                                                            title: 'Região', field: 'regiao', defaultGroupOrder: 1,
                                                        },
                                                        {
                                                            title: 'Mês', field: 'mes', 
                                                        },
                                                        {
                                                            title: 'Valor', field: 'valor',
                                                            render: rowData =>
                                                                rowData.totalMedicao === null ?
                                                                    null
                                                                    :
                                                                    rowData.valor.toLocaleString('pt-br', {
                                                                        style: 'currency', currency: 'BRL',
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    }),
                                                        },
                                                        {
                                                            title: 'Data de Importação', field: 'data',
                                                        },
                                                        {
                                                            title: 'Ano', field: 'ano', defaultGroupOrder: 0,
                                                        },
                                                    ]}
                                                    data={
                                                        listaManutencoesPorMes ?
                                                            listaManutencoesPorMes.map(i => {
                                                            return{
                                                                id: i.id,
                                                                mes: i.meses.descricao,
                                                                regiao: i.regiao.descricao,
                                                                ano: i.ano,
                                                                valor: i.valor,
                                                                data: moment(i.dataImportacao).format('L LT'),
                                                            }
                                                        })
                                                        : []
                                                    }
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: "first",
                                                        filtering: true,
                                                    }}
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                //:
                                                //<Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>   
            </div>

            <Modal
                show={showError}
                onHide={handleCloseError}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseError}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Importar Itens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formTipoDocumentoArquivo">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[]}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                readExcel(files[0])
                                            } else {
                                                setItems([])
                                            }
                                        }}
                                        filesLimit={1}
                                        acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 1 arquivo`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                    <Form.Group as={Col} md={6} controlId="formDataInicioContrato">
                                        <Form.Label>Selecione o ano</Form.Label>
                                        <Form.Control
                                            type="month"
                                            value={ano}
                                            onChange={(e)=> setAno(e.target.value)}
                                            required
                                            isInvalid={ano.length > 0 ? false : true}
                                            isValid={ano.length > 0 ? true : false} />
                                    </Form.Group>
                                </div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={(e) => {Validar(e)}}>Importar Documento</Button>
                            :
                            ''
                    }
                </Modal.Footer>
            </Modal>

            <Modal
                show={showMensagem}
                onHide={handleCloseMensagem}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {ImportarArquivo()}}>
                        Sim
                    </Button>
                    <Button variant="success" onClick={handleCloseMensagem}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showExcluir}
                onHide={handleCloseExcluir}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Qual ano você deseja excluir?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>Selecione o ano: </p>
                        <div>
                            <Select
                                placeholder="Selecione..."
                                isClearable={true}
                                isSearchable={true}
                                options={selectAnoManutencoes}
                                onChange={(e) => { e ? setAnoManutencoes(e.value) : setAnoManutencoes([]) }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {ExcluirManutencao(anoManutencoes)}}>
                        Excluir
                    </Button>
                    <Button variant="success" onClick={() => {handleCloseExcluir(); setAnoManutencoes(0)}}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
           
        </>
    )
}