import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ListarUnidadeMedidaRequest } from "../../../store/modules/unidadeMedida/actions";
import { InserirItensContratoVersaoRequest } from '../../../store/modules/contratoVersao/actions';
import { AlterarItemCustoRequest } from "../../../store/modules/itemCusto/actions";
import { AlterarGrupoCustoRequest } from "../../../store/modules/grupoCusto/actions";
import { AlterarSubitemCustoRequest } from "../../../store/modules/subitemCusto/actions";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { useParams } from 'react-router-dom';
import { ListarCompletoSolicitacaoFornecimentoRequest } from "../../../store/modules/solicitacaoFornecimento/actions";
import Select from 'react-select';
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import ModalSolicitacaoAjusteImportacao from "../../../components/ModalSolicitacaoAjusteImportacao/modalSolicitacaoAjusteImportacao";
import './visualizar.css';
import 'moment/locale/pt-br';

export default function Visualizar() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const listaUnidadeMedida = useSelector(state => state.unidadeMedida.listaUnidadeMedida);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const carregando = useSelector(state => state.contrato.carregando);
    const listaSolicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.listaSolicitacaoFornecimento);
    const [dados, setDados] = useState('');
    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState('');
    const [versaoSubItem, setVersaoSubItem] = useState('');
    const [oldVersaoSubItem, setOldVersaoSubItem] = useState('');
    const [show, setShow] = useState(false);
    const [showSolicitacao, setShowSolicitacao] = useState(false);
    const [unidMedida, setUnidMedida] = useState('');

    const [codigoInterno, setCodigoInterno] = useState(true);
    const [descricao, setDescricao] = useState(true);
    const [quantidadeSubitem, setQuantidadeSubitem] = useState(true);
    const [valorUnitarioSubitem, setValorUnitarioSubitem] = useState(true);
    const [quantidadeSubitemExc, setQuantidadeSubitemExc] = useState(true);
    const [quantidadeSubitemConsumido, setQuantidadeSubitemConsumido] = useState(true);
    const [saldoDeQuantidade, setSaldoDeQuantidade] = useState(true);
    const [saldoDeQuantidadeExc, setSaldoDeQuantidadeExc] = useState(true);
    const [quantidadeAF, setQuantidadeAF] = useState(true);
    const [valorTotalConsumido, setValorTotalConsumido] = useState(true);
    const [valorPrevisaoMensal, setValorPrevisaoMensal] = useState(true);
    const [ativo, setAtivo] = useState(true);
    const [unidadeDeMedida, setUnidadeDeMedida] = useState(true);
    const [camposEditaveis, setCamposEditaveis] = useState(false);
    const [tipo, setTipo] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSolicitacaoClose = () => setShowSolicitacao(false);
    const handleSolicitacaoShow = () => setShowSolicitacao(true);

    const options = listaUnidadeMedida ?
        listaUnidadeMedida.map(unidadeMedida => ({
            value: unidadeMedida.id, label: unidadeMedida.descricao
        }))
        :
        [];

    useEffect(() => {

        moment.locale('pt-br');

    }, [])

    useEffect(() => {

        dispatch(ListarUnidadeMedidaRequest());

        if (contratoCompleto != null) {
            constroiHierarquia();
        }

    }, [contratoCompleto])

    useEffect(() => {

        dispatch(ListarCompletoSolicitacaoFornecimentoRequest({
            IdSolicitacaoFornecimento: null,
            IdContrato: id,
            Servico: true
        }));

    }, [id])

    function constroiHierarquia() {

        let hierarquiaCompleta = [];

        contratoCompleto.gruposCusto.map((grupo) => {
            hierarquiaCompleta.push({
                id: grupo.id,
                descricaoCodigoInterno: grupo.descricaoCodigoInterno,
                descricao: grupo.descricao,
                idContratoVersao: grupo.idContratoVersao,
                quantidade: null,
                valorUnitario: null,
                valorPrevisaoMensal: null,
                marcadorSub: null,
                valorConsumido: null,
                ativo: null,
                unidadeMedida: null,
                marcadorItem: null,
                saldoProjetado: null,
                saldo: null,
                quantidadeConsumida: null,
                quantidadeExcedente: null,
                saldoExcedente: null
            });
            grupo.itens.map((item) => {
                hierarquiaCompleta.push({
                    id: item.id,
                    descricaoCodigoInterno: item.descricaoCodigoInterno,
                    descricao: item.descricao,
                    parentId: item.idGrupoCusto,
                    idContratoVersao: null,
                    quantidade: null,
                    valorUnitario: null,
                    valorPrevisaoMensal: null,
                    valorConsumido: null,
                    marcadorSub: null,
                    ativo: null,
                    unidadeMedida: null,
                    marcadorItem: true,
                    saldoProjetado: null,
                    saldo: null,
                    quantidadeConsumida: null,
                    quantidadeExcedente: null,
                    saldoExcedente: null
                });
                item.subItens.map((subItem) => {
                    hierarquiaCompleta.push({
                        id: subItem.id,
                        descricaoCodigoInterno: subItem.descricaoCodigoInterno,
                        descricao: subItem.descricao,
                        parentId: subItem.idItemCusto,
                        quantidade: parseFloat(subItem.quantidade),
                        valorUnitario: parseFloat(subItem.valorUnitario),
                        valorPrevisaoMensal: parseFloat(subItem.valorPrevisaoMensal),
                        marcadorSub: true,
                        ativo: subItem.ativo,
                        valorConsumido: parseFloat(subItem.valorUnitario * subItem.quantidadeConsumida),
                        idUnidadeMedida: subItem.idUnidadeMedida,
                        idItemCusto: subItem.idItemCusto,
                        idFonteReceita: subItem.idFonteReceita,
                        idComposicaoCusto: subItem.idComposicaoCusto,
                        saldoProjetado: subItem.saldoProjetado,
                        marcadorItem: null,
                        unidadeMedida: subItem.idUnidadeMedida,
                        saldo: subItem.saldo,
                        quantidadeConsumida: subItem.quantidadeConsumida,
                        idContratoVersao: null,
                        quantidadeExcedente: parseFloat(subItem.quantidade) + parseFloat(subItem.quantidade) * parseFloat(subItem.valorIndiceQuantidadeItemExcedente),
                        saldoExcedente: subItem.saldoExcedente
                    });
                })
            })
        })

        setListaHierarquiaCompleta(hierarquiaCompleta);
    }

    function EditarSubItem(contratoVersaoSubItem, oldData, unidadeMedidaSelect) {

        // Alterar Grupo Custo e Item Custo
        if (contratoVersaoSubItem.marcadorSub === null) {

            // Grupo Custo
            if (contratoVersaoSubItem.marcadorItem === null) {

                dispatch(AlterarGrupoCustoRequest({
                    'Id': contratoVersaoSubItem.id,
                    'Descricao': contratoVersaoSubItem.descricao.trim(),
                    'DescricaoCodigoInterno': contratoVersaoSubItem.descricaoCodigoInterno.trim(),
                    'IdContratoVersao': contratoVersaoSubItem.idContratoVersao
                }, contratoCompleto.id));


            } else {

                // Item Custo
                dispatch(AlterarItemCustoRequest({
                    'Id': contratoVersaoSubItem.id,
                    'Descricao': contratoVersaoSubItem.descricao.trim(),
                    'DescricaoCodigoInterno': contratoVersaoSubItem.descricaoCodigoInterno.trim(),
                    'IdGrupoCusto': contratoVersaoSubItem.parentId
                }, contratoCompleto.id));
            }

            // Alterar Subitem Custo
        } else {

            let select = listaUnidadeMedida.filter(soli => soli.descricao == unidadeMedidaSelect);
            let idUnidadeMedida = versaoSubItem.unidadeMedida;
            if (select.length > 0) {
                idUnidadeMedida = parseInt(select[0].id);
            }

            if (contratoVersaoSubItem.quantidade != undefined &&
                contratoVersaoSubItem.quantidade == oldData.quantidade) {

                dispatch(AlterarSubitemCustoRequest({
                    'Id': parseInt(contratoVersaoSubItem.id),
                    'IdItemCusto': parseInt(contratoVersaoSubItem.idItemCusto),
                    'Descricao': contratoVersaoSubItem.descricao.trim(),
                    'DescricaoCodigoInterno': contratoVersaoSubItem.descricaoCodigoInterno.trim(),
                    'Ativo': contratoVersaoSubItem.ativo,
                    'IdUnidadeMedida': idUnidadeMedida,
                    'ValorUnitario': parseFloat(contratoVersaoSubItem.valorUnitario),
                    'ValorPrevisaoMensal': parseFloat(contratoVersaoSubItem.valorPrevisaoMensal),
                    'Quantidade': contratoVersaoSubItem.quantidade,
                    'IdFonteReceita': parseInt(contratoVersaoSubItem.idFonteReceita),
                    'IdComposicaoCusto': parseInt(contratoVersaoSubItem.idComposicaoCusto)
                }, contratoCompleto.id));
            }

            // Alterar Subitem Custo criando nova versão (Altera quantidade e valor unitário)
            else if (versaoSubItem.quantidade != oldVersaoSubItem.quantidade ||
                versaoSubItem.valorUnitario != oldVersaoSubItem.valorUnitario) {

                let selectUnidMedida = listaUnidadeMedida.filter(soli => soli.descricao == unidMedida);
                let idUnidMedida = versaoSubItem.unidadeMedida;
                if (selectUnidMedida.length > 0) {
                    idUnidMedida = parseInt(selectUnidMedida[0].id);
                }

                setDados({
                    'Id': 0,
                    'IdContrato': contratoCompleto.id,
                    'IdTipoContratoVersao': contratoCompleto.contratoVersaoApiModel.idTipoContratoVersao,
                    'DataVersao': moment((new Date())).add('hours', 2),
                    'DataInicioContrato': contratoCompleto.contratoVersaoApiModel.dataInicioContrato,
                    'DataFimContrato': contratoCompleto.contratoVersaoApiModel.dataFimContrato,
                    'ValorIndiceReajuste': parseFloat(contratoCompleto.contratoVersaoApiModel.valorIndiceReajuste),
                    'ValorIndiceMulta': parseFloat(contratoCompleto.contratoVersaoApiModel.valorIndiceMulta),
                    'GruposCusto': contratoCompleto.gruposCusto,
                    'AdicionarSubItemCusto': {
                        'Id': versaoSubItem.id,
                        'IdItemCusto': versaoSubItem.idItemCusto,
                        'Descricao': versaoSubItem.descricao.trim(),
                        'DescricaoCodigoInterno': versaoSubItem.descricaoCodigoInterno.trim(),
                        'Ativo': versaoSubItem.ativo,
                        'IdUnidadeMedida': idUnidMedida,
                        'ValorUnitario': parseFloat(versaoSubItem.valorUnitario),
                        'ValorPrevisaoMensal': parseFloat(versaoSubItem.valorPrevisaoMensal),
                        'Quantidade': versaoSubItem.quantidade,
                        'IdFonteReceita': versaoSubItem.idFonteReceita,
                        'IdComposicaoCusto': versaoSubItem.idComposicaoCusto
                    },
                    'AlterarSub': true,
                    'IdUsuario': usuario.id,
                    'ValorIndiceQuantidadeItemExcedente': parseFloat(contratoCompleto.contratoVersaoApiModel.valorIndiceQuantidadeItemExcedente),
                    'ListaIdSolicitacaoFornecimento': null,
                    'ListaIdSolicitacaoFornecimentoCancelar': null
                });

                setTipo("Ajustes");
                handleSolicitacaoShow();

                if ((listaSolicitacaoFornecimento == undefined) || (listaSolicitacaoFornecimento != undefined &&
                    listaSolicitacaoFornecimento.filter(s => s.statusAtual != "Solicitação Finalizada" && s.statusAtual != "").length == 0)) {

                    dispatch(InserirItensContratoVersaoRequest(dados, id));

                }

            }
        }

        if (contratoCompleto != null) {
            constroiHierarquia();
        }

        handleClose();
    }

    function AbrirModalNovaVersao(newSubitem, oldSubitem, unidadeMedidaSelect) {

        setVersaoSubItem(newSubitem);
        setOldVersaoSubItem(oldSubitem);
        setUnidMedida(unidadeMedidaSelect);

        // Abre o modal caso tenha alterado quantidade ou valor unitário no subitem
        if (newSubitem.quantidade != oldSubitem.quantidade ||
            newSubitem.valorUnitario != oldSubitem.valorUnitario) {

            handleShow();

        } else {

            EditarSubItem(newSubitem, oldSubitem, unidadeMedidaSelect);
        }
    }

    return (
        <>
            <div className="divBody">
                <Row>
                    <Col md={12}>
                        <Row className="mb-3">
                            {
                                carregando ?

                                    contratoCompleto != undefined && contratoCompleto != null && contratoCompleto.gruposCusto.length > 0 ?
                                        <Form.Group as={Col} md={12} controlId="formContratoCompleto" >
                                            {
                                                <div>
                                                    <Row style={{ marginLeft: "1em", alignItems: 'center' }}>
                                                        <Col md='auto'>
                                                            <DropdownButton variant="success" title="Colunas">
                                                                <Form>
                                                                    <div className="tamanhoTela">
                                                                        <Row>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={codigoInterno} onChange={() => setCodigoInterno(!codigoInterno)} />
                                                                                    <Form.Check.Label> Número Interno</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={descricao} onChange={() => setDescricao(!descricao)} />
                                                                                    <Form.Check.Label> Descricao</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={quantidadeSubitem} onChange={() => setQuantidadeSubitem(!quantidadeSubitem)} />
                                                                                    <Form.Check.Label> Quantidade Subitem</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={quantidadeSubitemExc} onChange={() => setQuantidadeSubitemExc(!quantidadeSubitemExc)} />
                                                                                    <Form.Check.Label> Quantidade Subitem Excedente</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                        </Row>

                                                                        <Row>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={quantidadeSubitemConsumido} onChange={() => setQuantidadeSubitemConsumido(!quantidadeSubitemConsumido)} />
                                                                                    <Form.Check.Label> Quantidade Subitem Consumido</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={saldoDeQuantidade} onChange={() => setSaldoDeQuantidade(!saldoDeQuantidade)} />
                                                                                    <Form.Check.Label> Saldo De Quantidade</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={saldoDeQuantidadeExc} onChange={() => setSaldoDeQuantidadeExc(!saldoDeQuantidadeExc)} />
                                                                                    <Form.Check.Label> Saldo De Quantidade Excedente</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={quantidadeAF} onChange={() => setQuantidadeAF(!quantidadeAF)} />
                                                                                    <Form.Check.Label> Quantidade AF</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                        </Row>

                                                                        <Row>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={valorUnitarioSubitem} onChange={() => setValorUnitarioSubitem(!valorUnitarioSubitem)} />
                                                                                    <Form.Check.Label> Valor Unitário Subitem</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={valorTotalConsumido} onChange={() => setValorTotalConsumido(!valorTotalConsumido)} />
                                                                                    <Form.Check.Label> Valor Total Consumido</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={valorPrevisaoMensal} onChange={() => setValorPrevisaoMensal(!valorPrevisaoMensal)} />
                                                                                    <Form.Check.Label> Valor Previsao Mensal</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={ativo} onChange={() => setAtivo(!ativo)} />
                                                                                    <Form.Check.Label> Ativo</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                        </Row>

                                                                        <Row>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={unidadeDeMedida} onChange={() => setUnidadeDeMedida(!unidadeDeMedida)} />
                                                                                    <Form.Check.Label> Unidade De Medida</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={3}>
                                                                                <Form.Check>
                                                                                    <Form.Check.Input aria-label="option 1" checked={camposEditaveis} onChange={() => setCamposEditaveis(!camposEditaveis)} />
                                                                                    <Form.Check.Label> Campos Editáveis</Form.Check.Label>
                                                                                </Form.Check>
                                                                            </Form.Group>
                                                                        </Row>
                                                                    </div>
                                                                </Form>
                                                            </DropdownButton>
                                                        </Col>
                                                    </Row><br />
                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Código Interno', field: 'descricaoCodigoInterno', cellStyle: {
                                                                    width: '5%'
                                                                },
                                                                hidden: codigoInterno ? false : true,
                                                            },
                                                            {
                                                                title: 'Descrição', field: 'descricao',
                                                                hidden: descricao ? false : true,
                                                            },
                                                            {
                                                                title: 'Quantidade Subitem', field: 'quantidade',
                                                                hidden: quantidadeSubitem ? false : true,
                                                                type: "numeric",
                                                                searchable: false,
                                                                filtering: false,
                                                                align: 'left',
                                                                editable: (coluna, linha) => (linha.quantidade != undefined && linha.quantidade != null)
                                                            },
                                                            {
                                                                title: 'Quantidade Subitem com Excedente', field: 'quantidadeExcedente',
                                                                hidden: camposEditaveis ? true : quantidadeSubitemExc ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: 'never'
                                                            },
                                                            {
                                                                title: 'Quantidade Subitem Consumida', field: 'quantidadeConsumida',
                                                                hidden: camposEditaveis ? true : quantidadeSubitemConsumido ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: 'never'
                                                            },
                                                            {
                                                                title: 'Saldo de Quantidade', field: 'saldo',
                                                                hidden: camposEditaveis ? true : saldoDeQuantidade ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: 'never'
                                                            },
                                                            {
                                                                title: 'Saldo de Quantidade (com o valor índice excedente)', field: 'saldoExcedente',
                                                                hidden: camposEditaveis ? true : saldoDeQuantidadeExc ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: 'never'
                                                            },
                                                            {
                                                                title: 'Quantidade em AF', field: 'saldoProjetado',
                                                                hidden: camposEditaveis ? true : quantidadeAF ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: 'never'
                                                            },
                                                            {
                                                                title: 'Valor Unitário Subitem', field: 'valorUnitario',
                                                                hidden: valorUnitarioSubitem ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: (coluna, linha) => (linha.valorUnitario != undefined && linha.valorUnitario != null),
                                                                render: rowData =>
                                                                    rowData.valorUnitario === null ?
                                                                        null
                                                                        :
                                                                        rowData.valorUnitario.toLocaleString('pt-br', {
                                                                            style: 'currency', currency: 'BRL',
                                                                            minimumFractionDigits: 4,
                                                                            maximumFractionDigits: 4
                                                                        })
                                                            },
                                                            {
                                                                title: 'Valor Total Consumido', field: 'valorConsumido',
                                                                hidden: camposEditaveis ? true : valorTotalConsumido ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: 'never',
                                                                render: rowData =>
                                                                    rowData.valorConsumido === null ?
                                                                        null
                                                                        :
                                                                        rowData.valorConsumido.toLocaleString('pt-br', {
                                                                            style: 'currency', currency: 'BRL',
                                                                            minimumFractionDigits: 4,
                                                                            maximumFractionDigits: 4
                                                                        })
                                                            },
                                                            {
                                                                title: 'Valor Previsão Mensal', field: 'valorPrevisaoMensal',
                                                                hidden: valorPrevisaoMensal ? false : true,
                                                                searchable: false,
                                                                filtering: false,
                                                                editable: (coluna, linha) => (linha.valorPrevisaoMensal != undefined && linha.valorPrevisaoMensal != null),
                                                                render: rowData =>
                                                                    rowData.valorPrevisaoMensal === null ?
                                                                        null
                                                                        :
                                                                        rowData.valorPrevisaoMensal.toLocaleString('pt-br', {
                                                                            style: 'currency', currency: 'BRL',
                                                                            minimumFractionDigits: 4,
                                                                            maximumFractionDigits: 4
                                                                        })

                                                            },
                                                            {
                                                                title: 'Ativo', field: 'ativo',
                                                                hidden: ativo ? false : true,
                                                                editable: (coluna, linha) => (linha.ativo != undefined && linha.ativo != null),
                                                                searchable: false,
                                                                filtering: false,
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData =>
                                                                    rowData.ativo === null ?
                                                                        null
                                                                        :
                                                                        (<input type="checkbox" checked={rowData.ativo} />)

                                                            },
                                                            {
                                                                title: 'Unidade de Medida', field: 'unidadeMedida',
                                                                hidden: unidadeDeMedida ? false : true,
                                                                filtering: false,
                                                                searchable: false,
                                                                editable: (coluna, linha) => (linha.ativo != undefined && linha.ativo != null),
                                                                lookup:
                                                                    listaUnidadeMedida != undefined && listaUnidadeMedida.length > 0 ?

                                                                        listaUnidadeMedida.map(unidadeMedida => {
                                                                            return {
                                                                                id: unidadeMedida.id, descricao: unidadeMedida.descricao
                                                                            }
                                                                        }).reduce(function (acc, cur, i) {
                                                                            acc[cur.id] = cur.descricao;
                                                                            return acc;
                                                                        }, {})
                                                                        :
                                                                        null,
                                                                editComponent: (props) => (
                                                                    <Select id="idSelectUnidadeMedida"
                                                                        options={options}
                                                                        placeholder="Selecione..."
                                                                        defaultValue={{
                                                                            value: props.value, label: listaUnidadeMedida.filter(unidadeMedida => unidadeMedida.id == props.value).length > 0
                                                                                ? listaUnidadeMedida.filter(unidadeMedida => unidadeMedida.id == props.value)[0].descricao
                                                                                : 'Selecione...'
                                                                        }}
                                                                    />
                                                                ),
                                                            },
                                                        ]}
                                                        data={listaHierarquiaCompleta}
                                                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                                        editable={{
                                                            onRowUpdate: (newData, oldData) =>
                                                                new Promise((resolve, reject) => {
                                                                    setTimeout(() => {
                                                                        if (newData.marcadorSub == null) {
                                                                            EditarSubItem(newData);
                                                                            resolve();
                                                                        } else {
                                                                            let unidadeMedidaSelect = document.getElementById('idSelectUnidadeMedida').innerText;
                                                                            AbrirModalNovaVersao(newData, oldData, unidadeMedidaSelect);
                                                                            resolve();
                                                                        }
                                                                    }, 1000)
                                                                })
                                                        }}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: rowData.marcadorSub ?
                                                                    '#dcdcdc'
                                                                    : rowData.marcadorItem ?
                                                                        '#BCB8B8'
                                                                        :
                                                                        '#9D9999'
                                                            })
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: 'Pesquisar',
                                                                searchPlaceholder: 'Pesquisar'
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            },
                                                            toolbar: {
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </Form.Group>
                                        :
                                        <div className="divBody">
                                            Não existem itens cadastrados.
                                        </div>
                                    :
                                    <Loading />
                            }
                        </Row>
                    </Col>
                </Row>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        Você alterou a quantidade ou valor unitário do subitem, será criada uma nova versão de contrado. Você tem certeza que deseja continuar?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={5}>
                            <Button variant="success" onClick={EditarSubItem}>Sim</Button>
                        </Col>
                        <Col md={5}>
                            <Button variant="danger" onClick={handleClose}>Não</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <ModalSolicitacaoAjusteImportacao
                idContrato={id}
                show={showSolicitacao}
                onHide={handleSolicitacaoClose}
                dados={dados}
                tipo={tipo}
            />
        </>
    );
}