export function ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(idAutorizacaoSubitem, idAutorizacaoSubitemMedicao) {
    return {
      type: 'LISTAR_COMPLETO_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST',
      idAutorizacaoSubitem, idAutorizacaoSubitemMedicao
    }
  }
  
  export function ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest() {
    return {
      type: 'LISTAR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST'
    }
  }

  export function InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest(data, id) {
    return {
      type: 'INSERIR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_AUTORIZACAO_REQUEST',
      data, id
    }
  }

  export function InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemMedicaoRequest(data, id) {
    return {
      type: 'INSERIR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REQUEST',
      data, id
    }
  }
  
  export function ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse(listaRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem) {
    return {
      type: 'LISTAR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_RESPONSE',
      listaRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem
    }
  }

  export function ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse(listaCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem) {
    return {
      type: 'LISTAR_COMPLETO_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_RESPONSE',
      listaCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem
    }
  }
  
  export function BaixarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(id) {
    return {
      type: 'BAIXAR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST',
      id
    }
  }
  
  export function CarregarImagemRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(id) {
    return {
      type: 'CARREGAR_IMAGEM_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }