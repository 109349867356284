export function ListarContratoVersaoSubitemCustoPrimarioRequest() {
    return {
      type: 'LISTAR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST'
    }
  }
  
  export function ListarContratoVersaoSubitemCustoPrimarioResponse(listaContratoVersaoSubitemCustoPrimario) {
    return {
      type: 'LISTAR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_RESPONSE',
      listaContratoVersaoSubitemCustoPrimario
    }
  }
  
  export function InserirContratoVersaoSubitemCustoPrimarioRequest(data) {
    return {
      type: 'INSERIR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST',
      data
    }
  }
  
  export function AlterarContratoVersaoSubitemCustoPrimarioRequest(data) {
    return {
      type: 'ALTERAR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST',
      data
    }
  }
  
  export function ExcluirContratoVersaoSubitemCustoPrimarioRequest(id) {
    return {
      type: 'EXCLUIR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }