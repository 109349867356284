export function ListarTipoDocumentoArquivoRequest() {
  return {
    type: 'LISTAR_TIPO_DOCUMENTO_ARQUIVO_REQUEST'
  }
}

export function ListarTipoDocumentoArquivoResponse(listaTipoDocumentoArquivo) {
  return {
    type: 'LISTAR_TIPO_DOCUMENTO_ARQUIVO_RESPONSE',
    listaTipoDocumentoArquivo
  }
}

export function InserirTipoDocumentoArquivoRequest(data) {
  return {
    type: 'INSERIR_TIPO_DOCUMENTO_ARQUIVO_REQUEST',
    data
  }
}

export function AlterarTipoDocumentoArquivoRequest(data) {
  return {
    type: 'ALTERAR_TIPO_DOCUMENTO_ARQUIVO_REQUEST',
    data
  }
}

export function ExcluirTipoDocumentoArquivoRequest(id) {
  return {
    type: 'EXCLUIR_TIPO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}

