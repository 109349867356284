import produce from 'immer';

const initialState = {
  listaObrasIntervencoesPorMes: undefined,
  carregando: true
};

export default function obrasIntervencoesPorMes(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_OBRAS_INTERVENCOES_POR_MES_RESPONSE':
      return produce(state, draft => {
        draft.listaObrasIntervencoesPorMes = action.listaObrasIntervencoesPorMes;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}