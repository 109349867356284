export function ListarMedicaoAutorizacaoFornecimentoOcorrenciaRequest(id) {
    return {
      type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST',
      id
    }
  }
  
  export function ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse(listaMedicaoAutorizacaoFornecimentoOcorrencia) {
    return {
      type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_RESPONSE',
      listaMedicaoAutorizacaoFornecimentoOcorrencia
    }
  }

  export function InserirMedicaoAutorizacaoFornecimentoOcorrenciaRequest(data) {
    return {
      type: 'INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST',
      data
    }
  }
  
  export function AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRequest(data, id) {
    return {
      type: 'ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST',
      data,
      id
    }
  }
  
  export function ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRequest(data) {
    return {
      type: 'EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST',
      data
    }
  }

  export function BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdRequest(id) {
    return {
      type: 'BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_POR_ID_REQUEST',
      id
    }
  }

  export function BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdResponse(medicaoAutorizacaoFornecimentoOcorrenciaCompleto) {
    return {
      type: 'BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_POR_ID_RESPONSE',
      medicaoAutorizacaoFornecimentoOcorrenciaCompleto
    }
  }
  
export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}