export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(id) {
    return {
      type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST',
      id
    }
  }
  
  export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse(listaAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto) {
    return {
      type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_RESPONSE',
      listaAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto
    }
  }

  export function InserirAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(data, id) {
    return {
      type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST',
      data,
      id
    }
  }
  
  export function AlterarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(data, id) {
    return {
      type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST',
      data,
      id
    }
  }
  
  export function ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(idFoto, data) {
    return {
      type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST',
      idFoto,
      data
    }
  }


  export function CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(id) {
    return {
      type: 'CARREGAR_IMAGEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST',
      id
    }
  }
  
  export function CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse(url) {
    return {
      type: 'CARREGAR_IMAGEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_RESPONSE',
      url
    }
  }

  export function BaixarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(id) {
    return {
      type: 'BAIXAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST',
      id
    }
  }
  
export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}