import produce from 'immer';

const initialState = {
  listaDashboardSolicitacaoStatus: [],
  listaDashboardAutorizacaoStatus: [],
  listaDashboardMedicaoStatus: [],
  listaDashboardAutorizacaoPorRegional: [],
  listaDashboardAutorizacaoFinalizadaPorMes: [],
  listaDashboardAutorizacaoValorPorMes: [],
  listaDashboardValorContratoPorCategoria: [],
  listaDashboardCategoriaGastaPorAutorizacaoFornecimento: [],
  listaDashboardObrasIntervencoesPorMes: [],
  listaDashboardManutencoesPorMes: [],
  carregando: true
};

export default function dashboard(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_DASHBOARD_SOLICITACAO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardSolicitacaoStatus = action.listaDashboardSolicitacaoStatus;
      });

    case 'LISTAR_DASHBOARD_AUTORIZACAO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardAutorizacaoStatus = action.listaDashboardAutorizacaoStatus;
      });

    case 'LISTAR_DASHBOARD_MEDICAO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardMedicaoStatus = action.listaDashboardMedicaoStatus;
      });

    case 'LISTAR_DASHBOARD_AUTORIZACAO_POR_REGIONAL_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardAutorizacaoPorRegional = action.listaDashboardAutorizacaoPorRegional;
      });

    case 'LISTAR_DASHBOARD_AUTORIZACAO_FINALIZADA_POR_MES_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardAutorizacaoFinalizadaPorMes = action.listaDashboardAutorizacaoFinalizadaPorMes;
      });

    case 'LISTAR_DASHBOARD_AUTORIZACAO_VALOR_POR_MES_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardAutorizacaoValorPorMes = action.listaDashboardAutorizacaoValorPorMes;
      });

    case 'LISTAR_DASHBOARD_VALOR_CONTRATO_POR_CATEGORIA_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardValorContratoPorCategoria = action.listaDashboardValorContratoPorCategoria;
      });

    case 'LISTAR_DASHBOARD_CATEGORIA_GASTA_POR_AUTORIZACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardCategoriaGastaPorAutorizacaoFornecimento = action.listaDashboardCategoriaGastaPorAutorizacaoFornecimento;
      });

    case 'LISTAR_DASHBOARD_OBRAS_INTERVENCOES_POR_MES_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardObrasIntervencoesPorMes = action.listaDashboardObrasIntervencoesPorMes;
      });

    case 'LISTAR_DASHBOARD_MANUTENCOES_POR_MES_RESPONSE':
      return produce(state, draft => {
        draft.listaDashboardManutencoesPorMes = action.listaDashboardManutencoesPorMes;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}