import produce from 'immer';

const initialState = {
  listaMedicaoAutorizacaoFornecimentoStatus: undefined,
  listaValidacaoMedicaoStatus: undefined,
  listaCompletoMedicaoAutorizacaoFornecimentoStatus: undefined,
  carregando: true
};

export default function medicaoAutorizacaoFornecimentoStatus(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaMedicaoAutorizacaoFornecimentoStatus = action.listaMedicaoAutorizacaoFornecimentoStatus;
      });

    case 'LISTAR_COMPLETO_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletoMedicaoAutorizacaoFornecimentoStatus = action.listaCompletoMedicaoAutorizacaoFornecimentoStatus;
      });

    case 'VALIDAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaValidacaoMedicaoStatus = action.listaValidacaoMedicaoStatus;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    case 'LIMPAR_LISTA_VALIDACAO_MEDICAO':
      return produce(state, draft => {
        draft.listaValidacaoMedicaoStatus = undefined
      });

    default:
      return state;
  }
}