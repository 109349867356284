export function ListarEmpenhoRequest() {
  return {
    type: 'LISTAR_EMPENHO_REQUEST'
  }
}

export function ListarEmpenhoResponse(listaEmpenho) {
  return {
    type: 'LISTAR_EMPENHO_RESPONSE',
    listaEmpenho
  }
}

export function BuscarEmpenhoPorIdRequest(id) {
  return {
    type: 'BUSCAR_EMPENHO_POR_ID_REQUEST',
    id
  }
}

export function BuscarEmpenhoPorIdResponse(empenhoCompleto) {
  return {
    type: 'BUSCAR_EMPENHO_POR_ID_RESPONSE',
    empenhoCompleto
  }
}

export function InserirEmpenhoRequest(data) {
  return {
    type: 'INSERIR_EMPENHO_REQUEST',
    data
  }
}

export function AlterarEmpenhoRequest(data) {
  return {
    type: 'ALTERAR_EMPENHO_REQUEST',
    data
  }
}

export function ExcluirEmpenhoRequest(id) {
  return {
    type: 'EXCLUIR_EMPENHO_REQUEST',
    id
  }
}

export function LimparEmpenho() {
  return {
    type: 'LIMPAR_EMPENHO'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}

export function TransferirAfRequest(data) {
  return {
    type: 'TRANSFERIR_AF_REQUEST',
    data
  }
}


export function TransferirAfDefinitivaParcialRequest(data) {
  return {
    type: 'TRANSFERIR_AF_DEFINITIVA_PARCIAL_REQUEST',
    data
  }
}