import produce from 'immer';

const initialState = {
  listaMulta: undefined,
  carregando: true
};

export default function multa(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MULTA_RESPONSE':
      return produce(state, draft => {
        draft.listaMulta = action.listaMulta;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}