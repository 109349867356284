export function ListarTipoCaracteristicaRequest() {
    return {
        type: 'LISTAR_TIPO_CARACTERISTICA_REQUEST'
    }
}

export function ListarTipoCaracteristicaResponse(listaTipoCaracteristica) {
    return {
        type: 'LISTAR_TIPO_CARACTERISTICA_RESPONSE',
        listaTipoCaracteristica
    }
}

export function InserirTipoCaracteristicaRequest(data) {
    return {
        type: 'INSERIR_TIPO_CARACTERISTICA_REQUEST',
        data
    }
}

export function AlterarTipoCaracteristicaRequest(data) {
    return {
        type: 'ALTERAR_TIPO_CARACTERISTICA_REQUEST',
        data
    }
}

export function ExcluirTipoCaracteristicaRequest(id) {
    return {
        type: 'EXCLUIR_TIPO_CARACTERISTICA_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}