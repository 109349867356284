import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse, 
         BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdResponse, Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';


function* ListarMedicaoAutorizacaoFornecimentoOcorrenciaRequest(model){

  try {
    
    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrencia/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse(result.data.result)); 
    yield put(Carregando(true));    
    
  }catch(e){
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));  

  }
}

function* InserirMedicaoAutorizacaoFornecimentoOcorrenciaRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrencia`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrencia/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.data.IdMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse(result.data.result)); 
    yield put(Carregando(true));
    
    toast.success(`Medição Autorização Fornecimento Ocorrência foi cadastrado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);  
            }    
          }
        }
      }
  
    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));
  }
}

function* AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/MedicaoAutorizacaoFornecimentoOcorrencia`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrencia/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse(result.data.result));
    yield put(Carregando(true));    
  
    toast.success(`Medição Autorização Fornecimento Ocorrência foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);  
            }    
          }
        }
      }

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));

  }
}

function* ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRequest(model){

  try{

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/MedicaoAutorizacaoFornecimentoOcorrencia/${model.data.Id}`, { headers:headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrencia/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.data.IdMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse(result.data.result)); 
    yield put(Carregando(true));    

    toast.success(`Medição Autorização Fornecimento Ocorrência id: ${model.data.Id} foi excluido!`);
  
  } catch (e) {
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));

  }
}

function* BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/MedicaoAutorizacaoFornecimentoOcorrencia/${model.id}`, { headers: headerParams.token });

    yield put(BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if (e.response != null && e.response.data != null) {
      if(e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }

    yield put(BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdResponse(null));
    yield put(Carregando(true));    

  }
}

export default all([
  takeLatest('LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST', ListarMedicaoAutorizacaoFornecimentoOcorrenciaRequest),
  takeLatest('INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST', InserirMedicaoAutorizacaoFornecimentoOcorrenciaRequest),
  takeLatest('EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST', ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRequest),
  takeLatest('ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REQUEST', AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRequest),
  takeLatest('BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_POR_ID_REQUEST', BuscarMedicaoAutorizacaoFornecimentoOcorrenciaPorIdRequest),
])