import produce from 'immer';

const initialState = {
  listaContratoUsuario: undefined,
  carregando: true
};

export default function contratoUsuario(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_CONTRATO_USUARIO_RESPONSE':
      return produce(state, draft => {
        draft.listaContratoUsuario = action.listaContratoUsuario;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}