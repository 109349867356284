import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  ListarDashboardSolicitacaoStatusResponse, ListarDashboardAutorizacaoStatusResponse, ListarDashboardAutorizacaoPorRegionalResponse,
  ListarDashboardAutorizacaoFinalizadaPorMesResponse, ListarDashboardAutorizacaoValorPorMesResponse,
  ListarDashboardValorContratoPorCategoriaResponse, ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoResponse,
  ListarDashboardMedicaoStatusResponse, ListarDashboardObrasIntervencoesPorMesResponse, ListarDashboardManutencoesPorMesResponse
} from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import { Carregando } from '../perfil/actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import Cookies from 'universal-cookie';

function* ListarDashboardSolicitacaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    let data = {
      dataInicio: model.dataInicio,
      dataFinal: model.dataFinal,
      visualizarTudo: model.visualizarTudoSolicitacao
    }

    const result = yield call(api.post, `/api/Dashboard/ListarDashboardSolicitacaoStatus`, data, { headers: headerParams.token });

    yield put(ListarDashboardSolicitacaoStatusResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardAutorizacaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    let data = {
      dataInicio: model.inicio,
      dataFinal: model.final,
      visualizarTudo: model.visualizarTudoAutorizacao
    }

    const result = yield call(api.post, `/api/Dashboard/ListarDashboardAutorizacaoStatus`, data, { headers: headerParams.token });

    yield put(ListarDashboardAutorizacaoStatusResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardMedicaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    let data = {
      dataInicio: model.dataInicio,
      dataFinal: model.dataFinal,
      visualizarTudo: model.visualizarTudoMedicao
    }

    const result = yield call(api.post, `/api/Dashboard/ListarDashboardMedicaoStatus`, data, { headers: headerParams.token });

    yield put(ListarDashboardMedicaoStatusResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardAutorizacaoPorRegionalRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Dashboard/ListarDashboardAutorizacaoPorRegional`, { headers: headerParams.token });

    yield put(ListarDashboardAutorizacaoPorRegionalResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardAutorizacaoFinalizadaPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Dashboard/ListarDashboardAutorizacaoFinalizadaPorMes`, { headers: headerParams.token });

    yield put(ListarDashboardAutorizacaoFinalizadaPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardAutorizacaoValorPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Dashboard/ListarDashboardAutorizacaoValorPorMes`, { headers: headerParams.token });

    yield put(ListarDashboardAutorizacaoValorPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardValorContratoPorCategoriaRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Dashboard/ListarDashboardValorContratoPorCategoria`, { headers: headerParams.token });

    yield put(ListarDashboardValorContratoPorCategoriaResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Dashboard/ListarDashboardCategoriaGastaPorAutorizacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardAutorizacaoFinalizadaPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardAutorizacaoFinalizadaPorMes`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório do total de autorizações finalizadas por mês'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardAutorizacaoPorRegionalRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardAutorizacaoPorRegional`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório do total de autorizações por região'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardValorTotalContratoPorCategoriaRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardValorTotalContratoPorCategoria`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório do total de valor contrato por categoria'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimento`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório do total de categoria gasta por autorização fornecimento'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardStatusSolicitacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardStatusSolicitacaoFornecimento`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório do total de Solicitação Fornecimento por Status'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarDashboardObrasIntervencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data ={
      "VisualizarCompleto": model.visualizarTudo,
      "Ano": model.ano
    }

    const result = yield call(api.post, `/api/Dashboard/ListarDashboardObrasIntervencoesMes`, data, { headers: headerParams.token });

    yield put(ListarDashboardObrasIntervencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardObrasIntervencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    const cookies = new Cookies();

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardObrasIntervencoesPorMesRequest`,{
      method: "POST",
      headers: new Headers({
          'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
              `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
              : '',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
          VisualizarCompleto: model.visualizarTudo,
          Ano: model.ano
      }),
     })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de Obras e Intervenções por Mês'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}


function* ListarDashboardManutencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data ={
      "VisualizarCompleto": model.visualizarTudo,
      "Ano": model.ano
    }

    const result = yield call(api.post, `/api/Dashboard/ListarDashboardManutencoesPorMes`, data, { headers: headerParams.token });

    yield put(ListarDashboardManutencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarRelatorioDashboardManutencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    const cookies = new Cookies();

    fetch(`${urlBase}/api/Dashboard/BaixarRelatorioDashboardManutencoesPorMesRequest`,{
      method: "POST",
      headers: new Headers({
          'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
              `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
              : '',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
          VisualizarCompleto: model.visualizarTudo,
          Ano: model.ano
      }),
     })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Relatório de Manutenções por Mês'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

export default all([
  takeLatest('LISTAR_DASHBOARD_SOLICITACAO_STATUS_REQUEST', ListarDashboardSolicitacaoStatusRequest),
  takeLatest('LISTAR_DASHBOARD_AUTORIZACAO_STATUS_REQUEST', ListarDashboardAutorizacaoStatusRequest),
  takeLatest('LISTAR_DASHBOARD_AUTORIZACAO_POR_REGIONAL_REQUEST', ListarDashboardAutorizacaoPorRegionalRequest),
  takeLatest('LISTAR_DASHBOARD_AUTORIZACAO_FINALIZADA_POR_MES_REQUEST', ListarDashboardAutorizacaoFinalizadaPorMesRequest),
  takeLatest('LISTAR_DASHBOARD_AUTORIZACAO_VALOR_POR_MES_REQUEST', ListarDashboardAutorizacaoValorPorMesRequest),
  takeLatest('LISTAR_DASHBOARD_VALOR_CONTRATO_POR_CATEGORIA_REQUEST', ListarDashboardValorContratoPorCategoriaRequest),
  takeLatest('LISTAR_DASHBOARD_CATEGORIA_GASTA_POR_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_AUTORIZACAO_FINALIZADA_POR_MES_REQUEST', BaixarRelatorioDashboardAutorizacaoFinalizadaPorMesRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_AUTORIZACAO_POR_REGIONAL_REQUEST', BaixarRelatorioDashboardAutorizacaoPorRegionalRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_VALOR_TOTAL_CONTRATO_POR_CATEGORIA_REQUEST', BaixarRelatorioDashboardValorTotalContratoPorCategoriaRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_VALOR_TOTAL_CATEGORIA_GASTA_POR_AUTORIZACAO_FORNECIMENTO_REQUEST', BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimentoRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_STATUS_SOLICITACAO_FORNECIMENTO_REQUEST', BaixarRelatorioDashboardStatusSolicitacaoFornecimentoRequest),
  takeLatest('LISTAR_DASHBOARD_MEDICAO_STATUS_REQUEST', ListarDashboardMedicaoStatusRequest),
  takeLatest('LISTAR_DASHBOARD_OBRAS_INTERVENCOES_POR_MES_REQUEST', ListarDashboardObrasIntervencoesPorMesRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_OBRAS_INTERVENCOES_POR_MES_REQUEST', BaixarRelatorioDashboardObrasIntervencoesPorMesRequest),
  takeLatest('LISTAR_DASHBOARD_MANUTENCOES_POR_MES_REQUEST', ListarDashboardManutencoesPorMesRequest),
  takeLatest('BAIXAR_RELATORIO_DASHBOARD_MANUTENCOES_POR_MES_REQUEST', BaixarRelatorioDashboardManutencoesPorMesRequest)
])

