import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
    ListarUnidadeMedidaRequest, ExcluirUnidadeMedidaRequest,
    InserirUnidadeMedidaRequest, AlterarUnidadeMedidaRequest
} from '../../../store/modules/unidadeMedida/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IoResize } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import './unidadeMedida.css';
import { FaPlus } from "react-icons/fa";


export default function UnidadeMedida() {

    const dispatch = useDispatch();
    const history = useHistory();
    const listaUnidadeMedida = useSelector(state => state.unidadeMedida.listaUnidadeMedida);
    const carregando = useSelector(state => state.unidadeMedida.carregando);
    const [selectedRow, setSelectedRow] = useState(null);
    //   const myTextIcon = React.useRef(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {
        //        myTextIcon.current.parentNode.parentNode.classList.remove('MuiIconButton-root');
        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarUnidadeMedidaRequest());
    }, [])

    function ValidacoesInserir(unidadeMedida) {

        //Validação do campo descrição
        if (unidadeMedida.descricao == null || unidadeMedida.descricao == undefined
            || unidadeMedida.descricao.trim() == '' || unidadeMedida.descricao.length > 50) {
            toast.error(`Unidade de medida inválida`);
            return;
        }

        //Validação do campo abreviação
        if (unidadeMedida.abreviacao == null || unidadeMedida.abreviacao == undefined
            || unidadeMedida.abreviacao.trim() == '' || unidadeMedida.abreviacao.length > 2) {
            toast.error(`Abreviação inválida`);
            return;
        }

        return true;

    }

    function InserirUnidadeMedida(unidadeMedida) {

        let data = {
            'Id': 0,
            'Descricao': unidadeMedida.descricao.trim(),
            'Abreviacao': unidadeMedida.abreviacao.trim(),
        }

        dispatch(InserirUnidadeMedidaRequest(data))
    }

    function AlterarUnidadeMedida(unidadeMedida) {

        let data = {
            'Id': unidadeMedida.id,
            'Descricao': unidadeMedida.descricao.trim(),
            'Abreviacao': unidadeMedida.abreviacao.trim(),
        };

        dispatch(AlterarUnidadeMedidaRequest(data))
    }

    function ExcluirUnidadeMedida(idUnidadeMedida) {
        dispatch(ExcluirUnidadeMedidaRequest(idUnidadeMedida));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col md={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <span>
                                        <IoResize size={25} color={"#000"} /> Unidades de Medida
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col>
                                        {
                                            carregando ?

                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,

                                                    }}
                                                    title=""
                                                    columns={[
                                                        {
                                                            title: 'Número da Unidade', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                width: '20%',
                                                            }
                                                        },
                                                        {
                                                            title: 'Nome', field: 'descricao', sorting: true, editPlaceholder: 'Nome', validate: rowData => {
                                                                if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                } else if (rowData.descricao.trim().length > 50) {
                                                                    return "O nome não pode conter mais que 50 caracteres"
                                                                }
                                                                return true
                                                            }, cellStyle: { width: '40%' },

                                                        },
                                                        {
                                                            title: 'Abreviação', field: 'abreviacao', sorting: true, editPlaceholder: 'Abreviação', validate: rowData => {
                                                                if (rowData.abreviacao === undefined || rowData.abreviacao.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                } else if (rowData.abreviacao.trim().length > 2) {
                                                                    return "A abreviação não pode conter mais que 2 caracteres"
                                                                }
                                                                return true
                                                            }, cellStyle: { width: '40%' }
                                                        },
                                                    ]}
                                                    data={
                                                        listaUnidadeMedida != undefined ?
                                                            listaUnidadeMedida.map(unidadeMedida => {
                                                                return {
                                                                    id: unidadeMedida.id,
                                                                    descricao: unidadeMedida.descricao,
                                                                    abreviacao: unidadeMedida.abreviacao
                                                                }
                                                            })
                                                            :
                                                            []
                                                    }
                                                    icons={{
                                                        Add: () =>
                                                            <Button variant="success">
                                                                <span>
                                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                                </span>
                                                            </Button>
                                                    }}
                                                    editable={{
                                                        onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() => {
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else {
                                                                        InserirUnidadeMedida(newRow);
                                                                        resolve()
                                                                    }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        }),

                                                        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() => {
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else {
                                                                        AlterarUnidadeMedida(newRow);
                                                                        resolve()
                                                                    }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        }),

                                                        onRowDelete: oldData => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() => {
                                                                    ExcluirUnidadeMedida(oldData.id);
                                                                    resolve()
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        })
                                                    }}
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        addRowPosition: "first",
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        actionsColumnIndex: -1,
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        filtering: true,
                                                        rowStyle: rowData => ({
                                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        })

                                                    }}
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                :
                                                <Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    )
}