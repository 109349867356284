import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse } from './actions';
import history from '../../../services/history';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';

function* ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/listarCompleto`,
      { IdMedicaoSubItem: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

   if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }   

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));   
  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/listarCompleto`,
     { IdMedicaoSubItem: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse(result.data.result));
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Documentos id: ' + listaId + ' cadastrados com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
        }
      }

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse([]));    
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq`, model.data, { headers: headerParams.token });

    toast.success(`Documentos atualizado com sucesso!`);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/listarCompleto`,
      { IdMedicaoSubItem: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse([]));    
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/${model.id}/${'0'}`, { headers: headerParams.token });

    toast.success(`Documento id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/listarCompleto`,
      { IdMedicaoSubItem: parseInt(model.idMedicao) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse([]));    
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeArquivo}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/AutorizacaoFornecimentoSubitemCustoMedicaoDocArq/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {
        
    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }   

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    

  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST', ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST', AlterarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST', ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest),
  takeLatest('BAIXAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST', BaixarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest)
])