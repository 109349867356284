import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    BuscarEventoRespostaPorIdRequest,
    InserirEventoRespostaRequest, AlterarEventoRespostaRequest, LimparEventoResposta
} from '../../../store/modules/eventoResposta/actions';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Select from 'react-select';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
    GerarOficioRequest
} from '../../../store/modules/solicitacaoFornecimento/actions';
import {
    ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest
    , ListarDadosExportarPDFSolicitacaoFornecimentoRequest,
    RelatorioEventoRespostaRequest
} from "../../../store/modules/relatorio/actions";
import PdfOrcamentoEvento from "../../../components/pdfOrcamentoEvento/pdfOrcamentoEvento";
import Loading from "../../../components/Loading/loading";

export default function EventoResposta() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const listaTipoSolicitacao = useSelector(state => state.tipoSolicitacao.listaTipoSolicitacao);
    const listaUsuario = useSelector(state => state.usuario.listaUsuario);
    const listaOrgao = useSelector(state => state.orgao.listaOrgao);
    const eventoRespostaCompleto = useSelector(state => state.eventoResposta.eventoRespostaCompleto);
    const tipoSolicitacaoCompleto = useSelector(state => state.tipoSolicitacao.tipoSolicitacaoCompleto);
    const listaDadosExportarPDFSolicitacaoFornecimentoEvento = useSelector(state => state.relatorio.listaDadosExportarPDFSolicitacaoFornecimentoEvento);

    const [idEventoResposta, setIdEventoResposta] = useState('');
    const [idTipoSolicitacao, setIdTipoSolicitacao] = useState('');
    const [idSolicitacaoFornecimento, setIdSolicitacaoFornecimento] = useState('');
    const [autorizador, setAutorizador] = useState('');
    const [destinatario, setDestinatario] = useState('');
    const [remetente, setRemetente] = useState('');
    const [memorando, setMemorando] = useState('');
    const [assunto, setAssunto] = useState('');
    const [numeroProcesso, setNumeroProcesso] = useState('');
    const [numeroOficio, setNumeroOficio] = useState('');
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Permissões
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    //const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        if (id != undefined) {
            dispatch(BuscarEventoRespostaPorIdRequest(id));
        }

    }, [id])

    // Limpar useSelector ao sair da tela
    useEffect(() => () =>
        dispatch(LimparEventoResposta())
        , []);

    useEffect(() => {

        if (eventoRespostaCompleto != null) {
            setIdEventoResposta(eventoRespostaCompleto.id);
            setIdTipoSolicitacao(eventoRespostaCompleto.idTipoSolicitacao);
            setIdSolicitacaoFornecimento(eventoRespostaCompleto.idSolicitacaoFornecimento);
            setAutorizador(eventoRespostaCompleto.autorizador);
            setDestinatario(eventoRespostaCompleto.destinatario);
            setRemetente(eventoRespostaCompleto.remetente);
            setMemorando(eventoRespostaCompleto.memorando);
            setAssunto(eventoRespostaCompleto.assunto);
            setNumeroProcesso(eventoRespostaCompleto.numeroProcesso);
            setNumeroOficio(eventoRespostaCompleto.numeroOficio);
        }

    }, [eventoRespostaCompleto])

    function CadastrarEventoResposta(e) {

        e.preventDefault();

        if (eventoRespostaCompleto != null) {
            let data = {
                'Id': eventoRespostaCompleto.id,
                'IdTipoSolicitacao': parseInt(143),
                'IdSolicitacaoFornecimento': parseInt(id),
                'Autorizador': '',
                'Destinatario': '',
                'Remetente': '',
                'Memorando': memorando,
                'Assunto': assunto,
                'NumeroProcesso': '',
                'NumeroOficio': numeroOficio
            }
            dispatch(AlterarEventoRespostaRequest(data, id));

        } else {
            let data = {
                'Id': 0,
                'IdTipoSolicitacao': parseInt(143),
                'IdSolicitacaoFornecimento': parseInt(id),
                'Autorizador': '',
                'Destinatario': '',
                'Remetente': '',
                'Memorando': memorando,
                'Assunto': assunto,
                'NumeroProcesso': '',
                'NumeroOficio': numeroOficio
            }
            dispatch(InserirEventoRespostaRequest(data, id));
        }
    }

    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {

            event.preventDefault();
            setValidated(true);
        } else {

            CadastrarEventoResposta(event);
            setValidated(false);
        }
    };

    function MontarPDF() {

        if (eventoRespostaCompleto != null) {

            dispatch(RelatorioEventoRespostaRequest({
                'IdSolicitacaoFornecimento': id,
                'IdEventoResposta': eventoRespostaCompleto.id
            }))
        }
    }

    function GerarOficio() {
        dispatch(GerarOficioRequest(id, 2))
    }

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="tamanhoTela">
                    <Col md={12}>
                        <Row className="mb-3">

                            <Form.Group as={Col} md={12} controlId="formEndereco">
                                <Form.Label>Número Oficio</Form.Label>
                                <Form.Control
                                    style={{ minHeight: '36px' }}
                                    type="text"
                                    value={numeroOficio}
                                    disabled
                                    onChange={(e) => setNumeroOficio(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formEndereco">
                                <Form.Label>Assunto</Form.Label>
                                <Form.Control
                                    style={{ minHeight: '36px' }}
                                    type="text"
                                    value={assunto}
                                    onChange={(e) => setAssunto(e.target.value)}
                                    maxLength="500"
                                />
                                <Form.Control.Feedback type="invalid">O campo Assunto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formEndereco">
                                <Form.Label>Memorando</Form.Label>
                                <Form.Control
                                    as={"textarea"}
                                    style={
                                        {
                                            'height': '180px'
                                        }
                                    }
                                    type="text"
                                    value={memorando}
                                    onChange={(e) => setMemorando(e.target.value)}
                                    maxLength="10000"
                                />
                                <Form.Control.Feedback type="invalid">O campo Memorando é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        {/*
                        <Row className="mb-3">
                            <Form.Group as={Col} md={6} controlId="formEndereco">
                                <Form.Label>Número do Processo</Form.Label>
                                <Form.Control
                                    style={{ minHeight: '36px' }}
                                    type="text"
                                    value={numeroProcesso}
                                    onChange={(e) => setNumeroProcesso(e.target.value)}
                                    maxLength="200"
                                />
                                <Form.Control.Feedback type="invalid">O campo Número do Processo é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>*/}
                        <Row className="mb-3">
                            {
                                eventoRespostaCompleto == null
                                    ?

                                    <Col md={2}>

                                        <Button type="submit"
                                            variant="success"
                                            style={{ width: '100%' }} >Cadastrar</Button>
                                    </Col>

                                    :

                                    <Col md={2}>

                                        <Button type="submit"
                                            variant="success"
                                            style={{ width: '100%' }}>Editar</Button>
                                    </Col>

                            }


                            <Col md={2}>
                                <Button onClick={MontarPDF} variant="success" style={{ width: '100%' }}>
                                    Exportar Evento Resposta
                                </Button>
                            </Col>
                            <Col md={2}>
                                <Button
                                    variant={"danger"}
                                    style={{ width: '100%' }}
                                    onClick={() => GerarOficio()}>
                                    Gerar Ofício
                                </Button>
                            </Col>
                        </Row>

                        {
                            eventoRespostaCompleto == null ?
                                ''
                                :
                                eventoRespostaCompleto != null && eventoRespostaCompleto.eventoResposta == '' ?
                                    <Row className="mb-3">
                                        <Col md='auto'>
                                            <Button
                                                variant={"danger"}
                                                style={{ width: '100%', marginTop: '10%' }}
                                                onClick={() => GerarOficio()}>
                                                Gerar Ofício
                                            </Button>
                                        </Col>
                                    </Row>
                                    :
                                    ''
                        }

                    </Col>
                </div>
            </Form>

        </>
    );
}