import produce from 'immer';

const initialState = { listaAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto: undefined,
  url: undefined,
  carregando: true};

export default function autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_RESPONSE':
        return produce(state, draft => {
            draft.listaAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto = action.listaAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto
        });
    
     case 'CARREGANDO':
        return produce(state, draft => {
            draft.carregando = action.status
        });  

      case 'CARREGAR_IMAGEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_RESPONSE':
        return produce(state, draft => {
          draft.url = action.url
        });
    
    default:
      return state;
  }
}