import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarMedicaoAutorizacaoFornecimentoResponse, BuscarMedicaoAutorizacaoFornecimentoPorIdResponse, Carregando, ListarTodasMedicaoAutorizacaoFornecimentoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarTodasMedicaoAutorizacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarTodasCompletoPaginacao`,
      {
        Pagina: 1,
        NumeroPorPagina: 10
      }, { headers: headerParams.token });

    yield put(ListarTodasMedicaoAutorizacaoFornecimentoResponse(result.data.result));

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarTodasMedicaoAutorizacaoFornecimentoResponse([]));
    yield put(Carregando(true));

  }
}


function* ListarMedicaoAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarCompleto`,
      { IdAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarMedicaoAutorizacaoFornecimentoResponse([]));
    yield put(Carregando(true));

  }
}

function* InserirMedicaoAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/MedicaoAutorizacaoFornecimento`, model.data, { headers: headerParams.token });

    //history.push(`/autorizacaoFornecimento`);
    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarCompleto`,
      { IdAutorizacaoFornecimento: parseInt(model.data.IdAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoResponse(result.data.result));

    const resultTodas = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarTodasCompletoPaginacao`, {
      Pagina: 1,
      NumeroPorPagina: 10
    }, { headers: headerParams.token });
    yield put(ListarTodasMedicaoAutorizacaoFornecimentoResponse(resultTodas.data.result));

    yield put(Carregando(true));

    toast.success(`Medicao Autorizacao Fornecimento foi cadastrado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);
            }
          }
        }
      }

    //yield put(ListarMedicaoAutorizacaoFornecimentoResponse([]));
    yield put(Carregando(true));

  }
}

function* AlterarMedicaoAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/MedicaoAutorizacaoFornecimento`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarCompleto`,
      { IdAutorizacaoFornecimento: parseInt(model.data.IdAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoResponse(result.data.result));

    const resultTodas = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarTodasCompletoPaginacao`, {
      Pagina: 1,
      NumeroPorPagina: 10
    }, { headers: headerParams.token });
    yield put(ListarTodasMedicaoAutorizacaoFornecimentoResponse(resultTodas.data.result));

    yield put(Carregando(true));

    toast.success(`Medicao Autorizacao Fornecimento foi atualizado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);
            }
          }
        }
      }

    //yield put(ListarMedicaoAutorizacaoFornecimentoResponse([]));
    yield put(Carregando(true));

  }
}

function* ExcluirMedicaoAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/MedicaoAutorizacaoFornecimento/${model.data.Id}`, { headers: headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarCompleto`,
      { IdAutorizacaoFornecimento: parseInt(model.data.IdAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoResponse(result.data.result));

    const resultTodas = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarTodasCompletoPaginacao`, {
      Pagina: 1,
      NumeroPorPagina: 10
    }, { headers: headerParams.token });
    yield put(ListarTodasMedicaoAutorizacaoFornecimentoResponse(resultTodas.data.result));

    yield put(Carregando(true));

    toast.success(`Medicao Autorizacao Fornecimento id: ${model.id} foi excluido!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    //yield put(ListarMedicaoAutorizacaoFornecimentoResponse([]));
    yield put(Carregando(true));

  }
}

function* BuscarMedicaoAutorizacaoFornecimentoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/MedicaoAutorizacaoFornecimento/${model.id}`, { headers: headerParams.token });

    yield put(BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(null));
    yield put(Carregando(true));

  }
}

export default all([
  takeLatest('LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarMedicaoAutorizacaoFornecimentoRequest),
  takeLatest('INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST', InserirMedicaoAutorizacaoFornecimentoRequest),
  takeLatest('EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST', ExcluirMedicaoAutorizacaoFornecimentoRequest),
  takeLatest('ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST', AlterarMedicaoAutorizacaoFornecimentoRequest),
  takeLatest('BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_POR_ID_REQUEST', BuscarMedicaoAutorizacaoFornecimentoPorIdRequest),
  takeLatest('LISTAR_TODAS_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarTodasMedicaoAutorizacaoFornecimentoRequest),
])