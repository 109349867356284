import { Col, Card, Tabs, Tab } from "react-bootstrap";
import { MdAssignment } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../../../components/Header/header";
import DocumentoArquivo from "../DocumentoArquivo/documentoArquivo";
import RegistroFotografico from "../RegistroFotografico/registroFotografico";
import Ocorrencia from "../Ocorrencia/ocorrencia";
import Subitem from "../SubItem/subitem";
import { BuscarAutorizacaoFornecimentoPorIdRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import { toast } from "react-toastify";

export default function Abas() {

    const { idMedicaoSubItem, idAutorizacaoFornecimento } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const autorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimento);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])
    
    useEffect(() => {
        dispatch(BuscarAutorizacaoFornecimentoPorIdRequest(idAutorizacaoFornecimento));
    }, [])

    useEffect(() => {
        
        if (autorizacaoFornecimento != null &&
            autorizacaoFornecimento != undefined &&
            usuario.usuarioFornecedor == true &&
            autorizacaoFornecimento.solicitacaoFornecimentoVersao.solicitacaoFornecimento.idFornecedor != usuario.fornecedor.idFornecedor) {
            history.push('/');
        }
    }, [autorizacaoFornecimento])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdAssignment size={25} color={"#000"} /> Medição Autorização Item
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Tabs defaultActiveKey="Subitem" id="tab-fonte" className="mb-3">
                                <Tab eventKey="Subitem" title="Info">
                                    <Subitem />
                                </Tab>
                                <Tab eventKey="Foto" title="Registro Fotográfico" disabled={idMedicaoSubItem ? false : true}>
                                    <RegistroFotografico />
                                </Tab>
                                <Tab eventKey="Documento" title="Documento" disabled={idMedicaoSubItem ? false : true}>
                                    <DocumentoArquivo />
                                </Tab>
                                <Tab eventKey="Ocorrencia" title="Ocorrência" disabled={idMedicaoSubItem ? false : true}>
                                    <Ocorrencia />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}