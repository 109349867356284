import { useEffect, useState } from "react";
import { Row, Modal, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ImportarContratoExcelRequest } from "../../../store/modules/contrato/actions";
import { CriarTxt } from "../../../store/modules/global/actions";
import { DropzoneArea } from 'material-ui-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { ListarCompletoSolicitacaoFornecimentoRequest } from "../../../store/modules/solicitacaoFornecimento/actions";
import Loading from "../../../components/Loading/loading";
import * as XLSX from "xlsx";
import ModalSolicitacaoAjusteImportacao from "../../../components/ModalSolicitacaoAjusteImportacao/modalSolicitacaoAjusteImportacao";

export default function Importar() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const carregando = useSelector(state => state.contrato.carregando);
    const listaGrupoCusto = useSelector(state => state.grupoCusto.listaCompletaGrupoCusto);
    const listaSolicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.listaSolicitacaoFornecimento);

    const [items, setItems] = useState([]);
    const [messageError, setMessageError] = useState('');
    const [showConfirma, setShowConfirma] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSolicitacao, setShowSolicitacao] = useState(false);
    const [dados, setDados] = useState();
    const [tipo, setTipo] = useState('');

    const handleCloseConfirma = () => setShowConfirma(false);
    const handleShowConfirma = () => setShowConfirma(true);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    const handleSolicitacaoClose = () => setShowSolicitacao(false);
    const handleSolicitacaoShow = () => setShowSolicitacao(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setItems(d);
        });
    };

    useEffect(() => {

        dispatch(ListarCompletoSolicitacaoFornecimentoRequest({
            IdSolicitacaoFornecimento: null,
            IdContrato: id,
            Servico: true
        }));

    }, [id])

    function vezesLetraAparece(frase, letra) {
        var resultado = 0;

        for (const letraAtual of frase) {
            if (letraAtual === letra) {
                resultado++;
            }
        }

        return resultado;
    }

    function ImportarArquivo() {

        try {

            if (items.length > 0) {

                let lista = [];
                let continued = true;
                let wrongText = "";
                let count = 0;

                var data = {
                    'idPrimeiraVersaoContrato': contratoCompleto.contratoVersaoApiModel.id,
                    'idTipoContratoVersao': null,
                    'dataInicioContrato': null,
                    'dataFimContrato': null,
                    'valorIndiceReajuste': null,
                    'valorIndiceMulta': null,
                    'pageImport': false,
                    'idUsuario': usuario.id
                }

                items.forEach(item => {

                    if (item.__EMPTY_5 != undefined &&
                        vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 0 &&
                        Number.isInteger(item.__EMPTY_1)) {
                        lista.push({ 'item': item, 'tipo': 'grupo', 'contrato': data });
                    }

                    if (vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 1 &&
                        (item.__EMPTY_7 == undefined || item.__EMPTY_7 == '') &&
                        (item.__EMPTY_8 == undefined || item.__EMPTY_8 == '')) {
                        lista.push({ 'item': item, 'tipo': 'item', 'contrato': data });

                    } else
                        if (vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 1 &&
                            item.__EMPTY_7 != undefined &&
                            item.__EMPTY_8 != undefined) {
                            lista.push({ 'item': item, 'tipo': 'subitemSemItem', 'contrato': data });
                        }

                    if (vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 2 && item.__EMPTY_7 != undefined) {

                        if (item.__EMPTY_1 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "ITEM". \n`;
                        }
                        if (item.__EMPTY_2 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "FONTE". \n`;
                        }
                        if (item.__EMPTY_3 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "COMPOSIÇÃO". \n`;
                        }
                        if (item.__EMPTY_4 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "CÓDIGO". \n`;
                        }
                        if (item.__EMPTY_5 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "DESCRIÇÃO". \n`;
                        }
                        if (item.__EMPTY_6 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "UNIDADE". \n`;
                        }
                        if (item.__EMPTY_7 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "QUANTIDADE". \n`;
                        }
                        if (item.__EMPTY_8 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "VALOR UNITÁRIO". \n`;
                        }
                        if (item.__EMPTY_9 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "VALOR TOTAL". \n`;
                        }
                        if (item.__EMPTY_10 == undefined) {
                            continued = false;
                            wrongText += `linha com erro: Linha: ${count - 2} campo "VALOR PREVISÃO MENSAL". \n`;
                        }

                        lista.push({ 'item': item, 'tipo': 'subitem', 'contrato': data });
                    }

                    count++;
                });

                if (!continued) {
                    lista = [];
                    continued = true;
                }

                if (lista.length > 0) {
                    setDados(lista);

                    if ((listaSolicitacaoFornecimento == undefined) || (listaSolicitacaoFornecimento != undefined &&
                        listaSolicitacaoFornecimento.filter(s => s.statusAtual != "Solicitação Finalizada" && s.statusAtual != "").length == 0)) {

                        dispatch(ImportarContratoExcelRequest({
                            'ListaObjeto': lista,
                            'ListaIdSolicitacaoFornecimento': null,
                            'ListaIdSolicitacaoFornecimentoCancelar': null
                        }, id));

                    }

                    handleCloseConfirma();
                    setTipo("Importacao");
                    handleSolicitacaoShow();
                } else {
                    handleShowError();
                    handleCloseConfirma();
                    dispatch(CriarTxt(wrongText));
                    setMessageError(`Existe algo errado com o excel importado. \nLINHA INFORMADA A PARTIR DO CABEÇALHO DA TABELA: \nERROS: \n\n${wrongText}`);
                }
            } else {
                handleShowError();
                handleCloseConfirma();
                setMessageError("Selecione o Excel antes de Importar!");
            }

        } catch (error) {
            handleShowError();
            handleCloseConfirma();
            setMessageError("CAMPOS IMPORTANTES ESTÃO FALTANDO NO EXCEL, VERIFIQUE NOVAMENTE ANTES DE IMPORTAR");
        }
    }

    function AbrirModal(e) {

        e.preventDefault();

        if (items.length > 0) {

            // Verifica se é a primeira versão do contrato, se não for, aparece o modal de nova versão para o usuário confimar
            if (listaGrupoCusto != null && listaGrupoCusto != undefined && listaGrupoCusto.length > 0) {
                handleShowConfirma();
            } else {
                ImportarArquivo();
            }

        } else {
            handleShowError();
            handleCloseConfirma();
            setMessageError("Selecione o Excel antes de Importar!");
        }
    }

    return (
        <div className="divBody">

            <Row className="mb-3">
                <Col md={{ span: 12 }}>
                    {
                        carregando ?
                            <div className="divModal">
                                <DropzoneArea
                                    initialFiles={[]}
                                    onChange={(files) => {
                                        if (files.length > 0) {
                                            readExcel(files[0])
                                        } else {
                                            setItems([])
                                        }
                                    }}
                                    filesLimit={1}
                                    acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                    showPreviewsInDropzone={true}
                                    useChipsForPreview
                                    getFileLimitExceedMessage={() => `Só é permitido adicionar 1 arquivo`}
                                    getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                    getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                    disableRejectionFeedback={true}
                                    dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                    maxFileSize={30000000}
                                />
                            </div>
                            :
                            <Loading />
                    }
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md={1}>
                    {
                        inserir == true ?
                            <Button type="submit" variant="success" onClick={AbrirModal}>Importar</Button>
                            :
                            ''
                    }

                </Col>
            </Row>

            <Modal
                show={showError}
                onHide={handleCloseError}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseError}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showConfirma}
                onHide={handleCloseConfirma}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        Ao importar será criada uma nova versão de contrato, você deseja continuar?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={5}>
                            <Button variant="success" onClick={() => ImportarArquivo()}>Sim</Button>
                        </Col>
                        <Col md={5}>
                            <Button variant="danger" onClick={handleCloseConfirma}>Não</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <ModalSolicitacaoAjusteImportacao
                idContrato={id}
                show={showSolicitacao}
                onHide={handleSolicitacaoClose}
                dados={dados}
                tipo={tipo}
            />
        </div>
    );
}