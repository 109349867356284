import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { useEffect } from "react";
import img from '../../assets/login/img_login.png';
import moment from 'moment';
import cabecalho from '../../assets/novoCabecalhoCor.jpg';
import rodape from '../../assets/novoRodapeCor.jpg';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: '10',
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '15',
  },
  linha: {
    borderBottom: '1px solid #0000',
    width: '100%',
  },
  espaco: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  espacoGrande: {
    padding: '25',
    width: '10%',
  },
  table: {
    width: '100%',
  },
  section: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },

  block: {
    display: 'block'
  },

  rowCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold'
  },
  bordaCompleta: {
    border: '1px solid #0000',
    margin: 1
  },
  comeco: {
    width: "25%",
  },

  meio: {
    width: "50%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },

  fim: {
    width: "35%",
  },
  width8: {
    width: "8%"
  },
  width10: {
    width: "10%"
  },
  width20: {
    width: "20%"
  },
  width33: {
    width: "33%"
  },
  width100: {
    width: "100%"
  },
  cabecalho: {
    color: '#fff',
    backgroundColor: '#212529',
    bordercolor: '#32383e'
  },
  cinza: {
    backgroundColor: '#e9ecef',
  },
  logo: {
    width: '200',
    height: '100'
  },
  top40: {
    top: '40'
  },
  top60: {
    top: '60'
  },
  top20: {
    top: '20'
  },
  comecoMaior: {
    width: "40%",
  },
  comeco35: {
    width: "35%",
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',
    padding: '5',
  },
  alinharBottomSemPadding: {
    position: 'absolute',
    bottom: '0',
  },
  rodape: {
    width: '585',
    height: '50',
  },
  rodapeDiversosCI: {
    width: '570',
    height: '50',
  },
  bottom150: {
    marginBottom: 150,
  },
  bottom75: {
    marginBottom: 75,
  },
  marginRight100: {
    marginRight: 100
  },
  marginLeft20: {
    marginLeft: 20
  },
  paddingRight50: {
    paddingRight: 50
  }


});

export default function PdfOrcamentoEvento({ data }) {

  useEffect(() => {
    moment.locale('pt-br');
  }, [])

  return (
    <Document>
      {
        data != null ?

          data.nomeEvento == "Diversos CI" ?
            <Page size="A4" style={styles.page}>
              <View style={[styles.section]}>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.comeco35}></Text>
                  <Text style={[styles.meio]}>
                    <Image style={styles.logo} src={cabecalho} />
                  </Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>

                <View style={styles.table}>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.linha]}></View>
                  <View style={[styles.cabecalho, styles.rowCabecalho]} >
                    <Text style={styles.width33}>COMUNICAÇÃO INTERNA</Text>
                    <Text style={styles.width33}>Nº: {data.numeroOficio}</Text>
                    <Text style={styles.width33}>DATA: {moment(moment((new Date(data.dataSolicitacao)))).format('LL')}</Text>
                  </View>
                  <View style={[styles.espaco]}></View>
                  <View>
                    <View style={[styles.block, styles.centralizarTexto]} break>
                      <Text style={[styles.width100]}>DE:&nbsp;
                        {
                          data.listaPerguntasRespostas.filter(p => p.pergunta == 'Remetente').length == 1 ?
                            data.listaPerguntasRespostas.filter(p => p.pergunta == 'Remetente')[0].resposta
                            :
                            ''
                        }
                      </Text>
                      <View style={[styles.espaco]}></View>
                      <Text style={[styles.width100]}>PARA: {data.destinatario}</Text>
                      <View style={[styles.espaco]}></View>
                      <Text style={[styles.width100]}>PROCESSO:&nbsp;
                        {
                          data.listaPerguntasRespostas.filter(p => p.pergunta == 'Número de Processo').length == 1 ?
                            data.listaPerguntasRespostas.filter(p => p.pergunta == 'Número de Processo')[0].resposta
                            :
                            ''
                        }

                      </Text>
                    </View>
                    <View style={[styles.espaco]}></View>
                  </View>
                  <View>
                    <View style={styles.row} break>
                      <Text style={styles.width100}>
                        {
                          data.listaPerguntasRespostas.filter(p => p.pergunta == 'Assunto').length == 1 ?
                            'Assunto: ' + data.listaPerguntasRespostas.filter(p => p.pergunta == 'Assunto')[0].resposta
                            :
                            ''
                        }
                      </Text>
                    </View>
                    <View style={[styles.linha]}></View>
                  </View>
                </View>

                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>

                <View style={[styles.rowCabecalho]} >
                  <Text style={styles.linhaCompleta}>
                    {
                      data.listaPerguntasRespostas.filter(p => p.pergunta == 'Memorando').length == 1 ?
                        data.listaPerguntasRespostas.filter(p => p.pergunta == 'Memorando')[0].resposta
                        :
                        ''
                    }
                  </Text>
                </View>

                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>

                {
                  data.listaPerguntasRespostas.filter(p => p.pergunta == 'Memorando').length == 1 && 
                  data.listaPerguntasRespostas.filter(p => p.pergunta == 'Memorando')[0].resposta.length < 200 ?
                    <View style={[styles.espaco]}></View>
                    :
                    <View style={[styles.espacoGrande]}>
                      <Text style={{color:'white'}}>.</Text>
                    </View>
                }
                <View style={[styles.alinharBottomSemPadding]} wrap={false}>
                  <View style={[styles.espaco]}></View>
                  <View style={styles.bottom75}>
                    <View style={[styles.rowCabecalho]}>
                      <Text style={[styles.linhaSemiCompleta, styles.marginLeft20]}>
                        Atenciosamente,
                      </Text>
                    </View>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.rowCabecalho, styles.marginRight100]}>
                      <Text style={styles.comecoMaior}></Text>
                      <Text style={[styles.linhaCompleta, styles.meio]}>
                        {data.autorizador}
                      </Text>
                      <Text style={styles.fim}></Text>
                    </View>
                    <View style={[styles.rowCabecalho, styles.marginRight100]} >
                      <Text style={styles.comecoMaior}></Text>
                      <Text style={[styles.bold, styles.meio]}>
                        Coordenador da COEIP
                      </Text>
                      <Text style={styles.fim}></Text>
                    </View>
                  </View>
                  <View style={[styles.alinharBottom, styles.paddingRight50]}>
                    <Image style={styles.rodapeDiversosCI} src={rodape} />
                  </View>
                </View>
              </View>
            </Page>
            :
            <Page size="A4" style={styles.page}>
              <View style={[styles.section]}>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.comecoMaior}></Text>
                  <Text style={[styles.meio]}>
                    <Image style={styles.logo} src={cabecalho} />
                  </Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.comecoMaior}></Text>
                  <Text style={[styles.bold, styles.meio, styles.top20]}>
                    Conservação e Serviços Públicos
                  </Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.bold}>
                    OFÍCIO Nº: {data.numeroOficio}
                  </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={[styles.fim, styles.bold]}>
                    Fortaleza, {moment(moment((new Date(data.dataSolicitacao)))).format('LL')}
                  </Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.rowCabecalho]} >
                  <Text style={styles.linhaMetadeInicio}>
                    Sr(a): {data.destinatario}
                  </Text>
                  <Text style={styles.meio}></Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho]} >
                  <Text style={styles.linhaMetadeInicio}>
                    {data.cargo}
                  </Text>
                  <Text style={styles.meio}></Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.linhaCompleta}>
                    {data.endereco}
                  </Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                {
                  data.servico == true || data.fraseSolicitante == true ?
                    <View style={[styles.rowCabecalho, styles.bold]} >
                      <Text style={[styles.linhaCompleta]}>
                        Prezado(a) Senhor(a),
                      </Text>
                    </View>
                    :
                    <View></View>
                }
                {
                  data.fraseSolicitante == true ?
                    <View>
                      <View style={[styles.rowCabecalho, styles.bold]} >
                        <Text style={[styles.linhaCompleta]}>
                          Solicitamos V.Sa. atender solicitação do(a) SCSP (17.524.445/0001-73),
                          para realização do Evento abaixo discriminado, de acordo com o Art. 9º da
                          resolução da ANEEL 0456/00. PROCESSO: {data.numeroProcesso}.
                        </Text>
                      </View>
                      <View style={[styles.espaco]}></View>
                      <View style={[styles.espaco]}></View>
                    </View>
                    :
                    data.servico == true || data.numeroKit != '' ?
                      <View>
                        <View style={[styles.rowCabecalho, styles.bold]} >
                          <Text style={[styles.linhaCompleta]}>
                            Solicitamos V.Sa. atender solicitação do(a) SCSP (17.524.445/0001-73),
                            com a instalação de {data.numeroKit} kit's para eventos, conforme solicitação em anexo. PROCESSO: {data.numeroProcesso}.
                          </Text>
                        </View>
                        <View style={[styles.espaco]}></View>
                        <View style={[styles.espaco]}></View>
                      </View>
                      :
                      <View></View>
                }
                {
                  data.listaPerguntasRespostas != null && data.listaPerguntasRespostas.length > 0 ?

                    data.listaPerguntasRespostas.map((pr, i) => (

                      pr.pergunta == '' ?
                        <View></View>
                        :
                        pr.numeracao ?
                          pr.mostrarPergunta == true ?
                            pr.boldCompleto ?
                              <View>
                                <View key={i} style={[styles.rowCabecalho, styles.bold]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {i + 1}. {pr.pergunta} : {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                              :
                              <View>
                                <View key={i} style={[styles.rowCabecalho]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {i + 1}. {pr.pergunta} : {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                            :
                            pr.boldCompleto ?
                              <View>
                                <View key={i} style={[styles.rowCabecalho, styles.bold]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                              :
                              <View>
                                <View key={i} style={[styles.rowCabecalho]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                          :
                          pr.mostrarPergunta == true ?
                            pr.boldCompleto ?
                              <View>
                                <View key={i} style={[styles.rowCabecalho, styles.bold]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {pr.pergunta} : {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                              :
                              pr.boldPergunta ?
                                <View>
                                  <View key={i} style={[styles.rowCabecalho]} >
                                    <Text style={[styles.linhaCompleta]}>
                                      <Text style={[styles.bold]}>
                                        {pr.pergunta} :
                                      </Text>
                                      <Text>
                                        &nbsp; {pr.resposta}
                                      </Text>
                                    </Text>
                                  </View>
                                  <View style={[styles.espaco]}></View>
                                  <View style={[styles.espaco]}></View>
                                </View>
                                :
                                <View>
                                  <View key={i} style={[styles.rowCabecalho]} >
                                    <Text style={[styles.linhaCompleta]}>
                                      {pr.pergunta} : {pr.resposta}
                                    </Text>
                                  </View>
                                  <View style={[styles.espaco]}></View>
                                  <View style={[styles.espaco]}></View>
                                </View>
                            :
                            pr.boldCompleto ?
                              <View>
                                <View key={i} style={[styles.rowCabecalho, styles.bold]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                              :
                              <View>
                                <View key={i} style={[styles.rowCabecalho]} >
                                  <Text style={[styles.linhaCompleta]}>
                                    {pr.resposta}
                                  </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                              </View>
                    ))

                    :
                    <View style={[styles.espaco]}></View>
                }
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.rowCabecalho]} >
                  <Text style={[styles.linhaSemiCompleta, styles.marginLeft20]}>
                    Atenciosamente,
                  </Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.rowCabecalho]} >
                  <Text style={styles.comecoMaior}></Text>
                  <Text style={[styles.linhaCompleta, styles.meio]}>
                    {data.autorizador}
                  </Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho]} >
                  <Text style={styles.comecoMaior}></Text>
                  <Text style={[styles.bold, styles.meio]}>
                    Coordenador da COEIP
                  </Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
              </View>
              <View style={styles.alinharBottom}>
                <Image style={styles.rodape} src={rodape} />
              </View>
            </Page>
          :

          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>
                ERRO AO GERAR PDF
              </Text>
            </View>
          </Page>

      }
    </Document >
  )
}