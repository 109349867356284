export function ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(id) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse(listaMedicaoAutorizacaoFornecimentoDocumentoArquivo) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_RESPONSE',
    listaMedicaoAutorizacaoFornecimentoDocumentoArquivo
  }
}

export function InserirMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(data, id) {
  return {
    type: 'INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    data,
    id
  }
}

export function AlterarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(data, id) {
  return {
    type: 'ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    data,
    id
  }
}

export function ExcluirMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(id, idMedicao) {
  return {
    type: 'EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id,
    idMedicao
  }
}

export function BaixarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(id) {
  return {
    type: 'BAIXAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}