import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import { ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest,
    CarregarImagemRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest,
    BaixarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest } from "../../store/modules/regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/actions";
import { toast } from "react-toastify";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalRegFotoUltrapassarSubitem({ showModal, onHide, idAutorizacaoFornecimentoSubitem, idAutorizacaoFornecimentoSubitemMedicao, data }) {

    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaRegFotoSubitemAutorizacaoMedicaoUltrapassar = useSelector(state => state.regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem.listaCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem);

    const [selectedRow, setSelectedRow] = useState(null);
    const [showFoto, setShowFoto] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFotoClose = () => setShowFoto(false);
    const handleFotoShow = () => setShowFoto(true);

    useEffect(() => {

        moment.locale('pt-br');
        if (data != undefined && data != '') {
            dispatch(ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(idAutorizacaoFornecimentoSubitem, idAutorizacaoFornecimentoSubitemMedicao));
        }

    }, [data])

    function BaixarRegistroFotografico(idRegistroFotografico) {
        dispatch(BaixarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(idRegistroFotografico));
    }

    function VisualizarRegistroFotografico(linha) {
        dispatch(CarregarImagemRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(linha.id));
        handleFotoShow();
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                data={data}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Foto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Col md={12}>
                            {
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title=''
                                    columns={[
                                        {
                                            title: "Número do Registro", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                                width: '10%',
                                            }
                                        },
                                        {
                                            title: "Nome", field: 'nomeRegistroFotografico', cellStyle: {
                                                width: '22.5%'
                                            }
                                        },
                                        {
                                            title: "Usuário", field: 'usuario', editable: 'never', cellStyle: {
                                                width: '22.5%'
                                            }
                                        },
                                        {
                                            title: "Data", field: 'dataInclusao', editable: 'never', cellStyle: {
                                                width: '22.5%'
                                            }
                                        },
                                        {
                                            title: "Observação", field: 'observacao', cellStyle: {
                                                width: '22.5%'
                                            },
                                            render: rowData => rowData.observacao == '' ?
                                                <div className="spanVazio"></div>
                                                : rowData.observacao
                                        },
                                        /*{
                                            title: "Medição Excedendo o Subitem", field: 'medicao', cellStyle: {
                                                width: '22.5%'
                                            }
                                        }*/
                                    ]}
                                    data={
                                        listaRegFotoSubitemAutorizacaoMedicaoUltrapassar ?
                                        listaRegFotoSubitemAutorizacaoMedicaoUltrapassar.map(lista => {
                                                return {
                                                    id: lista.id,
                                                    idAutorizacaoFornecimentoSubitemCusto: lista.idAutorizacaoFornecimentoSubitemCusto,
                                                    idAutorizacaoFornecimentoSubitemCustoMedicao: lista.idAutorizacaoFornecimentoSubitemCustoMedicao,
                                                    nomeRegistroFotografico: lista.nomeRegistroFotografico,
                                                    descricaoExtensaoArquivo: lista.descricaoExtensaoArquivo,
                                                    usuario: lista.usuario.nome,
                                                    dataInclusao: moment(lista.dataInclusao).format('L LT'),
                                                    observacao: lista.observacao,
                                                    idUsuario: lista.usuario.nome,
                                                    //medicao: lista.autorizacaoFornecimentoSubitemCustoMedicao.idMedicaoAutorizacaoFornecimento
                                                }
                                            })
                                            :
                                            []
                                    }
                                    options={{
                                        minBodyHeight: 500,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: 'visibility',
                                            onClick: (event, rowData) => { VisualizarRegistroFotografico(rowData) }
                                        }),
                                        {
                                            icon: 'download',
                                            onClick: (event, rowData) => BaixarRegistroFotografico(rowData.id)
                                        }
                                    ]}
                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                            },
                                            deleteTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                            }
                        </Col>
                    </div>

                    <Modal show={showFoto} onHide={handleFotoClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formFoto">
                                        <div id="divImagemFotoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem">
                                        </div>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Modal.Body>
                    </Modal>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
