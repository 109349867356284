export function ListarDashboardSolicitacaoStatusRequest(dataInicio, dataFinal, visualizarTudoSolicitacao) {
    return {
        type: 'LISTAR_DASHBOARD_SOLICITACAO_STATUS_REQUEST',
        dataInicio, dataFinal, visualizarTudoSolicitacao
    }
}

export function ListarDashboardSolicitacaoStatusResponse(listaDashboardSolicitacaoStatus) {
    return {
        type: 'LISTAR_DASHBOARD_SOLICITACAO_STATUS_RESPONSE',
        listaDashboardSolicitacaoStatus
    }
}

export function ListarDashboardMedicaoStatusRequest(dataInicio, dataFinal, visualizarTudoMedicao) {
    return {
        type: 'LISTAR_DASHBOARD_MEDICAO_STATUS_REQUEST',
        dataInicio, dataFinal, visualizarTudoMedicao
    }
}

export function ListarDashboardMedicaoStatusResponse(listaDashboardMedicaoStatus) {
    return {
        type: 'LISTAR_DASHBOARD_MEDICAO_STATUS_RESPONSE',
        listaDashboardMedicaoStatus
    }
}

export function ListarDashboardAutorizacaoPorRegionalRequest() {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_POR_REGIONAL_REQUEST'
    }
}

export function ListarDashboardAutorizacaoPorRegionalResponse(listaDashboardAutorizacaoPorRegional) {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_POR_REGIONAL_RESPONSE',
        listaDashboardAutorizacaoPorRegional
    }
}

export function ListarDashboardAutorizacaoFinalizadaPorMesRequest() {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_FINALIZADA_POR_MES_REQUEST'
    }
}

export function ListarDashboardAutorizacaoFinalizadaPorMesResponse(listaDashboardAutorizacaoFinalizadaPorMes) {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_FINALIZADA_POR_MES_RESPONSE',
        listaDashboardAutorizacaoFinalizadaPorMes
    }
}

export function ListarDashboardAutorizacaoValorPorMesRequest() {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_VALOR_POR_MES_REQUEST'
    }
}

export function ListarDashboardAutorizacaoValorPorMesResponse(listaDashboardAutorizacaoValorPorMes) {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_VALOR_POR_MES_RESPONSE',
        listaDashboardAutorizacaoValorPorMes
    }
}

export function ListarDashboardValorContratoPorCategoriaRequest() {
    return {
        type: 'LISTAR_DASHBOARD_VALOR_CONTRATO_POR_CATEGORIA_REQUEST'
    }
}

export function ListarDashboardValorContratoPorCategoriaResponse(listaDashboardValorContratoPorCategoria) {
    return {
        type: 'LISTAR_DASHBOARD_VALOR_CONTRATO_POR_CATEGORIA_RESPONSE',
        listaDashboardValorContratoPorCategoria
    }
}

export function ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoRequest() {
    return {
        type: 'LISTAR_DASHBOARD_CATEGORIA_GASTA_POR_AUTORIZACAO_FORNECIMENTO_REQUEST'
    }
}

export function ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoResponse(listaDashboardCategoriaGastaPorAutorizacaoFornecimento) {
    return {
        type: 'LISTAR_DASHBOARD_CATEGORIA_GASTA_POR_AUTORIZACAO_FORNECIMENTO_RESPONSE',
        listaDashboardCategoriaGastaPorAutorizacaoFornecimento
    }
}

export function ListarDashboardAutorizacaoStatusRequest(inicio, final, visualizarTudoAutorizacao) {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_STATUS_REQUEST',
        inicio, final, visualizarTudoAutorizacao
    }
}

export function ListarDashboardAutorizacaoStatusResponse(listaDashboardAutorizacaoStatus) {
    return {
        type: 'LISTAR_DASHBOARD_AUTORIZACAO_STATUS_RESPONSE',
        listaDashboardAutorizacaoStatus
    }
}

export function BaixarRelatorioDashboardAutorizacaoFinalizadaPorMesRequest() {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_AUTORIZACAO_FINALIZADA_POR_MES_REQUEST'
    }
}

export function BaixarRelatorioDashboardAutorizacaoPorRegionalRequest() {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_AUTORIZACAO_POR_REGIONAL_REQUEST'
    }
}

export function BaixarRelatorioDashboardValorTotalContratoPorCategoriaRequest() {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_VALOR_TOTAL_CONTRATO_POR_CATEGORIA_REQUEST'
    }
}

export function BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimentoRequest() {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_VALOR_TOTAL_CATEGORIA_GASTA_POR_AUTORIZACAO_FORNECIMENTO_REQUEST'
    }
}

export function BaixarRelatorioDashboardStatusSolicitacaoFornecimentoRequest() {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_STATUS_SOLICITACAO_FORNECIMENTO_REQUEST'
    }
}

export function ListarDashboardObrasIntervencoesPorMesRequest(ano, visualizarTudo) {
    return {
        type: 'LISTAR_DASHBOARD_OBRAS_INTERVENCOES_POR_MES_REQUEST',
        ano, visualizarTudo
    }
}

export function ListarDashboardObrasIntervencoesPorMesResponse(listaDashboardObrasIntervencoesPorMes) {
    return {
        type: 'LISTAR_DASHBOARD_OBRAS_INTERVENCOES_POR_MES_RESPONSE',
        listaDashboardObrasIntervencoesPorMes
    }
}

export function BaixarRelatorioDashboardObrasIntervencoesPorMesRequest(ano, visualizarTudo) {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_OBRAS_INTERVENCOES_POR_MES_REQUEST',
        ano, visualizarTudo
    }
}

export function ListarDashboardManutencoesPorMesRequest(ano, visualizarTudo) {
    return {
        type: 'LISTAR_DASHBOARD_MANUTENCOES_POR_MES_REQUEST',
        ano, visualizarTudo
    }
}

export function ListarDashboardManutencoesPorMesResponse(listaDashboardManutencoesPorMes) {
    return {
        type: 'LISTAR_DASHBOARD_MANUTENCOES_POR_MES_RESPONSE',
        listaDashboardManutencoesPorMes
    }
}

export function BaixarRelatorioDashboardManutencoesPorMesRequest(ano, visualizarTudo) {
    return {
        type: 'BAIXAR_RELATORIO_DASHBOARD_MANUTENCOES_POR_MES_REQUEST',
        ano, visualizarTudo
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}
