export function ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(id) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse(listaMedicaoAutorizacaoFornecimentoRegistroFotografico) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_RESPONSE',
    listaMedicaoAutorizacaoFornecimentoRegistroFotografico
  }
}

export function InserirMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(data, id) {
  return {
    type: 'INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    data,
    id
  }
}

export function AlterarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(data, id) {
  return {
    type: 'ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    data,
    id
  }
}

export function ExcluirMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(id, idMedicao) {
  return {
    type: 'EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id,
    idMedicao
  }
}

export function BaixarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(id) {
  return {
    type: 'BAIXAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function CarregarImagemMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(id) {
  return {
    type: 'CARREGAR_IMAGEM_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}