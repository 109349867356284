export function ListarFornecedorRequest() {
    return {
        type: 'LISTAR_FORNECEDOR_REQUEST'
    }
}

export function ListarFornecedorResponse(listaFornecedor) {
    return {
        type: 'LISTAR_FORNECEDOR_RESPONSE',
        listaFornecedor
    }
}

export function BuscarFornecedorPorIdRequest(id) {
    return {
        type: 'BUSCAR_FORNECEDOR_POR_ID_REQUEST',
        id
    }
}

export function BuscarFornecedorPorIdResponse(fornecedorCompleto) {
    return {
        type: 'BUSCAR_FORNECEDOR_POR_ID_RESPONSE',
        fornecedorCompleto
    }
}

export function InserirFornecedorRequest(data) {
    return {
        type: 'INSERIR_FORNECEDOR_REQUEST',
        data
    }
}

export function AlterarFornecedorRequest(data) {
    return {
        type: 'ALTERAR_FORNECEDOR_REQUEST',
        data
    }
}

export function ExcluirFornecedorRequest(id) {
    return {
        type: 'EXCLUIR_FORNECEDOR_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}