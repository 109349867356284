import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarTipoSolicitacaoTipoDocumentoArquivoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import { ListarTipoSolicitacaoResponse } from '../tipoSolicitacao/actions';
import history from '../../../services/history';

function* ListarTipoSolicitacaoTipoDocumentoArquivoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/TipoSolicitacaoTipoDocumentoArquivo`, { headers: headerParams.token });

    yield put(ListarTipoSolicitacaoTipoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));
  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarTipoSolicitacaoTipoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

function* InserirTipoSolicitacaoTipoDocumentoArquivoRequest(model) {

  try {

    // yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/TipoSolicitacaoTipoDocumentoArquivo`, model.data, { headers: headerParams.token });

    // const resultTipoDocumentoArquivo = yield call(api.get, `/api/TipoSolicitacaoTipoDocumentoArquivo`, { headers: headerParams.token });
    // const result = yield call(api.get, `/api/TipoSolicitacao`, { headers: headerParams.token });

    // yield put(ListarTipoSolicitacaoTipoDocumentoArquivoResponse(resultTipoDocumentoArquivo.data.result));
    // yield put(ListarTipoSolicitacaoResponse(result.data.result));

    // yield put(Carregando(true));

    toast.success(`O tipo documento arquivo foi incluido com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarTipoSolicitacaoTipoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/TipoSolicitacaoTipoDocumentoArquivo`, model.data, { headers: headerParams.token });
    yield put(Carregando(true));

    toast.success(`Tipo documento arquivo alterado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

function* ExcluirTipoSolicitacaoTipoDocumentoArquivoRequest(model) {

  try {

    // yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/TipoSolicitacaoTipoDocumentoArquivo/${model.id}`, { headers: headerParams.token });

    toast.success(`O tipo documento arquivo foi excluido com sucesso!`);

    // const result = yield call(api.get, `/api/TipoSolicitacao`, { headers: headerParams.token });
    // yield put(ListarTipoSolicitacaoResponse(result.data.result));

    // const resultTipoDocumentoArquivo = yield call(api.get, `/api/TipoSolicitacaoTipoDocumentoArquivo`, { headers: headerParams.token });
    // yield put(ListarTipoSolicitacaoTipoDocumentoArquivoResponse(resultTipoDocumentoArquivo.data.result));

    // yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

export default all([
  takeLatest('LISTAR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST', ListarTipoSolicitacaoTipoDocumentoArquivoRequest),
  takeLatest('INSERIR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST', InserirTipoSolicitacaoTipoDocumentoArquivoRequest),
  takeLatest('ALTERAR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST', AlterarTipoSolicitacaoTipoDocumentoArquivoRequest),
  takeLatest('EXCLUIR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST', ExcluirTipoSolicitacaoTipoDocumentoArquivoRequest)
])