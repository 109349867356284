export function ListarAssinanteRequest() {
  return {
    type: 'LISTAR_ASSINANTE_REQUEST'
  }
}

export function ListarAssinanteResponse(listaAssinante) {
  return {
    type: 'LISTAR_ASSINANTE_RESPONSE',
    listaAssinante
  }
}

export function InserirAssinanteRequest(data) {
  return{
    type: 'INSERIR_ASSINANTE_REQUEST',
    data
  }
}

export function AlterarAssinanteRequest(data){
  return{
    type: 'ALTERAR_ASSINANTE_REQUEST',
    data
  }
}

export function ExcluirAssinanteRequest(id){
  return{
    type: 'EXCLUIR_ASSINANTE_REQUEST',
    id
  }
}



export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}