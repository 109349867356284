export function ListarEventoAdicionalRequest(idSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_EVENTO_ADICIONAL_REQUEST', idSolicitacaoFornecimento
  }
}

export function ListarEventoAdicionalResponse(listaEventoAdicional) {
  return {
    type: 'LISTAR_EVENTO_ADICIONAL_RESPONSE',
    listaEventoAdicional
  }
}

export function BuscarEventoAdicionalPorIdRequest(id) {
  return {
    type: 'BUSCAR_EVENTO_ADICIONAL_POR_ID_REQUEST', id
  }
}

export function BuscarEventoAdicionalPorIdResponse(eventoAdicionalCompleto) {
  return {
    type: 'BUSCAR_EVENTO_ADICIONAL_POR_ID_RESPONSE',
    eventoAdicionalCompleto
  }
}

export function InserirEventoAdicionalRequest(data, idSolicitacaoFornecimento) {
  return {
    type: 'INSERIR_EVENTO_ADICIONAL_REQUEST',
    data, idSolicitacaoFornecimento
  }
}

export function AlterarEventoAdicionalRequest(data, idSolicitacaoFornecimento) {
  return {
    type: 'ALTERAR_EVENTO_ADICIONAL_REQUEST',
    data, idSolicitacaoFornecimento
  }
}

export function ExcluirEventoAdicionalRequest(id, idSolicitacaoFornecimento) {
  return {
    type: 'EXCLUIR_EVENTO_ADICIONAL_REQUEST',
    id, idSolicitacaoFornecimento
  }
}

export function LimparEventoAdicional() {
  return {
    type: 'LIMPAR_EVENTO_ADICIONAL'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}