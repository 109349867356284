import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from "react-bootstrap";
import { MdGroups } from 'react-icons/md';
import {
    ListarGrupoAcessoRequest, ExcluirGrupoAcessoRequest,
    InserirGrupoAcessoRequest, AlterarGrupoAcessoRequest
} from '../../../store/modules/grupoAcesso/actions';
import { useDispatch, useSelector } from 'react-redux';
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Header from "../../../components/Header/header";
import MaterialTable from 'material-table';
import './listar.css';
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";

import Loading from "../../../components/Loading/loading";

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();

    const listaGrupoAcesso = useSelector(state => state.grupoAcesso.listaGrupoAcesso);
    const carregando = useSelector(state => state.grupoAcesso.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {
        if (token.role.filter(r => r == "GrupoAcessoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {
        dispatch(ListarGrupoAcessoRequest());
    }, [])

    function ValidacoesInserir(grupo) {

        if (grupo.thNomeGrupo == null || grupo.thNomeGrupo == undefined
            || grupo.thNomeGrupo.length > 50 || grupo.thNomeGrupo.trim() == '') {
            toast.error(`Nome do grupo inválido`);
            return false;
        }

        return true;

    }

    function InserirGrupo(grupo) {

        let data = {
            'Id': 0,
            'IdCliente': 1,
            'NomeGrupo': grupo.thNomeGrupo.trim(),
            'Ativo': true
        }

        dispatch(InserirGrupoAcessoRequest(data))
    }

    function AlterarGrupo(grupo) {

        let data = {
            'Id': grupo.thIdGrupo,
            'IdCliente': 1,
            'NomeGrupo': grupo.thNomeGrupo.trim(),
            'Ativo': true
        }

        dispatch(AlterarGrupoAcessoRequest(data))
    }

    function ExcluirGrupo(id) {
        dispatch(ExcluirGrupoAcessoRequest(id));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <span>
                                        <MdGroups size={25} color={"#000"} /> Grupos
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col>
                                        {
                                            carregando ?

                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title=""
                                                    columns={[
                                                        {
                                                            title: 'Número do Grupo', field: 'thIdGrupo', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                width: '20%',
                                                            }
                                                        },
                                                        {
                                                            title: 'Nome', field: 'thNomeGrupo', sorting: true, editPlaceholder: 'Nome', validate: rowData => {
                                                                if (rowData.thNomeGrupo === undefined || rowData.thNomeGrupo.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                }
                                                                else if (rowData.thNomeGrupo.trim().length > 50) {
                                                                    return "O nome não pode conter mais que 50 caracteres"
                                                                }
                                                                return true
                                                            }, cellStyle: { width: '80%' }
                                                        },
                                                    ]}
                                                    data={
                                                        listaGrupoAcesso != undefined ?
                                                            listaGrupoAcesso.map(grupo => {
                                                                return {
                                                                    thIdGrupo: grupo.id,
                                                                    thNomeGrupo: grupo.nomeGrupo,
                                                                }
                                                            })
                                                            :
                                                            []
                                                    }
                                                    editable={{
                                                        onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                if (!ValidacoesInserir(newRow)) {
                                                                    reject();
                                                                }
                                                                else {
                                                                    InserirGrupo(newRow);
                                                                    resolve()
                                                                }
                                                            }, 1000)
                                                        }),

                                                        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                if (!ValidacoesInserir(newRow)) {
                                                                    reject();
                                                                }
                                                                else {
                                                                    AlterarGrupo(newRow);
                                                                    resolve()
                                                                }
                                                            }, 1000)
                                                        }),

                                                        onRowDelete: oldData => new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                ExcluirGrupo(oldData.thIdGrupo);
                                                                resolve()
                                                            }, 1000)
                                                        })
                                                    }}
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        addRowPosition: "first",
                                                        actionsColumnIndex: -1,
                                                        filtering: true,
                                                        rowStyle: rowData => ({
                                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        })

                                                    }}
                                                    actions={[
                                                        {
                                                            icon: 'key',
                                                            tooltip: '',
                                                            onClick: (event, rowData) => { history.push(`/permissoesGrupo/${rowData.thIdGrupo}`); }
                                                        }
                                                    ]}
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                    icons={{
                                                        Add: () =>
                                                            <Button variant="success">
                                                                <span>
                                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                                </span>
                                                            </Button>
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                :
                                                <Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    )
}