export function ListarCompletoSolicitacaoFornecimentoRegistroFotograficoRequest(idSolicitacao) {
  return {
    type: 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    idSolicitacao
  }
}

export function ListarSolicitacaoFornecimentoRegistroFotograficoRequest() {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST'
  }
}

export function ListarSolicitacaoFornecimentoRegistroFotograficoResponse(listaSolicitacaoFornecimentoRegistroFotografico) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_RESPONSE',
    listaSolicitacaoFornecimentoRegistroFotografico
  }
}

export function InserirSolicitacaoFornecimentoRegistroFotograficoRequest(data, idSolicitacao) {
  return {
    type: 'INSERIR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    data, idSolicitacao
  }
}

export function AlterarSolicitacaoFornecimentoRegistroFotograficoRequest(data, idSolicitacao) {
  return {
    type: 'ALTERAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    data, idSolicitacao
  }
}

export function ExcluirSolicitacaoFornecimentoRegistroFotograficoRequest(id, idSolicitacao) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id, idSolicitacao
  }
}

export function BaixarSolicitacaoFornecimentoRegistroFotograficoRequest(id) {
  return {
    type: 'BAIXAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function CarregarImagemSolicitacaoFornecimentoRegistroFotograficoRequest(id) {
  return {
    type: 'CARREGAR_IMAGEM_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}