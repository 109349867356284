export function ListarStatusAutorizacaoFornecimentoRequest() {
    return {
      type: 'LISTAR_STATUS_AUTORIZACAO_FORNECIMENTO_REQUEST'
    }
  }
  
  export function ListarStatusAutorizacaoFornecimentoResponse(listaStatusAutorizacaoFornecimento) {
    return {
      type: 'LISTAR_STATUS_AUTORIZACAO_FORNECIMENTO_RESPONSE',
      listaStatusAutorizacaoFornecimento
    }
  }

  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }