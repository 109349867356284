import produce from 'immer';

const initialState = {
  listaSolicitacaoFornecimentoHistoricoRegFoto: undefined,
  carregando: true
};

export default function solicitacaoFornecimentoHistoricoRegFoto(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_REG_FOTO_RESPONSE':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimentoHistoricoRegFoto = action.listaSolicitacaoFornecimentoHistoricoRegFoto;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });    

    default:
      return state;
  }
}