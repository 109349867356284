export function ListarCategoriaItemSubitemCustoPrimarioRequest() {
  return {
    type: 'LISTAR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST'
  }
}

export function ListarCategoriaItemSubitemCustoPrimarioResponse(listaCategoriaItemSubitemCustoPrimario) {
  return {
    type: 'LISTAR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_RESPONSE',
    listaCategoriaItemSubitemCustoPrimario
  }
}

export function InserirCategoriaItemSubitemCustoPrimarioRequest(data, idCategoriaItem, idContrato) {
  return {
    type: 'INSERIR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST',
    data, idCategoriaItem, idContrato
  }
}

export function AlterarCategoriaItemSubitemCustoPrimarioRequest(data) {
  return {
    type: 'ALTERAR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST',
    data
  }
}

export function ExcluirCategoriaItemSubitemCustoPrimarioRequest(id, idCategoriaItem) {
  return {
    type: 'EXCLUIR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST',
    id, idCategoriaItem
  }
}

export function ListarCompletoCategoriaItemSubitemCustoPrimarioRequest(data) {
  return {
    type: 'LISTAR_COMPLETO_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST', data
  }
}

export function ListarCompletoCategoriaItemSubitemCustoPrimarioResponse(listaCompletaCategoriaItemSubitemCustoPrimario) {
  return {
    type: 'LISTAR_COMPLETO_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_RESPONSE',
    listaCompletaCategoriaItemSubitemCustoPrimario
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}