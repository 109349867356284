import produce from 'immer';

const token = JSON.parse(localStorage.getItem("token"));

const initialState = token
  ? { isLoggedIn: true, token, usuario: null, listaUsuario: undefined, carregando: true, senha:null }
  : { isLoggedIn: false, token: null, usuario: null, listaUsuario: undefined, carregando: true, senha:null };

export default function usuario(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_USUARIO_RESPONSE':
      return produce(state, draft => {
        draft.listaUsuario = action.listaUsuario;
      });

    case 'BUSCAR_USUARIO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.usuario = action.usuario;
      });

    case 'LOGIN_USUARIO_RESPONSE':
      return produce(state, draft => {
        localStorage.setItem('token', JSON.stringify(action.usuario));
      });

    case 'LOGIN_USUARIO_FAIL':
      return produce(state => {
        localStorage.removeItem('token');
      });
      
    case 'LIMPAR_USUARIO_REQUEST':
      return produce(state, draft => {
        draft.usuario = null;
        state = null;
      });

      case 'INSERIR_NOVA_SENHA_RESPONSE':
        return produce(state, draft => {
          draft.senha = action.senha ;
        });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}