import { Row, Button, Modal, ModalFooter } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { InserirCategoriaItemSubitemCustoPrimarioRequest } from "../../store/modules/categoriaItemSubitemCustoPrimario/actions";
import { ListarSubitemCustoCompletoRequest } from "../../store/modules/subitemCusto/actions";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalInserirSubitemCategoriaItem({ show, onHide, idCategoriaItem, idContrato }) {

    const { id } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const empenhoCompleto = useSelector(state => state.empenho.empenhoCompleto);
    const listaSubitemCusto = useSelector(state => state.subitemCusto.listaCompletaSubitemCusto);

    const [listaSubitemData, setlistaSubitemData] = useState('');
    const [listaSubitem, setListaSubitem] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {

        moment.locale('pt-br');

        if (idContrato != '' && idContrato != undefined) {
            dispatch(ListarSubitemCustoCompletoRequest({
                'IdContrato': idContrato
            }));
        }

    }, [idContrato])

    useEffect(() => {

        if (listaSubitemCusto != undefined) {
            constroiData();
        }

    }, [listaSubitemCusto])

    function constroiData() {

        let subitemCompleto = [];

        if (listaSubitemCusto != undefined) {
            listaSubitemCusto.map((subitemCusto) => {
                subitemCompleto.push({
                    id: subitemCusto.id,
                    codigoInterno: subitemCusto.descricaoCodigoInterno,
                    descricao: subitemCusto.descricao,
                    valorUnitario: subitemCusto.valorUnitario,
                    idSubitemCustoPrimario: subitemCusto.idSubitemCustoPrimario
                });
            })
        }

        setlistaSubitemData(subitemCompleto);
    }

    function Selecionar(data) {
        setListaSubitem(data);
    }

    function InserirCategoriaItemSubitemCustoPrimario() {

        var listaSubitemInsercao = listaSubitem.map((subitem) => {
            return {
                Id: 0,
                IdSubitemCusto: subitem.id,
                IdSubitemCustoPrimario: subitem.idSubitemCustoPrimario,
                IdCategoriaItem: idCategoriaItem
            }
        })

        dispatch(InserirCategoriaItemSubitemCustoPrimarioRequest(listaSubitemInsercao, idCategoriaItem, idContrato));
        onHide(true);
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MaterialTable
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        title=''
                        columns={[
                            {
                                title: "Código Interno", field: 'codigoInterno', cellStyle: {
                                    width: '20%'
                                },
                                editable: 'never'
                            },
                            {
                                title: "Descrição", field: 'descricao', cellStyle: {
                                    width: '60%'
                                },
                                editable: 'never'
                            },
                            {
                                title: "Valor Unitário", field: 'valorUnitario', cellStyle: {
                                    width: '20%'
                                }, editable: 'never',
                                render: rowData =>
                                    rowData.valorUnitario === null ?
                                        null
                                        :
                                        rowData.valorUnitario.toLocaleString('pt-br', {
                                            style: 'currency', currency: 'BRL',
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })
                            }
                        ]}
                        data={listaSubitemData}
                        options={{
                            minBodyHeight: 500,
                            paging: true,
                            pageSize: 10, // Início
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [10, 50, 200, 300],
                            filterRowStyle: {
                                backgroundColor: "#FAFAFA"
                            },
                            headerStyle: {
                                backgroundColor: '#454545',
                                color: '#FFF',
                                fontWeight: "bold"
                            },
                            actionsColumnIndex: -1,
                            filtering: true,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            }),
                            selection: true
                        }}
                        onSelectionChange={
                            rowData =>
                                Selecionar(rowData)
                        }
                        localization={{
                            header: {
                                actions: <span className="classHeaderTabela">Ações</span>
                            },
                            body: {
                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                editRow: {
                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                    saveTooltip: '',
                                    cancelTooltip: '',
                                },
                                deleteTooltip: '',
                                editTooltip: '',
                                addTooltip: '',
                            },
                            toolbar: {
                                searchTooltip: '',
                                searchPlaceholder: 'Pesquisar',
                                exportTitle: "",
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                nRowsSelected: '{0} linha(s) selecionada'
                            },
                            pagination: {
                                labelRowsSelect: 'linhas',
                                labelDisplayedRows: '{count} de {from}-{to}',
                                firstTooltip: '',
                                previousTooltip: '',
                                nextTooltip: '',
                                lastTooltip: ''
                            },
                            grouping: {
                                placeholder: 'Arraste a coluna aqui para agrupar',
                                groupedBy: 'Agrupado por:'
                            }
                        }}
                    />
                </Modal.Body>
                <ModalFooter>
                    <Row className="mb-3">
                        <Button
                            variant="success"
                            type="submit"
                            onClick={() => InserirCategoriaItemSubitemCustoPrimario()}
                        >Adicionar Itens</Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );
}