import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { ListarFornecedorContatoResponse, BuscarFornecedorContatoPorIdResponse, Carregando } from './actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarFornecedorContatoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FornecedorContato`, { headers: headerParams.token });

    yield put(ListarFornecedorContatoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarFornecedorContatoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* ListarCompletoFornecedorContatoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/FornecedorContato/listarCompleto`,
      { idFornecedor: parseInt(model.idFornecedor) }, { headers: headerParams.token });

    yield put(ListarFornecedorContatoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }  

    yield put(ListarFornecedorContatoResponse([]));
    yield put(Carregando(true)); 
  }
}

function* BuscarFornecedorContatoPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FornecedorContato/${model.id}`, { headers: headerParams.token });

    yield put(BuscarFornecedorContatoPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(BuscarFornecedorContatoPorIdResponse(null));
    yield put(Carregando(true));
    
  }
}

function* InserirFornecedorContatoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/FornecedorContato`, model.data, { headers: headerParams.token });
    
    const result = yield call(api.post, `/api/FornecedorContato/listarCompleto`,
      { idFornecedor: parseInt(model.data.IdFornecedor) }, { headers: headerParams.token });

    yield put(ListarFornecedorContatoResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Contato foi cadastrada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarFornecedorContatoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/FornecedorContato`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/FornecedorContato/listarCompleto`,
      { idFornecedor: model.data.IdFornecedor }, { headers: headerParams.token });

    yield put(ListarFornecedorContatoResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Contato alterado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ExcluirFornecedorContatoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/FornecedorContato/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.post, `/api/FornecedorContato/listarCompleto`,
      { idFornecedor: parseInt(model.id) }, { headers: headerParams.token });
    
    yield put(ListarFornecedorContatoResponse(result.data.result));        
    yield put(Carregando(true));    

    toast.success(`O Contato foi excluida com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_FORNECEDOR_CONTATO_REQUEST', ListarFornecedorContatoRequest),
  takeLatest('BUSCAR_FORNECEDOR_CONTATO_POR_ID_REQUEST', BuscarFornecedorContatoPorIdRequest),
  takeLatest('INSERIR_FORNECEDOR_CONTATO_REQUEST', InserirFornecedorContatoRequest),
  takeLatest('ALTERAR_FORNECEDOR_CONTATO_REQUEST', AlterarFornecedorContatoRequest),
  takeLatest('EXCLUIR_FORNECEDOR_CONTATO_REQUEST', ExcluirFornecedorContatoRequest),
  takeLatest('LISTAR_COMPLETO_FORNECEDOR_CONTATO_REQUEST', ListarCompletoFornecedorContatoRequest),
])