import { useEffect, useState } from "react";
import { Button, Alert, Row, Col, Modal, Form, Container } from "react-bootstrap";
import {
    ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest, InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest,
    ImportarSolicitacaoFornecimentoVersaoSubitemCustoExcelRequest, AlterarSolicitacaoFornecimentoVersaoSubitemCustoRequest
} from '../../../store/modules/solicitacaoFornecimentoVersaoSubitemCusto/actions';
import { BuscarContratoVersaoPorIdRequest } from '../../../store/modules/contratoVersao/actions';
import { BuscarSolicitacaoFornecimentoPorIdRequest } from '../../../store/modules/solicitacaoFornecimento/actions';
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { BuscarContratoPorIdRequest } from "../../../store/modules/contrato/actions";
import { ListarStatusSolicitacaoFornecimentoRequest } from '../../../store/modules/statusSolicitacaoFornecimento/actions';
import { DropzoneArea } from 'material-ui-dropzone';
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { InserirSolicitacaoFornecimentoVersaoRequest } from "../../../store/modules/solicitacaoFornecimentoVersao/actions";
import ModalSubitemAutorizacao from "../../../components/ModalSubitemAutorizacao/modalSubitemAutorizacao";
import { BuscarUsuarioPorIdRequest } from "../../../store/modules/usuario/actions";
import Loading from "../../../components/Loading/loading";
import Select from 'react-select';
import MaterialTable from 'material-table';
import './subitem.css';
import * as XLSX from "xlsx";
import 'moment/locale/pt-br';
import moment from 'moment';

export default function Subitem() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const contratoVersao = useSelector(state => state.contratoVersao.contratoVersaoCompleto);
    const listaSolicitacaoFornecimentoVersaoSubitemCusto = useSelector(state => state.solicitacaoFornecimentoVersaoSubitemCusto.listaSolicitacaoFornecimentoVersaoSubitemCusto);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const listaSolicitacaoFornecimentoVersaoStatus = useSelector(state =>
        state.solicitacaoFornecimentoVersaoStatus.listaSolicitacaoFornecimentoVersaoStatus);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const carregando = useSelector(state => state.solicitacaoFornecimentoVersaoSubitemCusto.carregando);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);
    const usuarioCompleto = useSelector(state => state.usuario.usuario);

    const [selectedRow, setSelectedRow] = useState(null);
    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);
    const [showSubitem, setShowSubitem] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [showError, setShowError] = useState(false);
    const [orcamento, setOrcamento] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [observacaoVersao, setObservacaoVersao] = useState('');
    const [validatedObservacaoNovaVersao, setValidatedObservacaoNovaVersao] = useState(false);
    const [valorTotalSubItem, setValorTotalSubItem] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSubitem = () => setShowSubitem(false);
    const handleShowSubitem = () => setShowSubitem(true);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    const handleCloseImport = () => setShowModal(false);
    const handleShowImport = () => setShowModal(true);

    const options = contratoVersao ?
        contratoVersao.subitemCusto.map(subitemUnidade => ({
            value: subitemUnidade.id, label: subitemUnidade.descricaoCodigoInterno + " - " + subitemUnidade.descricao
        }))
        :
        [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setItems(d);
        });

    };

    useEffect(() => {

        moment.locale('pt-br');

        //dispatch(ListarStatusSolicitacaoFornecimentoRequest());
        dispatch(ListarContratoUsuarioRequest());

        if (id != undefined)
            dispatch(ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest(id));

    }, [id])

    useEffect(() => {

        if (solicitacaoFornecimento != null) {
            dispatch(BuscarContratoVersaoPorIdRequest(solicitacaoFornecimento.idContratoVersao));
            dispatch(BuscarContratoPorIdRequest(solicitacaoFornecimento.idContrato));
        }

    }, [solicitacaoFornecimento])

    useEffect(() => {

        if (listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined) {

            var total = 0;
            listaSolicitacaoFornecimentoVersaoSubitemCusto.filter((solicitacao) => solicitacao.idSolicitacaoFornecimentoVersao === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id)
                .map((subItemUnidade) => {

                    total += (subItemUnidade.subitemCusto.valorUnitario * subItemUnidade.quantidade);
                })

            setValorTotalSubItem("R$" + total.toFixed(2));
        }

    }, [listaSolicitacaoFornecimentoVersaoSubitemCusto])

    function ImportarArquivo(e) {

        e.preventDefault();

        var verificacao = true;

        // Verifica se possui número de orçamento
        /*if (solicitacaoFornecimento != null && solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento === '' && orcamento === '') {
            toast.error('Não é possível cadastrar subitens se a solicitação não possuir Número de Orçamento.');
            verificacao = false;
            return;
        }*/

        // Verifica se o usuário tem permissão pra importar subitens
        if (listaContratoUsuario != undefined && contratoCompleto != null && usuario != null
            && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.criaItemSolicitacao === true).length === 0) {
            toast.error('Você não tem permissão para importar itens.');
            verificacao = false;
            return;
        }

        if (usuarioCompleto != null && !usuarioCompleto.usuarioFornecedor) {

            toast.error('Você precisar ser fornecedor para inserir itens.');
            verificacao = false;
            return;
        }

        if (verificacao === true) {
            try {

                if (items.length > 0 && solicitacaoFornecimento !== undefined) {

                    let i = 0;
                    let lista = [];
                    let orcamentos = '';

                    items.forEach(item => {

                        if (i == 1) {
                            if (item.__EMPTY_2 != undefined) {
                                orcamentos = (item.__EMPTY_2)
                            }
                        }

                        if (i > 9) {

                            //EMPTY 8 = valor total / EMPTY 6 = quantidade / EMPTY 1 = item
                            if (item.__EMPTY_6 != undefined && item.__EMPTY_6 != null && item.__EMPTY_6 != '' &&
                                item.__EMPTY_1 != undefined && item.__EMPTY_1 != null && item.__EMPTY_1 != '') {

                                var data = {
                                    'Id': 0,
                                    'IdSolicitacaoFornecimento': id,
                                    'IdSubItemCusto': 0,
                                    'Ativo': true,
                                    'Quantidade': parseFloat(item.__EMPTY_6),
                                    'NumeroSubItem': item.__EMPTY_1,
                                    'ValorSubItem': item.__EMPTY_7
                                }

                                var dadosSolicitacaoFornecimentoVersaoApiModel = {
                                    'Id': 0,
                                    'IdSolicitacaoFornecimento': solicitacaoFornecimento.id,
                                    'Observacao': observacaoVersao,
                                    'NumeroOrcamento': orcamentos,
                                    'DataVersao': moment((new Date())).add('hours', 2),
                                    'IdUsuario': usuario.id,
                                    'SolicitacaoFornecimentoVersaoStatusApiModel': {
                                        'Id': 0,
                                        'IdSolicitacaoFornecimentoVersao': 0,
                                        'IdStatusSolicitacaoFornecimento': 0,
                                        'IdUsuario': usuario.id,
                                        'DataStatus': moment((new Date())).add('hours', 2),
                                        'ObservacaoReprovacao': ''
                                    },
                                    'pageImport': false
                                }

                                //lista.push(data);
                                lista.push({ 'item': data, 'solicitacaoFornecimentoVersao': dadosSolicitacaoFornecimentoVersaoApiModel });
                            }
                        }

                        i++;
                    });

                    if (lista.length > 0) {
                        dispatch(ImportarSolicitacaoFornecimentoVersaoSubitemCustoExcelRequest(lista, id, contratoVersao != null ?
                            contratoVersao.id : null));
                        handleClose();
                        handleCloseImport();
                    }
                    else {
                        toast.error('Não é possível importar Sub Itens se a solicitação não possuir Número de Orçamento.');
                        return;
                    }

                } else {
                    handleShowError();
                    setMessageError("Selecione o Excel antes de Importar!");
                }

            } catch (error) {
                handleShowError();
                setMessageError("CAMPOS IMPORTANTES ESTÃO FALTANDO NO EXCEL, VERIFIQUE NOVAMENTE ANTES DE IMPORTAR");
            }
        }

    }

    function AdicionarSubitem(subitemSolicitacao) {

        var verificacao = true;

        let selectText = '';
        let selectSelecionado = [];

        // Pega o subitem selecionado na tabela
        if (document.getElementById('idSelectSubItem') != null && contratoVersao.subitemCusto != undefined) {
            selectText = document.getElementById('idSelectSubItem').innerText;
            selectSelecionado = contratoVersao.subitemCusto.filter(soli => soli.descricaoCodigoInterno + " - " + soli.descricao == selectText);
        }

        // Verifica se subitem foi selecionado
        if (selectSelecionado.length == 0) {
            toast.error(`Item não selecionado`);
            verificacao = false;
            return;
        }

        // Verifica se subitem ja pertence a lista de subitem da solicitação
        var listaSubItensAtual = listaSolicitacaoFornecimentoVersaoSubitemCusto.filter((solicitacaoSub) =>
            (solicitacaoSub.idSolicitacaoFornecimentoVersao === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id)
            &&
            (solicitacaoSub.idSubitemCusto === parseInt(selectSelecionado[0].id)));

        if (listaSubItensAtual.length > 0) {
            toast.error('Item ja está na lista');
            verificacao = false;
            return;
        }

        // Verifica se tem número de orçamento
        if (solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento === '') {
            toast.error('Não é possível cadastrar itens se a solicitação não possuir Número de Orçamento.');
            verificacao = false;
            return;
        }

        // Verifica se o usuário tem permissão de inserir subitem na solicitação
        if (listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.criaItemSolicitacao === true).length === 0) {

            toast.error('Você não tem permissão de cadastrar itens.');
            verificacao = false;
            return;
        }

        if (isNaN(subitemSolicitacao.quantidade) || subitemSolicitacao.quantidade == undefined
            || subitemSolicitacao.quantidade == null || subitemSolicitacao.quantidade == 0) {
            toast.error(`Quantidade informada inválida`);
            verificacao = false;
            return;
        }

        if (verificacao === true && selectSelecionado.length > 0) {
            let data = {
                'Id': 0,
                'IdSolicitacaoFornecimentoVersao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id,
                'IdSubItemCusto': parseInt(selectSelecionado[0].id),
                'Ativo': subitemSolicitacao.ativo == undefined ? true : subitemSolicitacao.ativo,
                'Quantidade': parseFloat(subitemSolicitacao.quantidade)
            }

            dispatch(InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest(data, id));
        }
    }

    function AlterarSubitem(subitemSolicitacao) {

        var verificacao = true;

        // Verifica se o usuário tem permissao de editar subitens
        if (listaContratoUsuario != undefined && contratoCompleto != null &&
            listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.criaItemSolicitacao === true).length === 0) {

            toast.error('Você não tem permissão para editar itens.');
            verificacao = false;
            return;
        }

        if (isNaN(subitemSolicitacao.quantidade) || subitemSolicitacao.quantidade == undefined
            || subitemSolicitacao.quantidade == null || subitemSolicitacao.quantidade == 0) {
            toast.error(`Quantidade informada inválida`);
            verificacao = false;
            return;
        }

        if (verificacao === true) {
            let data = {
                'Id': subitemSolicitacao.id,
                'IdSolicitacaoFornecimentoVersao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id,
                'IdSubItemCusto': parseInt(subitemSolicitacao.subitem),
                'Ativo': subitemSolicitacao.ativo != undefined ? subitemSolicitacao.ativo : false,
                'Quantidade': parseFloat(subitemSolicitacao.quantidade)
            }

            dispatch(AlterarSolicitacaoFornecimentoVersaoSubitemCustoRequest(data, id));
        }
    }

    function InserirSubitem() {
        // Verifica se o usuário tem permissão de inserir subitem na autorização
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.criaItemSolicitacao === true).length === 0) {

            toast.error('Você não tem permissão de cadastrar itens.');
            return;
        }

        if (usuarioCompleto != null && !usuarioCompleto.usuarioFornecedor) {

            toast.error('Você precisar ser fornecedor para inserir itens.');
            return;
        }

        handleShowSubitem();
    }

    function CriarNovaVersaoSolicitacao(e) {

        e.preventDefault();

        if (items.length > 0) {

            // Verifica se é a primeira versão da solicitacao, se não for, aparece o modal de nova versão para o usuário confimar
            if (listaSolicitacaoFornecimentoVersaoSubitemCusto.length > 0) {
                handleShowImport();
            } else {
                ImportarArquivo(e);
            }

        } else {
            handleShowError();
            handleCloseImport();
            setMessageError("Selecione o Excel antes de Importar!");
        }
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title={
                                    inserir == true ?
                                        (listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == false &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.subitemSomenteLeitura == false
                                            && usuarioCompleto != null && usuarioCompleto.usuarioFornecedor &&
                                            listaSolicitacaoFornecimentoVersaoStatus[listaSolicitacaoFornecimentoVersaoStatus.length - 1].analiseConcluida == false &&
                                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.statusSolicitacaoFornecimento.solicitaOrcamentoSolicitacao == true).length > 0
                                            ?
                                            <Row>
                                                <Col md='auto'>
                                                    <Button
                                                        variant="success"
                                                        onClick={InserirSubitem}
                                                    >Novo Item
                                                    </Button>
                                                </Col>
                                                <Col md='auto'>
                                                    <Button variant="success" onClick={handleShow}>Importar</Button>
                                                </Col>
                                            </Row>
                                            :
                                            '')
                                        :
                                        ''
                                }
                                columns={[
                                    {
                                        title: 'Código/Descrição Item',
                                        field: 'subitem',
                                        cellStyle: {
                                            width: '23%'
                                        },
                                        filterComponent: (props) => {
                                            return (
                                                <Select
                                                    placeholder="Selecione..."
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    options={options}
                                                    onChange={(e) => {
                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                    }}
                                                />
                                            )
                                        },
                                        editable: 'onAdd',
                                        lookup:
                                            contratoVersao ?
                                                contratoVersao.subitemCusto.map(subitemUnidade => {
                                                    return {
                                                        id: subitemUnidade.id, descricao: subitemUnidade.descricaoCodigoInterno + " - " + subitemUnidade.descricao
                                                    }
                                                }).reduce(function (acc, cur, i) {
                                                    acc[cur.id] = cur.descricao;
                                                    return acc;
                                                }, {})
                                                :
                                                null,
                                        editComponent: (props) => (
                                            <Select id="idSelectSubItem"
                                                options={options}
                                                placeholder="Selecione..."
                                                defaultValue={{
                                                    value: props.value, label: contratoVersao.subitemCusto.filter(soli => soli.id == props.value).length > 0
                                                        ? contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricaoCodigoInterno + " - " + contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricao
                                                        : 'Selecione...'
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: 'Quantidade',
                                        field: 'quantidade',
                                        type: 'numeric',
                                        align: 'left',
                                        cellStyle: {
                                            width: '12%'
                                        },
                                    },
                                    {
                                        title: 'Valor Unitário Item', field: 'valorUnitario', editable: 'never', cellStyle: {
                                            width: '12%',
                                        },
                                        grouping: false,
                                        render: rowData =>
                                            rowData.valorUnitario === null ?
                                                null
                                                :
                                                rowData.valorUnitario.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: 'Valor Total Item', field: 'valorTotalSubitem', editable: 'never', cellStyle: {
                                            width: '12%',
                                        },
                                        grouping: false,
                                        render: rowData =>
                                            rowData.valorTotalSubitem === null ?
                                                null
                                                :
                                                rowData.valorTotalSubitem.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: 'Saldo de Quantidade no Contrato',
                                        field: 'saldoContrato',
                                        cellStyle: {
                                            width: '12%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Saldo de Quantidade (com o valor índice excedente)',
                                        field: 'saldoContratoExcedente',
                                        cellStyle: {
                                            width: '12%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Unidade de Medida',
                                        field: 'unidadeMedida',
                                        cellStyle: {
                                            width: '14%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Ativo',
                                        field: 'ativo',
                                        cellStyle: {
                                            width: '5%'
                                        },
                                        customFilterAndSearch: (term, rowData) => term == rowData.ativo.toString(),
                                        filterComponent: (props) => {
                                            return (
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    options={[{ value: 'ativado', label: 'Ativado' },
                                                    { value: 'desativado', label: 'Desativado' },]}
                                                    onChange={(e) => {
                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                            e != null && e.value == 'desativado' ? 'false' : null)
                                                    }}
                                                />
                                            )
                                        },
                                        editComponent: (props) => (
                                            <input
                                                type="checkbox"
                                                checked={props.value == false ? props.value : true}
                                                onChange={(e) => props.onChange(e.target.checked)}
                                            />
                                        ),
                                        render: rowData => (<input type="checkbox" checked={rowData.ativo} onChange={() => { }} />)
                                    }
                                ]}
                                data={
                                    solicitacaoFornecimento != null && solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null &&
                                        listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined ?
                                        listaSolicitacaoFornecimentoVersaoSubitemCusto
                                            .filter((solicitacao) => solicitacao.idSolicitacaoFornecimentoVersao === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id)
                                            .map((subItemUnidade) => {

                                                return {
                                                    id: subItemUnidade.id,
                                                    codigoInterno: subItemUnidade.subitemCusto.descricaoCodigoInterno,
                                                    subitem: subItemUnidade.idSubitemCusto,
                                                    quantidade: subItemUnidade.quantidade,
                                                    unidadeMedida: subItemUnidade.subitemCusto.unidadeMedida.descricao,
                                                    ativo: subItemUnidade.ativo,
                                                    saldoContrato: subItemUnidade.saldo,
                                                    saldoContratoExcedente: subItemUnidade.saldoContratoExcedente,
                                                    valorUnitario: subItemUnidade.subitemCusto.valorUnitario,
                                                    valorTotalSubitem: subItemUnidade.subitemCusto.valorUnitario * subItemUnidade.quantidade
                                                }
                                            })
                                        :
                                        []
                                }
                                editable={{
                                    /*onRowAdd:
                                        id != undefined && listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == false &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.subitemSomenteLeitura == false &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusReprovado == false
                                            ?
                                            newData =>
                                                new Promise((resolve, reject) => {
                                                    inserir == true ?
                                                        setTimeout(() => {
                                                            AdicionarSubitem(newData);
                                                            resolve();
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                            : null,*/
                                    onRowUpdate:
                                        id != undefined && listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == false &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.subitemSomenteLeitura == false &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusReprovado == false && usuarioCompleto != null && usuarioCompleto.usuarioFornecedor &&
                                            listaSolicitacaoFornecimentoVersaoStatus[listaSolicitacaoFornecimentoVersaoStatus.length - 1].analiseConcluida == false
                                            ?
                                            (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    alterar == true ?
                                                        setTimeout(() => {
                                                            AlterarSubitem(newData);
                                                            resolve();
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                            : null
                                }}
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold",
                                        lineBreak: "auto",
                                        fontSize: "13px"
                                    },
                                    actionsColumnIndex: -1,
                                    addRowPosition: "first",
                                    filtering: true,
                                    rowStyle:
                                        rowData => ({
                                            backgroundColor:
                                                rowData.quantidade > rowData.saldoContratoExcedente && listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                                    listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length == 0 && rowData.ativo ? '#F7D1D1' :
                                                    (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'error',
                                        tooltip: <span style={{ fontSize: '1.5em' }}>
                                            {'Esse item esta passando ' + (rowData.quantidade - rowData.saldoContratoExcedente) + ' unidades da quantidade excedente do contrato.'}
                                        </span>,
                                        hidden: rowData.quantidade - rowData.saldoContratoExcedente <= 0 || rowData.ativo == false || listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0 ? true : false
                                    })
                                ]}
                                style={{
                                    fontSize: "13px",
                                }}
                                localization={{
                                    header: {
                                        actions: <span className="classHeaderTabela">Ações</span>
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                            saveTooltip: '',
                                            cancelTooltip: '',
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: '',
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                            :
                            <Loading />
                    }
                </Col>
                <Col md={12}>
                    <br />
                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                        <Form.Label>Valor total do orçamento</Form.Label>
                        <Form.Control
                            type="text"
                            value={valorTotalSubItem}
                            disabled
                        />
                    </Form.Group>
                    <br />

                    {
                        id != undefined &&
                            listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0
                            ?
                            <Col md={12}>
                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                    A Solicitação está Cancelada!
                                </Alert>
                            </Col>
                            :
                            listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`success`} style={{ width: '100%' }}>
                                        A Solicitação está Finalizada!
                                    </Alert>
                                </Col>
                                :
                                listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null &&
                                    listaSolicitacaoFornecimentoVersaoStatus
                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == true ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            Status atual desta solicitação só permite leitura!
                                        </Alert>
                                    </Col>
                                    :

                                    listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null &&
                                        listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.subitemSomenteLeitura == true &&
                                        listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusReprovado == false ?
                                        <Col md={12}>
                                            <Alert variant={`success`} style={{ width: '100%' }}>
                                                Solicitação está em análise!
                                            </Alert>
                                        </Col>
                                        :
                                        listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.subitemSomenteLeitura == true &&
                                            listaSolicitacaoFornecimentoVersaoStatus
                                            [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusReprovado == true ?
                                            <Col md={12}>
                                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                                    Solicitação foi reprovada!
                                                </Alert>
                                            </Col>
                                            :
                                            ''
                    }
                </Col>
            </div>

            <Modal
                show={showError}
                onHide={handleCloseError}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseError}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Importar Itens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formTipoDocumentoArquivo">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[]}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                readExcel(files[0])
                                            } else {
                                                setItems([])
                                            }
                                        }}
                                        filesLimit={1}
                                        acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 1 arquivo`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={CriarNovaVersaoSolicitacao}>Importar Documento</Button>
                            :
                            ''
                    }
                </Modal.Footer>
            </Modal>
            <ModalSubitemAutorizacao
                show={showSubitem}
                onHide={handleCloseSubitem}
                idContratoVersao={contratoVersao != null ?
                    contratoVersao.id : null}
                idSolicitacaoFornecimento={id}
                numeroOrcamentoSolicitacao={solicitacaoFornecimento != null && solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null
                    ? solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento : ''}
            />


            <Modal show={showModal} onHide={handleCloseImport} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={12}>
                                Ao importar novos itens com um novo número de orçamento, todos os itens antigos serão excluidos. Deseja continuar?
                            </Col>
                            {
                                /*
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formDescricao">
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={observacaoVersao}
                                            onChange={e => setObservacaoVersao(e.target.value)}
                                            required
                                            as="textarea"
                                            rows="5"
                                            maxLength="150"
                                            isInvalid={validatedObservacaoNovaVersao && observacaoVersao === "" ? true : false}
                                            isValid={validatedObservacaoNovaVersao && observacaoVersao !== "" ? true : false}
                                        />
                                        <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                */
                            }
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={ImportarArquivo}>
                                Sim
                            </Button>
                            :
                            ''

                    }
                    {
                        alterar == true ?
                            <Button variant="danger" onClick={handleCloseImport}>
                                Não
                            </Button>
                            :
                            ''
                    }

                </Modal.Footer>
            </Modal>
        </>
    )
}