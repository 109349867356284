import { Col, Card, Tabs, Tab } from "react-bootstrap";
import { MdAttachMoney } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Versao from '../Versao/versao';
import Header from "../../../components/Header/header";
import FonteReceita from '../FonteReceita/fonteReceita';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function Abas() {

    const { id } = useParams();

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdAttachMoney size={25} color={"#000"} /> Fonte Receita
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Tabs defaultActiveKey="Fonte" id="tab-fonte" className="mb-3">
                                <Tab eventKey="Fonte" title="Fonte Receita">
                                    <FonteReceita />
                                </Tab>
                                <Tab eventKey="Fonte Versao" title="Fonte Receita Versão" disabled={id != undefined ? false : true}>
                                    <Versao />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </div>

        </>
    );
}