import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarObrasIntervencoesPorMesResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';
import urlBase from '../../../services/rotas';

function* ListarObrasIntervencoesPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/ObrasIntervencoesPorMes`, { headers: headerParams.token });

    yield put(ListarObrasIntervencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ListarObrasIntervencoesPorMesResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* InserirObrasIntervencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      "Lista": model.data[0]
    }

    yield call(api.post, `/api/ObrasIntervencoesPorMes`, data, { headers: headerParams.token });

    toast.success(`As Obras/Intervenções Por Mês foram cadastradas com sucesso!`);

    const result = yield call(api.get, `/api/ObrasIntervencoesPorMes`, { headers: headerParams.token });

    yield put(ListarObrasIntervencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.status == 500 || e.response.data.status == 400) {
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);
    }

    yield put(ListarObrasIntervencoesPorMesResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BaixarPadraoImportacaoObrasIntervencoesPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/ObrasIntervencoesPorMes/BaixarPadraoImportacaoObrasIntervencoesPorMesRequest`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Padrão Importação Obras e Intervenções por Mês'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ExcluirObrasIntervencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/ObrasIntervencoesPorMes/${parseInt(model.ano)}`, { headers: headerParams.token });

    toast.success(`Obras/Intervenções do ano: ${model.ano} foram excluidas!`);

    const result = yield call(api.get, `/api/ObrasIntervencoesPorMes`, { headers: headerParams.token });

    yield put(ListarObrasIntervencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  
  }
}

export default all([
  takeLatest('LISTAR_OBRAS_INTERVENCOES_POR_MES_REQUEST', ListarObrasIntervencoesPorMesRequest),
  takeLatest('INSERIR_OBRAS_INTERVENCOES_POR_MES_REQUEST', InserirObrasIntervencoesPorMesRequest),
  takeLatest('BAIXAR_PADRAO_IMPORTACAO_OBRAS_INTERVENCOES_POR_MES_REQUEST', BaixarPadraoImportacaoObrasIntervencoesPorMesRequest),
  takeLatest('EXCLUIR_OBRAS_INTERVENCOES_POR_MES_REQUEST', ExcluirObrasIntervencoesPorMesRequest)
])