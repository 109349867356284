export function ListarStatusMedicaoAutorizacaoFornecimentoRequest() {
    return {
        type: 'LISTAR_STATUS_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST'
    }
}

export function ListarStatusMedicaoAutorizacaoFornecimentoResponse(listaStatusMedicaoAutorizacaoFornecimento) {
    return {
        type: 'LISTAR_STATUS_MEDICAO_AUTORIZACAO_FORNECIMENTO_RESPONSE',
        listaStatusMedicaoAutorizacaoFornecimento
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}