export function ListarHistoricoAutorizacaoFornecimentoEmpenhoRequest(IdAutorizacaoFornecimento) {
    return {
        type: 'LISTAR_HISTORICO_AUTORIZACAO_FORNECIMENTO_EMPENHO_REQUEST',
        IdAutorizacaoFornecimento
    }
}

export function ListarHistoricoAutorizacaoFornecimentoEmpenhoResponse(listaHistoricoAutorizacaoFornecimentoEmpenho) {
    return {
        type: 'LISTAR_HISTORICO_AUTORIZACAO_FORNECIMENTO_EMPENHO_RESPONSE',
        listaHistoricoAutorizacaoFornecimentoEmpenho
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}