import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarFaturamentoDocumentoArquivoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';

function* ListarFaturamentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/FaturamentoDocumentoArquivo/listarCompleto`,
     { IdFaturamento: parseInt(model.idFaturamento) }, { headers: headerParams.token });

    yield put(ListarFaturamentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    yield put(ListarFaturamentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirFaturamentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/FaturamentoDocumentoArquivo`, model.data, { headers: headerParams.token });

    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
      listaId = listaId.concat(element + ' ');
    });

    toast.success('Documento id: ' + listaId + 'cadastrados com sucesso!');

    const result = yield call(api.post, `/api/FaturamentoDocumentoArquivo/listarCompleto`,
     { IdFaturamento: parseInt(model.idFaturamento) }, { headers: headerParams.token });

    yield put(ListarFaturamentoDocumentoArquivoResponse(result.data.result));
    
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarFaturamentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/FaturamentoDocumentoArquivo`, model.data, { headers: headerParams.token });

    toast.success(`FaturamentoDocumentoArquivo foi atualizado com sucesso!`);

    const result = yield call(api.post, `/api/FaturamentoDocumentoArquivo/listarCompleto`,
     { IdFaturamento: parseInt(model.idFaturamento) }, { headers: headerParams.token });

    yield put(ListarFaturamentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirFaturamentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/FaturamentoDocumentoArquivo/${model.id}/${'0'}`, { headers: headerParams.token });

    toast.success(`FaturamentoDocumentoArquivo id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/FaturamentoDocumentoArquivo/listarCompleto`,
     { IdFaturamento: parseInt(model.idFaturamento) }, { headers: headerParams.token });

    yield put(ListarFaturamentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {
    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
    toast.error(e.response.data.message);
  }
}

function* BaixarFaturamentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FaturamentoDocumentoArquivo/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeArquivo}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/FaturamentoDocumentoArquivo/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
    toast.error(e.response.data.message);
  }
}

export default all([
  takeLatest('LISTAR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST', ListarFaturamentoDocumentoArquivoRequest),
  takeLatest('INSERIR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST', InserirFaturamentoDocumentoArquivoRequest),
  takeLatest('ALTERAR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST', AlterarFaturamentoDocumentoArquivoRequest),
  takeLatest('EXCLUIR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST', ExcluirFaturamentoDocumentoArquivoRequest),
  takeLatest('BAIXAR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST', BaixarFaturamentoDocumentoArquivoRequest)
])