export function BuscarInteressadoPorNomeRequest(interessado) {
    return {
        type: 'BUSCAR_INTERESSADO_POR_NOME_REQUEST',
        interessado
    }
}

export function BuscarInteressadoPorNomeResponse(listaInteressadoPorNome) {
    return {
        type: 'BUSCAR_INTERESSADO_POR_NOME_RESPONSE',
        listaInteressadoPorNome
    }
}

export function LimparlistaInteressadoPorNome() {
    return {
        type: 'LIMPAR_LISTA_INTERESSADO_POR_NOME'
    }
}

export function ListarInteressadoRequest() {
    return {
        type: 'LISTAR_INTERESSADO_REQUEST'
    }
}

export function ListarInteressadoResponse(listaInteressado) {
    return {
        type: 'LISTAR_INTERESSADO_RESPONSE',
        listaInteressado
    }
}

export function InserirInteressadoRequest(data) {
    return {
        type: 'INSERIR_INTERESSADO_REQUEST',
        data
    }
}

export function AlterarInteressadoRequest(data) {
    return {
        type: 'ALTERAR_INTERESSADO_REQUEST',
        data
    }
}

export function ExcluirInteressadoRequest(id) {
    return {
        type: 'EXCLUIR_INTERESSADO_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}