import produce from 'immer';

const initialState = {
    listaPrioridade: undefined,
  carregando: true
};

export default function prioridade(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_PRIORIDADE_RESPONSE':
      return produce(state, draft => {
        draft.listaPrioridade = action.listaPrioridade;
      });
    
      case 'CARREGANDO':
        return produce(state, draft => {
          draft.carregando = action.status
        });

    default:
      return state;
  }
}