export function ListarFormularioRequest() {
    return {
        type: 'LISTAR_FORMULARIO_REQUEST'
    }
}

export function ListarFormularioResponse(listaFormulario) {
    return {
        type: 'LISTAR_FORMULARIO_RESPONSE',
        listaFormulario
    }
}

export function InserirFormularioRequest(data) {
    return {
        type: 'INSERIR_FORMULARIO_REQUEST',
        data
    }
}

export function AlterarFormularioRequest(data) {
    return {
        type: 'ALTERAR_FORMULARIO_REQUEST',
        data
    }
}

export function ExcluirFormularioRequest(id) {
    return {
        type: 'EXCLUIR_FORMULARIO_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}