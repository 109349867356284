import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarTipoSolicitacaoResponse, BuscarTipoSolicitacaoResponse } from './actions';
import { ListarTipoSolicitacaoTipoDocumentoArquivoResponse } from '../tipoSolicitacaoTipoDocumentoArquivo/actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarTipoSolicitacaoRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/TipoSolicitacao`, { headers: headerParams.token });

    yield put(ListarTipoSolicitacaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarTipoSolicitacaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* BuscarTipoSolicitacaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'Id': parseInt(model.id),
      'IdSolicitacaoFornecimento': parseInt(model.idSolicitacaoFornecimento)
    }

    const result = yield call(api.post, `/api/TipoSolicitacao/BuscarCompleto`, data, { headers: headerParams.token });

    yield put(BuscarTipoSolicitacaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(BuscarTipoSolicitacaoResponse(null));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirTipoSolicitacaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/TipoSolicitacao`, model.data, { headers: headerParams.token });

    toast.success(`A Solicitacao foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/TipoSolicitacao`, { headers: headerParams.token });

    yield put(ListarTipoSolicitacaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarTipoSolicitacaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    yield call(api.put, `/api/TipoSolicitacao`, model.data, { headers: headerParams.token });

    toast.success(`Tipo solicitação foi atualizado com sucesso!`);

    // Listar Tipo Solicitacao
    const result = yield call(api.get, `/api/TipoSolicitacao`, { headers: headerParams.token });

    yield put(ListarTipoSolicitacaoResponse(result.data.result));

    // Listar relacao Tipo Solicitacao com Tipo Documento Arquivo
    const resultRelacao = yield call(api.get, `/api/TipoSolicitacaoTipoDocumentoArquivo`, { headers: headerParams.token });

    yield put(ListarTipoSolicitacaoTipoDocumentoArquivoResponse(resultRelacao.data.result));
    
    yield put(Carregando(true));    
    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else 
        if (e.response.data.statusCode != 404)  {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirTipoSolicitacaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    yield call(api.delete, `/api/TipoSolicitacao/${model.id}`, { headers: headerParams.token });

    toast.success(`Tipo solicitacao id: ${model.id} foi excluida!`);

    const result = yield call(api.get, `/api/TipoSolicitacao`, { headers: headerParams.token });

    yield put(ListarTipoSolicitacaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_TIPO_SOLICITACAO_REQUEST', ListarTipoSolicitacaoRequest),
  takeLatest('BUSCAR_TIPO_SOLICITACAO_REQUEST', BuscarTipoSolicitacaoRequest),
  takeLatest('INSERIR_TIPO_SOLICITACAO_REQUEST', InserirTipoSolicitacaoRequest),
  takeLatest('ALTERAR_TIPO_SOLICITACAO_REQUEST', AlterarTipoSolicitacaoRequest),
  takeLatest('EXCLUIR_TIPO_SOLICITACAO_REQUEST', ExcluirTipoSolicitacaoRequest)
])