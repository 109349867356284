export function ListarTipoRecursoRequest() {
  return {
    type: 'LISTAR_TIPO_RECURSO_REQUEST'
  }
}

export function ListarTipoRecursoResponse(listaTipoRecurso) {
  return {
    type: 'LISTAR_TIPO_RECURSO_RESPONSE',
    listaTipoRecurso
  }
}

export function InserirTipoRecursoRequest(data) {
  return {
    type: 'INSERIR_TIPO_RECURSO_REQUEST',
    data
  }
}

export function AlterarTipoRecursoRequest(data) {
  return {
    type: 'ALTERAR_TIPO_RECURSO_REQUEST',
    data
  }
}

export function ExcluirTipoRecursoRequest(id) {
  return {
    type: 'EXCLUIR_TIPO_RECURSO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}