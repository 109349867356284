import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Alert, Button, Row, DropdownButton, Modal, Container } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { FaSearchPlus } from "react-icons/fa";
import {
    ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest,
    AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest,
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest
} from "../../../store/modules/autorizacaoFornecimentoSubitemCustoMedicao/actions";
import { BuscarUsuarioPorIdRequest, ListarUsuarioRequest } from "../../../store/modules/usuario/actions";
import {
    ListarAutorizacaoFornecimentoSubitemCustoRequest,
    ListarCompletoAutorizacaoFornecimentoSubitemCustoRequest
} from "../../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { BuscarCompletoAutorizacaoFornecimentoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { toast } from "react-toastify";
import { BuscarContratoVersaoPorIdRequest } from '../../../store/modules/contratoVersao/actions';
import { DropzoneArea } from 'material-ui-dropzone';
import { InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest } from "../../../store/modules/regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/actions";
import ModalSubitemAutorizacao from "../../../components/ModalSubitemAutorizacao/modalSubitemAutorizacao";
import ModalSubitemMedicao from "../../../components/ModalSubitemMedicao/modalSubitemMedicao";
import Select from 'react-select';
import MaterialTable from "material-table";
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import 'moment/locale/pt-br';
import './subitem.css';
import { RelatorioTodasMedicaoSubItemsRequest } from "../../../store/modules/relatorio/actions";
import { ListarContratoVersaoRequest } from "../../../store/modules/contratoVersao/actions";
import { ListarMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/medicaoAutorizacaoFornecimento/actions";

export default function Subitem() {

    const { idMedicao, idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const carregando = useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicao.carregando);
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const listaUsuario = useSelector(state => state.usuario.listaUsuario);
    const listaAutorizacaoFornecimentoSubitemCustoMedicao =
        useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicao.listaAutorizacaoFornecimentoSubitemCustoMedicao);
    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const listaCompletoAutorizacaoFornecimentoSubitemCusto =
        useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaCompletoAutorizacaoFornecimentoSubitemCusto);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const medicaoAutorizacaoFornecimentoCompleto = useSelector(state => state.medicaoAutorizacaoFornecimento.medicaoAutorizacaoFornecimentoCompleto);
    const contratoVersao = useSelector(state => state.contratoVersao.contratoVersaoCompleto);
    const listaMedicaoAutorizacaoFornecimento = useSelector(state => state.medicaoAutorizacaoFornecimento.listaMedicaoAutorizacaoFornecimento);   

    const [selectedRow, setSelectedRow] = useState(null);
    const options = listaAutorizacaoFornecimentoSubitemCusto != undefined && listaAutorizacaoFornecimentoSubitemCusto.length > 0 ?
        listaAutorizacaoFornecimentoSubitemCusto.map(subitemUnidade => ({
            value: subitemUnidade.id, label: subitemUnidade.subitemCusto.descricaoCodigoInterno + " - " + subitemUnidade.subitemCusto.descricao
        }))
        :
        [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);

    const [codDescSubitem, setCodDescSubitem] = useState(true);
    const [aprovado, setAprovado] = useState(true);
    const [quantidadeCliente, setQuantidadeCliente] = useState(true);
    const [quantidadeFornecedor, setQuantidadeFornecedor] = useState(true);
    const [saldoQuantidadeAutorizacao, setSaldoQuantidadeAutorizacao] = useState(false);
    const [quantidadeTotalContrato, setQuantidadeTotalContrato] = useState(false);
    const [valorUnitarioSubitem, setValorUnitarioSubitem] = useState(false);
    const [dataUltimaAlteracao, setDataUltimaAlteracao] = useState(false);
    const [usuarioUltimaAlteracao, setUsuarioUltimaAlteracao] = useState(false);
    const [ativo, setAtivo] = useState(true);
    const [show, setShow] = useState(false);
    const [showSubitemSemAutorizacao, setShowSubitemSemAutorizacao] = useState(false);
    const [valorObra, setValorObra] = useState('');
    const [valorObraReais, setValorObraReais] = useState(0);
    const [showFoto, setShowFoto] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [listaFile, setListaFile] = useState([]);
    const [dataAutorizacaoSubitem, setDataAutorizacaoSubitem] = useState([]);
    const [dataId, setDataId] = useState([]);

    const [valorObraAtualSemItensAdicionadosDepois, setValorObraAtualSemItensAdicionadosDepois] = useState('');
    const [valorObraAtualSemItensAdicionadosDepoisReais, setValorObraAtualSemItensAdicionadosDepoisReais] = useState(0);

    const [unidadeDeMedida, setUnidadeDeMedida] = useState(false);
    const [showConfirmacao, setShowConfirmacao] = useState(false);
    const [observacaoSubitem, setObservacaoSubitem] = useState('');
    const [subitemConfirmacao, setSubitemConfirmacao] = useState('');
    const [validated, setValidated] = useState(false);
    const [valorTotalMedicao, setValorTotalMedicao] = useState(0);
    const [valorTotalMedicaoReais, setValorTotalMedicaoReais] = useState(0);
    const [valorTotalMedicaoSubItem, setValorTotalMedicaoSubItem] = useState(0);
    const [saldoQuantidadeContrato, setSaldoQuantidadeContrato] = useState(false);
    const [itemEmpenhado, setItemEmpenhado] = useState(false);
    const [porcentagemRelacional, setPorcentagemRelacional] = useState(0);
    const [porcentagemSolicitacaoComMedicao, setPorcentagemSolicitacaoComMedicao] = useState(0);
    const [valorTotalTodasMedicoes, setValorTotalTodasMedicoes] = useState(0);
    const [valorTotalTodasMedicoesSemAdicionado, setValorTotalTodasMedicoesSemAdicionado] = useState(0);
    const [subitemCompleto, setSubitemCompleto] = useState('');

    const handleCloseConfirmacao = () => setShowConfirmacao(false);
    const handleShowConfirmacao = () => setShowConfirmacao(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSubitemSemAutorizacao = () => setShowSubitemSemAutorizacao(false);
    const handleShowSubitemSemAutorizacao = () => setShowSubitemSemAutorizacao(true);
    const handleCloseFoto = () => setShowFoto(false);
    const handleShowFoto = () => setShowFoto(true);

    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());
        dispatch(ListarUsuarioRequest());
        dispatch(BuscarUsuarioPorIdRequest(usuario.id));

        if (idMedicao !== undefined) {
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(idMedicao));
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

        if (idAutorizacaoFornecimento !== undefined) {

            dispatch(BuscarCompletoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));

            var data = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                'Cancelado': false,
                'Finalizado': false
            }
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));

            var dataCompleto = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento)
            }
            dispatch(ListarCompletoAutorizacaoFornecimentoSubitemCustoRequest(dataCompleto));

            dispatch(ListarMedicaoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));
        }

    }, [idMedicao])


    useEffect(() => {

        if (autorizacaoFornecimentoCompleto != null) {
            dispatch(BuscarContratoVersaoPorIdRequest(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.id));
        }

    }, [autorizacaoFornecimentoCompleto])

    useEffect(() => {

        if (medicaoAutorizacaoFornecimentoCompleto != null && medicaoAutorizacaoFornecimentoCompleto.usuario != null
            && medicaoAutorizacaoFornecimentoCompleto.id == parseInt(idMedicao)) {

            CarregarTotalMedicao();
        }

    }, [medicaoAutorizacaoFornecimentoCompleto, valorObraReais])

    useEffect(() => {

        var valorObraAtual = 0;
        var valorObraAtualSemItensAdicionadosDepois = 0;

        if (listaAutorizacaoFornecimentoSubitemCusto != undefined) {

            listaAutorizacaoFornecimentoSubitemCusto.map(lista => {

                valorObraAtual += (lista.subitemCusto.valorUnitario * lista.quantidade)
            })

        }

        if (listaAutorizacaoFornecimentoSubitemCusto != undefined) {

            listaAutorizacaoFornecimentoSubitemCusto.map(lista => {

                if (!lista.inseridoAposSolicitacao) {
                    valorObraAtualSemItensAdicionadosDepois += (lista.subitemCusto.valorUnitario * lista.quantidade)
                }

            })

        }

        setValorObra("R$" + valorObraAtual.toFixed(2));
        setValorObraReais(valorObraAtual.toFixed(2));

        setValorObraAtualSemItensAdicionadosDepois("R$" + valorObraAtualSemItensAdicionadosDepois.toFixed(2));
        setValorObraAtualSemItensAdicionadosDepoisReais(valorObraAtualSemItensAdicionadosDepois.toFixed(2));

    }, [listaAutorizacaoFornecimentoSubitemCusto])

    const handleSubmit = (event) => {

        if (observacaoSubitem.trim() == '') {
            event.preventDefault();
        } else {
            ValidacaoAlterarSubitem(event);
        }

        setValidated(true);
    };

    function ValidacaoAlterarSubitem(event) {

        /*var valorAtual = valorObra.split('$', valorObra.length);*/
        /*let valorOrcamento = parseFloat(valorAtual[1]);*/
        let valorOrcamento = 0.0;
        let quantidade = 0;
        var quantidadeExcedida = 0;
        var valorComItensAdicionados = 0.0;
        var valorSubitem = 0.0;
        var validacaoExcedendoValor = 0;

        if (listaAutorizacaoFornecimentoSubitemCusto) {
            listaAutorizacaoFornecimentoSubitemCusto.forEach(subitem => {
                valorComItensAdicionados += subitem.quantidade * subitem.subitemCusto.valorUnitario;

                if (!subitem.inseridoAposSolicitacao)
                    valorOrcamento += subitem.quantidade * subitem.subitemCusto.valorUnitario;
            })
        }

        if (usuarioCompleto && usuarioCompleto.usuarioFornecedor == true) {
            quantidade = subitemCompleto.QuantidadeFornecedor + subitemCompleto.QuantidadeOutrasMedicoes;
        }
        else {
            quantidade = subitemCompleto.QuantidadeCliente + subitemCompleto.QuantidadeOutrasMedicoes;
        }

        if (listaAutorizacaoFornecimentoSubitemCusto) {
            var item = listaAutorizacaoFornecimentoSubitemCusto.filter(x => x.id == subitemCompleto.IdAutorizacaoFornecimentoSubitemCusto)[0];
            var itens = listaAutorizacaoFornecimentoSubitemCusto.filter(x => x.idSubitemCusto == item.idSubitemCusto)
            if (item) {

                var quantidadeTotalAutorizacao = 0;

                itens.forEach(subitem => {

                    quantidadeTotalAutorizacao += subitem.quantidade;
                })
                
                quantidadeExcedida += quantidade - quantidadeTotalAutorizacao;
                valorSubitem = item.subitemCusto.valorUnitario;
            }
        }

        var valorTodasMedicoes = 0.0;
        if(listaMedicaoAutorizacaoFornecimento){
            listaMedicaoAutorizacaoFornecimento.forEach(medicao => {
                valorTodasMedicoes += medicao.valorTotalMedicao;
            });
        }

        var teste = (quantidadeExcedida * valorSubitem) + valorTodasMedicoes;
        var totalAutorizacaoExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
        if (teste > totalAutorizacaoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
            validacaoExcedendoValor = validacaoExcedendoValor + 1;
            /*toast.error('O valor total dos itens selecionados esta ultrapassando o valor máximo da autorização');
            return;*/
        }

        if(validacaoExcedendoValor === 1){
            setDataAutorizacaoSubitem(event)
            handleShowFoto();
            return;
        } else {
            AlterarSubitemConfirmado(event);
        }

        /*
        if(quantidade > subitemCompleto.SaldoContratoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
            if(contratoVersao && valorOrcamento){
                let valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
                let valorTotalMed = quantidadeExcedida * subitemCompleto.ValorUnitario + valorComItensAdicionados;
                if(valorTotalMed > valorTotalExcedente){
                    toast.error('Item: ' + subitemCompleto.Descricao + ' esta ultrapassando o saldo do contrato');
                    return;
                }
                else{
                    setDataAutorizacaoSubitem(event)
                    handleShowFoto();
                    return;
                }
            }
        }
        else{
            AlterarSubitemConfirmado(event);
        }
        */

    }

    function AlterarAutorizacaoFornecimentoSubitemCustoMedicao(data) {

        if (isNaN(data.QuantidadeCliente) || data.QuantidadeCliente == undefined
            || data.QuantidadeCliente == null) {
            if (data.igualar == undefined && data.igualar != true) {
                toast.error(`Quantidade inválida`);
            }
            return;
        }

        var lista = [];

        if (listaContratoUsuario != undefined && idAutorizacaoFornecimento != undefined &&
            autorizacaoFornecimentoCompleto != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null) {

            lista = listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)
        }

        if (lista.length > 0 && lista[0].editaQuantidadeSubitemMedicao === true) {

            /*
            if ((usuarioCompleto.usuarioFornecedor == true && data.QuantidadeFornecedor == 0 ||
                usuarioCompleto.usuarioFornecedor == false && data.QuantidadeCliente == 0)) {

                if (data.igualar == undefined && data.igualar != true) {
                    toast.error("Você não pode por a quantidade igual a zero!");
                }

            } else */if (usuarioCompleto.usuarioFornecedor == true && data.QuantidadeFornecedor == undefined ||
                usuarioCompleto.usuarioFornecedor == false && data.QuantidadeCliente == undefined) {

                if (data.igualar == undefined && data.igualar != true) {
                    toast.error("A quantidade precisa ter um valor!");
                }

            } else {
                let dados = {
                    Id: parseInt(data.id),
                    IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                    IdAutorizacaoFornecimentoSubitemCusto: parseInt(data.IdAutorizacaoFornecimentoSubitemCusto),
                    IdUsuarioUltimaAlteracao: usuario.id,
                    DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                    QuantidadeCliente: parseFloat(data.QuantidadeCliente),
                    QuantidadeFornecedor: parseFloat(data.QuantidadeFornecedor),
                    AlteracaoFornecedor: usuarioCompleto.usuarioFornecedor,
                    Ativo: data.ativo,
                    ObservacaoSubitemAutorizacao: observacaoSubitem
                }

                if (usuarioCompleto != null && data.quantidadeSubitemMedicaoTotal - data.quantidadeInicial +
                    (usuarioCompleto.usuarioFornecedor ? data.QuantidadeFornecedor : data.QuantidadeCliente)
                    > data.quantidadeSubitemAutorizacaoTotal) {

                    let dataCompleto = {
                        Id: parseInt(data.id),
                        IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                        IdAutorizacaoFornecimentoSubitemCusto: parseInt(data.IdAutorizacaoFornecimentoSubitemCusto),
                        IdUsuarioUltimaAlteracao: usuario.id,
                        DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                        QuantidadeCliente: parseFloat(data.QuantidadeCliente),
                        QuantidadeFornecedor: parseFloat(data.QuantidadeFornecedor),
                        AlteracaoFornecedor: usuarioCompleto.usuarioFornecedor,
                        Ativo: data.ativo,
                        ObservacaoSubitemAutorizacao: observacaoSubitem,
                        QuantidadeSubitemAutorizacaoTotal: data.quantidadeSubitemAutorizacaoTotal,
                        QuantidadeSubitemMedicaoTotal: data.quantidadeSubitemMedicaoTotal,
                        QuantidadeTotalContrato: data.quantidadeContrato,
                        QuantidadeContratoExcedente: data.quantidadeContratoExcedente,
                        SaldoContratoExcedente: data.saldoContratoExcedente,
                        ValorUnitario: data.valorUnitario,
                        Descricao: data.codigoInterno,
                        QuantidadeFinal: data.quantidadeFinal,
                        QuantidadeOutrasMedicoes: data.quantidadeOutrasMedicoes
                    }

                    setSubitemCompleto(dataCompleto);
                    setSubitemConfirmacao(dados);
                    handleShowConfirmacao();
                    return;

                } else {
                    dispatch(AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(dados, idAutorizacaoFornecimento));
                }

            }

        } else {
            toast.error("Você não possui permissão para alterar um item de medição!");
        }
    }

    function InserirSubitem() {

        // Verifica se o usuário tem permissão de inserir subitem na medicao
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.editaQuantidadeSubitemMedicao === true).length === 0) {

            toast.error('Você não tem permissão de cadastrar itens.');
            return;
        }

        handleShow();
    }

    function IgualarTodasQuantidade() {

        if (listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined) {


            listaAutorizacaoFornecimentoSubitemCustoMedicao.forEach(subitem => {

                if (subitem.quantidadeCliente !== subitem.quantidadeFornecedor) {

                    const objetoSubItem = {
                        ...subitem,
                        QuantidadeFornecedor: 0,
                        QuantidadeCliente: 0,
                        IdAutorizacaoFornecimentoSubitemCusto: subitem.idAutorizacaoFornecimentoSubitemCusto,
                        igualar: true
                    };

                    if (subitem.quantidadeCliente == 0 && usuarioCompleto != null && usuarioCompleto.usuarioFornecedor == false) {
                        objetoSubItem.QuantidadeCliente = subitem.quantidadeFornecedor;
                        objetoSubItem.QuantidadeFornecedor = subitem.quantidadeFornecedor;
                    }
                    else if (subitem.quantidadeFornecedor == 0 && usuarioCompleto != null && usuarioCompleto.usuarioFornecedor == true) {
                        objetoSubItem.QuantidadeCliente = subitem.quantidadeCliente;
                        objetoSubItem.QuantidadeFornecedor = subitem.quantidadeCliente;
                    }
                    else {
                        toast.error('Você não pode igualar as quantidades');
                        return;
                    }

                    AlterarAutorizacaoFornecimentoSubitemCustoMedicao(objetoSubItem);
                }

            });

        }
    }

    function InserirSubitemSemAutorizacao() {

        // Verifica se o usuário tem permissão de inserir subitem na medicao
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.editaQuantidadeSubitemMedicao === true).length === 0) {

            toast.error('Você não tem permissão de cadastrar itens.');
            return;
        }

        setValidated(false);
        setObservacaoSubitem('');
        handleShowSubitemSemAutorizacao();

    }

    function AlterarSubitemConfirmado(event) {

        event.preventDefault();

        let data = {
            Id: subitemConfirmacao.Id,
            IdMedicaoAutorizacaoFornecimento: subitemConfirmacao.IdMedicaoAutorizacaoFornecimento,
            IdAutorizacaoFornecimentoSubitemCusto: subitemConfirmacao.IdAutorizacaoFornecimentoSubitemCusto,
            IdUsuarioUltimaAlteracao: subitemConfirmacao.IdUsuarioUltimaAlteracao,
            DataUltimaAlteracao: subitemConfirmacao.DataUltimaAlteracao,
            QuantidadeCliente: subitemConfirmacao.QuantidadeCliente,
            QuantidadeFornecedor: subitemConfirmacao.QuantidadeFornecedor,
            AlteracaoFornecedor: subitemConfirmacao.AlteracaoFornecedor,
            Ativo: subitemConfirmacao.Ativo,
            ObservacaoSubitemAutorizacao: observacaoSubitem
        }

        dispatch(AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(data, idAutorizacaoFornecimento));

        setValidated(false);
        setObservacaoSubitem('');
        handleCloseConfirmacao();
    }

    function AlterarSubitemConfirmadoComFoto(event) {

        event.preventDefault();

        let data = {
            Id: subitemConfirmacao.Id,
            IdMedicaoAutorizacaoFornecimento: subitemConfirmacao.IdMedicaoAutorizacaoFornecimento,
            IdAutorizacaoFornecimentoSubitemCusto: subitemConfirmacao.IdAutorizacaoFornecimentoSubitemCusto,
            IdUsuarioUltimaAlteracao: subitemConfirmacao.IdUsuarioUltimaAlteracao,
            DataUltimaAlteracao: subitemConfirmacao.DataUltimaAlteracao,
            QuantidadeCliente: subitemConfirmacao.QuantidadeCliente,
            QuantidadeFornecedor: subitemConfirmacao.QuantidadeFornecedor,
            AlteracaoFornecedor: subitemConfirmacao.AlteracaoFornecedor,
            Ativo: subitemConfirmacao.Ativo,
            ObservacaoSubitemAutorizacao: observacaoSubitem
        }

        dispatch(AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(data, idAutorizacaoFornecimento));

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdAutorizacaoFornecimentoSubitemCustoMedicao', subitemConfirmacao.Id);
                formData.append('IdUsuario', usuario.id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setListaFile([]);
            handleCloseFoto();
        } else {
            toast.error('Para continuar insira um documento');
        }

        dispatch(InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest(formData, subitemConfirmacao.IdAutorizacaoFornecimentoSubitemCusto))

        setValidated(false);
        setObservacaoSubitem('');
        handleCloseConfirmacao();
        handleCloseFoto();
    }

    function CarregarTotalMedicao() {
        setValorTotalMedicao("R$ " + medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicao.toFixed(2));
        setValorTotalMedicaoSubItem("R$ " + medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicaoSubItemAprovado.toFixed(2));
        setValorTotalMedicaoReais(medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicao);

        if (parseInt(valorObraReais) !== 0 && medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicao !== null &&
            medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicao !== undefined) {
            var totalMed = parseFloat((medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicao).toFixed(2));
            var totalTodasMed = parseFloat((medicaoAutorizacaoFornecimentoCompleto.valorTotalTodasMedicoes).toFixed(2));

            var totalObra = parseFloat(valorObraReais);
            var totalObraSemAdicionados = parseFloat(valorObraAtualSemItensAdicionadosDepoisReais);

            var porcentagemRelacional = ((totalMed / totalObra) * 100);
            var porcentagemRelacionalSemAdicionado = ((totalMed / totalObraSemAdicionados) * 100);

            var porcentagemTotal = ((totalTodasMed / totalObra) * 100);;
            var porcentagemTotalSemAdicionados = ((totalTodasMed / totalObraSemAdicionados) * 100);;

            setPorcentagemRelacional(porcentagemRelacional.toFixed(2))
            setPorcentagemSolicitacaoComMedicao(porcentagemRelacionalSemAdicionado.toFixed(2))
            setValorTotalTodasMedicoes(porcentagemTotal.toFixed(2));
            setValorTotalTodasMedicoesSemAdicionado(porcentagemTotalSemAdicionados.toFixed(2));
        }
    }

    function ExportarSubitensMedicao() {
        dispatch(RelatorioTodasMedicaoSubItemsRequest(idMedicao));
    }

    return (
        <>
            <Form>
                <div>
                    <Col md={12}>
                        {
                            carregando ?
                                <div>
                                    <Row style={{ marginLeft: "1em" }}>
                                        <Col>
                                            <DropdownButton variant="success" title="Colunas">
                                                <div className="tamanhoTela">
                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={aprovado} onChange={() => setAprovado(!aprovado)} />
                                                                <Form.Check.Label> Aprovado</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={codDescSubitem} onChange={() => setCodDescSubitem(!codDescSubitem)} />
                                                                <Form.Check.Label> Código/Descrição Item</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeCliente} onChange={() => setQuantidadeCliente(!quantidadeCliente)} />
                                                                <Form.Check.Label> Quantidade Cliente</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeFornecedor} onChange={() => setQuantidadeFornecedor(!quantidadeFornecedor)} />
                                                                <Form.Check.Label> Quantidade Fornecedor</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={saldoQuantidadeAutorizacao} onChange={() => setSaldoQuantidadeAutorizacao(!saldoQuantidadeAutorizacao)} />
                                                                <Form.Check.Label> Saldo Quantidade Autorização</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={ativo} onChange={() => setAtivo(!ativo)} />
                                                                <Form.Check.Label> Ativo</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorUnitarioSubitem} onChange={() => setValorUnitarioSubitem(!valorUnitarioSubitem)} />
                                                                <Form.Check.Label> Valor Unitário Item</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={unidadeDeMedida} onChange={() => setUnidadeDeMedida(!unidadeDeMedida)} />
                                                                <Form.Check.Label> Unidade de Medida</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={dataUltimaAlteracao} onChange={() => setDataUltimaAlteracao(!dataUltimaAlteracao)} />
                                                                <Form.Check.Label> Data Ultima Alteração</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={usuarioUltimaAlteracao} onChange={() => setUsuarioUltimaAlteracao(!usuarioUltimaAlteracao)} />
                                                                <Form.Check.Label> Usuario Ultima Alteração</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={saldoQuantidadeContrato} onChange={() => setSaldoQuantidadeContrato(!saldoQuantidadeContrato)} />
                                                                <Form.Check.Label> Saldo Quantidade Contrato (excedente)</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeTotalContrato} onChange={() => setQuantidadeTotalContrato(!quantidadeTotalContrato)} />
                                                                <Form.Check.Label> Quantidade Total Contrato (Excedente)</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={itemEmpenhado} onChange={() => setItemEmpenhado(!itemEmpenhado)} />
                                                                <Form.Check.Label> Empenhado</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                            </DropdownButton>
                                        </Col>
                                    </Row><br />

                                    <MaterialTable
                                        components={{
                                            Pagination: PatchedPagination,
                                        }}
                                        title={
                                            <Row >
                                                <Row className="mb-3">
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={12}>
                                                        <Button
                                                            // style={{ width: '100%' }}
                                                            onClick={ExportarSubitensMedicao}>
                                                            Exportar SubItems
                                                        </Button>
                                                    </Col>

                                                </Row>

                                                <Row className="mb-3">
                                                    <Col md={2}>
                                                        {
                                                            inserir == true ?
                                                                <Button
                                                                    variant="success"
                                                                    onClick={InserirSubitem}
                                                                    style={{ width: '100%' }}
                                                                    disabled={
                                                                        listaContratoUsuario !== undefined && usuario !== undefined && autorizacaoFornecimentoCompleto != undefined &&
                                                                            autorizacaoFornecimentoCompleto != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                                                            listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id &&
                                                                                x.idContrato == autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato).length == 0 ?
                                                                            true
                                                                            :
                                                                            listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                                                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0 ?
                                                                                true :
                                                                                listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                                                                    ?
                                                                                    true :
                                                                                    false
                                                                    }
                                                                >Novo Item
                                                                </Button>
                                                                :
                                                                ''
                                                        }
                                                    </Col>

                                                    <Col md={6}>
                                                        {
                                                            inserir == true ?
                                                                <Button
                                                                    variant="success"
                                                                    onClick={InserirSubitemSemAutorizacao}
                                                                    style={{ width: '100%' }}
                                                                    disabled={
                                                                        listaContratoUsuario !== undefined && usuario !== undefined && autorizacaoFornecimentoCompleto != undefined &&
                                                                            autorizacaoFornecimentoCompleto != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                                                            listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id &&
                                                                                x.idContrato == autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato).length == 0 ?
                                                                            true
                                                                            :
                                                                            listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                                                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0 ?
                                                                                true :
                                                                                listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                                                                    ?
                                                                                    true :
                                                                                    false
                                                                    }
                                                                >Novo Item (Fora da Autorização)
                                                                </Button>
                                                                :
                                                                ''
                                                        }
                                                    </Col>

                                                    <Col md={4}>
                                                        <Button
                                                            variant="success"
                                                            onClick={IgualarTodasQuantidade}
                                                            style={{ width: '100%' }}
                                                            disabled={
                                                                listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined && listaAutorizacaoFornecimentoSubitemCustoMedicao.length > 0
                                                                    ?
                                                                    false
                                                                    :
                                                                    true
                                                            }
                                                        >Igualar todas as quantidades
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">
                                                </Row>

                                            </Row>
                                        }
                                        columns={[
                                            {
                                                title: 'Aprovado',
                                                align: 'left',
                                                field: 'Aprovado',
                                                editable: 'never',
                                                hidden: aprovado ? false : true,
                                                render: rowData =>
                                                    rowData.ativo && rowData.QuantidadeFornecedor == rowData.QuantidadeCliente &&
                                                        rowData.QuantidadeFornecedor != 0 && rowData.QuantidadeCliente != 0 ?
                                                        <span
                                                            className="iconSubitemTrue"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Item confirmado."
                                                            trigger="hover focus"
                                                        ><RiCheckboxCircleFill size="2em" />
                                                        </span>
                                                        :
                                                        ''
                                            },
                                            {
                                                title: 'Código/Descrição Item',
                                                field: 'subitem',
                                                align: 'left',
                                                editable: 'onAdd',
                                                hidden: codDescSubitem ? false : true,
                                                filterComponent: (props) => {
                                                    return (
                                                        <Select
                                                            placeholder="Selecione..."
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={options}
                                                            onChange={(e) => {
                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                            }}
                                                        />
                                                    )
                                                },
                                                lookup:
                                                    listaCompletoAutorizacaoFornecimentoSubitemCusto != undefined && listaCompletoAutorizacaoFornecimentoSubitemCusto.length > 0 ?
                                                        listaCompletoAutorizacaoFornecimentoSubitemCusto.map(subitemUnidade => {
                                                            return {
                                                                id: subitemUnidade.id, descricao: subitemUnidade.subitemCusto.descricaoCodigoInterno + " - " + subitemUnidade.subitemCusto.descricao
                                                            }
                                                        }).reduce(function (acc, cur, i) {
                                                            acc[cur.id] = cur.descricao;
                                                            return acc;
                                                        }, {})
                                                        :
                                                        null,
                                                editComponent: (props) => (
                                                    <Select id="idSelectSubItem"
                                                        options={options}
                                                        placeholder="Selecione..."
                                                        defaultValue={{
                                                            value: props.value, label: listaCompletoAutorizacaoFornecimentoSubitemCusto.filter(soli => soli.id == props.value).length > 0
                                                                && listaCompletoAutorizacaoFornecimentoSubitemCusto.filter(soli => soli.id == props.value)[0].subitemCusto != null
                                                                ? listaCompletoAutorizacaoFornecimentoSubitemCusto.filter(soli => soli.id == props.value)[0].subitemCusto.descricaoCodigoInterno + " - " + listaCompletoAutorizacaoFornecimentoSubitemCusto.filter(soli => soli.id == props.value)[0].subitemCusto.descricao
                                                                : 'Selecione...'
                                                        }}
                                                    />
                                                ),
                                            },
                                            {
                                                title: 'Quantidade Outras Medições', field: 'quantidadeOutrasMedicoes', editable: 'never',
                                                //hidden: saldoQuantidadeContrato ? false : true
                                            },
                                            {
                                                title: 'Quantidade Cliente',
                                                type: 'numeric',
                                                align: 'left',
                                                field: 'QuantidadeCliente',
                                                hidden: quantidadeCliente ? false : true,
                                                editable: (coluna, linha) => (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor != true),
                                                validate:
                                                    usuarioCompleto != null && usuarioCompleto.usuarioFornecedor != true ?
                                                        rowData => {
                                                            if (rowData.QuantidadeCliente === undefined || rowData.QuantidadeCliente === "" || isNaN(rowData.QuantidadeCliente)) {
                                                                return "Campo Obrigatório"
                                                            } else if (rowData.QuantidadeCliente.toString().length > 10) {
                                                                return "A quantidade não pode conter mais que 10 dígitos"
                                                            } else if (rowData.QuantidadeCliente < 0) {
                                                                return "A quantidade não pode ser menor que zero"
                                                            }
                                                            return true
                                                        }
                                                        :
                                                        rowData => {
                                                            return true
                                                        }
                                            },
                                            {
                                                title: 'Quantidade Fornecedor',
                                                field: 'QuantidadeFornecedor',
                                                type: 'numeric',
                                                align: 'left',
                                                hidden: quantidadeFornecedor ? false : true,
                                                editable: (coluna, linha) => (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor == true),
                                                validate:
                                                    usuarioCompleto != null && usuarioCompleto.usuarioFornecedor == true ?
                                                        rowData => {
                                                            if (rowData.QuantidadeFornecedor === undefined || rowData.QuantidadeFornecedor === "" || isNaN(rowData.QuantidadeFornecedor)) {
                                                                return "Campo Obrigatório"
                                                            } else if (rowData.QuantidadeFornecedor.toString().length > 10) {
                                                                return "A quantidade não pode conter mais que 10 dígitos"
                                                            } else if (rowData.QuantidadeFornecedor < 0) {
                                                                return "A quantidade não pode ser menor que zero"
                                                            }
                                                            return true
                                                        }
                                                        :
                                                        rowData => {
                                                            return true
                                                        }
                                            },
                                            {
                                                title: 'Quantidade Final', field: 'quantidadeFinal', editable: 'never',
                                                //hidden: saldoQuantidadeContrato ? false : true
                                            },
                                            {
                                                title: 'Unidade de Medida',
                                                field: 'unidadeMedida',
                                                editable: 'never',
                                                hidden: unidadeDeMedida ? false : true,
                                            },
                                            {
                                                title: 'Saldo Quantidade Autorização', field: 'saldoAutorizacao', editable: 'never',
                                                hidden: saldoQuantidadeAutorizacao ? false : true,
                                            },
                                            {
                                                title: 'Saldo Quantidade Contrato (excedente)', field: 'saldoContrato', editable: 'never',
                                                hidden: saldoQuantidadeContrato ? false : true,
                                                render: rowData => rowData.saldoContrato + ' (' + rowData.saldoContratoExcedente + ')'
                                            },
                                            {
                                                title: 'Quantidade Total Contrato (Excedente)', field: 'quantidadeTotalContrato', editable: 'never',
                                                hidden: quantidadeTotalContrato ? false : true,
                                                render: rowData =>
                                                    rowData.quantidadeTotalContrato != null ?
                                                        rowData.quantidadeTotalContrato + ' (' + rowData.quantidadeContratoExcedente + ')' : ''
                                            },
                                            {
                                                title: 'Valor Unitário Item', field: 'valorUnitario', editable: 'never',
                                                grouping: false,
                                                hidden: valorUnitarioSubitem ? false : true,
                                                render: rowData =>
                                                    rowData.valorUnitario === null ?
                                                        null
                                                        :
                                                        rowData.valorUnitario.toLocaleString('pt-br', {
                                                            style: 'currency', currency: 'BRL',
                                                            minimumFractionDigits: 4,
                                                            maximumFractionDigits: 4
                                                        })
                                            },
                                            {
                                                title: 'Data Ultima Alteração',
                                                align: 'left',
                                                field: 'DataUltimaAlteracao',
                                                hidden: dataUltimaAlteracao ? false : true,
                                                editable: 'never'
                                            },
                                            {
                                                title: 'Usuário Ultima Alteração',
                                                field: 'Usuario',
                                                align: 'left',
                                                hidden: usuarioUltimaAlteracao ? false : true,
                                                editable: 'never',
                                                render: rowData =>
                                                    listaUsuario.filter(x => x.nome == rowData.Usuario).length > 0 &&
                                                        listaUsuario.filter(x => x.nome == rowData.Usuario)[0].usuarioFornecedor
                                                        == true ?
                                                        <div className="divStatusNormal">{rowData.Usuario}{' (Fornecedor)'}</div>
                                                        :
                                                        <div className="divStatusNormal">{rowData.Usuario}{' (Cliente)'}</div>
                                            },
                                            {
                                                title: 'Ativo',
                                                field: 'ativo', editable: 'onUpdate',
                                                customFilterAndSearch: (term, rowData) => term == rowData.ativo.toString(),
                                                filterComponent: (props) => {
                                                    return (
                                                        <Select
                                                            placeholder="Selecione..."
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={[{ value: 'ativado', label: 'Ativado' },
                                                            { value: 'desativado', label: 'Desativado' },]}
                                                            onChange={(e) => {
                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                            }}
                                                        />
                                                    )
                                                },
                                                editComponent: (props) => (
                                                    <input
                                                        type="checkbox"
                                                        checked={props.value == false ? props.value : true}
                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                    />
                                                ),
                                                hidden: ativo ? false : true,
                                                render: rowData => (<input type="checkbox" checked={rowData.ativo} onChange={() => { }} />)
                                            },
                                            {
                                                title: 'Empenhado',
                                                field: 'empenhado',
                                                align: 'left',
                                                editable: 'never',
                                                hidden: itemEmpenhado ? false : true,
                                            },

                                        ]}
                                        data={
                                            listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined && listaUsuario != undefined && listaAutorizacaoFornecimentoSubitemCusto != undefined ?
                                                listaAutorizacaoFornecimentoSubitemCustoMedicao.map((lista) => {
                                                    return {
                                                        id: lista.id,
                                                        subitem: lista.idAutorizacaoFornecimentoSubitemCusto,
                                                        IdAutorizacaoFornecimentoSubitemCusto: lista.idAutorizacaoFornecimentoSubitemCusto,
                                                        codigoInterno:
                                                            lista.autorizacaoFornecimentoSubitemCusto != null &&
                                                                lista.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                                                lista.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricaoCodigoInterno
                                                                :
                                                                ''
                                                        ,
                                                        DataUltimaAlteracao: moment(lista.dataUltimaAlteracao).format('L LT'),
                                                        Usuario: lista.usuario != null ?
                                                            lista.usuario.nome : '',
                                                        QuantidadeCliente: lista.quantidadeCliente,
                                                        QuantidadeFornecedor: lista.quantidadeFornecedor,
                                                        Aprovado: '',
                                                        saldoContrato: lista.saldo,
                                                        unidadeMedida: lista.autorizacaoFornecimentoSubitemCusto.subitemCusto.unidadeMedida.descricao,
                                                        saldoAutorizacao: lista.saldoAutorizacao,
                                                        ativo: lista.ativo,
                                                        saldoContratoExcedente: lista.saldoExcedente,
                                                        valorUnitario: lista.autorizacaoFornecimentoSubitemCusto != null &&
                                                            lista.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                                            lista.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario
                                                            :
                                                            '',
                                                        quantidadeSubitemAutorizacaoTotal: lista.quantidadeSubitemAutorizacaoTotal,
                                                        quantidadeSubitemMedicaoTotal: lista.quantidadeSubitemMedicaoTotal,
                                                        quantidadeInicial: lista.quantidadeCliente > lista.quantidadeFornecedor ? lista.quantidadeCliente : lista.quantidadeFornecedor,
                                                        quantidadeTotalContrato: lista.quantidadeContrato,
                                                        quantidadeContratoExcedente: lista.quantidadeContratoExcedente,
                                                        empenhado: lista.idEmpenho !== null && lista.idEmpenho.length > 0 ?
                                                            lista.idEmpenho.map(e => (
                                                                lista.idEmpenho[lista.idEmpenho.length - 1] == e ?
                                                                    `${e} `
                                                                    :
                                                                    `${e}, `
                                                            ))
                                                            :
                                                            'Este item ainda não foi empenhado',
                                                        quantidadeOutrasMedicoes: lista.quantidadeOutrasMedicoes,
                                                        quantidadeFinal: lista.quantidadeOutrasMedicoes + lista.quantidadeCliente
                                                    }
                                                })
                                                :
                                                []
                                        }
                                        editable={{
                                            //isEditHidden: rowData => rowData.ativo === false,
                                            onRowUpdate:
                                                idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                                [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                                [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                                    listaMedicaoAutorizacaoFornecimentoStatus
                                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                                    ?
                                                    (newRow, oldRow) => new Promise((resolve, reject) => {
                                                        alterar == true ?
                                                            setTimeout(() => {
                                                                AlterarAutorizacaoFornecimentoSubitemCustoMedicao(newRow);
                                                                resolve()
                                                            }, 1000)
                                                            :
                                                            setTimeout(() => {
                                                                toast.error(`Você não tem permissão`);
                                                                resolve()
                                                            }, 1000)
                                                    })
                                                    :
                                                    null
                                        }}
                                        options={{
                                            minBodyHeight: 500,
                                            actionsCellStyle: {
                                                width: '5%',
                                                position: 'static'
                                            },
                                            headerStyle: { position: 'sticky', top: 0 },
                                            maxBodyHeight: '650px',
                                            paging: true,
                                            pageSize: 10, // Início
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [10, 50, 200, 300],
                                            filterRowStyle: {
                                                backgroundColor: "#FAFAFA"
                                            },
                                            headerStyle: {
                                                backgroundColor: '#454545',
                                                color: '#FFF',
                                                fontWeight: "bold"
                                            },
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            filtering: true,
                                            rowStyle:
                                                rowData => ({
                                                    backgroundColor:
                                                        (rowData.QuantidadeCliente == rowData.QuantidadeFornecedor
                                                            && rowData.QuantidadeCliente - rowData.saldoContratoExcedente > 0 || rowData.ativo == false
                                                            || rowData.QuantidadeCliente == rowData.QuantidadeFornecedor
                                                            && rowData.QuantidadeCliente - rowData.saldoAutorizacao > 0) &&
                                                            listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                            listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length == 0 ? '#F7D1D1' :
                                                            (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })
                                        }}
                                        actions={[
                                            rowData => ({
                                                icon: 'error',
                                                tooltip: <span style={{ fontSize: '1.5em' }}>Contém divergência de quantidade entre fornecedor e cliente</span>,
                                                hidden: rowData.ativo == false || rowData.QuantidadeCliente == rowData.QuantidadeFornecedor ? true : false
                                            }),
                                            rowData => ({
                                                icon: 'error',
                                                tooltip: <span style={{ fontSize: '1.5em' }}>
                                                    {rowData.QuantidadeCliente == rowData.QuantidadeFornecedor
                                                        && rowData.QuantidadeCliente - rowData.saldoAutorizacao > 0 ?
                                                        'Quantidade saldo autorização excedida: ' + (rowData.QuantidadeFornecedor - rowData.saldoAutorizacao)
                                                        : null}
                                                </span>,
                                                hidden:
                                                    rowData.ativo == false || !(rowData.QuantidadeCliente == rowData.QuantidadeFornecedor
                                                        && rowData.QuantidadeFornecedor - rowData.saldoAutorizacao > 0) ||
                                                    listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                            }),
                                            rowData => ({
                                                icon: 'error',
                                                tooltip: <span style={{ fontSize: '1.5em' }}>
                                                    {rowData.QuantidadeCliente == rowData.QuantidadeFornecedor
                                                        && rowData.QuantidadeCliente - rowData.saldoContratoExcedente > 0 ?
                                                        'Esse item esta passando ' + (rowData.QuantidadeCliente - rowData.saldoContratoExcedente) + ' unidades da quantidade excedente do contrato.'
                                                        : null}
                                                </span>,
                                                hidden:
                                                    rowData.ativo == false ||
                                                    !(rowData.QuantidadeCliente == rowData.QuantidadeFornecedor
                                                        && rowData.QuantidadeCliente - rowData.saldoContratoExcedente > 0) ||
                                                    listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                            }),
                                            rowData => ({
                                                icon: () => <FaSearchPlus />,
                                                onClick: () => history.push(`/alterarGeralSubitem/${rowData.id}/${idMedicao}/${idAutorizacaoFornecimento}`)
                                            }),
                                            rowData => ({
                                                icon: 'error',
                                                tooltip: <span style={{ fontSize: '1.5em' }}>Item esta desativado e não será medido. </span>,
                                                hidden: rowData.ativo == true
                                            })
                                        ]}
                                        localization={{
                                            header: {
                                                actions: <span className="classHeaderTabela">Ações</span>
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                editRow: {
                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                    saveTooltip: '',
                                                    cancelTooltip: '',
                                                },
                                                deleteTooltip: '',
                                                editTooltip: '',
                                                addTooltip: '',
                                            },
                                            toolbar: {
                                                searchTooltip: '',
                                                searchPlaceholder: 'Pesquisar',
                                                exportTitle: "",
                                                exportCSVName: "Exportar como CSV",
                                                exportPDFName: "Exportar como PDF",
                                            },
                                            pagination: {
                                                labelRowsSelect: 'linhas',
                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                firstTooltip: '',
                                                previousTooltip: '',
                                                nextTooltip: '',
                                                lastTooltip: ''
                                            },
                                            grouping: {
                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                groupedBy: 'Agrupado por:'
                                            }
                                        }}
                                    />
                                </div>
                                :
                                <Loading />
                        }
                    </Col><br />
                    <Col md={12}>
                        <Row className="mb-3" type='hidden'>
                            <Form.Group as={Col} md={3} controlId="formValorObra">
                                <Form.Label>Valor do Orçamento (Sem Itens Adicionados Depois)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={valorObraAtualSemItensAdicionadosDepoisReais}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formValorObra">
                                <Form.Label>Valor do Orçamento (Com Itens Adicionados Depois)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={valorObra}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formValorMedicao">
                                <Form.Label>Valor da Medição (Total)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={valorTotalMedicao}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formValorMedicaoSubitensAprovados">
                                <Form.Label>Valor da Medição (Subitens Aprovados)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={valorTotalMedicaoSubItem}
                                    disabled
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3" type='hidden'>
                            {/* <Form.Group as={Col} md={3} controlId="formPorcentagemMedicao">
                                <Form.Label>% Medição (Com Itens Adicionados Depois):</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={porcentagemRelacional + "%"}
                                    disabled
                                />
                            </Form.Group> */}
                            <Form.Group as={Col} md={3} controlId="formPorcentagemMedicao">
                                <Form.Label>% Medição: {/*(Sem Itens Adicionados Depois)*/}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={porcentagemSolicitacaoComMedicao + "%"}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3} controlId="formPorcentagemMedicao">
                                <Form.Label>% de Todas as Medições da AF: {/* (Sem Itens Adicionados Depois)*/}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={valorTotalTodasMedicoesSemAdicionado + "%"}
                                    disabled
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3" type='hidden'>
                            {/* <Form.Group as={Col} md={3} controlId="formPorcentagemMedicao">
                                <Form.Label>% de Todas as Medições da AF (Com Itens Adicionados Depois):</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={valorTotalTodasMedicoes + "%"}
                                    disabled
                                />
                            </Form.Group> */}

                        </Row>
                    </Col>
                    <Col md={12}>
                        {
                            valorTotalTodasMedicoesSemAdicionado !== null &&
                                valorTotalTodasMedicoesSemAdicionado !== undefined &&
                                valorTotalTodasMedicoesSemAdicionado > 130.00 ?
                                <Col md={12}>
                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                        A soma das medições dessa Autorização passaram 130%
                                    </Alert>
                                </Col>
                                :
                                ''
                        }
                    </Col>
                    <Col md={12}>
                        {
                            (idMedicao != undefined) && (listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined) &&
                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                        A Medição está Cancelada!
                                    </Alert>
                                </Col>
                                :
                                (idMedicao != undefined) && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            A Medição está Finalizada!
                                        </Alert>
                                    </Col>
                                    :
                                    ''
                        }
                    </Col>
                </div>
            </Form>

            <ModalSubitemMedicao
                show={show}
                onHide={handleClose}
                idUsuario={usuario != null ? usuario.id : null}
                valorObraReais={valorObraReais}
                valorTotalMedicaoReais={valorTotalMedicaoReais}
                valorObraSemItensAdd={valorObraAtualSemItensAdicionadosDepoisReais}
            />

            <ModalSubitemAutorizacao
                show={showSubitemSemAutorizacao}
                onHide={handleCloseSubitemSemAutorizacao}
                idUsuario={usuario != null ? usuario.id : null}
                idMedicao={idMedicao}
                idAuto={idAutorizacaoFornecimento}
                idContratoVersao={autorizacaoFornecimentoCompleto != null ?
                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.id : null}
                totalOrcamento={valorObra}
            />

            <Modal show={showConfirmacao} onHide={handleCloseConfirmacao} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Quantidade passando a quantidade do item da autorização, ao prosseguir, a quantidade será alterada na autorização. Deseja continuar?
                            </Col>
                            {
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formDescricao">
                                        <Form.Label>Observação: </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={observacaoSubitem}
                                            onChange={e => { setObservacaoSubitem(e.target.value); setObservacao(e.target.value) }}
                                            required
                                            maxLength="500"
                                            isInvalid={validated && observacaoSubitem.trim() === "" ? true : false}
                                            isValid={validated && observacaoSubitem.trim() !== "" ? true : false}
                                        />
                                        <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            }

                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmit}>
                        Sim
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirmacao}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFoto} onHide={handleCloseFoto}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Fotográfico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <span>O valor da obra foi excedido. Inclua uma foto e uma justificativa para esta ação</span><br />
                            <Form.Group as={Col} md={12} controlId="formRegistroFotografico">
                                <div className="divModal"><br />
                                    <DropzoneArea
                                        //initialFiles={[listaRegistroFotografico]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="success" disabled={listaFile.length > 0 ? false : true}
                            onClick={() => AlterarSubitemConfirmadoComFoto(dataAutorizacaoSubitem)}>Salvar Registro Fotográfico</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}


