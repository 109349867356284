import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarComposicaoCustoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarComposicaoCustoRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/ComposicaoCusto`, { headers: headerParams.token });

    yield put(ListarComposicaoCustoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarComposicaoCustoResponse([]));
    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    

  }
}

function* InserirComposicaoCustoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/ComposicaoCusto`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ComposicaoCusto`, { headers: headerParams.token });

    yield put(ListarComposicaoCustoResponse(result.data.result));
    yield put(Carregando(true));
    
    toast.success(`Composicao Custo foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(Carregando(true));    

  }
}

function* AlterarComposicaoCustoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/ComposicaoCusto`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ComposicaoCusto`, { headers: headerParams.token });

    yield put(ListarComposicaoCustoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Composicao Custo foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(Carregando(true));
    
  }
}

function* ExcluirComposicaoCustoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/ComposicaoCusto/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ComposicaoCusto`, { headers: headerParams.token });

    yield put(ListarComposicaoCustoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Composicao Custo id: ${model.id} foi excluida!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(Carregando(true));
    
  }
}

export default all([
  takeLatest('LISTAR_COMPOSICAO_CUSTO_REQUEST', ListarComposicaoCustoRequest),
  takeLatest('INSERIR_COMPOSICAO_CUSTO_REQUEST', InserirComposicaoCustoRequest),
  takeLatest('ALTERAR_COMPOSICAO_CUSTO_REQUEST', AlterarComposicaoCustoRequest),
  takeLatest('EXCLUIR_COMPOSICAO_CUSTO_REQUEST', ExcluirComposicaoCustoRequest)
])