import produce from 'immer';

const initialState = {
    fornecedorCompleto: null,
    listaFornecedor: undefined,
    carregando: true
};

export default function fornecedor(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_FORNECEDOR_RESPONSE':
            return produce(state, draft => {
                draft.listaFornecedor = action.listaFornecedor;
            });

        case 'BUSCAR_FORNECEDOR_POR_ID_RESPONSE':
            return produce(state, draft => {
                draft.fornecedorCompleto = action.fornecedorCompleto;
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}