export function ListarUsuarioRequest() {
  return {
    type: 'LISTAR_USUARIO_REQUEST'
  }
}

export function ListarUsuarioResponse(listaUsuario) {
  return {
    type: 'LISTAR_USUARIO_RESPONSE',
    listaUsuario
  }
}

export function BuscarUsuarioPorIdRequest(id) {
  return {
    type: 'BUSCAR_USUARIO_POR_ID_REQUEST',
    id
  }
}

export function BuscarUsuarioPorIdResponse(usuario) {
  return {
    type: 'BUSCAR_USUARIO_POR_ID_RESPONSE',
    usuario
  }
}

export function InserirUsuarioRequest(data, usuarioFornecedor) {
  return {
    type: 'INSERIR_USUARIO_REQUEST',
    data,
    usuarioFornecedor
  }
}

export function AlterarUsuarioRequest(data, usuarioFornecedor) {
  return {
    type: 'ALTERAR_USUARIO_REQUEST',
    data,
    usuarioFornecedor
  }
}

export function InserirNovaSenhaRequest(email) {
  return {
    type: 'INSERIR_NOVA_SENHA_REQUEST',
    email
  }
}

export function InserirNovaSenhaResponse(senha) {
  return {
    type: 'INSERIR_NOVA_SENHA_RESPONSE',
    senha
  }
}

export function InserirNovaSenhaPageUsuarioRequest(email) {
  return {
    type: 'INSERIR_NOVA_SENHA_PAGE_USUARIO_REQUEST',
    email
  }
}

export function LoginUsuarioRequest(data) {
  return {
    type: 'LOGIN_USUARIO_REQUEST',
    data
  }
}

export function LoginUsuarioResponse(usuario) {
  return {
    type: 'LOGIN_USUARIO_RESPONSE',
    usuario
  }
}

export function LoginUsuarioFail() {
  return {
    type: 'LOGIN_USUARIO_FAIL'
  }
}

export function AtivarUsuarioRequest(data) {
  return {
    type: 'ATIVAR_USUARIO_REQUEST',
    data
  }
}

export function VerificarAtivacaoRequest(id) {
  return {
    type: 'VERIFICAR_ATIVACAO_REQUEST',
    id
  }
}

export function LimparUsuarioRequest() {
  return {
    type: 'LIMPAR_USUARIO_REQUEST',
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}

