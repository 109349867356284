import { Pie } from "react-chartjs-2";
import { Card, Col, Row, Button, Form, Dropdown } from "react-bootstrap";
import { IoBarChart } from "react-icons/io5";
import { RiFilter3Line } from "react-icons/ri";
import { useEffect } from "react";
import {
    ListarDashboardSolicitacaoStatusRequest, ListarDashboardAutorizacaoPorRegionalRequest,
    ListarDashboardAutorizacaoFinalizadaPorMesRequest, ListarDashboardAutorizacaoValorPorMesRequest,
    ListarDashboardValorContratoPorCategoriaRequest, ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoRequest,
    BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimentoRequest, BaixarRelatorioDashboardValorTotalContratoPorCategoriaRequest,
    BaixarRelatorioDashboardAutorizacaoFinalizadaPorMesRequest, BaixarRelatorioDashboardAutorizacaoPorRegionalRequest,
    BaixarRelatorioDashboardStatusSolicitacaoFornecimentoRequest, ListarDashboardAutorizacaoStatusRequest, ListarDashboardMedicaoStatusRequest,
    ListarDashboardObrasIntervencoesPorMesRequest, BaixarRelatorioDashboardObrasIntervencoesPorMesRequest,
    ListarDashboardManutencoesPorMesRequest, BaixarRelatorioDashboardManutencoesPorMesRequest
} from "../../store/modules/dashboard/actions";
import { ListarMesesRequest } from "../../store/modules/meses/actions";
import { useDispatch, useSelector } from "react-redux";
import { BuscarContratoPorIdRequest } from "../../store/modules/contrato/actions";
import { useState } from 'react';
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { chartColors } from "./colors";
import { ListarObrasIntervencoesPorMesRequest } from "../../store/modules/obrasIntervencoesPorMes/actions";
import Select from 'react-select';
import Loading from '../../components/Loading/loading';
import Header from "../../components/Header/header";
import moment from 'moment';
import IntlCurrencyInput from "react-intl-currency-input";
import 'moment/locale/pt-br';
import "./dashboard.css";
import { ListarManutencoesPorMesRequest } from "../../store/modules/manutencoesPorMes/actions";

export default function Dashboard() {

    const dispatch = useDispatch();
    const history = useHistory();
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaDashboardSolicitacaoStatusResponse = useSelector(state => state.dashboard.listaDashboardSolicitacaoStatus);
    const listaDashboardAutorizacaoPorRegional = useSelector(state => state.dashboard.listaDashboardAutorizacaoPorRegional);
    const listaDashboardAutorizacaoFinalizadaPorMes = useSelector(state => state.dashboard.listaDashboardAutorizacaoFinalizadaPorMes);
    const listaDashboardAutorizacaoValorPorMes = useSelector(state => state.dashboard.listaDashboardAutorizacaoValorPorMes);
    const listaDashboardValorContratoPorCategoria = useSelector(state => state.dashboard.listaDashboardValorContratoPorCategoria);
    const listaDashboardCategoriaGastaPorAutorizacaoFornecimento = useSelector(state => state.dashboard.listaDashboardCategoriaGastaPorAutorizacaoFornecimento);
    const listaDashboardAutorizacaoStatus = useSelector(state => state.dashboard.listaDashboardAutorizacaoStatus);
    const listaDashboardObrasIntervencoesPorMes = useSelector(state => state.dashboard.listaDashboardObrasIntervencoesPorMes);
    const listaDashboardManutencoesPorMes = useSelector(state => state.dashboard.listaDashboardManutencoesPorMes);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const listaDashboardMedicaoStatus = useSelector(state => state.dashboard.listaDashboardMedicaoStatus);
    const listaObrasIntervencoesPorMes = useSelector(state => state.obrasIntervencoesPorMes.listaObrasIntervencoesPorMes);
    const listaManutencoesPorMes = useSelector(state => state.manutencoesPorMes.listaManutencoesPorMes);
    const carregando = useSelector(state => state.dashboard.carregando);
    const listaMeses = useSelector(state => state.meses.listaMeses);

    const [dataInicioAutorizacao, setDataInicioAutorizacao] = useState(null);
    const [dataFinalAutorizacao, setDataFinalAutorizacao] = useState(null);
    const [visualizarTudoAutorizacao, setVisualizarTudoAutorizacao] = useState(false);

    const [dataInicioSolicitacao, setDataInicioSolicitacao] = useState(null);
    const [dataFinalSolicitacao, setDataFinalSolicitacao] = useState(null);
    const [visualizarTudoSolicitacao, setVisualizarTudoSolicitacao] = useState(false);

    const [dataInicioMedicao, setDataInicioMedicao] = useState(null);
    const [dataFinalMedicao, setDataFinalMedicao] = useState(null);
    const [visualizarTudoMedicao, setVisualizarTudoMedicao] = useState(false);

    const [descricaoRegiao, setDescricaoRegiao] = useState([]);
    const [quantidadeRegiao, setQuantidadeRegiao] = useState([]);
    const [descricaoStatus, setDescricaoStatus] = useState([]);
    const [quantidadeStatus, setQuantidadeStatus] = useState([]);
    const [descricaoCategoria, setDescricaoCategoria] = useState([]);
    const [valorCategoria, setValorCategoria] = useState([]);
    const [descricaoMesFinalizado, setDescricaoMesFinalizado] = useState([]);
    const [categoriaAutorizacao, setCategoriaAutorizacao] = useState([]);
    const [valorAutorizacao, setValorAutorizacao] = useState([]);
    const [valorMesFinalizado, setValorMesFinalizado] = useState([]);
    const [valorRegiaoFinalizado, setValorRegiaoFinalizado] = useState([]);
    const [descricaoStatusAutorizacao, setDescricaoStatusAutorizacao] = useState([]);
    const [quantidadeStatusAutorizacao, setQuantidadeStatusAutorizacao] = useState([]);
    const [descricaoStatusMedicao, setDescricaoStatusMedicao] = useState([]);
    const [quantidadeStatusMedicao, setQuantidadeStatusMedicao] = useState([]);
    const [valorTotalMes, setValorTotalMes] = useState(0);
    const [valorTotalRegiao, setValorTotalRegiao] = useState(0);
    const [descricaoObrasIntervencoesPorMes, setDescricaoObrasIntervencoesPorMes] = useState([]);
    const [quantidadeObrasIntervencoesPorMes, setQuantidadeObrasIntervencoesPorMes] = useState(0);
    const [descricaoManutencoesPorMes, setDescricaoManutencoesPorMes] = useState([]);
    const [valorManutencoesPorMes, setValorManutencoesPorMes] = useState(0);
    const [manutencoesPorMesLegenda, setManutencoesPorMesLegenda] = useState(0);
    let chartInstance = null;

    const [ano, setAno] = useState([]);
    const [anoManutencoes, setAnoManutencoes] = useState([]);
    const [visualizarTudoObras, setVisualizarTudoObras] = useState(true);
    const [visualizarTudoManutencoes, setVisualizarTudoManutencoes] = useState(true);

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    const selectAno = [];
    const optionsAno = listaObrasIntervencoesPorMes ?
        [...listaObrasIntervencoesPorMes].reduce(function(opcoes, index){
            let key = index['ano'];
            if(!opcoes[key]){
                opcoes[key] = [];
            }
            if(opcoes[key].length === 0){
                opcoes[key].push({value: index.ano, label: index.ano});
                selectAno.push({value: index.ano, label: index.ano});
                return opcoes;
            }
            else{
                return opcoes;
            }
        }, {})
    :
    [];

    const selectAnoManutencoes = [];
    const optionsAnoManutencoes = listaManutencoesPorMes ?
        [...listaManutencoesPorMes].reduce(function(opcoes, index){
            let key = index['ano'];
            if(!opcoes[key]){
                opcoes[key] = [];
            }
            if(opcoes[key].length === 0){
                opcoes[key].push({value: index.ano, label: index.ano});
                selectAnoManutencoes.push({value: index.ano, label: index.ano});
                return opcoes;
            }
            else{
                return opcoes;
            }
        }, {})
    :
    [];

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "DashboardVerValores").length == 0) {
            history.push(`/bemVindo`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        setDataInicioAutorizacao(moment().startOf('month'));
        setDataFinalAutorizacao(moment().endOf('month'));

        setDataInicioSolicitacao(moment().startOf('month'));
        setDataFinalSolicitacao(moment().endOf('month'));

        setDataInicioMedicao(moment().startOf('month'));
        setDataFinalMedicao(moment().endOf('month'));

    }, [])

    useEffect(() => {

        dispatch(ListarObrasIntervencoesPorMesRequest());
        dispatch(ListarMesesRequest());
        dispatch(ListarManutencoesPorMesRequest());
        dispatch(ListarDashboardAutorizacaoPorRegionalRequest());
        dispatch(ListarDashboardSolicitacaoStatusRequest(null, null));
        dispatch(ListarDashboardAutorizacaoFinalizadaPorMesRequest());
        dispatch(ListarDashboardAutorizacaoValorPorMesRequest());
        dispatch(ListarDashboardValorContratoPorCategoriaRequest());
        dispatch(ListarDashboardCategoriaGastaPorAutorizacaoFornecimentoRequest());
        dispatch(ListarDashboardAutorizacaoStatusRequest(null, null));
        dispatch(ListarDashboardMedicaoStatusRequest(null, null));
        dispatch(ListarDashboardObrasIntervencoesPorMesRequest(null, visualizarTudoObras));
        dispatch(ListarDashboardManutencoesPorMesRequest(null, visualizarTudoManutencoes));

        if (usuario != undefined && usuario != null && usuario.objetoJsonPreferencias.idContrato != null && usuario.objetoJsonPreferencias.idContrato != 0)
            dispatch(BuscarContratoPorIdRequest(usuario.objetoJsonPreferencias.idContrato))

    }, [])

    useEffect(() => {

        if (listaDashboardSolicitacaoStatusResponse != null) {

            let listaDescricaoStatus = [];
            let listaquantidadeStatus = [];
            listaDashboardSolicitacaoStatusResponse.map(c => {
                if (c.quantidade != 0) {
                    listaDescricaoStatus.push(c.descricaoStatus + ' (' + c.quantidade + ')');
                    listaquantidadeStatus.push(c.quantidade);
                }
            })

            setDescricaoStatus(listaDescricaoStatus);
            setQuantidadeStatus(listaquantidadeStatus);
        }

    }, [listaDashboardSolicitacaoStatusResponse])

    useEffect(() => {

        if (listaDashboardAutorizacaoStatus != null) {

            let listaDescricaoStatusAutorizacao = [];
            let listaquantidadeStatusAutorizacao = [];
            listaDashboardAutorizacaoStatus.map(c => {
                if (c.quantidade != 0) {
                    listaDescricaoStatusAutorizacao.push(c.descricaoStatus + ' (' + c.quantidade + ')');
                    listaquantidadeStatusAutorizacao.push(c.quantidade);
                }
            })

            setDescricaoStatusAutorizacao(listaDescricaoStatusAutorizacao);
            setQuantidadeStatusAutorizacao(listaquantidadeStatusAutorizacao);
        }

    }, [listaDashboardAutorizacaoStatus])

    useEffect(() => {

        if (listaDashboardMedicaoStatus != null) {

            let listaDescricaoStatusMedicao = [];
            let listaquantidadeStatusMedicao = [];
            listaDashboardMedicaoStatus.map(c => {
                if (c.quantidade != 0) {
                    listaDescricaoStatusMedicao.push(c.descricaoStatus + ' (' + c.quantidade + ')');
                    listaquantidadeStatusMedicao.push(c.quantidade);
                }
            })

            setDescricaoStatusMedicao(listaDescricaoStatusMedicao);
            setQuantidadeStatusMedicao(listaquantidadeStatusMedicao);
        }

    }, [listaDashboardMedicaoStatus])

    // Lista autorização por região
    useEffect(() => {

        if (listaDashboardAutorizacaoPorRegional != null && listaDashboardAutorizacaoPorRegional != undefined) {

            let listaDescricaoRegiao = [];
            let listaQuantidadeRegiao = [];
            let listaValorRegiaoFinalizado = [];

            let inputValor = 0.0;

            listaDashboardAutorizacaoPorRegional.map(c => {

                listaDescricaoRegiao.push(c.descricaoRegiao + ' (' + c.quantidadeRegiao + ')');
                listaQuantidadeRegiao.push(c.quantidadeRegiao);
                listaValorRegiaoFinalizado.push(c.valorTotalRegiao);

                inputValor += parseInt(c.valorTotalRegiao);
            })

            setDescricaoRegiao(listaDescricaoRegiao);
            setValorRegiaoFinalizado(listaValorRegiaoFinalizado)
            setQuantidadeRegiao(listaQuantidadeRegiao);

            setValorTotalRegiao(inputValor);
        }

    }, [listaDashboardAutorizacaoPorRegional])

    // Lista Autorização finalizada por mês
    useEffect(() => {

        if (listaDashboardAutorizacaoFinalizadaPorMes != null && listaDashboardAutorizacaoFinalizadaPorMes != undefined) {

            let listaDescricaoMesFinalizado = [];
            let listaQuantidadeMesFinalizado = [];
            let listaValorMesFinalizado = [];

            let inputValor = 0.0;

            listaDashboardAutorizacaoFinalizadaPorMes.map(c => {
                listaDescricaoMesFinalizado.push(moment(c.descricaoMes).format('MMM'));
                listaQuantidadeMesFinalizado.push(c.quantidade);
                listaValorMesFinalizado.push(c.valorTotalMes);

                inputValor += parseInt(c.valorTotalMes);
            })

            setValorMesFinalizado(listaValorMesFinalizado)
            setDescricaoMesFinalizado(listaDescricaoMesFinalizado);

            setValorTotalMes(inputValor);
        }

    }, [listaDashboardAutorizacaoFinalizadaPorMes])

    // Lista valor total utilizado por mês na autorização
    useEffect(() => {

        if (listaDashboardAutorizacaoValorPorMes != null && listaDashboardAutorizacaoValorPorMes != undefined) {

            let listaDescricaoMesValor = [];
            let listaValorMes = [];

            listaDashboardAutorizacaoValorPorMes.map(c => {
                listaDescricaoMesValor.push(c.descricaoMes);
                listaValorMes.push(c.valor);
            })
        }

    }, [listaDashboardAutorizacaoValorPorMes])

    // Lista valor contrato por categoria
    useEffect(() => {

        if (listaDashboardValorContratoPorCategoria != null && listaDashboardValorContratoPorCategoria != undefined
            && listaDashboardValorContratoPorCategoria.listaCategoria != undefined) {

            let listaDescricaoCategoria = [];
            let listaValorCategoria = [];
            listaDashboardValorContratoPorCategoria.listaCategoria.map(c => {

                if (c.valor != 0) {
                    listaDescricaoCategoria.push(c.descricaoCategoria);
                    listaValorCategoria.push(c.valor);
                }
            })

            setDescricaoCategoria(listaDescricaoCategoria);
            setValorCategoria(listaValorCategoria);
        }

    }, [listaDashboardValorContratoPorCategoria])

    // Lista categoria gasta por AF
    useEffect(() => {

        if (listaDashboardCategoriaGastaPorAutorizacaoFornecimento != null &&
            listaDashboardCategoriaGastaPorAutorizacaoFornecimento != undefined &&
            listaDashboardCategoriaGastaPorAutorizacaoFornecimento.listaCategoria != undefined &&
            listaDashboardCategoriaGastaPorAutorizacaoFornecimento.listaValores != undefined) {

            let listaDescricaoCategoria = [];
            let listaValorCategoria = [];

            listaDashboardCategoriaGastaPorAutorizacaoFornecimento.listaValores.map(function (c, y) {

                if (c != 0 && c != '0') {
                    listaValorCategoria.push(c);
                    listaDescricaoCategoria.push(listaDashboardCategoriaGastaPorAutorizacaoFornecimento.listaCategoria[y]);
                }
            })

            setCategoriaAutorizacao(listaDescricaoCategoria);
            setValorAutorizacao(listaValorCategoria);
        }

    }, [listaDashboardCategoriaGastaPorAutorizacaoFornecimento])

    //Lista de Obras e Intervenções por mes
    useEffect(() => {

        if (listaDashboardObrasIntervencoesPorMes != null) {

            let listaDescricaoObrasIntervencoesPorMes = [];
            let listaquantidadeObrasIntervencoesPorMes = [];
            listaDashboardObrasIntervencoesPorMes.map(c => {
                if (c.quantidade != 0) {
                    listaDescricaoObrasIntervencoesPorMes.push(c.idMes + ' (' + c.quantidade + ')');
                    listaquantidadeObrasIntervencoesPorMes.push(c.quantidade);
                }
            })

            setDescricaoObrasIntervencoesPorMes(listaDescricaoObrasIntervencoesPorMes);
            setQuantidadeObrasIntervencoesPorMes(listaquantidadeObrasIntervencoesPorMes);
        }

    }, [listaDashboardObrasIntervencoesPorMes])

    //Lista de Manutencoes por mes
    useEffect(() => {

        if (listaDashboardManutencoesPorMes != null) {

            let listaDescricaoManutencoesPorMes = [];
            let listaValorManutencoesPorMes = [];
            let listaCompletaManutencoesPorMes = [];
            listaDashboardManutencoesPorMes.map(c => {
                listaDescricaoManutencoesPorMes.push(c.idRegiao);
                listaValorManutencoesPorMes.push(c.valor);
                listaCompletaManutencoesPorMes.push(c.listaMeses)
            })

            setDescricaoManutencoesPorMes(listaDescricaoManutencoesPorMes);
            setValorManutencoesPorMes(listaValorManutencoesPorMes);
            setManutencoesPorMesLegenda(listaCompletaManutencoesPorMes);
        }

    }, [listaDashboardManutencoesPorMes])


    function BaixarRelatorioDashboardAutorizacaoPorRegional() {
        dispatch(BaixarRelatorioDashboardAutorizacaoPorRegionalRequest())
    }

    function BaixarRelatorioDashboardValorTotalContratoPorCategoria() {
        dispatch(BaixarRelatorioDashboardValorTotalContratoPorCategoriaRequest());
    }

    function BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimento() {
        dispatch(BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimentoRequest())
    }

    function BaixarRelatorioDashboardStatusSolicitacaoFornecimento() {
        dispatch(BaixarRelatorioDashboardStatusSolicitacaoFornecimentoRequest())
    }

    function FiltrarDataDashboardStatusSolicitacao() {

        var dataInicioSoli = dataInicioSolicitacao === '' ? null : dataInicioSolicitacao;
        var dataFinalSoli = dataFinalSolicitacao === '' ? null : dataFinalSolicitacao;

        dispatch(ListarDashboardSolicitacaoStatusRequest(dataInicioSoli, dataFinalSoli, visualizarTudoSolicitacao));

        if(dataInicioSolicitacao === ''){
            setDataInicioSolicitacao(null);
        }
        if(dataFinalSolicitacao === ''){
            setDataFinalSolicitacao(null);
        }
    }

    function FiltrarDataDashboardStatusAutorizacao() {

        var dataInicioAF = dataInicioAutorizacao === '' ? null : dataInicioAutorizacao;
        var dataFinalAF = dataFinalAutorizacao === '' ? null : dataFinalAutorizacao;

        dispatch(ListarDashboardAutorizacaoStatusRequest(dataInicioAF, dataFinalAF, visualizarTudoAutorizacao));

        if(dataInicioAutorizacao === ''){
            setDataInicioAutorizacao(null);
        }
        if(dataFinalAutorizacao === ''){
            setDataFinalAutorizacao(null);
        }
    }

    function FiltrarDataDashboardStatusMedicao() {

        var dataInicioMed = dataInicioMedicao === '' ? null : dataInicioMedicao;
        var dataFinalMed = dataFinalMedicao === '' ? null : dataFinalMedicao;

        dispatch(ListarDashboardMedicaoStatusRequest(dataInicioMed, dataFinalMed, visualizarTudoMedicao));

        if(dataInicioMedicao === ''){
            setDataInicioMedicao(null);
        }
        if(dataFinalMedicao === ''){
            setDataFinalMedicao(null);
        }
    }

    function BaixarRelatorioDashboardObrasIntervencoesPorMes() {
        
        var anoObras = ano === '' ? null : ano;

        dispatch(BaixarRelatorioDashboardObrasIntervencoesPorMesRequest(parseInt(anoObras), visualizarTudoObras))
    }

    function FiltrarDataDashboardObras(){

        var anoObras = ano === '' ? null : ano;

        dispatch(ListarDashboardObrasIntervencoesPorMesRequest(parseInt(anoObras), visualizarTudoObras));
        
        if (listaDashboardObrasIntervencoesPorMes != null) {

            let listaDescricaoObrasIntervencoesPorMes = [];
            let listaquantidadeObrasIntervencoesPorMes = [];
            listaDashboardObrasIntervencoesPorMes.map(c => {
                if (c.quantidade != 0) {
                    listaDescricaoObrasIntervencoesPorMes.push(c.idMes + ' (' + c.quantidade + ')');
                    listaquantidadeObrasIntervencoesPorMes.push(c.quantidade);
                }
            })

            setDescricaoObrasIntervencoesPorMes(listaDescricaoObrasIntervencoesPorMes);
            setQuantidadeObrasIntervencoesPorMes(listaquantidadeObrasIntervencoesPorMes);
        }

        if(ano === ''){
            setAno(null);
        }

    }

    function BaixarRelatorioDashboardManutencoesPorMes() {
        
        let anoSelecionadoExportacao = anoManutencoes === '' ? null : anoManutencoes;

        dispatch(BaixarRelatorioDashboardManutencoesPorMesRequest(parseInt(anoSelecionadoExportacao), visualizarTudoManutencoes))
    }

    function FiltrarDataDashboardManutencoes(){

        let anoSelecionado = anoManutencoes === '' ? null : anoManutencoes;

        dispatch(ListarDashboardManutencoesPorMesRequest(parseInt(anoSelecionado), visualizarTudoManutencoes));
        
        if (listaDashboardManutencoesPorMes != null) {

            let listaDescricaoManutencoesPorMes = [];
            let listaValorManutencoesPorMes = [];
            let listaCompletaManutencoesPorMes = [];
            listaDashboardManutencoesPorMes.map(c => {
                listaDescricaoManutencoesPorMes.push(c.idRegiao);
                listaValorManutencoesPorMes.push(c.valor);
                listaCompletaManutencoesPorMes.push(c.listaMeses)
            })

            setDescricaoManutencoesPorMes(listaDescricaoManutencoesPorMes);
            setValorManutencoesPorMes(listaValorManutencoesPorMes);
            setManutencoesPorMesLegenda(listaCompletaManutencoesPorMes);
        }

        if(anoManutencoes === ''){
            setAnoManutencoes(null);
        }

    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col lg={12}>
                    <Card>
                        <Card.Header className="divHeader">

                            {
                                contratoCompleto != null && usuario.objetoJsonPreferencias.idContrato != 0 ?
                                    <span>
                                        <IoBarChart size={25} color={"#000"} /> {`Dashboard: ${contratoCompleto.descricao}`}
                                    </span>
                                    :
                                    <span>
                                        <IoBarChart size={25} color={"#000"} /> Dashboard
                                    </span>
                            }

                        </Card.Header>
                        <Card.Body>

                            {usuario.objetoJsonPreferencias.idContrato != null && usuario.objetoJsonPreferencias.idContrato != undefined
                                && usuario.objetoJsonPreferencias.idContrato != 0 ?

                                <Col md={12}>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico de status de solicitações
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Row>
                                                            <Col style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', marginTop: '1%'}}>
                                                                <strong>Filtro de Data:</strong>
                                                            </Col>
                                                            <Col  style={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'flex'}}>
                                                                <Dropdown drop='down-centered' align={"start"} autoClose="inside" focusFirstItemOnShow={false}>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <RiFilter3Line />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                        <div>
                                                                            <Form as={Row} md={12}>
                                                                                <Form.Group as={Col} md={6}>
                                                                                    <Form.Label>Data Inicial de Criação</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type="date"
                                                                                        value={dataInicioSolicitacao != null ? moment(dataInicioSolicitacao).format("YYYY-MM-DD") : ''}
                                                                                        onChange={(e) => {
                                                                                            setDataInicioSolicitacao(e != null ? e.target.value : null)
                                                                                        }}
                                                                                    />
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={6}>
                                                                                    <Form.Label>Data Final de Criação</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type="date"
                                                                                        value={dataFinalSolicitacao != null ? moment(dataFinalSolicitacao).format("YYYY-MM-DD") : ''}
                                                                                        onChange={(e) => {
                                                                                            setDataFinalSolicitacao(e != null ? e.target.value : null)
                                                                                        }}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Form>
                                                                        </div>
                                                                        <Form.Check
                                                                            size="lg"
                                                                            label="Ver Tudo"
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            onChange={() => { setVisualizarTudoSolicitacao(!visualizarTudoSolicitacao) }}
                                                                            checked={visualizarTudoSolicitacao}
                                                                        />
                                                                        <Dropdown.Item className="dropdownItemClass">
                                                                            <div style={{ margin: 5, justifyContent: 'flex-end', flexDirection: 'row', display: 'flex'}}>
                                                                                <Button variant="success" onClick={() => {FiltrarDataDashboardStatusSolicitacao()}}
                                                                                disabled={carregando ? false : true}>Atualizar</Button>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col> 
                                                        </Row>
                                                        <Pie
                                                            data={{
                                                                labels: descricaoStatus,
                                                                datasets: [
                                                                    {
                                                                        data: quantidadeStatus,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Valor total dos subitens empenhados do cotrato por categoria (em reais)  ',
                                                                        fontSize: 20
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    }
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                        />
                                                        <Row style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                                                            
                                                            <Col md={6}>
                                                                <Button variant="success" onClick={BaixarRelatorioDashboardStatusSolicitacaoFornecimento}>Exportar</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    
                                                </center>
                                            </Card>
                                        </Col>

                                        <Col md={6}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico de status de autorização
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Col>
                                                            <Row>
                                                                <Col style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', marginTop: '1%'}}>
                                                                    <strong>Filtro de Data:</strong>
                                                                </Col>
                                                                <Col  style={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'flex'}}>
                                                                    <Dropdown autoClose="inside" focusFirstItemOnShow={false}>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                            <RiFilter3Line />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu
                                                                            style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                            <div>
                                                                                <Form as={Row} md={12}>
                                                                                    <Form.Group as={Col} md={6}>
                                                                                        <Form.Label>Data Inicial de Criação</Form.Label>
                                                                                        <Form.Control
                                                                                            style={{ minHeight: '36px' }}
                                                                                            type="date"
                                                                                            value={dataInicioAutorizacao != null ? moment(dataInicioAutorizacao).format("YYYY-MM-DD") : ''}
                                                                                            onChange={(e) => {
                                                                                                setDataInicioAutorizacao(e != null ? e.target.value : null)
                                                                                            }}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    <Form.Group as={Col} md={6}>
                                                                                        <Form.Label>Data Final de Criação</Form.Label>
                                                                                        <Form.Control
                                                                                            style={{ minHeight: '36px' }}
                                                                                            type="date"
                                                                                            value={dataFinalAutorizacao != null ? moment(dataFinalAutorizacao).format("YYYY-MM-DD") : ''}
                                                                                            onChange={(e) => {
                                                                                                setDataFinalAutorizacao(e != null ? e.target.value : null)
                                                                                            }}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </div>
                                                                            <Form.Check
                                                                                size="lg"
                                                                                label="Ver Tudo"
                                                                                type="switch"
                                                                                id="custom-switch"
                                                                                onChange={() => { setVisualizarTudoAutorizacao(!visualizarTudoAutorizacao) }}
                                                                                checked={visualizarTudoAutorizacao}
                                                                            />
                                                                            <Dropdown.Item className="dropdownItemClass">
                                                                                <div style={{ margin: 5, justifyContent: 'flex-end', flexDirection: 'row', display: 'flex'}}>
                                                                                    <Button variant="success" onClick={() => {FiltrarDataDashboardStatusAutorizacao()}}
                                                                                    disabled={carregando ? false : true}>Atualizar</Button>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                        </Col> 
                                                        <Pie
                                                            data={{
                                                                labels: descricaoStatusAutorizacao,
                                                                datasets: [
                                                                    {
                                                                        data: quantidadeStatusAutorizacao,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Valor total dos subitens empenhados do cotrato por categoria (em reais)  ',
                                                                        fontSize: 20
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    }
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                        />
                                                    </Card.Body>

                                                </center>
                                            </Card>
                                        </Col>

                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico de status de medição
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Col>
                                                            <Row>
                                                                <Col style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', marginTop: '1%'}}>
                                                                    <strong>Filtro de Data:</strong>
                                                                </Col>
                                                                <Col  style={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'flex'}}>
                                                                    <Dropdown autoClose="inside" focusFirstItemOnShow={false}>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                            <RiFilter3Line />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu
                                                                            style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                            <div>
                                                                                <Form as={Row} md={12}>
                                                                                    <Form.Group as={Col} md={6}>
                                                                                        <Form.Label>Data Inicial de Criação</Form.Label>
                                                                                        <Form.Control
                                                                                            style={{ minHeight: '36px' }}
                                                                                            type="date"
                                                                                            value={dataInicioMedicao != null ? moment(dataInicioMedicao).format("YYYY-MM-DD") : ''}
                                                                                            onChange={(e) => {
                                                                                                setDataInicioMedicao(e != null ? e.target.value : null)
                                                                                            }}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    <Form.Group as={Col} md={6}>
                                                                                        <Form.Label>Data Final de Criação</Form.Label>
                                                                                        <Form.Control
                                                                                            style={{ minHeight: '36px' }}
                                                                                            type="date"
                                                                                            value={dataFinalMedicao != null ? moment(dataFinalMedicao).format("YYYY-MM-DD") : ''}
                                                                                            onChange={(e) => {
                                                                                                setDataFinalMedicao(e != null ? e.target.value : null)
                                                                                            }}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Form>
                                                                            </div>
                                                                            <Form.Check
                                                                                size="lg"
                                                                                label="Ver Tudo"
                                                                                type="switch"
                                                                                id="custom-switch"
                                                                                onChange={() => { setVisualizarTudoMedicao(!visualizarTudoMedicao) }}
                                                                                checked={visualizarTudoMedicao}
                                                                            />
                                                                            <Dropdown.Item className="dropdownItemClass">
                                                                                <div style={{ margin: 5, justifyContent: 'flex-end', flexDirection: 'row', display: 'flex'}}>
                                                                                    <Button variant="success" onClick={() => {FiltrarDataDashboardStatusMedicao()}}
                                                                                    disabled={carregando ? false : true}>Atualizar</Button>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                        </Col> 
                                                        <Pie
                                                            data={{
                                                                labels: descricaoStatusMedicao,
                                                                datasets: [
                                                                    {
                                                                        data: quantidadeStatusMedicao,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Valor total dos subitens empenhados do cotrato por categoria (em reais)  ',
                                                                        fontSize: 20
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    }
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                        />
                                                    </Card.Body>

                                                </center>
                                            </Card>
                                        </Col>

                                        <Col md={6}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico de subitens medidos e empenhados por contrato (em reais)
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Pie
                                                            data={{
                                                                labels: categoriaAutorizacao,
                                                                datasets: [
                                                                    {
                                                                        data: valorAutorizacao,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Gráfico de subitens medidos e empenhados por contrato (em reais) ',
                                                                        fontSize: 60
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    }
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                            ref={input => {
                                                                chartInstance = input;
                                                            }}
                                                        />

                                                        <Row style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                                                            <Col md={2}>
                                                                <Button variant="success" onClick={BaixarRelatorioDashboardValorTotalCategoriaGastaPorAutorizacaoFornecimento}>Exportar</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    
                                                </center>
                                            </Card>
                                            {/*<Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico do valor total dos subitens empenhados do cotrato por categoria (em reais)
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Pie
                                                            data={{
                                                                labels: descricaoCategoria,
                                                                datasets: [
                                                                    {
                                                                        data: valorCategoria,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Valor total dos subitens empenhados do cotrato por categoria (em reais)  ',
                                                                        fontSize: 20
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    }
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                        />
                                                        <Row style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                                                           
                                                            <Col md={2}>
                                                                <Button variant="success" onClick={BaixarRelatorioDashboardValorTotalContratoPorCategoria}>Exportar</Button>
                                                            </Col>

                                                        </Row>
                                                    </Card.Body>
                                                </center>
                                            </Card>*/}
                                        </Col>

                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico do valor total empenhado no contrato por mês - Gráfico do valor total empenhado no contrato por região
                                                    </Card.Header>
                                                </center>
                                                <Card.Body>
                                                    <Row className="mb-3">

                                                        <Col md={6}>
                                                            <center>
                                                                <div className="GraficoPie">
                                                                    <Pie className="GraficoPie"
                                                                        data={{
                                                                            labels: descricaoMesFinalizado,
                                                                            datasets: [
                                                                                {
                                                                                    data: valorMesFinalizado,
                                                                                    backgroundColor: chartColors,
                                                                                    hoverBackgroundColor: chartColors,
                                                                                    borderWidth: 0,
                                                                                    hoverOffset: 4
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            minBodyHeight: 500,
                                                                            plugins: {
                                                                                tooltip: {
                                                                                    callbacks: {
                                                                                        label: function (context) {

                                                                                            let label = context.label;

                                                                                            if (label) {
                                                                                                label += ': ';
                                                                                            }

                                                                                            if (context.parsed.y !== null) {

                                                                                                let sumTotal = 0.0;
                                                                                                let dataArr = context.chart.data.datasets[0].data;

                                                                                                dataArr.map((data, y) => {

                                                                                                    if (context.chart._hiddenIndices[y] != true) {
                                                                                                        sumTotal += data;
                                                                                                    }

                                                                                                });

                                                                                                label += context.formattedValue;
                                                                                            }

                                                                                            return label;

                                                                                        }, afterLabel: function (context) {

                                                                                            let sumTotal = 0.0;

                                                                                            if (context.parsed.y !== null) {

                                                                                                let dataArr = context.chart.data.datasets[0].data;

                                                                                                dataArr.map((data, y) => {

                                                                                                    if (context.chart._hiddenIndices[y] != true) {
                                                                                                        sumTotal += data;
                                                                                                    }

                                                                                                });
                                                                                            }

                                                                                            sumTotal = sumTotal.toLocaleString();;

                                                                                            return `total: ${sumTotal}`;
                                                                                        }
                                                                                    },
                                                                                },
                                                                                title: {
                                                                                    display: true,
                                                                                    align: 'center',
                                                                                    //text: '',
                                                                                    fontSize: 20
                                                                                },
                                                                                legend: {
                                                                                    display: true,
                                                                                    position: 'right',
                                                                                    align: 'center'
                                                                                }, datalabels: {
                                                                                    formatter: (value, ctx) => {

                                                                                        let sum = 0.0;
                                                                                        //let sumTotal = 0.0;
                                                                                        let dataArr = ctx.chart.data.datasets[0].data;

                                                                                        dataArr.map((data, y) => {

                                                                                            sum += data;

                                                                                            /*if (ctx.chart._hiddenIndices[y] != true) {
                                                                                                sumTotal += data;
                                                                                            }*/

                                                                                        });

                                                                                        //setValorTotalMes(sumTotal);

                                                                                        let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                                        return percentage;

                                                                                    },
                                                                                    color: '#000',
                                                                                }
                                                                            }
                                                                        }}
                                                                        height={500}
                                                                        width={500}
                                                                    />
                                                                </div>
                                                            </center>
                                                        </Col>

                                                        <Col md={6}>
                                                            <center>
                                                                <div className="GraficoPie">
                                                                    <Pie
                                                                        data={{
                                                                            labels: descricaoRegiao,
                                                                            datasets: [
                                                                                {
                                                                                    data: valorRegiaoFinalizado,
                                                                                    backgroundColor: chartColors,
                                                                                    hoverBackgroundColor: chartColors,
                                                                                    borderWidth: 0,
                                                                                    hoverOffset: 4
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            minBodyHeight: 500,
                                                                            tooltips: {
                                                                                enabled: true
                                                                            },
                                                                            plugins: {
                                                                                title: {
                                                                                    display: true,
                                                                                    align: 'center',
                                                                                    //text: 'Gráfico de autorizações do contrato por região',
                                                                                    fontSize: 20
                                                                                },
                                                                                legend: {
                                                                                    display: true,
                                                                                    position: 'right',
                                                                                    align: 'center'
                                                                                }, datalabels: {
                                                                                    formatter: (value, ctx) => {
                                                                                        let sum = 0;
                                                                                        let dataArr = ctx.chart.data.datasets[0].data;
                                                                                        dataArr.map(data => {
                                                                                            sum += data;
                                                                                        });
                                                                                        let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                                        return percentage;
                                                                                    },
                                                                                    color: '#000',
                                                                                }, tooltip: {
                                                                                    callbacks: {
                                                                                        label: function (context) {

                                                                                            let label = context.label;

                                                                                            if (label) {
                                                                                                label += ': ';
                                                                                            }

                                                                                            if (context.parsed.y !== null) {

                                                                                                let sumTotal = 0.0;
                                                                                                let dataArr = context.chart.data.datasets[0].data;

                                                                                                dataArr.map((data, y) => {

                                                                                                    if (context.chart._hiddenIndices[y] != true) {
                                                                                                        sumTotal += data;
                                                                                                    }

                                                                                                });

                                                                                                label += context.formattedValue;
                                                                                            }

                                                                                            return label;

                                                                                        }, afterLabel: function (context) {

                                                                                            let sumTotal = 0.0;

                                                                                            if (context.parsed.y !== null) {

                                                                                                let dataArr = context.chart.data.datasets[0].data;

                                                                                                dataArr.map((data, y) => {

                                                                                                    if (context.chart._hiddenIndices[y] != true) {
                                                                                                        sumTotal += data;
                                                                                                    }

                                                                                                });
                                                                                            }

                                                                                            sumTotal = sumTotal.toLocaleString();
                                                                                            return `Total: ${sumTotal}`;
                                                                                        }
                                                                                    },
                                                                                },
                                                                            }
                                                                        }}
                                                                        height={500}
                                                                        width={500}
                                                                    />

                                                                </div>
                                                            </center>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        /*
                                                        <Row className="mb-3">
                                                        <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                                            <Form.Label>Total Mês</Form.Label>
                                                            <Form.Control
                                                                config={currencyConfig}
                                                                as={IntlCurrencyInput}
                                                                currency="BRL"
                                                                type="text"
                                                                value={valorTotalMes}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                                            <Form.Label>Total Regiões</Form.Label>
                                                            <Form.Control
                                                                config={currencyConfig}
                                                                as={IntlCurrencyInput}
                                                                currency="BRL"
                                                                type="text"
                                                                value={valorTotalRegiao}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        </Row>
                                                        */
                                                    }

                                                    <Row className="mb-3">
                                                        <Col md={12}>
                                                            <center>
                                                                <Button variant="success" onClick={BaixarRelatorioDashboardAutorizacaoPorRegional}>Exportar</Button>
                                                            </center>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico de Obras e Intervenções de IP por Mês
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Row>
                                                            <Col style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', marginTop: '1%'}}>
                                                                <strong>Filtro de Ano:</strong>
                                                            </Col>
                                                            <Col  style={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'flex'}}>
                                                                <Dropdown drop='down-centered' align={"start"} autoClose="inside" focusFirstItemOnShow={false}>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <RiFilter3Line />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                        <div>
                                                                            <Select
                                                                                placeholder="Selecione..."
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                options={selectAno}
                                                                                onChange={(e) => { e ? setAno(e.value) : setAno([]) }}
                                                                            />
                                                                        </div>
                                                                        <Form.Check
                                                                            size="lg"
                                                                            label="Ver Tudo"
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            onChange={() => { setVisualizarTudoObras(!visualizarTudoObras) }}
                                                                            checked={visualizarTudoObras}
                                                                        />
                                                                        <Dropdown.Item className="dropdownItemClass">
                                                                            <div style={{ margin: 5, justifyContent: 'flex-end', flexDirection: 'row', display: 'flex'}}>
                                                                                <Button variant="success" onClick={() => {FiltrarDataDashboardObras()}}
                                                                                disabled={carregando ? false : true}>Atualizar</Button>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col> 
                                                        </Row>
                                                        <Pie
                                                            data={{
                                                                labels: descricaoObrasIntervencoesPorMes,
                                                                datasets: [
                                                                    {
                                                                        data: quantidadeObrasIntervencoesPorMes,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Valor total dos subitens empenhados do cotrato por categoria (em reais)  ',
                                                                        fontSize: 20
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    }
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                        />
                                                        <Row style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                                                            
                                                            <Col md={6}>
                                                                <Button variant="success" onClick={() => BaixarRelatorioDashboardObrasIntervencoesPorMes()}>Exportar</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    
                                                </center>
                                            </Card>
                                        </Col>

                                        <Col md={6}>
                                            <Card>
                                                <center>
                                                    <Card.Header>
                                                        Gráfico de Manutenções por Mês
                                                    </Card.Header>
                                                </center>
                                                <center>
                                                    <Card.Body className="GraficoPie">
                                                        <Row>
                                                            <Col style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', marginTop: '1%'}}>
                                                                <strong>Filtro de Ano:</strong>
                                                            </Col>
                                                            <Col style={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'flex'}}>
                                                                <Dropdown drop='down-centered' align={"start"} autoClose="inside" focusFirstItemOnShow={false}>
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <RiFilter3Line />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                        <div>
                                                                            <Select
                                                                                placeholder="Selecione..."
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                options={selectAnoManutencoes}
                                                                                onChange={(e) => { e ? setAnoManutencoes(e.value) : setAnoManutencoes([]) }}
                                                                            />
                                                                        </div>
                                                                        <Form.Check
                                                                            size="lg"
                                                                            label="Ver Tudo"
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            onChange={() => { setVisualizarTudoManutencoes(!visualizarTudoManutencoes) }}
                                                                            checked={visualizarTudoManutencoes}
                                                                        />
                                                                        <Dropdown.Item className="dropdownItemClass">
                                                                            <div style={{ margin: 5, justifyContent: 'flex-end', flexDirection: 'row', display: 'flex'}}>
                                                                                <Button variant="success" onClick={() => {FiltrarDataDashboardManutencoes()}}
                                                                                disabled={carregando ? false : true}>Atualizar</Button>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col> 
                                                        </Row>
                                                        <Pie
                                                            data={{
                                                                labels: descricaoManutencoesPorMes,
                                                                datasets: [
                                                                    {
                                                                        data: valorManutencoesPorMes,
                                                                        backgroundColor: chartColors,
                                                                        hoverBackgroundColor: chartColors,
                                                                        borderWidth: 0,
                                                                        hoverOffset: 4
                                                                    },
                                                                ]
                                                            }}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                tooltips: {
                                                                    enabled: true
                                                                },
                                                                plugins: {
                                                                    datalabels: {
                                                                        formatter: (value, ctx) => {

                                                                            let sum = 0;
                                                                            let dataArr = ctx.chart.data.datasets[0].data;
                                                                            dataArr.map(data => {
                                                                                sum += data;
                                                                            });
                                                                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                                                                            return percentage;
                                                                        },
                                                                        color: '#000',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        align: 'center',
                                                                        //text: 'Valor total dos subitens empenhados do cotrato por categoria (em reais)  ',
                                                                        fontSize: 20
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right',
                                                                        align: 'center'
                                                                    },
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label: function (context) {

                                                                                let label = context.label;

                                                                                if (label) {
                                                                                    label += ' (Total): ';
                                                                                }

                                                                                if (context.parsed.y !== null) {

                                                                                    let sumTotal = 0.0;
                                                                                    let dataArr = context.chart.data.datasets[0].data;

                                                                                    dataArr.map((data, y) => {

                                                                                        if (context.chart._hiddenIndices[y] != true) {
                                                                                            sumTotal += data;
                                                                                        }

                                                                                    });

                                                                                    label += context.formattedValue;
                                                                                }

                                                                                return label;

                                                                            }, afterLabel: function (context) {

                                                                                var labelCompleta = "";
                                                                                var comparativoMes = [];

                                                                                if(manutencoesPorMesLegenda){
                                                                                    let listaDescricao = manutencoesPorMesLegenda.map(x => 
                                                                                        x.filter(p => p.regiao.descricao == context.label));
                                                                   
                                                                                    if (context.parsed !== null) {

                                                                                        listaDescricao.map((data, y) => {
    
                                                                                            if(data.length > 0){
                                                                                                if (context.chart._hiddenIndices[y] != true) {
                                                                                                    var somaMes = 0.0;
                                                                                                    data.map((w, q) => {
                                                                                                        if(comparativoMes.length == 0 || comparativoMes.filter(b => b == w.idMes).length == 0){
                                                                                                            if(data.filter(d => d.idMes == w.idMes).length > 1){
                                                                                                                data.filter(d => d.idMes == w.idMes).map(v => {
                                                                                                                    somaMes += v.valor;
                                                                                                                });

                                                                                                                comparativoMes = [...comparativoMes, w.idMes];

                                                                                                                if(data.length > listaMeses.length){
                                                                                                                    labelCompleta += w.meses.descricao + "= " + `${somaMes.toFixed(2)}` + "\n";
                                                                                                                    somaMes = 0.0;
                                                                                                                }
                                                                                                            }
                                                                                                            else{
                                                                                                                labelCompleta += w.meses.descricao + "= " + `${w.valor.toFixed(2)}` + "\n";
                                                                                                                comparativoMes = [...comparativoMes, w.idMes];
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
    
                                                                                        });
                                                                                    }

                                                                                }

                                                                                return `${labelCompleta}`;
                                                                            }
                                                                        },
                                                                    },
                                                                }
                                                            }}
                                                            height={500}
                                                            width={500}
                                                        />
                                                        <Row style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                                                            
                                                            <Col md={6}>
                                                                <Button variant="success" onClick={() => BaixarRelatorioDashboardManutencoesPorMes()}>Exportar</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    
                                                </center>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                : 'No momento você não possui um contrato como preferêcia.'
                            }
                        </Card.Body>
                    </Card>
                </Col>

            </div>
        </>
    );
}
