export function ListarFaturamentoRequest() {
  return {
    type: 'LISTAR_FATURAMENTO_REQUEST'
  }
}

export function ListarFaturamentoResponse(listaFaturamento) {
  return {
    type: 'LISTAR_FATURAMENTO_RESPONSE',
    listaFaturamento
  }
}

export function ListarCompletoFaturamentoRequest(idEmpenho) {
  return {
    type: 'LISTAR_COMPLETO_FATURAMENTO_REQUEST', idEmpenho
  }
}

export function ListarCompletoFaturamentoResponse(listaCompletaFaturamento) {
  return {
    type: 'LISTAR_COMPLETO_FATURAMENTO_RESPONSE',
    listaCompletaFaturamento
  }
}

export function InserirFaturamentoRequest(data, idEmpenho) {
  return {
    type: 'INSERIR_FATURAMENTO_REQUEST',
    data, idEmpenho
  }
}

export function AlterarFaturamentoRequest(data, idEmpenho) {
  return {
    type: 'ALTERAR_FATURAMENTO_REQUEST',
    data, idEmpenho
  }
}

export function ExcluirFaturamentoRequest(id, idEmpenho) {
  return {
    type: 'EXCLUIR_FATURAMENTO_REQUEST',
    id, idEmpenho
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}