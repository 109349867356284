import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarStatusAutorizacaoFornecimentoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarStatusAutorizacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/StatusAutorizacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarStatusAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarStatusAutorizacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_STATUS_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarStatusAutorizacaoFornecimentoRequest)
])