import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {  Carregando, ListarAssinanteResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarAssinanteRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Assinante/Listar`, { headers: headerParams.token });

    yield put(ListarAssinanteResponse(result.data.result));
    yield put(Carregando(true));  

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarAssinanteResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* InserirAssinanteRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post,`api/Assinante/Inserir`, model.data, {headers : headerParams.token });

    toast.success(`Assinante cadastrado com sucesso!`);

    const result = yield call(api.get,`/api/Assinante/Listar`, {headers : headerParams.token });

    yield put(ListarAssinanteResponse(result.data.result));
    yield put(Carregando(true));


  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

   
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* AlterarAssinanteRequest(model){
  try{
    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put,`/api/Assinante/Alterar`, model.data,{headers : headerParams.token });

    toast.success('Assinante alterado com sucesso!');

    const result = yield call(api.get,`/api/Assinante/Listar`,{headers : headerParams.token });

    yield put(ListarAssinanteResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e){
    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function * ExcluirAssinanteRequest(id){
  try{
    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete,`/api/Assinante/Deletar/${id.id}`,{headers : headerParams.token });
    toast.success("Assinante deletado com sucesso!");

    const result = yield call(api.get,`/api/Assinante/Listar`, {headers : headerParams.token });

    yield put(ListarAssinanteResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e){
    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

export default all([
  takeLatest('LISTAR_ASSINANTE_REQUEST', ListarAssinanteRequest),
  takeLatest('INSERIR_ASSINANTE_REQUEST', InserirAssinanteRequest),
  takeLatest('ALTERAR_ASSINANTE_REQUEST', AlterarAssinanteRequest),
  takeLatest('EXCLUIR_ASSINANTE_REQUEST', ExcluirAssinanteRequest)
])