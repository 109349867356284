import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarContratoResponse, BuscarContratoPorIdResponse, Carregando } from './actions';
import { ListarContratoVersaoResponse } from '../contratoVersao/actions';
import { InserirContratoUsuarioRequest } from '../contratoUsuario/actions';
import { AtualizarToken, CriarTxt } from '../global/actions';
import { toast } from 'react-toastify';
import { ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';
import { ListarSolicitacaoFornecimentoResponse } from '../solicitacaoFornecimento/actions';

function* ListarContratoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Contrato/listarCompleto`, { headers: headerParams.token });

    yield put(ListarContratoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarContratoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BuscarContratoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Contrato/${model.id}`, { headers: headerParams.token });

    yield put(BuscarContratoPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(BuscarContratoPorIdResponse(null));
    yield put(Carregando(true));

  }
}

function* InserirContratoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var result = yield call(api.post, `/api/Contrato`, model.data, { headers: headerParams.token });

    const resultBusca = yield call(api.get, `/api/Contrato/${result.data.result}`, { headers: headerParams.token });

    yield put(BuscarContratoPorIdResponse(resultBusca.data.result));
    yield put(Carregando(true));

    history.push(`/alterarGeralContrato/${result.data.result}`);

    toast.success(`O contrato foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarContratoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/Contrato`, model.data, { headers: headerParams.token });
    yield put(Carregando(true));

    toast.success(`O contrato foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirContratoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/Contrato/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));

    toast.success(`Contrato id: ${model.id} foi excluido!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ImportarContratoExcelRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    //Importar
    yield call(api.post, `/api/Contrato/importar`, model.data, { headers: headerParams.token });

    //Listando Contrato    
    const result = yield call(api.get, `/api/Contrato/${model.idContrato}`, { headers: headerParams.token });
    yield put(BuscarContratoPorIdResponse(result.data.result));

    //Listando Versão do Contrato
    const resultVesao = yield call(api.post, `/api/ContratoVersao/listarCompleto`,
        { IdContrato: parseInt(model.idContrato) }, { headers: headerParams.token });
    yield put(ListarContratoVersaoResponse(resultVesao.data.result));

    yield put(Carregando(true));
    toast.success(`Itens importados com sucesso!`);
    
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
          yield put(CriarTxt(e.response.data.message));
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    toast.error("Erro na importação de SubItems");
  }
}

export default all([
  takeLatest('LISTAR_CONTRATO_REQUEST', ListarContratoRequest),
  takeLatest('BUSCAR_CONTRATO_POR_ID_REQUEST', BuscarContratoPorIdRequest),
  takeLatest('INSERIR_CONTRATO_REQUEST', InserirContratoRequest),
  takeLatest('ALTERAR_CONTRATO_REQUEST', AlterarContratoRequest),
  takeLatest('EXCLUIR_CONTRATO_REQUEST', ExcluirContratoRequest),
  takeLatest('IMPORTAR_CONTRATO_EXCEL', ImportarContratoExcelRequest)
])