import { Modal, Button, Form, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListarEmpenhoRequest, TransferirAfRequest } from "../../store/modules/empenho/actions";

export default function ModalTransferenciaAfEmpenho({ show, onHide, idAutorizacao, idEmpenhoOrigem }) {

    const dispatch = useDispatch();
    const [idEmpenho, setIdEmpenho] = useState('');    
    const listaEmpenho = useSelector(state => state.empenho.listaEmpenho);
    const [validated, setValidated] = useState(false);

    function Transferir() {

        var dataTransferencia = {
            'IdAutorizacao': idAutorizacao,
            'idEmpenhoOrigem': idEmpenhoOrigem,
            'idEmpenhoDestino': idEmpenho != null ? parseInt(idEmpenho) : null
        }

        dispatch(TransferirAfRequest(dataTransferencia));
        setIdEmpenho('');
        onHide();
    }

    useEffect(() => {
        dispatch(ListarEmpenhoRequest());
    }, [])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {`Deseja transferir a autorização de id: ${idAutorizacao} para qual empenho?`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col} md={6} controlId="formIdEmpenho">
                    <Form.Label>Empenho</Form.Label>
                    <Form.Select
                        onChange={(e) => setIdEmpenho(e.target.value)}
                        value={idEmpenho}
                        required
                        isInvalid={validated && idEmpenho === "" ? true : false}
                        isValid={validated && idEmpenho !== "" ? true : false}>
                        <option value="">Selecione...</option>
                        {
                            listaEmpenho != undefined ?
                                listaEmpenho.filter(x => x.statusAtual == 'Inicial' && x.id != idEmpenhoOrigem).map(m => (
                                    <option value={m.id} key={m.id}>{m.descricao}</option>
                                ))
                                :
                                null
                        }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">O empenho é obrigatório.</Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={Transferir}>
                    {'Transferir'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}