import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
    AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest,
    BaixarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest,
    ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest,
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest,
    ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest,
    CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest
} from "../../../store/modules/autorizacaoFornecimentoSubitemCustoMedicaoRegFoto/actions";
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { toast } from "react-toastify";

export default function RegistroFotografico() {

    const { idMedicao, idMedicaoSubItem } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto =
        useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicaoRegFoto.listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);

    const [listaFile, setListaFile] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [observacao, setObservacao] = useState('');
    const [show, setShow] = useState(false);
    const [showFoto, setShowFoto] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFotoClose = () => setShowFoto(false);
    const handleFotoShow = () => setShowFoto(true);

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idMedicao !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

        if (idMedicaoSubItem != undefined) {
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(idMedicaoSubItem));
        }

    }, [idMedicaoSubItem])

    function InserirRegistroFotografico() {

        let formData = new FormData();

        if (listaFile.length > 0) {

            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdAutorizacaoFornecimentoSubitemCustoMedicao', idMedicaoSubItem);
                formData.append('IdUsuario', usuario.id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setObservacao('');
            setListaFile([]);
            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(formData, idMedicaoSubItem));
            handleClose();

        } else {
            toast.error('Para continuar insira um documento');
        }
    }

    function AlterarRegistroFotografico(registroFotografico, novoValorCampoEditado, colunaEditada) {

        // Valida alteração do campo nome arquivo
        if (colunaEditada.field == "nomeRegistroFotografico") {

            if (novoValorCampoEditado == null || novoValorCampoEditado == undefined
                || novoValorCampoEditado == ' ' || novoValorCampoEditado == '') {

                toast.error(`Nome do registro fotográfico inválido`);
                return;
            }

            if (novoValorCampoEditado.length > 150) {
                toast.error(`O nome do registro fotográfico não pode conter mais que 150 caracteres`);
                return;
            }
        }

        // Valida alteração do campo observação
        if (colunaEditada.field == "observacao") {
            if (novoValorCampoEditado.length > 500) {
                toast.error(`A observação não pode conter mais que 500 caracteres`);
                return;
            }
        }

        if (colunaEditada != null) {

            let data = {
                'Id': registroFotografico.id,
                'IdAutorizacaoFornecimentoSubitemCustoMedicao': registroFotografico.idAutorizacaoFornecimentoSubitemCustoMedicao,
                'Latitude': registroFotografico.latitude,
                'Longitute': registroFotografico.longitute,
                'FotoGuid': registroFotografico.fotoGuid,
                'DataInclusao': registroFotografico.dataInclusaoApi,
                'IdUsuario': registroFotografico.idUsuario,
                'NomeRegistroFotografico': colunaEditada.field == "observacao" ? registroFotografico.nomeRegistroFotografico : novoValorCampoEditado,
                'DescricaoExtensaoArquivo': registroFotografico.descricaoExtensaoArquivo,
                'Observacao': colunaEditada.field == "observacao" ? novoValorCampoEditado : registroFotografico.observacao
            };

            dispatch(AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(data, idMedicaoSubItem));
        }
    }

    function ExcluirRegistroFotografico(idRegistroFotografico) {
        dispatch(ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(idRegistroFotografico, idMedicaoSubItem));
    }

    function BaixarRegistroFotografico(idRegistroFotografico) {
        dispatch(BaixarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(idRegistroFotografico));
    }

    function VisualizarRegistroFotografico(linha) {
        dispatch(CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(linha.id));
        handleFotoShow();
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                    listaMedicaoAutorizacaoFornecimentoStatus
                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                    ?
                                    <Button variant="success" onClick={handleShow} style={{ width: '100%' }}>Adicionar Fotos</Button>
                                    :
                                    null
                            }
                            columns={[
                                {
                                    title: "Número do Registro", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                        width: '10%',
                                    }
                                },
                                {
                                    title: "Nome", field: 'nomeRegistroFotografico', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Usuário", field: 'usuario', editable: 'never', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Data", field: 'dataInclusao', editable: 'never', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Observação", field: 'observacao', cellStyle: {
                                        width: '22.5%'
                                    },
                                    render: rowData => rowData.observacao == '' ?
                                        <div className="spanVazio"></div>
                                        : rowData.observacao
                                }
                            ]}
                            data={
                                listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto != undefined ?
                                    listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto.map(doc => {
                                        return {
                                            id: doc.id,
                                            nomeRegistroFotografico: doc.nomeRegistroFotografico,
                                            usuario: doc.usuario.nome,
                                            dataInclusao: moment(doc.dataInclusao).format('L LT'),
                                            observacao: doc.observacao,

                                            // Info para alteração de documento arquivo
                                            dataInclusaoApi: doc.dataInclusao,
                                            descricaoExtensaoArquivo: doc.descricaoExtensaoArquivo,
                                            fotoGuid: doc.fotoGuid,
                                            idAutorizacaoFornecimentoSubitemCustoMedicao: doc.idAutorizacaoFornecimentoSubitemCustoMedicao,
                                            idUsuario: doc.idUsuario,
                                            latitude: doc.latitude,
                                            longitude: doc.longitude,
                                        }
                                    })
                                    :
                                    []
                            }
                            cellEditable={{
                                onCellEditApproved:
                                    (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                AlterarRegistroFotografico(rowData, newValue, columnDef);
                                                resolve()
                                            }, 1000)
                                        });
                                    }
                            }}
                            editable={{
                                onRowDelete:
                                    idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                        listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                        ?
                                        oldData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    ExcluirRegistroFotografico(oldData.id);
                                                    resolve()
                                                }, 1000)
                                            })
                                        :
                                        null
                            }}
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                actionsColumnIndex: -1,
                                filtering: true,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'visibility',
                                    onClick: (event, rowData) => { VisualizarRegistroFotografico(rowData) }
                                }),
                                {
                                    icon: 'download',
                                    onClick: (event, rowData) => BaixarRegistroFotografico(rowData.id)
                                }
                            ]}
                            localization={{
                                header: {
                                    actions: 'Ações'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: '',
                                    searchPlaceholder: 'Pesquisar',
                                    exportTitle: "",
                                    exportCSVName: "Exportar como CSV",
                                    exportPDFName: "Exportar como PDF",
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                </Col>
                <Col md={12}>
                    {
                        idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined
                            ?
                            // Verificação se o status atual é o cancelado
                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                        A Medição está Cancelada!
                                    </Alert>
                                </Col>
                                :
                                // Verificação se o status atual é o finalizado
                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            A Medição está Finalizada!
                                        </Alert>
                                    </Col>
                                    :
                                    ''
                            :
                            ''
                    }
                </Col>
            </div>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Fotos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formFoto">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={InserirRegistroFotografico}>Salvar Foto</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFoto} onHide={handleFotoClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formFoto">
                                <div id="divImagemFotoMedicaoAutorizacaoFornecimentoSubItem">
                                </div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>

        </>
    )
}