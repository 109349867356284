export function ListarSolicitacaoFornecimentoDocumentoArquivoRequest(id) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function ListarSolicitacaoFornecimentoDocumentoArquivoResponse(listaSolicitacaoFornecimentoDocumentoArquivo) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_RESPONSE',
    listaSolicitacaoFornecimentoDocumentoArquivo
  }
}

export function InserirSolicitacaoFornecimentoDocumentoArquivoRequest(data, id) {
  return {
    type: 'INSERIR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    data,
    id
  }
}

export function AlterarSolicitacaoFornecimentoDocumentoArquivoRequest(data, id) {
  return {
    type: 'ALTERAR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    data,
    id
  }
}

export function ExcluirSolicitacaoFornecimentoDocumentoArquivoRequest(id, idSolicitacao) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id,
    idSolicitacao
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}