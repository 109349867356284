import { useEffect, useState } from "react";
import { Button, Row, Col, Card, Modal, Form, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Loading from "../../../components/Loading/loading";
import Select from 'react-select';
import MaterialTable from 'material-table';
import * as XLSX from "xlsx";
import 'moment/locale/pt-br';
import moment from 'moment';
import { ListarObrasIntervencoesPorMesRequest, InserirObrasIntervencoesPorMesRequest, 
    BaixarPadraoImportacaoObrasIntervencoesPorMesRequest, ExcluirObrasIntervencoesPorMesRequest } from "../../../store/modules/obrasIntervencoesPorMes/actions";
import { ListarMesesRequest } from "../../../store/modules/meses/actions";
import Header from "../../../components/Header/header";
import { RiCalendarTodoFill } from "react-icons/ri";

export default function Listar() {

    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaObrasIntervencoesPorMes = useSelector(state => state.obrasIntervencoesPorMes.listaObrasIntervencoesPorMes);
    const listaMeses = useSelector(state => state.meses.listaMeses);
    const usuarioCompleto = useSelector(state => state.usuario.usuario);

    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [showError, setShowError] = useState(false);
    const [ano, setAno] = useState([]);
    const [hierarquiaCompleta, setHierarquiaCompleta] = useState([]);
    const [showMensagem, setShowMensagem] = useState(false);
    const [showExcluir, setShowExcluir] = useState(false);
    const [anoObrasExcluir, setAnoObrasExcluir] = useState(0);
    //const [confirmacao, setConfirmacao] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const handleCloseMensagem = () => setShowMensagem(false);
    const handleShowMensagem = () => setShowMensagem(true);

    const handleCloseExcluir = () => setShowExcluir(false);
    const handleShowExcluir = () => setShowExcluir(true);
    
    const colunas = listaMeses ?
    listaMeses.map(lista => {
        return{
        title: lista.descricao, field: `${lista.id}`
        }
    })
    :
    [];

    const selectAno = [];
    const optionsAno = listaObrasIntervencoesPorMes ?
        [...listaObrasIntervencoesPorMes].reduce(function(opcoes, index){
            let key = index['ano'];
            if(!opcoes[key]){
                opcoes[key] = [];
            }
            if(opcoes[key].length === 0){
                opcoes[key].push({value: index.ano, label: index.ano});
                selectAno.push({value: index.ano, label: index.ano});
                return opcoes;
            }
            else{
                return opcoes;
            }
        }, {})
    :
    [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setItems(d);
        });

    };

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarObrasIntervencoesPorMesRequest());
        dispatch(ListarMesesRequest())

    }, [])

    /*useEffect(() => {

        if(listaObrasIntervencoesPorMes && listaMeses){
            constroiData()
        }

    }, [listaObrasIntervencoesPorMes, listaMeses])

    function constroiData(){

        if(listaObrasIntervencoesPorMes && listaMeses){

            let index = 0;
            var quantidade = [];
            let hierarquiaCompleta = [];
            let lengthObras = 0;

            listaObrasIntervencoesPorMes.map(function(lista){

                for (index; index < listaMeses.length; index++) {
                    quantidade.push({[`idMes`]: lista.idMes,
                                    [`quantidade`]: lista.quantidade});
                    index++
                    break;
                }
                lengthObras = listaMeses.length;

            })

            listaObrasIntervencoesPorMes.map(function(lista, i){
                if(i == 0){
                    hierarquiaCompleta.push({
                        statusConcluido: lista.statusConcluido,
                        Ano: lista.ano,
                        Data: lista.dataImportacao,
                        quantidade: null
                    })
                }
                else if(listaObrasIntervencoesPorMes[i - 1].statusConcluido !== lista.statusConcluido){

                    hierarquiaCompleta = hierarquiaCompleta.map(x => {
                        return{
                            statusConcluido: x.statusConcluido,
                            Ano: x.ano,
                            Data: x.dataImportacao,
                            quantidade: quantidade
                        }
                    });

                    hierarquiaCompleta.push({
                        statusConcluido: lista.statusConcluido,
                        Ano: lista.ano,
                        Data: lista.dataImportacao,
                        quantidade: null
                    })

                    quantidade = [];
                    lengthObras +=  listaMeses.length;
                }
                if(quantidade.length === 0){
                    for (index; index < lengthObras; index++) {
                        quantidade.push({[`idMes`]: listaObrasIntervencoesPorMes[index].idMes,
                                    [`quantidade`]: listaObrasIntervencoesPorMes[index].quantidade});
                    }
                }
                if(listaObrasIntervencoesPorMes.length - 1 == i){

                    hierarquiaCompleta = hierarquiaCompleta.map(x => {
                        if(x.quantidade == null){
                            return{
                                statusConcluido: lista.statusConcluido,
                                Ano: lista.ano,
                                Data: lista.dataImportacao,
                                quantidade: quantidade
                            }
                        }
                        else{
                            return{
                                statusConcluido: x.statusConcluido,
                                Ano: x.ano,
                                Data: x.dataImportacao,
                                quantidade: x.quantidade
                            }
                        }
                    });

                }
                    
            })
            
            /*if(hierarquiaCompleta){

                var teste = hierarquiaCompleta.map(i => {
                    return{
                        ... i.quantidade ? i.quantidade.map(x => {
                            return x
                        }) : null,
                        statusConcluido: i.statusConcluido,
                        Ano: i.Ano,
                        Data: i.Data,
                    }
                })

                setHierarquiaCompleta(teste);
            }

            setHierarquiaCompleta(hierarquiaCompleta);

        }
    }*/

    function Validar(e){

        if (items.length > 0 && ano.length > 0) {

            e.preventDefault();

            const propertyValues = Object.values(items[0]);
    
            if(listaMeses){
                var mesesComparados = [];
                var listasComparadas = propertyValues.map(function(l, m){
                    if(m > 0 && listaMeses.filter(e => e.descricao.trim().toLowerCase() == l.toString().trim().toLowerCase()).length === 0){
                        mesesComparados.push(l);
                    }
                })
    
                if(mesesComparados.length > 0){
    
                    handleShowMensagem();
                    setMessageError(`O mês: " ${mesesComparados.map(l => {return l ? l : null})} " está presente no Excel de importação, porém, ` +
                    `não faz parte do banco atual. Caso continue esta ação, este mês não será listado com o respectivo valor. Deseja continuar?`);
    
                }
                else{
                    ImportarArquivo(e);
                }
            }
        }
        else{
            handleShowError();
            setMessageError("Selecione o Excel e o Ano antes de Importar!");
            setShowError(true);
        }

    }

    function ImportarArquivo(e) {

        if (listaMeses && ano.length > 0) {

            let lista = [];
            var data = [];

            try {

                if (items.length > 0) {

                    items.forEach(function(item, i){

                        if (item.__rowNum__ === 2) {

                            for (let x = 0; x < listaMeses.length; x++) {
                                if(x == 0){

                                    data.push({
                                        'Id': 0,
                                        'IdMes': listaMeses.filter(m => m.descricao.trim().toLowerCase() == items[0][`__EMPTY`].trim().toLowerCase())[0].id,
                                        'Quantidade': item[`__EMPTY`] ? item[`__EMPTY`] : 0,
                                        'StatusConcluido': true,
                                        'DataImportacao': moment((new Date())).add('hours', 2),
                                        'Ano': parseInt(moment(ano).format("YYYY")),
                                    })

                                }
                                else{
                                    if(items[0][`__EMPTY_${x}`] &&
                                    listaMeses.filter(m => m.descricao.trim().toLowerCase() == items[0][`__EMPTY_${x}`].trim().toLowerCase()).length > 0){
                                        data.push({
                                            'Id': 0,
                                            'IdMes': listaMeses.filter(m => m.descricao.trim().toLowerCase() == items[0][`__EMPTY_${x}`].trim().toLowerCase())[0].id,
                                            'Quantidade': item[`__EMPTY_${x}`] ? item[`__EMPTY_${x}`] : 0,
                                            'StatusConcluido': true,
                                            'DataImportacao': moment((new Date())).add('hours', 2),
                                            'Ano': parseInt(moment(ano).format("YYYY")),
                                        })
                                    }
                                    
                                }
                                
                            }

                        }

                        if (item.__rowNum__ === 3) {

                            for (let x = 0; x < listaMeses.length; x++) {

                                if(x == 0){

                                    data.push({
                                        'Id': 0,
                                        'IdMes': listaMeses.filter(m => m.descricao.trim().toLowerCase() == items[0][`__EMPTY`].trim().toLowerCase())[0].id,
                                        'Quantidade': item[`__EMPTY`] ? item[`__EMPTY`] : 0,
                                        'StatusConcluido': false,
                                        'DataImportacao': moment((new Date())).add('hours', 2),
                                        'Ano': parseInt(moment(ano).format("YYYY")),
                                    })

                                }
                                else{
                                    if(items[0][`__EMPTY_${x}`] && 
                                    listaMeses.filter(m => m.descricao.trim().toLowerCase() == items[0][`__EMPTY_${x}`].trim().toLowerCase()).length > 0){
                                        data.push({
                                            'Id': 0,
                                            'IdMes': listaMeses.filter(m => m.descricao.trim().toLowerCase() == items[0][`__EMPTY_${x}`].trim().toLowerCase())[0].id,
                                            'Quantidade': item[`__EMPTY_${x}`] ? item[`__EMPTY_${x}`] : 0,
                                            'StatusConcluido': false,
                                            'DataImportacao': moment((new Date())).add('hours', 2),
                                            'Ano': parseInt(moment(ano).format("YYYY")),
                                        })
                                    }
                                    
                                }
                                
                            }
                        }

                        i++;
                    });

                    lista.push(data);

                    if (lista.length > 0) {
                        dispatch(InserirObrasIntervencoesPorMesRequest(lista));
                        handleClose();
                        //handleCloseImport();
                    }
                    else {
                        toast.error('Não foi possível importar a lista');
                        return;
                    }

                } else {
                    handleShowError();
                    setMessageError("Selecione o Excel antes de Importar!");
                    setShowError(true);
                }

            } catch (error) {
                handleShowError();
                setMessageError("CAMPOS IMPORTANTES ESTÃO FALTANDO NO EXCEL, VERIFIQUE NOVAMENTE ANTES DE IMPORTAR");
                setShowError(true);
            }
        }
        else{
            handleShowError();
            setMessageError("Selecione o Excel e o Ano antes de Importar!");
            setShowError(true);
        }

        handleCloseMensagem();

    }

    function exportarPadraoImportacao(){
        dispatch(BaixarPadraoImportacaoObrasIntervencoesPorMesRequest())
    }

    function ExcluirObrasIntervencoes(ano) {

        if(anoObrasExcluir && anoObrasExcluir !== 0){
            if(anoObrasExcluir.length === 0){
                toast.error('Selecione o ano!');
                return;
            }
            else{
                dispatch(ExcluirObrasIntervencoesPorMesRequest(ano));
                handleCloseExcluir();
            }
        }
        else{
            toast.error('Selecione o ano!');
            return;
        }

    }


    return (
        <>
           <Header/>
             <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className ='divHeader'>
                            <Row>
                                <Col md={{ span:10}}>
                                    <span>
                                    < RiCalendarTodoFill size={25} color={"#000"} /> Obras/Intervenções de IP Por Mês
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className='divBody'>
                                <Row>
                                    <Col>
                                        {
                                            //carregando ?
                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title={
                                                        inserir == true ?
                                                            <Row>
                                                                <Col md='auto'>
                                                                    <Button variant="success" onClick={handleShow}>Importar</Button>
                                                                </Col>
                                                                <Col md='auto'>
                                                                    <Button variant="success" onClick={() => {exportarPadraoImportacao()}}>Exportar Padrão de Importação</Button>
                                                                </Col>
                                                                <Col md='auto'>
                                                                    <Button variant="danger" disabled={listaObrasIntervencoesPorMes && listaObrasIntervencoesPorMes.length > 0 ? false : true}
                                                                    onClick={() => {handleShowExcluir()}}>Excluir Obras/Intervenções</Button>
                                                                </Col>
                                                            </Row>
                                                        :
                                                        ''
                                                    }
                                                    columns={[
                                                        {
                                                            title: 'id', field: 'id', hidden: true,
                                                        },
                                                        {
                                                            title: 'Descrição', field: 'statusConcluido'
                                                        },
                                                        {
                                                            title: 'Mês', field: 'mes',
                                                        },
                                                        {
                                                            title: 'Quantidade', field: 'quantidade',
                                                        },
                                                        //...colunas,
                                                        {
                                                            title: 'Data de Importação', field: 'data',
                                                        },
                                                        {
                                                            title: 'Ano', field: 'ano', defaultGroupOrder: 0,
                                                        },
                                                    ]}
                                                    data={/*hierarquiaCompleta.length > 0 && colunas.length > 0 ? 
                                                        hierarquiaCompleta.map(function(l, i){
                                                            return{
                                                                statusConcluido: l.statusConcluido ? 'Definitivo' : 'Parcial',
                                                                ano: moment(l.ano).format("YYYY"),
                                                                data: moment(l.dataImportacao).format('L LT'),
                                                                [l.quantidade[i].idMes]: l.quantidade[i].quantidade
                                                            }
                                                        })*/
                                                        listaObrasIntervencoesPorMes ?
                                                        listaObrasIntervencoesPorMes.map(i => {
                                                            return{
                                                                id: i.id,
                                                                mes: i.meses.descricao,
                                                                statusConcluido: i.statusConcluido ? 'Concluído' : 'Parcial',
                                                                ano: i.ano,
                                                                quantidade: i.quantidade,
                                                                data: moment(i.dataImportacao).format('L LT'),
                                                            }
                                                        })
                                                        : []
                                                    }
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        actionsColumnIndex: -1,
                                                        addRowPosition: "first",
                                                        filtering: true,
                                                    }}
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                //:
                                                //<Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>   
            </div>

            <Modal
                show={showError}
                onHide={handleCloseError}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseError}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Importar Itens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formTipoDocumentoArquivo">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[]}
                                        onChange={(files) => {
                                            if (files.length > 0) {
                                                readExcel(files[0])
                                            } else {
                                                setItems([])
                                            }
                                        }}
                                        filesLimit={1}
                                        acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 1 arquivo`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                    <Form.Group as={Col} md={6} controlId="formDataInicioContrato">
                                        <Form.Label>Selecione o ano</Form.Label>
                                        <Form.Control
                                            type="month"
                                            value={ano}
                                            onChange={(e)=> setAno(e.target.value)}
                                            required
                                            isInvalid={ano.length > 0 ? false : true}
                                            isValid={ano.length > 0 ? true : false} />
                                    </Form.Group>
                                </div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={(e) => {Validar(e)}}>Importar Documento</Button>
                            :
                            ''
                    }
                </Modal.Footer>
            </Modal>

            <Modal
                show={showMensagem}
                onHide={handleCloseMensagem}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {ImportarArquivo()}}>
                        Sim
                    </Button>
                    <Button variant="success" onClick={handleCloseMensagem}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showExcluir}
                onHide={handleCloseExcluir}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Qual ano você deseja excluir?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>Selecione o ano: </p>
                        <div>
                            <Select
                                placeholder="Selecione..."
                                isClearable={true}
                                isSearchable={true}
                                options={selectAno}
                                onChange={(e) => { e ? setAnoObrasExcluir(e.value) : setAnoObrasExcluir([]) }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {ExcluirObrasIntervencoes(anoObrasExcluir)}}>
                        Excluir
                    </Button>
                    <Button variant="success" onClick={() => {handleCloseExcluir(); setAnoObrasExcluir(0)}}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
           
        </>
    )
}