import produce from 'immer';

const initialState = {
  listaTipoRecurso: undefined,
  carregando: true
};

export default function tipoRecurso(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_TIPO_RECURSO_RESPONSE':
      return produce(state, draft => {
        draft.listaTipoRecurso = action.listaTipoRecurso;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });    

    default:
      return state;
  }
}