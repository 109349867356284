export function ListarSolicitacaoFornecimentoRequest() {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_REQUEST'
  }
}

export function ListarSolicitacaoFornecimentoResponse(listaSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_RESPONSE',
    listaSolicitacaoFornecimento
  }
}

export function BuscarSolicitacaoFornecimentoPorIdRequest(id) {
  return {
    type: 'BUSCAR_SOLICITACAO_FORNECIMENTO_POR_ID_REQUEST',
    id
  }
}

export function BuscarSolicitacaoFornecimentoPorIdResponse(solicitacaoFornecimentoCompleto) {
  return {
    type: 'BUSCAR_SOLICITACAO_FORNECIMENTO_POR_ID_RESPONSE',
    solicitacaoFornecimentoCompleto
  }
}

export function InserirSolicitacaoFornecimentoRequest(data) {
  return {
    type: 'INSERIR_SOLICITACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function AlterarSolicitacaoFornecimentoRequest(data) {
  return {
    type: 'ALTERAR_SOLICITACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function AlterarPrazoSolicitacaoFornecimentoRequest(data) {
  return {
    type: 'ALTERAR_PRAZO_SOLICITACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function ExcluirSolicitacaoFornecimentoRequest(id) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_REQUEST',
    id
  }
}

export function ListarCompletoSolicitacaoFornecimentoRequest(data) {
  return {
    type: 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function InserirCoordenadasdSolicitacaoFornecimentoRequest(data) {
  return {
    type: 'INSERIR_COORDENADAS_SOLICITACAO_FORNECECIMENTO_REQUEST',
    data
  }
}

export function ReajustaOuCancelaSolicitacaoContrato(data) {
  return {
    type: 'REAJUSTA_OU_CANCELA_SOLICITACAO_CONTRATO',
    data
  }
}

export function LimparSolicitacaoFornecimento() {
  return {
    type: 'LIMPAR_SOLICITACAO_FORNECIMENTO'
  }
}

export function ListarSolicitacaoFornecimentoCamposVerificacaoRapidaRequest() {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_CAMPOS_VERIFICACAO_RAPIDA_REQUEST'
  }
}

export function ListarSolicitacaoFornecimentoCamposVerificacaoRapidaResponse(listarSolicitacaoFornecimentoCamposVerificacaoRapida) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_CAMPOS_VERIFICACAO_RAPIDA_RESPONSE',
    listarSolicitacaoFornecimentoCamposVerificacaoRapida
  }
}

export function BuscarCoordenadasPorEnderecoRequest(Endereco) {
  return {
    type: 'BUSCAR_COORDENADAS_POR_ENDERECO_REQUEST',
    Endereco
  }
}

export function BuscarCoordenadasPorEnderecoResponse(coordenadasPorEndereco) {
  return {
    type: 'BUSCAR_COORDENADAS_POR_ENDERECO_RESPONSE',
    coordenadasPorEndereco
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}

export function GerarOficioRequest(id, numero) {
    return {
        type: 'GERAR_OFICIO_REQUEST',
        id, numero
    }
}