export function ListarManutencoesPorMesRequest() {
    return {
      type: 'LISTAR_MANUTENCOES_POR_MES_REQUEST'
    }
  }
  
  export function ListarManutencoesPorMesResponse(listaManutencoesPorMes) {
    return {
      type: 'LISTAR_MANUTENCOES_POR_MES_RESPONSE',
      listaManutencoesPorMes
    }
  }
  
  export function InserirManutencoesPorMesRequest(data) {
    return {
      type: 'INSERIR_MANUTENCOES_POR_MES_REQUEST',
      data
    }
  }

  export function BaixarPadraoImportacaoManutencoesPorMesRequest() {
    return {
      type: 'BAIXAR_PADRAO_IMPORTACAO_MANUTENCOES_POR_MES_REQUEST'
    }
  }

  export function ExcluirManutencoesPorMesRequest(ano) {
    return {
        type: 'EXCLUIR_MANUTENCOES_POR_MES_REQUEST',
        ano
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }