import produce from 'immer';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import 'moment/locale/pt-br';
import moment from 'moment';

const initialState = {
  perfilCompleto: undefined,
  carregando: true
};

export default function perfil(state = initialState, action) {

  switch (action.type) {

    case 'ATUALIZAR_PERFIL_RESPONSE':

      return produce(state, draft => {

        const cookies = new Cookies();

        var tokenCookie = cookies.get('token');
        var token = JSON.parse(localStorage.getItem('token')).tokenCodificado;
        let decoded = jwt_decode(tokenCookie + token);

        var horaExpiracao = moment((new Date())).add('hours', 10);

        let dados = {
          token: decoded,
          tokenCodificado: token,
          usuario: action.data,
          hora: horaExpiracao.toString()
        }

        localStorage.setItem('token', JSON.stringify(dados));

      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });


    default:
      return state;
  }
}