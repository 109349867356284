import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import img from '../../assets/login/img_login.png';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#E4E4E4',
        fontSize: '10',
        paddingBottom: 65
    },
    titulo: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '15',
    },
    linha: {
        borderBottom: '1px solid #0000',
        width: '100%',
    },
    espaco: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    table: {
        width: '100%',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        marginBottom: 20
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: '10',
        height: '45',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowTitulo: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: '10',
        height: '30',
        justifyContent: 'center',
        alignItems: 'center'
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: '10',
        height: '30',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowCabecalho: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 2,
        paddingBottom: 2,
    },
    bold: {
        fontWeight: 'bold',
    },
    bordaCompleta: {
        border: '1px solid #0000',
        margin: 1,
        height: '30',
    },
    comeco: {
        width: "25%",
    },

    meio: {
        width: "50%",
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center'
    },
    alinharFim: {
        display: 'flex',
        textAlign: 'end',
        alignItems: 'end'
    },

    fim: {
        width: "25%",
    },
    linhaMetadeComeco: {
        width: "50%",
    },
    linhaMetadeFim: {
        width: "50%",
    },
    width7: {
        width: "7%",
        textAlign: 'center',
    },
    width8: {
        width: "8%",
        textAlign: 'center',
    },
    width10: {
        width: "10%",
        textAlign: 'center',
    },
    width11: {
        width: "11%",
        textAlign: 'center',
    },
    width20: {
        width: "20%",
        textAlign: 'center',
        fontSize: '9',
    },
    cabecalho: {
        color: '#fff',
        backgroundColor: '#212529',
        bordercolor: '#32383e'
    },
    cinza: {
        backgroundColor: '#e9ecef',
    },
    logo: {
        width: '70',
        height: '70'
    },
    top40: {
        top: '40'
    },
    top60: {
        top: '60'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 0,
        paddingTop: 2,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    margimBottom: {
        marginBottom: 0,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    espacogrande: {
        paddingTop: 100,
        paddingBottom: 100,
    },
    espacomedio: {
        paddingTop: 60,
        paddingBottom: 60,
    },
    fontSize8: {
        fontSize: 8,
    },
    fontSize6: {
        fontSize: 6,
    },
    marginBottomMenos50: {
        marginBottom: -50
    }
});

export default function MyDoc({ cabecalho, corpo, corpoAdicionado, percentualObra }) {

    return (
        <Document>
            {
                cabecalho != null && corpo != null ?

                    <Page size="A4" style={styles.page} orientation="landscape" fixed wrap>

                        <View style={[styles.rowCabecalho]} fixed>
                            <Text style={styles.comeco}>
                                <Image style={styles.logo} src={img} fixed />
                            </Text>
                            <Text style={[styles.titulo, styles.meio, styles.top40]}>
                                Boletim de Medição
                            </Text>
                            <Text style={[styles.fim, styles.top40]}>
                                Nº AS: {cabecalho[0].numeroAf}   Nº Contrato: {cabecalho[0].descricaoContrato}
                            </Text>
                        </View>
                        <View style={[styles.linha]} fixed></View>
                        <View style={[styles.section]} wrap>

                            <View style={[styles.rowCabecalho, styles.bold]} >
                                <Text style={[styles.comeco, styles.fontSize8]}>
                                    Processo: {cabecalho[0].numeroProcesso}
                                </Text>
                                <Text style={[styles.meio, styles.fontSize8]}>
                                    Orçamento Nº: {cabecalho[0].numeroOrcamento}
                                </Text>
                                <Text style={[styles.fim, styles.fontSize8]}>
                                    Ofício: {cabecalho[0].numeroOficio}
                                </Text>
                            </View>
                            <View style={[styles.rowCabecalho, styles.bold]} >
                                <Text style={[styles.comeco, styles.fontSize8]}>
                                    Local da Obra: {cabecalho[0].local}
                                </Text>
                                <Text style={[styles.meio, styles.fontSize8]}>
                                    Bairro: {cabecalho[0].descricaoBairro}
                                </Text>
                                <Text style={[styles.fim, styles.fontSize8]}>
                                    Fiscal Contratada:  _______________
                                </Text>
                            </View>
                            <View style={[styles.rowCabecalho, styles.bold]} >
                                <Text style={[styles.comeco, styles.fontSize8]}>
                                    Região: {cabecalho[0].descricaoRegiao}
                                </Text>
                                <Text style={[styles.meio, styles.fontSize8]}>
                                    Prazo de Execução: {cabecalho[0].tempo}
                                </Text>
                                <Text style={[styles.fim, styles.fontSize8]}>
                                    Data Medição:        _______________
                                </Text>
                            </View>
                            <View style={[styles.rowCabecalho, styles.bold]} >
                                <Text style={[styles.comeco, styles.fontSize8]}>
                                    Interessado: {cabecalho[0].interessado}
                                </Text>
                                <Text style={[styles.meio, styles.fontSize8]}>
                                    Endereço (Interes.): {cabecalho[0].endereco}
                                </Text>
                                <Text style={[styles.fim, styles.fontSize8]}>
                                    Tipo de Medição:    _______________
                                </Text>
                            </View>
                            <View style={[styles.espaco]}></View>
                            <View style={[styles.rowCabecalho, styles.bold]} >
                                <Text style={styles.comeco}></Text>
                                <Text style={[styles.meio, styles.bold, styles.fontSize8]}>SubItem Orçamento</Text>
                                <Text style={styles.fim}></Text>
                            </View>
                            <View style={[styles.espaco]}></View>
                            <View style={styles.table}>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.linha]}></View>
                                <View style={[styles.rowTitulo, styles.bold, styles.cabecalho]} fixed>
                                    <Text style={[styles.width10, styles.fontSize8]}>Cod. Interno</Text>
                                    <Text style={[styles.width20, styles.fontSize8]}>Atividade</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Un</Text>
                                    <Text style={[styles.width8, styles.fontSize8]}>Qtd Orçada</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Val. Unit. R$</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Total R$</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Qtd. Consum. Anterior</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Saldo</Text>
                                    <Text style={[styles.width5, styles.fontSize8]}>Total Exec. %</Text>
                                    <Text style={[styles.width11, styles.fontSize8]}>(Acum)Qtd méd</Text>
                                    <Text style={[styles.width11, styles.fontSize8]}>(Atual)Qtd méd</Text>


                                </View>
                                <View style={[styles.linha]}></View>
                                {
                                    corpoAdicionado != null && corpo != null && 
                                    corpo.length <= 4 && corpoAdicionado.length == 0 ? 
                                        <View>
                                            {
                                            corpo.map((c, i) => (
                                                <View>
                                                    {
                                                        (i + 1) % 2 == 0 ?
                                                            <View key={i} style={styles.row} break>
                                                                <Text style={[styles.width10, styles.fontSize8]}>{c.codigoInterno}</Text>
                                                                <Text style={[styles.width20, styles.fontSize6]}>{c.descricaoSubItemCusto}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.descricaoAbreviacao}</Text>
                                                                <Text style={[styles.width8, styles.fontSize8]}>{c.quantidade.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.valorUnitario.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.precoTotal.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.quantidadeConsumida.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.saldo.toFixed(2)}</Text>
                                                                <Text style={[styles.width5, styles.fontSize8]}>{c.porcentagem}</Text>
                                                                <Text style={[styles.width11, styles.bordaCompleta]}></Text>
                                                                <Text style={[styles.width11, styles.bordaCompleta]}></Text>
    
    
                                                            </View>
                                                            :
                                                            <View key={i} style={[styles.row, styles.cinza]} break>
                                                                <Text style={[styles.width10, styles.fontSize8]}>{c.codigoInterno}</Text>
                                                                <Text style={[styles.width20, styles.fontSize6]}>{c.descricaoSubItemCusto}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.descricaoAbreviacao}</Text>
                                                                <Text style={[styles.width8, styles.fontSize8]}>{c.quantidade.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.valorUnitario.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.precoTotal.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.quantidadeConsumida.toFixed(2)}</Text>
                                                                <Text style={[styles.width7, styles.fontSize8]}>{c.saldo.toFixed(2)}</Text>
                                                                <Text style={[styles.width5, styles.fontSize8]}>{c.porcentagem}</Text>
                                                                <Text style={[styles.width11, styles.bordaCompleta]}></Text>
                                                                <Text style={[styles.width11, styles.bordaCompleta]}></Text>
    
                                                            </View>
                                                    }
    
                                                    <View style={[styles.linha]}></View>
                                                </View>
                                            ))
                                            }
                                            <View style={styles.row2} break wrap>
                                                <Text style={styles.width10}></Text>
                                                <Text style={[styles.width20, styles.fontSize8]}>Total Medição</Text>
                                                <Text style={styles.width7}></Text>
                                                <Text style={styles.width8}></Text>
                                                <Text style={[styles.width7, styles.fontSize8]}>{cabecalho[0].totalUnitario.toFixed(2)}</Text>
                                                <Text style={[styles.width7, styles.fontSize8]}>{cabecalho[0].totalUnitarioQuantidade.toFixed(2)}</Text>
                                                <Text style={styles.width7}></Text>
                                                <Text style={styles.width7}></Text>
                                                <Text style={[styles.width5]}></Text>
                                                <Text style={[styles.width11]}></Text>
                                                <Text style={[styles.width11]}></Text>

                                            </View>
                                            <View style={[styles.linha]} break></View>
                                            <View style={[styles.rowCabecalho, styles.bold]} >
                                                <Text style={[styles.comeco, styles.fontSize8]}>
                                                    Percentual Concluído da Obra: {parseFloat(percentualObra.toFixed(2))}%
                                                </Text>
                                                <Text style={styles.meio}></Text>
                                                <Text style={styles.fim}></Text>
                                            </View>
                                            <View style={[styles.espaco]}></View>
                                            <View style={[styles.rowCabecalho, styles.bold]} >
                                                <Text style={[styles.comeco, styles.fontSize8]}>
                                                    Vistos:
                                                </Text>
                                                <Text style={styles.meio}></Text>
                                                <Text style={styles.fim}></Text>
                                            </View>
                                            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                                                <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                                    Fiscalização
                                                </Text>
                                                <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                                    Contratada
                                                </Text>
                                            </View>
                                            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                                                <Text style={[styles.linhaMetadeComeco]}>
                                                    _____________________________________
                                                </Text>
                                                <Text style={[styles.linhaMetadeFim]}>
                                                    _____________________________________
                                                </Text>
                                            </View>
                                        </View>
                                        
                                    :
                                        corpo.map((c, i) => (
                                            <View>
                                                {
                                                    (i + 1) % 2 == 0 ?
                                                        <View key={i} style={styles.row} break>
                                                            <Text style={[styles.width10, styles.fontSize8]}>{c.codigoInterno}</Text>
                                                            <Text style={[styles.width20, styles.fontSize6]}>{c.descricaoSubItemCusto}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.descricaoAbreviacao}</Text>
                                                            <Text style={[styles.width8, styles.fontSize8]}>{c.quantidade.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.valorUnitario.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.precoTotal.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.quantidadeConsumida.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.saldo.toFixed(2)}</Text>
                                                            <Text style={[styles.width5, styles.fontSize8]}>{c.porcentagem}</Text>
                                                            <Text style={[styles.width11, styles.bordaCompleta]}></Text>
                                                            <Text style={[styles.width11, styles.bordaCompleta]}></Text>


                                                        </View>
                                                        :
                                                        <View key={i} style={[styles.row, styles.cinza]} break>
                                                            <Text style={[styles.width10, styles.fontSize8]}>{c.codigoInterno}</Text>
                                                            <Text style={[styles.width20, styles.fontSize6]}>{c.descricaoSubItemCusto}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.descricaoAbreviacao}</Text>
                                                            <Text style={[styles.width8, styles.fontSize8]}>{c.quantidade.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.valorUnitario.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.precoTotal.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.quantidadeConsumida.toFixed(2)}</Text>
                                                            <Text style={[styles.width7, styles.fontSize8]}>{c.saldo.toFixed(2)}</Text>
                                                            <Text style={[styles.width5, styles.fontSize8]}>{c.porcentagem}</Text>
                                                            <Text style={[styles.width11, styles.bordaCompleta]}></Text>
                                                            <Text style={[styles.width11, styles.bordaCompleta]}></Text>

                                                        </View>
                                                }

                                                <View style={[styles.linha]}></View>
                                            </View>
                                        ))
                                }
                            </View>
                            {corpoAdicionado.length == 0 && corpo.length > 4 ?
                                <View>
                                    <View style={styles.row2}>
                                        <Text style={styles.width10}></Text>
                                        <Text style={[styles.width20, styles.fontSize8]}>Total Medição</Text>
                                        <Text style={styles.width7}></Text>
                                        <Text style={styles.width8}></Text>
                                        <Text style={[styles.width7, styles.fontSize8]}>{cabecalho[0].totalUnitario.toFixed(2)}</Text>
                                        <Text style={[styles.width7, styles.fontSize8]}>{cabecalho[0].totalUnitarioQuantidade.toFixed(2)}</Text>
                                        <Text style={styles.width7}></Text>
                                        <Text style={styles.width7}></Text>
                                        <Text style={[styles.width5]}></Text>
                                        <Text style={[styles.width11]}></Text>
                                        <Text style={[styles.width11]}></Text>

                                    </View>
                                    <View style={[styles.linha]}></View>
                                    
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.rowCabecalho, styles.bold]} >
                                        <Text style={[styles.comeco, styles.fontSize8]}>
                                            Percentual Concluído da Obra: {parseFloat(percentualObra.toFixed(2))}%
                                        </Text>
                                        <Text style={styles.meio}></Text>
                                        <Text style={styles.fim}></Text>
                                    </View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.rowCabecalho, styles.bold]} >
                                        <Text style={[styles.comeco, styles.fontSize8]}>
                                            Vistos:
                                        </Text>
                                        <Text style={styles.meio}></Text>
                                        <Text style={styles.fim}></Text>
                                    </View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                                        <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                            Fiscalização
                                        </Text>
                                        <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                            Contratada
                                        </Text>
                                    </View>
                                    <Text style={[styles.meio]}></Text>
                                    <Text style={[styles.meio]}></Text>
                                    <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                                        <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                            _____________________________________
                                        </Text>
                                        <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                            _____________________________________
                                        </Text>
                                    </View>
                                </View>
                                :
                                <View/>
                            }
                            {corpo != null && corpoAdicionado != null &&
                             corpoAdicionado.length == 0 ? 
                                <Text style={[styles.pageNumber, styles.marginBottomMenos50, styles.fontSize8]}
                                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages - 1}`}
                                    fixed
                                />
                                :
                                <Text style={[styles.pageNumber, styles.marginBottomMenos50, styles.fontSize8]}
                                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                                    fixed
                                />
                            }
                        </View>
                    </Page>
                    :

                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            <Text>
                                ERRO AO GERAR PDF
                            </Text>
                        </View>
                    </Page>

            }
            {
                cabecalho != null && corpo != null && percentualObra != null && corpoAdicionado != null &&
                    corpoAdicionado.length > 0 ?

                    <Page size="A4" style={styles.page} orientation="landscape" fixed>
                        <View style={[styles.rowCabecalho]} fixed>
                            <Text style={styles.comeco}>
                                <Image style={styles.logo} src={img} fixed />
                            </Text>
                            <Text style={[styles.titulo, styles.meio, styles.top40]}>
                                Boletim de Medição
                            </Text>
                            <Text style={[styles.fim, styles.top40]}>
                                Nº AS: {cabecalho[0].numeroAf}   Nº Contrato: {cabecalho[0].descricaoContrato}
                            </Text>
                        </View>
                        <View style={[styles.linha]} fixed></View>
                        <View style={[styles.espaco]} fixed></View>
                        <View style={[styles.section]}>
                            <View style={[styles.rowCabecalho, styles.bold]} >
                                <Text style={styles.comeco}></Text>
                                <Text style={[styles.meio, styles.bold, styles.fontSize8]}>SubItem Adicionados</Text>
                                <Text style={styles.fim}></Text>
                            </View>
                            <View style={[styles.espaco]}></View>
                            <View style={styles.table}>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.linha]}></View>
                                <View style={[styles.rowTitulo, styles.bold, styles.cabecalho]} fixed>
                                    <Text style={[styles.width10, styles.fontSize8]}>Cod. Interno</Text>
                                    <Text style={[styles.width20, styles.fontSize8]}>Atividade</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Un</Text>
                                    <Text style={[styles.width8, styles.fontSize8]}>Qtd Orçada</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Val. Unit. R$</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Total R$</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Qtd. Consum. Anterior</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>Saldo</Text>
                                    <Text style={[styles.width5,styles.fontSize8]}>Total Exec. %</Text>
                                    <Text style={[styles.width11, styles.fontSize8]}>(Acumulada)Qtd méd</Text>
                                    <Text style={[styles.width11, styles.fontSize8]}>(Atual)Qtd méd</Text>

                                </View>
                                <View style={[styles.linha]}></View>
                                {
                                    corpoAdicionado.map((c, i) => (
                                        <View>
                                            {
                                                (i + 1) % 2 == 0 ?
                                                    <View key={i} style={styles.row} break>
                                                        <Text style={[styles.width10, styles.fontSize8]}>{c.codigoInterno}</Text>
                                                        <Text style={[styles.width20, styles.fontSize6]}>{c.descricaoSubItemCusto}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.descricaoAbreviacao}</Text>
                                                        <Text style={[styles.width8, styles.fontSize8]}>{c.quantidade.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.valorUnitario.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.precoTotal.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.quantidadeConsumida.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.saldo.toFixed(2)}</Text>
                                                        <Text style={[styles.width5, styles.fontSize8]}>{c.porcentagem}</Text>
                                                        <Text style={[styles.width11, styles.bordaCompleta]}></Text>
                                                        <Text style={[styles.width11, styles.bordaCompleta]}></Text>

                                                    </View>
                                                    :
                                                    <View key={i} style={[styles.row, styles.cinza]} break>
                                                        <Text style={[styles.width10, styles.fontSize8]}>{c.codigoInterno}</Text>
                                                        <Text style={[styles.width20, styles.fontSize6]}>{c.descricaoSubItemCusto}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.descricaoAbreviacao}</Text>
                                                        <Text style={[styles.width8, styles.fontSize8]}>{c.quantidade.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.valorUnitario.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.precoTotal.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.quantidadeConsumida.toFixed(2)}</Text>
                                                        <Text style={[styles.width7, styles.fontSize8]}>{c.saldo.toFixed(2)}</Text>
                                                        <Text style={[styles.width5, styles.fontSize8]}>{c.porcentagem}</Text>
                                                        <Text style={[styles.width11, styles.bordaCompleta]}></Text>
                                                        <Text style={[styles.width11, styles.bordaCompleta]}></Text>

                                                    </View>
                                            }

                                            <View style={[styles.linha]}></View>

                                        </View>
                                    ))

                                }
                            </View>
                            <View>
                                <View style={styles.row2}>
                                    <Text style={styles.width10}></Text>
                                    <Text style={[styles.width20, styles.fontSize8]}>Total Medição</Text>
                                    <Text style={styles.width7}></Text>
                                    <Text style={styles.width8}></Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>{cabecalho[0].totalUnitario.toFixed(2)}</Text>
                                    <Text style={[styles.width7, styles.fontSize8]}>{cabecalho[0].totalUnitarioQuantidade.toFixed(2)}</Text>
                                    <Text style={styles.width7}></Text>
                                    <Text style={styles.width7}></Text>
                                    <Text style={[styles.width5]}></Text>
                                    <Text style={[styles.width11]}></Text>
                                    <Text style={[styles.width11]}></Text>

                                </View>
                                <View style={[styles.linha]}></View>
                                
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.espaco]}></View>
                            
                                <View style={[styles.rowCabecalho, styles.bold]} >
                                    <Text style={[styles.comeco, styles.fontSize8]}>
                                        Percentual Concluído da Obra: {parseFloat(percentualObra.toFixed(2))}%
                                    </Text>
                                    <Text style={styles.meio}></Text>
                                    <Text style={styles.fim}></Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.rowCabecalho, styles.bold]} >
                                    <Text style={[styles.comeco, styles.fontSize8]}>
                                        Vistos:
                                    </Text>
                                    <Text style={styles.meio}></Text>
                                    <Text style={styles.fim}></Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                                    <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                        Fiscalização
                                    </Text>
                                    <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                        Contratada
                                    </Text>
                                </View>
                                <Text style={[styles.meio]}></Text>
                                <Text style={[styles.meio]}></Text>
                                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                                    <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                        _____________________________________
                                    </Text>
                                    <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                        _____________________________________
                                    </Text>
                                </View>
                                <View style={[styles.espaco]}></View>
                                {corpo != null && corpoAdicionado != null &&
                                    corpo.length <= 4 && corpoAdicionado.length == 0 ? 
                                    <Text/>
                                    :
                                    <Text style={[styles.pageNumber, styles.marginBottomMenos50, styles.fontSize8]}
                                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                                        fixed
                                    />
                                }
                            </View>
                        </View>
                    </Page>
                    :

                    /*cabecalho != null && corpo != null && percentualObra != null && corpoAdicionado != null ? 
                        
                        corpo != null && corpo.length > 4 ?

                            <Page size="A4" style={styles.page} orientation="landscape" fixed>
                                <View style={[styles.rowCabecalho]} fixed>
                                    <Text style={styles.comeco}>
                                        <Image style={styles.logo} src={img} fixed />
                                    </Text>
                                    <Text style={[styles.titulo, styles.meio, styles.top40]}>
                                        Boletim de Medição
                                    </Text>
                                    <Text style={[styles.fim, styles.top60]}>
                                        Nº AS: {cabecalho[0].numeroAf}  Nº Contrato: {cabecalho[0].descricaoContrato}
                                    </Text>
                                </View>
                                <View style={[styles.linha]} fixed></View>
                                <View style={[styles.espaco]} fixed></View>
                                <View style={[styles.section]}>
                                    <View style={[styles.espacogrande]}></View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.rowCabecalho, styles.bold]} >
                                        <Text style={[styles.comeco, styles.fontSize8]}>
                                            Percentual Concluído da Obra: {parseFloat(percentualObra.toFixed(2))}%
                                        </Text>
                                        <Text style={styles.meio}></Text>
                                        <Text style={styles.fim}></Text>
                                    </View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.rowCabecalho, styles.bold]} >
                                        <Text style={[styles.comeco, styles.fontSize8]}>
                                            Vistos:
                                        </Text>
                                        <Text style={styles.meio}></Text>
                                        <Text style={styles.fim}></Text>
                                    </View>
                                    <View style={[styles.espaco]}></View>
                                    <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                                        <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                            Fiscalização
                                        </Text>
                                        <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                            Contratada
                                        </Text>
                                    </View>
                                    <Text style={[styles.meio]}></Text>
                                    <Text style={[styles.meio]}></Text>
                                    <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                                        <Text style={[styles.linhaMetadeComeco, styles.fontSize8]}>
                                            _____________________________________
                                        </Text>
                                        <Text style={[styles.linhaMetadeFim, styles.fontSize8]}>
                                            _____________________________________
                                        </Text>
                                    </View>
                                    <View style={[styles.espaco]}></View>
                                    {corpo != null && corpoAdicionado != null &&
                                        corpo.length <= 4 && corpoAdicionado.length == 0 ? 
                                        <Text/>
                                        :
                                        <Text style={[styles.pageNumber, styles.marginBottomMenos50, styles.fontSize8]}
                                            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                                            fixed
                                        />
                                    }
                                </View>
                            </Page>
                        :
                        <Text></Text>*/

                        <View></View>

            }
        </Document >
    )
}