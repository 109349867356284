import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  ListarSolicitacaoFornecimentoResponse,
  BuscarSolicitacaoFornecimentoPorIdResponse, Carregando, ListarSolicitacaoFornecimentoCamposVerificacaoRapidaResponse, BuscarCoordenadasPorEnderecoResponse
} from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';
import { BuscarEventoRespostaPorIdResponse } from '../eventoResposta/actions';
import { ListarStatusSolicitacaoFornecimentoCompletoResponse } from '../statusSolicitacaoFornecimento/actions';

function* ListarSolicitacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/SolicitacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarSolicitacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BuscarSolicitacaoFornecimentoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/SolicitacaoFornecimento/${model.id}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(null));
    yield put(Carregando(true));

  }

}

function* InserirSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var result = yield call(api.post, `/api/SolicitacaoFornecimento`, model.data, { headers: headerParams.token });

    // Buscar Solicitação
    const resultBusca = yield call(api.get, `/api/SolicitacaoFornecimento/${result.data.result}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultBusca.data.result));

    // Alterar Rota
    history.push(`/alterarGeralSolicitacao/${result.data.result}`);

    // Buscar Status
    const resultStatus = yield call(api.post, `/api/StatusSolicitacaoFornecimento/ListarCompleto`, 
      {idSolicitacaoFornecimento: resultBusca.data.result.id}, { headers: headerParams.token });

    yield put(ListarStatusSolicitacaoFornecimentoCompletoResponse(resultStatus.data.result));

    yield put(Carregando(true));

    toast.success(`A Solicitacao foi cadastrada com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

function* AlterarSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/SolicitacaoFornecimento`, model.data, { headers: headerParams.token });

    // Buscar Solicitação Fornecimento
    const result = yield call(api.get, `/api/SolicitacaoFornecimento/${model.data.Id}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Solicitacao foi atualizada com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/SolicitacaoFornecimento/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));
    toast.success(`Solicitacao id: ${model.id} foi excluida!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

function* ListarCompletoSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/SolicitacaoFornecimento/ListarCompleto`,
      model.data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarSolicitacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* InserirCoordenadasSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/SolicitacaoFornecimento/inserirCoordenadas`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/SolicitacaoFornecimento/${model.data.IdSolicitacaoFornecimento}`,
      { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(result.data.result));
    yield put(Carregando(true));
    toast.success(`Coordenadas inseridas com sucesso sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarSolicitacaoFornecimentoCamposVerificacaoRapidaRequest() {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/SolicitacaoFornecimento/ListarCamposVerificacaoRapida`, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoCamposVerificacaoRapidaResponse(result.data.result));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarSolicitacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
  }
}

function* BuscarCoordenadasPorEnderecoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/SolicitacaoFornecimento/buscarCoordenadasPorEndereco`,
      { 'Endereco': model.Endereco }, { headers: headerParams.token });

    yield put(BuscarCoordenadasPorEnderecoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarSolicitacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarPrazoSolicitacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.get, `/api/SolicitacaoFornecimento/${model.data.Id}/${model.data.PrazoDias}`,
      { headers: headerParams.token });

    // Buscar Solicitação Fornecimento
    const result = yield call(api.get, `/api/SolicitacaoFornecimento/${model.data.Id}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Prazo atualizado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* GerarOficioRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.post, `/api/SolicitacaoFornecimento/GerarOficio`, {'Id': model.id, 'Numero': model.numero} ,{ headers: headerParams.token });

        const resultSoli = yield call(api.get, `/api/SolicitacaoFornecimento/${model.id}`, { headers: headerParams.token });

        yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSoli.data.result));

        const resultEv = yield call(api.get, `/api/EventoResposta/${model.id}`, { headers: headerParams.token });

        yield put(BuscarEventoRespostaPorIdResponse(resultEv.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ErrorResponse(e));
        yield put(BuscarSolicitacaoFornecimentoPorIdResponse(null));
        yield put(Carregando(true));

    }

}

export default all([
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_REQUEST', ListarSolicitacaoFornecimentoRequest),
  takeLatest('BUSCAR_SOLICITACAO_FORNECIMENTO_POR_ID_REQUEST', BuscarSolicitacaoFornecimentoPorIdRequest),
  takeLatest('INSERIR_SOLICITACAO_FORNECIMENTO_REQUEST', InserirSolicitacaoFornecimentoRequest),
  takeLatest('ALTERAR_SOLICITACAO_FORNECIMENTO_REQUEST', AlterarSolicitacaoFornecimentoRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_REQUEST', ExcluirSolicitacaoFornecimentoRequest),
  takeLatest('LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_REQUEST', ListarCompletoSolicitacaoFornecimentoRequest),
  takeLatest('INSERIR_COORDENADAS_SOLICITACAO_FORNECECIMENTO_REQUEST', InserirCoordenadasSolicitacaoFornecimentoRequest),
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_CAMPOS_VERIFICACAO_RAPIDA_REQUEST', ListarSolicitacaoFornecimentoCamposVerificacaoRapidaRequest),
  takeLatest('BUSCAR_COORDENADAS_POR_ENDERECO_REQUEST', BuscarCoordenadasPorEnderecoRequest),
  takeLatest('ALTERAR_PRAZO_SOLICITACAO_FORNECIMENTO_REQUEST', AlterarPrazoSolicitacaoFornecimentoRequest),
    takeLatest('GERAR_OFICIO_REQUEST', GerarOficioRequest),
])