import { Col } from "react-bootstrap";
import Header from "../../components/Header/header";
import "./bemVindo.css";

export default function BemVindo() {

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col lg={12}>
                    <p className="centralizaTitulo" >Bem-Vindo</p>
                </Col>
            </div>
        </>
    )
}