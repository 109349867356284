import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarStatusMedicaoAutorizacaoFornecimentoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';

function* ListarStatusMedicaoAutorizacaoFornecimentoRequest() {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.get, `/api/StatusMedicaoAutorizacaoFornecimento`, { headers: headerParams.token });

        yield put(ListarStatusMedicaoAutorizacaoFornecimentoResponse(result.data.result));
        yield put(Carregando(true));
    } catch (e) {

        yield put(ListarStatusMedicaoAutorizacaoFornecimentoResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

export default all([
    takeLatest('LISTAR_STATUS_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarStatusMedicaoAutorizacaoFornecimentoRequest)
])