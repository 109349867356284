import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
    ListarTipoRecursoRequest, ExcluirTipoRecursoRequest,
    InserirTipoRecursoRequest, AlterarTipoRecursoRequest
} from '../../../store/modules/tipoRecurso/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GrResources } from "react-icons/gr";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

export default function TipoRecurso() {

    const dispatch = useDispatch();
    const history = useHistory();
    const listaTipoRecurso = useSelector(state => state.tipoRecurso.listaTipoRecurso);
    const carregando = useSelector(state => state.tipoRecurso.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarTipoRecursoRequest());
    }, [])

    function ValidacoesInserir(tipoRecurso) {

        //validação do campo descrição
        if (tipoRecurso.descricao == null || tipoRecurso.descricao == undefined
            || tipoRecurso.descricao.trim() == ''
            || tipoRecurso.descricao.length > 50) {
            toast.error(`Recurso inválido`);
            return;
        }

        return true;

    }

    function InserirTipoRecurso(tipoRecurso) {

        let data = {
            'Id': 0,
            'Descricao': tipoRecurso.descricao.trim(),
        }

        dispatch(InserirTipoRecursoRequest(data))
    }

    function AlterarTipoRecurso(tipoRecurso) {

        let data = {
            'Id': tipoRecurso.id,
            'Descricao': tipoRecurso.descricao.trim(),
        }

        dispatch(AlterarTipoRecursoRequest(data))
    }

    function ExcluirTipoRecurso(idTipoRecurso) {
        dispatch(ExcluirTipoRecursoRequest(idTipoRecurso));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <span>
                                        <GrResources size={25} color={"#000"} /> Recursos
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col>
                                        {
                                            carregando ?

                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title=""
                                                    columns={[
                                                        {
                                                            title: 'Número do Recurso', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                width: '20%',
                                                            }
                                                        },
                                                        {
                                                            title: 'Descrição', field: 'descricao', sorting: true, editPlaceholder: 'Descrição', validate: rowData => {
                                                                if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                }
                                                                else if (rowData.descricao.trim().length > 50) {
                                                                    return "A descrição não pode conter mais que 50 caracteres"
                                                                }
                                                                return true
                                                            }, cellStyle: { width: '80%' }
                                                        },
                                                    ]}
                                                    data={
                                                        listaTipoRecurso != undefined ?
                                                            listaTipoRecurso.map(recurso => {
                                                                return {
                                                                    id: recurso.id,
                                                                    descricao: recurso.descricao,
                                                                }
                                                            })
                                                            :
                                                            []
                                                    }
                                                    icons={{
                                                        Add: () =>
                                                            <Button variant="success">
                                                                <span>
                                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                                </span>
                                                            </Button>
                                                    }}
                                                    editable={{
                                                        onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() => {
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else {
                                                                        InserirTipoRecurso(newRow);
                                                                        resolve()
                                                                    }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        }),

                                                        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() => {
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else {
                                                                        AlterarTipoRecurso(newRow);
                                                                        resolve()
                                                                    }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        }),

                                                        onRowDelete: oldData => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() => {
                                                                    ExcluirTipoRecurso(oldData.id);
                                                                    resolve()
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        })
                                                    }}
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        addRowPosition: "first",
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        actionsColumnIndex: -1,
                                                        filtering: true,
                                                        rowStyle: rowData => ({
                                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        })

                                                    }}
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                :
                                                <Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    )
}