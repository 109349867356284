import produce from 'immer';

const initialState = {
    listaBanco: undefined,
    carregando: true
};

export default function banco(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_BANCO_RESPONSE':
            return produce(state, draft => {
                draft.listaBanco = action.listaBanco
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}