import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  ListarAutorizacaoFornecimentoSubitemCustoResponse, BuscarAutorizacaoFornecimentoSubitemCustoPorIdResponse,
  Carregando, ListarCompletoAutorizacaoFornecimentoSubitemCustoResponse
} from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { BuscarContratoVersaoPorIdResponse } from '../contratoVersao/actions';
import { ListarRelatorioMedicaoSubItemResponse } from '../relatorio/actions';

function* BuscarAutorizacaoFornecimentoSubitemCustoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCusto/${model.id}`, { headers: headerParams.token });

    yield put(BuscarAutorizacaoFornecimentoSubitemCustoPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(BuscarAutorizacaoFornecimentoSubitemCustoPorIdResponse(null));
    yield put(Carregando(true));
  }
}

function* ListarCompletoAutorizacaoFornecimentoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      model.data, { headers: headerParams.token });

    yield put(ListarCompletoAutorizacaoFornecimentoSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    //yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarAutorizacaoFornecimentoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      model.data, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoComFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var dataId = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto`, model.data, { headers: headerParams.token });

    if(dataId !== null){
      for (var x = 0; x < dataId.data.result.length; x++) {
        var resultId = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${dataId.data.result[x]}/AutorizacaoFornecimentoSubitem`, model.formData, { headers: headerParams.token });
      }
    }

    var data = {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(result.data.result));

    const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));

    toast.success(`Autorização Fornecimento Subitem Custo foi cadastrado com sucesso!`);
    //Atualizando o Exportar
    const resultExportar = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`, {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(resultExportar.data.result))

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
        const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

        yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));
      }

    //yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse([]));

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto`, model.data, { headers: headerParams.token });

    var data = {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(result.data.result));

    const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));

    toast.success(`Autorização Fornecimento Subitem Custo foi cadastrado com sucesso!`);
    //Atualizando o Exportar
    const resultExportar = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`, {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(resultExportar.data.result))

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
        const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

        yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));
      }

    //yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse([]));

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}


function* AlterarAutorizacaoFornecimentoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    // Alterar Subitem Autorização
    yield call(api.put, `/api/AutorizacaoFornecimentoSubitemCusto`, model.data, { headers: headerParams.token });

    // Listar Subitem Autorização
    var data = {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(result.data.result));

    toast.success(`Autorização Fornecimento Subitem Custo foi atualizado com sucesso!`);
    //Atualizando o Exportar
    const resultExportar = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`, {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(resultExportar.data.result));

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    //yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse([]));

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirAutorizacaoFornecimentoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/AutorizacaoFornecimentoSubitemCusto/${model.data.Id}`, { headers: headerParams.token });

    var data = {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Autorização Fornecimento Subitem Custo id: ${model.data.Id} foi excluido!`);

    //Atualizando o Exportar
    const resultExportar = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`, {
      'IdAutorizacaoFornecimento': parseInt(model.data.IdAutorizacaoFornecimento)
    }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(resultExportar.data.result));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST', ListarAutorizacaoFornecimentoSubitemCustoRequest),
  takeLatest('LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST', ListarCompletoAutorizacaoFornecimentoSubitemCustoRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST', ExcluirAutorizacaoFornecimentoSubitemCustoRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST', AlterarAutorizacaoFornecimentoSubitemCustoRequest),
  takeLatest('BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_POR_ID_REQUEST', BuscarAutorizacaoFornecimentoSubitemCustoPorIdRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_COM_FOTO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoComFotoRequest),
])