export function ListarMedicaoOcorrenciaRequest() {
    return {
      type: 'LISTAR_MEDICAO_OCORRENCIA_REQUEST'
    }
  }
  
  export function ListarMedicaoOcorrenciaResponse(listaMedicaoOcorrencia) {
    return {
      type: 'LISTAR_MEDICAO_OCORRENCIA_RESPONSE',
      listaMedicaoOcorrencia
    }
  }
  
  export function InserirMedicaoOcorrenciaRequest(data) {
    return {
      type: 'INSERIR_MEDICAO_OCORRENCIA_REQUEST',
      data
    }
  }
  
  export function AlterarMedicaoOcorrenciaRequest(data) {
    return {
      type: 'ALTERAR_MEDICAO_OCORRENCIA_REQUEST',
      data
    }
  }
  
  export function ExcluirMedicaoOcorrenciaRequest(id) {
    return {
      type: 'EXCLUIR_MEDICAO_OCORRENCIA_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }