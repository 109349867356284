import { Card, Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import { useState } from 'react';
import { chartColors } from "../../PageDashboard/colors";

export default function Dashboard() {

    const listaRelatorioAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.relatorio.listaRelatorioAutorizacaoFornecimentoSubitemCusto);
    const [listaDescricao, setListaDescricao] = useState([]);
    const [listaValor, setListaValor] = useState([]);

    useEffect(() => {

        if (listaRelatorioAutorizacaoFornecimentoSubitemCusto !== undefined) {
            ListarProjecao();
        }

    }, [listaRelatorioAutorizacaoFornecimentoSubitemCusto])

    function ListarProjecao() {

        let listaDesc = [];
        let listaValorTotal = [];

        if (listaRelatorioAutorizacaoFornecimentoSubitemCusto != undefined && listaRelatorioAutorizacaoFornecimentoSubitemCusto.length != 0) {

            if (listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal -
                listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual <= 0) {
                /*listaDesc.push("Valor ainda não utilizado na Autorização (" + (0).toLocaleString('pt-br', {
                        style: 'currency', currency: 'BRL',
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4
                    }) + ")");*/
            } else {
                listaDesc.push("Valor ainda não utilizado na Autorização (" + (listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal -
                    listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual).toLocaleString('pt-br', {
                        style: 'currency', currency: 'BRL',
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4
                    }) + ")");
            }

            listaDesc.push("Valor utilizado na Autorização (" + listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual.toLocaleString('pt-br', {
                style: 'currency', currency: 'BRL',
                minimumFractionDigits: 4,
                maximumFractionDigits: 4
            }) + ")");
            if (listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal -
                listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual <= 0) {
                //listaValorTotal.push(0);
            } else {
                listaValorTotal.push(listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal -
                    listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual);
            }

            listaValorTotal.push(listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual);
        }

        setListaDescricao(listaDesc);
        setListaValor(listaValorTotal);
    }

    return (
        <>
            <div className="tamanhoTela">
                <Row className="mb-3">
                    <Col md={6}>
                        <Card>
                            <center>
                                <Card.Header>
                                    Gráfico do valor utilizado da Autorização - Valor total da AF:  {listaRelatorioAutorizacaoFornecimentoSubitemCusto != undefined
                                        && listaRelatorioAutorizacaoFornecimentoSubitemCusto.length != 0 ?
                                        listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal.toLocaleString('pt-br', {
                                            style: 'currency', currency: 'BRL',
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        }) : ''}
                                </Card.Header>
                            </center>
                            <Card.Body>
                                <Row className="mb-3">

                                    <center>
                                        <div className="GraficoPie">
                                            <Pie className="GraficoPie"
                                                data={{
                                                    labels: listaDescricao,
                                                    datasets: [
                                                        {
                                                            data: listaValor,
                                                            backgroundColor: chartColors,
                                                            hoverBackgroundColor: chartColors,
                                                            borderWidth: 0,
                                                            hoverOffset: 4
                                                        },
                                                    ],
                                                }}
                                                options={{
                                                    minBodyHeight: 500,
                                                    tooltips: {
                                                        enabled: true
                                                    },
                                                    plugins: {
                                                        title: {
                                                            display: true,
                                                            align: 'center',
                                                            fontSize: 20
                                                        },
                                                        legend: {
                                                            display: true,
                                                            position: "top",
                                                        }, datalabels: {
                                                            formatter: (value, ctx) => {

                                                                let sum = 0;
                                                                let dataArr = ctx.chart.data.datasets[0].data;
                                                                dataArr.map(data => {
                                                                    sum += data;
                                                                });
                                                                let percentage = (value * 100 / (listaRelatorioAutorizacaoFornecimentoSubitemCusto != undefined ?
                                                                    listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal : 1)).toFixed(2) + "%";
                                                                return percentage;
                                                            },
                                                            color: '#000',
                                                        }
                                                    }
                                                }}
                                                height={500}
                                                width={500}
                                            />
                                        </div>
                                    </center>

                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    )
}