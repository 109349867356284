import { useState, useEffect } from "react";
import { Row, Col, Form, Alert, Button, Container, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InserirEmpenhoRequest, AlterarEmpenhoRequest } from "../../../store/modules/empenho/actions";
import { BuscarEmpenhoPorIdRequest, LimparEmpenho } from "../../../store/modules/empenho/actions";
import { ListarStatusEmpenhoRequest } from "../../../store/modules/statusEmpenho/actions";
import { ListarEmpenhoStatusRequest } from "../../../store/modules/empenhoStatus/actions";
import { useHistory, useParams, Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { ListarDadosExportarPDFEmpenhoRequest, ListarTodasFotosCompletasAutorizacaoFornecimentoRequest, LimparRelatorioEmpenho, ListarDadosExportarPDFEmpenhoBoletimMedicaoRequest } from "../../../store/modules/relatorio/actions";
import { PDFDownloadLink } from '@react-pdf/renderer';
import Loading from "../../../components/Loading/loading";
import IntlCurrencyInput from "react-intl-currency-input";
import PdfEmpenho from "../../../components/pdfEmpenho/pdfEmpenho";
import PdfEmpenhoBoletimMedicao from "../../../components/pdfEmpenhoBoletimMedicao/pdfEmpenhoBoletimMedicao";
import Select from 'react-select';
import { ListarAssinanteRequest } from "../../../store/modules/assinante/actions";
import moment from 'moment';
import { ListarContratoRequest } from "../../../store/modules/contrato/actions";
import { ListarFornecedorContaRequest } from "../../../store/modules/fornecedorConta/actions";
import { ListarContratoFornecedorRequest } from "../../../store/modules/contratoFornecedor/actions";
import { ListarFornecedorRequest } from "../../../store/modules/fornecedor/actions";

export default function Empenho() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const empenhoCompleto = useSelector(state => state.empenho.empenhoCompleto);
    const carregando = useSelector(state => state.empenho.carregando);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);
    const listaEmpenhoStatus = useSelector(state => state.empenhoStatus.listaEmpenhoStatus);
    const listaDadosExportarPDFEmpenho = useSelector(state => state.relatorio.listaDadosExportarPDFEmpenho);
    const listaDadosExportarPDFEmpenhoBoletimMedicao = useSelector(state => state.relatorio.listaDadosExportarPDFEmpenhoBoletimMedicao);
    const carregandoPDF = useSelector(state => state.relatorio.carregando);
    const listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed
        = useSelector(state => state.empenhoAutorizacaoFornecimentoSubitemCustoMed.listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed);
    const listarMulta = useSelector(state => state.multa.listaMulta);
    const listarDiferencaEmpenho = useSelector(state => state.empenhoStatus.listarDiferencaEmpenho);
    const listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed
        = useSelector(state => state.empenhoAutorizacaoFornecimentoSubitemCustoMed.listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed);
    const listaAssinante = useSelector(state => state.assinante.listaAssinante);
    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const listaContratoFornecedor = useSelector(state => state.contratoFornecedor.listaContratoFornecedor);
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);
    const listaFornecedorContrato = useSelector(state => state.fornecedor.listaFornecedor);

    const [carregaPage, setCarregaPage] = useState(false);
    const [empenhoId, setEmpenhoId] = useState('');
    const [observacao, setObservacao] = useState('');
    const [descricao, setDescricao] = useState('');
    const [numeroNotaFiscal, setNumeroNotaFiscal] = useState('');
    const [valorNotaFiscal, setValorNotaFiscal] = useState('');
    const [validated, setValidated] = useState(false);
    const [contrato, setContrato] = useState('');
    const [idContrato, setIdContrato] = useState('');
    const [fornecedor, setFornecedor] = useState('');
    const [idFornecedor, setIdFornecedor] = useState('');
    const [show, setShow] = useState(false);
    const [showModalAssinante, setShowModalAssinante] = useState(false);
    const [showMedicao, setMedicaoShow] = useState(false);
    const [valorTotalEmp, setValorTotalEmp] = useState(0);
    const [NomeAssinante, setNomeAssinante] = useState(0);
    const [dataInicioEmpenho, setDataInicioEmpenho] = useState('');
    const [dataFimEmpenho, setDataFimEmpenho] = useState('');

    const [validatedDataFim, setValidatedDataFim] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModalAssinante = () => setShowModalAssinante(false);
    const handleShowModalAssinante = () => setShowModalAssinante(true);

    const handleMedicaoClose = () => setMedicaoShow(false);
    const handleMedicaoShow = () => setMedicaoShow(true);

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                },
            },
        },
    };

    const listaRelacao = listaContratoFornecedor != undefined && idContrato != '' ?
    listaContratoFornecedor.filter(y => y.idContrato == idContrato)
    :
    [];


    const options = listaAssinante != undefined ?
        listaAssinante.map(a => (
            { value: a.id, label: a.nome }
        ))
        :
        [];

        const optionsContrato = listaContrato != undefined ?
        listaContrato.map(contrato => ({
            value: contrato.id, label: contrato.id + " - " + contrato.descricao
        }))
        :
        [];

        const optionsFornecedor =
        listaFornecedor != undefined && listaRelacao != undefined ?
            listaRelacao.map(y => {
                return {
                    value: y.idFornecedor, label: listaFornecedor.filter(x => x.id == y.idFornecedor)[0].nomeFornecedor
                }
            })
            :
            [];


    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "EmpenhoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

    }, [])

    useEffect(() => {
        dispatch(ListarAssinanteRequest());
        dispatch(ListarStatusEmpenhoRequest());
        dispatch(ListarContratoRequest());
        dispatch(ListarFornecedorRequest());
        dispatch(ListarContratoFornecedorRequest());

        if (id != undefined) {
            dispatch(BuscarEmpenhoPorIdRequest(id));
            dispatch(ListarEmpenhoStatusRequest(id));
        }

    }, [])

    useEffect(() => {
        if (id != undefined) {
            dispatch(BuscarEmpenhoPorIdRequest(id));
        }
    }, [id])

    useEffect(() => () =>
        dispatch(LimparEmpenho())
        , []);

    useEffect(() => () =>
        dispatch(LimparRelatorioEmpenho())
        , []);

    useEffect(() => {

        if (empenhoCompleto != null)
            CarregaEmpenho()

        TotalEmpenho();

    }, [empenhoCompleto])

    useEffect(() => {

        if (listarDiferencaEmpenho != undefined && listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined &&
            listarDiferencaEmpenho != undefined)

            TotalEmpenho();

    }, [listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed, listarMulta, listarDiferencaEmpenho])

    function exportaFotos() {
        dispatch(ListarTodasFotosCompletasAutorizacaoFornecimentoRequest(id));
    }

    function selecionarAssinante() {
        MontarPDF();
    }

    function CarregaEmpenho() {
        if (empenhoCompleto != null && empenhoCompleto.id == parseInt(id)) {
            setEmpenhoId(empenhoCompleto.id)
            setDescricao(empenhoCompleto.descricao);
            setObservacao(empenhoCompleto.observacao);
            setNumeroNotaFiscal(empenhoCompleto.numeroNotaFiscal);
            setValorNotaFiscal(empenhoCompleto.valorNotaFiscal);
            setIdContrato(empenhoCompleto.idContrato);
            setIdFornecedor(empenhoCompleto.idFornecedor);
            setDataInicioEmpenho(empenhoCompleto.dataEmpenho.split('T')[0]);
            setDataFimEmpenho(empenhoCompleto.dataEmpenhoFinal.split('T')[0]);
        }
        setCarregaPage(true);
    }

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false && Validacoes(event) === false) {

            event.preventDefault();
        } else {
            if(!id){
                if(Validacoes(event)){
                    InserirEmpenho()
                }
            }else{
                if(Validacoes(event)){
                    AlterarEmpenho()
                }
            }
        }

        setValidated(true);
    };

    let selectTextContrato = '';
    let selectSelecionadoContrato = [];
    let selectTextFornecedor = '';
    let selectSelecionadoFornecedor = [];
    
    function verificarDatainicioEmpenho(event){
        setDataInicioEmpenho(event.target.value);

        var verificarDataInicio = event.target.value.split("-");
        var verificarDatafim = dataFimEmpenho.split("-");

        if(verificarDatafim >= verificarDataInicio){
            setValidatedDataFim(true);
        } else {
            setValidatedDataFim(false)
        }

    }

    function verificarDataFimEmpenho(event){
        setDataFimEmpenho(event.target.value);

        var verificarDataFim = event.target.value.split("-");
        var verificarDataInicio = dataInicioEmpenho.split("-");

        if(verificarDataFim >= verificarDataInicio){

            setValidatedDataFim(true);
        } else {
            setValidatedDataFim(false);
        }
    }

    function Validacoes(empenho) {
        if(!id){

            if (observacao !== undefined) {
                if (observacao.trim().length > 500) {
                    toast.error('A observação não pode conter mais que 500 caracteres');
                    return false;
                }
            }
    
            if (descricao == ' ' || descricao == ''
                || descricao == null || descricao == undefined) {
                toast.error(`Descrição Inválida`);
                return false;
            }
    
            if (descricao.length > 200) {
                toast.error(`A descrição não pode conter mais que 200 caracteres`);
                return false;
            }
    
            if (!(numeroNotaFiscal == ' ' || numeroNotaFiscal == ''
                || numeroNotaFiscal == null || numeroNotaFiscal == undefined)) {
    
                if (numeroNotaFiscal.length > 50) {
                    toast.error(`O número da nota fiscal não pode conter mais que 50 caracteres`);
                    return false;
                }
            }
    
            if (idContrato == null || idContrato == undefined
                || idContrato.trim() == '') {
                toast.error(`Contrato Inválido`);
                return false;
            }

            if (idFornecedor == null || idFornecedor == undefined
                || idFornecedor.trim() == '') {
                toast.error(`Fornecedor Inválido`);
                return false;
            }

            if (dataInicioEmpenho == null || dataInicioEmpenho == undefined
                || dataInicioEmpenho.trim() == '') {
                toast.error(`Data Inicio Empenho Inválida`);
                return false;
            }

            if (dataFimEmpenho == null || dataFimEmpenho == undefined
                || dataFimEmpenho.trim() == '') {
                toast.error(`Data Fim Empenho Inválido`);
                return false;
            }

            return true;

            /*if (document.getElementById('idSelectContrato') != null && listaContrato != undefined) {
                selectTextContrato = document.getElementById('idSelectContrato').innerText;
                selectSelecionadoContrato = listaContrato.filter(contrato => contrato.id + " - " + contrato.descricao == selectTextContrato);
            }
    
            let nome = '';
    
            if (document.getElementById('idSelectFornecedor') != null && listaContratoFornecedor != undefined && listaRelacao.length > 0) {
                selectTextFornecedor = document.getElementById('idSelectFornecedor').innerText;
                selectSelecionadoFornecedor = listaFornecedor.filter(x => x.nomeFornecedor == selectTextFornecedor)
    
                if (selectTextFornecedor !== undefined && selectSelecionadoFornecedor.length === 0) {
                    nome = selectTextFornecedor.split('\n', selectTextFornecedor.length);
                    selectSelecionadoFornecedor = listaFornecedor.filter(x => x.nomeFornecedor == nome[1])
                }
            }
    
            if (listaRelacao != undefined && selectSelecionadoContrato.length > 0 && selectSelecionadoFornecedor.length > 0) {
                var listaVerifica =
                    listaRelacao.filter(contratoFornecedor => contratoFornecedor.idContrato == parseInt(selectSelecionadoContrato[0].id)
                        && contratoFornecedor.idFornecedor == parseInt(selectSelecionadoFornecedor[0].id));
                if (listaVerifica.length > 0) {
                } else {
                    toast.error(`Fornecedor não está presente no contrato id: ${parseInt(selectSelecionadoContrato[0].id)}`);
                    return false;
                }
            }
    
            if (selectSelecionadoContrato.length > 0 && selectSelecionadoFornecedor.length > 0) {
                return true;
            }
            else {
                toast.error(`Contrato ou fornecedor não selecionado.`);
                return false;
            }*/

        }else{

            if (observacao !== undefined) {
                if (observacao.trim().length > 500) {
                    toast.error('A observação não pode conter mais que 500 caracteres');
                    return false;
                }
            }
    
            if (descricao == ' ' || descricao == ''
                || descricao == null || descricao == undefined) {
                toast.error(`Descrição Inválida`);
                return false;
            }
    
            if (descricao.length > 200) {
                toast.error(`A descrição não pode conter mais que 200 caracteres`);
                return false;
            }
    
            if (!(numeroNotaFiscal.trim() == ''
                || numeroNotaFiscal == null || numeroNotaFiscal == undefined)) {
    
                if (numeroNotaFiscal.length > 50) {
                    toast.error(`O número da nota fiscal não pode conter mais que 50 caracteres`);
                    return false;
                }
            }

            if (dataInicioEmpenho == null || dataInicioEmpenho == undefined
                || dataInicioEmpenho.trim() == '') {
                toast.error(`Data Inicio Empenho Inválida`);
                return false;
            }

            if (dataFimEmpenho == null || dataFimEmpenho == undefined
                || dataFimEmpenho.trim() == '') {
                toast.error(`Data Fim Empenho Inválido`);
                return false;
            }
    
            return true;
        }
        
        

    }

    function InserirEmpenho() {

        let data = {
            'Id': 0 ,
            'DataAlteracao': moment((new Date())).add('hours', 2),
            'DataInclusao': moment((new Date())).add('hours', 2),
            'IdUsuario': parseInt(usuario.id),
            'Descricao': descricao.trim(),
            'Observacao': observacao.trim(),
            'NumeroNotaFiscal': numeroNotaFiscal.trim(),
            'ValorNotaFiscal': 0,
            'IdContrato': parseInt(idContrato),
            'IdFornecedor': idFornecedor,
            'DataEmpenho': dataInicioEmpenho,
            'DataEmpenhoFinal': dataFimEmpenho,
        }
        dispatch(InserirEmpenhoRequest(data));

    }    
    
    function AlterarEmpenho() {

        let data = {
            'Id': parseInt(id),
            'DataAlteracao': moment((new Date())).add('hours', 2),
            'DataInclusao': moment((new Date())).add('hours', 2),
            'IdUsuario': parseInt(usuario.id),
            'Descricao': descricao.trim(),
            'Observacao': observacao.trim(),
            'NumeroNotaFiscal':numeroNotaFiscal.trim(),
            'ValorNotaFiscal': valorNotaFiscal,
            'IdContrato': parseInt(idContrato),
            'IdFornecedor': parseInt(idFornecedor),
            'DataEmpenho': dataInicioEmpenho,
            'DataEmpenhoFinal': dataFimEmpenho,
        }

        dispatch(AlterarEmpenhoRequest(data));
    }



    function MontarPDF() {
        if (listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined && listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed.length > 0) {
            handleShow();
            dispatch(ListarDadosExportarPDFEmpenhoRequest({ 'IdEmpenho': id }, id));
        } else {
            handleShow();
            dispatch(ListarDadosExportarPDFEmpenhoRequest({ 'IdEmpenho': id }, id));
            //toast.error('Para exportar é necessario que o empenho tenha pelo menos um item.');
        }

    }

    function MontarMedicaoPDF() {
        //if (listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined && listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed.length > 0) {
            handleMedicaoShow();
            dispatch(ListarDadosExportarPDFEmpenhoBoletimMedicaoRequest({ 'IdEmpenho': id }, id));
        //} else {
            //toast.error('Para exportar é necessario que o empenho tenha pelo menos um item.');
        //}
    }

    function MudarFornecedor(e) {
        setIdContrato(e)
        listaFornecedorContrato = listaContratoFornecedor.filter(f => f.idContrato == e)

    }

    function TotalEmpenho() {

        var total = 0.0;

        // Pegar valor da multa
        if (listarMulta != undefined) {
            listarMulta.map(multa => {
                total -= multa.valorMulta;
            })
        }

        // Pegar valor da definitiva
        if (listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined) {
            listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed.map((autorizacaoFornecimento) => {
                if (autorizacaoFornecimento.listaMedicaoFinalizada !== undefined && autorizacaoFornecimento.listaMedicaoFinalizada.length > 0 &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0 &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto[0].statusParcial == false) {

                    autorizacaoFornecimento.listaMedicaoFinalizada.map((medicao) => {

                        medicao.listaEmpenhoAutorizacaoSubitemCusto.map((subitemEmpenho) => {

                            total += subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario * subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.quantidadeFornecedor : 0
                        })
                    })
                }
            })
        }

        // Pegar valor total da parcial
        if (listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined) {

            listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed.map((autorizacaoFornecimento) => {

                if (autorizacaoFornecimento.listaMedicaoFinalizada !== undefined && autorizacaoFornecimento.listaMedicaoFinalizada.length > 0 &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0 &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto[0].statusParcial == true) {

                    autorizacaoFornecimento.listaMedicaoFinalizada.map((medicao) => {

                        medicao.listaEmpenhoAutorizacaoSubitemCusto.map((subitemEmpenho) => {

                            total += subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario * subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.quantidadeFornecedor : 0
                        })
                    })
                }
            })
        }

        /*
        // Pegar valor total da diferenca
        if (listarDiferencaEmpenho != undefined) {

            listarDiferencaEmpenho.map((autorizacaoFornecimento) => {

                if (autorizacaoFornecimento.listaValorEntregaDiferenca.length > 0) {
                    
                    total += autorizacaoFornecimento.valorTotalDiferenca
                }
            })
        }*/

        setValorTotalEmp(total);
    }

    return (
        <>
            {
                carregando ?
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="divBody">
                            <Col md={12}>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formIdCliente">
                                        <Form.Label>Número Empenho</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={empenhoId}
                                            onChange={e => setEmpenhoId(e.target.value)}
                                            disabled
                                            //isInvalid={validated && empenhoId === "" ? true : false}
                                            //isValid={validated && empenhoId !== "" ? true : false}
                                            />
                                        <Form.Control.Feedback type="invalid">O campo Número Empenho é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formIdCliente">
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="60"
                                            value={descricao}
                                            onChange={e => setDescricao(e.target.value)}
                                            required
                                            isInvalid={validated && descricao === "" ? true : false}
                                            isValid={validated && descricao !== "" ? true : false}
                                             />
                                        <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formIdCliente">
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="500"
                                            value={observacao}
                                            onChange={e => setObservacao(e.target.value)}
                                            //required
                                            //isInvalid={validated && observacao === "" ? true : false}
                                            //isValid={validated && observacao !== "" ? true : false}
                                             />
                                        <Form.Control.Feedback type="invalid">O campo Observação é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formIdCliente">
                                        <Form.Label>Número Nota Fiscal</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="60"
                                            value={numeroNotaFiscal}
                                            onChange={e => setNumeroNotaFiscal(e.target.value)}
                                            //required
                                            //isInvalid={validated && numeroNotaFiscal === "" ? true : false}
                                            //isValid={validated && numeroNotaFiscal !== "" ? true : false}
                                             />
                                        <Form.Control.Feedback type="invalid">O campo Número Nota Fiscal é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                    {
                                        /*
                                        <Form.Group as={Col} md={3} controlId="formDescricaoObjeto">
                                        <Form.Label>Valor Nota Fiscal</Form.Label>
                                        <Form.Control
                                            as={IntlCurrencyInput}
                                            currency="BRL"
                                            config={currencyConfig}
                                            maxLength="50"
                                            value={valorNotaFiscal}
                                            onChange={e => setValorNotaFiscal(e.target.value)}
                                            required
                                            isInvalid={validated && valorNotaFiscal === "" ? true : false}
                                            isValid={validated && valorNotaFiscal !== "" ? true : false}
                                            disabled />
                                        <Form.Control.Feedback type="invalid">O campo Valor Nota Fiscal é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                        */
                                    }

                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="formDataInicioEmpenho">
                                        <Form.Label>Data Inicio Empenho</Form.Label>
                                        <Form.Control
                                        type="date"
                                        value={dataInicioEmpenho}
                                        onChange={(e) => verificarDatainicioEmpenho(e)}
                                        required
                                        isInvalid={validated && dataInicioEmpenho === '' ? true : false}
                                        isValid={validated && dataInicioEmpenho !== '' ? true : false}/>
                                        <Form.Control.Feedback type="invalid">O campo Data Inicio Empenho é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formDataFimEmpenho">
                                        <Form.Label>Data Fim Empenho</Form.Label>
                                        <Form.Control
                                        type="date"
                                        value={dataFimEmpenho}
                                        onChange={(e) => verificarDataFimEmpenho(e)}
                                        required
                                        isInvalid={validated && dataFimEmpenho === '' ? true : false}
                                        isValid={validated && dataFimEmpenho !== '' ? true : false}/>
                                        <Form.Control.Feedback type="invalid">A data do fim não pode ser antes da data do início do Empenho.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formIdContrato">
                                        <Form.Label>Contrato</Form.Label>
                                        <Form.Select
                                            onChange={e => setIdContrato(e.target.value)}
                                            value={idContrato}
                                            options={optionsContrato}
                                            required
                                            disabled={id ? true : false}
                                            isInvalid={validated && idContrato === "" ? true : false}
                                            isValid={validated && idContrato !== "" ? true : false}>
                                            
                                            <option value="">Selecione...</option>
                                            {
                                                listaContrato != undefined ?
                                                    listaContrato.map(m => (
                                                        <option value={m.id} key={m.id}>{m.descricao}</option>
                                                    ))
                                                    :
                                                    null
                                            }


                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">O campo Contrato é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formIdFornecedor">
                                        <Form.Label>Fornecedor</Form.Label>
                                        <Form.Select
                                            onChange={e => setIdFornecedor(e.target.value)}
                                            placeholder="Selecione..."
                                            value={idFornecedor}
                                            required
                                            disabled={id ? true : false}
                                            options={optionsFornecedor}
                                            isInvalid={validated && idFornecedor === "" ? true : false}
                                            isValid={validated && idFornecedor !== "" ? true : false}>

                                            <option value="">Selecione...</option>
                                            {
                                            listaRelacao != undefined ?
                                                listaRelacao.map(m => (
                                                        <option value={m.idFornecedor} key={m.idFornecedor}>{listaFornecedor.filter(x => x.id == m.idFornecedor)[0].nomeFornecedor}</option>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">O campo Fornecedor é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="formDescricaoObjeto">
                                        <Form.Label>Valor Total Empenho</Form.Label>
                                        <Form.Control
                                            as={IntlCurrencyInput}
                                            currency="BRL"
                                            config={currencyConfig}
                                            maxLength="50"
                                            value={valorTotalEmp}
                                            onChange={e => setValorTotalEmp(e.target.value)}
                                            disabled
                                            //isInvalid={validated && true === "" ? true : false}
                                            //isValid={validated && true !== "" ? true : false}
                                             />
                                        <Form.Control.Feedback type="invalid">O campo Valor Nota Fiscal é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                            </Col>

                            <Row className="mb-3">
                                <Col md={1}>
                                    <Button disabled={listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined && (
                                       (listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                        listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                        listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0) ||
                                        (listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                        listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0) ? true : false
                                    )}
                                     type="submit" style={{ width:'100%' }}>{id == undefined ? 'Cadastrar' : 'Editar'}</Button>
                                </Col>

                                <Col md={1}>
                                    <Link to='/empenho'><Button style={{ width:'100%'}} variant="outline-warning">Voltar</Button></Link>
                                </Col>
                           
                                <Col md='auto'>
                                    {
                                        id == undefined ?
                                        ''
                                    : 
                                    <Button variant="success" onClick={MontarMedicaoPDF} style={{ width: '100%' }}>
                                        Boletim de Medição
                                    </Button>
                                    }
                                </Col>

                                <Col md='auto'>
                                    {
                                        id == undefined ?
                                        ''
                                    : 
                                    <Button onClick={handleShowModalAssinante} variant="success" style={{ width: '100%' }}>
                                        Exportar Empenho Como PDF
                                    </Button>
                                    }
                                </Col>
                                
                            </Row>

                            <Col md={12}>
                                {
                                    id == undefined ?
                                        ''
                                        :
                                        listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                            listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                            listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0
                                            ?
                                            <Col md={12}>
                                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                                    O Empenho está Cancelado!
                                                </Alert>
                                            </Col>
                                            :
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                ?
                                                <Col md={12}>
                                                    <Alert variant={`success`} style={{ width: '100%' }}>
                                                        O Empenho está Finalizado!
                                                    </Alert>
                                                </Col>
                                                :
                                                ''
                                }
                            </Col>
                        </div>
                    </Form>
                    :
                    <Loading />
            }

            <Modal show={showModalAssinante} onHide={handleCloseModalAssinante} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Selecione o Assinante para esse Empenho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Select id="idSelectAssinante"
                            options={options}
                            placeholder="Selecione o assinante..."
                            onChange={(e) => setNomeAssinante(e.label)}
                        />
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={2}>
                            {
                                NomeAssinante != 0 ?
                                    <Button variant="success" onClick={selecionarAssinante}>
                                        prosseguir
                                    </Button>
                                    :
                                    ''
                            }
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Deseja exportar o empenho?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Row>
                        <Col md={2}>
                            {
                                carregandoPDF && listaDadosExportarPDFEmpenho != null ?
                                    <PDFDownloadLink document={
                                        <PdfEmpenho
                                            data={listaDadosExportarPDFEmpenho != null ? listaDadosExportarPDFEmpenho : null}
                                            assinante={NomeAssinante}
                                        />} fileName="empenho.pdf">
                                        <Button variant="success" onClick={exportaFotos}>
                                            Sim
                                        </Button>
                                        {({ blob, url, loading, error }) => (loading ? 'Carregando Empenho...' : 'Exportar como PDF')}
                                    </PDFDownloadLink>
                                    :
                                    <Loading />
                            }

                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal show={showMedicao} onHide={handleMedicaoClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Deseja exportar o boletim de medição?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Row>
                        <Col md={3}>
                            {
                                carregandoPDF && listaDadosExportarPDFEmpenhoBoletimMedicao != null ?
                                    <PDFDownloadLink document={
                                        <PdfEmpenhoBoletimMedicao
                                            data={listaDadosExportarPDFEmpenhoBoletimMedicao != null ? listaDadosExportarPDFEmpenhoBoletimMedicao : null}
                                        />} fileName="boletimMedicao.pdf">
                                        <Button variant="success">
                                            Sim
                                        </Button>
                                        {({ blob, url, loading, error }) => (loading ? 'Carregando Empenho...' : 'Exportar como PDF')}
                                    </PDFDownloadLink>
                                    :
                                    <Loading />
                            }

                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
}