export function ListarFormularioCaracteristicaRequest() {
    return {
        type: 'LISTAR_FORMULARIO_CARACTERISTICA_REQUEST'
    }
}

export function ListarFormularioCaracteristicaResponse(listaFormularioCaracteristica) {
    return {
        type: 'LISTAR_FORMULARIO_CARACTERISTICA_RESPONSE',
        listaFormularioCaracteristica
    }
}

export function InserirFormularioCaracteristicaRequest(data) {
    return {
        type: 'INSERIR_FORMULARIO_CARACTERISTICA_REQUEST',
        data
    }
}

export function AlterarFormularioCaracteristicaRequest(data) {
    return {
        type: 'ALTERAR_FORMULARIO_CARACTERISTICA_REQUEST',
        data
    }
}

export function ExcluirFormularioCaracteristicaRequest(id) {
    return {
        type: 'EXCLUIR_FORMULARIO_CARACTERISTICA_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}