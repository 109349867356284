import produce from 'immer';

const initialState = {
  listaMunicipio: undefined,
  carregando: true
};

export default function municipio(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MUNICIPIO_RESPONSE':
      return produce(state, draft => {
        draft.listaMunicipio = action.listaMunicipio;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}