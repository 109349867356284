export function ListarCompletoSolicitacaoFornecimentoHistoricoDocArqRequest(idSolicitacaoHistorico) {
    return {
      type: 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST',
      idSolicitacaoHistorico
    }
  }
  
  export function ListarSolicitacaoFornecimentoHistoricoDocArqRequest() {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST'
    }
  }
  
  export function ListarSolicitacaoFornecimentoHistoricoDocArqResponse(listaSolicitacaoFornecimentoHistoricoDocArq) {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_RESPONSE',
      listaSolicitacaoFornecimentoHistoricoDocArq
    }
  }
  
  export function BaixarSolicitacaoFornecimentoHistoricoDocArqRequest(id, numeroSolicitacaoDocArq) {
    return {
      type: 'BAIXAR_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST',
      id,
      numeroSolicitacaoDocArq
    }
  }
  
  export function CarregarImagemSolicitacaoFornecimentoHistoricoDocArqRequest(id) {
    return {
      type: 'CARREGAR_IMAGEM_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }