import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { BuscarInteressadoPorNomeResponse, Carregando, ListarInteressadoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarInteressadoRequest() {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.get, `/api/Interessado`, { headers: headerParams.token });

        yield put(ListarInteressadoResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ListarInteressadoResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* InserirInteressadoRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.post, `/api/Interessado`, model.data, { headers: headerParams.token });

        toast.success(`O interessado foi cadastrado com sucesso!`);

        const result = yield call(api.get, `/api/Interessado`, { headers: headerParams.token });

        yield put(ListarInteressadoResponse(result.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* AlterarInteressadoRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.put, `/api/Interessado`, model.data, { headers: headerParams.token });

        toast.success(`Interessado foi atualizado com sucesso!`);

        // Listar Interessado
        const result = yield call(api.get, `/api/Interessado`, { headers: headerParams.token });

        yield put(ListarInteressadoResponse(result.data.result));
        yield put(Carregando(true));


    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ExcluirInteressadoRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.delete, `/api/Interessado/${model.id}`, { headers: headerParams.token });

        toast.success(`Tipo solicitacao id: ${model.id} foi excluida!`);

        const result = yield call(api.get, `/api/Interessado`, { headers: headerParams.token });

        yield put(ListarInteressadoResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* BuscarInteressadoPorNomeRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.get, `/api/Interessado/BuscarPorNome/${model.interessado}`,
            { headers: headerParams.token });

        yield put(BuscarInteressadoPorNomeResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(BuscarInteressadoPorNomeResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

export default all([
    takeLatest('LISTAR_INTERESSADO_REQUEST', ListarInteressadoRequest),
    takeLatest('INSERIR_INTERESSADO_REQUEST', InserirInteressadoRequest),
    takeLatest('ALTERAR_INTERESSADO_REQUEST', AlterarInteressadoRequest),
    takeLatest('EXCLUIR_INTERESSADO_REQUEST', ExcluirInteressadoRequest),
    takeLatest('BUSCAR_INTERESSADO_POR_NOME_REQUEST', BuscarInteressadoPorNomeRequest),
])