import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarDiferencaEmpenhoRequest } from "../../../store/modules/empenhoStatus/actions";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import IntlCurrencyInput from "react-intl-currency-input";
import 'moment/locale/pt-br';

export default function Diferenca() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const listarDiferencaEmpenho = useSelector(state => state.empenhoStatus.listarDiferencaEmpenho);
    const carregando = useSelector(state => state.empenhoStatus.carregando);

    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState([]);
    const [valorTotalDiferenca, setValorTotalDiferenca] = useState(0);

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    useEffect(() => {

        moment.locale('pt-br');

        if (id != undefined) {
            dispatch(ListarDiferencaEmpenhoRequest(id));
        }

    }, [id])

    useEffect(() => {

        if (listarDiferencaEmpenho != undefined)
            ConstroiHierarquia()

    }, [listarDiferencaEmpenho])

    function ConstroiHierarquia() {

        let hierarquiaCompleta = [];
        let valorTotal = 0.0;

        if (listarDiferencaEmpenho != undefined) {

            listarDiferencaEmpenho.map((autorizacaoFornecimento) => {

                if (autorizacaoFornecimento.listaValorEntregaDiferenca.length > 0) {

                    hierarquiaCompleta.push({
                        id: autorizacaoFornecimento.idAutorizacaoFornecimento,
                        numeroAutorizacao: autorizacaoFornecimento.idAutorizacaoFornecimento,
                        valorTotalDiferenca: autorizacaoFornecimento.valorTotalDiferenca,
                        item: null,
                        quantidade: null,
                        diferenca: null,
                        valor: null,
                        valorDeduzir: null
                    });
                    valorTotal += autorizacaoFornecimento.valorTotalDiferenca
                    autorizacaoFornecimento.listaValorEntregaDiferenca.map(function (diferenca, y) {
                        var x = ('D').concat(diferenca.idAutorizacaoFornecimento).concat(diferenca.idSubitemCusto)
                        hierarquiaCompleta.push({
                            id: x,
                            parentId: diferenca.idAutorizacaoFornecimento,
                            numeroAutorizacao: null,
                            valorTotalDiferenca: null,
                            item: diferenca.item,
                            quantidade: diferenca.quantidade,
                            diferenca: diferenca.diferenca,
                            valor: diferenca.valor,
                            valorDeduzir: diferenca.valorTotal
                        });
                    })
                }
            })

            setListaHierarquiaCompleta(hierarquiaCompleta);
            setValorTotalDiferenca(valorTotal);
        }
    }

    return (
        <>
            <div>
                <Col md={12}>

                    {
                        carregando &&   
                        listarDiferencaEmpenho != null
                        ?

                            <div>
                                <MaterialTable 

                                    components={{

                                        Pagination: PatchedPagination,
                                    }
                                }

                                    title=''

                                    columns={[
                                        {
                                            title: 'Número Autorização', field: 'numeroAutorizacao', cellStyle: {
                                                width: '10%'
                                            }
                                        },
                                        {
                                            title: 'Valor Total Diferença Autorização', field: 'valorTotalDiferenca', cellStyle: {
                                                width: '15%'
                                            }, render: rowData =>
                                                rowData.valorTotalDiferenca === null ?
                                                    null
                                                    :
                                                    rowData.valorTotalDiferenca.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })
                                        },
                                        {
                                            title: 'Item', field: 'item', cellStyle: {
                                                width: '15%'
                                            }
                                        },
                                        {
                                            title: 'Quantidade Fiscalizada', field: 'quantidade', cellStyle: {
                                                width: '15%'
                                            }
                                        },
                                        {
                                            title: 'Diferença', field: 'diferenca', cellStyle: {
                                                width: '15%'
                                            }
                                        },
                                        {
                                            title: 'Valor Unitário', field: 'valor', cellStyle: {
                                                width: '15%'
                                            }
                                        },
                                        {
                                            title: 'Valor a Deduzir', field: 'valorDeduzir', cellStyle: {
                                                width: '15%'
                                            }
                                        },
                                    ]}
                                    data={listaHierarquiaCompleta}
                                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                    options={{
                                        minBodyHeight: 500,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (rowData.item === null) ? '#9D9999' : '#BCB8B8'
                                        })
                                    }}
                                    localization={{
                                        header: {
                                            actions: 'Ações'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                            },
                                            deleteTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                                <Col md={12}>
                                    <br />
                                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                                        <Form.Label>Total Diferença Empenho</Form.Label>
                                        <Form.Control
                                            config={currencyConfig}
                                            as={IntlCurrencyInput}
                                            currency="BRL"
                                            type="text"
                                            value={valorTotalDiferenca}
                                            disabled
                                        />
                                    </Form.Group>
                                    <br />
                                </Col>
                            </div>
                            :
                            <Loading />
                    }
                </Col>
            </div>
        </>
    )
}