export function ListarMedicaoAutorizacaoFornecimentoRequest(id) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST',
    id
  }
}

export function ListarTodasMedicaoAutorizacaoFornecimentoRequest() {
  return {
    type: 'LISTAR_TODAS_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST',
  }
}

export function ListarTodasMedicaoAutorizacaoFornecimentoResponse(listaTodasMedicaoAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_TODAS_MEDICAO_AUTORIZACAO_FORNECIMENTO_RESPONSE',
    listaTodasMedicaoAutorizacaoFornecimento
  }
}

export function ListarMedicaoAutorizacaoFornecimentoResponse(listaMedicaoAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_RESPONSE',
    listaMedicaoAutorizacaoFornecimento
  }
}

export function InserirMedicaoAutorizacaoFornecimentoRequest(data) {
  return {
    type: 'INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function AlterarMedicaoAutorizacaoFornecimentoRequest(data) {
  return {
    type: 'ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function ExcluirMedicaoAutorizacaoFornecimentoRequest(data) {
  return {
    type: 'EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function BuscarMedicaoAutorizacaoFornecimentoPorIdRequest(id) {
  return {
    type: 'BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_POR_ID_REQUEST',
    id
  }
}

export function BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(medicaoAutorizacaoFornecimentoCompleto) {
  return {
    type: 'BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_POR_ID_RESPONSE',
    medicaoAutorizacaoFornecimentoCompleto
  }
}

export function LimparMedicao() {
  return {
    type: 'LIMPAR_MEDICAO'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}