export function ListarContratoVersaoRequest(idContrato) {
    return {
        type: 'LISTAR_CONTRATO_VERSAO_REQUEST', idContrato
    }
}

export function ListarContratoVersaoResponse(listaContratoVersao) {
    return {
        type: 'LISTAR_CONTRATO_VERSAO_RESPONSE',
        listaContratoVersao
    }
}

export function BuscarContratoVersaoPorIdRequest(id) {
    return {
        type: 'BUSCAR_CONTRATO_VERSAO_POR_ID_REQUEST',
        id
    }
}

export function BuscarContratoVersaoPorIdResponse(contratoVersaoCompleto) {
    return {
        type: 'BUSCAR_CONTRATO_VERSAO_POR_ID_RESPONSE',
        contratoVersaoCompleto
    }
}

export function InserirContratoVersaoRequest(data) {
    return {
        type: 'INSERIR_CONTRATO_VERSAO_REQUEST',
        data
    }
}

export function InserirItensContratoVersaoRequest(data, idContrato) {
    return {
        type: 'INSERIR_ITENS_CONTRATO_VERSAO_REQUEST',
        data, idContrato
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}