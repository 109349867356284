export function ListarContratoDocumentoArquivoRequest(id) {
  return {
    type: 'LISTAR_CONTRATO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function ListarContratoDocumentoArquivoResponse(listaContratoDocumentoArquivo) {
  return {
    type: 'LISTAR_CONTRATO_DOCUMENTO_ARQUIVO_RESPONSE',
    listaContratoDocumentoArquivo
  }
}

export function InserirContratoDocumentoArquivoRequest(data, id) {
  return {
    type: 'INSERIR_CONTRATO_DOCUMENTO_ARQUIVO_REQUEST',
    data,
    id
  }
}

export function AlterarContratoDocumentoArquivoRequest(data, id) {
  return {
    type: 'ALTERAR_CONTRATO_DOCUMENTO_ARQUIVO_REQUEST',
    data,
    id
  }
}

export function ExcluirContratoDocumentoArquivoRequest(id, idContrato) {
  return {
    type: 'EXCLUIR_CONTRATO_DOCUMENTO_ARQUIVO_REQUEST',
    id,
    idContrato
  }
}

export function BaixarContratoDocumentoArquivoRequest(id) {
  return {
    type: 'BAIXAR_CONTRATO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}