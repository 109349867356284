import produce from 'immer';

const initialState = {
  fonteReceitaVersaoCompleta: null,
  listaFonteReceitaVersao: undefined,
  listaFonteReceitaVersaoUltimo: undefined,
  carregando: true
};

export default function fonteReceitaVersao(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_FONTE_RECEITA_VERSAO_RESPONSE':
      return produce(state, draft => {
        draft.listaFonteReceitaVersao = action.listaFonteReceitaVersao;
      });

    case 'BUSCAR_FONTE_RECEITA_VERSAO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.fonteReceitaVersaoCompleta = action.fonteReceitaVersaoCompleta;
      });

    case 'LISTAR_POR_ULTIMA_VERSAO_ANO_RESPONSE':
      return produce(state, draft => {
        draft.listaFonteReceitaVersaoUltimo = action.listaFonteReceitaVersaoUltimo;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}