import produce from 'immer';

const initialState = {
  listaBairro: undefined,
  carregando: true
};

export default function bairro(state = initialState, action) {


  switch (action.type) {

    case 'LISTAR_BAIRRO_RESPONSE':
      return produce(state, draft => {
        draft.listaBairro = action.listaBairro;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}