import { all } from 'redux-saga/effects';

import usuario from './usuario/saga';
import grupoAcesso from './grupoAcesso/saga';
import perfil from './perfil/saga'
import municipio from './municipio/saga';
import grupoFuncaoAcesso from './grupoFuncaoAcesso/saga';
import cliente from './cliente/saga';
import tipoDocumento from './tipoDocumento/saga';
import subitemCusto from './subitemCusto/saga';
import itemCusto from './itemCusto/saga';
import grupoCusto from './grupoCusto/saga';
import contrato from './contrato/saga';
import origemContrato from './origemContrato/saga';
import unidadeMedida from './unidadeMedida/saga';
import contratoUsuario from './contratoUsuario/saga';
import fonteReceita from './fonteReceita/saga';
import global from './global/saga';
import composicaoCusto from './composicaoCusto/saga'
import tipoRecurso from './tipoRecurso/saga'
import fonteVersao from './fonteReceitaVersao/saga'
import solicitacaoFornecimento from './solicitacaoFornecimento/saga';
import regiao from './regiao/saga';
import tipoSolicitacao from './tipoSolicitacao/saga';
import solicitacaoFornecimentoDocumentoArquivo from './solicitacaoFornecimentoDocumentoArquivo/saga';
import contratoVersao from './contratoVersao/saga';
import solicitacaoFornecimentoVersaoSubitemCusto from './solicitacaoFornecimentoVersaoSubitemCusto/saga';
import solicitacaoFornecimentoVersaoStatus from './solicitacaoFornecimentoVersaoStatus/saga';
import tipoDocumentoArquivo from './tipoDocumentoArquivo/saga';
import solicitacaoFornecimentoVersao from './solicitacaoFornecimentoVersao/saga';
import tipoContratoVersao from './tipoContratoVersao/saga';
import relatorio from './relatorio/saga';
import tipoSolicitacaoTipoDocumentoArquivo from './tipoSolicitacaoTipoDocumentoArquivo/saga';
import solicitacaoFornecimentoRegistroFotografico from './solicitacaoFornecimentoRegistroFotografico/saga';
import autorizacaoFornecimento from './autorizacaoFornecimento/saga';
import autorizacaoFornecimentoSubitemCusto from './autorizacaoFornecimentoSubitemCusto/saga';
import medicaoAutorizacaoFornecimento from './medicaoAutorizacaoFornecimento/saga';
import statusSolicitacaoFornecimento from './statusSolicitacaoFornecimento/saga';
import medicaoAutorizacaoFornecimentoDocumento from './medicaoAutorizacaoFornecimentoDocumento/saga';
import statusAutorizacaoFornecimento from './statusAutorizacaoFornecimento/saga';
import autorizacaoFornecimentoStatus from './autorizacaoFornecimentoStatus/saga';
import statusMedicaoAutorizacaoFornecimento from './statusMedicaoAutorizacaoFornecimento/saga';
import medicaoAutorizacaoFornecimentoStatus from './medicaoAutorizacaoFornecimentoStatus/saga';
import medicaoAutorizacaoFornecimentoRegistroFotografico from './medicaoAutorizacaoFornecimentoRegistroFotografico/saga';
import autorizacaoFornecimentoSubitemCustoMedicao from './autorizacaoFornecimentoSubitemCustoMedicao/saga';
import autorizacaoFornecimentoSubitemCustoMedicaoDocArq from './autorizacaoFornecimentoSubitemCustoMedicaoDocArq/saga';
import medicaoAutorizacaoFornecimentoOcorrencia from './medicaoAutorizacaoFornecimentoOcorrencia/saga';
import fornecedor from './fornecedor/saga';
import tipoPessoa from './tipoPessoa/saga';
import contratoVersaoSubitemCustoPrimario from './contratoVersaoSubitemCustoPrimario/saga';
import contratoVersaoSubitemCustoConsumido from './contratoVersaoSubitemCustoConsumido/saga';
import medicaoOcorrencia from './medicaoOcorrencia/saga';
import autorizacaoFornecimentoSubitemCustoMedOcor from './autorizacaoFornecimentoSubitemCustoMedOcor/saga';
import tipoPix from './tipoPix/saga';
import cnae from './cnae/saga';
import banco from './banco/saga';
import fornecedorContato from './fornecedorContato/saga';
import fornecedorConta from './fornecedorConta/saga';
import autorizacaoFornecimentoSubitemCustoMedicaoRegFoto from './autorizacaoFornecimentoSubitemCustoMedicaoRegFoto/saga';
import autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto from './autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/saga';
import medicaoAutorizacaoFornecimentoOcorrenciaRegFoto from './medicaoAutorizacaoFornecimentoOcorrenciaRegFoto/saga';
import usuarioFornecedor from './usuarioFornecedor/saga';
import contratoFornecedor from './contratoFornecedor/saga';
import contratoDocumentoArquivo from './contratoDocumentoArquivo/saga';
import prioridade from './prioridade/saga';
import empenho from './empenho/saga';
import statusEmpenho from './statusEmpenho/saga';
import empenhoStatus from './empenhoStatus/saga';
import faturamento from './faturamento/saga';
import empenhoAutorizacaoFornecimentoSubitemCustoMed from './empenhoAutorizacaoFornecimentoSubitemCustoMed/saga';
import empenhoDocumentoArquivo from './empenhoDocumentoArquivo/saga';
import faturamentoDocumentoArquivo from './faturamentoDocumentoArquivo/saga';
import categoriaItem from './categoriaItem/saga';
import categoriaItemSubitemCustoPrimario from './categoriaItemSubitemCustoPrimario/saga';
import bairro from './bairro/saga';
import dashboard from './dashboard/saga';
import interessado from './interessado/saga';
import tipoCaracteristica from './tipoCaracteristica/saga';
import caracteristica from './caracteristica/saga';
import formulario from './formulario/saga';
import caracteristicaResposta from './caracteristicaResposta/saga';
import formularioCaracteristica from './formularioCaracteristica/saga';
import solicitacaoFornecimentoCaracteristicaResposta from './solicitacaoFornecimentoCaracteristicaResposta/saga';
import limiteValor from './limiteValor/saga';
import orgao from './orgao/saga';
import multa from './multa/saga';
import eventoAdicional from './eventoAdicional/saga';
import eventoResposta from './eventoResposta/saga';
import historicoAutorizacaoFornecimentoEmpenho from './historicoAutorizacaoFornecimentoEmpenho/saga';
import solicitacaoFornecimentoHistorico from './solicitacaoFornecimentoHistorico/saga';
import solicitacaoFornecimentoHistoricoRegFoto from './solicitacaoFornecimentoHistoricoRegFoto/saga';
import solicitacaoFornecimentoHistoricoDocArq from './solicitacaoFornecimentoHistoricoDocArq/saga';
import notificacao from './notificacao/saga';
import assinante from './assinante/saga';
import regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem from './regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/saga';
import meses from './meses/saga';
import obrasIntervencoesPorMes from './obrasIntervencoesPorMes/saga';
import manutencoesPorMes from './manutencoesPorMes/saga';

export default function* rootSaga() {
  return yield all([
    usuario, grupoAcesso, perfil, municipio, grupoFuncaoAcesso,
    cliente, tipoDocumento, subitemCusto, itemCusto, grupoCusto,
    contrato, origemContrato, unidadeMedida, contratoUsuario,
    fonteReceita, global, composicaoCusto, tipoRecurso, fonteVersao,
    solicitacaoFornecimento, regiao, tipoSolicitacao,
    solicitacaoFornecimentoDocumentoArquivo, contratoVersao, solicitacaoFornecimentoVersaoSubitemCusto, solicitacaoFornecimentoVersaoStatus,
    tipoDocumentoArquivo, solicitacaoFornecimentoVersao, tipoContratoVersao,
    relatorio, tipoSolicitacaoTipoDocumentoArquivo, solicitacaoFornecimentoRegistroFotografico,
    autorizacaoFornecimento, autorizacaoFornecimentoSubitemCusto, medicaoAutorizacaoFornecimento,
    statusSolicitacaoFornecimento, medicaoAutorizacaoFornecimentoDocumento, statusAutorizacaoFornecimento, autorizacaoFornecimentoStatus,
    statusMedicaoAutorizacaoFornecimento, medicaoAutorizacaoFornecimentoStatus, medicaoAutorizacaoFornecimentoRegistroFotografico,
    autorizacaoFornecimentoSubitemCustoMedicao, autorizacaoFornecimentoSubitemCustoMedicaoDocArq, medicaoAutorizacaoFornecimentoOcorrencia, fornecedor, tipoPessoa,
    contratoVersaoSubitemCustoPrimario, contratoVersaoSubitemCustoConsumido, medicaoOcorrencia, autorizacaoFornecimentoSubitemCustoMedOcor,
    tipoPix, cnae, banco, fornecedorContato, fornecedorConta, autorizacaoFornecimentoSubitemCustoMedicaoRegFoto,
    autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto, medicaoAutorizacaoFornecimentoOcorrenciaRegFoto, usuarioFornecedor,
    contratoFornecedor, contratoDocumentoArquivo, prioridade, empenho, statusEmpenho, empenhoStatus, faturamento, empenhoAutorizacaoFornecimentoSubitemCustoMed,
    empenhoDocumentoArquivo, faturamentoDocumentoArquivo, categoriaItem, categoriaItemSubitemCustoPrimario, bairro, dashboard, interessado,
    tipoCaracteristica, caracteristica, formulario, caracteristicaResposta, formularioCaracteristica, solicitacaoFornecimentoCaracteristicaResposta,
    limiteValor, orgao, multa, eventoAdicional, eventoResposta, historicoAutorizacaoFornecimentoEmpenho, solicitacaoFornecimentoHistorico,
      solicitacaoFornecimentoHistoricoRegFoto, solicitacaoFornecimentoHistoricoDocArq, notificacao, assinante, regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem, meses,
      obrasIntervencoesPorMes, manutencoesPorMes
  ])
}