import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarSolicitacaoFornecimentoDocumentoArquivoResponse } from './actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarSolicitacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdSolicitacao: model.id }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
      
    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
     
  }
}

function* InserirSolicitacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/SolicitacaoFornecimentoDocumentoArquivo`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdSolicitacao: model.id }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });

    yield put(Carregando(true));
    
    toast.success('Documento(s): ' + listaId + ' cadastrado(s) com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
        }
      }

    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
          
  }
}

function* AlterarSolicitacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/SolicitacaoFornecimentoDocumentoArquivo`, model.data, { headers: headerParams.token });

    toast.success(`Documentos atualizado com sucesso!`);

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdSolicitacao: model.id }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
   
  }
}

function* ExcluirSolicitacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/SolicitacaoFornecimentoDocumentoArquivo/${model.id}`, { headers: headerParams.token });

    toast.success(`Documento id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdSolicitacao: parseInt(model.idSolicitacao) }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarSolicitacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
     
  }
}

export default all([
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', ListarSolicitacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('INSERIR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', InserirSolicitacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('ALTERAR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', AlterarSolicitacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', ExcluirSolicitacaoFornecimentoDocumentoArquivoRequest)
])