export function AtualizarPerfilRequest(data) {
  return {
    type: 'ATUALIZAR_PERFIL_REQUEST',
    data
  }
}

export function AtualizarPerfilResponse(data) {
  return {
    type: 'ATUALIZAR_PERFIL_RESPONSE',
    data
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}