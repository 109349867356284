import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarLimiteValorResponse, Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarLimiteValorRequest(){

  try{

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/limiteValor`, { headers:headerParams.token });

    yield put(ListarLimiteValorResponse(result.data.result)); 
    yield put(Carregando(true));    
  
  }catch(e){

    if (e.response != null && e.response.data != null) {
      if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }
  
    yield put(ListarLimiteValorResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirLimiteValorRequest(model){

  try{
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/limiteValor`, model.data, { headers:headerParams.token });
    
    yield put(Carregando(true));    

    toast.success(`Limite Valor foi cadastrado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));   
    yield put(Carregando(true));    

  }
}

function* AlterarLimiteValorRequest(model){

  try{
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/limiteValor`, model.data, { headers: headerParams.token });

    yield put(Carregando(true));
      
    toast.success(`Limite valor foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));     
    yield put(Carregando(true));    
  }
}

function* ExcluirLimiteValorRequest(model){

  try{ 

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/limiteValor/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Limite Valor id: ${model.id} foi excluido!`);
  
  }catch(e){

    if (e.response != null && e.response.data != null) {
      if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }
  
    yield put(ErrorResponse(e));      
    yield put(Carregando(true));    
  }
}

export default all([
    takeLatest('LISTAR_LIMITE_VALOR_REQUEST', ListarLimiteValorRequest),
    takeLatest('INSERIR_LIMITE_VALOR_REQUEST', InserirLimiteValorRequest),
    takeLatest('ALTERAR_LIMITE_VALOR_REQUEST', AlterarLimiteValorRequest),
    takeLatest('EXCLUIR_LIMITE_VALOR_REQUEST', ExcluirLimiteValorRequest),    
])