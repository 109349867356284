import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarSolicitacaoFornecimentoHistoricoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarSolicitacaoFornecimentoHistoricoComRedirecionamentoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdSolicitacaoFornecimento': parseInt(model.idSolicitacao)
    }
    
    history.push(`/historicoSolicitacao/${model.idSolicitacao}`); 

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoHistorico/listarCompleto`, data, { headers: headerParams.token });
    
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarSolicitacaoFornecimentoHistoricoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}


function* ListarSolicitacaoFornecimentoHistoricoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdSolicitacaoFornecimento': parseInt(model.data)
  }
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoHistorico/listarCompleto`, data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoHistoricoResponse([result.data.result]));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarSolicitacaoFornecimentoHistoricoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}


export default all([
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_COM_REDIRECIONAMENTO_REQUEST', ListarSolicitacaoFornecimentoHistoricoComRedirecionamentoRequest),
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_REQUEST', ListarSolicitacaoFornecimentoHistoricoRequest),
])