import { useEffect, useState } from "react";
import { Button, Alert, Col, Modal, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import { ListarCompletoCategoriaItemSubitemCustoPrimarioRequest, ExcluirCategoriaItemSubitemCustoPrimarioRequest } from "../../store/modules/categoriaItemSubitemCustoPrimario/actions";
import { ListarSubitemCustoRequest } from "../../store/modules/subitemCusto/actions";
import ModalInserirSubitemCategoriaItem from "../ModalInserirSubitemCategoriaItem/modalInserirSubitemCategoriaItem"
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalCategoriaItemSubitemCustoPrimario({ show, onHide, idCategoriaItem, idContrato }) {

    const { id } = useParams();
    const dispatch = useDispatch();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaSubitemCusto = useSelector(state => state.subitemCusto.listaSubitemCusto);
    const listaCompletaCategoriaItemSubitemCustoPrimario = useSelector(state =>
        state.categoriaItemSubitemCustoPrimario.listaCompletaCategoriaItemSubitemCustoPrimario);

    const [showSubitem, setShowSubitem] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleCloseSubitem = () => setShowSubitem(false);
    const handleShowSubitem = () => setShowSubitem(true);


    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarSubitemCustoRequest());

        if (idCategoriaItem != undefined && idCategoriaItem != '') {

            var data = { 'IdCategoriaItem': idCategoriaItem }
            dispatch(ListarCompletoCategoriaItemSubitemCustoPrimarioRequest(data))
        }

    }, [idCategoriaItem])

    function ExcluirSubitem(idSubitem) {

        if (idCategoriaItem != undefined)
            dispatch(ExcluirCategoriaItemSubitemCustoPrimarioRequest(idSubitem, idCategoriaItem));
    }

    function InserirSubitem() {

        handleShowSubitem();
    }

    return (
        <>
            <Modal show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>

                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={
                                        <Button
                                            variant="success"
                                            onClick={InserirSubitem}
                                            style={{ width: '100%' }}
                                        >Adicionar item</Button>
                                    }
                                    columns={[
                                        {
                                            title: 'Código Interno',
                                            field: 'codigoInterno',
                                            cellStyle: {
                                                width: '30%'
                                            },
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Descrição Item',
                                            field: 'descricaoSubitem',
                                            filtering: false,
                                            cellStyle: {
                                                width: '70%'
                                            },
                                            editable: 'never'
                                        }
                                    ]}
                                    data={
                                        listaCompletaCategoriaItemSubitemCustoPrimario != undefined ?
                                            listaCompletaCategoriaItemSubitemCustoPrimario
                                                .map((categoriaItemSubitemCustoPrimario) => {
                                                    return {
                                                        id: categoriaItemSubitemCustoPrimario.id,
                                                        codigoInterno: categoriaItemSubitemCustoPrimario.subitemCusto.descricaoCodigoInterno,
                                                        descricaoSubitem: categoriaItemSubitemCustoPrimario.subitemCusto.descricao
                                                    }
                                                })
                                            :
                                            []
                                    }
                                    options={{
                                        minBodyHeight: 500,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true
                                    }}
                                    editable={{
                                        onRowDelete:
                                            oldData => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    ExcluirSubitem(oldData.id);
                                                    resolve()
                                                }, 1000)
                                            })
                                    }}
                                    localization={{
                                        header: {
                                            actions: <span className="classHeaderTabela">Ações</span>
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                saveTooltip: '',
                                                cancelTooltip: '',
                                            },
                                            deleteTooltip: '',
                                            editTooltip: '',
                                            addTooltip: '',
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <ModalInserirSubitemCategoriaItem
                show={showSubitem}
                onHide={handleCloseSubitem}
                idCategoriaItem={idCategoriaItem}
                idContrato={idContrato}
            />
        </>
    )
}