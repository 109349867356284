export function ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(id) {
    return {
      type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST',
      id
    }
  }
  
  export function ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse(listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto) {
    return {
      type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_RESPONSE',
      listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto
    }
  }

  export function InserirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(data, id) {
    return {
      type: 'INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST',
      data,
      id
    }
  }
  
  export function AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(data, id) {
    return {
      type: 'ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST',
      data,
      id
    }
  }
  
  export function ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(idFoto, data) {
    return {
      type: 'EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST',
      idFoto,
      data
    }
  }


  export function CarregarImagemMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(id) {
    return {
      type: 'CARREGAR_IMAGEM_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST',
      id
    }
  }

  export function BaixarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(id) {
    return {
      type: 'BAIXAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST',
      id
    }
  }
  
export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}