export function ListarFornecedorContaRequest() {
    return {
      type: 'LISTAR_FORNECEDOR_CONTA_REQUEST'
    }
}

export function ListarCompletoFornecedorContaRequest(idFornecedor) {
    return {
      type: 'LISTAR_COMPLETO_FORNECEDOR_CONTA_REQUEST',
      idFornecedor
    }
  }

export function ListarFornecedorContaResponse(listaFornecedorConta) {
  return {
    type: 'LISTAR_FORNECEDOR_CONTA_RESPONSE',
    listaFornecedorConta
  }
}

export function BuscarFornecedorContaPorIdRequest(id) {
  return {
    type: 'BUSCAR_FORNECEDOR_CONTA_POR_ID_REQUEST',
    id
  }
}

export function BuscarFornecedorContaPorIdResponse(fornecedorContaCompleta) {
  return {
    type: 'BUSCAR_FORNECEDOR_CONTA_POR_ID_RESPONSE',
    fornecedorContaCompleta
  }
}

export function InserirFornecedorContaRequest(data) {
  return {
    type: 'INSERIR_FORNECEDOR_CONTA_REQUEST',
    data
  }
}

export function AlterarFornecedorContaRequest(data) {
  return {
    type: 'ALTERAR_FORNECEDOR_CONTA_REQUEST',
    data
  }
}

export function ExcluirFornecedorContaRequest(id) {
  return {
    type: 'EXCLUIR_FORNECEDOR_CONTA_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
