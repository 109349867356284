import { combineReducers } from 'redux';

import usuario from './usuario/reducer';
import grupoAcesso from './grupoAcesso/reducer';
import perfil from './perfil/reducer'
import municipio from './municipio/reducer';
import grupoFuncaoAcesso from './grupoFuncaoAcesso/reducer';
import cliente from './cliente/reducer';
import tipoDocumento from './tipoDocumento/reducer';
import subitemCusto from './subitemCusto/reducer';
import itemCusto from './itemCusto/reducer';
import grupoCusto from './grupoCusto/reducer';
import contrato from './contrato/reducer';
import origemContrato from './origemContrato/reducer';
import unidadeMedida from './unidadeMedida/reducer';
import contratoUsuario from './contratoUsuario/reducer';
import fonteReceita from './fonteReceita/reducer';
import composicaoCusto from './composicaoCusto/reducer';
import tipoRecurso from './tipoRecurso/reducer';
import fonteReceitaVersao from './fonteReceitaVersao/reducer';
import global from './global/reducer';
import solicitacaoFornecimento from './solicitacaoFornecimento/reducer';
import regiao from './regiao/reducer';
import tipoSolicitacao from './tipoSolicitacao/reducer';
import solicitacaoFornecimentoDocumentoArquivo from './solicitacaoFornecimentoDocumentoArquivo/reducer';
import contratoVersao from './contratoVersao/reducer';
import solicitacaoFornecimentoVersaoSubitemCusto from './solicitacaoFornecimentoVersaoSubitemCusto/reducer';
import solicitacaoFornecimentoVersaoStatus from './solicitacaoFornecimentoVersaoStatus/reducer';
import tipoDocumentoArquivo from './tipoDocumentoArquivo/reducer';
import solicitacaoFornecimentoVersao from './solicitacaoFornecimentoVersao/reducer';
import tipoContratoVersao from './tipoContratoVersao/reducer';
import relatorio from './relatorio/reducer';
import tipoSolicitacaoTipoDocumentoArquivo from './tipoSolicitacaoTipoDocumentoArquivo/reducer';
import solicitacaoFornecimentoRegistroFotografico from './solicitacaoFornecimentoRegistroFotografico/reducer';
import autorizacaoFornecimento from './autorizacaoFornecimento/reducer';
import autorizacaoFornecimentoSubitemCusto from './autorizacaoFornecimentoSubitemCusto/reducer';
import medicaoAutorizacaoFornecimento from './medicaoAutorizacaoFornecimento/reducer';
import statusSolicitacaoFornecimento from './statusSolicitacaoFornecimento/reducer';
import statusAutorizacaoFornecimento from './statusAutorizacaoFornecimento/reducer';
import autorizacaoFornecimentoStatus from './autorizacaoFornecimentoStatus/reducer';
import statusMedicaoAutorizacaoFornecimento from './statusMedicaoAutorizacaoFornecimento/reducer';
import medicaoAutorizacaoFornecimentoStatus from './medicaoAutorizacaoFornecimentoStatus/reducer';
import medicaoAutorizacaoFornecimentoDocumento from './medicaoAutorizacaoFornecimentoDocumento/reducer'
import medicaoAutorizacaoFornecimentoRegistroFotografico from './medicaoAutorizacaoFornecimentoRegistroFotografico/reducer';
import autorizacaoFornecimentoSubitemCustoMedicao from './autorizacaoFornecimentoSubitemCustoMedicao/reducer';
import autorizacaoFornecimentoSubitemCustoMedicaoDocArq from './autorizacaoFornecimentoSubitemCustoMedicaoDocArq/reducer';
import medicaoAutorizacaoFornecimentoOcorrencia from './medicaoAutorizacaoFornecimentoOcorrencia/reducer';
import fornecedor from './fornecedor/reducer';
import tipoPessoa from './tipoPessoa/reducer';
import contratoVersaoSubitemCustoPrimario from './contratoVersaoSubitemCustoPrimario/reducer';
import contratoVersaoSubitemCustoConsumido from './contratoVersaoSubitemCustoConsumido/reducer';
import medicaoOcorrencia from './medicaoOcorrencia/reducer';
import autorizacaoFornecimentoSubitemCustoMedOcor from './autorizacaoFornecimentoSubitemCustoMedOcor/reducer';
import tipoPix from './tipoPix/reducer';
import cnae from './cnae/reducer';
import banco from './banco/reducer';
import fornecedorContato from './fornecedorContato/reducer';
import fornecedorConta from './fornecedorConta/reducer';
import autorizacaoFornecimentoSubitemCustoMedicaoRegFoto from './autorizacaoFornecimentoSubitemCustoMedicaoRegFoto/reducer';
import autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto from './autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/reducer';
import medicaoAutorizacaoFornecimentoOcorrenciaRegFoto from './medicaoAutorizacaoFornecimentoOcorrenciaRegFoto/reducer';
import usuarioFornecedor from './usuarioFornecedor/reducer';
import contratoFornecedor from './contratoFornecedor/reducer';
import contratoDocumentoArquivo from './contratoDocumentoArquivo/reducer';
import prioridade from './prioridade/reducer';
import empenho from './empenho/reducer';
import statusEmpenho from './statusEmpenho/reducer';
import empenhoStatus from './empenhoStatus/reducer';
import faturamento from './faturamento/reducer';
import empenhoAutorizacaoFornecimentoSubitemCustoMed from './empenhoAutorizacaoFornecimentoSubitemCustoMed/reducer';
import empenhoDocumentoArquivo from './empenhoDocumentoArquivo/reducer';
import faturamentoDocumentoArquivo from './faturamentoDocumentoArquivo/reducer';
import categoriaItem from './categoriaItem/reducer';
import categoriaItemSubitemCustoPrimario from './categoriaItemSubitemCustoPrimario/reducer';
import bairro from './bairro/reducer';
import dashboard from './dashboard/reducer';
import interessado from './interessado/reducer';
import tipoCaracteristica from './tipoCaracteristica/reducer';
import caracteristica from './caracteristica/reducer';
import formulario from './formulario/reducer';
import caracteristicaResposta from './caracteristicaResposta/reducer';
import formularioCaracteristica from './formularioCaracteristica/reducer';
import solicitacaoFornecimentoCaracteristicaResposta from './solicitacaoFornecimentoCaracteristicaResposta/reducer';
import limiteValor from './limiteValor/reducer';
import orgao from './orgao/reducer';
import multa from './multa/reducer';
import eventoAdicional from './eventoAdicional/reducer';
import eventoResposta from './eventoResposta/reducer';
import historicoAutorizacaoFornecimentoEmpenho from './historicoAutorizacaoFornecimentoEmpenho/reducer';
import solicitacaoFornecimentoHistorico from './solicitacaoFornecimentoHistorico/reducer';
import solicitacaoFornecimentoHistoricoRegFoto from './solicitacaoFornecimentoHistoricoRegFoto/reducer';
import solicitacaoFornecimentoHistoricoDocArq from './solicitacaoFornecimentoHistoricoDocArq/reducer';
import notificacao from './notificacao/reducer';
import assinante from './assinante/reducer';
import regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem from './regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/reducer';
import meses from './meses/reducer';
import obrasIntervencoesPorMes from './obrasIntervencoesPorMes/reducer';
import manutencoesPorMes from './manutencoesPorMes/reducer';

export default combineReducers({
  usuario, grupoAcesso, perfil, municipio, grupoFuncaoAcesso,
  cliente, tipoDocumento, subitemCusto, itemCusto, grupoCusto,
  contrato, origemContrato, unidadeMedida, contratoUsuario,
  fonteReceita, global, composicaoCusto, tipoRecurso, fonteReceitaVersao,
  solicitacaoFornecimento, regiao, tipoSolicitacao,
  solicitacaoFornecimentoDocumentoArquivo, solicitacaoFornecimentoVersaoStatus, contratoVersao,
  solicitacaoFornecimentoVersaoSubitemCusto, tipoDocumentoArquivo, solicitacaoFornecimentoVersao,
  tipoContratoVersao, relatorio, tipoSolicitacaoTipoDocumentoArquivo, solicitacaoFornecimentoRegistroFotografico,
  autorizacaoFornecimento, autorizacaoFornecimentoSubitemCusto, medicaoAutorizacaoFornecimento,
  statusSolicitacaoFornecimento, medicaoAutorizacaoFornecimentoDocumento, statusAutorizacaoFornecimento, autorizacaoFornecimentoStatus,
  statusMedicaoAutorizacaoFornecimento, medicaoAutorizacaoFornecimentoStatus, medicaoAutorizacaoFornecimentoRegistroFotografico,
  autorizacaoFornecimentoSubitemCustoMedicao, autorizacaoFornecimentoSubitemCustoMedicaoDocArq, medicaoAutorizacaoFornecimentoOcorrencia, fornecedor, tipoPessoa,
  contratoVersaoSubitemCustoPrimario, contratoVersaoSubitemCustoConsumido, medicaoOcorrencia, autorizacaoFornecimentoSubitemCustoMedOcor,
  tipoPix, cnae, banco, fornecedorContato, fornecedorConta, autorizacaoFornecimentoSubitemCustoMedicaoRegFoto,
  autorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto, medicaoAutorizacaoFornecimentoOcorrenciaRegFoto, usuarioFornecedor, contratoFornecedor,
  contratoDocumentoArquivo, prioridade, empenho, statusEmpenho, empenhoStatus, faturamento, empenhoAutorizacaoFornecimentoSubitemCustoMed,
  empenhoDocumentoArquivo, faturamentoDocumentoArquivo, categoriaItem, categoriaItemSubitemCustoPrimario, bairro, dashboard, interessado,
  tipoCaracteristica, caracteristica, formulario, caracteristicaResposta, formularioCaracteristica, solicitacaoFornecimentoCaracteristicaResposta,
  limiteValor, orgao, multa, eventoAdicional, eventoResposta, historicoAutorizacaoFornecimentoEmpenho, solicitacaoFornecimentoHistorico,
    solicitacaoFornecimentoHistoricoRegFoto, solicitacaoFornecimentoHistoricoDocArq, notificacao, assinante, regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem,
    meses, obrasIntervencoesPorMes, manutencoesPorMes
})