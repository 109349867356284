import produce from 'immer';

const initialState = {
  listaManutencoesPorMes: undefined,
  carregando: true
};

export default function manutencoesPorMes(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MANUTENCOES_POR_MES_RESPONSE':
      return produce(state, draft => {
        draft.listaManutencoesPorMes = action.listaManutencoesPorMes;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}