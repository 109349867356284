export function ListarTipoDocumentoRequest() {
  return {
    type: 'LISTAR_TIPO_DOCUMENTO_REQUEST'
  }
}

export function ListarTipoDocumentoResponse(listaTipoDocumento) {
  return {
    type: 'LISTAR_TIPO_DOCUMENTO_RESPONSE',
    listaTipoDocumento
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}