
export function ListarMunicipiosRequest() {
  return {
    type: 'LISTAR_MUNICIPIO_REQUEST'
  }
}

export function ListarMunicipioResponse(listaMunicipio) {
  return {
    type: 'LISTAR_MUNICIPIO_RESPONSE',
    listaMunicipio
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}