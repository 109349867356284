import produce from 'immer';

const initialState = {
  listaSubitemCusto: undefined,
  listaCompletaSubitemCusto: undefined,
  carregando: true
};

export default function subitemCusto(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SUBITEM_CUSTO_RESPONSE':
      return produce(state, draft => {
        draft.listaSubitemCusto = action.listaSubitemCusto;
      });

    case 'LISTAR_SUBITEM_CUSTO_COMPLETO_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletaSubitemCusto = action.listaCompletaSubitemCusto;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}