import produce from 'immer';

const initialState = {
  listaTipoDocumento: undefined,
  carregando: true
};

export default function tipoDocumento(state = initialState, action){
  
  switch(action.type){

    case 'LISTAR_TIPO_DOCUMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaTipoDocumento = action.listaTipoDocumento;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });    

    default:
      return state;
  }
}