import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarStatusEmpenhoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarStatusEmpenhoRequest() {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/StatusEmpenho`, { headers: headerParams.token });

    yield put(ListarStatusEmpenhoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarStatusEmpenhoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirStatusEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/StatusEmpenho`, model.data, { headers: headerParams.token });

    toast.success(`StatusEmpenho ${model.data.Descricao} foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/StatusEmpenho`, { headers: headerParams.token });

    yield put(ListarStatusEmpenhoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
  }
}

function* AlterarStatusEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/StatusEmpenho`, model.data, { headers: headerParams.token });

    toast.success(`StatusEmpenho foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/StatusEmpenho`, { headers: headerParams.token });

    yield put(ListarStatusEmpenhoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    
    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
  }
}

function* ExcluirStatusEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/StatusEmpenho/${model.id}`, { headers: headerParams.token });

    toast.success(`StatusEmpenho id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/StatusEmpenho`, { headers: headerParams.token });

    yield put(ListarStatusEmpenhoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
  }
}

export default all([
  takeLatest('LISTAR_STATUS_EMPENHO_REQUEST', ListarStatusEmpenhoRequest),
  takeLatest('INSERIR_STATUS_EMPENHO_REQUEST', InserirStatusEmpenhoRequest),
  takeLatest('ALTERAR_STATUS_EMPENHO_REQUEST', AlterarStatusEmpenhoRequest),
  takeLatest('EXCLUIR_STATUS_EMPENHO_REQUEST', ExcluirStatusEmpenhoRequest)
])