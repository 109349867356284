import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import { MdOutlineLocalShipping } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Fornecedor from '../Fornecedor/fornecedor';
import Header from "../../../components/Header/header";
import Conta from "../Conta/conta";
import Contato from "../Contato/contato";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export default function Abas() {

    const { id } = useParams();
    
    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <span>
                                    <MdOutlineLocalShipping size={25} color={"#000"} /> Fornecedor
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Tabs defaultActiveKey="Fornecedor" id="tab-fornecedor" className="mb-3">
                                    <Tab eventKey="Fornecedor" title="Fornecedor">
                                        <Fornecedor />
                                    </Tab>
                                    <Tab eventKey="Contas" title="Contas" disabled={id ? false : true}>
                                        <Conta />
                                    </Tab>
                                    <Tab eventKey="Contatos" title="Contatos" disabled={id ? false : true}>
                                        <Contato />
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}