import { Container, Row, Col, Modal } from "react-bootstrap";
import { LimparListaValidacaoEmpenho } from "../../store/modules/empenhoStatus/actions";
import { LimparListaValidacaoMedicao } from "../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { LimparListaValidacaoSolicitacao } from "../../store/modules/solicitacaoFornecimentoVersaoStatus/actions";
import { useDispatch, useSelector } from "react-redux";

export default function ModalVerificacaoSaldoSubitem({ show, onHide, solicitacao, medicao, empenho }) {

    const dispatch = useDispatch();
    const listaValidacaoEmpenhoStatus = useSelector(state => state.empenhoStatus.listaValidacaoEmpenhoStatus);
    const listaValidacaoSolicitacaoStatus = useSelector(state => state.solicitacaoFornecimentoVersaoStatus.listaValidacaoSolicitacaoStatus);
    const listaValidacaoMedicaoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaValidacaoMedicaoStatus);

    function LimparValidacao() {

        if (solicitacao) {

            dispatch(LimparListaValidacaoSolicitacao());
            onHide();

        } else if (medicao) {

            dispatch(LimparListaValidacaoMedicao());
            onHide();

        } else if (empenho) {

            dispatch(LimparListaValidacaoEmpenho());
            onHide();

        }
    }

    return (
        <>
            <Modal show={show}
                onHide={LimparValidacao}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                {
                                    listaValidacaoEmpenhoStatus != undefined && listaValidacaoEmpenhoStatus != null ?
                                        listaValidacaoEmpenhoStatus.map((listaValidacaoEmpenhoStatus) => (
                                            listaValidacaoEmpenhoStatus != null && listaValidacaoEmpenhoStatus != undefined ?
                                                listaValidacaoEmpenhoStatus.saldoContratoAlemExcedente == true ?
                                                    <span className='new-line'>
                                                        <p>
                                                            <h5 className="saldoAlem">O item ({listaValidacaoEmpenhoStatus.descricaoSubitem}) está com uma quantidade total no empenho ({listaValidacaoEmpenhoStatus.quantidadeSubitemMedicaoContrato}) que excede além do permitido o saldo do item no contrato!</h5>
                                                            {`Quantidade máxima do item no contrato é: ${listaValidacaoEmpenhoStatus.quantidadeSubitemMaxima}.
                                                                    Quantidade de itens usados no contrato é: ${listaValidacaoEmpenhoStatus.quantidadeSubitemConsumido}.
                                                                    Assim o saldo atual é: ${listaValidacaoEmpenhoStatus.quantidadeSubitemMaxima - listaValidacaoEmpenhoStatus.quantidadeSubitemConsumido}.`}
                                                        </p>
                                                    </span>
                                                    :
                                                    null
                                                :
                                                null
                                        ))
                                        :
                                        null
                                }
                                {
                                    listaValidacaoEmpenhoStatus != undefined && listaValidacaoEmpenhoStatus != null ?
                                    listaValidacaoEmpenhoStatus.map((listaValidacaoEmpenhoStatus) => (
                                        listaValidacaoEmpenhoStatus != null && listaValidacaoEmpenhoStatus != undefined ?
                                        listaValidacaoEmpenhoStatus.saldoAutorizacaoExcedente == true ?
                                                        <span className='new-line'>
                                                            <p>
                                                                <h5 className="saldoAlem">O item ({listaValidacaoEmpenhoStatus.descricaoSubitem}) está com uma quantidade total no empenho ({listaValidacaoEmpenhoStatus.quantidadeSubitemMedicao}) que excede o saldo do item na autorização ({listaValidacaoEmpenhoStatus.descricaoAutorizacao})!</h5>
                                                                {`Quantidade máxima do item na autorização é: ${listaValidacaoEmpenhoStatus.quantidadeSubitemMaximaAutorizacao}.
                                                                Quantidade de itens usados na autorização é: ${listaValidacaoEmpenhoStatus.quantidadeSubitemConsumidoAutorizacao}.
                                                                Assim o saldo atual é: ${listaValidacaoEmpenhoStatus.quantidadeSubitemMaximaAutorizacao - listaValidacaoEmpenhoStatus.quantidadeSubitemConsumidoAutorizacao}.`}                                                    </p>
                                                        </span>
                                                        :

                                                        null
                                                :
                                                null
                                        ))
                                        :
                                        null
                                }
                                {
                                    listaValidacaoSolicitacaoStatus != undefined && listaValidacaoSolicitacaoStatus != null ?
                                        listaValidacaoSolicitacaoStatus.map((listaValidacaoSolicitacaoStatus) => (
                                            listaValidacaoSolicitacaoStatus != null && listaValidacaoSolicitacaoStatus != undefined ?
                                                listaValidacaoSolicitacaoStatus.saldoContratoAlemExcedente == true ?
                                                    <span className='new-line'>
                                                        <p>
                                                            <h5 className="saldoAlem">O item ({listaValidacaoSolicitacaoStatus.descricaoSubitem}) está com uma quantidade ({listaValidacaoSolicitacaoStatus.quantidadeSubitemMedicao}) que excede o além do permitido o saldo do item no contrato!</h5>
                                                            {`Quantidade máxima do item no contrato é: ${listaValidacaoSolicitacaoStatus.quantidadeSubitemMaxima}.
                                                                    Quantidade de itens usados no contrato é: ${listaValidacaoSolicitacaoStatus.quantidadeSubitemConsumido}.
                                                                    Assim o saldo atual é: ${listaValidacaoSolicitacaoStatus.quantidadeSubitemMaxima - listaValidacaoSolicitacaoStatus.quantidadeSubitemConsumido}.`}
                                                        </p>
                                                    </span>
                                                    :
                                                    null
                                                :
                                                null
                                        ))
                                        :
                                        null
                                }
                                {
                                    listaValidacaoMedicaoStatus != undefined && listaValidacaoMedicaoStatus != null ?
                                        listaValidacaoMedicaoStatus.map((listaValidacaoMedicaoStatus) => (
                                            listaValidacaoMedicaoStatus != null && listaValidacaoMedicaoStatus != undefined ?
                                                listaValidacaoMedicaoStatus.saldoContratoAlemExcedente == true ?
                                                    <span className='new-line'>
                                                        <p>
                                                            <h5 className="saldoAlem">O item ({listaValidacaoMedicaoStatus.descricaoSubitem}) está com uma quantidade ({listaValidacaoMedicaoStatus.quantidadeSubitemMedicao}) que excede além do permitido o saldo do item no contrato!</h5>
                                                            {`Quantidade máxima do item no contrato é: ${listaValidacaoMedicaoStatus.quantidadeSubitemMaxima}.
                                                                    Quantidade de itens usados no contrato é: ${listaValidacaoMedicaoStatus.quantidadeSubitemConsumido}.
                                                                    Assim o saldo atual é: ${listaValidacaoMedicaoStatus.quantidadeSubitemMaxima - listaValidacaoMedicaoStatus.quantidadeSubitemConsumido}.`}                                                    </p>
                                                    </span>
                                                    :

                                                    null
                                                :
                                                null
                                        ))
                                        :
                                        null
                                }
                                {
                                    listaValidacaoMedicaoStatus != undefined && listaValidacaoMedicaoStatus != null ?
                                        listaValidacaoMedicaoStatus.map((listaValidacaoMedicaoStatus) => (
                                            listaValidacaoMedicaoStatus != null && listaValidacaoMedicaoStatus != undefined ?
                                                    listaValidacaoMedicaoStatus.saldoAutorizacaoExcedente == true ?
                                                        <span className='new-line'>
                                                            <p>
                                                                <h5 className="saldoAlem">O item ({listaValidacaoMedicaoStatus.descricaoSubitem}) está com uma quantidade ({listaValidacaoMedicaoStatus.quantidadeSubitemMedicao}) que excede o saldo do item na autorização!</h5>
                                                                {`Quantidade máxima do item na autorização é: ${listaValidacaoMedicaoStatus.quantidadeSubitemMaximaAutorizacao}.
                                                                Quantidade de itens usados na autorização é: ${listaValidacaoMedicaoStatus.quantidadeSubitemConsumidoAutorizacao}.
                                                                Assim o saldo atual é: ${listaValidacaoMedicaoStatus.quantidadeSubitemMaximaAutorizacao - listaValidacaoMedicaoStatus.quantidadeSubitemConsumidoAutorizacao}.`}                                                    </p>
                                                        </span>
                                                        :

                                                        null
                                                :
                                                null
                                        ))
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}