import { Navbar, Container, Nav, Offcanvas, Button, Popover, Overlay, Badge, Col, Form, Row } from "react-bootstrap";
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SidebarData } from '../SidebarData/sidebarData';
import { Link } from "react-router-dom";
import { IoPersonCircleSharp, IoNotificationsCircle } from "react-icons/io5";
import SubMenu from '../SubMenu/subMenu';
import history from '../../services/history';
import logo from '../../assets/logo.png';
import "./header.css";
import { AlterarNotificacaoRequest, ListarNotificacaoRequest } from "../../store/modules/notificacao/actions";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function Header() {

  const dispatch = useDispatch();
  const usuario = JSON.parse(localStorage.getItem('token')).usuario;
  const listaNotificacao = useSelector(state => state.notificacao.listaNotificacao);

  const ref = useRef(null);
  const refNotificacao = useRef(null);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showNotificacao, setShowNotificacao] = useState(false);
  const [targetNotificacao, setTargetNotificacao] = useState(null);

  useEffect(() => {

    moment.locale('pt-br');
    dispatch(ListarNotificacaoRequest(usuario.id));

  }, [])


  useEffect(() => {

    if (listaNotificacao != null && listaNotificacao.length == 0) {
      setShowNotificacao(false)
    }

  }, [listaNotificacao])

  const handleClick = (event) => {
    setShow(!show);
    setShowNotificacao(false);
    setTarget(event.target);
  };

  const handleClickNotificacao = (event) => {
    setShowNotificacao(!showNotificacao);
    setShow(false);
    setTargetNotificacao(event.target);
  };

  function MudarStatusNotificacao(notificacao) {

    let data = {
      'Id': notificacao.id,
      'IdUsuario': notificacao.idUsuario,
      'Mensagem': notificacao.mensagem,
      'DataNotificacao': notificacao.dataNotificacao,
      'DataVisualizacao': new Date(),
      'Ativo': false
    }

    dispatch(AlterarNotificacaoRequest(data));

  }

  function Sair() {
    localStorage.removeItem('token');
    history.push('/');
  }

  return (
    <Navbar expand={false} className="headerColor">
      <Container fluid>

        <Navbar.Brand href="#" style={{ color: 'white' }}>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
        </Navbar.Brand>
        <Nav>
          <div>
            <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>

              {
                usuario.notificacao == true ?
                  <div ref={refNotificacao} >
                    <a onClick={handleClickNotificacao} href="#/" className="d-flex">
                      <div className="corBotao">
                        <IoNotificationsCircle size={40} color={'white'} />
                        <Badge style={{ backgroundColor: '#1976d2' }}>
                          {
                            listaNotificacao != null && listaNotificacao.length > 0 ?
                              listaNotificacao.length
                              :
                              0
                          }
                        </Badge>
                      </div>

                    </a>

                    <Overlay
                      show={showNotificacao}
                      target={targetNotificacao}
                      placement="bottom"
                      container={refNotificacao}
                      containerPadding={20}
                    >
                      <Popover className="popoverOverlayHeader" id="popover-contained">
                        <div className="tituloNotificacoes">Notificações</div>
                        <Popover.Body className="d-grid gap-2 divNotificacao">
                          {
                            listaNotificacao != null && listaNotificacao.length > 0 ?
                              listaNotificacao.map(notificacao => (
                                // <Link to="/">
                                <div className="divGeralMensagem">

                                  <div className="divMensagem">
                                    <Row className="mb-3">

                                      <Form.Group as={Col} md={10}>
                                        {notificacao.mensagem}
                                      </Form.Group>
                                      <Form.Group as={Col} md={2}>
                                        <a className="buttonXMensagem" onClick={() => MudarStatusNotificacao(notificacao)}>X</a>
                                      </Form.Group>
                                    </Row>
                                  </div>
                                  <div className="divData">
                                    Data {moment(notificacao.dataNotificacao).format('L LT')}
                                  </div>
                                </div>
                                // </Link>
                              ))
                              :
                              ''
                          }

                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>
                  :
                  ''
              }


              <div ref={ref}>
                <a onClick={handleClick} href="#/" className="d-flex">
                  <IoPersonCircleSharp size={40} color={'white'}></IoPersonCircleSharp>
                </a>
                <Overlay
                  show={show}
                  target={target}
                  placement="bottom"
                  container={ref}
                  containerPadding={20}
                >
                  <Popover className="popoverOverlayHeader" id="popover-contained">
                    <Popover.Body className="d-grid gap-2">
                      <Link to="/Perfil">Perfil</Link>
                      <Button variant="danger" size="lg" onClick={Sair}>sair</Button>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>

          </div>
        </Nav>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          className="headerColor"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="logoMenuLateral">
              <img src={logo} alt='img' />
            </div>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <>
                {SidebarData.map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })}
              </>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}