import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse, Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';


function* ListarAutorizacaoFornecimentoSubitemCustoMedOcorRequest(model){

  try {
    
    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor/listarCompleto`,
      { IdAutorizacaoFornecimentoSubitemCustoMedicao: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse(result.data.result)); 
    yield put(Carregando(true));    
    
  }catch(e){
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e)); 
  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoMedOcorRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor/listarCompleto`,
      { 'IdAutorizacaoFornecimentoSubitemCustoMedicao': parseInt(model.data.IdAutorizacaoFornecimentoSubitemCustoMedicao) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse(result.data.result)); 
    yield put(Carregando(true));
    
    toast.success(`Medição Ocorrência foi cadastrado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);  
            }    
          }
        }
      }
  
    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    
  }
}

function* AlterarAutorizacaoFornecimentoSubitemCustoMedOcorRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor/listarCompleto`,
      { IdAutorizacaoFornecimentoSubitemCustoMedicao: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse(result.data.result));
    yield put(Carregando(true));    
  
    toast.success(`Medição Ocorrência foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);  
            }    
          }
        }
      }

    yield put(ErrorResponse(e));      
    yield put(Carregando(true));    

  }
}

function* ExcluirAutorizacaoFornecimentoSubitemCustoMedOcorRequest(model){

  try{

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor/${model.data.Id}`, { headers:headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedOcor/listarCompleto`,
      { 'IdAutorizacaoFornecimentoSubitemCustoMedicao': parseInt(model.data.IdAutorizacaoFornecimentoSubitemCustoMedicao) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse(result.data.result)); 
    yield put(Carregando(true));    

    toast.success(`Medição Ocorrência id: ${model.data.Id} foi excluido!`);
  
  } catch (e) {
    
    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }   

    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST', ListarAutorizacaoFornecimentoSubitemCustoMedOcorRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedOcorRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST', ExcluirAutorizacaoFornecimentoSubitemCustoMedOcorRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST', AlterarAutorizacaoFornecimentoSubitemCustoMedOcorRequest),
])