import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import { IoDocumentText } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import Contrato from '../Contrato/contrato';
import UsuarioPermissao from '../UsuarioPermissao/usuarioPermissao'
import GrupoCusto from '../GrupoCusto/grupoCusto';
import ItemCusto from '../ItemCusto/itemCusto';
import SubitemCusto from '../SubitemCusto/subitemCusto';
import Visualizar from '../Visualizar/visualizar';
import Importar from "../Importar/importar";
import Header from "../../../components/Header/header";
import Fornecedor from "../Fornecedor/fornecedor";
import Documento from '../DocumentoArquivo/documentoArquivo';
import HistoricoVersao from '../HistoricoVersao/historicoVersao';

export default function Abas() {

    const { id } = useParams();

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <span>
                                    <IoDocumentText size={25} color={"#000"} /> Contrato
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Tabs defaultActiveKey="Contrato" id="tab-contrato" className="mb-3">
                                    <Tab eventKey="Contrato" title="Contrato">
                                        <Contrato />
                                    </Tab>
                                    <Tab eventKey="Importar" title="Importar" disabled={id ? false : true}>
                                        <Importar />
                                    </Tab>
                                    <Tab eventKey="Permissao Contrato" title="Permissões" disabled={id ? false : true}>
                                        <UsuarioPermissao />
                                    </Tab>
                                    <Tab eventKey="Fornecedor" title="Fornecedor" disabled={id ? false : true}>
                                        <Fornecedor />
                                    </Tab>
                                    <Tab eventKey="DocumentoArquivo" title="Documentos" disabled={id ? false : true}>
                                        <Documento />
                                    </Tab>
                                    <Tab eventKey="Grupo Custo" title="Grupo Custo" disabled={id ? false : true}>
                                        <GrupoCusto />
                                    </Tab>
                                    <Tab eventKey="Item" title="Item Custo" disabled={id ? false : true}>
                                        <ItemCusto />
                                    </Tab>
                                    <Tab eventKey="Subitem" title="Subitem Custo" disabled={id ? false : true}>
                                        <SubitemCusto />
                                    </Tab>
                                    <Tab eventKey="Visualizar" title="Visualizar" disabled={id ? false : true}>
                                        <Visualizar />
                                    </Tab>
                                    <Tab eventKey="HistoricoVersao" title="Historico Versão" disabled={id ? false : true}>
                                        <HistoricoVersao />
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}