import produce from 'immer';

const initialState = { listaAutorizacaoFornecimentoSubitemCustoMedicao: undefined,
                       autorizacaoFornecimentoSubitemCustoMedicaoCompleto: null};

export default function autorizacaoFornecimentoSubitemCustoMedicao(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_RESPONSE':
        return produce(state, draft => {
            draft.listaAutorizacaoFornecimentoSubitemCustoMedicao = action.listaAutorizacaoFornecimentoSubitemCustoMedicao
        });
    case 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.autorizacaoFornecimentoSubitemCustoMedicaoCompleto = action.autorizacaoFornecimentoSubitemCustoMedicaoCompleto
      });

     case 'CARREGANDO':
        return produce(state, draft => {
            draft.carregando = action.status
        });  
    
    default:
      return state;
  }
}