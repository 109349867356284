export function ListarGrupoFuncaoAcessoRequest(id) {
  return {
    type: 'LISTAR_GRUPO_FUNCAO_ACESSO_REQUEST',
    id
  }
}

export function ListarGrupoFuncaoAcessoResponse(listaGrupoFuncaoAcesso) {
  return {
    type: 'LISTAR_GRUPO_FUNCAO_ACESSO_RESPONSE',
    listaGrupoFuncaoAcesso
  }
}

export function AlterarGrupoFuncaoAcessoRequest(data) {
  return {
    type: 'ALTERAR_GRUPO_FUNCAO_ACESSO_REQUEST',
    data
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}