import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarMedicaoOcorrenciaResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarMedicaoOcorrenciaRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/MedicaoOcorrencia`, { headers: headerParams.token });

    yield put(ListarMedicaoOcorrenciaResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }
    
    yield put(ListarMedicaoOcorrenciaResponse([]));        
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirMedicaoOcorrenciaRequest(model) {

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/MedicaoOcorrencia`, model.data, { headers: headerParams.token });

    toast.success(`A Medição Ocorrência foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/MedicaoOcorrencia`, { headers: headerParams.token });

    yield put(ListarMedicaoOcorrenciaResponse(result.data.result));
    yield put(Carregando(true));     

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(Carregando(true));     
  }
}

function* AlterarMedicaoOcorrenciaRequest(model) {

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/MedicaoOcorrencia`, model.data, { headers: headerParams.token });

    toast.success(`Medição Ocorrência foi atualizada com sucesso!`);

    const result = yield call(api.get, `/api/MedicaoOcorrencia`, { headers: headerParams.token });

    yield put(ListarMedicaoOcorrenciaResponse(result.data.result));
    yield put(Carregando(true));     

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(Carregando(true));     
  }
}

function* ExcluirMedicaoOcorrenciaRequest(model) {

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/MedicaoOcorrencia/${model.id}`, { headers: headerParams.token });

    toast.success(`Medição Ocorrência id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/MedicaoOcorrencia`, { headers: headerParams.token });

    yield put(ListarMedicaoOcorrenciaResponse(result.data.result));
    yield put(Carregando(true));     

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(Carregando(true)); 
    
  }
}

export default all([
  takeLatest('LISTAR_MEDICAO_OCORRENCIA_REQUEST', ListarMedicaoOcorrenciaRequest),
  takeLatest('INSERIR_MEDICAO_OCORRENCIA_REQUEST', InserirMedicaoOcorrenciaRequest),
  takeLatest('ALTERAR_MEDICAO_OCORRENCIA_REQUEST', AlterarMedicaoOcorrenciaRequest),
  takeLatest('EXCLUIR_MEDICAO_OCORRENCIA_REQUEST', ExcluirMedicaoOcorrenciaRequest)
])