export function ListarFonteReceitaRequest() {
  return {
    type: 'LISTAR_FONTE_RECEITA_REQUEST'
  }
}

export function ListarFonteReceitaResponse(listaFonteReceita) {
  return {
    type: 'LISTAR_FONTE_RECEITA_RESPONSE',
    listaFonteReceita
  }
}

export function BuscarFonteReceitaPorIdRequest(id) {
  return {
    type: 'BUSCAR_FONTE_RECEITA_POR_ID_REQUEST',
    id
  }
}

export function BuscarFonteReceitaPorIdResponse(fonteReceitaCompleta) {
  return {
    type: 'BUSCAR_FONTE_RECEITA_POR_ID_RESPONSE',
    fonteReceitaCompleta
  }
}

export function InserirFonteReceitaRequest(data) {
  return {
    type: 'INSERIR_FONTE_RECEITA_REQUEST',
    data
  }
}

export function AlterarFonteReceitaRequest(data) {
  return {
    type: 'ALTERAR_FONTE_RECEITA_REQUEST',
    data
  }
}

export function ExcluirFonteReceitaRequest(id) {
  return {
    type: 'EXCLUIR_FONTE_RECEITA_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
