export function ListarLimiteValorRequest() {
  return {
    type: 'LISTAR_LIMITE_VALOR_REQUEST'
  }
}

export function ListarLimiteValorResponse(listaLimiteValor) {
  return {
    type: 'LISTAR_LIMITE_VALOR_SUCCESS',
    listaLimiteValor
  }
}

export function InserirLimiteValorRequest(data) {
  return {
    type: 'INSERIR_LIMITE_VALOR_REQUEST',
    data
  }
}

export function AlterarLimiteValorRequest(data) {
  return {
    type: 'ALTERAR_LIMITE_VALOR_REQUEST',
    data
  }
}

export function ExcluirLimiteValorRequest(id) {
  return {
    type: 'EXCLUIR_LIMITE_VALOR_REQUEST',
    id
  }
}


export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}