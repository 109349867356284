export function ListarClienteRequest() {
  return {
    type: 'LISTAR_CLIENTE_REQUEST'
  }
}

export function ListarClienteResponse(listaCliente) {
  return {
    type: 'LISTAR_CLIENTE_RESPONSE',
    listaCliente
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}