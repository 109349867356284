import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
    ListarTipoSolicitacaoRequest, ExcluirTipoSolicitacaoRequest,
    InserirTipoSolicitacaoRequest, AlterarTipoSolicitacaoRequest
} from '../../../store/modules/tipoSolicitacao/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ListarTipoDocumentoArquivoRequest } from "../../../store/modules/tipoDocumentoArquivo/actions";
import { ListarFormularioRequest } from "../../../store/modules/formulario/actions";
import { ListarTipoSolicitacaoTipoDocumentoArquivoRequest, InserirTipoSolicitacaoTipoDocumentoArquivoRequest, ExcluirTipoSolicitacaoTipoDocumentoArquivoRequest } from "../../../store/modules/tipoSolicitacaoTipoDocumentoArquivo/actions";
import { IoNewspaperOutline } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import Multiselect from 'multiselect-react-dropdown';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";

export default function TipoSolicitacao() {

    const dispatch = useDispatch();
    const history = useHistory();

    const listaTipoSolicitacao = useSelector(state => state.tipoSolicitacao.listaTipoSolicitacao);
    const listaFormulario = useSelector(state => state.formulario.listaFormulario);
    const listaTipoDocumentoArquivo = useSelector(state => state.tipoDocumentoArquivo.listaTipoDocumentoArquivo);
    const listaTipoSolicitacaoTipoDocumentoArquivo = useSelector(state =>
        state.tipoSolicitacaoTipoDocumentoArquivo.listaTipoSolicitacaoTipoDocumentoArquivo);
    const carregando = useSelector(state => state.tipoSolicitacao.carregando);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    const options = listaTipoDocumentoArquivo != undefined && listaTipoDocumentoArquivo ?
        listaTipoDocumentoArquivo.map(tipoDocumentoArquivo => ({
            value: tipoDocumentoArquivo.id, label: tipoDocumentoArquivo.descricao
        }))
        :
        [];

    useEffect(() => {
        dispatch(ListarTipoSolicitacaoRequest());
        dispatch(ListarFormularioRequest());
        dispatch(ListarTipoDocumentoArquivoRequest());
        dispatch(ListarTipoSolicitacaoTipoDocumentoArquivoRequest())
    }, [])

    function InserirTipoSolicitacao(tipoSolicitacao) {

        dispatch(ListarTipoSolicitacaoRequest());
        dispatch(ListarFormularioRequest());
        dispatch(ListarTipoDocumentoArquivoRequest());
        dispatch(ListarTipoSolicitacaoTipoDocumentoArquivoRequest())

        //Validação do campo descrição
        if (tipoSolicitacao.descricao == null || tipoSolicitacao.descricao == undefined
            || tipoSolicitacao.descricao == ' ' || tipoSolicitacao.descricao.length > 50
            || tipoSolicitacao.descricao == '') {
            toast.error(`Descrição do tipo de solicitação inválida`);
            return;
        }

        let selectTextFormulario = '';

        if (document.getElementById('idSelectFormulario') != null && listaFormulario != undefined) {
            selectTextFormulario = document.getElementById('idSelectFormulario').innerText;
        }

        let data = {
            'Id': parseInt(0),
            'Descricao': tipoSolicitacao.descricao.trim(),
            'Servico': tipoSolicitacao.servico != undefined ? tipoSolicitacao.servico : false,
            'Geografico': tipoSolicitacao.geografico != undefined ? tipoSolicitacao.geografico : false,
            'IdFormulario': null,
            'InputServico': tipoSolicitacao.servico != undefined ? tipoSolicitacao.servico : false,
            'FotoObrigatorio': tipoSolicitacao.fotoObrigatorio != undefined ? tipoSolicitacao.fotoObrigatorio : false,
            'QuantidadeFotoObrigatorio': tipoSolicitacao.quantidadeFoto != null ? tipoSolicitacao.quantidadeFoto : null,
            'TipoFornecedor': tipoSolicitacao.tipoFornecedor != undefined ? tipoSolicitacao.tipoFornecedor : false,
            'EventoEspecial': tipoSolicitacao.eventoEspecial != undefined ? tipoSolicitacao.eventoEspecial : false,
            'SemOficio': tipoSolicitacao.semOficio != undefined ? tipoSolicitacao.semOficio : false,
            'EventoResposta': false
        }

        dispatch(InserirTipoSolicitacaoRequest(data));
    }

    function AlterarTipoSolicitacao(tipoSolicitacao) {

        //Validação do campo descrição
        if (tipoSolicitacao.descricao == null || tipoSolicitacao.descricao == undefined
            || tipoSolicitacao.descricao == ' ' || tipoSolicitacao.descricao.length > 50
            || tipoSolicitacao.descricao == '') {
            toast.error(`Descrição do tipo de solicitação inválida`);
            return;
        }

        let selectTextFormulario = '';

        if (document.getElementById('idSelectFormulario') != null && listaFormulario != undefined) {
            selectTextFormulario = document.getElementById('idSelectFormulario').innerText;
        }

        let data = {
            'Id': tipoSolicitacao.id,
            'Descricao': tipoSolicitacao.descricao.trim(),
            'Servico': tipoSolicitacao.servico != undefined ? tipoSolicitacao.servico : false,
            'Geografico': tipoSolicitacao.geografico != undefined ? tipoSolicitacao.geografico : false,
            'IdFormulario': tipoSolicitacao.idFormulario == '' ? null : tipoSolicitacao.idFormulario,
            'InputServico': tipoSolicitacao.inputServico,
            'FotoObrigatorio': tipoSolicitacao.fotoObrigatorio != undefined ? tipoSolicitacao.fotoObrigatorio : false,
            'QuantidadeFotoObrigatorio': tipoSolicitacao.quantidadeFoto != null ? tipoSolicitacao.quantidadeFoto : null,
            'TipoFornecedor': tipoSolicitacao.tipoFornecedor != undefined ? tipoSolicitacao.tipoFornecedor : false,
            'EventoEspecial': tipoSolicitacao.eventoEspecial != undefined ? tipoSolicitacao.eventoEspecial : false,
            'SemOficio': tipoSolicitacao.semOficio != undefined ? tipoSolicitacao.semOficio : false,
            'EventoResposta': tipoSolicitacao.eventoResposta != undefined ? tipoSolicitacao.eventoResposta : false
        }

        dispatch(AlterarTipoSolicitacaoRequest(data))
    }

    function ExcluirTipoSolicitacao(id) {
        dispatch(ExcluirTipoSolicitacaoRequest(id));
    }

    function AddListaTipoSolicitacaoTipoDocumentoArquivo(event, add, idTipoSolicitacao) {

        var data = {
            'Id': 0,
            'IdTipoSolicitacao': idTipoSolicitacao,
            'IdTipoDocumentoArquivo': add.value,
        }

        dispatch(InserirTipoSolicitacaoTipoDocumentoArquivoRequest(data));
    }

    function RemoveListaTipoSolicitacaoTipoDocumentoArquivo(event, add, idTipoSolicitacao) {

        if (add.label != undefined) {
            var tipoDocumento = listaTipoDocumentoArquivo.find(documento => documento.descricao === add.label);

            var relacao = listaTipoSolicitacaoTipoDocumentoArquivo.find(relacao => relacao.idTipoSolicitacao === idTipoSolicitacao
                && relacao.idTipoDocumentoArquivo === tipoDocumento.id);

            dispatch(ExcluirTipoSolicitacaoTipoDocumentoArquivoRequest(relacao.id));

        } else {

            var relacao = listaTipoSolicitacaoTipoDocumentoArquivo.filter(s => s.idTipoSolicitacao === idTipoSolicitacao)[add];

            dispatch(ExcluirTipoSolicitacaoTipoDocumentoArquivoRequest(relacao.id));
        }
    }

    function Cancel() {
        dispatch(ListarTipoSolicitacaoRequest());
        dispatch(ListarFormularioRequest());
        dispatch(ListarTipoDocumentoArquivoRequest());
        dispatch(ListarTipoSolicitacaoTipoDocumentoArquivoRequest())
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <IoNewspaperOutline size={25} color={"#000"} /> Tipo Solicitacao
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregando ?

                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Número do Tipo de Solicitação', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                    width: '10%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Descrição', field: 'descricao', sorting: true, editPlaceholder: 'Descrição', validate: rowData => {
                                                                    if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    else if (rowData.descricao.trim().length > 50) {
                                                                        return "A descrição não pode conter mais que 50 caracteres"
                                                                    }
                                                                    return true
                                                                }, cellStyle: { width: '20%' }
                                                            },
                                                            {
                                                                title: "Tipo Documento Arquivo", field: 'tipoDocumentoArquivo', cellStyle: { width: '20%' }, editable: 'onUpdate',
                                                                editComponent: (props) => (
                                                                    <Multiselect
                                                                        options={options}
                                                                        avoidHighlightFirstOption={true}
                                                                        selectedValues={
                                                                            listaTipoSolicitacaoTipoDocumentoArquivo != undefined ?
                                                                                props.rowData ?
                                                                                    listaTipoSolicitacaoTipoDocumentoArquivo.map((relacao) => {
                                                                                        if (props.rowData.id === relacao.idTipoSolicitacao) {
                                                                                            return {
                                                                                                value: props.value,
                                                                                                label: relacao.tipoDocumentoArquivo.descricao
                                                                                            }
                                                                                        } else {
                                                                                            return null
                                                                                        }
                                                                                    }).filter(Boolean)
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                        disablePreSelectedValues={false}
                                                                        placeholder="Selecione..."
                                                                        displayValue="label"
                                                                        onSelect={(e, a) => { AddListaTipoSolicitacaoTipoDocumentoArquivo(e, a, props.rowData.id) }}
                                                                        onRemove={(e, a) => { RemoveListaTipoSolicitacaoTipoDocumentoArquivo(e, a, props.rowData.id) }}
                                                                        emptyRecordMsg="Nenhuma opção disponível."
                                                                    ></Multiselect>
                                                                ),
                                                            },
                                                            {
                                                                title: 'Geográfico', field: 'geografico', sorting: true,
                                                                cellStyle: { width: '12.5%' },
                                                                customFilterAndSearch: (term, rowData) => term == rowData.geografico.toString(),
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={[{ value: 'ativado', label: 'Sim' },
                                                                            { value: 'desativado', label: 'Não' },]}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData => (<input type="checkbox" checked={rowData.geografico} onChange={() => { }} />)

                                                            },
                                                            {
                                                                title: 'Serviço', field: 'servico', sorting: true, editable: 'onAdd',
                                                                cellStyle: { width: '12.5%' },
                                                                customFilterAndSearch: (term, rowData) => term == rowData.servico.toString(),
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={[{ value: 'ativado', label: 'Sim' },
                                                                            { value: 'desativado', label: 'Não' },]}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData => (<input type="checkbox" checked={rowData.servico} onChange={() => { }} />)
                                                            },
                                                            {
                                                                title: 'Foto Obrigatório', field: 'fotoObrigatorio', sorting: true,
                                                                cellStyle: { width: '12.5%' },
                                                                customFilterAndSearch: (term, rowData) => term == rowData.fotoObrigatorio.toString(),
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={[{ value: 'ativado', label: 'Sim' },
                                                                            { value: 'desativado', label: 'Não' },]}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData => (<input type="checkbox" checked={rowData.fotoObrigatorio} onChange={() => { }} />)
                                                            },
                                                            {
                                                                title: 'Quantidade de Fotos Obrigatórias', field: 'quantidadeFoto', cellStyle: {
                                                                    width: '12.5%'
                                                                }
                                                            },
                                                            {
                                                                title: 'Tipo Fornecedor', field: 'tipoFornecedor', sorting: true, editable: 'onAdd',
                                                                cellStyle: { width: '12.5%' },
                                                                customFilterAndSearch: (term, rowData) => term == rowData.tipoFornecedor.toString(),
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={[{ value: 'ativado', label: 'Sim' },
                                                                            { value: 'desativado', label: 'Não' },]}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData => (<input type="checkbox" checked={rowData.tipoFornecedor} onChange={() => { }} />)
                                                            }
                                                        ]}
                                                        data={
                                                            listaTipoSolicitacao != undefined ?
                                                                listaTipoSolicitacao.map(tipoSolicitacao => {
                                                                    return {
                                                                        id: tipoSolicitacao.id,
                                                                        descricao: tipoSolicitacao.descricao,
                                                                        servico: tipoSolicitacao.servico,
                                                                        geografico: tipoSolicitacao.geografico,
                                                                        idFormulario: tipoSolicitacao.idFormulario != null ? tipoSolicitacao.idFormulario : '',
                                                                        inputServico: tipoSolicitacao.inputServico,
                                                                        fotoObrigatorio: tipoSolicitacao.fotoObrigatorio,
                                                                        quantidadeFoto: tipoSolicitacao.quantidadeFotoObrigatorio,
                                                                        tipoFornecedor: tipoSolicitacao.tipoFornecedor,
                                                                        eventoEspecial: tipoSolicitacao.eventoEspecial,
                                                                        semOficio: tipoSolicitacao.semOficio,
                                                                        tipoDocumentoArquivo:
                                                                            listaTipoSolicitacaoTipoDocumentoArquivo != undefined && listaTipoDocumentoArquivo != undefined ?
                                                                                listaTipoSolicitacaoTipoDocumentoArquivo.map((relacao) => {
                                                                                    if (tipoSolicitacao.id === relacao.idTipoSolicitacao) {
                                                                                        if (listaTipoSolicitacaoTipoDocumentoArquivo.filter(relacao => tipoSolicitacao.id == relacao.idTipoSolicitacao).length > 1) {
                                                                                            return relacao.tipoDocumentoArquivo.descricao + ", "
                                                                                        }
                                                                                        else {
                                                                                            return relacao.tipoDocumentoArquivo.descricao + " "
                                                                                        }
                                                                                    } else {
                                                                                        return ''
                                                                                    }
                                                                                }).filter(Boolean)
                                                                                :
                                                                                ''
                                                                    }
                                                                })
                                                                :
                                                                []
                                                        }
                                                        icons={{
                                                            Add: () =>
                                                                <Button variant="success">
                                                                    <span>
                                                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                                                    </span>
                                                                </Button>
                                                        }}
                                                        editable={{
                                                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        InserirTipoSolicitacao(newRow);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        AlterarTipoSolicitacao(newRow);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        ExcluirTipoSolicitacao(oldData.id);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowUpdateCancelled: rowData => Cancel(),
                                                        }}

                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            filtering: true,
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            actionsColumnIndex: -1

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Loading />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}