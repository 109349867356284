export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(id) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse(listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_RESPONSE',
    listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto
  }
}

export function InserirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(data, id) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST',
    data,
    id
  }
}

export function AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(data, id) {
  return {
    type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST',
    data,
    id
  }
}

export function ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(id, idMedicao) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST',
    id,
    idMedicao
  }
}

export function BaixarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(id) {
  return {
    type: 'BAIXAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(id) {
  return {
    type: 'CARREGAR_IMAGEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}