import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarSolicitacaoFornecimentoHistoricoDocArqResponse } from './actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import history from '../../../services/history';

function* ListarCompletoSolicitacaoFornecimentoHistoricoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoHistoricoDocArq/listarCompleto`,
      { IdSolicitacaoFornecimentoHistorico: parseInt(model.idSolicitacaoHistorico) }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoHistoricoDocArqResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarSolicitacaoFornecimentoHistoricoDocArqResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarSolicitacaoFornecimentoHistoricoDocArqRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoHistoricoDocArq`, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoHistoricoDocArqResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarSolicitacaoFornecimentoHistoricoDocArqResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BaixarSolicitacaoFornecimentoHistoricoDocArqRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/SolicitacaoFornecimentoHistoricoDocArq/listar/${model.numeroSolicitacaoDocArq}/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result[0].nomeArquivo}${result.data.result[0].descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/SolicitacaoFornecimentoHistoricoDocArq/${model.numeroSolicitacaoDocArq}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });

    yield put(Carregando(true));
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(Carregando(true));
    yield put(ErrorResponse(e));
  }
}

function* CarregarImagemSolicitacaoFornecimentoHistoricoDocArqRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
  
    fetch(`${urlBase}/api/SolicitacaoFornecimentoHistoricoDocArq/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoSolicitacaoFornecimentoHistoricoDocArq").appendChild(elem).style.width = "100%";        
        });
    
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST', ListarCompletoSolicitacaoFornecimentoHistoricoDocArqRequest),
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST', ListarSolicitacaoFornecimentoHistoricoDocArqRequest),
  takeLatest('BAIXAR_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST', BaixarSolicitacaoFornecimentoHistoricoDocArqRequest),
  takeLatest('CARREGAR_IMAGEM_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_REQUEST', CarregarImagemSolicitacaoFornecimentoHistoricoDocArqRequest)
])