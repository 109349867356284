import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Col, Button, Alert } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarMedicaoOcorrenciaRequest } from '../../../store/modules/medicaoOcorrencia/actions';
import {
    InserirAutorizacaoFornecimentoSubitemCustoMedOcorRequest,
    ListarAutorizacaoFornecimentoSubitemCustoMedOcorRequest,
    AlterarAutorizacaoFornecimentoSubitemCustoMedOcorRequest
} from '../../../store/modules/autorizacaoFornecimentoSubitemCustoMedOcor/actions';
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import MaterialTable from "material-table";
import ModalOcorrenciaMedicaoSubitem from '../../../components/ModalOcorrenciaMedicaoSubitem/modalOcorrenciaMedicaoSubitem';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function Ocorrencia() {

    const { idMedicao, idMedicaoSubItem } = useParams();
    const dispatch = useDispatch();

    const listaMedicaoOcorrencia = useSelector(state => state.medicaoOcorrencia.listaMedicaoOcorrencia);
    const listaAutorizacaoFornecimentoSubitemCustoMedOcor = useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedOcor.listaAutorizacaoFornecimentoSubitemCustoMedOcor);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);

    const [show, setShow] = useState(false);
    const [dataId, setDataId] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedRow, setSelectedRow] = useState(null);

    const options = listaMedicaoOcorrencia != undefined && listaMedicaoOcorrencia ?
        listaMedicaoOcorrencia.map(lista => ({
            value: lista.id, label: lista.descricao
        }))
        :
        [];

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarMedicaoOcorrenciaRequest());

    }, [])

    useEffect(() => {

        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idMedicao !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

        if (idMedicaoSubItem !== undefined) {
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoMedOcorRequest(idMedicaoSubItem));
        }

    }, [idMedicao, idMedicaoSubItem])

    function InserirAutorizacaoFornecimentoSubitemCustoMedOcor(autorizacaoFornecimentoSubitemCustoMedOcor) {

        let selectText = '';
        let selectSelecionado = [];

        if (document.getElementById('idSelectMedicaoOcorrencia') != null) {
            selectText = document.getElementById('idSelectMedicaoOcorrencia').innerText;
            selectSelecionado = listaMedicaoOcorrencia.filter(soli => soli.descricao == selectText);
        }

        if (autorizacaoFornecimentoSubitemCustoMedOcor.observacao == ' ' || autorizacaoFornecimentoSubitemCustoMedOcor.observacao == ''
            || autorizacaoFornecimentoSubitemCustoMedOcor.observacao == null || autorizacaoFornecimentoSubitemCustoMedOcor.observacao == undefined) {
            toast.error(`Observação Inválida`);
            return;
        }
        if (autorizacaoFornecimentoSubitemCustoMedOcor.observacao.length > 500) {
            toast.error(`A observação não pode conter mais que 500 caracteres`);
            return;
        }

        if (selectSelecionado.length == 0) {
            toast.error(`Medição Ocorrência não selecionado`);
            return;
        }

        let data = {
            'Id': parseInt(0),
            'IdMedicaoOcorrencia': parseInt(selectSelecionado[0].id),
            'IdAutorizacaoFornecimentoSubitemCustoMedicao': idMedicaoSubItem,
            'Observacao': autorizacaoFornecimentoSubitemCustoMedOcor.observacao
        }
        dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedOcorRequest(data));
    }

    function AlterarAutorizacaoFornecimentoSubitemCustoMedOcor(autorizacaoFornecimentoSubitemCustoMedOcor) {

        let selectText = document.getElementById('idSelectMedicaoOcorrencia').innerText;
        let selectSelecionado = listaMedicaoOcorrencia.filter(soli => soli.descricao == selectText);

        if (selectSelecionado.length == 0) {
            toast.error(`Recurso não selecionado`);
            return;
        }

        if (autorizacaoFornecimentoSubitemCustoMedOcor.observacao == ' ' || autorizacaoFornecimentoSubitemCustoMedOcor.observacao == ''
            || autorizacaoFornecimentoSubitemCustoMedOcor.observacao == null || autorizacaoFornecimentoSubitemCustoMedOcor.observacao == undefined) {
            toast.error(`Observação Inválida`);
            return;
        }

        if (autorizacaoFornecimentoSubitemCustoMedOcor.observacao.length > 500) {
            toast.error(`A observação não pode conter mais que 500 caracteres`);
            return;
        }

        let data = {
            'Id': autorizacaoFornecimentoSubitemCustoMedOcor.id,
            'IdMedicaoOcorrencia': parseInt(selectSelecionado[0].id),
            'IdAutorizacaoFornecimentoSubitemCustoMedicao': autorizacaoFornecimentoSubitemCustoMedOcor.idAutorizacaoFornecimentoSubitemCustoMedicao,
            'Observacao': autorizacaoFornecimentoSubitemCustoMedOcor.observacao
        };

        dispatch(AlterarAutorizacaoFornecimentoSubitemCustoMedOcorRequest(data, idMedicaoSubItem))
    }

    function AlterarValores(data) {
        setDataId(data);
        setShow(true);
    }

    return (
        <>
            <Form>
                <div>
                    <Col md={12}>
                        {
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: 'Número da Ocorrência',
                                        field: 'id',
                                        defaultSort: 'desc',
                                        cellStyle: {
                                            width: '20%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Observação',
                                        field: 'observacao',
                                        defaultSort: 'desc',
                                        cellStyle: {
                                            width: '40%'
                                        },
                                        validate: rowData => {
                                            if (rowData.observacao !== undefined && rowData.observacao.length > 500) {
                                                return "A observação não pode conter mais que 500 caracteres"
                                            }
                                            else if (rowData.observacao == undefined || rowData.observacao.trim() == '') {
                                                return "Campo obrigatório"
                                            }
                                            return true
                                        },
                                    },
                                    {
                                        title: 'Medição Ocorrência',
                                        field: 'idMedicaoOcorrencia',
                                        cellStyle: {
                                            width: '40%'
                                        },
                                        lookup:
                                            listaMedicaoOcorrencia != undefined && listaMedicaoOcorrencia ?
                                                listaMedicaoOcorrencia.map(medicao => {
                                                    return {
                                                        id: medicao.id, descricao: medicao.descricao
                                                    }
                                                }).reduce(function (acc, cur, i) {
                                                    acc[cur.id] = cur.descricao;
                                                    return acc;
                                                }, {})
                                                :
                                                null,
                                        editComponent: (props) => (
                                            <Select id="idSelectMedicaoOcorrencia"
                                                options={options}
                                                placeholder="Selecione..."
                                                defaultValue={{
                                                    value: props.value, label: listaMedicaoOcorrencia.filter(soli => soli.id == props.value).length > 0
                                                        ? listaMedicaoOcorrencia.filter(soli => soli.id == props.value)[0].descricao
                                                        : 'Selecione...'
                                                }}
                                            />
                                        ),

                                    }
                                ]}
                                data={
                                    listaAutorizacaoFornecimentoSubitemCustoMedOcor != undefined ?
                                        listaAutorizacaoFornecimentoSubitemCustoMedOcor.map((lista) => {
                                            return {
                                                id: lista.id,
                                                idMedicaoOcorrencia: lista.idMedicaoOcorrencia,
                                                idAutorizacaoFornecimentoSubitemCustoMedicao: lista.idAutorizacaoFornecimentoSubitemCustoMedicao,
                                                observacao: lista.observacao
                                            }
                                        })
                                        :
                                        []
                                }
                                editable={{
                                    onRowUpdate:
                                        idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                            listaMedicaoAutorizacaoFornecimentoStatus
                                            [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                            ?
                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    AlterarAutorizacaoFornecimentoSubitemCustoMedOcor(newRow);
                                                    resolve()
                                                }, 1000)
                                            })
                                            :
                                            null,
                                    onRowAdd:
                                        idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                            listaMedicaoAutorizacaoFornecimentoStatus
                                            [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                            ?
                                            (newRow) => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    InserirAutorizacaoFornecimentoSubitemCustoMedOcor(newRow);
                                                    resolve()
                                                }, 1000)
                                            })
                                            :
                                            null,
                                }}
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    addRowPosition: "first",
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'visibility',
                                        onClick: (event, rowData) => { AlterarValores(rowData.id) }
                                    })
                                ]}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                            saveTooltip: '',
                                            cancelTooltip: '',
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: '',
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                        }
                    </Col>
                    <Col md={12}>
                        {
                            idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined
                                ?
                                // Verificação se o status atual é o cancelado
                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`danger`} style={{ width: '100%' }}>
                                            A Medição está Cancelada!
                                        </Alert>
                                    </Col>
                                    :
                                    // Verificação se o status atual é o finalizado
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                        listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                        ?
                                        <Col md={12}>
                                            <Alert variant={`success`} style={{ width: '100%' }}>
                                                A Medição está Finalizada!
                                            </Alert>
                                        </Col>
                                        :
                                        ''
                                :
                                ''
                        }
                    </Col>
                </div>
            </Form >
            <ModalOcorrenciaMedicaoSubitem
                showModal={show}
                onHide={handleClose}
                idMedicao={idMedicao}
                data={dataId}
            />
        </>
    )
}