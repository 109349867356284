export function ListarContratoFornecedorRequest() {
  return {
    type: 'LISTAR_CONTRATO_FORNECEDOR_REQUEST'
    
  }
}

export function ListarContratoFornecedorResponse(listaContratoFornecedor) {
  return {
    type: 'LISTAR_CONTRATO_FORNECEDOR_RESPONSE',
    listaContratoFornecedor
  }
}

export function ListarCompletoContratoFornecedorRequest(idContratoVersao) {
  return {
    type: 'LISTAR_COMPLETO_CONTRATO_FORNECEDOR_REQUEST',
    idContratoVersao
  }
}

export function BuscarContratoFornecedorPorIdRequest(id) {
  return {
    type: 'BUSCAR_CONTRATO_FORNECEDOR_POR_ID_REQUEST',
    id
  }
}

export function BuscarContratoFornecedorPorIdResponse(contratoFornecedorCompleta) {
  return {
    type: 'BUSCAR_CONTRATO_FORNECEDOR_POR_ID_RESPONSE',
    contratoFornecedorCompleta
  }
}

export function InserirContratoFornecedorRequest(data) {
  return {
    type: 'INSERIR_CONTRATO_FORNECEDOR_REQUEST',
    data
  }
}

export function AlterarContratoFornecedorRequest(data) {
  return {
    type: 'ALTERAR_CONTRATO_FORNECEDOR_REQUEST',
    data
  }
}

export function ExcluirContratoFornecedorRequest(id) {
  return {
    type: 'EXCLUIR_CONTRATO_FORNECEDOR_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
