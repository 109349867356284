export function ListarTipoPessoaRequest() {
    return {
      type: 'LISTAR_TIPO_PESSOA_REQUEST'
    }
  }
  
  export function ListarTipoPessoaResponse(listaTipoPessoa) {
    return {
      type: 'LISTAR_TIPO_PESSOA_RESPONSE',
      listaTipoPessoa
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }