import { useEffect, useState } from "react";
import { Col, Alert, Button, Row, Form, DropdownButton } from "react-bootstrap";
import {
    ExcluirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest,
    LimparArvoreSubitem, ExcluirListaSubitensAutorizacaoMedicaoRequest
} from '../../../store/modules/empenhoAutorizacaoFornecimentoSubitemCustoMed/actions';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { BiTransfer, BiTransferAlt } from "react-icons/bi";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import ModalSubitemEmpenho from "../../../components/ModalSubitemEmpenho/ModalSubitemEmpenho";
import moment from 'moment';
import IntlCurrencyInput from "react-intl-currency-input";
import Select from 'react-select';
import ModalTransferenciaAfEmpenho from "../../../components/ModalTransferenciaAfEmpenho/modalTransferenciaAfEmpenho";
import ModalTransferenciaAfEmpenhoParcialDefinitiva from "../../../components/ModalTransferenciaAfEmpenhoParcialDefinitiva/modalTransferenciaAfEmpenhoParcialDefinitiva";
import 'moment/locale/pt-br';

export default function Subitem() {

    const { id } = useParams();
    const dispatch = useDispatch();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const empenhoCompleto = useSelector(state => state.empenho.empenhoCompleto);
    const listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed
        = useSelector(state => state.empenhoAutorizacaoFornecimentoSubitemCustoMed.listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed);
    const carregando = useSelector(state => state.empenhoAutorizacaoFornecimentoSubitemCustoMed.carregando);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);
    const listaEmpenhoStatus = useSelector(state => state.empenhoStatus.listaEmpenhoStatus);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const contratoVersao = useSelector(state => state.contratoVersao.contratoVersaoCompleto);
    const listaUnidadeMedida = useSelector(state => state.unidadeMedida.listaUnidadeMedida);

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showDefinitivaParcial, setShowDefinitivaParcial] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleCloseDefinitivaParcial = () => setShowDefinitivaParcial(false);
    const handleShowDefinitivaParcial = () => setShowDefinitivaParcial(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);

    const [carregaPage, setCarregaPage] = useState(false);
    const [numeroAutorizacao, setNumeroAutorizacao] = useState(true);
    const [numeroOficio, setNumeroOficio] = useState(false);
    const [numeroOrcamento, setNumeroOrcamento] = useState(false);
    const [valorObra, setValorObra] = useState(false);
    const [codDescSubitem, setCodDescSubitem] = useState(true);
    const [medicao, setMedicao] = useState(false);
    const [descricaoMedicao, setDescricaoMedicao] = useState(true);
    const [quantidade, setQuantidade] = useState(true);
    const [saldoQuantidadeAutorizacao, setSaldoQuantidadeAutorizacao] = useState(true);
    const [saldoQuantidadeExc, setSaldoQuantidadeExc] = useState(false);
    const [valorUnitario, setValorUnitario] = useState(false);
    const [quantidadeContrato, setQuantidadeContrato] = useState(false);
    const [valorTotal, setValorTotal] = useState(true);
    const [usuarioCheck, setUsuarioCheck] = useState(false);
    const [dataInclusao, setDataInclusao] = useState(false);
    const [unidadeDeMedida, setUnidadeDeMedida] = useState(false);
    const [parcial, setParcial] = useState(true);
    const [valorTotalMedicao, setValorTotalMedicao] = useState(true);
    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState([]);
    const [valorTotalDefinitivo, setValorTotalDefinitivo] = useState(0);
    const [idAutorizacao, setIdAutorizacao] = useState(0);

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    const options = contratoVersao !== undefined && contratoVersao !== null && contratoVersao ?
        contratoVersao.subitemCusto.map(subitemUnidade => ({
            value: subitemUnidade.id, label: subitemUnidade.descricaoCodigoInterno + " - " + subitemUnidade.descricao
        }))
        :
        [];

    useEffect(() => {

        moment.locale('pt-br');

        //Permissão inserir e editar
        if (token.role.filter(r => r == "EmpenhoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "EmpenhoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "EmpenhoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        ConstroiHierarquia();

    }, [listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed])

    useEffect(() => () =>
        dispatch(LimparArvoreSubitem())
        , []);

    function ConstroiHierarquia() {

        let hierarquiaCompleta = [];
        let valorTotal = 0.0;

        if (listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined) {
            listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed.map((autorizacaoFornecimento) => {
                if (autorizacaoFornecimento.listaMedicaoFinalizada !== undefined && autorizacaoFornecimento.listaMedicaoFinalizada.length > 0 &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0 &&
                    autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto[0].statusParcial == false) {
                    hierarquiaCompleta.push({
                        id: autorizacaoFornecimento.id,
                        numeroAutorizacao: autorizacaoFornecimento.numeroAf,
                        numeroMedicao: null,
                        descricaoAutorizacao: autorizacaoFornecimento.observacaoAutorizacaoFornecimento,
                        entregaParcial: autorizacaoFornecimento.listaMedicaoFinalizada !== undefined && autorizacaoFornecimento.listaMedicaoFinalizada.length > 0 &&
                            autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                            autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0 ?
                            autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto[0].statusParcial
                            : null,
                        quantidade: null,
                        descricaoMedicao: null,
                        valorUnitario: null,
                        total: null,
                        codigoInterno: null,
                        valorTotal: null,
                        descricao: null,
                        marcadorSubitem: false,
                        marcadorMedicao: false,
                        select: false,
                        valorObra: autorizacaoFornecimento.valorObra,
                        marcadorMedicao: false,
                        marcadorSubitem: false,
                        numeroOficio: autorizacaoFornecimento.numeroOficio,
                        numeroOrcamento: autorizacaoFornecimento.numeroOrcamento,
                        quantidadeContrato: null,
                        quantidadeContratoExcedente: null,
                        valorTotalMedicao: null
                    });
                    autorizacaoFornecimento.listaMedicaoFinalizada.map((medicao) => {
                        var x = ('M').concat(medicao.id)
                        hierarquiaCompleta.push({
                            id: x,
                            numeroAutorizacao: null,
                            numeroMedicao: medicao.id,
                            parentId: medicao.idAutorizacaoFornecimento,
                            descricaoMedicao: medicao.descricao,
                            descricaoAutorizacao: null,
                            valorUnitario: null,
                            codigoInterno: null,
                            total: null,
                            valorTotal: null,
                            quantidade: null,
                            descricao: null,
                            marcadorSubitem: false,
                            marcadorMedicao: true,
                            select: false,
                            valorObra: null,
                            marcadorMedicao: true,
                            marcadorSubitem: false,
                            numeroOficio: null,
                            numeroOrcamento: null,
                            quantidadeContrato: null,
                            quantidadeContratoExcedente: null,
                            valorTotalMedicao: medicao.valorTotalMedicao
                        });
                        medicao.listaEmpenhoAutorizacaoSubitemCusto.map((subitemEmpenho) => {
                            var x = ('M').concat(subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.idMedicaoAutorizacaoFornecimento)
                            var y = ('S').concat(subitemEmpenho.id)
                            hierarquiaCompleta.push({
                                parentId: x,
                                id: y,
                                numeroAutorizacao: null,
                                entregaParcial: subitemEmpenho.statusParcial,
                                numeroMedicao: null,
                                marcadorMedicao: false,
                                marcadorSubitem: true,
                                valorTotal: subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario * subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.quantidadeFornecedor
                                    : '',
                                quantidade:
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null ?
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.quantidadeFornecedor : '',
                                codigoInterno:
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao
                                            .autorizacaoFornecimentoSubitemCusto.subitemCusto.descricaoCodigoInterno
                                        : '',
                                descricaoSubitem:
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricaoCodigoInterno + " - " +
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricao
                                        : '',
                                subitemMedicao: subitemEmpenho.idAutorizacaoFornecimentoSubitemCustoMedicao,
                                usuario:
                                    subitemEmpenho.usuario != null ?
                                        subitemEmpenho.usuario.nome : '',
                                dataInclusao: moment(subitemEmpenho.dataInclusao).format('L LT'),
                                saldoContratoExcedente: subitemEmpenho.saldo + "(" + subitemEmpenho.saldoContratoExcedente + ")",
                                valorUnitario: subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario : '',
                                descricaoMedicao: null,
                                saldoAutorizacao: subitemEmpenho.saldoAutorizacao,
                                unidadeMedida:
                                    subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                        subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null &&
                                        listaUnidadeMedida != undefined && listaUnidadeMedida.filter(x => x.id ==
                                            subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.idUnidadeMedida).length > 0 ?
                                        listaUnidadeMedida.filter(x => x.id ==
                                            subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.idUnidadeMedida)[0].descricao : '',
                                descricaoAutorizacao: null,
                                valorObra: null,
                                numeroOficio: null,
                                numeroOrcamento: null,
                                quantidadeContrato: subitemEmpenho.quantidadeContrato,
                                quantidadeContratoExcedente: subitemEmpenho.quantidadeContratoExcedente,
                                valorTotalMedicao: null
                            });
                            valorTotal += subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao != null &&
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto != null &&
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto != null ?
                                subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario * subitemEmpenho.autorizacaoFornecimentoSubitemCustoMedicao.quantidadeFornecedor : 0
                        })
                    })
                }
            })

            setListaHierarquiaCompleta(hierarquiaCompleta);
            setValorTotalDefinitivo(valorTotal);
            setCarregaPage(true);
        }
    }

    function ExcluirSubitem(idSubitem) {

        let subitensAf = [];
        let subitensMedicao = [];

        if (listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != undefined) {
            listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed.map((af) => {
                if (af.id == idSubitem.id) {
                    af.listaMedicaoFinalizada.map((medicao) => {
                        medicao.listaEmpenhoAutorizacaoSubitemCusto.map((subitemEmpenho) => {
                            subitensAf.push(
                                subitemEmpenho.id
                            );
                        })
                    })
                }
                else if (af.listaMedicaoFinalizada !== undefined && af.listaMedicaoFinalizada.length > 0 &&
                    af.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                    af.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0) {
                    af.listaMedicaoFinalizada.map((medicao) => {
                        medicao.listaEmpenhoAutorizacaoSubitemCusto.map((subitemEmpenho) => {
                            var x = ('M').concat(medicao.id)
                            if (x == idSubitem.id) {
                                subitensMedicao.push(
                                    subitemEmpenho.id
                                );
                            }
                        })
                    })
                }
            })
        }

        if (subitensAf !== [] && subitensAf.length > 0 && id != undefined && empenhoCompleto != null) {
            dispatch(ExcluirListaSubitensAutorizacaoMedicaoRequest(subitensAf, id, empenhoCompleto.idContrato, empenhoCompleto.idFornecedor));
        }
        else if (subitensMedicao !== [] && subitensMedicao.length > 0 && id != undefined && empenhoCompleto != null) {
            dispatch(ExcluirListaSubitensAutorizacaoMedicaoRequest(subitensMedicao, id, empenhoCompleto.idContrato, empenhoCompleto.idFornecedor));
        }


    }

    function ExcluirIdSubitem(idSubitem) {

        var listaId = idSubitem.split('S', idSubitem.length);
        if (id != undefined && empenhoCompleto != null) {
            dispatch(ExcluirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(parseInt(listaId[1]),
                id, empenhoCompleto.idContrato, empenhoCompleto.idFornecedor));
        }

    }

    function InserirSubitem() {

        // Verifica se o usuário tem permissão de inserir subitem no empenho
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaSubitemEmpenho === true).length === 0) {

            toast.error('Você não tem permissão de cadastrar itens.');
            return;
        }

        handleShow();
    }


    function TransferirAf(linha) {
        setIdAutorizacao(linha.id);
        handleShow2();
    }


    function TransferirDefinitivaOuParcial(linha) {
        setIdAutorizacao(linha.id);
        handleShowDefinitivaParcial();
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando&&
                        listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed != null
                        ?
                            <div>
                                <Row style={{ marginLeft: "1em" }}>
                                    <Col>
                                        <DropdownButton variant="success" title="Colunas">
                                            <Form>
                                                <div className="tamanhoTela">
                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroAutorizacao} onChange={() => setNumeroAutorizacao(!numeroAutorizacao)} />
                                                                <Form.Check.Label> Número Autorizacao</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroOficio} onChange={() => setNumeroOficio(!numeroOficio)} />
                                                                <Form.Check.Label> Número Oficio</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroOrcamento} onChange={() => setNumeroOrcamento(!numeroOrcamento)} />
                                                                <Form.Check.Label> Número Orçamento</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorObra} onChange={() => setValorObra(!valorObra)} />
                                                                <Form.Check.Label> Valor Obra</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={codDescSubitem} onChange={() => setCodDescSubitem(!codDescSubitem)} />
                                                                <Form.Check.Label> Código/Descrição Item</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={medicao} onChange={() => setMedicao(!medicao)} />
                                                                <Form.Check.Label> Medição</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={usuarioCheck} onChange={() => setUsuarioCheck(!usuarioCheck)} />
                                                                <Form.Check.Label> Usuario</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidade} onChange={() => setQuantidade(!quantidade)} />
                                                                <Form.Check.Label> Quantidade</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorTotal} onChange={() => setValorTotal(!valorTotal)} />
                                                                <Form.Check.Label> Valor Total</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={
                                                                    listaHierarquiaCompleta != '' && listaHierarquiaCompleta.length > 0 &&
                                                                        listaHierarquiaCompleta[2].saldoAutorizacao != undefined
                                                                        && listaHierarquiaCompleta[2].saldoAutorizacao == 0 &&
                                                                        listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                                        listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                                            listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0 ?
                                                                        (() => setSaldoQuantidadeAutorizacao(!saldoQuantidadeAutorizacao),
                                                                            !saldoQuantidadeAutorizacao)
                                                                        :
                                                                        saldoQuantidadeAutorizacao
                                                                } onChange={() => setSaldoQuantidadeAutorizacao(!saldoQuantidadeAutorizacao)} />
                                                                <Form.Check.Label> Saldo Quantidade Autorização</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={parcial} onChange={() => setParcial(!parcial)} />
                                                                <Form.Check.Label> Tipo de Entrega</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorUnitario} onChange={() => setValorUnitario(!valorUnitario)} />
                                                                <Form.Check.Label> Valor Unitário</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={dataInclusao} onChange={() => setDataInclusao(!dataInclusao)} />
                                                                <Form.Check.Label> Data Inclusão</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={unidadeDeMedida} onChange={() => setUnidadeDeMedida(!unidadeDeMedida)} />
                                                                <Form.Check.Label> Unidade De Medida</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={descricaoMedicao} onChange={() => setDescricaoMedicao(!descricaoMedicao)} />
                                                                <Form.Check.Label> Descrição Medição</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={saldoQuantidadeExc} onChange={() => setSaldoQuantidadeExc(!saldoQuantidadeExc)} />
                                                                <Form.Check.Label> Saldo Quantidade Contrato (excedente)</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeContrato} onChange={() => setQuantidadeContrato(!quantidadeContrato)} />
                                                                <Form.Check.Label> Quantidade Total Contrato (Excedente)</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorTotalMedicao} onChange={() => setValorTotalMedicao(!valorTotalMedicao)} />
                                                                <Form.Check.Label> Valor Total Medição </Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </DropdownButton>
                                    </Col>
                                </Row><br />
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={
                                        <Row>
                                            <Col>
                                                {inserir == true ?
                                                    <Button
                                                        onClick={InserirSubitem}
                                                        style={{ width: '100%' }}
                                                        variant="success"
                                                        disabled={
                                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                                listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                                    listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0 ?
                                                                true :
                                                                listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                                    ?
                                                                    true :
                                                                    listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                                                    [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                                                    [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                                        listaEmpenhoStatus
                                                                        [listaEmpenhoStatus.length - 1].statusEmpenho.somenteLeitura == true
                                                                        ?
                                                                        true
                                                                        :
                                                                        (listaContratoUsuario != undefined && contratoCompleto != null && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaSubitemEmpenho === true).length === 0) ?
                                                                            true : false
                                                        }
                                                    >Novo Item
                                                    </Button>
                                                    :
                                                    ''}
                                            </Col>
                                        </Row>
                                    }
                                    columns={[
                                        {
                                            title: 'Número Autorização',
                                            field: 'numeroAutorizacao',
                                            align: 'left',
                                            hidden: numeroAutorizacao ? false : true,
                                        },
                                        {
                                            title: 'Entrega',
                                            field: 'entregaParcial',
                                            align: 'left',
                                            render: rowData =>
                                                rowData.entregaParcial !== null && rowData.entregaParcial === true ?
                                                    'Parcial'
                                                    :
                                                    rowData.entregaParcial !== null && rowData.entregaParcial === false ?
                                                        'Definitiva'
                                                        :
                                                        null,
                                            hidden: parcial ? false : true,
                                        },
                                        {
                                            title: 'Número Ofício',
                                            field: 'numeroOficio',
                                            align: 'left',
                                            hidden: numeroOficio ? false : true,
                                        },
                                        {
                                            title: 'Número Orçamento',
                                            field: 'numeroOrcamento',
                                            align: 'left',
                                            hidden: numeroOrcamento ? false : true,
                                        },
                                        {
                                            title: 'Valor da Obra',
                                            field: 'valorObra',
                                            align: 'left',
                                            hidden: valorObra ? false : true,
                                            render: rowData =>
                                                rowData.valorObra === null ?
                                                    null
                                                    :
                                                    rowData.valorObra.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        },
                                        {
                                            title: 'Número Medição',
                                            field: 'numeroMedicao',
                                            align: 'left',
                                            hidden: medicao ? false : true,
                                        },
                                        {
                                            title: 'Descrição Medição',
                                            field: 'descricaoMedicao',
                                            align: 'left',
                                            hidden: descricaoMedicao ? false : true,
                                        },
                                        {

                                            title: 'Código/Descrição Item',
                                            field: 'descricaoSubitem',
                                            filterComponent: (props) => {
                                                return (
                                                    <Select
                                                        placeholder="Selecione..."
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={options}
                                                        onChange={(e) => {
                                                            props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.label : false)
                                                        }}
                                                    />
                                                )
                                            },
                                            hidden: codDescSubitem ? false : true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Quantidade',
                                            field: 'quantidade',
                                            hidden: quantidade ? false : true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Unidade de Medida',
                                            field: 'unidadeMedida',
                                            hidden: unidadeDeMedida ? false : true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Saldo Quantidade Autorização', field: 'saldoAutorizacao', editable: 'never',
                                            hidden: listaHierarquiaCompleta != '' && listaHierarquiaCompleta.length > 0 &&
                                                listaHierarquiaCompleta[2].saldoAutorizacao != undefined
                                                && listaHierarquiaCompleta[2].saldoAutorizacao == 0 &&
                                                listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0 ?
                                                (() => setSaldoQuantidadeAutorizacao(!saldoQuantidadeAutorizacao),
                                                    saldoQuantidadeAutorizacao)
                                                :
                                                !saldoQuantidadeAutorizacao
                                        },
                                        {
                                            title: 'Saldo Quantidade Contrato (excedente)',
                                            field: 'saldoContratoExcedente',
                                            hidden: saldoQuantidadeExc ? false : true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Valor Unitário',
                                            field: 'valorUnitario',
                                            hidden: valorUnitario ? false : true,
                                            editable: 'never',
                                            grouping: false,
                                            render: rowData =>
                                                rowData.valorUnitario === null ?
                                                    null
                                                    :
                                                    rowData.valorUnitario.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        },
                                        {
                                            title: 'Valor Total',
                                            field: 'valorTotal',
                                            hidden: valorTotal ? false : true,
                                            editable: 'never',
                                            grouping: false,
                                            render: rowData =>
                                                rowData.valorTotal === null ?
                                                    null
                                                    :
                                                    rowData.valorTotal.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        },
                                        {
                                            title: 'Usuario',
                                            field: 'usuario',
                                            hidden: usuarioCheck ? false : true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Data Inclusao',
                                            field: 'dataInclusao',
                                            hidden: dataInclusao ? false : true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Quantidade Total Contrato (excedente)',
                                            field: 'quantidadeContrato',
                                            hidden: quantidadeContrato ? false : true,
                                            editable: 'never',
                                            render: rowData =>
                                                rowData.quantidadeContrato != null ?
                                                    rowData.quantidadeContrato + ' (' + rowData.quantidadeContratoExcedente + ')' : ''
                                        },
                                        {
                                            title: 'Valor Total Medição',
                                            field: 'valorTotalMedicao',
                                            hidden: valorTotalMedicao ? false : true,
                                            editable: 'never',
                                            render: rowData =>
                                                rowData.valorTotalMedicao === null ?
                                                    null
                                                    :
                                                    rowData.valorTotalMedicao.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        }
                                    ]}
                                    data={listaHierarquiaCompleta}
                                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                    options={{
                                        minBodyHeight: 500,
                                        actionsCellStyle: {
                                            width: '5%',
                                        },
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold",
                                            lineBreak: "auto",
                                            fontSize: "11px",
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle:
                                            rowData => ({
                                                backgroundColor:
                                                    rowData.quantidade > rowData.saldoContratoExcedente && listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                                    [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                                    [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                        listaEmpenhoStatus
                                                        [listaEmpenhoStatus.length - 1].statusEmpenho.finalizaEmpenho != true || rowData.quantidade - rowData.saldoAutorizacao > 0 &&
                                                        listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                                        [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                                        [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                        listaEmpenhoStatus
                                                        [listaEmpenhoStatus.length - 1].statusEmpenho.finalizaEmpenho != true
                                                        ? '#F7D1D1' :
                                                        rowData.marcadorSubitem ?
                                                            '#dcdcdc'
                                                            : rowData.marcadorMedicao ?
                                                                '#BCB8B8'
                                                                :
                                                                '#9D9999'
                                            })
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: 'error',
                                            tooltip: <span style={{ fontSize: '1.5em' }}>
                                                {'Esse Item esta passando ' + (rowData.quantidade - rowData.saldoContratoExcedente) + ' unidades da quantidade excedente do contrato.'}
                                            </span>,
                                            hidden: rowData.quantidade - rowData.saldoContratoExcedente <= 0 || (listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                            [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                            [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                listaEmpenhoStatus
                                                [listaEmpenhoStatus.length - 1].statusEmpenho.finalizaEmpenho == true) || rowData.marcadorSubitem == false
                                        }),
                                        rowData => ({
                                            icon: 'error',
                                            tooltip: <span style={{ fontSize: '1.5em' }}>
                                                {rowData.quantidade - rowData.saldoAutorizacao > 0 ?
                                                    'Quantidade saldo autorização excedida: ' + (rowData.quantidade - rowData.saldoAutorizacao)
                                                    : null}
                                            </span>,
                                            hidden:
                                                !(rowData.quantidade - rowData.saldoAutorizacao > 0) || (listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                                [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                                [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                    listaEmpenhoStatus
                                                    [listaEmpenhoStatus.length - 1].statusEmpenho.finalizaEmpenho == true) || rowData.marcadorSubitem == false
                                        }),
                                        rowData => ({
                                            icon: () => <BiTransferAlt color={"#ff0000"} />,
                                            tooltip: <span style={{ fontSize: '1.5em' }}>{'Transferir para parcial'}</span>,
                                            onClick: (event, rowData) => { TransferirDefinitivaOuParcial(rowData) },
                                            hidden: !(rowData.numeroAutorizacao != null)
                                        }),
                                        rowData => ({
                                            icon: () => <BiTransfer />,
                                            tooltip: <span style={{ fontSize: '1.5em' }}>{'Transferir para outro empenho'}</span>,
                                            onClick: (event, rowData) => { TransferirAf(rowData) },
                                            hidden: !(rowData.numeroAutorizacao != null)
                                        })
                                    ]}
                                    editable={{
                                        //isDeleteHidden: rowData => rowData.marcadorSubitem === false,
                                        onRowDelete:
                                            listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                            [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                            [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                listaEmpenhoStatus
                                                [listaEmpenhoStatus.length - 1].statusEmpenho.somenteLeitura == true
                                                ?
                                                null :
                                                oldData => new Promise((resolve, reject) => {
                                                    excluir == true ?
                                                        setTimeout(() => {
                                                            oldData.marcadorSubitem == true ?
                                                                ExcluirIdSubitem(oldData.id)
                                                                :
                                                                ExcluirSubitem(oldData);
                                                            resolve()
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                    }}
                                    style={{
                                        fontSize: "12px"
                                    }}
                                    localization={{
                                        header: {
                                            actions: <span className="classHeaderTabela">Ações</span>
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                saveTooltip: '',
                                                cancelTooltip: '',
                                            },
                                            deleteTooltip: '',
                                            editTooltip: '',
                                            addTooltip: '',
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                                <Col md={12}>
                                    <br />
                                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                                        <Form.Label>Total Definitivo</Form.Label>
                                        <Form.Control
                                            config={currencyConfig}
                                            as={IntlCurrencyInput}
                                            currency="BRL"
                                            type="text"
                                            value={valorTotalDefinitivo}
                                            disabled
                                        />
                                    </Form.Group>
                                    <br />
                                    {
                                        id == undefined ?
                                            ''
                                            :
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0
                                                ?
                                                <Col md={12}>
                                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                                        O Empenho está Cancelado!
                                                    </Alert>
                                                </Col>
                                                :
                                                listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                    ?
                                                    <Col md={12}>
                                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                                            O Empenho está Finalizado!
                                                        </Alert>
                                                    </Col>
                                                    :
                                                    listaEmpenhoStatus != undefined && listaEmpenhoStatus
                                                    [listaEmpenhoStatus.length - 1] != null && listaEmpenhoStatus
                                                    [listaEmpenhoStatus.length - 1].statusEmpenho != null &&
                                                        listaEmpenhoStatus
                                                        [listaEmpenhoStatus.length - 1].statusEmpenho.somenteLeitura == true
                                                        ?
                                                        <Col md={12}>
                                                            <Alert variant={`success`} style={{ width: '100%' }}>
                                                                O status atual só permite leitura na aba de Item!
                                                            </Alert>
                                                        </Col>
                                                        : ''
                                    }
                                </Col>
                            </div>
                            :
                            <Loading />
                    }
                </Col>
            </div>
            <ModalSubitemEmpenho
                show={show}
                onHide={handleClose}
                parcial={false}
                idEmpenho={id !== null ? id : null}
                idUsuario={usuario != null ? usuario.id : null}
            />
            <ModalTransferenciaAfEmpenho
                show={show2}
                onHide={handleClose2}
                idAutorizacao={idAutorizacao !== null ? idAutorizacao : null}
                idEmpenhoOrigem={empenhoCompleto !== null ? empenhoCompleto.id : null}
            />
            <ModalTransferenciaAfEmpenhoParcialDefinitiva
                show={showDefinitivaParcial}
                onHide={handleCloseDefinitivaParcial}
                idAutorizacao={idAutorizacao !== null ? idAutorizacao : null}
                idEmpenhoOrigem={empenhoCompleto !== null ? empenhoCompleto.id : null}
                definitivaParcial={false}
            />
        </>
    )
}