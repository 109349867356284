import produce from 'immer';

const initialState = {
  listaGrupoAcesso: undefined,
  carregando: true
};

export default function grupoAcesso(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_GRUPO_ACESSO_RESPONSE':
      return produce(state, draft => {
        draft.listaGrupoAcesso = action.listaGrupoAcesso;
      });
    
      case 'CARREGANDO':
        return produce(state, draft => {
          draft.carregando = action.status
        });

    default:
      return state;
  }
}