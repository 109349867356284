export function ListarUsuarioFornecedorRequest() {
  return {
    type: 'LISTAR_USUARIO_FORNECEDOR_REQUEST'
  }
}

export function ListarUsuarioFornecedorResponse(listaUsuarioFornecedor) {
  return {
    type: 'LISTAR_USUARIO_FORNECEDOR_RESPONSE',
    listaUsuarioFornecedor
  }
}

export function ListarCompletoUsuarioFornecedorRequest(idUsuario) {
  return {
    type: 'LISTAR_COMPLETO_USUARIO_FORNECEDOR_REQUEST',
    idUsuario
  }
}

export function InserirUsuarioFornecedorRequest(data) {
  return {
    type: 'INSERIR_USUARIO_FORNECEDOR_REQUEST',
    data
  }
}

export function AlterarUsuarioFornecedorRequest(data) {
  return {
    type: 'ALTERAR_USUARIO_FORNECEDOR_REQUEST',
    data
  }
}

export function ExcluirUsuarioFornecedorRequest(id) {
  return {
    type: 'EXCLUIR_USUARIO_FORNECEDOR_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}