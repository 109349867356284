import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarCompletoEmpenhoStatusRequest } from "../../../store/modules/empenhoStatus/actions";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function HistoricoStatus() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const listaCompletoEmpenhoStatus = useSelector(state => state.empenhoStatus.listaCompletoEmpenhoStatus);
    const carregando = useSelector(state => state.empenhoStatus.carregando);

    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {

        moment.locale('pt-br');

        if (id != undefined) {
            //dispatch(ListarCompletoEmpenhoStatusRequest(id));
        }

    }, [id])

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: 'Status', field: 'status', cellStyle: {
                                            width: '25%'
                                        },
                                        render: rowData =>
                                            rowData.statusCancela == true ?
                                                <div className="divStatusDanger">{rowData.status}</div>
                                                :
                                                rowData.statusAprovaDuasPartes ?
                                                    rowData.statusAprovado ?
                                                        <div className="divStatusGreen">{rowData.status} (Aprovada)</div>
                                                        :
                                                        rowData.statusReprovado ?
                                                            <div className="divStatusDanger">{rowData.status} (Reprovada)</div>
                                                            :
                                                            ''
                                                    :
                                                    <div className="divStatusGreen">{rowData.status}</div>
                                    },
                                    {
                                        title: 'Observação', field: 'observacao', cellStyle: {
                                            width: '25%'
                                        }
                                    },
                                    {
                                        title: 'Data', field: 'dataStatus', cellStyle: {
                                            width: '25%'
                                        }
                                    },
                                    {
                                        title: 'Nome usuário', field: 'usuario', cellStyle: {
                                            width: '25%'
                                        }
                                    }
                                ]}
                                data={
                                    listaCompletoEmpenhoStatus != undefined ?
                                        listaCompletoEmpenhoStatus.map(empenhoStatus => {
                                            if (empenhoStatus.statusEmpenho != null && empenhoStatus.statusEmpenho.statusAprovaDuasPartes &&
                                                ((empenhoStatus.statusAprovadoCliente == false || empenhoStatus.statusAprovadoFornecedor == false)
                                                    && empenhoStatus.statusReprovado == false)) {
                                                return null;
                                            } else if (empenhoStatus.statusEmpenho != null && empenhoStatus.statusEmpenho.statusFaturamento &&
                                                empenhoStatus.statusFaturamentoAprovado == false) {
                                                return null;
                                            } else {
                                                return {
                                                    status:
                                                        empenhoStatus.statusEmpenho != null ?
                                                            empenhoStatus.statusEmpenho.descricao : '',
                                                    dataStatus: moment(empenhoStatus.dataStatus).format('L LT'),
                                                    usuario: empenhoStatus.usuario != null ? empenhoStatus.usuario.nome : '',
                                                    observacao: empenhoStatus.observacao,
                                                    statusCancela: empenhoStatus.statusEmpenho.cancelaEmpenho,
                                                    statusAprovaDuasPartes: empenhoStatus.statusEmpenho.statusAprovaDuasPartes,
                                                    statusAprovado: empenhoStatus.statusAprovadoCliente && empenhoStatus.statusAprovadoFornecedor ? true : false,
                                                    statusReprovado: empenhoStatus.statusReprovado
                                                }
                                            }
                                        }).filter(Boolean)
                                        :
                                        []
                                }
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                                }}
                                localization={{
                                    header: {
                                        actions: 'Ações'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                        },
                                        deleteTooltip: ''
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                            :
                            <Loading />
                    }
                </Col>
            </div>
        </>
    )
}