import { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InserirGrupoCustoRequest } from "../../../store/modules/grupoCusto/actions";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

export default function GrupoCusto() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);

    const [descricaoCodigoInterno, setDescricaoCodigoInterno] = useState('');
    const [descricao, setDescricao] = useState('');
    const [validated, setValidated] = useState(false);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "ContratoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    function CadastrarGrupoCusto(e) {

        e.preventDefault();

        if (descricao == null || descricao == undefined
            || descricao.trim() == '') {
            toast.error(`Descrição inválida`);
            return;
        }

        if (descricaoCodigoInterno == null || descricaoCodigoInterno == undefined
            || descricaoCodigoInterno.trim() == '') {
            toast.error(`Número Interno inválido`);
            return;
        }

        var data = {
            'Id': parseInt(0),
            'Descricao': descricao.trim(),
            'DescricaoCodigoInterno': descricaoCodigoInterno.trim(),
            'IdContratoVersao': contratoCompleto.contratoVersaoApiModel.id
        }

        dispatch(InserirGrupoCustoRequest(data, id));
    }

    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {

            event.preventDefault();
            setValidated(true);
        } else {

            CadastrarGrupoCusto(event);
            setDescricao("");
            setDescricaoCodigoInterno("");
            setValidated(false);
        }
    };

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="divBody">
                    <Col md={12}>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formDescricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" value={descricao} onChange={e => setDescricao(e.target.value)}
                                    maxLength="100"
                                    isInvalid={validated && descricao.trim() === "" ? true : false}
                                    isValid={validated && descricao.trim() !== "" ? true : false}
                                    required />
                                <Form.Control.Feedback type="invalid">O campo descrição é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formDescricaoCodigoInterno">
                                <Form.Label>Número Interno</Form.Label>
                                <Form.Control type="text" value={descricaoCodigoInterno} onChange={e => setDescricaoCodigoInterno(e.target.value)}
                                    maxLength="70"
                                    isInvalid={validated && descricaoCodigoInterno.trim() === "" ? true : false}
                                    isValid={validated && descricaoCodigoInterno.trim() !== "" ? true : false}
                                    required />
                                <Form.Control.Feedback type="invalid">O campo número interno é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        {
                            inserir == true ?
                                <Row>
                                    <Col md={2}>
                                        <Button variant="success" type="submit">Cadastrar Grupo Custo</Button>
                                    </Col>
                                </Row>
                                :
                                ''

                        }


                    </Col>
                </div>
            </Form>
        </>
    );
}