export function ListarNotificacaoRequest(idUsuario) {
  return {
    type: 'LISTAR_NOTIFICACAO_REQUEST',
    idUsuario
  }
}

export function ListarNotificacaoResponse(listaNotificacao) {
  return {
    type: 'LISTAR_NOTIFICACAO_RESPONSE',
    listaNotificacao
  }
}

export function InserirNotificacaoRequest(data) {
  return {
    type: 'INSERIR_NOTIFICACAO_REQUEST',
    data
  }
}

export function AlterarNotificacaoRequest(data) {
  return {
    type: 'ALTERAR_NOTIFICACAO_REQUEST',
    data
  }
}

export function ExcluirMedicaoRequest(id) {
  return {
    type: 'EXCLUIR_MEDICAO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}