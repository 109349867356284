import { Button, Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListarCompletoSolicitacaoFornecimentoRequest } from '../../store/modules/solicitacaoFornecimento/actions';
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import { InserirItensContratoVersaoRequest } from "../../store/modules/contratoVersao/actions";
import moment from 'moment';
import MaterialTable from "material-table";
import { ImportarContratoExcelRequest } from "../../store/modules/contrato/actions";


export default function ModalSolicitacaoAjusteImportacao({ idContrato, show, onHide, dados, tipo }) {

  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState(null);
  const listaSolicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.listaSolicitacaoFornecimento);
  const carregando = useSelector(state => state.solicitacaoFornecimento.carregando);
  const [idsCancelados, setIdsCancelados] = useState([]);
  const [idsReajustados, setIdsReajustados] = useState([]);

  useEffect(() => {
    moment.locale('pt-br');
  }, [dispatch])

  useEffect(() => {

    dispatch(ListarCompletoSolicitacaoFornecimentoRequest({
      IdSolicitacaoFornecimento: null,
      IdContrato: idContrato,
      Servico: true
    }));

  }, [idContrato])

  function Salvar() {

    if (tipo == "Importacao") {

      var data = {
        'ListaObjeto': dados,
        'ListaIdSolicitacaoFornecimento': idsReajustados,
        'ListaIdSolicitacaoFornecimentoCancelar': idsCancelados
      }

      dispatch(ImportarContratoExcelRequest(data, idContrato));

    } else if (tipo == "Ajustes" && dados != undefined) {

      var data = {
        'Id': dados.Id,
        'IdContrato': dados.IdContrato,
        'IdTipoContratoVersao': dados.IdTipoContratoVersao,
        'DataVersao': dados.DataVersao,
        'DataInicioContrato': dados.DataInicioContrato,
        'DataFimContrato': dados.DataFimContrato,
        'ValorIndiceReajuste': dados.ValorIndiceReajuste,
        'ValorIndiceMulta': dados.ValorIndiceMulta,
        'GruposCusto': dados.GruposCusto,
        'AdicionarSubItemCusto': dados.AdicionarSubItemCusto,
        'AlterarSub': dados.AlterarSub,
        'IdUsuario': dados.IdUsuario,
        'ValorIndiceQuantidadeItemExcedente': dados.ValorIndiceQuantidadeItemExcedente,
        'ListaIdSolicitacaoFornecimento': idsReajustados,
        'ListaIdSolicitacaoFornecimentoCancelar': idsCancelados
      }

      dispatch(InserirItensContratoVersaoRequest(data, idContrato));
    }

    onHide();

  }

  function cancelar(rowData) {

    var id = idsCancelados.filter(i => i.id == rowData.id);
    if (id.length == 0) {
      var lista = idsCancelados;
      lista.push(rowData.id)
      setIdsCancelados(lista);
    }
  }

  function reajustar(rowData) {

    var id = idsReajustados.filter(i => i.id == rowData.id);
    if (id.length == 0) {
      var lista = idsReajustados;
      lista.push(rowData.id)
      setIdsReajustados(lista);
    }
  }

  return (
    carregando && listaSolicitacaoFornecimento != undefined &&
      listaSolicitacaoFornecimento.filter(s => s.statusAtual != "Solicitação Finalizada" && s.statusAtual != "").length > 0 ?
      <Modal
        size="xl"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Solicitações a partir deste Contrato
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="labelModalSolicitacao">Atenção, após mudar a versão do contrato você pode selecionar os novos status das solicitações vínculadas (Reajustar, Cancelar ou Manter)</label>
          <MaterialTable
            components={{
              Pagination: PatchedPagination,
            }}
            title=''
            columns={[
              {
                title: "Reajustar", field: 'reajustar', cellStyle: {
                  width: '5%'
                },
                searchable: false,
                filtering: false,
                editComponent: (props) => (
                  <input
                    type="checkbox"
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                ),
                render: rowData => (<input type="checkbox" onChange={() => { reajustar(rowData) }} />)
              },
              {
                title: "Cancelar", field: 'cancelar', cellStyle: {
                  width: '5%'
                },
                searchable: false,
                filtering: false,
                editComponent: (props) => (
                  <input
                    type="checkbox"
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                ),
                render: rowData => (<input type="checkbox" onChange={() => { cancelar(rowData) }} />)
              },
              {
                title: "Código", field: 'id', defaultSort: 'desc', cellStyle: {
                  width: '5%'
                }
              },
              {
                title: "Descrição", field: 'descricao', sorting: true, cellStyle: {
                  width: '10%'
                }
              },
              {
                title: "Número Processo", field: 'numeroProcesso', sorting: true, cellStyle: {
                  width: '15%'
                }
              },
              {
                title: "Endereço", field: 'endereco', sorting: true, cellStyle: {
                  width: '15%'
                }
              },
              {
                title: "Número Ofício", field: 'numeroOficio', sorting: true, cellStyle: {
                  width: '15%'
                }
              },
              {
                title: "Status", field: 'status', sorting: true, cellStyle: {
                  width: '15%'
                }
              },
              {
                title: "Data Criação", field: 'dataInclusao', sorting: true, cellStyle: {
                  width: '15%'
                }
              }
            ]}
            data={
              listaSolicitacaoFornecimento != undefined ?
                listaSolicitacaoFornecimento.filter(s => s.statusAtual != "Solicitação Finalizada" && s.statusAtual != "")
                  .map((lista) => {
                    return {
                      reajustar: lista.reajustar,
                      cancelar: lista.cancelar,
                      id: lista.id,
                      descricao: lista.descricaoSolicitacao,
                      numeroProcesso: lista.numeroProcesso,
                      endereco: lista.endereco,
                      numeroOficio: lista.numeroOficio,
                      status: lista.statusAtual,
                      dataInclusao: moment(lista.dataInclusao).format('L LT'),
                    }
                  })
                :
                []
            }
            options={{
              minBodyHeight: 500,
              paging: true,
              pageSize: 10, // Início
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 50, 200, 300],
              filterRowStyle: {
                backgroundColor: "#FAFAFA"
              },
              headerStyle: {
                backgroundColor: '#454545',
                color: '#FFF',
                fontWeight: "bold"
              },
              actionsColumnIndex: -1,
              filtering: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              //selection: true
            }}
            /*actions={[
              {
                tooltip: 'Reajustar solicitações selecionadas',
                icon: 'save',
                onClick: (evt, data) => { Salvar(data) }
              }
            ]}*/
            localization={{
              body: {
                emptyDataSourceMessage: 'Nenhum registro para exibir',
                editRow: {
                  deleteText: 'Você tem certeza que deseja excluir essa linha?',
                  saveTooltip: '',
                  cancelTooltip: '',
                },
                deleteTooltip: '',
                editTooltip: '',
                addTooltip: '',
              },
              toolbar: {
                searchTooltip: '',
                searchPlaceholder: 'Pesquisar',
                exportTitle: "",
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                nRowsSelected: '{0} linha(s) selecionadas'
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{count} de {from}-{to}',
                firstTooltip: '',
                previousTooltip: '',
                nextTooltip: '',
                lastTooltip: ''
              },
              grouping: {
                placeholder: 'Arraste a coluna aqui para agrupar',
                groupedBy: 'Agrupado por:'
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={Salvar} style={{ width: '100%' }}>Salvar</Button>
        </Modal.Footer>
      </Modal>
      :
      ''
  );
}