export function ListarRegiaoRequest() {
  return {
    type: 'LISTAR_REGIAO_REQUEST'
  }
}

export function ListarRegiaoResponse(listaRegiao) {
  return {
    type: 'LISTAR_REGIAO_RESPONSE',
    listaRegiao
  }
}

export function InserirRegiaoRequest(data) {
  return {
    type: 'INSERIR_REGIAO_REQUEST',
    data
  }
}

export function AlterarRegiaoRequest(data) {
  return {
    type: 'ALTERAR_REGIAO_REQUEST',
    data
  }
}

export function ExcluirRegiaoRequest(id) {
  return {
    type: 'EXCLUIR_REGIAO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}