import produce from 'immer';

const initialState = {
  listaSolicitacaoFornecimentoHistoricoDocArq: undefined,
  carregando: true
};

export default function solicitacaoFornecimentoHistoricoDocArq(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_DOC_ARQ_RESPONSE':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimentoHistoricoDocArq = action.listaSolicitacaoFornecimentoHistoricoDocArq;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });    

    default:
      return state;
  }
}