import { Col, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListarFornecedorRequest } from '../../../store/modules/fornecedor/actions';
import { ListarTipoPessoaRequest } from "../../../store/modules/tipoPessoa/actions";
import { ListarClienteRequest } from '../../../store/modules/cliente/actions';
import {
    ListarCompletoContratoFornecedorRequest,
    InserirContratoFornecedorRequest,
    ExcluirContratoFornecedorRequest
} from "../../../store/modules/contratoFornecedor/actions";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';

export default function Fornecedor() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);
    const listaContratoFornecedor = useSelector(state => state.contratoFornecedor.listaContratoFornecedor)
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);

    const [listaAtualContratoFornecedor, setListaAtualContratoFornecedor] = useState([]);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "ContratoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "ContratoExcluir").length == 1) {
            setExcluir(true);
        }          

    }, [])    

    useEffect(() => {

        dispatch(ListarFornecedorRequest());
        dispatch(ListarTipoPessoaRequest());
        dispatch(ListarClienteRequest());

        if (id != undefined)
            dispatch(ListarCompletoContratoFornecedorRequest(id));

    }, [])

    useEffect(() => {

        if (listaFornecedor != undefined && listaContratoFornecedor != undefined && listaContratoFornecedor.length > 0) {
            carregaListaContratoFornecedor();
        }

    }, [listaFornecedor, contratoCompleto, listaContratoFornecedor])

    function carregaListaContratoFornecedor() {

        if (contratoCompleto != null && listaFornecedor !== undefined && listaContratoFornecedor !== undefined) {
            var idFornecedorContrato = listaContratoFornecedor.filter((contrato) => contrato.idContrato === contratoCompleto.id)
                .map((contratoF) => {
                    return contratoF.idFornecedor
                });

            var listaCompleta = listaFornecedor.filter((fornecedore) => idFornecedorContrato.includes(fornecedore.id))
                .map((fornecedore) => {

                    var v = listaContratoFornecedor.filter((contrato) => contrato.idFornecedor === fornecedore.id
                        && contrato.idContrato === contratoCompleto.id);

                    return {
                        id: fornecedore.id,
                        nome: fornecedore.nomeFornecedor,
                        idContrato: v[0].idContrato,
                        idFornecedor: v[0].idFornecedor,
                        dataInicioContrato: v[0].dataInicioContrato,
                        ativo: v[0].ativo,
                        dataFimContrato: v[0].dataFimContrato
                    }
                });

            setListaAtualContratoFornecedor(listaCompleta);
        }
    }

    function AdicionarContratoFornecedor(event, e) {

        var data = {
            'Id': 0,
            'IdContrato': contratoCompleto.id,
            'IdFornecedor': e.id,
            'DataInicioContrato': e.dataInicioContrato,
            'Ativo': e.ativo,
            'DataFimContrato': e.dataFimContrato
        }

        dispatch(InserirContratoFornecedorRequest(data));
    }

    function ExcluirContratoFornecedor(event, e) {

        if (e.id != undefined) {

            let contrato = listaContratoFornecedor.find(contrato => contrato.idContrato === contratoCompleto.id
                && contrato.idFornecedor === e.id);

            dispatch(ExcluirContratoFornecedorRequest(contrato.id));

        } else {

            let contrato = listaContratoFornecedor.find(contrato => contrato.idContrato === contratoCompleto.id
                && contrato.idFornecedor === listaAtualContratoFornecedor[e].idFornecedor);

            dispatch(ExcluirContratoFornecedorRequest(contrato.id));
        }
    }

    function semPermissao() {
        toast.error(`Você não tem permissão`)
    }
    return (
        <>
            <div className="divBody">
                <Col md={12}>
                    <Form.Label>Selecione os fornecedores</Form.Label>
                    <Multiselect
                        options={
                            listaFornecedor != undefined && listaFornecedor.length > 0 && contratoCompleto != undefined ?
                                listaFornecedor.map((usuario) => {
                                    return {
                                        id: usuario.id,
                                        nome: usuario.nomeFornecedor,
                                        idContrato: contratoCompleto.contratoVersaoApiModel.idContrato,
                                        idFornecedor: usuario.id,
                                        dataInicioContrato: contratoCompleto.contratoVersaoApiModel.dataInicioContrato,
                                        ativo: usuario.ativo,
                                        dataFimContrato: contratoCompleto.contratoVersaoApiModel.dataFimContrato
                                    }
                                })
                                :
                                []
                        }
                        avoidHighlightFirstOption={true}
                        selectedValues={listaAtualContratoFornecedor}
                        disablePreSelectedValues={false}
                        placeholder="Selecione..."
                        displayValue="nome"      
                        disable={inserir == true && excluir == true ? false : true}
                        onSelect={AdicionarContratoFornecedor}
                        onRemove={ExcluirContratoFornecedor}
                        emptyRecordMsg="Nenhuma opção disponível."
                    ></Multiselect>
                </Col>
            </div>
        </>
    );
}