import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Alert, Row, Col, Modal, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ListarTipoDocumentoArquivoRequest } from "../../../store/modules/tipoDocumentoArquivo/actions";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import {
    AlterarEmpenhoDocumentoArquivoRequest, ExcluirEmpenhoDocumentoArquivoRequest,
    InserirEmpenhoDocumentoArquivoRequest, ListarEmpenhoDocumentoArquivoRequest,
    BaixarEmpenhoDocumentoArquivoRequest
} from "../../../store/modules/empenhoDocumentoArquivo/actions";
import { toast } from "react-toastify";
import { ListarStatusEmpenhoRequest } from "../../../store/modules/statusEmpenho/actions";
import { ListarEmpenhoStatusRequest } from "../../../store/modules/empenhoStatus/actions";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function DocumentoArquivo() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const documentos = useSelector(state => state.empenhoDocumentoArquivo.listaEmpenhoDocumentoArquivo);
    const listaTipoDocumentoArquivo = useSelector(state => state.tipoDocumentoArquivo.listaTipoDocumentoArquivo);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);
    const listaEmpenhoStatus = useSelector(state => state.empenhoStatus.listaEmpenhoStatus);

    const [listaFile, setListaFile] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [tipoDocumentoArquivo, setTipoDocumentoArquivo] = useState();
    const [observacao, setObservacao] = useState('');
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setObservacao('');
        setTipoDocumentoArquivo();
        setValidated(false);
    }
    const handleShow = () => setShow(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "EmpenhoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "EmpenhoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "EmpenhoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarTipoDocumentoArquivoRequest());
        //dispatch(ListarStatusEmpenhoRequest());

        if (id != undefined) {
            dispatch(ListarEmpenhoDocumentoArquivoRequest(id));
            //dispatch(ListarEmpenhoStatusRequest(id));
        }

    }, [id])

    const handleSubmit = (event) => {

        if (tipoDocumentoArquivo == undefined || tipoDocumentoArquivo == '') {
            event.preventDefault();
            setValidated(true);
        } else {
            InserirDocumentoArquivo(event);
            setValidated(false);
        }
    };

    function InserirDocumentoArquivo() {

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdEmpenho', parseInt(id));
                formData.append('IdUsuario', usuario.id);
                formData.append('IdTipoDocumentoArquivo', parseInt(tipoDocumentoArquivo));
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setListaFile([]);

            dispatch(InserirEmpenhoDocumentoArquivoRequest(formData, id));
            handleClose();

        } else {
            toast.error('Para continuar insira um documento');
        }
    }

    function AlterarDocumentoArquivo(documentoArquivo, novoValorCampoEditado, colunaEditada) {

        // Valida alteração do campo nome arquivo
        if (colunaEditada.field == "nomeArquivo") {

            if (novoValorCampoEditado == null || novoValorCampoEditado == undefined
                || novoValorCampoEditado == ' ' || novoValorCampoEditado == '') {

                toast.error(`Nome do arquivo inválido`);
                return;
            }

            if (novoValorCampoEditado.length > 150) {
                toast.error(`O nome do arquivo não pode conter mais que 150 caracteres`);
                return;
            }
        }

        // Valida alteração do campo observação
        if (colunaEditada.field == "observacao") {
            if (novoValorCampoEditado.length > 500) {
                toast.error(`A observação não pode conter mais que 500 caracteres`);
                return;
            }
        }

        if (colunaEditada != null) {

            let data = {
                'Id': documentoArquivo.id,
                'IdEmpenho': documentoArquivo.idEmpenho,
                'NomeArquivo': colunaEditada.field == "observacao" ? documentoArquivo.nomeArquivo : novoValorCampoEditado,
                'DescricaoExtensaoArquivo': documentoArquivo.descricaoExtensaoArquivo,
                'DataInclusao': documentoArquivo.dataInclusaoApi,
                'IdTipoDocumentoArquivo': documentoArquivo.idTipoDocumentoArquivo,
                'DocumentoArquivoGuid': documentoArquivo.documentoArquivoGuid,
                'IdUsuario': usuario.id,
                'Observacao': colunaEditada.field == "observacao" ? novoValorCampoEditado : documentoArquivo.observacao
            };

            dispatch(AlterarEmpenhoDocumentoArquivoRequest(data, id));
        }
    }

    function ExcluirDocumento(idDocumentoArquivo) {
        dispatch(ExcluirEmpenhoDocumentoArquivoRequest(idDocumentoArquivo, id));
    }

    function BaixarDocumento(idDocumentoArquivo) {
        dispatch(BaixarEmpenhoDocumentoArquivoRequest(idDocumentoArquivo));
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <div>
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title={
                                    inserir == true ?
                                        <Button
                                            variant="success"
                                            onClick={handleShow}
                                            style={{ width: '100%' }}
                                            disabled={
                                                listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                    listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                        listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0 ?
                                                    true :
                                                    listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                        listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                            listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                        ?
                                                        true : false
                                            }>
                                            Adicionar Documentos
                                        </Button>
                                        :
                                        ''
                                }
                                columns={[
                                    {
                                        title: "Número do Documento", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                            width: '10%',
                                        }
                                    },
                                    {
                                        title: "Nome Arquivo", field: 'nomeArquivo', cellStyle: {
                                            width: '18%'
                                        },
                                    },
                                    {
                                        title: "Tipo Documento Arquivo", field: 'tipoDocumentoArquivo', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Data", field: 'dataInclusao', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Usuario", field: 'usuario', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Observação", field: 'observacao', cellStyle: {
                                            width: '18%'
                                        },
                                        render: rowData => rowData.observacao == '' ?
                                            <div className="spanVazio"></div>
                                            : rowData.observacao
                                    }
                                ]}
                                data={
                                    documentos != undefined ?
                                        documentos.map(doc => {
                                            return {
                                                id: doc.id,
                                                nomeArquivo: doc.nomeArquivo,
                                                tipoDocumentoArquivo: doc.tipoDocumentoArquivo.descricao,
                                                dataInclusao: moment(doc.dataInclusao).format('L LT'),
                                                observacao: doc.observacao,
                                                usuario: doc.usuario.nome,

                                                // Info para alteração de documento arquivo
                                                dataInclusaoApi: doc.dataInclusao,
                                                descricaoExtensaoArquivo: doc.descricaoExtensaoArquivo,
                                                documentoArquivoGuid: doc.documentoArquivoGuid,
                                                idEmpenho: doc.idEmpenho,
                                                idTipoDocumentoArquivo: doc.idTipoDocumentoArquivo,
                                                idUsuario: doc.idUsuario
                                            }
                                        })
                                        :
                                        []
                                }
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            alterar == true ?
                                                setTimeout(() => {
                                                    AlterarDocumentoArquivo(rowData, newValue, columnDef);
                                                    resolve()
                                                }, 1000)
                                                :
                                                setTimeout(() => {
                                                    toast.error(`Você não tem permissão`);
                                                    resolve()
                                                }, 1000)
                                        });
                                    }
                                }}
                                editable={{
                                    onRowDelete:
                                        oldData =>
                                            new Promise((resolve, reject) => {
                                                excluir == true ?
                                                    setTimeout(() => {
                                                        ExcluirDocumento(oldData.id);
                                                        resolve()
                                                    }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)
                                            })
                                }}
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                                }}
                                actions={[
                                    {
                                        icon: 'download',
                                        onClick: (event, rowData) => BaixarDocumento(rowData.id)
                                    },
                                ]}
                                localization={{
                                    header: {
                                        actions: 'Ações'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                        },
                                        deleteTooltip: ''
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                            <Col md={12}>
                                {
                                    id == undefined ?
                                        ''
                                        :
                                        listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                            listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                            listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0
                                            ?
                                            <Col md={12}>
                                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                                    O Empenho está Cancelado!
                                                </Alert>
                                            </Col>
                                            :
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                ?
                                                <Col md={12}>
                                                    <Alert variant={`success`} style={{ width: '100%' }}>
                                                        O Empenho está Finalizado!
                                                    </Alert>
                                                </Col>
                                                :
                                                ''
                                }
                            </Col>
                        </div>
                    }
                </Col>
            </div>

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formTipoDocumentoArquivo">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[documentos]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={500000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDocumentos">
                                <Form.Label>Tipo documento arquivo</Form.Label>
                                <Form.Select
                                    onChange={(e) => setTipoDocumentoArquivo(e.target.value)}
                                    value={tipoDocumentoArquivo}
                                    required
                                    isInvalid={validated && tipoDocumentoArquivo == undefined
                                        || validated && tipoDocumentoArquivo == '' ? true : false}
                                    isValid={validated && tipoDocumentoArquivo !== undefined
                                        || validated && tipoDocumentoArquivo !== '' ? true : false}>
                                    <option value="">Selecione...</option>
                                    {
                                        listaTipoDocumentoArquivo != undefined ?
                                            listaTipoDocumentoArquivo.map(m => (
                                                <option value={m.id} key={m.id}>{m.descricao}</option>
                                            ))
                                            :
                                            null
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={handleSubmit}>Salvar Documentos</Button>
                            :
                            ''
                    }
                </Modal.Footer>
            </Modal>

        </>
    )
}