import { Spinner } from "react-bootstrap";
import './loading.css';

export default function Loading() {
    return (
        <>
            <Spinner animation="border" role="status" className="idSpinnerLoading">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </>
    );
}