export function ListarEventoRespostaRequest(idSolicitacaoFornecimento) {
    return {
      type: 'LISTAR_EVENTO_RESPOSTA_REQUEST', idSolicitacaoFornecimento
    }
  }
  
  export function ListarEventoRespostaResponse(listaEventoResposta) {
    return {
      type: 'LISTAR_EVENTO_RESPOSTA_RESPONSE',
      listaEventoResposta
    }
  }
  
  export function BuscarEventoRespostaPorIdRequest(id) {
    return {
      type: 'BUSCAR_EVENTO_RESPOSTA_POR_ID_REQUEST', id
    }
  }
  
  export function BuscarEventoRespostaPorIdResponse(eventoRespostaCompleto) {
    return {
      type: 'BUSCAR_EVENTO_RESPOSTA_POR_ID_RESPONSE',
      eventoRespostaCompleto
    }
  }
  
  export function InserirEventoRespostaRequest(data, idSolicitacaoFornecimento) {
    return {
      type: 'INSERIR_EVENTO_RESPOSTA_REQUEST',
      data, idSolicitacaoFornecimento
    }
  }
  
  export function AlterarEventoRespostaRequest(data, idSolicitacaoFornecimento) {
    return {
      type: 'ALTERAR_EVENTO_RESPOSTA_REQUEST',
      data, idSolicitacaoFornecimento
    }
  }
  
  export function ExcluirEventoRespostaRequest(id, idSolicitacaoFornecimento) {
    return {
      type: 'EXCLUIR_EVENTO_RESPOSTA_REQUEST',
      id, idSolicitacaoFornecimento
    }
  }
  
  export function LimparEventoResposta() {
    return {
      type: 'LIMPAR_EVENTO_RESPOSTA'
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }