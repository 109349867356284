import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarHistoricoAutorizacaoFornecimentoEmpenhoRequest } from "../../../store/modules/historicoAutorizacaoFornecimentoEmpenho/actions";
import { ListarAutorizacaoFornecimentoSubitemCustoRequest } from "../../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function HistoricoEmpenho() {

    const { idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();

    const [selectedRow, setSelectedRow] = useState(null);

    const listaHistoricoAutorizacaoFornecimentoEmpenho = useSelector(state => state.historicoAutorizacaoFornecimentoEmpenho.listaHistoricoAutorizacaoFornecimentoEmpenho);

    useEffect(() => {

        moment.locale('pt-br');

        if (idAutorizacaoFornecimento !== undefined) {
            dispatch(ListarHistoricoAutorizacaoFornecimentoEmpenhoRequest(idAutorizacaoFornecimento));

            var data = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento)
            }

            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));
        }


    }, [idAutorizacaoFornecimento])

    return (
        <>
            <div>
                <Col md={12}>
                    <MaterialTable
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        title=''
                        columns={[
                            {
                                title: 'Autorização Fornecimento', field: 'autorizacao', cellStyle: {
                                    width: '5%'
                                }
                            },
                            {
                                title: 'Empenho', field: 'empenho', cellStyle: {
                                    width: '5%'
                                }
                            },
                            {
                                title: 'Usuario', field: 'usuario', cellStyle: {
                                    width: '5%'
                                }
                            },
                            {
                                title: 'Data Inclusão', field: 'data', cellStyle: {
                                    width: '5%'
                                }
                            },
                            {
                                title: 'Total Medições Empenhadas', field: 'valorMedicoes', cellStyle: {
                                    width: '8%'
                                }
                            },
                            {
                                title: '% Relacional', field: 'percentual', cellStyle: {
                                    width: '22%'
                                }
                            },
                            {
                                title: 'Descrição', field: 'descricao', cellStyle: {
                                    width: '30%'
                                }
                            },
                            {
                                title: 'Ação', field: 'acao', cellStyle: {
                                    width: '20%', alignItems: 'center'
                                }
                            },
                        ]}
                        data={
                            listaHistoricoAutorizacaoFornecimentoEmpenho != undefined ?
                                listaHistoricoAutorizacaoFornecimentoEmpenho.map(listaHistorico => {
                                    return {
                                        usuario: listaHistorico.usuario.nome,
                                        autorizacao: listaHistorico.autorizacaoFornecimento.numeroAf,
                                        empenho: listaHistorico.empenho.descricao,
                                        descricao: listaHistorico.descricao,
                                        data: moment(listaHistorico.dataInclusao).format('L LT'),
                                        acao: listaHistorico.nomeAcao != null && listaHistorico.nomeAcao == "Inserção" ?
                                            <div className="divStatusGreen">{listaHistorico.nomeAcao}</div>
                                            :
                                            listaHistorico.nomeAcao != null && listaHistorico.nomeAcao == "Transferência" ?
                                                <div className="divStatusWarning">{listaHistorico.nomeAcao}</div>
                                                :
                                                listaHistorico.nomeAcao != null && listaHistorico.nomeAcao == "Remoção" ?
                                                    <div className="divStatusDanger">{listaHistorico.nomeAcao}</div>
                                                    : '',
                                        percentual: listaHistorico.nomeAcao != null && listaHistorico.nomeAcao == "Remoção" ?
                                            "A autorização foi removida deste empenho"
                                            :
                                            listaHistorico.nomeAcao != null && listaHistorico.nomeAcao == "Transferência" ?
                                                "Foi transferido " + ((listaHistorico.valorAutorizacaoEmpenhoTotal / listaHistorico.valorObraTotal) * 100).toFixed(2) +
                                                "% do total das medições empenhadas" + " para o empenho " + listaHistorico.empenho.descricao +
                                                " - valor empenhado " + (listaHistorico.valorAutorizacaoEmpenhoTotal).toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                                :
                                                "Foi inserido no empenho " + listaHistorico.empenho.descricao + ", " +
                                                ((listaHistorico.valorAutorizacaoEmpenhoTotal / listaHistorico.valorObraTotal) * 100).toFixed(2) +
                                                "% do total das medições empenhadas" +
                                                " - valor empenhado " + (listaHistorico.valorAutorizacaoEmpenhoTotal).toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }),
                                        valorMedicoes: (listaHistorico.valorObraTotal).toLocaleString('pt-br', {
                                            style: 'currency', currency: 'BRL',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }),
                                    }
                                })
                                :
                                []
                        }
                        options={{
                            minBodyHeight: 500,
                            paging: true,
                            pageSize: 10, // Início
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [10, 50, 200, 300],
                            filterRowStyle: {
                                backgroundColor: "#FAFAFA"
                            },
                            headerStyle: {
                                backgroundColor: '#454545',
                                color: '#FFF',
                                fontWeight: "bold"
                            },
                            actionsColumnIndex: -1,
                            filtering: true,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        localization={{
                            header: {
                                actions: 'Ações'
                            },
                            body: {
                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                editRow: {
                                    deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                },
                                deleteTooltip: ''
                            },
                            toolbar: {
                                searchTooltip: '',
                                searchPlaceholder: 'Pesquisar',
                                exportTitle: "",
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                            },
                            pagination: {
                                labelRowsSelect: 'linhas',
                                labelDisplayedRows: '{count} de {from}-{to}',
                                firstTooltip: '',
                                previousTooltip: '',
                                nextTooltip: '',
                                lastTooltip: ''
                            },
                            grouping: {
                                placeholder: 'Arraste a coluna aqui para agrupar',
                                groupedBy: 'Agrupado por:'
                            }
                        }}
                    />
                </Col>
                <Col md={12}>
                    <br />
                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                        <Form.Label>Total das Medições Empenhadas</Form.Label>
                        <Form.Control
                            type="text"
                            value={listaHistoricoAutorizacaoFornecimentoEmpenho !== undefined &&
                                listaHistoricoAutorizacaoFornecimentoEmpenho !== null &&
                                listaHistoricoAutorizacaoFornecimentoEmpenho.length > 0 ?
                                (listaHistoricoAutorizacaoFornecimentoEmpenho[0].valorObraTotal).toLocaleString('pt-br', {
                                    style: 'currency', currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                                : null}
                            disabled
                        />
                    </Form.Group> <br />
                </Col>
            </div>
        </>
    )
}