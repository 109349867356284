import { Col, Row, Form, Accordion } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import { ListarUsuarioRequest } from '../../../store/modules/usuario/actions';
import { useHistory, useParams } from 'react-router-dom';
import {
    AlterarContratoUsuarioRequest, InserirContratoUsuarioRequest,
    ListarContratoUsuarioRequest, ExcluirContratoUsuarioRequest
} from '../../../store/modules/contratoUsuario/actions'
import { toast } from "react-toastify";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './usuarioPermissao.css';

export default function UsuarioPermissao() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const listaUsuario = useSelector(state => state.usuario.listaUsuario);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const [listaAtualContratoUsuario, setListaAtualContratoUsuario] = useState([]);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "ContratoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "ContratoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "ContratoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        dispatch(ListarUsuarioRequest());
        dispatch(ListarContratoUsuarioRequest());

    }, [id])

    useEffect(() => {
        if (listaContratoUsuario != undefined) {
            carregaLista();
        }
    }, [contratoCompleto, listaContratoUsuario])

    // Carrega lista atual de usuários (e suas permissões) que estão inclusos no contrato
    function carregaLista() {

        if (contratoCompleto != null && listaContratoUsuario != undefined && listaUsuario != undefined) {
            let idUsuariosContrato = listaContratoUsuario.filter((contratoUsu) => contratoUsu.idContrato === contratoCompleto.id)
                .map((contratoUsu) => {
                    return contratoUsu.idUsuario
                });

            let listaCompleta = listaUsuario.filter((usuario) => idUsuariosContrato.includes(usuario.id))
                .map((usuario) => {

                    let contrato = listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                        && contratoUsu.idContrato === contratoCompleto.id);

                    return {
                        nome: usuario.nome,
                        id: usuario.id,
                        analisaOrcamentoSolicitacao: contrato[0].analisaOrcamentoSolicitacao,
                        aprovaSolicitacao: contrato[0].aprovaSolicitacao,
                        finalizaSolicitacao: contrato[0].finalizaSolicitacao,
                        criaItemSolicitacao: contrato[0].criaItemSolicitacao,
                        cancelaSolicitacao: contrato[0].cancelaSolicitacao,
                        finalizaAutorizacao: contrato[0].finalizaAutorizacao,
                        editaPrazoAutorizacao: contrato[0].editaPrazoAutorizacao,
                        cancelaAutorizacao: contrato[0].cancelaAutorizacao,
                        reabrirAutorizacao: contrato[0].reabrirAutorizacao,
                        finalizaMedicao: contrato[0].finalizaMedicao,
                        cancelaMedicao: contrato[0].cancelaMedicao,
                        iniciarMedicao: contrato[0].iniciarMedicao,
                        editaQuantidadeSubitemMedicao: contrato[0].editaQuantidadeSubitemMedicao,
                        editaQuantidadeSubitemAutorizacao: contrato[0].editaQuantidadeSubitemAutorizacao,
                        cancelaEmpenho: contrato[0].cancelaEmpenho,
                        finalizaEmpenho: contrato[0].finalizaEmpenho,
                        analisaSubitemEmpenho: contrato[0].analisaSubitemEmpenho,
                        faturamentoEmpenho: contrato[0].faturamentoEmpenho,
                        idContratoUsuario: contrato[0].id,
                        editaExcedenteSubitemAutorizacao: contrato[0].editaExcedenteSubitemAutorizacao,
                    }
                });

            setListaAtualContratoUsuario(listaCompleta);
        }
    }

    function AdicionarContratoUsuario(event, e) {

        let data = {
            'Id': 0,
            'IdContrato': contratoCompleto.id,
            'IdUsuario': e.id,
            'AnalisaOrcamentoSolicitacao': false,
            'AprovaSolicitacao': false,
            'FinalizaSolicitacao': false,
            'CriaItemSolicitacao': false,
            'CancelaSolicitacao': false,
            'FinalizaAutorizacao': false,
            'EditaPrazoAutorizacao': false,
            'CancelaAutorizacao': false,
            'ReabrirAutorizacao': false,
            'FinalizaMedicao': false,
            'CancelaMedicao': false,
            'IniciarMedicao': false,
            'EditaQuantidadeSubitemMedicao': false,
            'EditaQuantidadeSubitemAutorizacao': false,
            'CancelaEmpenho': false,
            'FinalizaEmpenho': false,
            'AnalisaSubitemEmpenho': false,
            'FaturamentoEmpenho': false,
            'EditaExcedenteSubitemAutorizacao': false,
        }

        dispatch(InserirContratoUsuarioRequest(data));
    }

    function ExcluirContratoUsuario(event, e) {

        if (e.id != undefined) {

            let contrato = listaContratoUsuario.find(contrato => contrato.idContrato === contratoCompleto.id
                && contrato.idUsuario === e.id);

            if (e.id === contratoCompleto.idUsuario) {
                toast.error('Não é possível excluir o criador do contrato!');
                setListaAtualContratoUsuario(event);
                dispatch(ListarContratoUsuarioRequest());
            } else {
                dispatch(ExcluirContratoUsuarioRequest(contrato.id));
            }
        } else {

            let contrato = listaContratoUsuario.find(contrato => contrato.idContrato === contratoCompleto.id
                && contrato.idUsuario === e);

            if (e === contratoCompleto.idUsuario) {
                toast.error('Não é possível excluir o criador do contrato!');
                setListaAtualContratoUsuario(event);
                dispatch(ListarContratoUsuarioRequest());
            } else {
                dispatch(ExcluirContratoUsuarioRequest(contrato.id));
            }
        }
    }

    function AlterarContratoUsuario(e, contratoUsuario, permissao) {

        let data = {
            'Id': contratoUsuario.idContratoUsuario,
            'IdContrato': contratoCompleto.id,
            'IdUsuario': contratoUsuario.id,
            'AprovaSolicitacao': contratoUsuario.aprovaSolicitacao,
            'FinalizaSolicitacao': contratoUsuario.finalizaSolicitacao,
            'CriaItemSolicitacao': contratoUsuario.criaItemSolicitacao,
            'CancelaSolicitacao': contratoUsuario.cancelaSolicitacao,
            'AnalisaOrcamentoSolicitacao': contratoUsuario.analisaOrcamentoSolicitacao,
            'FinalizaAutorizacao': contratoUsuario.finalizaAutorizacao,
            'EditaPrazoAutorizacao': contratoUsuario.editaPrazoAutorizacao,
            'CancelaAutorizacao': contratoUsuario.cancelaAutorizacao,
            'ReabrirAutorizacao': contratoUsuario.reabrirAutorizacao,
            'FinalizaMedicao': contratoUsuario.finalizaMedicao,
            'CancelaMedicao': contratoUsuario.cancelaMedicao,
            'IniciarMedicao': contratoUsuario.iniciarMedicao,
            'EditaQuantidadeSubitemMedicao': contratoUsuario.editaQuantidadeSubitemMedicao,
            'EditaQuantidadeSubitemAutorizacao': contratoUsuario.editaQuantidadeSubitemAutorizacao,
            'CancelaEmpenho': contratoUsuario.cancelaEmpenho,
            'FinalizaEmpenho': contratoUsuario.finalizaEmpenho,
            'AnalisaSubitemEmpenho': contratoUsuario.analisaSubitemEmpenho,
            'FaturamentoEmpenho': contratoUsuario.faturamentoEmpenho,
            'EditaExcedenteSubitemAutorizacao': contratoUsuario.editaExcedenteSubitemAutorizacao,
        }

        switch (permissao) {

            case 'aprovaSolicitacao':
                contratoUsuario.aprovaSolicitacao = e.target.checked;
                data.AprovaSolicitacao = !data.AprovaSolicitacao;
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'finalizaSolicitacao':
                contratoUsuario.finalizaSolicitacao = e.target.checked;
                data.FinalizaSolicitacao = !data.FinalizaSolicitacao;
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'criaItemSolicitacao':
                contratoUsuario.criaItemSolicitacao = e.target.checked;
                data.CriaItemSolicitacao = !data.CriaItemSolicitacao;
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'cancelaSolicitacao':
                contratoUsuario.cancelaSolicitacao = e.target.checked;
                data.CancelaSolicitacao = !data.CancelaSolicitacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'analisaOrcamentoSolicitacao':
                contratoUsuario.analisaOrcamentoSolicitacao = e.target.checked;
                data.AnalisaOrcamentoSolicitacao = !data.AnalisaOrcamentoSolicitacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'finalizaAutorizacao':
                contratoUsuario.finalizaAutorizacao = e.target.checked;
                data.FinalizaAutorizacao = !data.FinalizaAutorizacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'editaPrazoAutorizacao':
                contratoUsuario.editaPrazoAutorizacao = e.target.checked;
                data.EditaPrazoAutorizacao = !data.EditaPrazoAutorizacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'cancelaAutorizacao':
                contratoUsuario.cancelaAutorizacao = e.target.checked;
                data.CancelaAutorizacao = !data.CancelaAutorizacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'reabrirAutorizacao':
                contratoUsuario.reabrirAutorizacao = e.target.checked;
                data.ReabrirAutorizacao = !data.ReabrirAutorizacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'finalizaMedicao':
                contratoUsuario.finalizaMedicao = e.target.checked;
                data.FinalizaMedicao = !data.FinalizaMedicao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'cancelaMedicao':
                contratoUsuario.cancelaMedicao = e.target.checked;
                data.CancelaMedicao = !data.CancelaMedicao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'iniciarMedicao':
                contratoUsuario.iniciarMedicao = e.target.checked;
                data.IniciarMedicao = !data.IniciarMedicao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'editaQuantidadeSubitemMedicao':
                contratoUsuario.editaQuantidadeSubitemMedicao = e.target.checked;
                data.EditaQuantidadeSubitemMedicao = !data.EditaQuantidadeSubitemMedicao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'editaQuantidadeSubitemAutorizacao':
                contratoUsuario.editaQuantidadeSubitemAutorizacao = e.target.checked;
                data.EditaQuantidadeSubitemAutorizacao = !data.EditaQuantidadeSubitemAutorizacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'cancelaEmpenho':
                contratoUsuario.cancelaEmpenho = e.target.checked;
                data.CancelaEmpenho = !data.CancelaEmpenho
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'finalizaEmpenho':
                contratoUsuario.finalizaEmpenho = e.target.checked;
                data.FinalizaEmpenho = !data.FinalizaEmpenho
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'analisaSubitemEmpenho':
                contratoUsuario.analisaSubitemEmpenho = e.target.checked;
                data.AnalisaSubitemEmpenho = !data.AnalisaSubitemEmpenho
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'faturamentoEmpenho':
                contratoUsuario.faturamentoEmpenho = e.target.checked;
                data.FaturamentoEmpenho = !data.FaturamentoEmpenho
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
            case 'editaExcedenteSubitemAutorizacao':
                contratoUsuario.editaExcedenteSubitemAutorizacao = e.target.checked;
                data.EditaExcedenteSubitemAutorizacao = !data.EditaExcedenteSubitemAutorizacao
                dispatch(AlterarContratoUsuarioRequest(data));
                break;
        }
    }

    function AlterarContratoUsuarioTodos(e, contratoUsuario, tabela) {

        let data = {
            'Id': contratoUsuario.idContratoUsuario,
            'IdContrato': contratoCompleto.id,
            'IdUsuario': contratoUsuario.id,
            'AprovaSolicitacao': contratoUsuario.aprovaSolicitacao,
            'FinalizaSolicitacao': contratoUsuario.finalizaSolicitacao,
            'CriaItemSolicitacao': contratoUsuario.criaItemSolicitacao,
            'CancelaSolicitacao': contratoUsuario.cancelaSolicitacao,
            'AnalisaOrcamentoSolicitacao': contratoUsuario.analisaOrcamentoSolicitacao,
            'FinalizaAutorizacao': contratoUsuario.finalizaAutorizacao,
            'EditaPrazoAutorizacao': contratoUsuario.editaPrazoAutorizacao,
            'CancelaAutorizacao': contratoUsuario.cancelaAutorizacao,
            'ReabrirAutorizacao': contratoUsuario.reabrirAutorizacao,
            'FinalizaMedicao': contratoUsuario.finalizaMedicao,
            'CancelaMedicao': contratoUsuario.cancelaMedicao,
            'IniciarMedicao': contratoUsuario.iniciarMedicao,
            'EditaQuantidadeSubitemMedicao': contratoUsuario.editaQuantidadeSubitemMedicao,
            'EditaQuantidadeSubitemAutorizacao': contratoUsuario.editaQuantidadeSubitemAutorizacao,
            'CancelaEmpenho': contratoUsuario.cancelaEmpenho,
            'FinalizaEmpenho': contratoUsuario.finalizaEmpenho,
            'AnalisaSubitemEmpenho': contratoUsuario.analisaSubitemEmpenho,
            'FaturamentoEmpenho': contratoUsuario.faturamentoEmpenho,
            'EditaExcedenteSubitemAutorizacao': contratoUsuario.editaExcedenteSubitemAutorizacao,
        }

        switch (tabela) {

            case 'tabelaSolicitacaoFornecimento':
                data.AnalisaOrcamentoSolicitacao = e.target.checked;
                data.AprovaSolicitacao = e.target.checked;
                data.FinalizaSolicitacao = e.target.checked;
                data.CancelaSolicitacao = e.target.checked;
                data.CriaItemSolicitacao = e.target.checked;
                break;

            case 'tabelaAutorizacaoFornecimento':
                data.EditaQuantidadeSubitemAutorizacao = e.target.checked;
                data.EditaPrazoAutorizacao = e.target.checked;
                data.FinalizaAutorizacao = e.target.checked;
                data.CancelaAutorizacao = e.target.checked;
                data.ReabrirAutorizacao = e.target.checked;
                data.EditaExcedenteSubitemAutorizacao = e.target.checked;
                break;

            case 'tabelaMedicaoAutorizacaoFornecimento':
                data.EditaQuantidadeSubitemMedicao = e.target.checked;
                data.FinalizaMedicao = e.target.checked;
                data.CancelaMedicao = e.target.checked;
                data.IniciarMedicao = e.target.checked;
                break;
            case 'tabelaEmpenho':
                data.CancelaEmpenho = e.target.checked;
                data.FinalizaEmpenho = e.target.checked;
                data.AnalisaSubitemEmpenho = e.target.checked;
                data.FaturamentoEmpenho = e.target.checked;
                break;
        }

        dispatch(AlterarContratoUsuarioRequest(data));
    }

    return (
        <>
            <div className="divBody">
                <Col md={12}>
                    <Form.Label>Selecione os usuários que terão permissão</Form.Label>
                    <Multiselect
                        options={
                            contratoCompleto && listaUsuario != undefined ?
                                listaUsuario.filter((usuario) => (contratoCompleto.idUsuario !== usuario.id))
                                    .map((usuario) => {
                                        return {
                                            nome: usuario.nome,
                                            id: usuario.id,
                                            aprovaSolicitacao: false,
                                            finalizaSolicitacao: false,
                                            criaItemSolicitacao: false,
                                            cancelaSolicitacao: false,
                                            analisaOrcamentoSolicitacao: false,
                                            finalizaAutorizacao: false,
                                            editaPrazoAutorizacao: false,
                                            cancelaAutorizacao: false,
                                            reabrirAutorizacao: false,
                                            finalizaMedicao: false,
                                            cancelaMedicao: false,
                                            iniciarMedicao: false,
                                            editaQuantidadeSubitemMedicao: false,
                                            editaQuantidadeSubitemAutorizacao: false,
                                            cancelaEmpenho: false,
                                            finalizaEmpenho: false,
                                            analisaSubitemEmpenho: false,
                                            faturamentoEmpenho: false,
                                            editaExcedenteSubitemAutorizacao: false,
                                            idContratoUsuario: 0
                                        }
                                    })
                                :
                                []
                        }
                        avoidHighlightFirstOption={true}
                        selectedValues={listaAtualContratoUsuario}
                        disablePreSelectedValues={false}
                        placeholder="Selecione..."
                        displayValue="nome"
                        onSelect={inserir == true ? AdicionarContratoUsuario : undefined}
                        onRemove={excluir == true ? ExcluirContratoUsuario : undefined}
                        emptyRecordMsg="Nenhuma opção disponível."
                    ></Multiselect>
                </Col>
            </div>
            <div className="divBody">
                <Row>
                    <Col md={12}>

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Permissões Solicitação Fornecimento</Accordion.Header>
                                <Accordion.Body>
                                    <Table striped bordered hover variant="white">
                                        <Thead>
                                            <Tr className="trPermissoesGrupo">
                                                <Th className="thIdGrupo">#</Th>
                                                <Th className="thDescricaoPermissoesContrato">Nome</Th>
                                                <Th className="thDescricaoPermissoesNome">Selecionar Todos</Th>
                                                <Th className="tAlterarPermissoesContrato">Orçamento</Th>
                                                <Th className="tAlterarPermissoesContrato">Aprova</Th>
                                                <Th className="thCriarPermissoesContrato">Finaliza</Th>
                                                <Th className="thCriarPermissoesContrato">Cancela</Th>
                                                <Th className="thCriarPermissoesContrato">Cria Item</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className='tbody'>
                                            {
                                                contratoCompleto ?
                                                    listaAtualContratoUsuario.map((contratoUsuario) => (
                                                        <Tr className="trGrupo" key={contratoUsuario.id}>
                                                            <Td>{contratoUsuario.id}</Td>
                                                            <Td>{contratoUsuario.nome}</Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={
                                                                        contratoUsuario.analisaOrcamentoSolicitacao &&
                                                                            contratoUsuario.aprovaSolicitacao &&
                                                                            contratoUsuario.finalizaSolicitacao &&
                                                                            contratoUsuario.cancelaSolicitacao &&
                                                                            contratoUsuario.criaItemSolicitacao ? true : false
                                                                    }
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuarioTodos(e, contratoUsuario, 'tabelaSolicitacaoFornecimento') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.analisaOrcamentoSolicitacao}
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'analisaOrcamentoSolicitacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.aprovaSolicitacao}
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'aprovaSolicitacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.finalizaSolicitacao}
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'finalizaSolicitacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.cancelaSolicitacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'cancelaSolicitacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"

                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.criaItemSolicitacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'criaItemSolicitacao') : ''} />
                                                            </Td>
                                                        </Tr>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Permissões Autorização Fornecimento</Accordion.Header>
                                <Accordion.Body>
                                    <Table striped bordered hover variant="white">
                                        <Thead>
                                            <Tr className="trPermissoesGrupo">
                                                <Th className="thIdGrupo">#</Th>
                                                <Th className="thDescricaoPermissoesContrato">Nome</Th>
                                                <Th className="thDescricaoPermissoesNome">Selecionar Todos</Th>
                                                <Th className="thCriarPermissoesContrato">Edita Subitem</Th>
                                                <Th className="thCriarPermissoesContrato">Edita Prazo</Th>
                                                <Th className="thCriarPermissoesContrato">Finaliza</Th>
                                                <Th className="thCriarPermissoesContrato">Cancela</Th>
                                                <Th className="thCriarPermissoesContrato">Reabrir</Th>
                                                <Th className="thCriarPermissoesContrato">Edita Excedente Subitem</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className='tbody'>
                                            {
                                                contratoCompleto ?
                                                    listaAtualContratoUsuario.map((contratoUsuario) => (
                                                        <Tr className="trGrupo" key={contratoUsuario.id}>
                                                            <Td>{contratoUsuario.id}</Td>
                                                            <Td>{contratoUsuario.nome}</Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={
                                                                        contratoUsuario.editaQuantidadeSubitemAutorizacao &&
                                                                            contratoUsuario.finalizaAutorizacao &&
                                                                            contratoUsuario.cancelaAutorizacao ? true : false
                                                                    }
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuarioTodos(e, contratoUsuario, 'tabelaAutorizacaoFornecimento') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.editaQuantidadeSubitemAutorizacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'editaQuantidadeSubitemAutorizacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.editaPrazoAutorizacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'editaPrazoAutorizacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.finalizaAutorizacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'finalizaAutorizacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.cancelaAutorizacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'cancelaAutorizacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.reabrirAutorizacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'reabrirAutorizacao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.editaExcedenteSubitemAutorizacao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'editaExcedenteSubitemAutorizacao') : ''} />
                                                            </Td>
                                                        </Tr>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Permissões Medição Autorização Fornecimento</Accordion.Header>
                                <Accordion.Body>
                                    <Table striped bordered hover variant="white">
                                        <Thead>
                                            <Tr className="trPermissoesGrupo">
                                                <Th className="thIdGrupo">#</Th>
                                                <Th className="thDescricaoPermissoesContrato">Nome</Th>
                                                <Th className="thDescricaoPermissoesNome">Selecionar Todos</Th>
                                                <Th className="thCriarPermissoesContrato">Iniciar Medição</Th>
                                                <Th className="thCriarPermissoesContrato">Edita Subitem</Th>
                                                <Th className="thCriarPermissoesContrato">Finaliza</Th>
                                                <Th className="thCriarPermissoesContrato">Cancela</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className='tbody'>
                                            {
                                                contratoCompleto ?
                                                    listaAtualContratoUsuario.map((contratoUsuario) => (
                                                        <Tr className="trGrupo" key={contratoUsuario.id}>
                                                            <Td>{contratoUsuario.id}</Td>
                                                            <Td>{contratoUsuario.nome}</Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={
                                                                        contratoUsuario.editaQuantidadeSubitemMedicao &&
                                                                            contratoUsuario.finalizaMedicao &&
                                                                            contratoUsuario.cancelaMedicao &&
                                                                            contratoUsuario.iniciarMedicao ? true : false
                                                                    }
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuarioTodos(e, contratoUsuario, 'tabelaMedicaoAutorizacaoFornecimento') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.iniciarMedicao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'iniciarMedicao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.editaQuantidadeSubitemMedicao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'editaQuantidadeSubitemMedicao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.finalizaMedicao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'finalizaMedicao') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.cancelaMedicao}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'cancelaMedicao') : ''} />
                                                            </Td>
                                                        </Tr>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Permissões Empenho</Accordion.Header>
                                <Accordion.Body>
                                    <Table striped bordered hover variant="white">
                                        <Thead>
                                            <Tr className="trPermissoesGrupo">
                                                <Th className="thIdGrupo">#</Th>
                                                <Th className="thDescricaoPermissoesContrato">Nome</Th>
                                                <Th className="thDescricaoPermissoesNome">Selecionar Todos</Th>
                                                <Th className="thCriarPermissoesContrato">Analisa Subitem</Th>
                                                <Th className="thCriarPermissoesContrato">Faturamento</Th>
                                                <Th className="thCriarPermissoesContrato">Finaliza</Th>
                                                <Th className="thCriarPermissoesContrato">Cancela</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody className='tbody'>
                                            {
                                                contratoCompleto ?
                                                    listaAtualContratoUsuario.map((contratoUsuario) => (
                                                        <Tr className="trGrupo" key={contratoUsuario.id}>
                                                            <Td>{contratoUsuario.id}</Td>
                                                            <Td>{contratoUsuario.nome}</Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={
                                                                        contratoUsuario.analisaSubitemEmpenho &&
                                                                            contratoUsuario.faturamentoEmpenho &&
                                                                            contratoUsuario.finalizaEmpenho &&
                                                                            contratoUsuario.cancelaEmpenho ? true : false
                                                                    }
                                                                    disabled={contratoUsuario.id === contratoCompleto.idUsuario ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuarioTodos(e, contratoUsuario, 'tabelaEmpenho') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.analisaSubitemEmpenho}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'analisaSubitemEmpenho') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.faturamentoEmpenho}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'faturamentoEmpenho') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.finalizaEmpenho}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'finalizaEmpenho') : ''} />
                                                            </Td>
                                                            <Td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={contratoUsuario.id}
                                                                    checked={contratoUsuario.cancelaEmpenho}
                                                                    disabled={(contratoUsuario.id === contratoCompleto.idUsuario) ? true : false}
                                                                    onChange={e => alterar == true ? AlterarContratoUsuario(e, contratoUsuario, 'cancelaEmpenho') : ''} />
                                                            </Td>
                                                        </Tr>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </div>
        </>
    );
}