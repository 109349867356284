import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { BuscarContratoVersaoPorIdResponse, Carregando, ListarContratoVersaoResponse } from './actions';
import { BuscarContratoPorIdRequest } from '../contrato/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { ListarSolicitacaoFornecimentoResponse } from '../solicitacaoFornecimento/actions';

function* ListarContratoVersaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/ContratoVersao/listarCompleto`, 
      { IdContrato: parseInt(model.idContrato) },{ headers: headerParams.token });

    yield put(ListarContratoVersaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarContratoVersaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirContratoVersaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/ContratoVersao`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ContratoVersao/${model.id}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Contrato Versão foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
    
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    

  }
}

function* BuscarContratoVersaoPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/ContratoVersao/${model.id}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(BuscarContratoVersaoPorIdResponse(null));
    yield put(Carregando(true));
    
  }
}

function* InserirItensContratoVersaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const resultListaSubItem = yield call(api.post, `/api/subItemCusto/listarCompleto`, {'IdContrato': model.data.IdContrato}, { headers: headerParams.token });

    if (resultListaSubItem.data.result != null && resultListaSubItem.data.result.length == 0) {
      toast.error(`No momento o contrato não possui subitem.`);
    } else {
                      
      //Criar nova versão de contrato
      yield call(api.post, `/api/ContratoVersao/criarItensVersao`, model.data, { headers: headerParams.token });

      //Listando Contrato
      yield put(BuscarContratoPorIdRequest(model.idContrato));
        
      //Listando Versão do Contrato
      const resultVersao = yield call(api.post, `/api/ContratoVersao/listarCompleto`, 
        { IdContrato: parseInt(model.idContrato) },{ headers: headerParams.token });
      yield put(ListarContratoVersaoResponse(resultVersao.data.result));

      //Listando Solicitações
      var listaSolicitacao = yield call(api.post, `/api/SolicitacaoFornecimento/ListarCompleto`,
        { IdSolicitacaoFornecimento: null, IdContrato: model.idContrato }, { headers: headerParams.token });    
      yield put(ListarSolicitacaoFornecimentoResponse(listaSolicitacao.data.result));
      
      toast.success(`A nova versão do contrato foi cadastrada com sucesso!`);      

    }

    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    

  }
}

export default all([
  takeLatest('LISTAR_CONTRATO_VERSAO_REQUEST', ListarContratoVersaoRequest),
  takeLatest('BUSCAR_CONTRATO_VERSAO_POR_ID_REQUEST', BuscarContratoVersaoPorIdRequest),
  takeLatest('INSERIR_CONTRATO_VERSAO_REQUEST', InserirContratoVersaoRequest),
  takeLatest('INSERIR_ITENS_CONTRATO_VERSAO_REQUEST', InserirItensContratoVersaoRequest)
])