import produce from 'immer';

const initialState = {
  listaOrigemContrato: undefined,
  carregando: true
};

export default function origemContrato(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_ORIGEM_CONTRATO_RESPONSE':
      return produce(state, draft => {
        draft.listaOrigemContrato = action.listaOrigemContrato;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}