import produce from 'immer';

const initialState = {
  autorizacaoFornecimentoSubitemCustoCompleto: null,
  listaAutorizacaoFornecimentoSubitemCusto: undefined,
  listaCompletoAutorizacaoFornecimentoSubitemCusto: undefined,
  carregando: true
};

export default function autorizacaoFornecimentoSubitemCusto(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_RESPONSE':
      return produce(state, draft => {
        draft.listaAutorizacaoFornecimentoSubitemCusto = action.listaAutorizacaoFornecimentoSubitemCusto
      });

      case 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletoAutorizacaoFornecimentoSubitemCusto = action.listaCompletoAutorizacaoFornecimentoSubitemCusto
      });

    case 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.autorizacaoFornecimentoSubitemCustoCompleto = action.autorizacaoFornecimentoSubitemCustoCompleto;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}