import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarEmpenhoStatusResponse, ListarCompletoEmpenhoStatusResponse, ValidarEmpenhoStatusResponse, ListarMultaEmpenhoResponse, ListarDiferencaEmpenhoResponse } from './actions';
import { toast } from 'react-toastify';
import { BuscarEmpenhoPorIdResponse } from '../empenho/actions';
import { ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse } from '../empenhoAutorizacaoFornecimentoSubitemCustoMed/actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarEmpenhoStatusRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdEmpenho': parseInt(model.idEmpenho)
    }

    const result = yield call(api.post, `/api/EmpenhoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

    yield put(ListarEmpenhoStatusResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarEmpenhoStatusResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirEmpenhoStatusRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    if (model.validacaoFinalizado) {

      yield call(api.post, `/api/EmpenhoStatus`, model.data, { headers: headerParams.token });

      toast.success(`Status inserido!`);

      var data = {
        'IdEmpenho': parseInt(model.idEmpenho)
      }

      // Carrega lista de status atuais
      const result = yield call(api.post, `/api/EmpenhoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

      yield put(ListarEmpenhoStatusResponse(result.data.result));

      // Carregar a lista completa para a aba de histórico
      const resultCompleto = yield call(api.post, `/api/EmpenhoStatus/ListarCompleto`, data, { headers: headerParams.token });

      yield put(ListarCompletoEmpenhoStatusResponse(resultCompleto.data.result));

      // Buscar empenho pra atualizar valores pagos
      const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

      yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

      // Lista de subitem atualizadas
      const resultEmpSub = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/ListarCompleto`,
        { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

      yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(resultEmpSub.data.result));

    } else {

      // Validar saldo quantidade
      const resultValidar = yield call(api.post, `/api/EmpenhoStatus/validar`, model.data, { headers: headerParams.token });

      var valida = false;
      var lista = resultValidar.data.result;
      lista.forEach(element => {
        if (element.saldoContratoAlemExcedente === true)
          valida = true;
        if (element.saldoAutorizacaoExcedente === true)
          valida = true;
      });

      if (valida) {

        yield put(ValidarEmpenhoStatusResponse(resultValidar.data.result));

      } else {

        //Inserir Status
        yield call(api.post, `/api/EmpenhoStatus`, model.data, { headers: headerParams.token });

        toast.success(`Status inserido!`);

        var data = {
          'IdEmpenho': parseInt(model.idEmpenho)
        }

        // Carrega lista de status atuais
        const result = yield call(api.post, `/api/EmpenhoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarEmpenhoStatusResponse(result.data.result));

        // Carregar a lista completa para a aba de histórico
        const resultCompleto = yield call(api.post, `/api/EmpenhoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoEmpenhoStatusResponse(resultCompleto.data.result));

        // Buscar empenho pra atualizar valores pagos
        const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

        yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

        // Lista de subitem atualizadas
        const resultEmpSub = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/ListarCompleto`,
          { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

        yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(resultEmpSub.data.result));

        yield put(ValidarEmpenhoStatusResponse(null));
      }

    }

    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarEmpenhoStatusRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    if (model.validacaoFinalizado) {

      yield call(api.put, `/api/EmpenhoStatus`, model.data, { headers: headerParams.token });

      toast.success(`Status foi atualizado com sucesso!`);

      var data = {
        'IdEmpenho': parseInt(model.idEmpenho)
      }

      const result = yield call(api.post, `/api/EmpenhoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

      yield put(ListarEmpenhoStatusResponse(result.data.result));

      // Carregar a lista completa para a aba de historico
      const resultCompleto = yield call(api.post, `/api/EmpenhoStatus/ListarCompleto`, data, { headers: headerParams.token });

      yield put(ListarCompletoEmpenhoStatusResponse(resultCompleto.data.result));

      // Buscar empenho pra atualizar valores pagos
      const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

      yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

      // Lista de subitem atualizadas
      const resultEmpSub = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/ListarCompleto`,
        { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

      yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(resultEmpSub.data.result));

    } else {

      // Validar saldo quantidade
      const resultValidar = yield call(api.post, `/api/EmpenhoStatus/validar`, model.data, { headers: headerParams.token });

      var valida = false;
      var lista = resultValidar.data.result;
      lista.forEach(element => {
        if (element.saldoContratoAlemExcedente === true)
          valida = true;
        if (element.saldoAutorizacaoExcedente === true)
          valida = true;
      });

      if (valida) {

        yield put(ValidarEmpenhoStatusResponse(resultValidar.data.result));

      } else {

        yield call(api.put, `/api/EmpenhoStatus`, model.data, { headers: headerParams.token });

        toast.success(`Status foi atualizado com sucesso!`);

        var data = {
          'IdEmpenho': parseInt(model.idEmpenho)
        }

        const result = yield call(api.post, `/api/EmpenhoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarEmpenhoStatusResponse(result.data.result));

        // Carregar a lista completa para a aba de historico
        const resultCompleto = yield call(api.post, `/api/EmpenhoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoEmpenhoStatusResponse(resultCompleto.data.result));

        // Buscar empenho pra atualizar valores pagos
        const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

        yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

        // Lista de subitem atualizadas
        const resultEmpSub = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/ListarCompleto`,
          { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

        yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(resultEmpSub.data.result));
      }

    }

    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirEmpenhoStatusRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/EmpenhoStatus/${model.id}`, { headers: headerParams.token });

    toast.success(`EmpenhoStatus id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/EmpenhoStatus`, { headers: headerParams.token });

    yield put(ListarEmpenhoStatusResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

function* ListarCompletoEmpenhoStatusRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdEmpenho': parseInt(model.idEmpenho)
    }

    const result = yield call(api.post, `/api/EmpenhoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoEmpenhoStatusResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarCompletoEmpenhoStatusResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarMultaEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdEmpenho': parseInt(model.idEmpenho)
    }

    const result = yield call(api.get, `/api/Empenho/ListaMulta/${parseInt(model.idEmpenho)}`, { headers: headerParams.token });

    yield put(ListarMultaEmpenhoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarCompletoEmpenhoStatusResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarDiferencaEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdEmpenho': parseInt(model.idEmpenho)
    }

    const result = yield call(api.get, `/api/Empenho/ListaDiferenca/${parseInt(model.idEmpenho)}`, { headers: headerParams.token });

    yield put(ListarDiferencaEmpenhoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarCompletoEmpenhoStatusResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* VoltarStatusEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/EmpenhoStatus/VoltarStatus/${model.id}`, { headers: headerParams.token });

    toast.success(`O status foi excluido!`);

    var data = {
      'IdEmpenho': parseInt(model.id)
    }

    // Carrega lista de status atuais
    const result = yield call(api.post, `/api/EmpenhoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

    yield put(ListarEmpenhoStatusResponse(result.data.result));

    // Carregar a lista completa para a aba de histórico
    const resultCompleto = yield call(api.post, `/api/EmpenhoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoEmpenhoStatusResponse(resultCompleto.data.result));
    
    // Buscar empenho pra atualizar valores pagos
    const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

export default all([
  takeLatest('LISTAR_EMPENHO_STATUS_REQUEST', ListarEmpenhoStatusRequest),
  takeLatest('INSERIR_EMPENHO_STATUS_REQUEST', InserirEmpenhoStatusRequest),
  takeLatest('ALTERAR_EMPENHO_STATUS_REQUEST', AlterarEmpenhoStatusRequest),
  takeLatest('EXCLUIR_EMPENHO_STATUS_REQUEST', ExcluirEmpenhoStatusRequest),
  takeLatest('LISTAR_COMPLETO_EMPENHO_STATUS_REQUEST', ListarCompletoEmpenhoStatusRequest),
  takeLatest('LISTAR_MULTA_EMPENHO_REQUEST', ListarMultaEmpenhoRequest),
  takeLatest('LISTAR_DIFERENCA_EMPENHO_REQUEST',ListarDiferencaEmpenhoRequest),
  takeLatest('VOLTAR_STATUS_EMPENHO_REQUEST', VoltarStatusEmpenhoRequest)
])