import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarOrigemContratoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarOrigemContratoRequest() {

  try {


    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/OrigemContrato`, { headers: headerParams.token });

    yield put(ListarOrigemContratoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarOrigemContratoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
    
  }
}

function* InserirOrigemContratoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/OrigemContrato`, model.data, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Origem Contrato ${model.nome} foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarOrigemContratoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/OrigemContrato`, model.data, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Origem Contrato foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ExcluirOrigemContratoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/OrigemContrato/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Origem Contrato id: ${model.id} foi excluido!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_ORIGEM_CONTRATO_REQUEST', ListarOrigemContratoRequest),
  takeLatest('INSERIR_ORIGEM_CONTRATO_REQUEST', InserirOrigemContratoRequest),
  takeLatest('ALTERAR_ORIGEM_CONTRATO_REQUEST', AlterarOrigemContratoRequest),
  takeLatest('EXCLUIR_ORIGEM_CONTRATO_REQUEST', ExcluirOrigemContratoRequest)
])