import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { ListarClienteRequest } from '../../../store/modules/cliente/actions';
import { useDispatch, useSelector } from "react-redux";
import { InserirContratoRequest, AlterarContratoRequest, BuscarContratoPorIdRequest } from "../../../store/modules/contrato/actions";
import { ListarOrigemContratoRequest } from '../../../store/modules/origemContrato/actions';
import { useHistory, useParams } from 'react-router-dom';
import { ListarTipoContratoVersaoRequest } from '../../../store/modules/tipoContratoVersao/actions';
import { toast } from "react-toastify";
import ModalContratoVersao from "../../../components/ModalContratoVersao/modalContratoVersao";
import './contrato.css';
import 'moment/locale/pt-br';
import moment from 'moment';

export default function Contrato() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaCliente = useSelector(state => state.cliente.listaCliente);
    const listaOrigemContrato = useSelector(state => state.origemContrato.listaOrigemContrato);
    const listaTipoContratoVersao = useSelector(state => state.tipoContratoVersao.listaTipoContratoVersao);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);

    const [idCliente, setIdCliente] = useState('');
    const [descricao, setDescricao] = useState('');
    const [numeroInterno, setNumeroInterno] = useState('');
    const [idOrigem, setIdOrigem] = useState('');
    const [numeroOrigem, setNumeroOrigem] = useState('');
    const [privado, setPrivado] = useState(false);
    const [dataInicioContrato, setDataInicioContrato] = useState('');
    const [dataFimContrato, setDataFimContrato] = useState('');
    const [valorIndiceReajuste, setValorIndiceReajuste] = useState('');
    const [valorIndiceMulta, setValorIndiceMulta] = useState('');
    const [valorIndiceQuantidadeItemExcedente, setValorIndiceQuantidadeItemExcedente] = useState('');
    const [observacao, setObservacao] = useState('');
    const [liberaExcedenteGlobal, setLiberaExcedenteGlobal] = useState(false);
    const [liberaExcedenteSubitem, setLiberaExcedenteSubitem] = useState(false);
    const [liberaExcedenteAutorizacao, setLiberaExcedenteAutorizacao] = useState(false);

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [validatedDataFim, setValidatedDataFim] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "ContratoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "ContratoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        const date = moment((new Date())).add('hours', 2).toISOString().substr(0, 10);
        setDataInicioContrato(date);
        setDataFimContrato(date);

        dispatch(ListarClienteRequest());
        dispatch(ListarOrigemContratoRequest());
        dispatch(ListarTipoContratoVersaoRequest());
        if (id != undefined)
            dispatch(BuscarContratoPorIdRequest(id));

    }, [])

    useEffect(() => {

        if (contratoCompleto != null && contratoCompleto.id == parseInt(id)) {
            carregaContrato();
        }

    }, [contratoCompleto])

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false || ValidaDataInicioFim() === false ||
            (valorIndiceMulta > 1 || valorIndiceMulta < 0 || valorIndiceMulta === "") === true ||
            (valorIndiceReajuste > 1 || valorIndiceReajuste < -1 || valorIndiceReajuste === "") === true ||
            (valorIndiceQuantidadeItemExcedente > 1 || valorIndiceQuantidadeItemExcedente < 0 || valorIndiceQuantidadeItemExcedente === "") === true) {

            event.preventDefault();
        } else {
            InserirContrato(event);
        }

        setValidated(true);
    };

    function ValidaDataInicioFim() {

        var verificarDatainicio = dataInicioContrato.split("-");
        var verificarDatafim = dataFimContrato.split("-");

        if (verificarDatafim >= verificarDatainicio) {
            return true;
        } else {
            return false;
        }
    }

    function VerificarDataInicioContrato(event) {

        setDataInicioContrato(event.target.value);

        var verificarDatainicio = event.target.value.split("-");
        var verificarDatafim = dataFimContrato.split("-");

        if (verificarDatafim >= verificarDatainicio) {
            setValidatedDataFim(true);
        } else {
            setValidatedDataFim(false);
        }
    }

    function VerificarDataFimContrato(event) {
        setDataFimContrato(event.target.value);

        var verificarDatafim = event.target.value.split("-");
        var verificarDatainicio = dataInicioContrato.split("-");

        if (verificarDatafim >= verificarDatainicio) {
            setValidatedDataFim(true);
        } else {
            setValidatedDataFim(false);
        }
    }

    function InserirContrato(e) {

        e.preventDefault();

        //validação do campo observação
        if (observacao.length > 500) {
            toast.error(`A observação não pode conter mais que 500 caracteres`);
            return;
        }

        //validação do campo descrição
        if (descricao == null || descricao == undefined
            || descricao == ' ' || descricao == '') {
            toast.error(`Descrição inválida`);
            return;
        }
        if (descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return;
        }

        //validação do campo numeroInterno
        if (numeroInterno == null || numeroInterno == undefined
            || numeroInterno == ' ' || numeroInterno == '') {
            toast.error(`Numero Interno inválido`);
            return;
        }

        //validação do campo numeroOrigem
        if (numeroOrigem == null || numeroOrigem == undefined
            || numeroOrigem == ' ' || numeroOrigem == '') {
            toast.error(`Numero Origem inválido`);
            return;
        }

        var data = {
            'Id': contratoCompleto == null ? parseInt(0) : contratoCompleto.id,
            'IdCliente': parseInt(idCliente),
            'Descricao': descricao.trim(),
            'NumeroInternoContrato': numeroInterno,
            'IdOrigemContrato': parseInt(idOrigem),
            'NumeroOrigemContrato': numeroOrigem,
            'Privado': privado,
            'IdUsuario': parseInt(usuario.id),
            'DataAlteracaoSistema': moment((new Date())).add('hours', 2),
            'DataInclusaoSistema': moment((new Date())).add('hours', 2),
            'Observacao': observacao,
            'ContratoVersaoApiModel': {
                'Id': contratoCompleto == null ? parseInt(0) : contratoCompleto.contratoVersaoApiModel.id,
                'IdTipoContratoVersao': contratoCompleto == null ? parseInt(0) : contratoCompleto.contratoVersaoApiModel.idTipoContratoVersao,
                'DataVersao': moment((new Date())).add('hours', 2),
                'DataInicioContrato': dataInicioContrato,
                'DataFimContrato': dataFimContrato,
                'ValorIndiceReajuste': parseFloat(valorIndiceReajuste),
                'ValorIndiceMulta': parseFloat(valorIndiceMulta),
                'ValorIndiceQuantidadeItemExcedente': parseFloat(valorIndiceQuantidadeItemExcedente),
                'LiberaExcedenteGlobal': liberaExcedenteGlobal,
                'LiberaExcedenteSubitem': liberaExcedenteSubitem,
                'LiberaExcedenteAutorizacao': liberaExcedenteAutorizacao
            }
        }

        if (id == undefined) {
            dispatch(InserirContratoRequest(data));
        } else {
            dispatch(AlterarContratoRequest(data));
        }
    }

    function carregaContrato() {

        // Contrato
        setIdCliente(contratoCompleto.idCliente);
        setDescricao(contratoCompleto.descricao);
        setIdOrigem(contratoCompleto.idOrigemContrato);
        setNumeroInterno(contratoCompleto.numeroInternoContrato);
        setNumeroOrigem(contratoCompleto.numeroOrigemContrato);
        setPrivado(contratoCompleto.privado);
        setObservacao(contratoCompleto.observacao);

        // Contrato Versão
        setDataInicioContrato(contratoCompleto.contratoVersaoApiModel.dataInicioContrato.split('T')[0]);
        setDataFimContrato(contratoCompleto.contratoVersaoApiModel.dataFimContrato.split('T')[0]);
        setValorIndiceReajuste(contratoCompleto.contratoVersaoApiModel.valorIndiceReajuste);
        setValorIndiceMulta(contratoCompleto.contratoVersaoApiModel.valorIndiceMulta);
        setValorIndiceQuantidadeItemExcedente(contratoCompleto.contratoVersaoApiModel.valorIndiceQuantidadeItemExcedente);
        setLiberaExcedenteGlobal(contratoCompleto.contratoVersaoApiModel.liberaExcedenteGlobal);
        setLiberaExcedenteSubitem(contratoCompleto.contratoVersaoApiModel.liberaExcedenteSubitem);
        setLiberaExcedenteAutorizacao(contratoCompleto.contratoVersaoApiModel.liberaExcedenteAutorizacao);
    }

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="divBody">
                    <Row>
                        <Col md={12}>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formDescricao">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricao}
                                        maxLength="200"
                                        onChange={e => setDescricao(e.target.value)}
                                        required
                                        isInvalid={validated && descricao === "" ? true : false}
                                        isValid={validated && descricao !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formIdCliente">
                                    <Form.Label>Cliente</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setIdCliente(e.target.value)}
                                        value={idCliente}
                                        required
                                        isInvalid={validated && idCliente === "" ? true : false}
                                        isValid={validated && idCliente !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaCliente != undefined ?
                                                listaCliente.map(m => (
                                                    <option value={m.id} key={m.id}>{m.nome}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo Cliente é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formIdOrigem">
                                    <Form.Label>Origem</Form.Label>
                                    <Form.Select onChange={e => setIdOrigem(e.target.value)} value={idOrigem} required
                                        isInvalid={validated && idOrigem === "" ? true : false}
                                        isValid={validated && idOrigem !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaOrigemContrato != undefined ?
                                                listaOrigemContrato.map(m => (
                                                    <option value={m.id} key={m.id}>{m.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo Origem é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formNumeroOrigem">
                                    <Form.Label>Número Origem</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={numeroOrigem}
                                        onChange={e => setNumeroOrigem(e.target.value)}
                                        required
                                        maxLength="500"
                                        isInvalid={validated && numeroOrigem === "" ? true : false}
                                        isValid={validated && numeroOrigem !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Número Origem é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formNumeroInterno">
                                    <Form.Label>Número Interno</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={numeroInterno}
                                        onChange={e => setNumeroInterno(e.target.value)}
                                        required
                                        maxLength="50"
                                        isInvalid={validated && numeroInterno === "" ? true : false}
                                        isValid={validated && numeroInterno !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Número Interno é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formDescricao">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={observacao}
                                        onChange={e => setObservacao(e.target.value)} />
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formDataInicioContrato">
                                    <Form.Label>Data início contrato</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dataInicioContrato}
                                        onChange={VerificarDataInicioContrato}
                                        required
                                        isInvalid={validated ? false : false}
                                        isValid={validated ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formDataFimContrato">
                                    <Form.Label>Data fim contrato</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dataFimContrato}
                                        onChange={VerificarDataFimContrato}
                                        required
                                        isInvalid={validated && !validatedDataFim ? true : false}
                                        isValid={validated && validatedDataFim ? true : false} />
                                    <Form.Control.Feedback type="invalid">A data do fim não pode ser antes da data do início do contrato.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formValorIndiceReajuste">
                                    <Form.Label>Valor índice reajuste</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={valorIndiceReajuste}
                                        onChange={(e) => setValorIndiceReajuste(e.target.value)}
                                        isInvalid={validated && valorIndiceReajuste === ""
                                            || validated && valorIndiceReajuste > 1
                                            || validated && valorIndiceReajuste < -1 ? true : false}
                                        isValid={validated && valorIndiceReajuste !== ""
                                            || validated && valorIndiceReajuste < 1
                                            || validated && valorIndiceReajuste > -1 ? true : false}
                                        required
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {
                                            valorIndiceReajuste === "" ?
                                                'O campo valor índice reajuste é obrigatório.'
                                                :
                                                'O valor índice reajuste deve estar no intervalo de -1 a 1.'

                                        }
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formValorIndiceMulta">
                                    <Form.Label>Valor índice multa</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={valorIndiceMulta}
                                        onChange={(e) => setValorIndiceMulta(e.target.value)}
                                        isInvalid={validated && valorIndiceMulta < 0
                                            || validated && valorIndiceMulta > 1
                                            || validated && valorIndiceMulta === "" ? true : false}
                                        isValid={validated && valorIndiceMulta > 0
                                            || validated && valorIndiceMulta < 1
                                            || validated && valorIndiceMulta !== "" ? true : false}
                                        required
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {
                                            valorIndiceMulta === "" ?
                                                'O campo valor índice multa é obrigatório.'
                                                :
                                                'O valor índice multa deve estar no intervalo de 0 a 1.'

                                        }
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formValorIndiceQuantidadeItemExcedente">
                                    <Form.Label>Valor índice qtd. itens excedentes</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={valorIndiceQuantidadeItemExcedente}
                                        onChange={(e) => setValorIndiceQuantidadeItemExcedente(e.target.value)}
                                        isInvalid={validated && valorIndiceQuantidadeItemExcedente < 0
                                            || validated && valorIndiceQuantidadeItemExcedente > 1
                                            || validated && valorIndiceQuantidadeItemExcedente === "" ? true : false}
                                        isValid={validated && valorIndiceQuantidadeItemExcedente > 0
                                            || validated && valorIndiceQuantidadeItemExcedente < 1
                                            || validated && valorIndiceQuantidadeItemExcedente !== "" ? true : false}
                                        required
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {
                                            valorIndiceQuantidadeItemExcedente === "" ?
                                                'O campo valor índice quantidade de item excedente é obrigatório.'
                                                :
                                                'O valor índice quantidade de item excedente deve estar no intervalo de 0 a 1.'

                                        }
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={1} controlId="formPrivado">
                                    <Form.Label></Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label={`Privado`}
                                        checked={privado}
                                        onChange={(e) => setPrivado(e.target.checked)} />
                                </Form.Group>

                                <Form.Group as={Col} md={1} controlId="formExcedenteSubitem">
                                    <Form.Label></Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label={`Excedente Subitem`}
                                        checked={liberaExcedenteSubitem}
                                        onChange={(e) => setLiberaExcedenteSubitem(e.target.checked)} />
                                </Form.Group>

                                <Form.Group as={Col} md={1} controlId="formExcedenteAutorizacao">
                                    <Form.Label></Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label={`Excedente Autorizacao`}
                                        checked={liberaExcedenteAutorizacao}
                                        onChange={(e) => setLiberaExcedenteAutorizacao(e.target.checked)} />
                                </Form.Group>

                                <Form.Group as={Col} md={1} controlId="formExcedenteGlobal">
                                    <Form.Label></Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label={`Excedente Global`}
                                        checked={liberaExcedenteGlobal}
                                        onChange={(e) => setLiberaExcedenteGlobal(e.target.checked)} />
                                </Form.Group>
                            </Row>

                            <Row>
                                {
                                    id == undefined && inserir == true
                                        ?
                                        <Col md={2}>
                                            <Button type="submit" variant="success" style={{ width: '100%' }}>Cadastrar</Button>
                                        </Col>

                                        : alterar == true
                                            ?
                                            <Col md={2}>
                                                <Button type="submit" variant="success" style={{ width: '100%' }}>Editar</Button>
                                            </Col>
                                            :
                                            ''
                                }
                                {
                                    id != undefined ?
                                        <Col md={2}>
                                            <Button variant="success" onClick={handleShow} style={{ width: '100%' }} disabled={id == undefined ? true : false}>Criar Nova Versão</Button>
                                        </Col>
                                        :
                                        ''
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form >

            <ModalContratoVersao
                show={show}
                onHide={handleClose}
                idContratoCompleto={id !== undefined ? id : null}
                listaTipoContratoVersao={contratoCompleto !== null && listaTipoContratoVersao != undefined ? listaTipoContratoVersao : null}
                dataInicioContratoForm={contratoCompleto !== null ? dataInicioContrato : null}
                dataFimContratoForm={contratoCompleto !== null ? dataFimContrato : null}
                valorIndiceReajusteForm={contratoCompleto !== null ? valorIndiceReajuste : null}
                valorIndiceMultaForm={contratoCompleto !== null ? valorIndiceMulta : null}
                valorIndiceQuantidadeItemExcedenteForm={contratoCompleto !== null ? valorIndiceQuantidadeItemExcedente : null}
            />

        </>
    );
}