import produce from 'immer';

const initialState = {
  listaEventoResposta: undefined,
  carregando: true,
  eventoRespostaCompleto: null
};

export default function eventoResposta(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_EVENTO_RESPOSTA_RESPONSE':
      return produce(state, draft => {
        draft.listaEventoResposta = action.listaEventoResposta;
      });

      case 'BUSCAR_EVENTO_RESPOSTA_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.eventoRespostaCompleto = action.eventoRespostaCompleto;
      });

      case 'LIMPAR_EVENTO_RESPOSTA':
      return produce(state, draft => {
        draft.eventoRespostaCompleto = null
      }); 

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}