export function ListarTipoSolicitacaoTipoDocumentoArquivoRequest() {
    return {
      type: 'LISTAR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST'
    }
  }
  
  export function ListarTipoSolicitacaoTipoDocumentoArquivoResponse(listaTipoSolicitacaoTipoDocumentoArquivo) {
    return {
      type: 'LISTAR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_RESPONSE',
      listaTipoSolicitacaoTipoDocumentoArquivo
    }
  }
  
  export function InserirTipoSolicitacaoTipoDocumentoArquivoRequest(data) {
    return {
      type: 'INSERIR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST',
      data
    }
  }
  
  export function AlterarTipoSolicitacaoTipoDocumentoArquivoRequest(data) {
    return {
      type: 'ALTERAR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST',
      data
    }
  }
  
  export function ExcluirTipoSolicitacaoTipoDocumentoArquivoRequest(id) {
    return {
      type: 'EXCLUIR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_REQUEST',
      id
    }
}
  
export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}