import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import {
    InserirSolicitacaoFornecimentoVersaoStatusRequest, ListarSolicitacaoFornecimentoVersaoStatusRequest,
    AlterarSolicitacaoFornecimentoVersaoStatusRequest, LimparListaValidacaoSolicitacao, LimparSolicitacaoStatus,
    ExcluirSolicitacaoFornecimentoVersaoStatusRequest, ExcluirSolicitacaoFornecimentoVersaoStatusMantendoOsItensRequest
} from "../../../store/modules/solicitacaoFornecimentoVersaoStatus/actions";
import { ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest } from '../../../store/modules/solicitacaoFornecimentoVersaoSubitemCusto/actions';
import { ListarSolicitacaoFornecimentoDocumentoArquivoRequest } from "../../../store/modules/solicitacaoFornecimentoDocumentoArquivo/actions";
import { BuscarContratoPorIdRequest } from "../../../store/modules/contrato/actions";
import { ListarUsuarioFornecedorRequest } from "../../../store/modules/usuarioFornecedor/actions";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { ListarStatusSolicitacaoFornecimentoRequest } from '../../../store/modules/statusSolicitacaoFornecimento/actions';
import { BuscarSolicitacaoFornecimentoPorIdRequest } from '../../../store/modules/solicitacaoFornecimento/actions';
import { BuscarUsuarioPorIdRequest } from "../../../store/modules/usuario/actions";
import { toast } from 'react-toastify';
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarTipoSolicitacaoTipoDocumentoArquivoRequest } from "../../../store/modules/tipoSolicitacaoTipoDocumentoArquivo/actions";
import { InserirSolicitacaoFornecimentoVersaoRequest } from "../../../store/modules/solicitacaoFornecimentoVersao/actions";
import { ListarTipoDocumentoArquivoRequest } from "../../../store/modules/tipoDocumentoArquivo/actions";
import { ListarCompletoAutorizacaoFornecimentoComRedirecionamentoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import {
    InserirSolicitacaoFornecimentoDocumentoArquivoRequest
} from "../../../store/modules/solicitacaoFornecimentoDocumentoArquivo/actions";
import { BaixarDocumentoRequest } from "../../../store/modules/global/actions";
import { DropzoneArea } from 'material-ui-dropzone';
import ModalVerificacaoSaldoSubitem from "../../../components/ModalVerificacaoSaldoSubitem/modalVerificacaoSaldoSubitem";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';
import './status.css';
import { parse } from "date-fns";
import { RiFileListFill, RiFolder3Fill, RiFileTextFill } from "react-icons/ri";

export default function Status() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const listaSolicitacaoFornecimentoVersaoStatus = useSelector(state =>
        state.solicitacaoFornecimentoVersaoStatus.listaSolicitacaoFornecimentoVersaoStatus);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const listaSolicitacaoFornecimentoDocumentoArquivo = useSelector(state =>
        state.solicitacaoFornecimentoDocumentoArquivo.listaSolicitacaoFornecimentoDocumentoArquivo);
    const listaTipoSolicitacaoTipoDocumentoArquivo = useSelector(state =>
        state.tipoSolicitacaoTipoDocumentoArquivo.listaTipoSolicitacaoTipoDocumentoArquivo);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);
    const listaSolicitacaoFornecimentoVersaoSubitemCusto = useSelector(state =>
        state.solicitacaoFornecimentoVersaoSubitemCusto.listaSolicitacaoFornecimentoVersaoSubitemCusto);
    const listaTipoDocumentoArquivo = useSelector(state => state.tipoDocumentoArquivo.listaTipoDocumentoArquivo);
    const listaUsuarioFornecedor = useSelector(state => state.usuarioFornecedor.listaUsuarioFornecedor);
    const listaValidacaoSolicitacaoStatus = useSelector(state => state.solicitacaoFornecimentoVersaoStatus.listaValidacaoSolicitacaoStatus);
    const documentos = useSelector(state => state.solicitacaoFornecimentoDocumentoArquivo.listaSolicitacaoFornecimentoDocumentoArquivo);
    const listaRegistroFotografico = useSelector(state => state.solicitacaoFornecimentoRegistroFotografico.listaSolicitacaoFornecimentoRegistroFotografico);

    const [descricaoObservacaoReprovado, setDescricaoObservacaoReprovado] = useState("");
    const [descricaoObservacaoCancelado, setDescricaoObservacaoCancelado] = useState("");
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showNovaVersao, setShowNovaVersao] = useState(false);
    const [showStatusReprovado, setShowStatusReprovado] = useState(false);
    const [status, setStatus] = useState('');
    const [listaFile, setListaFile] = useState([]);
    const [tipoDocumentoArquivo, setTipoDocumentoArquivo] = useState();
    const [statusAnaliseAcao, setStatusAnaliseAcao] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [validated, setValidated] = useState(false);
    const [validatedReprovado, setValidatedReprovado] = useState(false);
    const [showValidacao, setShowValidacao] = useState(false);
    const [showFinalizarOrcamento, setShowFinalizarOrcamento] = useState(false);
    const [finalizarOrcamento, setFinalizarOrcamento] = useState('');
    const [manterItens, setManterItens] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const handleCloseNovaVersao = () => setShowNovaVersao(false);
    const handleShowNovaVersao = () => setShowNovaVersao(true);
    const handleCloseStatusReprovado = () => setShowStatusReprovado(false);
    const handleShowStatusReprovado = () => setShowStatusReprovado(true);
    const handleCloseValidacao = () => setShowValidacao(false);
    const handleShowValidacao = () => setShowValidacao(true);
    const handleCloseFinalizarOrcamento = () => setShowFinalizarOrcamento(false);
    const handleShowFinalizarOrcamento = () => setShowFinalizarOrcamento(true);

    var relacaoIdStatus = listaSolicitacaoFornecimentoVersaoStatus != undefined ?
        listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.statusReprovado == true)
        : [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarUsuarioFornecedorRequest());
        //dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarSolicitacaoFornecimentoDocumentoArquivoRequest(id));
        dispatch(ListarTipoSolicitacaoTipoDocumentoArquivoRequest());
        //dispatch(ListarStatusSolicitacaoFornecimentoRequest());
        dispatch(ListarTipoDocumentoArquivoRequest());

        if (id != undefined) {
            dispatch(ListarSolicitacaoFornecimentoVersaoStatusRequest(id));
            //dispatch(ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest(id));
        }

    }, [id])

    useEffect(() => () =>
        dispatch(LimparSolicitacaoStatus())
        , []);

    useEffect(() => {
        dispatch(BuscarUsuarioPorIdRequest(usuario.id));
        if (solicitacaoFornecimento != null) {
            dispatch(BuscarContratoPorIdRequest(solicitacaoFornecimento.idContrato));
        }

    }, [solicitacaoFornecimento])

    // Abrir o modal de aviso com itens excedentes (caso tenha) ao aprovar análise de orçamento
    useEffect(() => {
        if (listaValidacaoSolicitacaoStatus != null && listaValidacaoSolicitacaoStatus != undefined) {
            handleShowValidacao();
        }
    }, [listaValidacaoSolicitacaoStatus])

    // Verificar observação ao cancelar solicitação
    const handleSubmit = (event) => {

        if (descricaoObservacaoCancelado.trim() == '') {
            event.preventDefault();
        } else {
            InserirSolicitacaoStatusCancelado(event);
        }

        setValidated(true);
    };

    // Verificar observação ao reprovar análise de orçamento em solicitação
    /*const handleSubmitReprovado = (event) => {

        if (descricaoObservacaoReprovado == '') {
            event.preventDefault();
        } else {
            InserirSolicitacaoStatus();
            setDescricaoObservacaoReprovado('');
        }

        setValidatedReprovado(true);
    };*/

    const handleSubmitReprovadoComDocumento = (event) => {

        if (descricaoObservacaoReprovado.trim() === '') {
            toast.error('Descrição invalida');
            event.preventDefault();
            setValidated(true);
        } else {
            if (descricaoObservacaoReprovado.trim() !== '' && listaFile.length > 0 && (tipoDocumentoArquivo === undefined || isNaN(tipoDocumentoArquivo) || tipoDocumentoArquivo === '')) {
                toast.error('Insira um tipo documento arquivo');
                setValidated(true);
                return
            }
            else if (descricaoObservacaoReprovado.trim() !== '' && listaFile.length === 0 && (tipoDocumentoArquivo !== undefined && !isNaN(tipoDocumentoArquivo) && tipoDocumentoArquivo !== '')) {
                toast.error('Ao selecionar um Tipo Documento Arquivo deve-se inserir um Documento');
                event.preventDefault();
                setValidated(true);
                return
            }
            else if (descricaoObservacaoReprovado.trim() !== '' && listaFile.length > 0 && (tipoDocumentoArquivo !== undefined && !isNaN(tipoDocumentoArquivo) && tipoDocumentoArquivo !== '')) {
                SalvarDocumento(event);
                setValidated(false);
                InserirSolicitacaoStatus();
                if (id != undefined)
                    dispatch(BuscarSolicitacaoFornecimentoPorIdRequest(id));

            }
            else if (descricaoObservacaoReprovado.trim() !== '' && listaFile.length === 0 && (tipoDocumentoArquivo === undefined || isNaN(tipoDocumentoArquivo) || tipoDocumentoArquivo === '')) {
                InserirSolicitacaoStatus();
                setDescricaoObservacaoReprovado('');
            }
            setTipoDocumentoArquivo();
            setDescricaoObservacaoReprovado('');
        }

        setValidatedReprovado(true);
    };

    // Faz verificações para ver se poderá avançar ao próximo status
    function ValidarProximoStatus(statusParametro) {

        var verificacao = true;

        if (statusParametro == 'Orçamento Solicitado') {

            if (listaSolicitacaoFornecimentoDocumentoArquivo.filter(x => x.idTipoDocumentoArquivo == 98).length == 0) {
                toast.error("Para avançar no status é preciso que tenha um documento do tipo Ofício inserido na solicitação.");
                verificacao = false;
                return;
            }
        }

        // Algumas validações de acordo com o tipo do status
        if (listaStatusSolicitacaoFornecimentoCompleto != undefined &&
            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro).length > 0) {

            // Verifica se o status precisa validar documentos
            if (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].documentoObrigatorio == true) {

                var documentos = '';

                // Verifica se falta algum documento obrigatório, se faltar manda o toast com os pendentes
                if (listaTipoSolicitacaoTipoDocumentoArquivo != undefined && listaSolicitacaoFornecimentoDocumentoArquivo != undefined
                    && solicitacaoFornecimento != null) {
                    var x = 0;
                    listaTipoSolicitacaoTipoDocumentoArquivo.map(relacao => {
                        if (relacao.idTipoSolicitacao == solicitacaoFornecimento.idTipoSolicitacao) {
                            var verifica = listaSolicitacaoFornecimentoDocumentoArquivo.filter(x => relacao.idTipoDocumentoArquivo == x.idTipoDocumentoArquivo)
                            if (verifica.length > 0) {
                            } else {
                                var tipoDocumento = listaTipoDocumentoArquivo.filter(x => x.id == relacao.idTipoDocumentoArquivo)
                                if (tipoDocumento.length > 0) {
                                    if (x == 0) {
                                        documentos = documentos.concat(tipoDocumento[0].descricao);
                                        x++;
                                    } else {
                                        documentos = documentos.concat(', ' + tipoDocumento[0].descricao);
                                        x++;
                                    }
                                }
                            }
                        }
                    })

                    if (documentos != '') {
                        toast.error("Para aprovar é preciso que a solicitação tenha todos os documentos inseridos! Documentos pendentes: " + documentos);
                        verificacao = false;
                        return;
                    }
                }
            }

            // Verifica se o status precisa validar numero de processo
            if (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].numeroProcessoObrigatorio == true) {

                if (solicitacaoFornecimento.numeroProcesso == '' || solicitacaoFornecimento.numeroProcesso == null) {
                    toast.error("Para aprovar é preciso que a solicitação tenha o Número Processo!");
                    verificacao = false;
                    return;
                }
            }

            // Verifica se precisa de fotos
            /*if(listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].solicitaOrcamentoSolicitacao == true
            || listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].descricaoSolicitacaoFornecimentoVersao
            == 'Orçamento Finalizado'){
                if(solicitacaoFornecimento.tipoSolicitacao.fotoObrigatorio == true && solicitacaoFornecimento.tipoSolicitacao.quantidadeFotoObrigatorio != null){

                    if(listaRegistroFotografico.length < solicitacaoFornecimento.tipoSolicitacao.quantidadeFotoObrigatorio){
                        toast.error("Para seguir é preciso que a solicitação tenha " + solicitacaoFornecimento.tipoSolicitacao.quantidadeFotoObrigatorio + " registro(s) fotográfico(s) inseridos!");
                        verificacao = false;
                        return;
                    }

                }
            }*/

            // Verifica se o status precisa validar se possui subitens inseridos
            if (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].itemObrigatorio == true) {

                if (!listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].solicitaOrcamentoSolicitacao) {
                    // Verifica número de subitens na solicitação
                    if (listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined && solicitacaoFornecimento != null &&
                        solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null && listaSolicitacaoFornecimentoVersaoSubitemCusto
                            .filter((solicitacao) => solicitacao.idSolicitacaoFornecimentoVersao
                                === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id).length == 0) {

                        toast.error("Para seguir é preciso que a solicitação tenha itens inseridos!");
                        verificacao = false;
                        return;
                    }

                    // Verifica se tem pelo menos 1 subitem ativo para continuar
                    var ativo = false;
                    var verificaAtivoLista = [];

                    if (listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined && solicitacaoFornecimento != null &&
                        solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null) {

                        verificaAtivoLista = listaSolicitacaoFornecimentoVersaoSubitemCusto
                            .filter((solicitacao) => solicitacao.idSolicitacaoFornecimentoVersao === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id);
                    }

                    if (verificaAtivoLista.length > 0) {

                        verificaAtivoLista.map(x => {
                            if (x.ativo == true) {
                                ativo = true;
                            }
                        })

                        if (ativo == false) {
                            toast.error("Para seguir é preciso que a solicitação tenha pelo menos 1 item ativo!");
                            verificacao = false;
                            return;
                        }
                    }
                }
            }
        }

        if (listaStatusSolicitacaoFornecimentoCompleto != undefined &&
            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro).length > 0
            && listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == statusParametro)[0].statusParteFornecedor == true) {

            if (usuarioCompleto != null && !usuarioCompleto.usuarioFornecedor) {

                toast.error("Para avançar o status é necessario ser fornecedor!");
                verificacao = false;
                return;
            }

        } else {

            if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {

                toast.error("Para avançar o status é necessario ser cliente!");
                verificacao = false;
                return;
            }
        }

        // Se não tiver problema abre o modal para o usuário avançar
        if (verificacao == true) {
            setStatus(statusParametro);
            handleShowStatus();
        }
    }

    // Insere(ou altera em alguns casos) o status que o usuário clicou para avançar
    function InserirSolicitacaoStatus() {

        // Pega o status a ser inserido (próximo)
        let proximoStatus = [];
        var verificacao = true;

        if (listaStatusSolicitacaoFornecimentoCompleto != undefined) {
            proximoStatus = listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == status);
        }

        // Caso o status é análise e já está criado, busca o status para altera-lo
        let relacaoAlterarAnalise = [];

        if (statusAnaliseAcao != '' && proximoStatus.length > 0) {
            relacaoAlterarAnalise = listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento == proximoStatus[0].id)
        }

        // Altera status análise de orçamento caso foi aprovado
        if (statusAnaliseAcao == 'Aprovar') {
            if (relacaoAlterarAnalise.length > 0) {
                let data = {
                    'Id': relacaoAlterarAnalise[0].id,
                    'IdSolicitacaoFornecimentoVersao': relacaoAlterarAnalise[0].idSolicitacaoFornecimentoVersao,
                    'IdStatusSolicitacaoFornecimento': relacaoAlterarAnalise[0].idStatusSolicitacaoFornecimento,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'ObservacaoReprovado': '',
                    'StatusAprovado': true,
                    'StatusReprovado': false,
                    'ObservacaoCancelado': '',
                    'AnaliseConcluida': false,
                    'IdSolicitacaoFornecimento': id
                }

                dispatch(AlterarSolicitacaoFornecimentoVersaoStatusRequest(data, id, false));
            }

            // Altera status análise de orçamento caso foi reprovado
        } else if (statusAnaliseAcao == 'Reprovar') {

            if (relacaoAlterarAnalise.length > 0) {
                let data = {
                    'Id': relacaoAlterarAnalise[0].id,
                    'IdSolicitacaoFornecimentoVersao': relacaoAlterarAnalise[0].idSolicitacaoFornecimentoVersao,
                    'IdStatusSolicitacaoFornecimento': relacaoAlterarAnalise[0].idStatusSolicitacaoFornecimento,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'ObservacaoReprovado': descricaoObservacaoReprovado,
                    'StatusAprovado': false,
                    'StatusReprovado': true,
                    'ObservacaoCancelado': '',
                    'AnaliseConcluida': false,
                    'IdSolicitacaoFornecimento': id
                }

                dispatch(AlterarSolicitacaoFornecimentoVersaoStatusRequest(data, id, true));
                setModal(false);
                handleCloseStatusReprovado();
            }

            // Cria o status finalizado, mas verifica antes se tem algum item ultrapassando o saldo excedente do contrato
        } else if (proximoStatus.length > 0 && proximoStatus[0].finalizaSolicitacao) {

            let data = {
                'Id': 0,
                'IdSolicitacaoFornecimentoVersao': parseInt(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id),
                'IdStatusSolicitacaoFornecimento': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'ObservacaoReprovado': '',
                'StatusAprovado': false,
                'StatusReprovado': false,
                'ObservacaoCancelado': '',
                'AnaliseConcluida': false,
                'IdSolicitacaoFornecimento': id
            }

            dispatch(InserirSolicitacaoFornecimentoVersaoStatusRequest(data, id, false));

            // Insere o status solicita orçamento
        } else if (proximoStatus[0].solicitaOrcamentoSolicitacao) {

            // Verificar se é cliente
            if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
                toast.error("Você precisa ser cliente para solicitar o orçamento!");
                verificacao = false;
                return;
            }

            if (proximoStatus.length > 0 && verificacao) {
                let data = {
                    'Id': 0,
                    'IdSolicitacaoFornecimentoVersao': parseInt(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id),
                    'IdStatusSolicitacaoFornecimento': proximoStatus[0].id,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'ObservacaoReprovado': '',
                    'StatusAprovado': false,
                    'StatusReprovado': false,
                    'ObservacaoCancelado': '',
                    'AnaliseConcluida': false,
                    'IdSolicitacaoFornecimento': id,
                    'DataOrcamentoSolicitado': moment((new Date())).add('hours', 2),
                    'IdUsuarioOrcamentoSolicitado': usuario.id
                }

                dispatch(InserirSolicitacaoFornecimentoVersaoStatusRequest(data, id, true));
            }
        }
        // Cria o próximo status
        else {

            if (proximoStatus.length > 0) {
                let data = {
                    'Id': 0,
                    'IdSolicitacaoFornecimentoVersao': parseInt(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id),
                    'IdStatusSolicitacaoFornecimento': proximoStatus[0].id,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'ObservacaoReprovado': '',
                    'StatusAprovado': false,
                    'StatusReprovado': false,
                    'ObservacaoCancelado': '',
                    'AnaliseConcluida': false,
                    'IdSolicitacaoFornecimento': id
                }

                dispatch(InserirSolicitacaoFornecimentoVersaoStatusRequest(data, id, true));
            }
        }

        setStatusAnaliseAcao('');
        handleCloseStatus();
    }

    // Insere o status cancelado na solicitação
    function InserirSolicitacaoStatusCancelado(e) {

        e.preventDefault();

        var verificacao = true;

        // Verificar se é cliente
        if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
            toast.error("Você precisa ser cliente para cancelar a solicitação!");
            verificacao = false;
            return;
        }

        // Busca o status cancelado
        let proximoStatus = [];

        if (listaStatusSolicitacaoFornecimentoCompleto != undefined) {
            proximoStatus = listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true);
        }

        if (verificacao && proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdSolicitacaoFornecimentoVersao': parseInt(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id),
                'IdStatusSolicitacaoFornecimento': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'ObservacaoReprovado': '',
                'StatusAprovado': false,
                'StatusReprovado': false,
                'ObservacaoCancelado': descricaoObservacaoCancelado,
                'AnaliseConcluida': false,
                'IdSolicitacaoFornecimento': id
            }

            dispatch(InserirSolicitacaoFornecimentoVersaoStatusRequest(data, id, true));
            setValidated(false);
            setDescricaoObservacaoCancelado('');
            handleClose();
        }
    }

    // Análise de orçamento aprovada e abre o modal para confirmação
    function AprovarSolicitacaoStatusAnaliseOrcamento(statusParametro) {

        var verificacao = true;

        // Verifica se o usuario é cliente
        if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {

            toast.error("Para avançar o status é necessario ser cliente!");
            verificacao = false;
            return;
        }

        if (statusParametro != 0) {
            setStatus(statusParametro);
            setStatusAnaliseAcao('Aprovar');
            handleShowStatus();
        }
    }

    // Análise de orçamento reprovada e abre o modal para inserir observação e confirmação
    function ReprovarSolicitacaoStatusAnaliseOrcamento(statusParametro) {

        var verificacao = true;

        // Verifica se o usuario é cliente
        if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {

            toast.error("Para avançar o status é necessario ser cliente!");
            verificacao = false;
            return;
        }

        if (verificacao && statusParametro != 0) {
            setModal(false);
            setStatus(statusParametro);
            setStatusAnaliseAcao('Reprovar');
            handleShowStatusReprovado();
        }
    }

    // Cria nova versão da solicitação
    function CriarNovaVersaoSolicitacao() {

        if (!manterItens) {

            if (solicitacaoFornecimento != null &&
                solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null) {
                let dadosSolicitacaoFornecimentoVersaoApiModel = {
                    'Id': 0,
                    'IdSolicitacaoFornecimento': solicitacaoFornecimento.id,
                    'Observacao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.observacao,
                    'NumeroOrcamento': '',
                    'DataVersao': moment((new Date())).add('hours', 2),
                    'IdUsuario': usuario.id,
                    'SolicitacaoFornecimentoVersaoStatusApiModel': {
                        'Id': 0,
                        'IdSolicitacaoFornecimentoVersao': 0,
                        'IdStatusSolicitacaoFornecimento': 0,
                        'IdUsuario': usuario.id,
                        'DataStatus': moment((new Date())).add('hours', 2),
                        'ObservacaoReprovado': '',
                        'ObservacaoCancelado': '',
                        'StatusReprovado': false,
                        'StatusAprovado': false,
                        'AnaliseConcluida': false
                    }
                }

                dispatch(InserirSolicitacaoFornecimentoVersaoRequest(dadosSolicitacaoFornecimentoVersaoApiModel));
                setValidated(false);
                handleCloseNovaVersao();
            }
        }
        else {
            if (solicitacaoFornecimento != null &&
                solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null) {
                dispatch(ExcluirSolicitacaoFornecimentoVersaoStatusMantendoOsItensRequest(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id, solicitacaoFornecimento.id))
            }
        }

        setManterItens(false)
        setValidated(false);
        handleCloseNovaVersao();

    }

    // Abre o modal para confirmar o cancelamento da solicitação
    function CancelarSolicitacao(statusParametro) {
        setStatus(statusParametro);
        handleShow();
    }

    function ExcluirUltimoStatus() {
        dispatch(ExcluirSolicitacaoFornecimentoVersaoStatusRequest(solicitacaoFornecimento.id));
    }

    // Verificar se ao reprovar análise deve criar versão (só tem análise de orçamento por enquanto e ela cria nova versao)
    function VerificarTipoAnaliseReprovada(analiseTipoReprovarCriaVersao) {

        if (analiseTipoReprovarCriaVersao) {
            if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
                handleShowNovaVersao();
            } else {
                toast.error('Para refazer o orçamento é preciso ser fornecedor!');
            }
        }
    }

    function BaixarDocumento(rowData) {

        var idDoc = documentos != undefined &&
            documentos.filter(m => m.statusReprovado == true
                && m.idSolicitacaoFornecimentoVersaoStatus == relacaoIdStatus[0].id).length > 0 ?
            documentos.filter(m => m.statusReprovado == true
                && m.idSolicitacaoFornecimentoVersaoStatus == relacaoIdStatus[0].id)[0].id
            :
            ''

        dispatch(BaixarDocumentoRequest(idDoc));
    }

    function SalvarDocumento() {

        // Pega o status a ser inserido (próximo)
        let proximoStatus = [];

        if (listaStatusSolicitacaoFornecimentoCompleto != undefined) {
            proximoStatus = listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == status);
        }

        // Caso o status é análise e já está criado, busca o status para altera-lo
        let relacaoAlterarAnalise = [];

        if (statusAnaliseAcao != '' && proximoStatus.length > 0) {
            relacaoAlterarAnalise = listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento == proximoStatus[0].id)
        }

        let formData = new FormData();

        if (listaFile.length > 0 && statusAnaliseAcao == 'Reprovar' && relacaoAlterarAnalise.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdSolicitacao', id);
                formData.append('IdUsuario', usuario.id);
                formData.append('IdTipoDocumentoArquivo', parseInt(tipoDocumentoArquivo));
                formData.append('StatusReprovado', true);
                formData.append('IdSolicitacaoFornecimentoVersaoStatus', relacaoAlterarAnalise[0].id);
                if (descricaoObservacaoReprovado != '')
                    formData.append('Observacao', descricaoObservacaoReprovado);
            }
            setListaFile([]);
            dispatch(InserirSolicitacaoFornecimentoDocumentoArquivoRequest(formData, id));
            handleClose();
        } else {
            toast.error('Para continuar insira um documento');
        }
    }

    function FinalizarOrcamento() {

        // Pega o status a ser inserido (próximo)
        let proximoStatus = [];
        var verificacao = true;

        if (listaStatusSolicitacaoFornecimentoCompleto != undefined) {
            proximoStatus = listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.descricaoSolicitacaoFornecimentoVersao == finalizarOrcamento);
        }

        // Caso o status é orçamento e já está criado, busca o status para altera-lo
        let finalizarAnalise = [];

        if (proximoStatus.length > 0) {
            finalizarAnalise = listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento == proximoStatus[0].id)
        }

        // Verificar se é fornecedor
        if (usuarioCompleto != null && !usuarioCompleto.usuarioFornecedor) {
            toast.error("Você precisa ser fornecedor para finalizar o orçamento!");
            verificacao = false;
            return;
        }

        // Verifica número de subitens na solicitação
        if (listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined && solicitacaoFornecimento != null &&
            solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null && listaSolicitacaoFornecimentoVersaoSubitemCusto
                .filter((solicitacao) => solicitacao.idSolicitacaoFornecimentoVersao
                    === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id).length == 0) {

            toast.error("Para finalizar o orçamento é preciso que a solicitação tenha itens inseridos!");
            verificacao = false;
            return;
        }

        // Verifica se tem pelo menos 1 subitem ativo para continuar
        var ativo = false;
        var verificaAtivoLista = [];

        if (listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined && solicitacaoFornecimento != null &&
            solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null) {

            verificaAtivoLista = listaSolicitacaoFornecimentoVersaoSubitemCusto
                .filter((solicitacao) => solicitacao.idSolicitacaoFornecimentoVersao === solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id);
        }

        if (verificaAtivoLista.length > 0) {

            verificaAtivoLista.map(x => {
                if (x.ativo == true) {
                    ativo = true;
                }
            })

            if (ativo == false) {
                toast.error("Para fazer a análise é preciso que a solicitação tenha pelo menos 1 item ativo!");
                verificacao = false;
                return;
            }
        }

        if (verificacao && finalizarAnalise.length > 0) {
            let data = {
                'Id': finalizarAnalise[0].id,
                'IdSolicitacaoFornecimentoVersao': finalizarAnalise[0].idSolicitacaoFornecimentoVersao,
                'IdStatusSolicitacaoFornecimento': finalizarAnalise[0].idStatusSolicitacaoFornecimento,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'ObservacaoReprovado': descricaoObservacaoReprovado,
                'StatusAprovado': false,
                'StatusReprovado': false,
                'ObservacaoCancelado': '',
                'AnaliseConcluida': true,
                'IdSolicitacaoFornecimento': parseInt(id),
                'DataOrcamentoFinalizado': moment((new Date())).add('hours', 2),
                'IdUsuarioOrcamentoFinalizado': usuario.id,
                'DataOrcamentoSolicitado': finalizarAnalise[0].dataOrcamentoSolicitado,
                'IdUsuarioOrcamentoSolicitado': finalizarAnalise[0].idUsuarioOrcamentoSolicitado
            }
            setFinalizarOrcamento('');
            handleCloseFinalizarOrcamento();
            dispatch(AlterarSolicitacaoFornecimentoVersaoStatusRequest(data, id, true));
        }

        handleCloseFinalizarOrcamento();
    }

    // Análise de orçamento reprovada e abre o modal para inserir observação e confirmação
    function FinalizarOrcamentoConfirmacao(descricao) {

        setFinalizarOrcamento(descricao);
        handleShowFinalizarOrcamento();
    }

    function ArrumarData(rowData) {

        let resposta = '';

        if (rowData != undefined && rowData != null && rowData.dataOrcaSoli != null) {

            let dataCriacao = moment(rowData.dataOrcaSoli);
            let dataPrazo = dataCriacao.add('days', 30);
            let dataAtual = moment((new Date())).add('hours', 2);

            var diferencaPrazoEAtual = moment(moment(dataPrazo).diff(dataAtual));
            var diferencaAtualEPrazo = moment(moment(dataAtual).diff(dataPrazo));

            if (rowData.statusSolicitaOrcamento == true && rowData.analiseConcluida == false &&
                parseInt((moment.duration(diferencaPrazoEAtual).asHours())) > 0) {

                resposta = parseInt(moment.duration(diferencaPrazoEAtual).asDays()).toString() + ' dia(s) e ';
                resposta += (parseInt(moment.duration(diferencaPrazoEAtual).asHours()) - parseInt(moment.duration(diferencaPrazoEAtual).asDays()) * 24).toString() + 'hora(s)';

            } else if (rowData.statusSolicitaOrcamento == true && rowData.analiseConcluida == false &&
                parseInt((moment.duration(diferencaAtualEPrazo).asHours())) > 0) {

                resposta = parseInt(moment.duration(diferencaAtualEPrazo).asDays()).toString() + ' dia(s) e ';
                resposta += (parseInt(moment.duration(diferencaAtualEPrazo).asHours()) - parseInt(moment.duration(diferencaAtualEPrazo).asDays()) * 24).toString() + 'hora(s)';

            }

        }

        return resposta;
    }



    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                <div>
                                    <Row>
                                        <Col md='auto'>
                                            {
                                                // Verifica se a solicitação esta finalizada
                                                id != undefined && listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                                    listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0
                                                    ?
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        variant="success"
                                                        onClick={() => dispatch(ListarCompletoAutorizacaoFornecimentoComRedirecionamentoRequest(id))}
                                                        disabled={
                                                            listaUsuarioFornecedor != undefined && usuario != null && solicitacaoFornecimento != null
                                                                && usuarioCompleto != null ?

                                                                usuarioCompleto.usuarioFornecedor == false ? false :

                                                                    listaUsuarioFornecedor.filter(x => x.idFornecedor == solicitacaoFornecimento.idFornecedor
                                                                        && x.idUsuario == usuario.id).length > 0 ?
                                                                        false
                                                                        :
                                                                        true
                                                                :
                                                                true
                                                        }>
                                                        Ir para Autorização
                                                    </Button>
                                                    :
                                                    inserir == true ?
                                                        <Button
                                                            // Trava o botão se o usuário não tiver permissão ou a solicitação estiver nos status de finalizada ou cancelada.
                                                            disabled={
                                                                id != undefined && contratoCompleto != null && listaContratoUsuario != undefined &&
                                                                    listaSolicitacaoFornecimentoVersaoStatus != undefined &&
                                                                    listaStatusSolicitacaoFornecimentoCompleto != undefined
                                                                    ?
                                                                    // Verifica se o usuário tem permissão de cancelar a solicitação
                                                                    (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                        && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.cancelaSolicitacao === true).length === 0)
                                                                        ? true
                                                                        :
                                                                        // Verifica se a solicitação está cancelada ou finalizada
                                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                                                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0
                                                                            ||
                                                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                                                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0
                                                                            ?
                                                                            true
                                                                            :
                                                                            false
                                                                    :
                                                                    null
                                                            }
                                                            variant={"danger"}
                                                            style={{ width: '100%' }}
                                                            onClick={() => CancelarSolicitacao("Cancelado")}>
                                                            Cancelar Solicitação
                                                        </Button>
                                                        :
                                                        ''


                                            }
                                        </Col>
                                        <Col md='auto'>
                                            {usuarioCompleto != null && usuarioCompleto.grupoAcesso != null && usuarioCompleto.grupoAcesso.nomeGrupo == 'Super Administrador' ?
                                                <Button
                                                    variant={"danger"}
                                                    style={{ width: '100%' }}
                                                    onClick={() => ExcluirUltimoStatus()}>
                                                    Voltar status
                                                </Button>
                                                : ''
                                            }
                                        </Col>
                                    </Row>






                                </div>
                            }
                            columns={[
                                {
                                    title: 'Status', field: 'status', cellStyle: {
                                        width: '30%'
                                    }
                                },
                                {
                                    title: 'Nome', field: 'usuario', cellStyle: {
                                        width: '12.5%'
                                    }
                                },
                                {
                                    title: 'Data', field: 'dataInclusao', cellStyle: {
                                        width: '12.5%'
                                    }
                                },
                                {
                                    title: 'Data da Solicitação Orçamento', field: 'dataOrcamentoSolicitado', cellStyle: {
                                        width: '12.5%'
                                    }, render: rowData =>
                                        rowData.usuarioOrcamentoSolicitado != '' && rowData.statusSolicitaOrcamento ?
                                            rowData.dataOrcamentoSolicitado : ''
                                },
                                {
                                    title: 'Prazo Restante', field: 'prazoOrcamento', cellStyle: {
                                        width: '12.5%'
                                    }, render: rowData => ArrumarData(rowData)

                                },
                                {
                                    title: 'Ação', field: 'aprovacao', cellStyle: {
                                        width: '20%'
                                    }
                                }/*,
                                {
                                    title: 'Orçamento Solicitado (Cliente)', field: 'usuarioOrcamentoSolicitado', cellStyle: {
                                        width: '8%'
                                    },
                                    editable: 'never',
                                    render: rowData =>
                                        rowData.usuarioOrcamentoSolicitado != '' && rowData.statusSolicitaOrcamento ?
                                            <RiCheckboxCircleFill
                                                className="iconSubitemTrue"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={'Orçamento solicitado pelo cliente ' + rowData.usuarioOrcamentoSolicitado + ' na data: ' + rowData.dataOrcamentoSolicitado}
                                                trigger="hover focus"
                                            ></RiCheckboxCircleFill>
                                            :
                                            ''
                                },
                                {
                                    title: 'Orçamento Finalizado (Fornecedor)', field: 'usuarioOrcamentoFinalizado', cellStyle: {
                                        width: '8%'
                                    },
                                    editable: 'never',
                                    render: rowData =>
                                        rowData.analiseConcluida == true ?
                                            <RiCheckboxCircleFill
                                                className="iconSubitemTrue"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={'Orçamento feito pelo fornecedor ' + rowData.usuarioOrcamentoFinalizado + ' na data: ' + rowData.dataOrcamentoFinalizado}
                                                trigger="hover focus"
                                            ></RiCheckboxCircleFill>
                                            :
                                            ''
                                }*/
                            ]}
                            data={
                                // Lista de status que ja foram inseridos
                                listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined ?
                                    listaSolicitacaoFornecimentoVersaoStatus.map(status => {
                                        if (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0 &&
                                            status.statusSolicitacaoFornecimento != null && status.statusSolicitacaoFornecimento.statusAnalise &&
                                            status.statusAprovado != true && status.statusReprovado != true) {
                                            return null;
                                        }
                                        else {
                                            return {
                                                // Lista os status atuais
                                                observacaoCancelado: status.observacaoCancelado,
                                                observacaoReprovado: status.observacaoReprovado,
                                                usuarioOrcamentoFinalizado: status.idUsuarioOrcamentoFinalizado != null ? status.usuarioOrcamentoFinalizado.nome : '',
                                                usuarioOrcamentoSolicitado: status.idUsuarioOrcamentoSolicitado != null ? status.usuarioOrcamentoSolicitado.nome : '',
                                                dataOrcamentoSolicitado: moment(status.dataOrcamentoSolicitado).format('L'),
                                                dataOrcamentoFinalizado: moment(status.dataOrcamentoFinalizado).format('L'),
                                                dataOrcaSoli: status.dataOrcamentoSolicitado,
                                                analiseConcluida: status.analiseConcluida,
                                                statusSolicitaOrcamento: status.statusSolicitacaoFornecimento.solicitaOrcamentoSolicitacao,
                                                status:
                                                    status.statusSolicitacaoFornecimento != null &&
                                                        status.statusSolicitacaoFornecimento.cancelaSolicitacao == true ?
                                                        <div className="divStatusDanger">{status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao}

                                                        </div>
                                                        :
                                                        status.statusSolicitacaoFornecimento.statusAnalise ?
                                                            status.statusAprovado == true ?
                                                                <div className="divStatusGreen">{status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao} (Aprovada)</div>
                                                                :
                                                                status.statusReprovado == true ?
                                                                    <div className="divStatusDanger">{status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao} (Reprovada)

                                                                    </div>
                                                                    :
                                                                    <div className="divStatusNormal">{status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao}</div>
                                                            :
                                                            <div className="divStatusGreen">{status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao}</div>,



                                                usuario:
                                                    status.statusSolicitacaoFornecimento.statusAnalise ?
                                                        status.statusAprovado == true || status.statusReprovado == true ? status.usuario.nome : ''
                                                        :
                                                        status.usuario.nome,
                                                dataInclusao:
                                                    status.statusSolicitacaoFornecimento.statusAnalise ?
                                                        status.statusAprovado == true || status.statusReprovado == true
                                                            ? moment(status.dataStatus).format('L LTS') : ''
                                                        : moment(status.dataStatus).format('L LTS'),
                                                aprovacao:
                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                                        listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0 ?
                                                        status.statusSolicitacaoFornecimento.cancelaSolicitacao == true ?
                                                            <Button
                                                                variant="success"
                                                                onClick={handleShowNovaVersao}
                                                                disabled={
                                                                    listaContratoUsuario != undefined && contratoCompleto != undefined ?
                                                                        status.statusSolicitacaoFornecimento.permissaoCancelar == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.cancelaSolicitacao === true).length === 0) ? true : false
                                                                        :
                                                                        null
                                                                }
                                                            >Reativar Solicitação</Button>
                                                            :
                                                            ''
                                                        :
                                                        status.statusSolicitacaoFornecimento.statusAnalise ?
                                                            status.statusAprovado ?
                                                                ''
                                                                :
                                                                status.statusReprovado ?
                                                                    <Button variant="success"
                                                                        disabled={
                                                                            listaContratoUsuario != undefined && contratoCompleto != undefined ?
                                                                                status.statusSolicitacaoFornecimento.permissaoAnalisarOrcamento == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                    && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaOrcamentoSolicitacao === true).length === 0) ? true : false
                                                                                :
                                                                                null
                                                                        }
                                                                        onClick={() => VerificarTipoAnaliseReprovada(status.statusSolicitacaoFornecimento.statusAnaliseReprovarCriaVersao)}
                                                                    >Refazer {status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao.
                                                                        substring(status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao.lastIndexOf(" ") + 1)}</Button>
                                                                    :
                                                                    <Row>
                                                                        <Col md={5}>
                                                                            <Button
                                                                                variant="success"
                                                                                onClick={() => AprovarSolicitacaoStatusAnaliseOrcamento(listaStatusSolicitacaoFornecimentoCompleto.
                                                                                    filter(x => x.id == status.idStatusSolicitacaoFornecimento).length > 0 ? listaStatusSolicitacaoFornecimentoCompleto.
                                                                                        filter(x => x.id == status.idStatusSolicitacaoFornecimento)[0].descricaoSolicitacaoFornecimentoVersao : 0
                                                                                )}
                                                                                disabled={
                                                                                    listaContratoUsuario != undefined && contratoCompleto != undefined ?
                                                                                        status.statusSolicitacaoFornecimento.permissaoAnalisarOrcamento == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaOrcamentoSolicitacao === true).length === 0) ? true : false
                                                                                        :
                                                                                        null
                                                                                }
                                                                            > Aprovar {status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao.
                                                                                substring(status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao.lastIndexOf(" ") + 1)}</Button>
                                                                        </Col>
                                                                        <Col md={5}>
                                                                            <Button
                                                                                variant="danger"
                                                                                onClick={() => ReprovarSolicitacaoStatusAnaliseOrcamento(listaStatusSolicitacaoFornecimentoCompleto.
                                                                                    filter(x => x.id == status.idStatusSolicitacaoFornecimento).length > 0 ? listaStatusSolicitacaoFornecimentoCompleto.
                                                                                        filter(x => x.id == status.idStatusSolicitacaoFornecimento)[0].descricaoSolicitacaoFornecimentoVersao : 0
                                                                                )}
                                                                                disabled={
                                                                                    listaContratoUsuario != undefined && contratoCompleto != undefined ?
                                                                                        status.statusSolicitacaoFornecimento.permissaoAnalisarOrcamento == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaOrcamentoSolicitacao === true).length === 0) ? true : false
                                                                                        :
                                                                                        null
                                                                                }
                                                                            > Reprovar {status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao.
                                                                                substring(status.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao.lastIndexOf(" ") + 1)}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                            :
                                                            ''
                                            }
                                        }
                                        // Lista os status futuros (que faltam ser inseridos)
                                    }).filter(Boolean).concat(
                                        listaStatusSolicitacaoFornecimentoCompleto.map(statusSol => {
                                            if (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                                listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0
                                                ||
                                                (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true).length > 0 &&
                                                    listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true)[0].id).length > 0 &&
                                                    listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true)[0].id)[0].statusReprovado == true)
                                            ) {
                                                return null
                                            } else {
                                                if (statusSol.idStatusSolicitacaoAnterior == null || (listaSolicitacaoFornecimentoVersaoStatus.
                                                    filter(x => x.idStatusSolicitacaoFornecimento == statusSol.id))[0] != undefined) {
                                                    return null
                                                } else {
                                                    return {
                                                        status: <div className="divStatusNormal">{statusSol.descricaoSolicitacaoFornecimentoVersao}</div>,
                                                        usuario: '',
                                                        dataInclusao: '',
                                                        aprovacao:
                                                            listaSolicitacaoFornecimentoVersaoStatus.filter
                                                                (x => x.idStatusSolicitacaoFornecimento == statusSol.idStatusSolicitacaoAnterior).length > 0 &&
                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.id == statusSol.idStatusSolicitacaoAnterior).length > 0 &&
                                                                (listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.id == statusSol.idStatusSolicitacaoAnterior)[0].statusAnalise
                                                                    ?
                                                                    (listaSolicitacaoFornecimentoVersaoStatus.filter
                                                                        (x => x.idStatusSolicitacaoFornecimento == statusSol.idStatusSolicitacaoAnterior)[0].statusAprovado == true)
                                                                    :
                                                                    true)
                                                                ?
                                                                inserir == true
                                                                    ?
                                                                    <Button
                                                                        disabled={
                                                                            contratoCompleto != null && listaContratoUsuario != undefined ?
                                                                                statusSol.permissaoAnalisarOrcamento == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                    && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaOrcamentoSolicitacao === true).length === 0)
                                                                                    ||
                                                                                    statusSol.permissaoAprovar == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                        && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.aprovaSolicitacao === true).length === 0)
                                                                                    ||
                                                                                    statusSol.permissaoFinalizar == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                        && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.finalizaSolicitacao === true).length === 0)
                                                                                    ? true : false
                                                                                :
                                                                                null
                                                                        }
                                                                        variant="success"
                                                                        onClick={() => ValidarProximoStatus(statusSol.descricaoSolicitacaoFornecimentoVersao)}>
                                                                        {statusSol.statusAnalise == true ? 'Fazer Análise' :
                                                                            statusSol.solicitaOrcamentoSolicitacao ? 'Pedir Orçamento'
                                                                                :
                                                                                'Mudar Status'}
                                                                    </Button>
                                                                    :
                                                                    ''
                                                                :
                                                                ''
                                                    }
                                                }
                                            }
                                        }).filter(Boolean)
                                    )
                                    :
                                    []
                            }
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                filtering: true,
                                grouping: false,
                                rowStyle:
                                    rowData => ({
                                        backgroundColor:
                                            rowData.statusSolicitaOrcamento == true && parseInt((moment.duration(moment(moment((new Date())).add('hours', 2)).diff(moment(rowData.dataOrcaSoli)
                                                .add('days', 30))).asDays())) > 0 && rowData.analiseConcluida == false ? '#F7D1D1' :
                                                (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'download',
                                    onClick: (event, rowData) => BaixarDocumento(rowData),
                                    hidden: documentos != undefined && solicitacaoFornecimento != undefined && relacaoIdStatus.length > 0 &&
                                        documentos.filter(m => m.statusReprovado == true &&
                                            m.idSolicitacaoFornecimentoVersaoStatus == relacaoIdStatus[0].id &&
                                            solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.statusReprovado == true).length > 0 ?
                                        rowData.status.props.children.length > 1 && rowData.status.props.children[1] == ' (Reprovada)' ?
                                            false
                                            :
                                            true
                                        :
                                        true
                                }),
                                rowData => ({
                                    icon: 'error',
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Obervação Reprovado: ' + rowData.observacaoReprovado}</span>,
                                    hidden: rowData.observacaoReprovado == '' || rowData.observacaoReprovado == undefined ? true : false,
                                }),
                                rowData => ({
                                    icon: 'error',
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Obervação Cancelado: ' + rowData.observacaoCancelado}</span>,
                                    hidden: rowData.observacaoCancelado == '' || rowData.observacaoCancelado == undefined ? true : false,
                                }),
                                rowData => ({
                                    icon: (rowData) => <span><RiFolder3Fill size={20} color={"#000"} /></span>,
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Esta solicitação já possui registro fotográfico'}</span>,
                                    hidden: listaRegistroFotografico != undefined && listaRegistroFotografico.length > 0 &&
                                        rowData.status.props.children == "Inicial" ? false : true,
                                }),
                                rowData => ({
                                    icon: (rowData) => <span><RiFileListFill size={20} color={"#000"} /></span>,
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Esta solicitação já possui um número de ofício'}</span>,
                                    hidden: solicitacaoFornecimento != undefined && solicitacaoFornecimento.numeroOficio != undefined &&
                                        solicitacaoFornecimento.numeroOficio != null && solicitacaoFornecimento.numeroOficio != '' &&
                                        rowData.status.props.children == "Inicial" ? false : true,
                                }),
                                rowData => ({
                                    icon: (rowData) => <span><RiFileTextFill size={20} color={"#000"} /></span>,
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Esta solicitação já possui um arquivo do tipo ofício'}</span>,
                                    hidden: documentos != undefined && documentos.length > 0 && documentos.filter(t => t.tipoDocumentoArquivo.descricao == 'OFÍCIO').length > 0 &&
                                        rowData.status.props.children == "Inicial" ? false : true,
                                })
                            ]}
                            localization={{
                                header: {
                                    actions: 'Info'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: 'Pesquisar'
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                </Col>
            </div >

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Cancelar essa Solicitação?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricaoObservacaoCancelado}
                                        onChange={e => setDescricaoObservacaoCancelado(e.target.value)}
                                        required
                                        as="textarea"
                                        rows="5"
                                        maxLength="500"
                                        isInvalid={validated && descricaoObservacaoCancelado.trim() === "" ? true : false}
                                        isValid={validated && descricaoObservacaoCancelado.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={handleSubmit}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleClose}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatus} onHide={handleCloseStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Mudar de Status?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={InserirSolicitacaoStatus}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleCloseStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNovaVersao} onHide={handleCloseNovaVersao} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Ao avançar, sem o reaproveitamento dos itens, será criado uma nova versão da solicitação,
                                caso contrário, poderá selecionar a opção de manter os itens e a versão da solicitação, como deseja prosseguir?
                                <Form.Group as={Col} md={12}>
                                    <Form.Check
                                        size="lg"
                                        label="Manter Itens da Solicitação"
                                        type="switch"
                                        id="custom-switch"
                                        onChange={() => { setManterItens(!manterItens) }}
                                        checked={manterItens}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={CriarNovaVersaoSolicitacao}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleCloseNovaVersao}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFinalizarOrcamento} onHide={handleCloseFinalizarOrcamento} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você realmente deseja finalizar o orçamento?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={FinalizarOrcamento}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleCloseNovaVersao}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatusReprovado} onHide={handleCloseStatusReprovado} size={'lg'} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja reprovar essa análise?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricaoObservacaoReprovado}
                                        onChange={e => setDescricaoObservacaoReprovado(e.target.value)}
                                        required
                                        as="textarea"
                                        rows="5"
                                        maxLength="500"
                                        isInvalid={validatedReprovado && descricaoObservacaoReprovado.trim() === "" ? true : false}
                                        isValid={validatedReprovado && descricaoObservacaoReprovado.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                    <br />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12}>
                                        <div>
                                            <DropzoneArea
                                                initialFiles={[]}
                                                onChange={(files) => setListaFile(files)}
                                                filesLimit={1}
                                                showPreviewsInDropzone={true}
                                                useChipsForPreview
                                                getFileLimitExceedMessage={() => `Só é permitido adicionar 1 arquivo`}
                                                getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                                getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                                disableRejectionFeedback={true}
                                                dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                maxFileSize={30000000}
                                            />
                                        </div>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12}>
                                        <Form.Label>Tipo documento arquivo</Form.Label>
                                        <Form.Select
                                            onChange={(e) => setTipoDocumentoArquivo(e.target.value)}
                                            value={tipoDocumentoArquivo}
                                            isValid={validated && tipoDocumentoArquivo !== undefined
                                                || validated && tipoDocumentoArquivo !== '' ? true : false}>
                                            <option value="">Selecione...</option>
                                            {
                                                listaTipoDocumentoArquivo != undefined ?
                                                    listaTipoDocumentoArquivo.map(m => (
                                                        <option value={m.id} key={m.id}>{m.descricao}</option>
                                                    ))
                                                    :
                                                    null
                                            }

                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={handleSubmitReprovadoComDocumento}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseStatusReprovado}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalVerificacaoSaldoSubitem
                show={showValidacao}
                onHide={handleCloseValidacao}
                solicitacao={true}
                medicao={false}
                empenho={false}
            />


        </>
    )
}