export function ListarSolicitacaoFornecimentoVersaoRequest() {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_REQUEST'
  }
}

export function ListarSolicitacaoFornecimentoVersaoResponse(listaSolicitacaoFornecimentoVersao) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_RESPONSE',
    listaSolicitacaoFornecimentoVersao
  }
}

export function InserirSolicitacaoFornecimentoVersaoRequest(data) {
    return {
      type: 'INSERIR_SOLICITACAO_FORNECIMENTO_VERSAO_REQUEST',
      data
    }
}

export function ListarCompletoSolicitacaoFornecimentoVersaoRequest(idSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_VERSAO_REQUEST', idSolicitacaoFornecimento
  }
}

export function ListarCompletoSolicitacaoFornecimentoVersaoResponse(listaCompletaSolicitacaoFornecimentoVersao) {
  return {
    type: 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_VERSAO_RESPONSE', listaCompletaSolicitacaoFornecimentoVersao
  }
}
  
export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}