import produce from 'immer';

const initialState = { listaStatusAutorizacaoFornecimento: undefined,
  carregando: true };

export default function tipoAutorizacao(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_STATUS_AUTORIZACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaStatusAutorizacaoFornecimento = action.listaStatusAutorizacaoFornecimento;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}