import produce from 'immer';

const initialState = {
    listaSolicitacaoFornecimentoCaracteristicaResposta: undefined,
    carregando: true
};

export default function solicitacaoFornecimentoCaracteristicaResposta(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_SOLICITACAO_FORNECIMENTO_CARACTERISTICA_RESPOSTA_RESPONSE':
            return produce(state, draft => {
                draft.listaSolicitacaoFornecimentoCaracteristicaResposta = action.listaSolicitacaoFornecimentoCaracteristicaResposta;
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}