import produce from 'immer';

const initialState = {
  listaRegiao: undefined,
  carregando: true
};

export default function regiao(state = initialState, action) {


  switch (action.type) {

    case 'LISTAR_REGIAO_RESPONSE':
      return produce(state, draft => {
        draft.listaRegiao = action.listaRegiao;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}