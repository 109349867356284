import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Alert, Button, Row, Accordion, Form, DropdownButton, Modal } from "react-bootstrap";
import { useHistory, useParams } from 'react-router-dom';
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import {
    ListarAutorizacaoFornecimentoSubitemCustoRequest,
    AlterarAutorizacaoFornecimentoSubitemCustoRequest
} from "../../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import { BuscarContratoVersaoPorIdRequest } from '../../../store/modules/contratoVersao/actions';
import { BuscarUsuarioPorIdRequest } from '../../../store/modules/usuario/actions';
import { toast } from "react-toastify";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ListarRelatorioMedicaoSubItemRequest } from "../../../store/modules/relatorio/actions";
import { ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest } from "../../../store/modules/relatorio/actions";
import { DropzoneArea } from 'material-ui-dropzone';
import { InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest } from "../../../store/modules/regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/actions";
import ModalSubitemAutorizacao from "../../../components/ModalSubitemAutorizacao/modalSubitemAutorizacao";
import ModalRegFotoUltrapassarSubitem from "../../../components/ModalRegFotoUltrapassarSubitem/modalRegFotoUltrapassarSubitem";
import { ImFilePicture } from "react-icons/im";
import Select from 'react-select';
import MyDoc from '../../../components/Pdf/pdf';
import './subitem.css';

export default function Subitem() {

    const { idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const listaRelatorioAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.relatorio.listaRelatorioAutorizacaoFornecimentoSubitemCusto);
    const carregando = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.carregando);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const listaAutorizacaoFornecimentoStatus = useSelector(state => state.autorizacaoFornecimentoStatus.listaAutorizacaoFornecimentoStatus);
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const listaRelatorioMedicaoSubItem = useSelector(state => state.relatorio.listaRelatorioMedicaoSubItem);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const contratoVersao = useSelector(state => state.contratoVersao.contratoVersaoCompleto);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const listaMedicaoAutorizacaoFornecimento = useSelector(state => state.medicaoAutorizacaoFornecimento.listaMedicaoAutorizacaoFornecimento);

    const [dados, setDados] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [show, setShow] = useState(false);
    const [showFoto, setShowFoto] = useState(false);
    const [showVisualizacaoFoto, setShowVisualizacaoFoto] = useState(false);

    const [codDescSubitem, setCodDescSubitem] = useState(true);
    const [quantidadeAutorizacao, setQuantidadeAutorizacao] = useState(true);
    const [quantidadeEmpenhada, setQuantidadeEmpenhada] = useState(true);
    const [valorUnitarioSubitem, setValorUnitarioSubitem] = useState(false);
    const [valorTotalSubitem, setValorTotalSubitem] = useState(false);
    const [valorTotalEmpenhado, setValorTotalEmpenhado] = useState(false);
    const [completado, setCompletado] = useState(false);
    const [saldoQuantidadeAutorizacao, setSaldoQuantidadeAutorizacao] = useState(false);
    const [saldoQuantidadeContratoExc, setSaldoQuantidadeContratoExc] = useState(false);
    const [quantidadeSolicitacao, setQuantidadeSolicitacao] = useState(false);
    const [unidadeMedida, setUnidadeMedida] = useState(false);
    const [cancelado, setCancelado] = useState(true);
    const [finalizado, setFinalizado] = useState(true);
    const [inseridoAposSoli, setInseridoAposSoli] = useState(false);
    const [quantidadeContrato, setQuantidadeContrato] = useState(false);
    const [valorObra, setValorObra] = useState('');
    const [valorObraEAdicionados, setValorObraEAdicionados] = useState('');
    const [observacao, setObservacao] = useState('');
    const [listaFile, setListaFile] = useState([]);
    const [dataAutorizacaoSubitem, setDataAutorizacaoSubitem] = useState([]);
    const [dataId, setDataId] = useState([]);
    const [valorMedicoes, setValorMedicoes] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseFoto = () => setShowFoto(false);
    const handleShowFoto = () => setShowFoto(true);

    const handleCloseVisualizacaoFoto = () => setShowVisualizacaoFoto(false);
    const handleShowVisualizacaoFoto = () => setShowVisualizacaoFoto(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r === "AutorizacaoFornecimentoVerValores").length === 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r === "AutorizacaoFornecimentoInserir").length === 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r === "AutorizacaoFornecimentoAlterar").length === 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r === "AutorizacaoFornecimentoExcluir").length === 1) {
            setExcluir(true);
        }

    }, [])

    const options = contratoVersao ?
        contratoVersao.subitemCusto.map(subitemUnidade => ({
            value: subitemUnidade.id, label: subitemUnidade.descricaoCodigoInterno + " - " + subitemUnidade.descricao
        }))
        :
        [];

    useEffect(() => {
        var data = {
            'IdAutorizacaoFornecimento': idAutorizacaoFornecimento
        }
        dispatch(ListarRelatorioMedicaoSubItemRequest(data));
    }, [idAutorizacaoFornecimento])

    useEffect(() => {
        
        var medicoes = 0;

        if (listaMedicaoAutorizacaoFornecimento != undefined) {


            listaMedicaoAutorizacaoFornecimento.map(lista => {

                medicoes += lista.valorTotalMedicao
            })

        }
        setValorMedicoes(medicoes.toLocaleString('pt-br', {
            style: 'currency', currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }));

    }, [listaMedicaoAutorizacaoFornecimento])
    
    useEffect(() => {

        if (usuario != null) {
            dispatch(BuscarUsuarioPorIdRequest(usuario.id));
        }

        if (idAutorizacaoFornecimento !== undefined) {

            var data = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento)
            }

            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));
            dispatch(ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest(idAutorizacaoFornecimento));
        }

    }, [])

    useEffect(() => {

        if (autorizacaoFornecimentoCompleto != null) {
            dispatch(BuscarContratoVersaoPorIdRequest(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.id));
        }

    }, [autorizacaoFornecimentoCompleto])

    useEffect(() => {

        var valorObraAtual = 0;
        var valorObraEAdicionadosAtual = 0;

        if (listaAutorizacaoFornecimentoSubitemCusto != undefined) {


            listaAutorizacaoFornecimentoSubitemCusto.map(lista => {

                if (!lista.inseridoAposSolicitacao) {

                    valorObraAtual += (lista.subitemCusto.valorUnitario * lista.quantidade)
                } else {
                    valorObraEAdicionadosAtual += (lista.subitemCusto.valorUnitario * lista.quantidade)
                }
            })

        }
        setValorObra(valorObraAtual.toLocaleString('pt-br', {
            style: 'currency', currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }));
        setValorObraEAdicionados((valorObraAtual + valorObraEAdicionadosAtual).toLocaleString('pt-br', {
            style: 'currency', currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }));

    }, [listaAutorizacaoFornecimentoSubitemCusto])

    function ValidacaoAlterarSubitemAutorizacao(autorizacaoFornecimentoSubitemCusto){

        var valor = valorObra.split('$', valorObra.length);
        let valorOrcamento = parseFloat(valor[1]);
        var valorAdicionados = valorObraEAdicionados.split('$', valorObraEAdicionados.length)
        let valorTotalAdicionados = parseFloat(valorAdicionados[1]);
        let saldoAutorizacaoTotal = 0;
        
        if(listaAutorizacaoFornecimentoSubitemCusto && listaAutorizacaoFornecimentoSubitemCusto
        .filter(x => x.id == autorizacaoFornecimentoSubitemCusto.id).length > 0 ){
            
            //pegar o subitem com os valores iniciais
            var autorizacaoFornecimentoSubitem = listaAutorizacaoFornecimentoSubitemCusto.filter(x => x.id == autorizacaoFornecimentoSubitemCusto.id)[0];

            //subtrair o antigo valor e somar o novo da quantidade e do saldo total da af
            valorTotalAdicionados -= autorizacaoFornecimentoSubitem.quantidade * 
                autorizacaoFornecimentoSubitem.subitemCusto.valorUnitario;

            valorTotalAdicionados += autorizacaoFornecimentoSubitemCusto.quantidade * 
                autorizacaoFornecimentoSubitem.subitemCusto.valorUnitario;

            saldoAutorizacaoTotal = autorizacaoFornecimentoSubitemCusto.saldoAutorizacaoTotal - autorizacaoFornecimentoSubitem.quantidade;

            saldoAutorizacaoTotal = saldoAutorizacaoTotal + autorizacaoFornecimentoSubitemCusto.quantidade;
            
            if(saldoAutorizacaoTotal > autorizacaoFornecimentoSubitem.saldoContratoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
                if(contratoVersao && valorOrcamento && valorTotalAdicionados){
                    let valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
                    if(valorTotalAdicionados > valorTotalExcedente){
                        toast.error('Item: ' + autorizacaoFornecimentoSubitem.subitemCusto.descricao + ' esta ultrapassando o saldo do contrato');
                        return;
                    }
                    else{
                        setObservacao(autorizacaoFornecimentoSubitemCusto.observacao)
                        setDataAutorizacaoSubitem(autorizacaoFornecimentoSubitemCusto)
                        handleShowFoto();
                        return;
                    }
                }
            }
            else{
                AlterarAutorizacaoFornecimentoSubitemCusto(autorizacaoFornecimentoSubitemCusto);
            }
        }

    }

    // Alterar subitem
    function AlterarAutorizacaoFornecimentoSubitemCustoComFoto(autorizacaoFornecimentoSubitemCusto) {

        // Valida campo quantidade
        if (isNaN(autorizacaoFornecimentoSubitemCusto.quantidade) || autorizacaoFornecimentoSubitemCusto.quantidade == undefined
            || autorizacaoFornecimentoSubitemCusto.quantidade == null) {
            toast.error(`Quantidade inválida`);
            return;
        }

        // Valida campo observacao
        if (autorizacaoFornecimentoSubitemCusto.observacao != undefined && autorizacaoFornecimentoSubitemCusto.observacao != null
            && autorizacaoFornecimentoSubitemCusto.observacao.length > 500) {
            toast.error(`Observação não pode conter mais que 500 caracteres`);
            return;
        }

        // Verificar permissões
        var lista = [];

        if (listaContratoUsuario !== undefined && autorizacaoFornecimentoCompleto != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null) {
            lista = listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)
        }

        if (lista.length > 0 && lista[0].editaQuantidadeSubitemAutorizacao === true) {

            let data = {
                'Id': autorizacaoFornecimentoSubitemCusto.id,
                'IdAutorizacaoFornecimento': autorizacaoFornecimentoSubitemCusto.autorizacaoFornecimento,
                'IdSubitemCusto': autorizacaoFornecimentoSubitemCusto.subitemCusto,
                'Quantidade': parseFloat(autorizacaoFornecimentoSubitemCusto.quantidade),
                'Cancelado': autorizacaoFornecimentoSubitemCusto.cancelado,
                'Finalizado': autorizacaoFornecimentoSubitemCusto.finalizado,
                'InseridoAposSolicitacao': autorizacaoFornecimentoSubitemCusto.inseridoAposSolicitacao,
                'InseridoAposSolicitacaoAprovado': autorizacaoFornecimentoSubitemCusto.inseridoAposSolicitacaoAprovado,
                'Observacao': autorizacaoFornecimentoSubitemCusto.observacao,
                'IdUsuario': autorizacaoFornecimentoSubitemCusto.idUsuario
            }

            // Coloca o data no dados, para ao abrir o modal, ter o objeto para inserir depois da confirmação
            setDados(data);

            dispatch(AlterarAutorizacaoFornecimentoSubitemCustoRequest(data, false));

            let formData = new FormData();

            if (listaFile.length > 0) {
                for (var x = 0; x < listaFile.length; x++) {
                    formData.append('File', listaFile[x]);
                    formData.append('IdAutorizacaoFornecimentoSubitemCusto', autorizacaoFornecimentoSubitemCusto.id);
                    formData.append('IdUsuario', usuario.id);
                    if (observacao != '')
                        formData.append('Observacao', observacao);
                }
                setListaFile([]);
                handleCloseFoto();
            } else {
                toast.error('Para continuar insira um documento');
            }

            dispatch(InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest(formData, autorizacaoFornecimentoSubitemCusto.id))

        } else {
            toast.error("Você não possui permissão para editar um item autorização!");
        }
    }

    // Alterar subitem
    function AlterarAutorizacaoFornecimentoSubitemCusto(autorizacaoFornecimentoSubitemCusto) {

        // Valida campo quantidade
        if (isNaN(autorizacaoFornecimentoSubitemCusto.quantidade) || autorizacaoFornecimentoSubitemCusto.quantidade == undefined
            || autorizacaoFornecimentoSubitemCusto.quantidade == null) {
            toast.error(`Quantidade inválida`);
            return;
        }

        // Valida campo observacao
        if (autorizacaoFornecimentoSubitemCusto.observacao != undefined && autorizacaoFornecimentoSubitemCusto.observacao != null
            && autorizacaoFornecimentoSubitemCusto.observacao.length > 500) {
            toast.error(`Observação não pode conter mais que 500 caracteres`);
            return;
        }

        // Verificar permissões
        var lista = [];

        if (listaContratoUsuario !== undefined && autorizacaoFornecimentoCompleto != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null) {
            lista = listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)
        }

        if (lista.length > 0 && lista[0].editaQuantidadeSubitemAutorizacao === true) {

            let data = {
                'Id': autorizacaoFornecimentoSubitemCusto.id,
                'IdAutorizacaoFornecimento': autorizacaoFornecimentoSubitemCusto.autorizacaoFornecimento,
                'IdSubitemCusto': autorizacaoFornecimentoSubitemCusto.subitemCusto,
                'Quantidade': parseFloat(autorizacaoFornecimentoSubitemCusto.quantidade),
                'Cancelado': autorizacaoFornecimentoSubitemCusto.cancelado,
                'Finalizado': autorizacaoFornecimentoSubitemCusto.finalizado,
                'InseridoAposSolicitacao': autorizacaoFornecimentoSubitemCusto.inseridoAposSolicitacao,
                'InseridoAposSolicitacaoAprovado': autorizacaoFornecimentoSubitemCusto.inseridoAposSolicitacaoAprovado,
                'Observacao': autorizacaoFornecimentoSubitemCusto.observacao,
                'IdUsuario': autorizacaoFornecimentoSubitemCusto.idUsuario
            }

            // Coloca o data no dados, para ao abrir o modal, ter o objeto para inserir depois da confirmação
            setDados(data);

            dispatch(AlterarAutorizacaoFornecimentoSubitemCustoRequest(data, false));
        } else {
            toast.error("Você não possui permissão para editar um item autorização!");
        }
    }

    function InserirSubitem() {

        // Verifica se o usuário tem permissão de inserir item na autorização
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.editaQuantidadeSubitemAutorizacao === true).length === 0) {

            toast.error('Você não tem permissão de cadastrar itens.');
            return;
        }

        handleShow();
    }

    function AprovarSubitem(autorizacaoFornecimentoSubitemCusto) {

        // Verificar permissões
        var lista = [];

        if (listaContratoUsuario !== undefined && autorizacaoFornecimentoCompleto != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null) {
            lista = listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)
        }

        if (lista.length > 0 && lista[0].editaQuantidadeSubitemAutorizacao === true) {

            let data = {
                'Id': autorizacaoFornecimentoSubitemCusto.id,
                'IdAutorizacaoFornecimento': autorizacaoFornecimentoSubitemCusto.autorizacaoFornecimento,
                'IdSubitemCusto': autorizacaoFornecimentoSubitemCusto.subitemCusto,
                'Quantidade': parseFloat(autorizacaoFornecimentoSubitemCusto.quantidade),
                'Cancelado': autorizacaoFornecimentoSubitemCusto.cancelado,
                'Finalizado': autorizacaoFornecimentoSubitemCusto.finalizado,
                'InseridoAposSolicitacao': autorizacaoFornecimentoSubitemCusto.inseridoAposSolicitacao,
                'InseridoAposSolicitacaoAprovado': true,
                'Observacao': autorizacaoFornecimentoSubitemCusto.observacao
            }

            // Coloca o data no dados, para ao abrir o modal, ter o objeto para inserir depois da confirmação
            setDados(data);

            dispatch(AlterarAutorizacaoFornecimentoSubitemCustoRequest(data, true));

        } else {
            toast.error("Você não possui permissão para editar um item autorização!");
        }
    }

    function AlterarValores(data) {
        setDataId(data);
        setShowVisualizacaoFoto(true);
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando ?
                            <div>
                                <Row style={{ marginLeft: "1em", alignItems: 'center' }}>
                                    <Col md='auto'>
                                        <DropdownButton variant="success" title="Colunas">
                                            <Form>
                                                <div className="tamanhoTela">
                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={codDescSubitem} onChange={() => setCodDescSubitem(!codDescSubitem)} />
                                                                <Form.Check.Label> Código/Descrição Item</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeAutorizacao} onChange={() => setQuantidadeAutorizacao(!quantidadeAutorizacao)} />
                                                                <Form.Check.Label> Quantidade Autorização</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeEmpenhada} onChange={() => setQuantidadeEmpenhada(!quantidadeEmpenhada)} />
                                                                <Form.Check.Label> Quantidade Empenhada</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorUnitarioSubitem} onChange={() => setValorUnitarioSubitem(!valorUnitarioSubitem)} />
                                                                <Form.Check.Label> Valor Unitário Item</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorTotalSubitem} onChange={() => setValorTotalSubitem(!valorTotalSubitem)} />
                                                                <Form.Check.Label> Valor Total Item</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorTotalEmpenhado} onChange={() => setValorTotalEmpenhado(!valorTotalEmpenhado)} />
                                                                <Form.Check.Label> Valor Total Empenhado</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={completado} onChange={() => setCompletado(!completado)} />
                                                                <Form.Check.Label> Completado</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={saldoQuantidadeAutorizacao} onChange={() => setSaldoQuantidadeAutorizacao(!saldoQuantidadeAutorizacao)} />
                                                                <Form.Check.Label> Saldo Quantidade Autorização</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={saldoQuantidadeContratoExc} onChange={() => setSaldoQuantidadeContratoExc(!saldoQuantidadeContratoExc)} />
                                                                <Form.Check.Label> Saldo Quantidade Contrato (excedente)</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeSolicitacao} onChange={() => setQuantidadeSolicitacao(!quantidadeSolicitacao)} />
                                                                <Form.Check.Label> Quantidade Solicitação</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={unidadeMedida} onChange={() => setUnidadeMedida(!unidadeMedida)} />
                                                                <Form.Check.Label> Unidade de Medida</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={cancelado} onChange={() => setCancelado(!cancelado)} />
                                                                <Form.Check.Label> Cancelado</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={finalizado} onChange={() => setFinalizado(!finalizado)} />
                                                                <Form.Check.Label> Finalizado</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={inseridoAposSoli} onChange={() => setInseridoAposSoli(!inseridoAposSoli)} />
                                                                <Form.Check.Label> Inserido Após Solicitação</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={quantidadeContrato} onChange={() => setQuantidadeContrato(!quantidadeContrato)} />
                                                                <Form.Check.Label>Quantidade Total Contrato (excedente)</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                </div>
                                            </Form>
                                        </DropdownButton>
                                    </Col>
                                    <Col md='auto'>
                                        <Row>
                                            <Col>
                                                <div className="App">
                                                    <PDFDownloadLink document={
                                                        <MyDoc
                                                            cabecalho={listaRelatorioMedicaoSubItem != undefined ? listaRelatorioMedicaoSubItem.listaCabecalho : null}
                                                            corpo={listaRelatorioMedicaoSubItem != undefined ? listaRelatorioMedicaoSubItem.listaCorpo : null}
                                                            corpoAdicionado={listaRelatorioMedicaoSubItem != undefined ? listaRelatorioMedicaoSubItem.listaCorpoAdicionado : null}
                                                            percentualObra={listaRelatorioMedicaoSubItem != undefined ? listaRelatorioMedicaoSubItem.percentualObra : null}
                                                        />} fileName="medicao.pdf">
                                                        <Button
                                                            variant="success"
                                                            style={{ width: '100%' }}>
                                                            Exportar Item
                                                        </Button>
                                                        {({ blob, url, loading, error }) => (loading ? 'Carregando subitems...' : 'Exportar como PDF')}
                                                    </PDFDownloadLink>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row><br />
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Itens Orçamento</Accordion.Header>
                                        <Accordion.Body>
                                            <MaterialTable
                                                components={{
                                                    Pagination: PatchedPagination,
                                                }}
                                                title=''
                                                columns={[
                                                    {
                                                        title: 'Código/Descrição Item', field: 'subitem',
                                                        editable: 'onAdd',
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={options}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        hidden: codDescSubitem ? false : true,
                                                        lookup:
                                                            contratoVersao ?
                                                                contratoVersao.subitemCusto.map(subitemUnidade => {
                                                                    return {
                                                                        id: subitemUnidade.id, descricao: subitemUnidade.descricaoCodigoInterno + " - " + subitemUnidade.descricao
                                                                    }
                                                                }).reduce(function (acc, cur, i) {
                                                                    acc[cur.id] = cur.descricao;
                                                                    return acc;
                                                                }, {})
                                                                :
                                                                null,
                                                        editComponent: (props) => (
                                                            <Select id="idSelectSubItem"
                                                                options={options}
                                                                placeholder="Selecione..."
                                                                defaultValue={{
                                                                    value: props.value, label: contratoVersao.subitemCusto.filter(soli => soli.id == props.value).length > 0
                                                                        ? contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricaoCodigoInterno + " - " + contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricao
                                                                        : 'Selecione...'
                                                                }}
                                                            />
                                                        ),
                                                    },
                                                    {
                                                        title: "Quantidade Autorização (total)", editable: 'never', field: 'quantidade', type: 'numeric', align: 'left',
                                                        validate: rowData => {
                                                            if (rowData.quantidade === undefined || rowData.quantidade === "" || isNaN(rowData.quantidade)) {
                                                                return "Campo Obrigatório"
                                                            } else if (rowData.quantidade.toString().length > 10) {
                                                                return "A quantidade não pode conter mais que 10 dígitos"
                                                            }
                                                            return true
                                                        },
                                                        render: rowData => rowData.quantidade + ' (' + rowData.quantidadeSubitemAutorizacaoTotal + ')',
                                                        hidden: quantidadeAutorizacao ? false : true,
                                                    },
                                                    {
                                                        title: 'Quantidade Empenhada', field: 'quantidadeConsumidaAutorizacao', editable: 'never',
                                                        hidden: quantidadeEmpenhada ? false : true,
                                                    },
                                                    {
                                                        title: 'Valor Unitário Item', field: 'valorUnitario', editable: 'never',
                                                        grouping: false,
                                                        hidden: valorUnitarioSubitem ? false : true,
                                                        render: rowData =>
                                                            rowData.valorUnitario === null ?
                                                                null
                                                                :
                                                                rowData.valorUnitario.toLocaleString('pt-br', {
                                                                    style: 'currency', currency: 'BRL',
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4
                                                                })
                                                    },
                                                    {
                                                        title: 'Valor Total Item', field: 'valorTotalSubitem', editable: 'never',
                                                        grouping: false,
                                                        hidden: valorTotalSubitem ? false : true,
                                                        render: rowData =>
                                                            rowData.valorTotalSubitem === null ?
                                                                null
                                                                :
                                                                rowData.valorTotalSubitem.toLocaleString('pt-br', {
                                                                    style: 'currency', currency: 'BRL',
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4
                                                                })
                                                    },
                                                    {
                                                        title: 'Valor Total Empenhado', field: 'valorTotalEmpenhado', editable: 'never',
                                                        hidden: valorTotalEmpenhado ? false : true,
                                                        grouping: false,
                                                        render: rowData =>
                                                            rowData.valorTotalEmpenhado === null ?
                                                                null
                                                                :
                                                                rowData.valorTotalEmpenhado.toLocaleString('pt-br', {
                                                                    style: 'currency', currency: 'BRL',
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4
                                                                })
                                                    },
                                                    {
                                                        title: 'Completado', field: 'completado', editable: 'never',
                                                        hidden: completado ? false : true,
                                                        render:
                                                            rowData =>
                                                                rowData.quantidadeConsumidaAutorizacao === null ?
                                                                    null
                                                                    :
                                                                    (rowData.quantidadeConsumidaAutorizacao / rowData.quantidadeSubitemAutorizacaoTotal).toLocaleString('pt-br', {
                                                                        style: 'percent',
                                                                        minimumFractionDigits: 4,
                                                                        maximumFractionDigits: 4
                                                                    })
                                                    },
                                                    {
                                                        title: 'Saldo Quantidade Total Autorização', field: 'saldoAutorizacaoTotal', editable: 'never',
                                                        hidden: saldoQuantidadeAutorizacao ? false : true,
                                                    },
                                                    {
                                                        title: 'Saldo Quantidade Contrato (excedente)', field: 'saldoContrato', editable: 'never',
                                                        hidden: saldoQuantidadeContratoExc ? false : true,
                                                        render: rowData => rowData.saldoContrato + ' (' + rowData.saldoContratoExcedente + ')'
                                                    },
                                                    {
                                                        title: 'Quantidade Solicitação', field: 'quantidadeSolicitacao', editable: 'never',
                                                        hidden: quantidadeSolicitacao ? false : true,
                                                    },
                                                    {
                                                        title: 'Unidade de Medida', field: 'unidadeMedida', editable: 'never',
                                                        hidden: unidadeMedida ? false : true,
                                                    },
                                                    {
                                                        title: "Cancelado", field: 'cancelado',
                                                        hidden: cancelado ? false : true,
                                                        customFilterAndSearch: (term, rowData) => term == rowData.cancelado.toString(),
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={[{ value: 'ativado', label: 'Sim' },
                                                                    { value: 'desativado', label: 'Não' },]}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                            e != null && e.value == 'desativado' ? 'false' : null)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        editComponent: (props) => (
                                                            <input
                                                                type="checkbox"
                                                                checked={props.value}
                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                            />
                                                        ),
                                                        render: rowData => (<input type="checkbox" checked={rowData.cancelado} onChange={() => { }} />)

                                                    },
                                                    {
                                                        title: "Finalizado", field: 'finalizado',
                                                        hidden: finalizado ? false : true,
                                                        customFilterAndSearch: (term, rowData) => term == rowData.finalizado.toString(),
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={[{ value: 'ativado', label: 'Sim' },
                                                                    { value: 'desativado', label: 'Não' },]}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                            e != null && e.value == 'desativado' ? 'false' : null)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        editComponent: (props) => (
                                                            <input
                                                                type="checkbox"
                                                                checked={props.value}
                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                            />
                                                        ),
                                                        render: rowData => (<input type="checkbox" checked={rowData.finalizado} onChange={() => { }} />)

                                                    },
                                                    {
                                                        title: "Inserido Após Solicitação", field: 'inseridoAposSolicitacao', editable: 'never',
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={[{ value: 'sim', label: 'Sim' },
                                                                    { value: 'não', label: 'Não' },]}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'sim' ? 'true' : false)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        hidden: inseridoAposSoli ? false : true,
                                                        render: rowData => rowData.inseridoAposSolicitacao ? 'Sim' : 'Não'
                                                    },
                                                    {
                                                        title: 'Quantidade Total Contrato (excedente)', field: 'quantidadeContrato', editable: 'never',
                                                        hidden: quantidadeContrato ? false : true,
                                                        render: rowData => rowData.quantidadeContrato + ' (' + rowData.quantidadeContratoExcedente + ')'
                                                    }
                                                ]}
                                                data={
                                                    listaAutorizacaoFornecimentoSubitemCusto != undefined ?
                                                        listaAutorizacaoFornecimentoSubitemCusto.map(lista => {
                                                            if (!lista.inseridoAposSolicitacao) {
                                                                return {
                                                                    id: lista.id,
                                                                    autorizacaoFornecimento: lista.idAutorizacaoFornecimento,
                                                                    subitem: lista.subitemCusto.id,
                                                                    subitemCusto: lista.idSubitemCusto,
                                                                    codigoInterno: lista.subitemCusto.descricaoCodigoInterno,
                                                                    quantidadeSolicitacao: lista.quantidadeSubitemSolicitacao,
                                                                    saldoContrato: lista.saldo,
                                                                    quantidade: lista.quantidade,
                                                                    unidadeMedida: lista.subitemCusto.unidadeMedida != undefined ? lista.subitemCusto.unidadeMedida.descricao : '',
                                                                    cancelado: lista.cancelado,
                                                                    finalizado: lista.finalizado,
                                                                    observacao: lista.observacao,
                                                                    inseridoAposSolicitacao: lista.inseridoAposSolicitacao,
                                                                    saldoAutorizacao: lista.saldoAutorizacao,
                                                                    saldoContratoExcedente: lista.saldoContratoExcedente,
                                                                    quantidadeConsumidaAutorizacao: lista.quantidadeConsumidaAutorizacao,
                                                                    valorUnitario: lista.subitemCusto.valorUnitario,
                                                                    valorTotalEmpenhado: lista.subitemCusto.valorUnitario * lista.quantidadeConsumidaAutorizacao,
                                                                    valorTotalSubitem: lista.subitemCusto.valorUnitario * lista.quantidadeSubitemAutorizacaoTotal,
                                                                    quantidadeSubitemAutorizacaoTotal: lista.quantidadeSubitemAutorizacaoTotal,
                                                                    saldoAutorizacaoTotal: lista.saldoAutorizacaoTotal,
                                                                    idUsuario: lista.idUsuario,
                                                                    quantidadeContrato: lista.quantidadeContrato,
                                                                    quantidadeContratoExcedente: lista.quantidadeContratoExcedente
                                                                }
                                                            } else {
                                                                return null;
                                                            }

                                                        }).filter(Boolean)
                                                        :
                                                        []
                                                }
                                                options={{
                                                    minBodyHeight: 500,
                                                    actionsCellStyle: {
                                                        width: '5%',
                                                    },
                                                    paging: true,
                                                    pageSize: 10, // Início
                                                    emptyRowsWhenPaging: false,
                                                    pageSizeOptions: [10, 50, 200, 300],
                                                    filterRowStyle: {
                                                        backgroundColor: "#FAFAFA"
                                                    },
                                                    headerStyle: {
                                                        backgroundColor: '#454545',
                                                        color: '#FFF',
                                                        fontWeight: "bold"
                                                    },
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    filtering: true,
                                                    rowStyle:
                                                        rowData => ({
                                                            backgroundColor:
                                                                rowData.quantidade - rowData.quantidadeConsumidaAutorizacao > rowData.saldoContratoExcedente && listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length == 0 ? '#F7D1D1' :
                                                                    (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        })
                                                }}
                                                editable={{
                                                    onRowUpdate:
                                                        idAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimentoStatus != undefined && listaAutorizacaoFornecimentoStatus
                                                        [listaAutorizacaoFornecimentoStatus.length - 1] != undefined && listaAutorizacaoFornecimentoStatus
                                                        [listaAutorizacaoFornecimentoStatus.length - 1].statusAutorizacaoFornecimento.somenteLeitura == false && usuarioCompleto != null &&
                                                            usuarioCompleto.usuarioFornecedor == false
                                                            ?
                                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                alterar == true ?
                                                                    setTimeout(() => {
                                                                        AlterarAutorizacaoFornecimentoSubitemCusto(newRow);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            })
                                                            :
                                                            null

                                                }}
                                                actions={[
                                                    rowData => ({
                                                        icon: 'error',
                                                        tooltip: <span style={{ fontSize: '1.5em' }}>
                                                            {'Quantidade solicitação excedida: ' + (rowData.quantidadeSubitemAutorizacaoTotal - rowData.quantidadeSolicitacao).toFixed(2)}
                                                        </span>,
                                                        hidden: (rowData.inseridoAposSolicitacao) || (rowData.quantidadeSubitemAutorizacaoTotal - rowData.quantidadeSolicitacao <= 0) ? true : false
                                                    }),
                                                    rowData => ({
                                                        icon: 'error',
                                                        tooltip: <span style={{ fontSize: '1.5em' }}>
                                                            {'Quantidade contrato que pode ser excedida: ' + (rowData.quantidadeSubitemAutorizacaoTotal - rowData.saldoContrato).toFixed(2)}
                                                        </span>,
                                                        hidden: rowData.saldoAutorizacaoTotal - rowData.saldoContratoExcedente <= 0 || listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                            listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                            listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0 ? true : false
                                                    }),
                                                    rowData => ({
                                                        //hidden: rowData.saldoAutorizacaoTotal > rowData.saldoContratoExcedente ? false : true,
                                                        icon: () => <ImFilePicture />,
                                                        tooltip: 'Fotos',
                                                        onClick: (event, rowData) => { AlterarValores(rowData.id) }
                                                    })
                                                ]}
                                                localization={{
                                                    header: {
                                                        actions: <span className="classHeaderTabela">Ações</span>
                                                    },
                                                    body: {
                                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                        editRow: {
                                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                            saveTooltip: '',
                                                            cancelTooltip: '',
                                                        },
                                                        deleteTooltip: '',
                                                        editTooltip: '',
                                                        addTooltip: ''
                                                    },
                                                    toolbar: {
                                                        searchTooltip: '',
                                                        searchPlaceholder: 'Pesquisar',
                                                        exportTitle: "",
                                                        exportCSVName: "Exportar como CSV",
                                                        exportPDFName: "Exportar como PDF",
                                                    },
                                                    pagination: {
                                                        labelRowsSelect: 'linhas',
                                                        labelDisplayedRows: '{count} de {from}-{to}',
                                                        firstTooltip: '',
                                                        previousTooltip: '',
                                                        nextTooltip: '',
                                                        lastTooltip: ''
                                                    },
                                                    grouping: {
                                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                                        groupedBy: 'Agrupado por:'
                                                    }
                                                }}
                                            />

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Itens Adicionados</Accordion.Header>
                                        <Accordion.Body>
                                            <MaterialTable
                                                components={{
                                                    Pagination: PatchedPagination,
                                                }}
                                                title=""/*{
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    variant="success"
                                                                    onClick={InserirSubitem}
                                                                    style={{ width: '100%' }}
                                                                    disabled={
                                                                        listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                                            listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0 &&
                                                                            listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                                listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0 ?
                                                                            true :
                                                                            listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                                                listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                                                listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0
                                                                                ?
                                                                                true :
                                                                                false
                                                                    }
                                                                >Novo Item
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }*/
                                                columns={[
                                                    {
                                                        title: 'Código/Descrição Item', field: 'subitem',
                                                        editable: 'onAdd',
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={options}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        hidden: codDescSubitem ? false : true,
                                                        lookup:
                                                            contratoVersao ?
                                                                contratoVersao.subitemCusto.map(subitemUnidade => {
                                                                    return {
                                                                        id: subitemUnidade.id, descricao: subitemUnidade.descricaoCodigoInterno + " - " + subitemUnidade.descricao
                                                                    }
                                                                }).reduce(function (acc, cur, i) {
                                                                    acc[cur.id] = cur.descricao;
                                                                    return acc;
                                                                }, {})
                                                                :
                                                                null,
                                                        editComponent: (props) => (
                                                            <Select id="idSelectSubItem"
                                                                options={options}
                                                                placeholder="Selecione..."
                                                                defaultValue={{
                                                                    value: props.value, label: contratoVersao.subitemCusto.filter(soli => soli.id == props.value).length > 0
                                                                        ? contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricaoCodigoInterno + " - " + contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricao
                                                                        : 'Selecione...'
                                                                }}
                                                            />
                                                        ),
                                                    },
                                                    {
                                                        title: "Quantidade Autorização (total)", editable: 'never', field: 'quantidade', type: 'numeric', align: 'left',
                                                        validate: rowData => {
                                                            if (rowData.quantidade === undefined || rowData.quantidade === "" || isNaN(rowData.quantidade)) {
                                                                return "Campo Obrigatório"
                                                            } else if (rowData.quantidade.toString().length > 10) {
                                                                return "A quantidade não pode conter mais que 10 dígitos"
                                                            }
                                                            return true
                                                        },
                                                        render: rowData => rowData.quantidade + ' (' + rowData.quantidadeSubitemAutorizacaoTotal + ')',
                                                        hidden: quantidadeAutorizacao ? false : true,
                                                    },
                                                    {
                                                        title: 'Quantidade Empenhada', field: 'quantidadeConsumidaAutorizacao', editable: 'never',
                                                        hidden: quantidadeEmpenhada ? false : true,
                                                    },
                                                    {
                                                        title: 'Valor Unitário Item', field: 'valorUnitario', editable: 'never',
                                                        grouping: false,
                                                        hidden: valorUnitarioSubitem ? false : true,
                                                        render: rowData =>
                                                            rowData.valorUnitario === null ?
                                                                null
                                                                :
                                                                rowData.valorUnitario.toLocaleString('pt-br', {
                                                                    style: 'currency', currency: 'BRL',
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4
                                                                })
                                                    },
                                                    {
                                                        title: 'Valor Total Item', field: 'valorTotalSubitem', editable: 'never',
                                                        grouping: false,
                                                        hidden: valorTotalSubitem ? false : true,
                                                        render: rowData =>
                                                            rowData.valorTotalSubitem === null ?
                                                                null
                                                                :
                                                                rowData.valorTotalSubitem.toLocaleString('pt-br', {
                                                                    style: 'currency', currency: 'BRL',
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4
                                                                })
                                                    },
                                                    {
                                                        title: 'Valor Total Empenhado', field: 'valorTotalEmpenhado', editable: 'never',
                                                        hidden: valorTotalEmpenhado ? false : true,
                                                        grouping: false,
                                                        render: rowData =>
                                                            rowData.valorTotalEmpenhado === null ?
                                                                null
                                                                :
                                                                rowData.valorTotalEmpenhado.toLocaleString('pt-br', {
                                                                    style: 'currency', currency: 'BRL',
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4
                                                                })
                                                    },
                                                    {
                                                        title: 'Completado', field: 'completado', editable: 'never',
                                                        hidden: completado ? false : true,
                                                        render:
                                                            rowData =>
                                                                rowData.quantidadeConsumidaAutorizacao === null ?
                                                                    null
                                                                    :
                                                                    (rowData.quantidadeConsumidaAutorizacao / rowData.quantidadeSubitemAutorizacaoTotal).toLocaleString('pt-br', {
                                                                        style: 'percent',
                                                                        minimumFractionDigits: 4,
                                                                        maximumFractionDigits: 4
                                                                    })
                                                    },
                                                    {
                                                        title: 'Saldo Quantidade Total Autorização', field: 'saldoAutorizacaoTotal', editable: 'never',
                                                        hidden: saldoQuantidadeAutorizacao ? false : true,
                                                    },
                                                    {
                                                        title: 'Saldo Quantidade Contrato (excedente)', field: 'saldoContrato', editable: 'never',
                                                        hidden: saldoQuantidadeContratoExc ? false : true,
                                                        render: rowData => rowData.saldoContrato + ' (' + rowData.saldoContratoExcedente + ')'
                                                    },
                                                    {
                                                        title: 'Quantidade Solicitação', field: 'quantidadeSolicitacao', editable: 'never',
                                                        hidden: quantidadeSolicitacao ? false : true,
                                                    },
                                                    {
                                                        title: 'Unidade de Medida', field: 'unidadeMedida', editable: 'never',
                                                        hidden: unidadeMedida ? false : true,
                                                    },
                                                    {
                                                        title: "Cancelado", field: 'cancelado',
                                                        editable: usuarioCompleto != null && usuarioCompleto.usuarioFornecedor ? 'never' : 'always',
                                                        hidden: cancelado ? false : true,
                                                        customFilterAndSearch: (term, rowData) => term == rowData.cancelado.toString(),
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={[{ value: 'ativado', label: 'Sim' },
                                                                    { value: 'desativado', label: 'Não' },]}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                            e != null && e.value == 'desativado' ? 'false' : null)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        editComponent: (props) => (
                                                            <input
                                                                type="checkbox"
                                                                checked={props.value}
                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                            />
                                                        ),
                                                        render: rowData => (<input type="checkbox" checked={rowData.cancelado} onChange={() => { }} />)

                                                    },
                                                    {
                                                        title: "Finalizado", field: 'finalizado',
                                                        editable: usuarioCompleto != null && usuarioCompleto.usuarioFornecedor ? 'never' : 'always',
                                                        hidden: finalizado ? false : true,
                                                        customFilterAndSearch: (term, rowData) => term == rowData.finalizado.toString(),
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={[{ value: 'ativado', label: 'Sim' },
                                                                    { value: 'desativado', label: 'Não' },]}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                            e != null && e.value == 'desativado' ? 'false' : null)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        editComponent: (props) => (
                                                            <input
                                                                type="checkbox"
                                                                checked={props.value}
                                                                onChange={(e) => props.onChange(e.target.checked)}
                                                            />
                                                        ),
                                                        render: rowData => (<input type="checkbox" checked={rowData.finalizado} onChange={() => { }} />)
                                                    },
                                                    {
                                                        title: 'Observação', field: 'observacao',
                                                        validate: rowData => {
                                                            if (rowData.observacao != null && rowData.observacao != undefined && rowData.observacao.length > 500) {
                                                                return "A observacao não pode conter mais que 500 dígitos"
                                                            }
                                                            return true
                                                        },
                                                        render: rowData => rowData.observacao == '' ?
                                                            <div className="spanVazio"></div>
                                                            : rowData.observacao,

                                                    },
                                                    {
                                                        title: "Inserido Após Solicitação", field: 'inseridoAposSolicitacao', editable: 'never',
                                                        filterComponent: (props) => {
                                                            return (
                                                                <Select
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={[{ value: 'sim', label: 'Sim' },
                                                                    { value: 'não', label: 'Não' },]}
                                                                    onChange={(e) => {
                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'sim' ? 'true' : false)
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                        hidden: inseridoAposSoli ? false : true,
                                                        render: rowData => rowData.inseridoAposSolicitacao ? 'Sim' : 'Não'
                                                    },
                                                    {
                                                        title: 'Quantidade Total Contrato (excedente)', field: 'quantidadeContrato', editable: 'never',
                                                        hidden: quantidadeContrato ? false : true,
                                                        render: rowData => rowData.quantidadeContrato + ' (' + rowData.quantidadeContratoExcedente + ')'
                                                    }
                                                ]}
                                                data={
                                                    listaAutorizacaoFornecimentoSubitemCusto != undefined ?
                                                        listaAutorizacaoFornecimentoSubitemCusto.map(lista => {

                                                            if (lista.inseridoAposSolicitacao) {
                                                                return {
                                                                    id: lista.id,
                                                                    autorizacaoFornecimento: lista.idAutorizacaoFornecimento,
                                                                    subitem: lista.subitemCusto.id,
                                                                    subitemCusto: lista.idSubitemCusto,
                                                                    codigoInterno: lista.subitemCusto.descricaoCodigoInterno,
                                                                    quantidadeSolicitacao: lista.quantidadeSubitemSolicitacao,
                                                                    saldoContrato: lista.saldo,
                                                                    quantidade: lista.quantidade,
                                                                    unidadeMedida: lista.subitemCusto.unidadeMedida != undefined ? lista.subitemCusto.unidadeMedida.descricao : '',
                                                                    cancelado: lista.cancelado,
                                                                    finalizado: lista.finalizado,
                                                                    observacao: lista.observacao,
                                                                    inseridoAposSolicitacao: lista.inseridoAposSolicitacao,
                                                                    saldoAutorizacao: lista.saldoAutorizacao,
                                                                    saldoContratoExcedente: lista.saldoContratoExcedente,
                                                                    quantidadeConsumidaAutorizacao: lista.quantidadeConsumidaAutorizacao,
                                                                    valorUnitario: lista.subitemCusto.valorUnitario,
                                                                    valorTotalEmpenhado: lista.subitemCusto.valorUnitario * lista.quantidadeConsumidaAutorizacao,
                                                                    valorTotalSubitem: lista.subitemCusto.valorUnitario * lista.quantidadeSubitemAutorizacaoTotal,
                                                                    inseridoAposSolicitacaoAprovado: lista.inseridoAposSolicitacaoAprovado,
                                                                    quantidadeSubitemAutorizacaoTotal: lista.quantidadeSubitemAutorizacaoTotal,
                                                                    saldoAutorizacaoTotal: lista.saldoAutorizacaoTotal,
                                                                    idUsuario: lista.idUsuario,
                                                                    quantidadeContrato: lista.quantidadeContrato,
                                                                    quantidadeContratoExcedente: lista.quantidadeContratoExcedente
                                                                }
                                                            } else {
                                                                return null;
                                                            }
                                                        }).filter(Boolean)
                                                        :
                                                        []
                                                }
                                                editable={{
                                                    onRowUpdate:
                                                        idAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimentoStatus != undefined && listaAutorizacaoFornecimentoStatus
                                                        [listaAutorizacaoFornecimentoStatus.length - 1] != undefined && listaAutorizacaoFornecimentoStatus
                                                        [listaAutorizacaoFornecimentoStatus.length - 1].statusAutorizacaoFornecimento.somenteLeitura == false
                                                            ?
                                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                alterar == true ?
                                                                    setTimeout(() => {
                                                                        AlterarAutorizacaoFornecimentoSubitemCusto(newRow);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            })
                                                            :
                                                            null

                                                }}
                                                options={{
                                                    minBodyHeight: 500,
                                                    actionsCellStyle: {
                                                        width: '5%',
                                                    },
                                                    paging: true,
                                                    pageSize: 10, // Início
                                                    emptyRowsWhenPaging: false,
                                                    pageSizeOptions: [10, 50, 200, 300],
                                                    filterRowStyle: {
                                                        backgroundColor: "#FAFAFA"
                                                    },
                                                    headerStyle: {
                                                        backgroundColor: '#454545',
                                                        color: '#FFF',
                                                        fontWeight: "bold",
                                                        lineBreak: "auto",
                                                        fontSize: "11px",
                                                    },
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    filtering: true,
                                                    rowStyle:
                                                        rowData => ({
                                                            backgroundColor:
                                                                rowData.quantidade - rowData.quantidadeConsumidaAutorizacao > rowData.saldoContratoExcedente && listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length == 0 ? '#F7D1D1' :
                                                                    (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        })
                                                }}
                                                actions={[
                                                    rowData => ({
                                                        icon: 'error',
                                                        tooltip: <span style={{ fontSize: '1.5em' }}>
                                                            {'Quantidade solicitação excedida: ' + (rowData.quantidadeSubitemAutorizacaoTotal - rowData.quantidadeSolicitacao)}
                                                        </span>,
                                                        hidden: (rowData.inseridoAposSolicitacao) || (rowData.quantidadeSubitemAutorizacaoTotal - rowData.quantidadeSolicitacao <= 0) ? true : false
                                                    }),
                                                    rowData => ({
                                                        icon: 'error',
                                                        tooltip: <span style={{ fontSize: '1.5em' }}>
                                                            {'Quantidade contrato que pode ser excedida: ' + (rowData.quantidadeSubitemAutorizacaoTotal - rowData.saldoContrato)}
                                                        </span>,
                                                        hidden: rowData.saldoAutorizacaoTotal - rowData.saldoContratoExcedente <= 0 || listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                            listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                            listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0 ? true : false
                                                    }),
                                                    rowData => ({
                                                        //hidden: rowData.saldoAutorizacaoTotal > rowData.saldoContratoExcedente ? false : true,
                                                        icon: () => <ImFilePicture />,
                                                        tooltip: 'Fotos',
                                                        onClick: (event, rowData) => { AlterarValores(rowData.id) }
                                                    })
                                                ]}
                                                style={{
                                                    fontSize: "11px",
                                                }}
                                                localization={{
                                                    header: {
                                                        actions: <span className="classHeaderTabela">Ações</span>
                                                    },
                                                    body: {
                                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                        editRow: {
                                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                            saveTooltip: '',
                                                            cancelTooltip: '',
                                                        },
                                                        deleteTooltip: '',
                                                        editTooltip: '',
                                                        addTooltip: ''
                                                    },
                                                    toolbar: {
                                                        searchTooltip: '',
                                                        searchPlaceholder: 'Pesquisar',
                                                        exportTitle: "",
                                                        exportCSVName: "Exportar como CSV",
                                                        exportPDFName: "Exportar como PDF",
                                                    },
                                                    pagination: {
                                                        labelRowsSelect: 'linhas',
                                                        labelDisplayedRows: '{count} de {from}-{to}',
                                                        firstTooltip: '',
                                                        previousTooltip: '',
                                                        nextTooltip: '',
                                                        lastTooltip: ''
                                                    },
                                                    grouping: {
                                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                                        groupedBy: 'Agrupado por:'
                                                    }
                                                }}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            :
                            <Loading />
                    }
                </Col>
                <Col md={12}>
                    <br />
                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                        <Form.Label>Valor do Orçamento</Form.Label>
                        <Form.Control
                            type="text"
                            value={valorObra}
                            disabled
                        />
                    </Form.Group> 
                    <br />
                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                        <Form.Label>Valor do Orçamento + Itens Adicionados</Form.Label>
                        <Form.Control
                            type="text"
                            value={valorObraEAdicionados}
                            disabled
                        />
                    </Form.Group>
                    <br />
                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                        <Form.Label>Valor Total Medições</Form.Label>
                        <Form.Control
                            type="text"
                            value={valorMedicoes}
                            disabled
                        />
                    </Form.Group>
                    <br />
                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                        <Form.Label>Valor Empenhado</Form.Label>
                        <Form.Control
                            type="text"
                            value={listaRelatorioAutorizacaoFornecimentoSubitemCusto != undefined && listaRelatorioAutorizacaoFornecimentoSubitemCusto != null
                                && listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual !== undefined ?
                                ((listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual).toLocaleString('pt-br', {
                                    style: 'currency', currency: 'BRL',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })) : ''}
                            disabled
                        />
                    </Form.Group>
                    <br />
                    {
                        //Verifica o status da autorização
                        idAutorizacaoFornecimento != undefined ?
                            listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0 &&
                                listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                    listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                        A Autorização está Cancelada!
                                    </Alert>
                                </Col>
                                :
                                listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            A Autorização está Finalizada!
                                        </Alert>
                                    </Col>
                                    :
                                    ''
                            :
                            ''
                    }
                </Col>
            </div>
            <ModalSubitemAutorizacao
                show={show}
                onHide={handleClose}
                idContratoVersao={autorizacaoFornecimentoCompleto != null ?
                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.id : null}
                idAutorizacaoFornecimento={idAutorizacaoFornecimento}
                totalOrcamento={valorObra}
                totalOrcamentoAdicionados={valorObraEAdicionados}
            />

            <Modal show={showFoto} onHide={handleCloseFoto}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Fotográfico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <span>A quantidade do subitem foi excedida além do permitido. Inclua uma foto e uma justificativa para esta ação</span><br/>
                            <Form.Group as={Col} md={12} controlId="formRegistroFotografico">
                                <div className="divModal"><br/>
                                    <DropzoneArea
                                        //initialFiles={[listaRegistroFotografico]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="success" disabled={listaFile.length > 0 ? false : true}
                        onClick={() => AlterarAutorizacaoFornecimentoSubitemCustoComFoto(dataAutorizacaoSubitem)}>Salvar Registro Fotográfico</Button>
                    }
                </Modal.Footer>
            </Modal>

            <ModalRegFotoUltrapassarSubitem
                showModal={showVisualizacaoFoto}
                onHide={handleCloseVisualizacaoFoto}
                idAutorizacaoFornecimentoSubitem={dataId}
                idAutorizacaoFornecimentoSubitemMedicao={null}
                data={dataId}
            />
        </>
    )
}