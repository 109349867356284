export function ListarSubitemCustoRequest() {
  return {
    type: 'LISTAR_SUBITEM_CUSTO_REQUEST'
  }
}

export function ListarSubitemCustoResponse(listaSubitemCusto) {
  return {
    type: 'LISTAR_SUBITEM_CUSTO_RESPONSE',
    listaSubitemCusto
  }
}

export function InserirSubitemCustoRequest(data, idContrato, idContratoVersao) {
  return {
    type: 'INSERIR_SUBITEM_CUSTO_REQUEST',
    data, idContrato, idContratoVersao
  }
}

export function AlterarSubitemCustoRequest(data, idContrato) {
  return {
    type: 'ALTERAR_SUBITEM_CUSTO_REQUEST',
    data, idContrato
  }
}

export function ExcluirSubitemCustoRequest(id) {
  return {
    type: 'EXCLUIR_SUBITEM_CUSTO_REQUEST',
    id
  }
}

export function ListarSubitemCustoCompletoRequest(data) {
  return {
    type: 'LISTAR_SUBITEM_CUSTO_COMPLETO_REQUEST',
    data
  }
}

export function ListarSubitemCustoCompletoResponse(listaCompletaSubitemCusto) {
  return {
    type: 'LISTAR_SUBITEM_CUSTO_COMPLETO_RESPONSE',
    listaCompletaSubitemCusto
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}