import produce from 'immer';

const initialState = {
  empenhoCompleto: null,
  listaEmpenho: undefined,
  carregando: true
};

export default function empenho(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_EMPENHO_RESPONSE':
      return produce(state, draft => {
        draft.listaEmpenho = action.listaEmpenho;
      });

    case 'BUSCAR_EMPENHO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.empenhoCompleto = action.empenhoCompleto;
      });

    case 'LIMPAR_EMPENHO':
      return produce(state, draft => {
        draft.empenhoCompleto = null
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}