export function ListarTipoPixRequest() {
    return {
        type: 'LISTAR_TIPO_PIX_REQUEST'
    }
}

export function ListarTipoPixResponse(listaTipoPix) {
    return {
        type: 'LISTAR_TIPO_PIX_RESPONSE',
        listaTipoPix
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}