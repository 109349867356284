export function ListarTipoSolicitacaoRequest() {
  return {
    type: 'LISTAR_TIPO_SOLICITACAO_REQUEST'
  }
}

export function ListarTipoSolicitacaoResponse(listaTipoSolicitacao) {
  return {
    type: 'LISTAR_TIPO_SOLICITACAO_RESPONSE',
    listaTipoSolicitacao
  }
}

export function BuscarTipoSolicitacaoRequest(id, idSolicitacaoFornecimento) {
  return {
    type: 'BUSCAR_TIPO_SOLICITACAO_REQUEST', id, idSolicitacaoFornecimento
  }
}

export function BuscarTipoSolicitacaoResponse(tipoSolicitacaoCompleto) {
  return {
    type: 'BUSCAR_TIPO_SOLICITACAO_RESPONSE',
    tipoSolicitacaoCompleto
  }
}

export function InserirTipoSolicitacaoRequest(data) {
  return {
    type: 'INSERIR_TIPO_SOLICITACAO_REQUEST',
    data
  }
}

export function AlterarTipoSolicitacaoRequest(data) {
  return {
    type: 'ALTERAR_TIPO_SOLICITACAO_REQUEST',
    data
  }
}

export function ExcluirTipoSolicitacaoRequest(id) {
  return {
    type: 'EXCLUIR_TIPO_SOLICITACAO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}