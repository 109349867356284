import { Row, Button, Modal, ModalFooter, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemRequest } from "../../store/modules/autorizacaoFornecimento/actions";
import { InserirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest } from "../../store/modules/empenhoAutorizacaoFornecimentoSubitemCustoMed/actions";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import { ListarUnidadeMedidaRequest } from '../../store/modules/unidadeMedida/actions';
import { toast } from "react-toastify";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalSubitemEmpenho({ idEmpenho, idUsuario, show, onHide, parcial }) {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const empenhoCompleto = useSelector(state => state.empenho.empenhoCompleto);
    const listaAutorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.listaCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem);
    const listaUnidadeMedida = useSelector(state => state.unidadeMedida.listaUnidadeMedida);


    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState('');
    const [listaSubitem, setListaSubitem] = useState([]);
    const [idAutorizacao, setIdAutorizacao] = useState('');
    const [showAviso, setShowAviso] = useState(false);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);

    const handleShowAviso = () => setShowAviso(true);
    const handleCloseAviso = () => setShowAviso(false);

    var listaAf = [];

    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "EmpenhoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarUnidadeMedidaRequest());

        if (id != undefined && empenhoCompleto != null)
            dispatch(ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemRequest(id, empenhoCompleto.idContrato, empenhoCompleto.idFornecedor));

    }, [empenhoCompleto])

    useEffect(() => {
        constroiHierarquia();
    }, [listaAutorizacaoFornecimento])

    function constroiHierarquia() {

        let hierarquiaCompleta = [];

        if (listaAutorizacaoFornecimento != undefined)

            if (listaAutorizacaoFornecimento != undefined && listaUnidadeMedida != undefined) {
                listaAutorizacaoFornecimento.map((autorizacaoFornecimento) => {

                    if (parcial && autorizacaoFornecimento.idAutorizacaoStatus != 6 ||
                        (parcial && autorizacaoFornecimento.idAutorizacaoStatus == 6 && autorizacaoFornecimento.finalizadaComMedicaoAnterior)) {
                        hierarquiaCompleta.push({
                            id: autorizacaoFornecimento.id,
                            numeroAf: autorizacaoFornecimento.numeroAf,
                            descricaoAutorizacao: autorizacaoFornecimento.observacaoAutorizacaoFornecimento,
                            entregaParcial: autorizacaoFornecimento.listaMedicaoFinalizada !== undefined && autorizacaoFornecimento.listaMedicaoFinalizada.length > 0 &&
                                autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                                autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0 ?
                                autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto[0].statusParcial
                                : null,
                            quantidade: null,
                            descricaoMedicao: null,
                            valorUnitario: null,
                            idInserirSubitem: null,
                            total: null,
                            codigoInterno: null,
                            descricao: null,
                            marcadorSubitem: false,
                            marcadorMedicao: false,
                            select: false
                        });
                        autorizacaoFornecimento.listaMedicaoFinalizada.map((medicao) => {
                            var x = ('M').concat(medicao.id)
                            hierarquiaCompleta.push({
                                id: x,
                                parentId: medicao.idAutorizacaoFornecimento,
                                descricaoMedicao: medicao.descricao,
                                idInserirSubitem: null,
                                descricaoAutorizacao: null,
                                valorUnitario: null,
                                codigoInterno: null,
                                total: null,
                                quantidade: null,
                                descricao: null,
                                marcadorSubitem: false,
                                marcadorMedicao: true,
                                select: false
                            });
                            medicao.listaSubitemCusto.map((subitemCustoMedicao) => {
                                var x = ('M').concat(subitemCustoMedicao.idMedicaoAutorizacaoFornecimento)
                                var y = ('S').concat(subitemCustoMedicao.id)
                                hierarquiaCompleta.push({
                                    id: y,
                                    descricaoAutorizacao: null,
                                    parentId: x,
                                    idInserirSubitem: subitemCustoMedicao.id,
                                    quantidade: subitemCustoMedicao.quantidadeFornecedor,
                                    codigoInterno: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricaoCodigoInterno,
                                    descricaoMedicao: null,
                                    valorUnitario: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario,
                                    total: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario * subitemCustoMedicao.quantidadeFornecedor,
                                    descricao: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricao,
                                    unidadeMedida: listaUnidadeMedida.filter(x => x.id == subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.idUnidadeMedida)[0].descricao,
                                    marcadorSubitem: true,
                                    marcadorMedicao: false,
                                    select: true
                                });
                            })
                        })
                    }
                    if (parcial == false && autorizacaoFornecimento.idAutorizacaoStatus == 6 && autorizacaoFornecimento.finalizadaComMedicaoAnterior == false) {
                        hierarquiaCompleta.push({
                            id: autorizacaoFornecimento.id,
                            numeroAf: autorizacaoFornecimento.numeroAf,
                            descricaoAutorizacao: autorizacaoFornecimento.observacaoAutorizacaoFornecimento,
                            entregaParcial: autorizacaoFornecimento.listaMedicaoFinalizada !== undefined && autorizacaoFornecimento.listaMedicaoFinalizada.length > 0 &&
                                autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto !== undefined &&
                                autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto.length > 0 ?
                                autorizacaoFornecimento.listaMedicaoFinalizada[0].listaEmpenhoAutorizacaoSubitemCusto[0].statusParcial
                                : null,
                            quantidade: null,
                            idInserirSubitem: null,
                            descricaoMedicao: null,
                            valorUnitario: null,
                            total: null,
                            codigoInterno: null,
                            descricao: null,
                            marcadorSubitem: false,
                            marcadorMedicao: false,
                            select: false
                        });
                        autorizacaoFornecimento.listaMedicaoFinalizada.map((medicao) => {
                            var x = ('M').concat(medicao.id)
                            hierarquiaCompleta.push({
                                id: x,
                                idInserirSubitem: null,
                                parentId: medicao.idAutorizacaoFornecimento,
                                descricaoMedicao: medicao.descricao,
                                descricaoAutorizacao: null,
                                valorUnitario: null,
                                codigoInterno: null,
                                total: null,
                                quantidade: null,
                                descricao: null,
                                marcadorSubitem: false,
                                marcadorMedicao: true,
                                select: false
                            });
                            medicao.listaSubitemCusto.map((subitemCustoMedicao) => {
                                var x = ('M').concat(subitemCustoMedicao.idMedicaoAutorizacaoFornecimento)
                                var y = ('S').concat(subitemCustoMedicao.id)
                                hierarquiaCompleta.push({
                                    id: y,
                                    idInserirSubitem: subitemCustoMedicao.id,
                                    descricaoAutorizacao: null,
                                    parentId: x,
                                    quantidade: subitemCustoMedicao.quantidadeFornecedor,
                                    codigoInterno: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricaoCodigoInterno,
                                    descricaoMedicao: null,
                                    valorUnitario: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario,
                                    total: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.valorUnitario * subitemCustoMedicao.quantidadeFornecedor,
                                    descricao: subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.descricao,
                                    unidadeMedida: listaUnidadeMedida.filter(x => x.id == subitemCustoMedicao.autorizacaoFornecimentoSubitemCusto.subitemCusto.idUnidadeMedida)[0].descricao,
                                    marcadorSubitem: true,
                                    marcadorMedicao: false,
                                    select: true
                                });
                            })
                        })
                    }
                })
            }

        setListaHierarquiaCompleta(hierarquiaCompleta);
    }

    function Selecionar(data) {
        setListaSubitem(data);
    }

    function InserirValidacaoEmpenhoSubitem() {

        if (listaSubitem != undefined && listaSubitem !== null && listaSubitem.length > 0 &&
            listaAutorizacaoFornecimento !== undefined && listaAutorizacaoFornecimento.length > 0 &&
            listaAutorizacaoFornecimento !== null && listaAutorizacaoFornecimento.filter(m => m.idAutorizacaoStatus !== 6 &&
                listaSubitem.filter(c => c.marcadorMedicao == false && c.marcadorSubitem == false && c.id == m.id).length > 0).length > 0) {
            handleShowAviso();
        }
        else {
            InserirEmpenhoSubitem();
        }
    }

    function InserirEmpenhoSubitem() {

        var listaSubitemInsercao = listaSubitem.filter(x => x.marcadorSubitem == true).map((subitem) => {
            return {
                Id: 0,
                IdAutorizacaoFornecimentoSubitemCustoMedicao: subitem.idInserirSubitem,
                DataInclusao: moment((new Date())).add('hours', 2),
                IdUsuario: parseInt(usuario.id),
                IdEmpenho: parseInt(id)
            }
        })

        dispatch(InserirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(listaSubitemInsercao, id, empenhoCompleto.idContrato, empenhoCompleto.idFornecedor));
        onHide(true);
        handleCloseAviso();
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        listaHierarquiaCompleta != undefined && listaHierarquiaCompleta.length > 0 ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: "Número Autorização", field: 'numeroAf', cellStyle: {
                                            width: '10%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: "Descrição Autorização", field: 'descricaoAutorizacao', cellStyle: {
                                            width: '15%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: "Descrição Medição", field: 'descricaoMedicao', cellStyle: {
                                            width: '15%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: "Número Interno", field: 'codigoInterno', cellStyle: {
                                            width: '10%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: "Descrição", field: 'descricao', cellStyle: {
                                            width: '20%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: "Quantidade", field: 'quantidade', cellStyle: {
                                            width: '10%'
                                        }, editable: 'never'
                                    },
                                    {
                                        title: "Valor Unitário", field: 'valorUnitario', cellStyle: {
                                            width: '10%'
                                        }, editable: 'never',
                                        render: rowData =>
                                            rowData.valorUnitario === null ?
                                                null
                                                :
                                                rowData.valorUnitario.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: "Total", field: 'total', cellStyle: {
                                            width: '10%'
                                        }, editable: 'never',
                                        render: rowData =>
                                            rowData.total === null ?
                                                null
                                                :
                                                rowData.total.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: 'Unidade de Medida',
                                        field: 'unidadeMedida',
                                        cellStyle: {
                                            width: '5%'
                                        },
                                        editable: 'never'
                                    }
                                ]}
                                data={listaHierarquiaCompleta}
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.marcadorSubitem ?
                                            '#dcdcdc'
                                            : rowData.marcadorMedicao ?
                                                '#BCB8B8'
                                                :
                                                '#9D9999'
                                    }),
                                    selection: true,
                                    selectionProps: rowData => ({
                                        color: 'primary'
                                    }),
                                    showSelectAllCheckbox: false
                                }}
                                onSelectionChange={
                                    rowData =>
                                        Selecionar(rowData)
                                }
                                localization={{
                                    header: {
                                        actions: <span className="classHeaderTabela">Ações</span>
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                            saveTooltip: '',
                                            cancelTooltip: '',
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: '',
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                        nRowsSelected: '{0} linha(s) selecionada'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />

                            :
                            <div className="divBody">
                                No momento não possui Itens para serem inseridos.
                            </div>
                    }
                </Modal.Body>
                <ModalFooter>
                    <Row className="mb-3">
                        {
                            inserir == true ?
                                <Button
                                    disabled={
                                        listaHierarquiaCompleta != undefined && listaHierarquiaCompleta.length > 0 ? false : true
                                    }
                                    variant="success"
                                    type="submit"
                                    onClick={() => InserirValidacaoEmpenhoSubitem()}>
                                    Adicionar Itens
                                </Button>
                                :
                                ''
                        }

                    </Row>
                </ModalFooter>
            </Modal>

            <Modal show={showAviso} onHide={handleCloseAviso}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Aviso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <span>
                                As seguintes autorizações não estão finalizadas, sendo assim, elas irão para o TRO como Entrega Parcial. Deseja Continuar?
                                <h6 style={{ color: 'red' }}>Autorizações:
                                    {
                                        listaAutorizacaoFornecimento !== undefined && listaAutorizacaoFornecimento.length > 0 &&
                                            listaAutorizacaoFornecimento !== null && listaSubitem != undefined && listaSubitem !== null && listaSubitem.length > 0 ?
                                            listaSubitem.map((e) => (
                                                e.marcadorSubitem == false && e.marcadorMedicao == false &&
                                                    listaAutorizacaoFornecimento.filter(m => m.idAutorizacaoStatus !== 6 && e.id == m.id).length > 0 ?
                                                    ` ${e.id}, `
                                                    :
                                                    null
                                            ))
                                            :
                                            null
                                    }
                                </h6>
                            </span>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={InserirEmpenhoSubitem}>Sim</Button>
                    <Button variant="danger" onClick={handleCloseAviso}>Não</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}