import { Card, Button, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { MdPermIdentity } from 'react-icons/md'
import { IoEyeSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { AtualizarPerfilRequest } from '../../../store/modules/perfil/actions';
import { ListarMunicipiosRequest } from "../../../store/modules/municipio/actions";
import { toast } from "react-toastify";
import Header from "../../../components/Header/header";
import InputMask from "react-input-mask";
import './perfil.css';

export default function Perfil() {

    const dispatch = useDispatch();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaMunicipio = useSelector(state => state.municipio.listaMunicipio);

    const [nome, setNome] = useState(usuario.nome);
    const [email] = useState(usuario.email);
    const [ddd, setDdd] = useState(usuario.ddd);
    const [telefone, setTelefone] = useState(usuario.telefone);
    const [cep, setCep] = useState(usuario.cep);
    const [logradouro, setLogradouro] = useState(usuario.logradouro);
    const [numero, setNumero] = useState(usuario.numero);
    const [complemento, setComplemento] = useState(usuario.complemento);
    const [bairro, setBairro] = useState(usuario.bairro);
    const [municipio, setMunicipio] = useState(usuario.idMunicipio);
    const [senha, setSenha] = useState(usuario.senha);
    const [confirmarSenha, setConfirmaSenha] = useState(usuario.senha);
    const [cargo, setCargo] = useState(usuario.cargo);
    const [historicoFiltro, setHistoricoFiltro] = useState(usuario.historicoFiltro);

    const [validated, setValidated] = useState(false);

    function AlterarPerfil(e) {
        e.preventDefault();

        if (senha !== confirmarSenha) {
            toast.error('As senhas são diferentes');
            return;
        }

        if (!validaSenha(senha)) {
            toast.error('Senha precisar conter letras maiúsculas, minúsculas, caracteres especiais e mínimo 8 digitos');
            return;
        }

        usuario.nome = nome;
        usuario.email = email;
        usuario.ddd = ddd;
        usuario.telefone = telefone;
        usuario.cep = cep;
        usuario.logradouro = logradouro;
        usuario.numero = numero;
        usuario.complemento = complemento;
        usuario.bairro = bairro;
        usuario.municipio = municipio;
        usuario.senha = senha;
        usuario.apiRefreshToken = '0';
        usuario.cargo = cargo;
        usuario.historicoFiltro = historicoFiltro;

        dispatch(AtualizarPerfilRequest(usuario));
    }

    function validaSenha(senha) {
        const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
        return senhaRegex.test(String(senha))
    }

    useEffect(() => {
        CarregarMunicipios();
    })

    function CarregarMunicipios() {
        dispatch(ListarMunicipiosRequest());
    }

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false || ValidaTelefone() === false
            || ValidaDdd() === false || ValidaCep() === false) {
            event.preventDefault();
        } else {
            AlterarPerfil(event);
        }

        setValidated(true);
    };

    function ValidaDdd() {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(ddd))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone() {
        const telefoneRegex = /^\d{1}\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;

        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaCep() {
        const cepRegex = /^\d{2}.\d{3}-\d{3}$/;
        if (cepRegex.test(String(cep))) {
            return true;
        } else {
            return false;
        }
    }

    function VerSenha() {
        let input = document.getElementById('formSenha');
        if (input.getAttribute('type') == 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    function VerSenha2() {
        let input = document.getElementById('formSenha2');
        if (input.getAttribute('type') == 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdPermIdentity size={25} color={"#000"} /> Meu perfil
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Container fluid={true} className="divBody">
                                    <Row>
                                        <Col md={12}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formNome">
                                                    <Form.Label>Nome</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={nome}
                                                        onChange={e => setNome(e.target.value)}
                                                        maxLength="100"
                                                        isInvalid={validated && nome === "" ? true : false}
                                                        isValid={validated && nome !== "" ? true : false}
                                                        required />
                                                    <Form.Control.Feedback type="invalid">O campo Nome é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} controlId="formEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        value={email}
                                                        disabled />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={1} controlId="formDDD">
                                                    <Form.Label>DDD</Form.Label>
                                                    <Form.Control
                                                        as={InputMask}
                                                        mask='99'
                                                        value={ddd}
                                                        onChange={(e) => setDdd(e.target.value)}
                                                        required
                                                        isInvalid={validated && !/^\d{2}$/.test(String(ddd)) ? true : false}
                                                        isValid={validated && /^\d{2}$/.test(String(ddd)) ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">O campo DDD é obrigatório.</Form.Control.Feedback>

                                                </Form.Group>

                                                <Form.Group as={Col} md={5} controlId="formTelefone">

                                                    <Form.Label>Telefone</Form.Label><br />
                                                    <Form.Control
                                                        as={InputMask}
                                                        mask='99999 9999'
                                                        value={telefone}
                                                        onChange={(e) => setTelefone(e.target.value)}
                                                        required
                                                        isInvalid={validated && !/^\d{1}\d{4} \d{4}$/.test(String(telefone))
                                                            && !/^\d{1}\d{4}\d{4}$/.test(String(telefone)) ? true : false}
                                                        isValid={validated && /^\d{1}\d{4} \d{4}$/.test(String(telefone))
                                                            || (validated && /^\d{1}\d{4}\d{4}$/.test(String(telefone))) ? true : false} >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">O campo Telefone é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} controlId="formCep">
                                                    <Form.Label>Cep</Form.Label><br />
                                                    <Form.Control
                                                        as={InputMask}
                                                        mask='99.999-999'
                                                        value={cep}
                                                        onChange={(e) => setCep(e.target.value)}
                                                        required
                                                        isInvalid={validated && !/^\d{2}.\d{3}-\d{3}$/.test(String(cep)) ? true : false}
                                                        isValid={validated && /^\d{2}.\d{3}-\d{3}$/.test(String(cep)) ? true : false}>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">O campo Cep é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formLogradouro">
                                                    <Form.Label>Logradouro</Form.Label>
                                                    <Form.Control
                                                        maxLength="150"
                                                        type="text"
                                                        value={logradouro}
                                                        onChange={(e) => setLogradouro(e.target.value)}
                                                        required
                                                        isInvalid={validated && logradouro === "" ? true : false}
                                                        isValid={validated && logradouro !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">O campo Logradouro é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md={2} controlId="formNumero">
                                                    <Form.Label>Número</Form.Label>
                                                    <Form.Control
                                                        maxLength="10"
                                                        type="text"
                                                        value={numero}
                                                        onChange={(e) => setNumero(e.target.value)}
                                                        required
                                                        isInvalid={validated && numero === "" ? true : false}
                                                        isValid={validated && numero !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">O campo Número é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} controlId="formComplemento">
                                                    <Form.Label>Complemento</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={complemento}
                                                        onChange={(e) => setComplemento(e.target.value)}
                                                        maxLength="250" />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formBairro">
                                                    <Form.Label>Bairro</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={bairro}
                                                        onChange={(e) => setBairro(e.target.value)}
                                                        required
                                                        maxLength="100"
                                                        isInvalid={validated && bairro === "" ? true : false}
                                                        isValid={validated && bairro !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">O campo Bairro é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} controlId="formMunicipio">
                                                    <Form.Label>Município</Form.Label>
                                                    <Form.Select
                                                        onChange={(e) => setMunicipio(e.target.value)}
                                                        value={municipio}
                                                        required
                                                        isInvalid={validated && municipio === "" ? true : false}
                                                        isValid={validated && municipio !== "" ? true : false}>
                                                        <option value="">Selecione...</option>
                                                        {
                                                            listaMunicipio != undefined ?
                                                                listaMunicipio.map(m => (
                                                                    <option value={m.id} key={m.id}>{m.nome}</option>
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">O campo Município é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formSenha">
                                                    <Form.Label>Senha</Form.Label>
                                                    <InputGroup>
                                                    <Form.Control
                                                        type="password"
                                                        value={senha}
                                                        onChange={(e) => setSenha(e.target.value)}
                                                        isInvalid={validated && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/.test(String(senha))
                                                            || validated && (senha !== confirmarSenha) ? true : false}
                                                        isValid={validated && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/.test(String(senha))
                                                            || validated && (senha === confirmarSenha) ? true : false}
                                                        required
                                                    />
                                                        <InputGroup.Text onClick={VerSenha} className="campoCssOlho">
                                                            <IoEyeSharp className='cssOlho' size={25} />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} controlId="formSenha2">
                                                    <Form.Label>Confirmar Senha</Form.Label>
                                                    <InputGroup>
                                                    <Form.Control
                                                        type="password"
                                                        value={confirmarSenha}
                                                        onChange={(e) => setConfirmaSenha(e.target.value)}
                                                        isInvalid={validated && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/.test(String(senha))
                                                            || validated && (senha !== confirmarSenha) ? true : false}
                                                        isValid={validated && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/.test(String(senha))
                                                            || validated && (senha === confirmarSenha) ? true : false}
                                                        required 
                                                    />
                                                        <InputGroup.Text onClick={VerSenha2} className="campoCssOlho">
                                                            <IoEyeSharp className='cssOlho' size={25} />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formCargo">
                                                    <Form.Label>Cargo</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={cargo}
                                                        onChange={e => setCargo(e.target.value)}
                                                        required
                                                        maxLength="150"
                                                        isInvalid={validated && cargo.trim() === "" ? true : false}
                                                        isValid={validated && cargo.trim() !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">
                                                        O campo Cargo é obrigatório.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={4}>
                                                    <Form.Label></Form.Label>
                                                    <Form.Check
                                                        size="lg"
                                                        label="Habilitar histórico de filtros"
                                                        type="switch"
                                                        id="custom-switch"
                                                        onChange={() => { setHistoricoFiltro(!historicoFiltro); }}
                                                        checked={historicoFiltro}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={12} controlId="formSalvar">
                                                    <Button variant="success" type="submit">
                                                        Salvar
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}