import produce from 'immer';

const initialState = {
    listaCaracteristicaResposta: undefined,
    listaCaracteristicaRespostaPorNome: undefined,
    carregando: true
};

export default function caracteristicaResposta(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_CARACTERISTICA_RESPOSTA_RESPONSE':
            return produce(state, draft => {
                draft.listaCaracteristicaResposta = action.listaCaracteristicaResposta;
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        case 'BUSCAR_CARACTERISTICA_RESPOSTA_POR_NOME_RESPONSE':
            return produce(state, draft => {
                draft.listaCaracteristicaRespostaPorNome = action.listaCaracteristicaRespostaPorNome
            });

        case 'LIMPAR_LISTA_CARACTERISTICA_RESPOSTA_POR_NOME':
            return produce(state, draft => {
                draft.listaCaracteristicaRespostaPorNome = undefined
            });

        default:
            return state;
    }
}