import produce from 'immer';

const initialState = {
    fonteReceitaCompleta: null,
    listaFonteReceita: undefined,
    carregando: true
};

export default function fonte(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_FONTE_RECEITA_RESPONSE':
            return produce(state, draft => {
                draft.listaFonteReceita = action.listaFonteReceita;
            });

        case 'BUSCAR_FONTE_RECEITA_POR_ID_RESPONSE':
            return produce(state, draft => {
                draft.fonteReceitaCompleta = action.fonteReceitaCompleta;
            });
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}