import produce from 'immer';

const initialState = {
  listaMedicaoAutorizacaoFornecimentoRegistroFotografico: undefined,
  carregando: true
};

export default function medicaoAutorizacaoFornecimentoRegistroFotografico(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_RESPONSE':
      return produce(state, draft => {
        draft.listaMedicaoAutorizacaoFornecimentoRegistroFotografico = action.listaMedicaoAutorizacaoFornecimentoRegistroFotografico;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}