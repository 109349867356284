import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdRemoveRedEye } from "react-icons/md";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import {
    AlterarFaturamentoDocumentoArquivoRequest,
    BaixarFaturamentoDocumentoArquivoRequest,
    ExcluirFaturamentoDocumentoArquivoRequest,
    InserirFaturamentoDocumentoArquivoRequest,
    ListarFaturamentoDocumentoArquivoRequest
} from "../../store/modules/faturamentoDocumentoArquivo/actions";
import { ListarTipoDocumentoArquivoRequest } from "../../store/modules/tipoDocumentoArquivo/actions";
import { toast } from "react-toastify";
import Loading from "../Loading/loading";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalFaturamentoDocumento({ showModal, onHide, idFaturamento }) {

    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaFaturamentoDocumentoArquivo = useSelector(state => state.faturamentoDocumentoArquivo.listaFaturamentoDocumentoArquivo);
    const listaTipoDocumentoArquivo = useSelector(state => state.tipoDocumentoArquivo.listaTipoDocumentoArquivo);

    const [listaFile, setListaFile] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showDocumento, setShowDocumento] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [show, setShow] = useState(false);
    const [tipoDocumentoArquivo, setTipoDocumentoArquivo] = useState('');
    const [validated, setValidated] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDocumentoClose = () => setShowDocumento(false);
    const handleDocumentoShow = () => setShowDocumento(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "FaturamentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "FaturamentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "FaturamentoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "FaturamentoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarTipoDocumentoArquivoRequest());
        if (idFaturamento != undefined && idFaturamento != '') {
            dispatch(ListarFaturamentoDocumentoArquivoRequest(idFaturamento));
        }

    }, [idFaturamento])

    function InserirDocumentoArquivo() {

        let formData = new FormData();

        if (tipoDocumentoArquivo == '') {
            toast.error('Para continuar selecione o tipo de documento');
        } else {
            if (listaFile.length > 0) {
                for (var x = 0; x < listaFile.length; x++) {
                    formData.append('File', listaFile[x]);
                    formData.append('IdFaturamento', parseInt(idFaturamento));
                    formData.append('IdUsuario', parseInt(usuario.id));
                    formData.append('IdTipoDocumentoArquivo', parseInt(tipoDocumentoArquivo));
                    if (observacao != '')
                        formData.append('Observacao', observacao);
                }

                setObservacao('');
                setListaFile([]);

                dispatch(InserirFaturamentoDocumentoArquivoRequest(formData, idFaturamento));
                handleClose();

            } else {
                toast.error('Para continuar insira um documento');
            }
        }
    }

    function AlterarDocumentoArquivo(documentoArquivo, novoValorCampoEditado, colunaEditada) {

        // Valida alteração do campo nome arquivo
        if (colunaEditada.field == "nomeArquivo") {

            if (novoValorCampoEditado == null || novoValorCampoEditado == undefined
                || novoValorCampoEditado == ' ' || novoValorCampoEditado == '') {

                toast.error(`Nome do arquivo inválido`);
                return;
            }

            if (novoValorCampoEditado.length > 150) {
                toast.error(`O nome do arquivo não pode conter mais que 150 caracteres`);
                return;
            }
        }

        // Valida alteração do campo observação
        if (colunaEditada.field == "observacao") {
            if (novoValorCampoEditado.length > 500) {
                toast.error(`A observação não pode conter mais que 500 caracteres`);
                return;
            }
        }

        if (colunaEditada != null) {

            let data = {
                'Id': documentoArquivo.id,
                'IdFaturamento': documentoArquivo.idFaturamento,
                'NomeArquivo': colunaEditada.field == "observacao" ? documentoArquivo.nomeArquivo : novoValorCampoEditado,
                'DescricaoExtensaoArquivo': documentoArquivo.descricaoExtensaoArquivo,
                'DataInclusao': documentoArquivo.dataInclusaoApi,
                'IdTipoDocumentoArquivo': documentoArquivo.idTipoDocumentoArquivo,
                'DocumentoArquivoGuid': documentoArquivo.documentoArquivoGuid,
                'IdUsuario': documentoArquivo.idUsuario,
                'Observacao': colunaEditada.field == "observacao" ? novoValorCampoEditado : documentoArquivo.observacao
            };

            dispatch(AlterarFaturamentoDocumentoArquivoRequest(data, idFaturamento));
        }
    }

    function ExcluirDocumentoArquivo(idDocumentoArquivo) {
        dispatch(ExcluirFaturamentoDocumentoArquivoRequest(idDocumentoArquivo, idFaturamento));
    }

    function BaixarDocumentoArquivo(idDocumentoArquivo) {
        dispatch(BaixarFaturamentoDocumentoArquivoRequest(idDocumentoArquivo));
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                data={idFaturamento}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Documentos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Col md={12}>
                            {
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={
                                        <Button variant="success" onClick={handleShow} style={{ width: '100%' }}>Adicionar Documento</Button>
                                    }
                                    columns={[
                                        {
                                            title: "Código", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                                width: '10%',
                                            }
                                        },
                                        {
                                            title: "Nome", field: 'nomeArquivo', cellStyle: {
                                                width: '18%'
                                            },
                                        },
                                        {
                                            title: "Tipo Documento Arquivo", field: 'tipoDocumentoArquivo', editable: 'never', cellStyle: {
                                                width: '18%'
                                            }
                                        },
                                        {
                                            title: "Data", field: 'dataInclusao', editable: 'never', cellStyle: {
                                                width: '18%'
                                            }
                                        },
                                        {
                                            title: "Usuario", field: 'usuario', editable: 'never', cellStyle: {
                                                width: '18%'
                                            }
                                        },
                                        {
                                            title: "Observação", field: 'observacao', cellStyle: {
                                                width: '18%'
                                            },
                                            render: rowData => rowData.observacao == '' ?
                                                <div className="spanVazio"></div>
                                                : rowData.observacao
                                        }
                                    ]}
                                    data={
                                        listaFaturamentoDocumentoArquivo != undefined ?
                                            listaFaturamentoDocumentoArquivo.map(doc => {
                                                return {
                                                    id: doc.id,
                                                    nomeArquivo: doc.nomeArquivo,
                                                    tipoDocumentoArquivo: doc.tipoDocumentoArquivo.descricao,
                                                    dataInclusao: moment(doc.dataInclusao).format('L LT'),
                                                    observacao: doc.observacao,
                                                    usuario: doc.usuario.nome,

                                                    // Info para alteração de documento arquivo
                                                    dataInclusaoApi: doc.dataInclusao,
                                                    descricaoExtensaoArquivo: doc.descricaoExtensaoArquivo,
                                                    documentoArquivoGuid: doc.documentoArquivoGuid,
                                                    idTipoDocumentoArquivo: doc.idTipoDocumentoArquivo,
                                                    idFaturamento: doc.idFaturamento,
                                                    idUsuario: doc.idUsuario
                                                }
                                            })
                                            :
                                            []
                                    }
                                    cellEditable={{
                                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                alterar == true ?
                                                    setTimeout(() => {
                                                        AlterarDocumentoArquivo(rowData, newValue, columnDef);
                                                        resolve()
                                                    }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)
                                            });
                                        }
                                    }}
                                    editable={{
                                        onRowDelete:
                                            oldData =>
                                                new Promise((resolve, reject) => {
                                                    excluir == true ?
                                                        setTimeout(() => {
                                                            ExcluirDocumentoArquivo(oldData.id);
                                                            resolve()
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                    }}
                                    options={{
                                        minBodyHeight: 500,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    actions={[
                                        {
                                            icon: 'download',
                                            onClick: (event, rowData) => BaixarDocumentoArquivo(rowData.id)
                                        }
                                    ]}
                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                            },
                                            deleteTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                            }
                        </Col>
                    </div>

                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Documentos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formDocumento">
                                        <div className="divModal">
                                            <DropzoneArea
                                                initialFiles={[listaFaturamentoDocumentoArquivo]}
                                                onChange={(files) => setListaFile(files)}
                                                filesLimit={10}
                                                showPreviewsInDropzone={true}
                                                useChipsForPreview
                                                getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                                getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                                getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                                disableRejectionFeedback={true}
                                                dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                maxFileSize={30000000}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                        <Form.Label>Observacao</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={observacao}
                                            onChange={e => setObservacao(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={12} controlId="formDocumentos">
                                        <Form.Label>Tipo documento arquivo</Form.Label>
                                        <Form.Select
                                            onChange={(e) => setTipoDocumentoArquivo(e.target.value)}
                                            value={tipoDocumentoArquivo}
                                            required
                                            isInvalid={validated && tipoDocumentoArquivo == undefined
                                                || validated && tipoDocumentoArquivo == '' ? true : false}
                                            isValid={validated && tipoDocumentoArquivo !== undefined
                                                || validated && tipoDocumentoArquivo !== '' ? true : false}>
                                            <option value="">Selecione...</option>
                                            {
                                                listaTipoDocumentoArquivo != undefined ?
                                                    listaTipoDocumentoArquivo.map(m => (
                                                        <option value={m.id} key={m.id}>{m.descricao}</option>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                inserir == true ?
                                    < Button onClick={InserirDocumentoArquivo}>Salvar Documento</Button>
                                    :
                                    ''
                            }
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDocumento} onHide={handleDocumentoClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formDocumento">
                                        <div id="divImagemDocumentoFaturamentoDocumentoArquivo">
                                        </div>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Modal.Body>
                    </Modal>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}