import produce from 'immer';

const initialState = {
  listaFaturamento: undefined,
  listaCompletaFaturamento: undefined,
  carregando: true
};

export default function faturamento(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_FATURAMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaFaturamento = action.listaFaturamento;
      });

    case 'LISTAR_COMPLETO_FATURAMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletaFaturamento = action.listaCompletaFaturamento;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}