import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    ListarPorUltimaVersaoAnoRequest,
    InserirFonteReceitaVersaoRequest, AlterarFonteReceitaVersaoRequest,
    ExcluirFonteReceitaVersaoRequest
} from '../../../store/modules/fonteReceitaVersao/actions';
import { BuscarFonteReceitaPorIdRequest } from "../../../store/modules/fonteReceita/actions";
import { ListarTipoRecursoRequest } from '../../../store/modules/tipoRecurso/actions';
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";

export default function Versao() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const fonteReceitaCompleta = useSelector(state => state.fonteReceita.fonteReceitaCompleta);
    const listaTipoRecurso = useSelector(state => state.tipoRecurso.listaTipoRecurso);
    const listaFonteReceitaVersaoUltimoAno = useSelector(state => state.fonteReceitaVersao.listaFonteReceitaVersaoUltimo);

    const [selectedRow, setSelectedRow] = useState(null);

    const options = listaTipoRecurso != undefined && listaTipoRecurso ?
        listaTipoRecurso.map(fonte => ({
            value: fonte.id, label: fonte.descricao
        }))
        :
        [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "FonteReceitaVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "FonteReceitaInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "FonteReceitaAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "FonteReceitaExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {
        if (id != undefined)
            dispatch(BuscarFonteReceitaPorIdRequest(id));
    }, [])

    useEffect(() => {
        dispatch(ListarTipoRecursoRequest());
        if (fonteReceitaCompleta != null)
            dispatch(ListarPorUltimaVersaoAnoRequest(fonteReceitaCompleta.id));
    }, [fonteReceitaCompleta])

    function InserirFonteVersao(fonteReceitaVersao) {

        if (isNaN(fonteReceitaVersao.ano) || fonteReceitaVersao.ano == undefined
            || fonteReceitaVersao.ano == null) {
            toast.error(`Ano inválido`);
            return;
        }

        if (isNaN(fonteReceitaVersao.valorFonteReceita) || fonteReceitaVersao.valorFonteReceita == undefined
            || fonteReceitaVersao.valorFonteReceita == null) {
            toast.error(`Defina um valor`);
            return;
        }

        let selectText = document.getElementById('idSelectFonteVersao').innerText;
        let selectSelecionado = listaTipoRecurso.filter(soli => soli.descricao == selectText);

        if (selectSelecionado.length == 0) {
            toast.error(`Recurso não selecionado`);
            return;
        }

        let data = {
            'Id': parseInt(0),
            'IdFonteReceita': parseInt(id),
            'IdRecurso': parseInt(selectSelecionado[0].id),
            'Ano': parseInt(fonteReceitaVersao.ano),
            'ValorFonteReceita': parseFloat(fonteReceitaVersao.valorFonteReceita)
        }

        dispatch(InserirFonteReceitaVersaoRequest(data));
    }

    function AlterarFonteVersao(fonteReceitaVersao) {

        if (isNaN(fonteReceitaVersao.ano) || fonteReceitaVersao.ano == undefined
            || fonteReceitaVersao.ano == null) {
            toast.error(`Ano inválido`);
            return;
        }

        if (isNaN(fonteReceitaVersao.valorFonteReceita) || fonteReceitaVersao.valorFonteReceita == undefined
            || fonteReceitaVersao.valorFonteReceita == null) {
            toast.error(`Defina um valor`);
            return;
        }

        let selectText = document.getElementById('idSelectFonteVersao').innerText;
        let selectSelecionado = listaTipoRecurso.filter(soli => soli.descricao == selectText);

        if (selectSelecionado.length == 0) {
            toast.error(`Recurso não selecionado`);
            return;
        }

        let data = {
            'Id': fonteReceitaVersao.id,
            'IdFonteReceita': fonteReceitaCompleta.id,
            'IdRecurso': parseInt(selectSelecionado[0].id),
            'Ano': fonteReceitaVersao.ano,
            'ValorFonteReceita': fonteReceitaVersao.valorFonteReceita
        }

        dispatch(AlterarFonteReceitaVersaoRequest(data));
    }

    function ExcluirFonteReceita(idVersao) {

        dispatch(ExcluirFonteReceitaVersaoRequest(idVersao, id));
    }

    return (
        <>
            <div>
                <Col md={12}>
                    <MaterialTable
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        title=''
                        columns={[
                            {
                                title: "Número da Versão", field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                    width: '11%',

                                }
                            },
                            {
                                title: "Ano", field: 'ano', type: 'numeric', align: 'left', validate: rowData => {
                                    if (rowData.ano === undefined || rowData.ano === "" || isNaN(rowData.ano)) {
                                        return "Campo Obrigatório"
                                    } else if (rowData.ano.toString().length > 4) {
                                        return "O ano não pode conter mais que 4 dígitos"
                                    }
                                    return true
                                }, cellStyle: {
                                    width: '33%'
                                },

                            },
                            {
                                title: "Recurso", field: 'idRecurso', cellStyle: { width: '33%' },
                                lookup:
                                    listaTipoRecurso != undefined && listaTipoRecurso ?
                                        listaTipoRecurso.map(recurso => {
                                            return {
                                                id: recurso.id, descricao: recurso.descricao
                                            }
                                        }).reduce(function (acc, cur, i) {
                                            acc[cur.id] = cur.descricao;
                                            return acc;
                                        }, {})
                                        :
                                        null,
                                editComponent: (props) => (
                                    <Select id="idSelectFonteVersao"
                                        options={options}
                                        placeholder="Selecione..."
                                        defaultValue={{
                                            value: props.value, label: listaTipoRecurso.filter(soli => soli.id == props.value).length > 0
                                                ? listaTipoRecurso.filter(soli => soli.id == props.value)[0].descricao
                                                : 'Selecione...'
                                        }}
                                    />
                                ),
                                filterComponent: (props) => {
                                    return (
                                        <Select
                                            placeholder="Selecione..."
                                            isClearable={true}
                                            isSearchable={true}
                                            options={options}
                                            onChange={(e) => {
                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                            }}
                                        />
                                    )
                                },
                            },
                            {
                                title: "Valor", field: 'valorFonteReceita', type: 'currency', currencySetting: { currencyCode: "BRL" }, align: 'left',
                                validate: rowData => {
                                    if (rowData.valorFonteReceita === undefined || rowData.valorFonteReceita === "" || isNaN(rowData.valorFonteReceita)) {
                                        return "Campo Obrigatório"
                                    } else if (rowData.valorFonteReceita.toString().length > 10) {
                                        return "O valor não pode conter mais que 10 números"
                                    }
                                    return true
                                }, cellStyle: {
                                    width: '33%'
                                }
                            },
                        ]}
                        data={
                            listaFonteReceitaVersaoUltimoAno != undefined ?
                                listaFonteReceitaVersaoUltimoAno.map((fonteVersao) => {
                                    return {
                                        id: fonteVersao.id,
                                        ano: fonteVersao.ano,
                                        idRecurso: fonteVersao.idRecurso,
                                        valorFonteReceita: fonteVersao.valorFonteReceita
                                    }
                                })
                                :
                                []
                        }
                        editable={{

                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                inserir == true ?
                                    setTimeout(() => {
                                        InserirFonteVersao(newRow);
                                        resolve()
                                    }, 1000)
                                    :
                                    setTimeout(() => {
                                        toast.error(`Você não tem permissão`);
                                        resolve()
                                    }, 1000)
                            }),

                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                excluir == true ?
                                    setTimeout(() => {
                                        ExcluirFonteReceita(oldData.id);
                                        resolve()
                                    }, 1000)
                                    :
                                    setTimeout(() => {
                                        toast.error(`Você não tem permissão`);
                                        resolve()
                                    }, 1000)
                            }),

                            onRowUpdate: (newRow) => new Promise((resolve, reject) => {
                                alterar == true ?
                                    setTimeout(() => {
                                        AlterarFonteVersao(newRow);
                                        resolve()
                                    }, 1000)
                                    :
                                    setTimeout(() => {
                                        toast.error(`Você não tem permissão`);
                                        resolve()
                                    }, 1000)
                            })
                        }}
                        icons={{
                            Add: () =>
                                <Button variant="success">
                                    <span>
                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                    </span>
                                </Button>
                        }}
                        options={{
                            minBodyHeight: 500,
                            paging: true,
                            pageSize: 10, // Início
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [10, 50, 200, 300],
                            headerStyle: {
                                backgroundColor: '#454545',
                                color: '#FFF',
                                fontWeight: "bold"
                            },
                            actionsColumnIndex: -1,
                            filterRowStyle: {
                                backgroundColor: "#FAFAFA"
                            },
                            addRowPosition: "first",
                            filtering: true,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        localization={{
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                editRow: {
                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                    saveTooltip: '',
                                    cancelTooltip: '',
                                },
                                deleteTooltip: '',
                                editTooltip: '',
                                addTooltip: '',
                            },
                            toolbar: {
                                searchTooltip: '',
                                searchPlaceholder: 'Pesquisar',
                                exportTitle: "",
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                            },
                            pagination: {
                                labelRowsSelect: 'linhas',
                                labelDisplayedRows: '{count} de {from}-{to}',
                                firstTooltip: '',
                                previousTooltip: '',
                                nextTooltip: '',
                                lastTooltip: ''
                            },
                            grouping: {
                                placeholder: 'Arraste a coluna aqui para agrupar',
                                groupedBy: 'Agrupado por:'
                            }
                        }}
                    />
                </Col>
            </div>
        </>
    );
}