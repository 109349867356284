import { useEffect, useState } from "react";
import { Row, Col, Card, Button, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { IoPersonCircleOutline  } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";
import InputMask from "react-input-mask";
import { ExcluirAssinanteRequest, AlterarAssinanteRequest, InserirAssinanteRequest, ListarAssinanteRequest} from "../../../store/modules/assinante/actions";


export default function Assinante(){
    const dispatch = useDispatch();
    const history = useHistory();
    const listaAssinante = useSelector(state => state.assinante.listaAssinante);
    const carregando = useSelector(state => state.assinante.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }
    },[])

    useEffect(()=>{
        dispatch(ListarAssinanteRequest());
    },[])

    function ValidacoesInserir(assinante) {

        //validação do campo Nome
        if (assinante.nome == null || assinante.nome == undefined
            || assinante.nome.trim() == ''
            || assinante.nome.length > 50) {
            toast.error(`Recurso inválido`);
            return;
        }

        return true;
    }

    function InserirAssinante (assinante){
        let data = {
            'Id':0,
            'Nome': assinante.nome.trim(),
            'Ativo':true
        }
        dispatch(InserirAssinanteRequest(data));
    }

    function AlterarAssinante (assinante){
        let data = {
            'Id': assinante.id,
            'Nome': assinante.nome.trim(),
            'Ativo': assinante.ativo
        }
        dispatch(AlterarAssinanteRequest(data));
    }

    function ExcluirAssinante (idAssinante){
        dispatch(ExcluirAssinanteRequest(idAssinante))
    }

    return(
        <>
            <Header/>
             <div className="">
                <Col xs={12}>
                    <Card>
                        <Card.Header className ='divHeader'>
                            <Row>
                                <Col md={{ span:10}}>
                                    <span>
                                    < IoPersonCircleOutline size={25} color={"#000"} /> Assinantes
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className='divBody'>
                                <Row>
                                    <Col>
                                        {

                                            carregando ? 
                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title=""
                                                    columns={[
                                                        {
                                                            title: 'Número Assinante' , field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                width: '20%',
                                                            }
                                                        },
                                                        {
                                                            title: 'Assinantes', field: 'nome', sorting: true, editPlaceholder: 'Nome', validate: rowData => {
                                                                if (rowData.nome === undefined || rowData.nome.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                }
                                                                else if (rowData.nome.trim().length > 50) {
                                                                    return "O nome não pode conter mais que 50 caracteres"
                                                                }
                                                                return true
                                                            }, cellStyle: { width: '80%' }
                                                        },
                                                        {
                                                            title: 'Ativo', field:'ativo', editable: 'onUpdate',

                                                            editComponent: (props) => (
                                                                <input
                                                                    type="checkbox"
                                                                    checked={props.value}
                                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                                />
                                                            ),
                                                            render: rowData => (<input type='checkbox' checked={rowData.ativo} onChange={() => { }} />)
                                                        }
                                                    ]}
                                                    data={
                                                        listaAssinante != undefined ?
                                                            listaAssinante.map(assinante => {
                                                                return {
                                                                    id : assinante.id,
                                                                    nome : assinante.nome,
                                                                    ativo : assinante.ativo
                                                                }
                                                            })
                                                            :
                                                            []
                                                    }
                                                    icons={{
                                                        Add: () =>
                                                        <Button variant="success">
                                                            <span>
                                                                <FaPlus size={15} color={"#fff"} />Inserir
                                                            </span>
                                                        </Button>
                                                    }}
                                                    editable={{
                                                        onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(()=>{
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                     else{
                                                                        InserirAssinante(newRow);
                                                                        resolve()
                                                                     }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error('Você não tem permissão');
                                                                    resolve()
                                                                },1000)
                                                        }),

                                                        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(()=> {
                                                                    if(!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else{
                                                                        AlterarAssinante(newRow);
                                                                        reject();
                                                                     }
                                                                },1000)
                                                                :
                                                                setTimeout(()=> {
                                                                    toast.error('Você não tem permissão');
                                                                    resolve()
                                                                },1000)
                                                        }),

                                                        onRowDelete: oldRow => new Promise((resolve, reject) => {
                                                            TodasOperacoes ?
                                                                setTimeout(() =>{
                                                                    ExcluirAssinante(oldRow.id);
                                                                    resolve()
                                                                },1000)
                                                                :
                                                                setTimeout(() =>{
                                                                    toast.error('Você não tem premissão')
                                                                    resolve()
                                                                },1000)
                                                        })
                                                    }}
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        addRowPosition: "first",
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        actionsColumnIndex: -1,
                                                        filtering: true,
                                                        rowStyle: rowData => ({
                                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        })

                                                    }}
                                                    style={{
                                                        fontSize: '13px',
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                            />
                                            :
                                            <Loading/>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>   
             </div>
            
        </>
    )
}        