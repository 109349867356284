export function ListarMultaRequest(idEmpenho) {
  return {
    type: 'LISTAR_MULTA_REQUEST',
    idEmpenho
  }
}

export function ListarMultaResponse(listaMulta) {
  return {
    type: 'LISTAR_MULTA_RESPONSE',
    listaMulta
  }
}

export function InserirMultaRequest(data) {
  return {
    type: 'INSERIR_MULTA_REQUEST',
    data
  }
}

export function AlterarMultaRequest(data) {
  return {
    type: 'ALTERAR_MULTA_REQUEST',
    data
  }
}

export function ExcluirMultaRequest(id, idEmpenho) {
  return {
    type: 'EXCLUIR_MULTA_REQUEST',
    id, idEmpenho
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}