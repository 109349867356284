import produce from 'immer';

const initialState =
{
  listaAutorizacaoFornecimento: undefined,
  listaCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem: undefined,
  autorizacaoFornecimentoCompleto: undefined,
  autorizacaoFornecimento: undefined,
  carregando: true
};

export default function autorizacaoFornecimento(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaAutorizacaoFornecimento = action.listaAutorizacaoFornecimento
      });
    
    case 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem = action.listaCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem
      });    

      case 'LIMPAR_AUTORIZACAO':
      return produce(state, draft => {
        draft.autorizacaoFornecimentoCompleto = null
      }); 
    
    case 'BUSCAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.autorizacaoFornecimentoCompleto = action.autorizacaoFornecimentoCompleto
      });        

    case 'BUSCAR_AUTORIZACAO_FORNECIMENTO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.autorizacaoFornecimento = action.autorizacaoFornecimento;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}