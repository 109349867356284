import Header from "../../../components/Header/header";
import { useHistory, useParams } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Card, Col, Row, Form } from "react-bootstrap";
import { BsFillGearFill } from 'react-icons/bs'
import { ListarGrupoFuncaoAcessoRequest, AlterarGrupoFuncaoAcessoRequest } from '../../../store/modules/grupoFuncaoAcesso/actions';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './permissao.css'

export default function Permissao() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const listaGrupoFuncaoAcesso = useSelector(state => state.grupoFuncaoAcesso.listaGrupoFuncaoAcesso);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [alterar, setAlterar] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "GrupoAcessoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        if (token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setAlterar(true);
        }        

    }, [])  

    useEffect(() => {
        dispatch(ListarGrupoFuncaoAcessoRequest(id));
    }, [])

    function changeCheckedGrupo(e, index, grupo) {

        let listaPermissoes = listaGrupoFuncaoAcesso;

        var data = {
            'Id': listaPermissoes[index].id,
            'IdGrupo': listaPermissoes[index].idGrupo,
            'IdFuncao': listaPermissoes[index].idFuncao,
            'Visualizar': listaPermissoes[index].visualizar,
            'Criar': listaPermissoes[index].criar,
            'Alterar': listaPermissoes[index].alterar,
            'Excluir': listaPermissoes[index].excluir,
            'Relatorio': listaPermissoes[index].relatorio,
            'Exportar': listaPermissoes[index].exportar,
            'VerValores': listaPermissoes[index].verValores,
            'PadraoAba': listaPermissoes[index].padraoAba,
            'Menu': listaPermissoes[index].menu
        }

        switch (grupo) {
            case 'Criar/Alterar':
                data.Criar = !data.Criar;                
                data.Alterar = data.Criar;
                dispatch(AlterarGrupoFuncaoAcessoRequest(data));                                
                break;
            case 'Excluir':
                data.Excluir = !data.Excluir;
                dispatch(AlterarGrupoFuncaoAcessoRequest(data));
                break;
            case 'VerValores':
                data.VerValores = !data.VerValores;                
                data.Relatorio = data.VerValores
                data.Visualizar = data.VerValores                
                data.Exportar = data.VerValores
                dispatch(AlterarGrupoFuncaoAcessoRequest(data));
                break;
            case 'TodasOperacoes':
                data.Criar = e.target.checked;             
                data.Alterar = e.target.checked;               
                data.Excluir = e.target.checked;             
                dispatch(AlterarGrupoFuncaoAcessoRequest(data));
                break;    
            case 'Menu':               
                data.Menu = e.target.checked;             
                dispatch(AlterarGrupoFuncaoAcessoRequest(data));
                break;               
        }
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <BsFillGearFill size={20} color={"#000"} /> Permissões do grupo
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col md={12}>
                                        <Table striped bordered hover variant="white">
                                            <Thead>
                                                <Tr className="trPermissoesGrupo">
                                                    <Th className="thIdGrupo">#</Th>
                                                    <Th className="thDescricaoPermissoesGrupo">Permissão</Th>                                                    
                                                    <Th className="thCriarPermissoesGrupo">Criar/Alterar</Th>
                                                    <Th className="thExcluirPermissoesGrupo">Excluir</Th>
                                                    <Th className="thVerValoresPermissoesGrupo">Visualizar</Th>
                                                    <Th className="thVerValoresPermissoesGrupo">Visualizar Menu</Th>
                                                    <Th className="thVerValoresPermissoesGrupo">Todas Operações</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody className='tbody'>
                                                {
                                                    listaGrupoFuncaoAcesso != undefined ?
                                                        listaGrupoFuncaoAcesso.map((permissao, index) => (
                                                            
                                                            permissao.padraoAba == true ?
                                                                
                                                                <Tr className="trGrupo" key={permissao.id}>
                                                                    <Td>{permissao.id}</Td>
                                                                    <Td>{permissao.funcaoAcesso.funcao}</Td>
                                                                    <Td>       
                                                                        <Form.Check type="checkbox" id={permissao.id}
                                                                         disabled={true}
                                                                         checked={
                                                                            permissao.criar &&
                                                                            permissao.alterar &&
                                                                            permissao.excluir ? true : false
                                                                        } onChange={e => changeCheckedGrupo(e, index, 'TodasOperacoes')} />                                                                        
                                                                    </Td>
                                                                    <Td>   
                                                                        <Form.Check type="checkbox" id={permissao.id}
                                                                         disabled={true}
                                                                         checked={
                                                                            permissao.criar &&
                                                                            permissao.alterar &&
                                                                            permissao.excluir ? true : false
                                                                        } onChange={e => changeCheckedGrupo(e, index, 'TodasOperacoes')} />                                                                        
                                                                    </Td>
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id} checked={permissao.verValores} onChange={e => changeCheckedGrupo(e, index, 'VerValores')} />
                                                                    </Td>
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id} checked={permissao.menu} onChange={e => changeCheckedGrupo(e, index, 'Menu')} />
                                                                    </Td>                                                                    
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id}
                                                                         checked={
                                                                            permissao.criar &&
                                                                            permissao.alterar &&
                                                                            permissao.excluir ? true : false
                                                                        } onChange={e => changeCheckedGrupo(e, index, 'TodasOperacoes')} />
                                                                    </Td>                                                                    
                                                                </Tr>
                                                            :
                                                                <Tr className="trGrupo" key={permissao.id}>
                                                                    <Td>{permissao.id}</Td>
                                                                    <Td>{permissao.funcaoAcesso.funcao}</Td>
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id} checked={permissao.criar} onChange={e => changeCheckedGrupo(e, index, 'Criar/Alterar')} />
                                                                    </Td>
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id} checked={permissao.excluir} onChange={e => changeCheckedGrupo(e, index, 'Excluir')} />
                                                                    </Td>
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id} checked={permissao.verValores} onChange={e => changeCheckedGrupo(e, index, 'VerValores')} />
                                                                    </Td>
                                                                    <Td>
                                                                        <Form.Check type="checkbox" id={permissao.id} checked={permissao.menu} onChange={e => changeCheckedGrupo(e, index, 'Menu')} />
                                                                    </Td>                                                                    
                                                                    <Td>        
                                                                        <Form.Check type="checkbox" id={permissao.id}
                                                                         disabled={true}
                                                                         checked={
                                                                            permissao.criar &&
                                                                            permissao.alterar &&
                                                                            permissao.excluir ? true : false
                                                                        } onChange={e => changeCheckedGrupo(e, index, 'TodasOperacoes')} />                                                                        
                                                                    </Td>                                                                    
                                                                </Tr>                                                                                                                            

                                                        ))
                                                        :
                                                        null
                                                }
                                            </Tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}