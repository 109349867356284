import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarEmpenhoResponse, BuscarEmpenhoPorIdResponse } from './actions';
import { ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse } from '../empenhoAutorizacaoFornecimentoSubitemCustoMed/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarEmpenhoRequest() {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Empenho/listarCompleto`, { headers: headerParams.token });

    yield put(ListarEmpenhoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarEmpenhoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BuscarEmpenhoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Empenho/${model.id}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(BuscarEmpenhoPorIdResponse(null));
    yield put(Carregando(true));

  }
}

function* InserirEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var resultId = yield call(api.post, `/api/Empenho`, model.data, { headers: headerParams.token });

    toast.success(`Empenho id: ${resultId.data.result} foi cadastrada com sucesso!`);

    // Alterar Rota
    history.push(`/alterarGeralEmpenho/${resultId.data.result}`);

    const result = yield call(api.get, `/api/Empenho/${resultId.data.result}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/Empenho`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/Empenho/listarCompleto`, { headers: headerParams.token });

    yield put(ListarEmpenhoResponse(result.data.result));
    yield put(Carregando(true))

    toast.success(`Empenho foi atualizado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirEmpenhoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/Empenho/${model.id}`, { headers: headerParams.token });

    toast.success(`Empenho id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/Empenho/listarCompleto`, { headers: headerParams.token });

    yield put(ListarEmpenhoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

function* TransferirAfRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var resultId = yield call(api.post, `/api/Empenho/TransferirAf`, model.data, { headers: headerParams.token });

    // Buscar empenho pra atualizar valores pagos
    const resultEmp = yield call(api.get, `/api/Empenho/${model.data.idEmpenhoOrigem}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

    toast.success(`A autorização de id: ${model.data.IdAutorizacao} foi transferida com sucesso!`);

    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* TransferirAfDefinitivaParcialRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/Empenho/TransferirAfDefinitivaParcial`, model.data,
      { headers: headerParams.token });

    // Buscar empenho pra atualizar valores pagos
    const resultEmp = yield call(api.get, `/api/Empenho/${model.data.IdEmpenhoOrigem}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

    toast.success(`A autorização de id: ${model.data.IdAutorizacao} foi transferida com sucesso!`);

    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_EMPENHO_REQUEST', ListarEmpenhoRequest),
  takeLatest('BUSCAR_EMPENHO_POR_ID_REQUEST', BuscarEmpenhoPorIdRequest),
  takeLatest('INSERIR_EMPENHO_REQUEST', InserirEmpenhoRequest),
  takeLatest('ALTERAR_EMPENHO_REQUEST', AlterarEmpenhoRequest),
  takeLatest('EXCLUIR_EMPENHO_REQUEST', ExcluirEmpenhoRequest),
  takeLatest('TRANSFERIR_AF_REQUEST', TransferirAfRequest),
  takeLatest('TRANSFERIR_AF_DEFINITIVA_PARCIAL_REQUEST', TransferirAfDefinitivaParcialRequest),
])