export function ListarCompletoSolicitacaoFornecimentoHistoricoRegFotoRequest(idSolicitacaoHistorico) {
    return {
      type: 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_HISTORICO_REG_FOTO_REQUEST',
      idSolicitacaoHistorico
    }
  }
  
  export function ListarSolicitacaoFornecimentoHistoricoRegFotoRequest() {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_REG_FOTO_REQUEST'
    }
  }
  
  export function ListarSolicitacaoFornecimentoHistoricoRegFotoResponse(listaSolicitacaoFornecimentoHistoricoRegFoto) {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_REG_FOTO_RESPONSE',
      listaSolicitacaoFornecimentoHistoricoRegFoto
    }
  }
  
  export function BaixarSolicitacaoFornecimentoHistoricoRegFotoRequest(id, numeroSolicitacaoRegFoto) {
    return {
      type: 'BAIXAR_SOLICITACAO_FORNECIMENTO_HISTORICO_REG_FOTO_REQUEST',
      id,
      numeroSolicitacaoRegFoto
    }
  }
  
  export function CarregarImagemSolicitacaoFornecimentoHistoricoRegFotoRequest(id) {
    return {
      type: 'CARREGAR_IMAGEM_SOLICITACAO_FORNECIMENTO_HISTORICO_REG_FOTO_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }