import produce from "immer";

const initialState = {
  listaSolicitacaoFornecimentoVersao: undefined,
  listaCompletaSolicitacaoFornecimentoVersao: undefined,
  carregando: true
};

export default function solicitacaoFornecimentoVersao(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_RESPONSE':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimentoVersao = action.listaSolicitacaoFornecimentoVersao;
      });

      case 'LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_VERSAO_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletaSolicitacaoFornecimentoVersao = action.listaCompletaSolicitacaoFornecimentoVersao;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}