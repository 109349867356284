import produce from 'immer';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const initialState = {
    token: null,
    carregando: true
};

export default function global(state = initialState, action) {

    switch (action.type) {

        case 'CRIAR_TXT':
            setTimeout(() => {
                WriteErroFile(action.text);
            }, 1000)
            return state;
            break;

        case 'ERROR_RESPONSE':
            return produce(state, draft => {
                console.log(state, draft);
                console.log(draft.text)
            });

        case 'ATUALIZAR_TOKEN':
            return produce(state, draft => {
                draft.token = {
                    Authorization: JSON.parse(localStorage.getItem('token')) !== null ?
                        `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
                        : ''
                };
            });
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });
            
        default:
            return state;
    }

    function WriteErroFile(text) {

        const blob = new Blob([text]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileDownloadUrl;
        link.setAttribute(
            `download`,
            `ErroExcel.txt`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
}