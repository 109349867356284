
import { Route, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    const cookies = new Cookies();
    const signed = !!localStorage.getItem('token') && cookies.get('token');

    if (!signed && isPrivate) {
        return <Redirect to="/" />
    }

    if (signed && !isPrivate) {

        let token = JSON.parse(localStorage.getItem('token')).token;

        if (token.role != null && token.role.filter(d => d == 'DashboardVerValores').length > 0) {
            return <Redirect to="/dashboard" />
        } else {
            return <Redirect to="/bemVindo" />
        }
    }

    return (
        <Route
            {...rest}
            render={props => (
                <Component {...props} />
            )}
        />
    )
}