export function ListarEmpenhoStatusRequest(idEmpenho) {
  return {
    type: 'LISTAR_EMPENHO_STATUS_REQUEST', idEmpenho
  }
}

export function ListarEmpenhoStatusResponse(listaEmpenhoStatus) {
  return {
    type: 'LISTAR_EMPENHO_STATUS_RESPONSE',
    listaEmpenhoStatus
  }
}

export function ListarCompletoEmpenhoStatusRequest(idEmpenho) {
  return {
    type: 'LISTAR_COMPLETO_EMPENHO_STATUS_REQUEST', idEmpenho
  }
}

export function ListarCompletoEmpenhoStatusResponse(listaCompletoEmpenhoStatus) {
  return {
    type: 'LISTAR_COMPLETO_EMPENHO_STATUS_RESPONSE',
    listaCompletoEmpenhoStatus
  }
}

export function InserirEmpenhoStatusRequest(data, idEmpenho, validacaoFinalizado) {
  return {
    type: 'INSERIR_EMPENHO_STATUS_REQUEST',
    data, idEmpenho, validacaoFinalizado
  }
}

export function AlterarEmpenhoStatusRequest(data, idEmpenho, validacaoFinalizado) {
  return {
    type: 'ALTERAR_EMPENHO_STATUS_REQUEST',
    data, idEmpenho, validacaoFinalizado
  }
}

export function ExcluirEmpenhoStatusRequest(id) {
  return {
    type: 'EXCLUIR_EMPENHO_STATUS_REQUEST',
    id
  }
}

export function ValidarEmpenhoStatusResponse(listaValidacaoEmpenhoStatus) {
  return {
    type: 'VALIDAR_EMPENHO_STATUS_RESPONSE',
    listaValidacaoEmpenhoStatus
  }
}

export function LimparListaValidacaoEmpenho() {
  return {
    type: 'LIMPAR_LISTA_VALIDACAO_EMPENHO'
  }
}

export function ListarMultaEmpenhoRequest(idEmpenho) {
  return {
    type: 'LISTAR_MULTA_EMPENHO_REQUEST', idEmpenho
  }
}

export function ListarMultaEmpenhoResponse(listarMultaEmpenho) {
  return {
    type: 'LISTAR_MULTA_EMPENHO_RESPONSE',
    listarMultaEmpenho
  }
}

export function ListarDiferencaEmpenhoRequest(idEmpenho) {
  return {
    type: 'LISTAR_DIFERENCA_EMPENHO_REQUEST', idEmpenho
  }
}

export function ListarDiferencaEmpenhoResponse(listarDiferencaEmpenho) {
  return {
    type: 'LISTAR_DIFERENCA_EMPENHO_RESPONSE',
    listarDiferencaEmpenho
  }
}

export function VoltarStatusEmpenhoRequest(id) {
  return {
    type: 'VOLTAR_STATUS_EMPENHO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}