import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {
    BuscarCaracteristicaRespostaPorNomeRequest,
    InserirCaracteristicaRespostaRequest,
    ListarCaracteristicaRespostaRequest
} from "../../store/modules/caracteristicaResposta/actions";
import { toast } from "react-toastify";
import { ListarBairroRequest } from "../../store/modules/bairro/actions";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

export default function ModalInserirDestinatario({ show, onHide, idSolicitacaoFornecimento, idTipoSolicitacao }) {

    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [nome, setNome] = useState('');

    useEffect(() => {
        moment.locale('pt-br');
    }, [dispatch])

    useEffect(() => {
        dispatch(ListarBairroRequest());
        dispatch(ListarCaracteristicaRespostaRequest());
        console.log(idSolicitacaoFornecimento)
    }, [])


    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {

            event.preventDefault();
        } else {

            if (ValidacoesInserir(event)) {
                InserirCaracteristicaResposta();
            };

        }

        setValidated(true);
    };

    function ValidacoesInserir(e) {

        e.preventDefault();

        // Valida o campo nome
        if (nome == null || nome == undefined
            || nome.length > 100 || nome.trim() == '') {
            toast.error(`Nome inválido`);
            return false;
        }

        return true;
    }

    function InserirCaracteristicaResposta() {

        let data = {
            'Id': 0,
            'IdCaracteristica': 17,
            'CodigoResposta': 0,
            'Descricao': nome.trim()
        }

        dispatch(InserirCaracteristicaRespostaRequest(data, idSolicitacaoFornecimento, idTipoSolicitacao))

        setNome('');
        onHide();

    }
    return (

        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastrar Destinatário
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="tamanhoTela">
                        <Col md={12}>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={12} controlId="formNome">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Nome é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Col>
                    </div>
                    <Col md={12}>
                        <Button type="submit"
                            variant="success" style={{ width: '100%' }}>Cadastrar</Button>
                    </Col>
                    <br/>
                    {
                        idSolicitacaoFornecimento == undefined ?
                        <center>
                            <h5 style={{ color: 'red' }}>
                                
                                Aviso: Ao cadastrar um destinário a página da solicitação será atualizada.
                                
                            </h5>
                            </center>
                            :
                            <></>
                    }
                </Form>
            </Modal.Body>

        </Modal>
    );
}