export function ListarAutorizacaoFornecimentoSubitemCustoMedOcorRequest(id) {
    return {
      type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST',
      id
    }
  }
  
  export function ListarAutorizacaoFornecimentoSubitemCustoMedOcorResponse(listaAutorizacaoFornecimentoSubitemCustoMedOcor) {
    return {
      type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_RESPONSE',
      listaAutorizacaoFornecimentoSubitemCustoMedOcor
    }
  }

  export function InserirAutorizacaoFornecimentoSubitemCustoMedOcorRequest(data) {
    return {
      type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST',
      data
    }
  }
  
  export function AlterarAutorizacaoFornecimentoSubitemCustoMedOcorRequest(data, id) {
    return {
      type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST',
      data,
      id
    }
  }
  
  export function ExcluirAutorizacaoFornecimentoSubitemCustoMedOcorRequest(data) {
    return {
      type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_REQUEST',
      data
    }
  }

  export function BuscarAutorizacaoFornecimentoSubitemCustoMedOcorPorIdRequest(id) {
    return {
      type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_POR_ID_REQUEST',
      id
    }
  }

  export function BuscarAutorizacaoFornecimentoSubitemCustoMedOcorPorIdResponse(autorizacaoFornecimentoSubitemCustoMedOcorCompleto) {
    return {
      type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_POR_ID_RESPONSE',
      autorizacaoFornecimentoSubitemCustoMedOcorCompleto
    }
  }
  
export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}