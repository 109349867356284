export function ListarCategoriaItemRequest() {
  return {
    type: 'LISTAR_CATEGORIA_ITEM_REQUEST'
  }
}

export function ListarCategoriaItemResponse(listaCategoriaItem) {
  return {
    type: 'LISTAR_CATEGORIA_ITEM_RESPONSE',
    listaCategoriaItem
  }
}

export function InserirCategoriaItemRequest(data) {
  return {
    type: 'INSERIR_CATEGORIA_ITEM_REQUEST',
    data
  }
}

export function AlterarCategoriaItemRequest(data) {
  return {
    type: 'ALTERAR_CATEGORIA_ITEM_REQUEST',
    data
  }
}

export function ExcluirCategoriaItemRequest(id) {
  return {
    type: 'EXCLUIR_CATEGORIA_ITEM_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}