import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { ListarFornecedorContaResponse, BuscarFornecedorContaPorIdResponse, Carregando } from './actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarFornecedorContaRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FornecedorConta`, { headers: headerParams.token });

    yield put(ListarFornecedorContaResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarFornecedorContaResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* ListarCompletoFornecedorContaRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/FornecedorConta/listarCompleto`,
      { idFornecedor: parseInt(model.idFornecedor) }, { headers: headerParams.token });

    yield put(ListarFornecedorContaResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarFornecedorContaResponse([]));
    yield put(Carregando(true));
  }
}

function* BuscarFornecedorContaPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FornecedorConta/${model.id}`, { headers: headerParams.token });

    yield put(BuscarFornecedorContaPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(BuscarFornecedorContaPorIdResponse(null));
    yield put(Carregando(true));
    
  }
}

function* InserirFornecedorContaRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/FornecedorConta`, model.data, { headers: headerParams.token });

   const result = yield call(api.post, `/api/FornecedorConta/listarCompleto`,
     { idFornecedor: parseInt(model.data.IdFornecedor) }, { headers: headerParams.token });
    
    yield put(ListarFornecedorContaResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Conta foi cadastrada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarFornecedorContaRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/FornecedorConta`, model.data, { headers: headerParams.token });

   const result = yield call(api.post, `/api/FornecedorConta/listarCompleto`,
      { idFornecedor: model.data.IdFornecedor }, { headers: headerParams.token });

    yield put(ListarFornecedorContaResponse(result.data.result));    
    yield put(Carregando(true));    

    toast.success(`Conta alterada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ExcluirFornecedorContaRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/FornecedorConta/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.post, `/api/FornecedorConta/listarCompleto`,
      { idFornecedor: parseInt(model.data.IdFornecedor) }, { headers: headerParams.token });
    
    yield put(ListarFornecedorContaResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`A Conta foi excluida com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_FORNECEDOR_CONTA_REQUEST', ListarFornecedorContaRequest),
  takeLatest('BUSCAR_FORNECEDOR_CONTA_POR_ID_REQUEST', BuscarFornecedorContaPorIdRequest),
  takeLatest('INSERIR_FORNECEDOR_CONTA_REQUEST', InserirFornecedorContaRequest),
  takeLatest('ALTERAR_FORNECEDOR_CONTA_REQUEST', AlterarFornecedorContaRequest),
  takeLatest('EXCLUIR_FORNECEDOR_CONTA_REQUEST', ExcluirFornecedorContaRequest),
  takeLatest('LISTAR_COMPLETO_FORNECEDOR_CONTA_REQUEST', ListarCompletoFornecedorContaRequest),
])