import { Col, Button, Card } from "react-bootstrap";
import { IoDocumentText } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { ListarClienteRequest } from '../../../store/modules/cliente/actions';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearchPlus } from "react-icons/fa";
import { ListarFornecedorRequest } from '../../../store/modules/fornecedor/actions';
import { ListarOrigemContratoRequest } from '../../../store/modules/origemContrato/actions';
import { useEffect, useState } from "react";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { useHistory } from "react-router-dom";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarTipoPessoaRequest } from "../../../store/modules/tipoPessoa/actions";
import { ListarMunicipiosRequest } from "../../../store/modules/municipio/actions";
import Header from '../../../components/Header/header';
import Loading from '../../../components/Loading/loading';
import MaterialTable from 'material-table';
import { toast } from "react-toastify";
import Select from 'react-select';

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaTipoPessoa = useSelector(state => state.tipoPessoa.listaTipoPessoa);
    const listaContratos = useSelector(state => state.contrato.listaContrato);
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const carregando = useSelector(state => state.contrato.carregando);
    const listaCliente = useSelector(state => state.cliente.listaCliente);
    const listaOrigemContrato = useSelector(state => state.origemContrato.listaOrigemContrato);
    const listaMunicipio = useSelector(state => state.municipio.listaMunicipio);

    const [selectedRow, setSelectedRow] = useState(null);
    const [id, setId] = useState(0);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {
        if (token.role.filter(r => r == "FornecedorMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {
        dispatch(ListarClienteRequest());
        dispatch(ListarFornecedorRequest());
        dispatch(ListarOrigemContratoRequest());
        dispatch(ListarTipoPessoaRequest());
        dispatch(ListarMunicipiosRequest());
        dispatch(ListarContratoUsuarioRequest());
    }, [])

    function ExcluirContrato(id) {
        if (id != 0) {
            //dispatch(ExcluirContratoRequest(id));
            setId(0);
        }
    }
    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <IoDocumentText size={25} color={"#000"} /> Fornecedores
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                {
                                    carregando ?
                                        <MaterialTable
                                            components={{
                                                Pagination: PatchedPagination,
                                            }}
                                            title={<Link to="/cadastroGeralFornecedor"><Button variant="success" style={{ width: '100%' }}>Novo Fornecedor</Button></Link>}
                                            columns={[
                                                {
                                                    title: 'Número do Fornecedor', field: 'id', defaultSort: 'desc', cellStyle: {
                                                        width: '10%',
                                                    }
                                                },
                                                {
                                                    title: 'Fornecedor', field: 'idFornecedor', cellStyle: {
                                                        width: '16%',
                                                    }
                                                },
                                                {
                                                    title: 'Endereço', field: 'endereco', cellStyle: {
                                                        width: '16%',
                                                    }, render: rowData =>
                                                        <span>{rowData.logradouro + ', ' + rowData.numero + ', ' + rowData.bairro}</span>
                                                },
                                                {
                                                    title: 'Tipo Pessoa', field: 'idTipoPessoa', cellStyle: {
                                                        width: '16%',
                                                    }
                                                },
                                                {
                                                    title: 'Cliente', field: 'idCliente', cellStyle: {
                                                        width: '16%',
                                                    }
                                                },
                                                {
                                                    title: 'Município', field: 'idMunicipio', cellStyle: {
                                                        width: '16%',
                                                    }
                                                },
                                                {
                                                    title: 'Ativo', field: 'idAtivo', sorting: true,
                                                    cellStyle: { width: '10%' },
                                                    customFilterAndSearch: (term, rowData) => term == rowData.idAtivo.toString(),
                                                    filterComponent: (props) => {
                                                        return (
                                                            <Select
                                                                placeholder="Selecione..."
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={[{ value: 'ativado', label: 'Ativado' },
                                                                { value: 'desativado', label: 'Desativado' },]}
                                                                onChange={(e) => {
                                                                    props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                        e != null && e.value == 'desativado' ? 'false' : null)
                                                                }}
                                                            />
                                                        )
                                                    },
                                                    editComponent: (props) => (
                                                        <input
                                                            type="checkbox"
                                                            checked={props.value}
                                                            onChange={(e) => props.onChange(e.target.checked)}
                                                        />
                                                    ),
                                                    render: rowData => (<input type="checkbox" checked={rowData.idAtivo} onChange={() => { }} />)
                                                }
                                            ]}
                                            data={
                                                listaFornecedor != undefined && listaCliente != undefined && listaTipoPessoa != undefined &&
                                                    listaMunicipio != undefined ?
                                                    listaFornecedor.map(fornecedor => {
                                                        return {
                                                            id: fornecedor.id,
                                                            idFornecedor: fornecedor.nomeFornecedor,
                                                            idTipoPessoa: fornecedor.tipoPessoa.descricao,
                                                            idCliente: fornecedor.cliente.nome,
                                                            idMunicipio: fornecedor.municipio.nome,
                                                            idAtivo: fornecedor.ativo,
                                                            logradouro: fornecedor.nomeLogradouro,
                                                            numero: fornecedor.numeroLocal,
                                                            bairro: fornecedor.bairro
                                                        }
                                                    })
                                                    :
                                                    []
                                            }
                                            actions={[
                                                rowData => ({
                                                    icon: () => <FaSearchPlus />,
                                                    onClick: (event, rowData) => { history.push(`/alterarGeralFornecedor/${rowData.id}`); }
                                                }),
                                            ]}
                                            options={{
                                                minBodyHeight: 500,
                                                paging: true,
                                                pageSize: 10, // Início
                                                emptyRowsWhenPaging: false,
                                                pageSizeOptions: [10, 50, 200, 300],
                                                headerStyle: {
                                                    backgroundColor: '#454545',
                                                    color: '#FFF',
                                                    fontWeight: "bold"
                                                },
                                                filterRowStyle: {
                                                    backgroundColor: "#FAFAFA"
                                                },
                                                actionsColumnIndex: -1,
                                                filtering: true,
                                                rowStyle: rowData => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })
                                            }}
                                            localization={{
                                                header: {
                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                    editRow: {
                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                        saveTooltip: '',
                                                        cancelTooltip: '',
                                                    },
                                                    deleteTooltip: '',
                                                },
                                                toolbar: {
                                                    searchTooltip: '',
                                                    searchPlaceholder: 'Pesquisar',
                                                    exportTitle: "",
                                                    exportCSVName: "Exportar como CSV",
                                                    exportPDFName: "Exportar como PDF",
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'linhas',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: '',
                                                    previousTooltip: '',
                                                    nextTooltip: '',
                                                    lastTooltip: ''
                                                },
                                                grouping: {
                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                    groupedBy: 'Agrupado por:'
                                                }
                                            }}
                                        />
                                        :
                                        <Loading />
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}