import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarMesesResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarMesesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Meses`, { headers: headerParams.token });

    yield put(ListarMesesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ListarMesesResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* InserirMesesRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/Meses`, model.data, { headers: headerParams.token });

    toast.success(`O Mes foi cadastrado com sucesso!`);

    const result = yield call(api.get, `/api/Meses`, { headers: headerParams.token });

    yield put(ListarMesesResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarMesesRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/Meses`, model.data, { headers: headerParams.token });

    toast.success(`Mes foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/Meses`, { headers: headerParams.token });

    yield put(ListarMesesResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}


export default all([
  takeLatest('LISTAR_MESES_REQUEST', ListarMesesRequest),
  takeLatest('INSERIR_MESES_REQUEST', InserirMesesRequest),
  takeLatest('ALTERAR_MESES_REQUEST', AlterarMesesRequest),
])