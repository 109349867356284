export function ListarObrasIntervencoesPorMesRequest() {
    return {
      type: 'LISTAR_OBRAS_INTERVENCOES_POR_MES_REQUEST'
    }
  }
  
  export function ListarObrasIntervencoesPorMesResponse(listaObrasIntervencoesPorMes) {
    return {
      type: 'LISTAR_OBRAS_INTERVENCOES_POR_MES_RESPONSE',
      listaObrasIntervencoesPorMes
    }
  }
  
  export function InserirObrasIntervencoesPorMesRequest(data) {
    return {
      type: 'INSERIR_OBRAS_INTERVENCOES_POR_MES_REQUEST',
      data
    }
  }

  export function BaixarPadraoImportacaoObrasIntervencoesPorMesRequest() {
    return {
      type: 'BAIXAR_PADRAO_IMPORTACAO_OBRAS_INTERVENCOES_POR_MES_REQUEST'
    }
  }

  export function ExcluirObrasIntervencoesPorMesRequest(ano) {
    return {
        type: 'EXCLUIR_OBRAS_INTERVENCOES_POR_MES_REQUEST',
        ano
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }