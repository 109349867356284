import { ListarSolicitacaoFornecimentoHistoricoRequest } from "../../../store/modules/solicitacaoFornecimentoHistorico/actions";
import { CarregarImagemSolicitacaoFornecimentoHistoricoRegFotoRequest,
    BaixarSolicitacaoFornecimentoHistoricoRegFotoRequest } from "../../../store/modules/solicitacaoFornecimentoHistoricoRegFoto/actions";
import { BaixarSolicitacaoFornecimentoHistoricoDocArqRequest } from "../../../store/modules/solicitacaoFornecimentoHistoricoDocArq/actions";
import { Button, Col, Card, Row, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import Loading from '../../../components/Loading/loading';
import MaterialTable from 'material-table';
import { RiHistoryFill, RiEyeLine } from "react-icons/ri";
import moment from 'moment'
import Header from "../../../components/Header/header";
import { AtualizarToken } from "../../../store/modules/global/actions";

export default function HistoricoSolicitacao() {

    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaSolicitacaoFornecimentoHistorico = useSelector(state =>
        state.solicitacaoFornecimentoHistorico.listaSolicitacaoFornecimentoHistorico);
    const carregando = useSelector(state => state.solicitacaoFornecimentoHistorico.carregando);

    const [showFoto, setShowFoto] = useState(false);

    const handleFotoShow = () => setShowFoto(true);
    const handleFotoClose = () => setShowFoto(false);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
        moment.locale('pt-br');

    }, [])

    useEffect(() => {
        if (id != undefined) {
            dispatch(ListarSolicitacaoFornecimentoHistoricoRequest(id));
        }
    }, [id])

    function VisualizarRegistroFotografico(linha) {
        dispatch(CarregarImagemSolicitacaoFornecimentoHistoricoRegFotoRequest(linha.regFoto));
        handleFotoShow();
    }

    function Baixar(linha) {
        if(linha.regFoto !== 0){
            dispatch(BaixarSolicitacaoFornecimentoHistoricoRegFotoRequest(linha.id, linha.regFoto));
        }
        else{
            dispatch(BaixarSolicitacaoFornecimentoHistoricoDocArqRequest(linha.id, linha.docArq));
        }
    }

    return (
        <>
            <Header></Header>
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <RiHistoryFill size={25} color={"#000"} /> Histórico Solicitação: {id}
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregando ?

                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title=''
                                                    columns={[
                                                        {
                                                            title: 'Número do Registro', field: 'id', cellStyle: {
                                                                width: '8%'
                                                            }, sorting: true,
                                                        },
                                                        {
                                                            title: 'Solicitação', field: 'idSolicitacao', cellStyle: {
                                                                width: '8%'
                                                            }
                                                        },
                                                        {
                                                            title: 'Descrição', field: 'descricao', cellStyle: {
                                                                width: '37%'
                                                            }
                                                        },
                                                        {
                                                            title: 'Data da Ação', field: 'dataAcao', cellStyle: {
                                                                width: '12%'
                                                            }
                                                        },
                                                        {
                                                            title: 'Usuário da Ação', field: 'usuario', cellStyle: {
                                                                width: '12%'
                                                            }
                                                        },
                                                        {
                                                            title: 'Ação', field: 'acao', cellStyle: {
                                                                width: '25%'
                                                            }
                                                        },
                                                        {
                                                            title: 'regFoto', field: 'regFoto', cellStyle: {
                                                                width: '25%'
                                                            }, hidden: true
                                                        },
                                                        {
                                                            title: 'docArq', field: 'docArq', cellStyle: {
                                                                width: '25%'
                                                            }, hidden: true
                                                        },
                                                    ]}
                                                    data={
                                                        listaSolicitacaoFornecimentoHistorico != undefined && listaSolicitacaoFornecimentoHistorico.length > 0 ?
                                                            listaSolicitacaoFornecimentoHistorico[0].map(lista => {
                                                                return {
                                                                    id: lista.id,
                                                                    idSolicitacao: lista.idSolicitacaoFornecimento,
                                                                    acao: lista.nomeAcao != null && lista.nomeAcao == "Inserção" ?
                                                                    <div className="divStatusGreen">{lista.nomeAcao}</div>
                                                                    : 
                                                                    lista.nomeAcao != null && lista.nomeAcao == "Alteração" ?
                                                                    <div className="divStatusWarning">{lista.nomeAcao}</div>
                                                                    :
                                                                    lista.nomeAcao != null && lista.nomeAcao == "Exclusão" ?
                                                                    <div className="divStatusDanger">{lista.nomeAcao}</div>
                                                                    : lista.nomeAcao,
                                                                    descricao: lista.descricao,
                                                                    usuario: lista.usuario.nome,
                                                                    regFoto : lista.numeroSolicitacaoFornecimentoRegistroFotografico,
                                                                    docArq : lista.numeroSolicitacaoFornecimentoDocumentoArquivo,
                                                                    dataAcao: moment(lista.dataInclusao).format('L LT'),
                                                                }
                                                            })
                                                        :
                                                        []
                                                    }
                                                    actions={[
                                                        rowData => ({
                                                            icon:  'visibility',
                                                            tooltip: 'Visualizar Documento',
                                                            hidden: rowData.regFoto ? false : true,
                                                            onClick: () => {VisualizarRegistroFotografico(rowData)}
                                                        }),
                                                        rowData => ({
                                                            icon: 'download',
                                                            tooltip: 'Baixar Documento',
                                                            onClick: () => {Baixar(rowData)}
                                                        })
                                                    ]}
                                                    options={{
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold"
                                                        },
                                                        filtering: true,
                                                        grouping: false,
                                                        actionsColumnIndex: -1,
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: 'Info'
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                                            },
                                                            deleteTooltip: ''
                                                        },
                                                        toolbar: {
                                                            searchTooltip: 'Pesquisar',
                                                            searchPlaceholder: 'Pesquisar'
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                :
                                                    <Loading />
                                            }
                                            </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal show={showFoto} onHide={handleFotoClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formFoto">
                                <div id="divImagemFotoSolicitacaoFornecimentoHistoricoRegFoto">
                                </div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    );
}