import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarSolicitacaoFornecimentoVersaoStatusResponse, ValidarSolicitacaoFornecimentoVersaoStatusResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { BuscarSolicitacaoFornecimentoPorIdResponse } from '../solicitacaoFornecimento/actions';
import { ListarNotificacaoResponse } from '../notificacao/actions';

function* ListarSolicitacaoFornecimentoVersaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdSolicitacaoFornecimento': model.IdSolicitacaoFornecimento
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirSolicitacaoFornecimentoVersaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    // Validação true, significa que o status não precisa validar.
    if (model.validacao) {

      yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus`, model.data, { headers: headerParams.token });

      toast.success(`Status finalizado!`);

      var data = {
        'IdSolicitacaoFornecimento': model.IdSolicitacaoFornecimento
      }

      const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

      yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

      const resultSolicitacao = yield call(api.get, `/api/SolicitacaoFornecimento/${model.IdSolicitacaoFornecimento}`, { headers: headerParams.token });

      yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSolicitacao.data.result));

    } else {

      // Validar Saldo
      const resultValidar = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/validar`, model.data, { headers: headerParams.token });

      var valida = false;
      var lista = resultValidar.data.result;
      lista.forEach(element => {
        if (element.saldoContratoAlemExcedente === true)
          valida = true;
      });

      // Se algum item excedeu mostra o modal
      if (valida) {

        yield put(ValidarSolicitacaoFornecimentoVersaoStatusResponse(resultValidar.data.result));

        var data = {
          'IdSolicitacaoFornecimento': parseInt(model.IdSolicitacaoFornecimento)
        }

        const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

        const resultSolicitacao = yield call(api.get, `/api/SolicitacaoFornecimento/${model.IdSolicitacaoFornecimento}`, { headers: headerParams.token });

        yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSolicitacao.data.result));

        // Se não, insere o status
      } else {

        yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus`, model.data, { headers: headerParams.token });

        toast.success(`Status finalizado!`);

        var data = {
          'IdSolicitacaoFornecimento': parseInt(model.IdSolicitacaoFornecimento)
        }

        const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });
        yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

        const resultSolicitacao = yield call(api.get, `/api/SolicitacaoFornecimento/${model.IdSolicitacaoFornecimento}`, { headers: headerParams.token });

        yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSolicitacao.data.result));

      }
    }

    const result = yield call(api.post, `/api/Notificacao/ListarCompleto`, { idUsuario: model.idUsuario },
      { headers: headerParams.token });

    yield put(ListarNotificacaoResponse(result.data.result));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response.data.message != 'Atualmente não existe notificações') {
          toast.error(e.response.data.message);
        }
      }

    yield put(ErrorResponse(e));
  }
}

function* AlterarSolicitacaoFornecimentoVersaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    /* Validação de saldo no contrato de subitens
    Ao alterar o status análise (aprovar), é preciso validar todos os itens e mostrar o modal para o usuário caso
    tenha excedido o saldo de algum item */

    // Validação true, significa que o status não precisa validar.
    if (model.validacao) {

      yield call(api.put, `/api/SolicitacaoFornecimentoVersaoStatus`, model.data, { headers: headerParams.token });

      toast.success(`Status foi Alterado!`);

      var data = {
        'IdSolicitacaoFornecimento': model.IdSolicitacaoFornecimento
      }

      const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

      yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

    } else {

      // Validar Saldo
      const resultValidar = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/validar`, model.data, { headers: headerParams.token });

      var valida = false;
      var lista = resultValidar.data.result;
      lista.forEach(element => {
        if (element.saldoContratoAlemExcedente === true)
          valida = true;
      });

      // Se algum item excedeu mostra o modal
      if (valida) {

        yield put(ValidarSolicitacaoFornecimentoVersaoStatusResponse(resultValidar.data.result));

        const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

      } else {

        yield call(api.put, `/api/SolicitacaoFornecimentoVersaoStatus`, model.data, { headers: headerParams.token });

        toast.success(`Status foi Alterado!`);

        var data = {
          'IdSolicitacaoFornecimento': model.IdSolicitacaoFornecimento
        }

        const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));
      }
    }

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSolicitacaoFornecimentoVersaoStatusRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/SolicitacaoFornecimentoVersaoStatus/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));

    var data = {
      'IdSolicitacaoFornecimento': parseInt(model.id)
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });
    yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

    const resultSolicitacao = yield call(api.get, `/api/SolicitacaoFornecimento/${model.id}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSolicitacao.data.result));

    toast.success(`O ultimo Status foi excluido!`);

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSolicitacaoFornecimentoVersaoStatusMantendoOsItensRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.get, `/api/SolicitacaoFornecimentoVersaoStatus/ExcluirStatusMantendoOsItens/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));

    var data = {
      'IdSolicitacaoFornecimento': parseInt(model.IdSolicitacaoFornecimento)
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });
    yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

    const resultSolicitacao = yield call(api.get, `/api/SolicitacaoFornecimento/${model.id}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSolicitacao.data.result));

    toast.success(`O Status foi excluido!`);

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST', ListarSolicitacaoFornecimentoVersaoStatusRequest),
  takeLatest('INSERIR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST', InserirSolicitacaoFornecimentoVersaoStatusRequest),
  takeLatest('ALTERAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST', AlterarSolicitacaoFornecimentoVersaoStatusRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST', ExcluirSolicitacaoFornecimentoVersaoStatusRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_MANTENDO_OS_ITENS_REQUEST', ExcluirSolicitacaoFornecimentoVersaoStatusMantendoOsItensRequest)
])