export function ListarStatusSolicitacaoFornecimentoRequest() {
  return {
    type: 'LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_REQUEST'
  }
}

export function ListarStatusSolicitacaoFornecimentoResponse(listaStatusSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_RESPONSE',
    listaStatusSolicitacaoFornecimento
  }
}

export function ListarStatusSolicitacaoFornecimentoCompletoRequest(idSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_COMPLETO_REQUEST', idSolicitacaoFornecimento
  }
}

export function ListarStatusSolicitacaoFornecimentoCompletoResponse(listaStatusSolicitacaoFornecimentoCompleto) {
  return {
    type: 'LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_COMPLETO_RESPONSE',
    listaStatusSolicitacaoFornecimentoCompleto
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
