import { Modal, Button, Form, Col } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { TransferirAfDefinitivaParcialRequest } from "../../store/modules/empenho/actions";

export default function ModalTransferenciaAfEmpenhoParcialDefinitiva({ show, onHide, idAutorizacao, idEmpenhoOrigem, definitivaParcial }) {

    // definitivaParcial true = passar para definitiva
    // definitivaParcial false = passar para parcial
    const dispatch = useDispatch();

    function Transferir() {

        dispatch(TransferirAfDefinitivaParcialRequest(
            {
                'IdAutorizacao': idAutorizacao,
                'IdEmpenhoOrigem': idEmpenhoOrigem,
                'DefinitivaParcial': definitivaParcial
            }
        ));
        onHide();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Col} md={12} controlId="formIdEmpenho">
                    <Form.Label>
                        <h2 style={{ color: 'red' }}>{`Deseja transferir a autorização número: ${idAutorizacao} para 
                            ${definitivaParcial ? "Definitiva" : "Parcial"}? `}
                        </h2>
                    </Form.Label>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={Transferir}>
                    {'Transferir'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}