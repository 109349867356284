import produce from 'immer';

const initialState = { listaAutorizacaoFornecimentoSubitemCustoMedOcor: undefined,
                       autorizacaoFornecimentoSubitemCustoMedOcorCompleto: undefined };

export default function autorizacaoFornecimentoSubitemCustoMedOcor(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_RESPONSE':
        return produce(state, draft => {
            draft.listaAutorizacaoFornecimentoSubitemCustoMedOcor = action.listaAutorizacaoFornecimentoSubitemCustoMedOcor;
        });
        
    case 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_OCOR_POR_ID_RESPONSE':
        return produce(state, draft => {
            draft.autorizacaoFornecimentoSubitemCustoMedOcorCompleto = action.autorizacaoFornecimentoSubitemCustoMedOcorCompleto;
        });
    
     case 'CARREGANDO':
        return produce(state, draft => {
            draft.carregando = action.status
        });  
    
    default:
      return state;
  }
}