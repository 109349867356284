import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse, ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse } from './actions';
import { ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse } from '../autorizacaoFornecimento/actions';
import { BuscarEmpenhoPorIdResponse } from '../empenho/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/listarCompleto`,
      { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var dados = {
      ListaEmpenhoAutorizacaoFornecimentoSubitemCustoMed: model.data
    }

    yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed`, dados, { headers: headerParams.token });

    toast.success(`Itens cadastrado(s) com sucesso!`);

    const result = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/listarCompleto`,
      { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(result.data.result));

    const resultListaModal = yield call(api.post, `/api/AutorizacaoFornecimento/ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem`,
      {
        IdEmpenho: model.idEmpenho,
        IdContrato: model.idContrato,
        IdFornecedor: model.idFornecedor
      }, { headers: headerParams.token });

    yield put(ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse(resultListaModal.data.result))

    // Buscar empenho pra atualizar valores pagos
    const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed`, model.data, { headers: headerParams.token });

    toast.success(`EmpenhoAutorizacaoFornecimentoSubitemCustoMed foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed`, { headers: headerParams.token });

    yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

function* ExcluirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/${model.id}`, { headers: headerParams.token });

    toast.success(`Empenho item id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/listarCompleto`,
      { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(result.data.result));

    // Listar op��es do modal de inser��o de subitem
    const resultListaModal = yield call(api.post, `/api/AutorizacaoFornecimento/ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem`,
      {
        IdEmpenho: model.idEmpenho,
        IdContrato: model.idContrato,
        IdFornecedor: model.idFornecedor
      }, { headers: headerParams.token });

    yield put(ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse(resultListaModal.data.result))

    // Buscar empenho pra atualizar valores pagos
    const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

function* ExcluirListaSubitensAutorizacaoMedicaoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var dados = {
      Id: model.listaId
    }

    yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/listaId`, dados, { headers: headerParams.token });

    //toast.success(`Empenho item id: ${model.id} foi excluido!`);
    toast.success(`Itens excluídos com sucesso!`);

    const result = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/listarCompleto`,
      { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(result.data.result));

    // Listar opções do modal de inserção de subitem
    const resultListaModal = yield call(api.post, `/api/AutorizacaoFornecimento/ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem`,
      {
        IdEmpenho: model.idEmpenho,
        IdContrato: model.idContrato,
        IdFornecedor: model.idFornecedor
      }, { headers: headerParams.token });

    yield put(ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse(resultListaModal.data.result))

    // Buscar empenho pra atualizar valores pagos
    const resultEmp = yield call(api.get, `/api/Empenho/${model.idEmpenho}`, { headers: headerParams.token });

    yield put(BuscarEmpenhoPorIdResponse(resultEmp.data.result));

    yield put(Carregando(true))

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

function* ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/EmpenhoAutorizacaoFornecimentoSubitemCustoMed/ListarCompletoHierarquiaEmpenhoAutorizacaoSubitem`,
      {
        IdEmpenho: model.idEmpenho,
        IdContrato: model.idContrato,
        IdFornecedor: model.idFornecedor
      }, { headers: headerParams.token });

    yield put(ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(result.data.result))

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST', ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest),
  takeLatest('INSERIR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST', InserirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest),
  takeLatest('ALTERAR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST', AlterarEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest),
  takeLatest('EXCLUIR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST', ExcluirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest),
  takeLatest('EXCLUIR_LISTA_SUBITENS_AUTORIZACAO_MEDICAO_REQUEST', ExcluirListaSubitensAutorizacaoMedicaoRequest),
  takeLatest('LISTAR_HIERARQUIA_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST', ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest)
])