import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarStatusEmpenhoRequest } from "../../../store/modules/statusEmpenho/actions";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import {
    ListarEmpenhoStatusRequest, InserirEmpenhoStatusRequest, AlterarEmpenhoStatusRequest, LimparListaValidacaoEmpenho,
    VoltarStatusEmpenhoRequest
} from "../../../store/modules/empenhoStatus/actions";
import { BuscarContratoPorIdRequest } from "../../../store/modules/contrato/actions";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { toast } from 'react-toastify';
import { BuscarUsuarioPorIdRequest } from "../../../store/modules/usuario/actions";
import ModalVerificacaoSaldoSubitem from "../../../components/ModalVerificacaoSaldoSubitem/modalVerificacaoSaldoSubitem";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function Status() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);
    const listaEmpenhoStatus = useSelector(state => state.empenhoStatus.listaEmpenhoStatus);
    const empenhoCompleto = useSelector(state => state.empenho.empenhoCompleto);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const listaValidacaoEmpenhoStatus = useSelector(state => state.empenhoStatus.listaValidacaoEmpenhoStatus);

    const [observacao, setObservacao] = useState("");
    const [show, setShow] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showNovoStatus, setShowNovoStatus] = useState(false);
    const [status, setStatus] = useState('');
    const [statusAnalise, setStatusAnalise] = useState(null);
    const [statusFaturamento, setStatusFaturamento] = useState(null);
    const [statusAnaliseAcao, setStatusAnaliseAcao] = useState('');
    const [statusExcluir, setStatusExcluir] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [validated, setValidated] = useState(false);
    const [showStatusReprovado, setShowStatusReprovado] = useState(false);
    const [showStatusAprovado, setShoStatusAprovado] = useState(false);
    const [showStatusFaturamentoAprovado, setShowStatusFaturamentoAprovado] = useState(false);
    const [showValidacao, setShowValidacao] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const handleCloseNovoStatus = () => setShowNovoStatus(false);
    const handleShowNovoStatus = () => setShowNovoStatus(true);
    const handleCloseStatusReprovado = () => setShowStatusReprovado(false);
    const handleShowStatusReprovado = () => setShowStatusReprovado(true);
    const handleCloseStatusAprovado = () => setShoStatusAprovado(false);
    const handleShowStatusAprovado = () => setShoStatusAprovado(true);
    const handleCloseStatusFaturamentoAprovado = () => setShowStatusFaturamentoAprovado(false);
    const handleShowStatusFaturamentoAprovado = () => setShowStatusFaturamentoAprovado(true);
    const handleCloseValidacao = () => setShowValidacao(false);
    const handleShowValidacao = () => setShowValidacao(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "EmpenhoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "EmpenhoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "EmpenhoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "EmpenhoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarStatusEmpenhoRequest());

        if (id != undefined) {
            dispatch(ListarEmpenhoStatusRequest(id));
        }

        dispatch(BuscarUsuarioPorIdRequest(usuario.id));

    }, [id])

    useEffect(() => {

        if (empenhoCompleto != null) {
            dispatch(BuscarContratoPorIdRequest(empenhoCompleto.idContrato));
        }


    }, [empenhoCompleto])

    useEffect(() => {
        if (listaValidacaoEmpenhoStatus != null && listaValidacaoEmpenhoStatus != undefined) {
            handleShowValidacao();
        }
    }, [listaValidacaoEmpenhoStatus])

    // Confirmação para reprovar empenho
    const handleSubmit = (event) => {

        if (observacao.trim() == '') {
            event.preventDefault();
        } else {
            CancelarEmpenho(event);
        }

        setValidated(true);
    };

    function InserirEmpenhoStatus() {

        let proximoStatus = '';

        if (listaStatusEmpenho != undefined) {
            proximoStatus = listaStatusEmpenho.filter(x => x.descricao == status);
        }

        // Se for status análise, verificar se o usuário tem permissao
        if (proximoStatus.length > 0 && proximoStatus[0].statusAprovaDuasPartes == true) {

            // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
            if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaSubitemEmpenho === true).length === 0) {

                toast.error('Você não tem permissão de iniciar a análise de item do empenho.');
                return;
            }
        }

        // Se for status faturamento, verificar se o usuário tem permissao
        if (proximoStatus.length > 0 && proximoStatus[0].statusFaturamento == true) {

            // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
            if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.faturamentoEmpenho === true).length === 0) {

                toast.error('Você não tem permissão de iniciar o faturamento.');
                return;
            }
        }

        // Se for status finalizado, verificar se o usuário tem permissao
        if (proximoStatus.length > 0 && proximoStatus[0].finalizaEmpenho == true) {

            // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
            if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.finalizaEmpenho === true).length === 0) {

                toast.error('Você não tem permissão de finalizar o empenho.');
                return;
            }
        }

        if (proximoStatus.length > 0) {

            let data = {
                'Id': 0,
                'IdEmpenho': parseInt(id),
                'IdStatusEmpenho': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'Observacao': '',
                'StatusAprovadoCliente': false,
                'StatusAprovadoFornecedor': false,
                'StatusReprovado': false,
                'DataAprovacaoCliente': null,
                'DataAprovacaoFornecedor': null,
                'StatusFaturamentoAprovado': false,
                'IdUsuarioAprovacaoCliente': null,
                'IdUsuarioAprovacaoFornecedor': null
            }

            // Só vai pra validação de subitens quando for finalizar
            if (proximoStatus[0].finalizaEmpenho) {
                dispatch(InserirEmpenhoStatusRequest(data, id, false));
            } else {
                dispatch(InserirEmpenhoStatusRequest(data, id, true));
            }

        }

        handleCloseStatus();
    }

    // Altera o status faturamento (finaliza)
    function MudarEmpenhoStatusFaturamento() {

        // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.faturamentoEmpenho === true).length === 0) {

            toast.error('Você não tem permissão de finalizar o faturamento.');
            setStatusFaturamento('');
            return;
        }

        if (usuarioCompleto != null) {
            let data = {
                'Id': statusFaturamento.id,
                'IdEmpenho': statusFaturamento.idEmpenho,
                'IdStatusEmpenho': statusFaturamento.idStatusEmpenho,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'Observacao': '',
                'StatusAprovadoCliente': statusFaturamento.statusAprovadoCliente,
                'StatusAprovadoFornecedor': statusFaturamento.statusAprovadoFornecedor,
                'StatusReprovado': statusFaturamento.statusReprovado,
                'DataAprovacaoCliente': statusFaturamento.dataAprovacaoCliente,
                'DataAprovacaoFornecedor': statusFaturamento.dataAprovacaoFornecedor,
                'StatusFaturamentoAprovado': true,
                'IdUsuarioAprovacaoCliente': statusFaturamento.idUsuarioAprovacaoCliente,
                'IdUsuarioAprovacaoFornecedor': statusFaturamento.idUsuarioAprovacaoFornecedor
            }

            dispatch(AlterarEmpenhoStatusRequest(data, id));
            setStatusFaturamento('');
            handleCloseStatusFaturamentoAprovado();
        }
    }

    // Altera o status de análise item
    function AlterarEmpenhoStatusAnaliseSubitem(event) {

        if (observacao.trim() == '' && statusAnaliseAcao == 'Reprovado') {
            event.preventDefault();
        }
        else {

            // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
            if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaSubitemEmpenho === true).length === 0) {

                toast.error('Você não tem permissões de análise de item do empenho.');
                setStatusAnaliseAcao(null);
                setStatusAnalise('');
                setObservacao('');
                return;
            }

            if (usuarioCompleto != null) {
                let data = {
                    'Id': statusAnalise.id,
                    'IdEmpenho': statusAnalise.idEmpenho,
                    'IdStatusEmpenho': statusAnalise.idStatusEmpenho,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'Observacao': observacao == '' ? '' : observacao,
                    'StatusAprovadoCliente': statusAnaliseAcao == 'Aprovado' && usuarioCompleto.usuarioFornecedor == false ? true : statusAnalise.statusAprovadoCliente,
                    'StatusAprovadoFornecedor': statusAnaliseAcao == 'Aprovado' && usuarioCompleto.usuarioFornecedor == true ? true : statusAnalise.statusAprovadoFornecedor,
                    'StatusReprovado': statusAnaliseAcao == 'Reprovado' ? true : statusAnalise.statusReprovado,
                    'DataAprovacaoCliente': statusAnaliseAcao == 'Aprovado' && usuarioCompleto.usuarioFornecedor == false ? moment((new Date())).add('hours', 2) : statusAnalise.dataAprovacaoCliente,
                    'DataAprovacaoFornecedor': statusAnaliseAcao == 'Aprovado' && usuarioCompleto.usuarioFornecedor == true ? moment((new Date())).add('hours', 2) : statusAnalise.dataAprovacaoFornecedor,
                    'StatusFaturamentoAprovado': statusAnalise.statusFaturamentoAprovado,
                    'IdUsuarioAprovacaoCliente': statusAnaliseAcao == 'Aprovado' && usuarioCompleto.usuarioFornecedor == false ? usuario.id : statusAnalise.idUsuarioAprovacaoCliente,
                    'IdUsuarioAprovacaoFornecedor': statusAnaliseAcao == 'Aprovado' && usuarioCompleto.usuarioFornecedor == true ? usuario.id : statusAnalise.idUsuarioAprovacaoFornecedor,
                    'StatusReprovadoCliente': statusAnaliseAcao == 'Reprovado' && usuarioCompleto.usuarioFornecedor == false ? true : false,
                }

                if (statusAnaliseAcao == 'Aprovado') {
                    dispatch(AlterarEmpenhoStatusRequest(data, id, false));
                } else {
                    dispatch(AlterarEmpenhoStatusRequest(data, id, true));
                }

                setStatusAnaliseAcao(null);
                setStatusAnalise('');
                handleCloseStatusAprovado();
                handleCloseStatusReprovado();
                setObservacao('');
            }
        }
        setValidated(true);
    }

    function CancelarEmpenho(e) {

        e.preventDefault();

        let proximoStatus = [];

        if (listaStatusEmpenho != undefined) {
            proximoStatus = listaStatusEmpenho.filter(x => x.cancelaEmpenho == true);
        }

        // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.cancelaEmpenho === true).length === 0) {

            toast.error('Você não tem permissão de cancelar/reativar o status do empenho.');
            return;
        }

        if (observacao !== undefined) {
            if (observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return;
            }
        }

        // Verifica se o empenho ja esta finalizado
        if (listaStatusEmpenho != undefined && listaEmpenhoStatus != undefined &&
            listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0) {
            toast.error('O empenho não pode ser cancelado');
            return;
        }

        // Insere o status Cancelado
        if (proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdEmpenho': parseInt(id),
                'IdStatusEmpenho': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'Observacao': observacao,
                'StatusAprovadoCliente': false,
                'StatusAprovadoFornecedor': false,
                'StatusReprovado': false,
                'DataAprovacaoCliente': null,
                'DataAprovacaoFornecedor': null,
                'StatusFaturamentoAprovado': false,
                'IdUsuarioAprovacaoCliente': null,
                'IdUsuarioAprovacaoFornecedor': null
            }

            dispatch(InserirEmpenhoStatusRequest(data, id, true));
            setObservacao('');
            setValidated(false);
        }

        handleClose();
    }

    // Inserir novo empenho status
    function InserirNovoEmpenhoStatus() {

        let proximoStatus = [];

        if (listaStatusEmpenho != undefined) {
            proximoStatus = listaStatusEmpenho.filter(x => x.iniciaEmpenho == true);
        }

        // Verifica se o usuário tem permissão de cancelar/reativar o status do empenho
        if (contratoCompleto != null && listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.cancelaEmpenho === true).length === 0) {

            toast.error('Você não tem permissão de cancelar/reativar o status do empenho.');
            return;
        }

        if (proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdEmpenho': parseInt(id),
                'IdStatusEmpenho': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'Observacao': '',
                'StatusAprovadoCliente': false,
                'StatusAprovadoFornecedor': false,
                'StatusReprovado': false,
                'DataAprovacaoCliente': null,
                'DataAprovacaoFornecedor': null,
                'StatusFaturamentoAprovado': false,
                'IdUsuarioAprovacaoCliente': null,
                'IdUsuarioAprovacaoFornecedor': null
            }

            dispatch(InserirEmpenhoStatusRequest(data, id, true));
            setValidated(false);
        }

        handleCloseNovoStatus();
    }

    // Abrir o modal de reprovar análise
    function AlterarEmpenhoStatusAnaliseSubitemReprovada(status, statusNome) {
        setStatusAnaliseAcao(statusNome);
        setStatusAnalise(status);
        handleShowStatusReprovado();
    }

    // Abrir o modal de aprovar análise
    function AlterarEmpenhoStatusAnaliseSubitemAprovada(status, statusNome) {
        setStatusAnaliseAcao(statusNome);
        setStatusAnalise(status);
        handleShowStatusAprovado();
    }

    function InserirStatusClick(statusParametro) {
        setStatus(statusParametro);
        handleShowStatus();
    }

    function InserirStatusCanceladoClick(statusParametro) {
        setStatus(statusParametro);
        handleShow();
    }

    function ResetarStatus(idStatus) {
        setStatusExcluir(idStatus);
        handleShowNovoStatus();
    }

    // Abrir o modal finalizar faturamento
    function FinalizarStatusFaturamento(status) {
        setStatusFaturamento(status);
        handleShowStatusFaturamentoAprovado();
    }

    function VoltarStatus(){
        dispatch(VoltarStatusEmpenhoRequest(parseInt(id)));
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                <Row>
                                    <Col md='auto'>
                                        <div>
                                            {
                                                inserir == true ?
                                                    <Button
                                                        disabled={
                                                            id != undefined && contratoCompleto != null && listaStatusEmpenho != undefined && listaEmpenhoStatus != undefined &&
                                                                listaContratoUsuario != null && listaContratoUsuario != undefined
                                                                ?
                                                                (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                    && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.cancelaEmpenho === true).length === 0)
                                                                    ?
                                                                    true
                                                                    :
                                                                    // Verifica se o empenho ja esta finalizado ou cancelado
                                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                                        listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0
                                                                        ||
                                                                        listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                                            listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                                        ?
                                                                        true
                                                                        :
                                                                        false
                                                                :
                                                                null
                                                        }
                                                        variant={"danger"}
                                                        style={{ margin: '10px' }}
                                                        onClick={() => InserirStatusCanceladoClick("Cancelado")}>
                                                        Cancelar Empenho
                                                    </Button>
                                                    :
                                                    ''
                                            }
                                            {
                                                inserir == true ?
                                                    id != undefined && usuarioCompleto != null && usuarioCompleto.grupoAcesso &&
                                                    usuarioCompleto.grupoAcesso.nomeGrupo === "Super Administrador" ?
                                                        <Button
                                                            variant={"danger"}
                                                            onClick={() => VoltarStatus()}>
                                                            Voltar Status
                                                        </Button>
                                                        :
                                                        ''
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                            columns={[
                                {
                                    title: 'Status', field: 'status', cellStyle: {
                                        width: '25%'
                                    }
                                },
                                {
                                    title: 'Nome', field: 'usuario', cellStyle: {
                                        width: '15%'
                                    }
                                },
                                {
                                    title: 'Data', field: 'dataInclusao', cellStyle: {
                                        width: '15%'
                                    }
                                },
                                {
                                    title: 'Ação', field: 'aprovacao', cellStyle: {
                                        width: '25%'
                                    }
                                },
                                {
                                    title: 'Cliente Aprovado', field: 'clienteAprovado', cellStyle: {
                                        width: '10%'
                                    },
                                    editable: 'never',
                                    render: rowData =>
                                        rowData.statusReprovado == true && rowData.statusReprovadoCliente == true ?
                                            <RiCloseCircleFill
                                                className="iconSubitemFalse"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={'Análise de item reprovada pelo cliente ' + rowData.usuario + ' na data: ' + rowData.dataInclusao}
                                                trigger="hover focus"
                                            ></RiCloseCircleFill>
                                            :
                                            rowData.statusAprovado && rowData.clienteAprovado == true ?
                                                <RiCheckboxCircleFill
                                                    className="iconSubitemTrue"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={'Análise de item aprovada pelo cliente ' + rowData.nomeUsuarioAprovacaoCliente + ' na data: ' + rowData.dataAprovacaoCliente}
                                                    trigger="hover focus"
                                                ></RiCheckboxCircleFill>
                                                :
                                                ''
                                },
                                {
                                    title: 'Fornecedor Aprovado', field: 'fornecedorAprovado', cellStyle: {
                                        width: '10%'
                                    },
                                    editable: 'never',
                                    render: rowData =>
                                        rowData.statusReprovado == true && rowData.statusReprovadoCliente == false ?
                                            <RiCloseCircleFill
                                                className="iconSubitemFalse"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={'Análise de item reprovada pelo fornecedor ' + rowData.usuario + ' na data: ' + rowData.dataInclusao}
                                                trigger="hover focus"
                                            ></RiCloseCircleFill>
                                            :
                                            rowData.statusAprovado && rowData.fornecedorAprovado == true ?
                                                <RiCheckboxCircleFill
                                                    className="iconSubitemTrue"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={'Análise de item aprovada pelo fornecedor ' + rowData.nomeUsuarioAprovacaoFornecedor + ' na data: ' + rowData.dataAprovacaoFornecedor}
                                                    trigger="hover focus"
                                                ></RiCheckboxCircleFill>
                                                :
                                                ''
                                }
                            ]}
                            data={
                                id != undefined &&
                                    listaStatusEmpenho != undefined && listaEmpenhoStatus != undefined ?
                                    listaEmpenhoStatus.map(status => {
                                        if (listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                            listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0 &&
                                            status.statusEmpenho != null && status.statusEmpenho.statusAprovaDuasPartes &&
                                            status.statusReprovado != true && (status.statusAprovadoCliente == false || status.statusAprovadoFornecedor == false)) {

                                            return null;
                                        } else {
                                            return {
                                                dataAprovacaoCliente: moment(status.dataAprovacaoCliente).format('L LT'),
                                                dataAprovacaoFornecedor: moment(status.dataAprovacaoFornecedor).format('L LT'),
                                                nomeUsuarioAprovacaoFornecedor: status.usuarioAprovacaoCliente.nome,
                                                nomeUsuarioAprovacaoCliente: status.usuarioAprovacaoFornecedor.nome,
                                                statusReprovado: status.statusReprovado,
                                                statusReprovadoCliente: status.statusReprovadoCliente,
                                                statusAprovado:
                                                    status.statusEmpenho != null ?
                                                        status.statusEmpenho.statusAprovaDuasPartes : '',
                                                observacao: status.observacao,
                                                clienteAprovado: status.statusAprovadoCliente,
                                                fornecedorAprovado: status.statusAprovadoFornecedor,
                                                statusFaturamentoAprovado: status.statusFaturamentoAprovado,
                                                status:
                                                    status.statusEmpenho == null ?
                                                        '' :
                                                        status.statusEmpenho.cancelaEmpenho == true ?
                                                            <div className="divStatusDanger">{status.statusEmpenho.descricao}</div>
                                                            :
                                                            status.statusEmpenho.statusFaturamento == true && status.statusFaturamentoAprovado == false ?
                                                                <div className="divStatusNormal">{status.statusEmpenho.descricao}</div> :
                                                                status.statusEmpenho.statusAprovaDuasPartes == false ?
                                                                    <div className="divStatusGreen">{status.statusEmpenho.descricao}</div>
                                                                    :
                                                                    status.statusAprovadoCliente && status.statusAprovadoFornecedor ?
                                                                        <div className="divStatusGreen">{status.statusEmpenho.descricao} (Aprovada)</div>
                                                                        :
                                                                        status.statusReprovado == true ?
                                                                            <div className="divStatusDanger">{status.statusEmpenho.descricao} (Reprovada)</div>
                                                                            :
                                                                            <div className="divStatusNormal">{status.statusEmpenho.descricao}</div>,
                                                usuario:
                                                    status.usuario != null && status.statusEmpenho != null && status.statusEmpenho.statusFaturamento == true
                                                        && status.statusFaturamentoAprovado == false ?
                                                        ''
                                                        :
                                                        status.statusEmpenho.statusAprovaDuasPartes != true ?
                                                            status.usuario.nome
                                                            :
                                                            status.statusEmpenho != null && status.statusEmpenho.statusAprovaDuasPartes == true
                                                                && ((status.statusAprovadoCliente == true && status.statusAprovadoFornecedor == true) ||
                                                                    status.statusReprovado == true) ?
                                                                status.usuario.nome
                                                                : '',
                                                dataInclusao:
                                                    status.statusEmpenho != null && status.statusEmpenho.statusFaturamento == true
                                                        && status.statusFaturamentoAprovado == false ?
                                                        ''
                                                        :
                                                        status.statusEmpenho.statusAprovaDuasPartes != true ?
                                                            moment(status.dataStatus).format('L LT')
                                                            :
                                                            status.statusEmpenho.statusAprovaDuasPartes == true
                                                                && ((status.statusAprovadoCliente == true && status.statusAprovadoFornecedor == true) ||
                                                                    status.statusReprovado == true) ?
                                                                moment(status.dataStatus).format('L LT') : '',
                                                aprovacao:
                                                    listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                        listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                        listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                            listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0 ?
                                                        // Se o status atual é cancelado, libera o botão de reativar empenho
                                                        status.statusEmpenho != null && status.statusEmpenho.cancelaEmpenho == true ?
                                                            <Button
                                                                variant="success"
                                                                onClick={() => ResetarStatus(status.id)}
                                                            >Reativar Empenho</Button>
                                                            :
                                                            ''
                                                        :
                                                        status.statusEmpenho != null && status.statusEmpenho.statusAprovaDuasPartes ?
                                                            status.statusAprovadoCliente && status.statusAprovadoFornecedor ?
                                                                ''
                                                                :
                                                                status.statusReprovado ?
                                                                    <Button variant="success"
                                                                        onClick={() => ResetarStatus(status.id)}
                                                                    >Reativar Empenho</Button>
                                                                    :
                                                                    usuarioCompleto != null && usuarioCompleto.usuarioFornecedor == true && status.statusAprovadoFornecedor ?
                                                                        'Aguardando a aprovação do Cliente!'
                                                                        :
                                                                        usuarioCompleto != null && usuarioCompleto.usuarioFornecedor == false && status.statusAprovadoCliente ?
                                                                            'Aguardando a aprovação do Forncedor!'
                                                                            :
                                                                            <Row>
                                                                                <Col md={5}>
                                                                                    <Button
                                                                                        variant="success"
                                                                                        onClick={() => AlterarEmpenhoStatusAnaliseSubitemAprovada(status, 'Aprovado')}
                                                                                    > Aprovar Empenho</Button>
                                                                                </Col>
                                                                                <Col md={5}>
                                                                                    <Button
                                                                                        variant="danger"
                                                                                        onClick={() => AlterarEmpenhoStatusAnaliseSubitemReprovada(status, 'Reprovado')}
                                                                                    > Reprovar Empenho</Button>
                                                                                </Col>
                                                                            </Row>
                                                            :
                                                            status.statusEmpenho != null && status.statusEmpenho.statusFaturamento ?
                                                                status.statusFaturamentoAprovado ?
                                                                    ''
                                                                    :
                                                                    <Button variant="success"
                                                                        onClick={() => FinalizarStatusFaturamento(status)}
                                                                    >Finalizar Faturamento</Button>
                                                                :
                                                                ''
                                            }
                                        }
                                        // Concatenar a lista de status restantes, que ainda não foram confirmados
                                    }).filter(Boolean).concat(

                                        listaStatusEmpenho.map(statusSol => {
                                            // Verifica se o Empenho esta cancelado, se sim, não mostra os possíveis status futuros
                                            // Ou se ta no analise mas foi reprovado
                                            if (listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0
                                                ||
                                                (listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true).length > 0 &&
                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                        listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true)[0].id).length > 0 &&
                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                        listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true)[0].id)[0].statusReprovado)
                                            ) {
                                                return null
                                            } else {
                                                // Verifica se o status possui anterior ou ja foi confirmado
                                                if (statusSol.idStatusEmpenhoAnterior == null || listaEmpenhoStatus.
                                                    filter(x => x.idStatusEmpenho == statusSol.id).length > 0) {
                                                    return null
                                                } else {
                                                    return {
                                                        id: '',
                                                        status: <div className="divStatusNormal">{statusSol.descricao}</div>,
                                                        usuario: '',
                                                        dataInclusao: '',
                                                        aprovacao:
                                                            listaEmpenhoStatus.filter
                                                                (x => x.idStatusEmpenho == statusSol.idStatusEmpenhoAnterior).length > 0 &&
                                                                listaStatusEmpenho.filter(x => x.id == statusSol.idStatusEmpenhoAnterior).length > 0 &&
                                                                (listaStatusEmpenho.filter(x => x.id == statusSol.idStatusEmpenhoAnterior)[0].statusAprovaDuasPartes
                                                                    ?
                                                                    (listaEmpenhoStatus.filter
                                                                        (x => x.idStatusEmpenho
                                                                            == statusSol.idStatusEmpenhoAnterior)[0].statusAprovadoFornecedor == true) &&
                                                                    (listaEmpenhoStatus.filter
                                                                        (x => x.idStatusEmpenho
                                                                            == statusSol.idStatusEmpenhoAnterior)[0].statusAprovadoCliente == true)
                                                                    :
                                                                    true)
                                                                ?
                                                                statusSol.statusAprovaDuasPartes == true ?
                                                                    <Button
                                                                        disabled={
                                                                            listaStatusEmpenho != undefined && contratoCompleto != null && listaEmpenhoStatus != undefined
                                                                                ?
                                                                                (listaContratoUsuario != undefined && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                    && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.analisaSubitemEmpenho === true).length === 0)
                                                                                    ?
                                                                                    true
                                                                                    :
                                                                                    false
                                                                                :
                                                                                null
                                                                        }
                                                                        variant="success"
                                                                        onClick={() => InserirStatusClick(statusSol.descricao)}>
                                                                        Fazer Análise
                                                                    </Button>
                                                                    :
                                                                    statusSol.statusFaturamento == true ?
                                                                        <Button
                                                                            variant="success"
                                                                            onClick={() => InserirStatusClick(statusSol.descricao)}>
                                                                            Iniciar Faturamento
                                                                        </Button>
                                                                        :
                                                                        // Verifica se o status Faturamento ja foi encerrado para liberar o próximo
                                                                        (x => x.idStatusEmpenho == statusSol.idStatusEmpenhoAnterior).length > 0 &&
                                                                            listaStatusEmpenho.filter(x => x.id == statusSol.idStatusEmpenhoAnterior).length > 0 &&
                                                                            (listaStatusEmpenho.filter(x => x.id == statusSol.idStatusEmpenhoAnterior)[0].statusFaturamento
                                                                                ?
                                                                                (listaEmpenhoStatus.filter
                                                                                    (x => x.idStatusEmpenho
                                                                                        == statusSol.idStatusEmpenhoAnterior)[0].statusFaturamentoAprovado == true)
                                                                                :
                                                                                true)
                                                                            ?
                                                                            <Button
                                                                                variant="success"
                                                                                disabled={
                                                                                    contratoCompleto != null && listaContratoUsuario != undefined &&
                                                                                        (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                            && contratoUsu.idContrato === contratoCompleto.id && contratoUsu.finalizaEmpenho === true).length === 0)
                                                                                        ?
                                                                                        true
                                                                                        :
                                                                                        false
                                                                                }
                                                                                onClick={() => InserirStatusClick(statusSol.descricao)}>
                                                                                Mudar Status
                                                                            </Button>
                                                                            :
                                                                            ''
                                                                :
                                                                ''
                                                    }
                                                }
                                            }
                                        }).filter(Boolean)
                                    )
                                    :
                                    []
                            }
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                filtering: true,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'error',
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Observação: ' + rowData.observacao}</span>,
                                    hidden: rowData.observacao == '' || rowData.observacao == undefined ? true : false,
                                })
                            ]}
                            localization={{
                                header: {
                                    actions: 'Info'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: 'Pesquisar'
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                </Col>
            </div >

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Cancelar o empenho?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={observacao}
                                        onChange={e => setObservacao(e.target.value)}
                                        required
                                        maxLength="500"
                                        isInvalid={validated && observacao.trim() === "" ? true : false}
                                        isValid={validated && observacao.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Obervação é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={handleSubmit}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleClose}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatus} onHide={handleCloseStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Mudar de Status?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={InserirEmpenhoStatus}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNovoStatus} onHide={handleCloseNovoStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Ao avançar o status irá para o Inicial, deseja prosseguir?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={InserirNovoEmpenhoStatus}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseNovoStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatusAprovado} onHide={handleCloseStatusAprovado} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja aprovar os status?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={AlterarEmpenhoStatusAnaliseSubitem}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseStatusAprovado}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatusReprovado} onHide={handleCloseStatusReprovado} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja reprovar a análise de item?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={observacao}
                                        onChange={e => setObservacao(e.target.value)}
                                        required
                                        maxLength="500"
                                        isInvalid={validated && observacao.trim() === '' ? true : false}
                                        isValid={validated && observacao.trim() !== '' ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Obervação é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={AlterarEmpenhoStatusAnaliseSubitem}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseStatusAprovado}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatusFaturamentoAprovado} onHide={handleCloseStatusFaturamentoAprovado} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você tem certeza deseja finalizar o faturamento?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={MudarEmpenhoStatusFaturamento}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseStatusFaturamentoAprovado}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalVerificacaoSaldoSubitem
                show={showValidacao}
                onHide={handleCloseValidacao}
                solicitacao={false}
                medicao={false}
                empenho={true}
            />
        </>
    )
}