import { Col, Button, Card } from "react-bootstrap";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaSearchPlus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { ListarEmpenhoRequest, AlterarEmpenhoRequest, InserirEmpenhoRequest } from '../../../store/modules/empenho/actions';
import { ListarContratoRequest } from '../../../store/modules/contrato/actions';
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarStatusEmpenhoRequest } from "../../../store/modules/statusEmpenho/actions";
import { ListarFornecedorRequest } from '../../../store/modules/fornecedor/actions';
import { ListarContratoFornecedorRequest } from "../../../store/modules/contratoFornecedor/actions";
import { AtualizarToken } from "../../../store/modules/global/actions";
import { FaPlus } from "react-icons/fa";
import Select from 'react-select';
import Header from '../../../components/Header/header';
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';
import Cookies from 'universal-cookie';
import urlBase from '../../../services/rotas';

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();
    const tableRef = React.createRef();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);
    const cookies = new Cookies();

    const listaEmpenho = useSelector(state => state.empenho.listaEmpenho);
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);
    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const carregando = useSelector(state => state.empenho.carregando);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);
    const listaContratoFornecedor = useSelector(state => state.contratoFornecedor.listaContratoFornecedor);

    const [selectedRow, setSelectedRow] = useState(null);
    const [idContrato, setIdContrato] = useState('');
    const [selectFornecedor, setSelectFornecedor] = useState('');
    const [selectContrato, setSelectContrato] = useState('');
    const [id, setId] = useState(0);

    const listaRelacao = listaContratoFornecedor != undefined && idContrato != '' ?
        listaContratoFornecedor.filter(y => y.idContrato == idContrato)
        :
        [];

    const options = listaContrato != undefined ?
        listaContrato.map(contrato => ({
            value: contrato.id, label: contrato.id + " - " + contrato.descricao
        }))
        :
        [];

    const optionsFornecedor =
        listaFornecedor != undefined && listaRelacao != undefined ?
            listaRelacao.map(y => {
                return {
                    value: y.idFornecedor, label: listaFornecedor.filter(x => x.id == y.idFornecedor)[0].nomeFornecedor
                }
            })
            :
            [];

    const totalFornecedores =
        listaFornecedor != undefined ?
            listaFornecedor.map(y => {
                return {
                    value: y.id, label: y.nomeFornecedor
                }
            })
            :
            [];

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {
        if (token.role.filter(r => r == "EmpenhoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
        //Permissão inserir e editar
        if (token.role.filter(r => r == "EmpenhoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "EmpenhoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "EmpenhoExcluir").length == 1) {
            setExcluir(true);
        }
    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarStatusEmpenhoRequest());
        dispatch(ListarEmpenhoRequest());
        dispatch(ListarFornecedorRequest());
        dispatch(ListarContratoFornecedorRequest());
        dispatch(ListarContratoRequest());
        dispatch(AtualizarToken());

    }, [])

    let selectTextContrato = '';
    let selectSelecionadoContrato = [];
    let selectTextFornecedor = '';
    let selectSelecionadoFornecedor = [];

    function ValidacoesInserir(empenho) {

        if (empenho.observacao !== undefined) {
            if (empenho.observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return false;
            }
        }

        if (empenho.descricao == ' ' || empenho.descricao == ''
            || empenho.descricao == null || empenho.descricao == undefined) {
            toast.error(`Descrição Inválida`);
            return false;
        }

        if (empenho.descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return false;
        }

        if (!(empenho.numeroNotaFiscal == ' ' || empenho.numeroNotaFiscal == ''
            || empenho.numeroNotaFiscal == null || empenho.numeroNotaFiscal == undefined)) {

            if (empenho.numeroNotaFiscal.length > 50) {
                toast.error(`O número da nota fiscal não pode conter mais que 50 caracteres`);
                return false;
            }
        }

        if (document.getElementById('idSelectContrato') != null && listaContrato != undefined) {
            selectTextContrato = document.getElementById('idSelectContrato').innerText;
            selectSelecionadoContrato = listaContrato.filter(contrato => contrato.id + " - " + contrato.descricao == selectTextContrato);
        }

        let nome = '';

        if (document.getElementById('idSelectFornecedor') != null && listaContratoFornecedor != undefined && listaRelacao.length > 0) {
            selectTextFornecedor = document.getElementById('idSelectFornecedor').innerText;
            selectSelecionadoFornecedor = listaFornecedor.filter(x => x.nomeFornecedor == selectTextFornecedor)

            if (selectTextFornecedor !== undefined && selectSelecionadoFornecedor.length === 0) {
                nome = selectTextFornecedor.split('\n', selectTextFornecedor.length);
                selectSelecionadoFornecedor = listaFornecedor.filter(x => x.nomeFornecedor == nome[1])
            }
        }

        if (listaRelacao != undefined && selectSelecionadoContrato.length > 0 && selectSelecionadoFornecedor.length > 0) {
            var listaVerifica =
                listaRelacao.filter(contratoFornecedor => contratoFornecedor.idContrato == parseInt(selectSelecionadoContrato[0].id)
                    && contratoFornecedor.idFornecedor == parseInt(selectSelecionadoFornecedor[0].id));
            if (listaVerifica.length > 0) {
            } else {
                toast.error(`Fornecedor não está presente no contrato id: ${parseInt(selectSelecionadoContrato[0].id)}`);
                return false;
            }
        }

        if (selectSelecionadoContrato.length > 0 && selectSelecionadoFornecedor.length > 0) {
            return true;
        }
        else {
            toast.error(`Contrato ou fornecedor não selecionado.`);
            return false;
        }

    }

    function InserirEmpenho(empenho) {

        let data = {
            'Id': 0,
            'DataAlteracao': moment((new Date())).add('hours', 2),
            'DataInclusao': moment((new Date())).add('hours', 2),
            'IdUsuario': parseInt(usuario.id),
            'Descricao': empenho.descricao.trim(),
            'Observacao': empenho.observacao != undefined ? empenho.observacao : '',
            'NumeroNotaFiscal': empenho.numeroNotaFiscal == ' ' || empenho.numeroNotaFiscal == ''
                || empenho.numeroNotaFiscal == null || empenho.numeroNotaFiscal == undefined ? '' : empenho.numeroNotaFiscal,
            'ValorNotaFiscal': 0,
            'IdContrato': parseInt(selectSelecionadoContrato[0].id),
            'IdFornecedor': parseInt(selectSelecionadoFornecedor[0].id),
            'DataEmpenho': empenho.dataEmpenho,
            'DataEmpenhoFinal': empenho.dataEmpenhoFinal,
        }
        dispatch(InserirEmpenhoRequest(data));

    }

    function ValidacoesAlterar(empenho) {

        if (empenho.observacao !== undefined) {
            if (empenho.observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return false;
            }
        }

        if (empenho.descricao == ' ' || empenho.descricao == ''
            || empenho.descricao == null || empenho.descricao == undefined) {
            toast.error(`Descrição Inválida`);
            return false;
        }

        if (empenho.descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return false;
        }

        if (!(empenho.numeroNotaFiscal.trim() == ''
            || empenho.numeroNotaFiscal == null || empenho.numeroNotaFiscal == undefined)) {

            if (empenho.numeroNotaFiscal.length > 50) {
                toast.error(`O número da nota fiscal não pode conter mais que 50 caracteres`);
                return false;
            }
        }

        return true;
    }

    function AlterarEmpenho(empenho) {

        let data = {
            'Id': empenho.id,
            'DataAlteracao': moment((new Date())).add('hours', 2),
            'DataInclusao': new Date(empenho.dataInclusaoSemFormato),
            'IdUsuario': parseInt(empenho.idUsuario),
            'Descricao': empenho.descricao.trim(),
            'Observacao': empenho.observacao,
            'NumeroNotaFiscal': empenho.numeroNotaFiscal.trim() == ''
                || empenho.numeroNotaFiscal == null || empenho.numeroNotaFiscal == undefined ? '' : empenho.numeroNotaFiscal,
            'ValorNotaFiscal': empenho.valorNotaFiscal,
            'IdContrato': empenho.idContrato,
            'IdFornecedor': empenho.idFornecedor,
            'DataEmpenho': empenho.dataEmpenho,
            'DataEmpenhoFinal': empenho.dataEmpenhoFinal,
        }

        dispatch(AlterarEmpenhoRequest(data));
    }

    function MudarFornecedor(e, props) {
        setIdContrato(e.value);
        return props.onChange(e.value);
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <FaMoneyCheckAlt size={25} color={"#000"} /> Empenhos
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">

                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={<Link to="cadastrarGeralEmpenho"><Button variant="success" style={{ width:'100%' }}>Novo Empenho</Button></Link>}
                                    columns={[
                                        {
                                            title: 'Número do Empenho', field: 'id', cellStyle: {
                                                width: '10%',
                                            },
                                            sorting: true,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Descrição', field: 'descricao', cellStyle: {
                                                width: '11.25%',
                                            },
                                            sorting: false,
                                            validate: rowData => {
                                                if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                    return "Campo Obrigatório"
                                                } else if (rowData.descricao.trim().length > 200) {
                                                    return "A descrição não pode conter mais que 200 caracteres"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Contrato', field: 'idContrato', cellStyle: {
                                                width: '11.25%',
                                            }, editable: 'onAdd',
                                            filtering: false,
                                            sorting: false,
                                            filterComponent: (props) => {
                                                return (
                                                    <Select
                                                        placeholder="Selecione..."
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={options}
                                                        onChange={(e) => {
                                                            props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.label : false)
                                                        }}
                                                    />
                                                )
                                            },
                                            lookup:
                                                listaContrato != undefined ?
                                                    listaContrato.map(contrato => {
                                                        return {
                                                            id: contrato.id, descricao: contrato.id + " - " + contrato.descricao
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectContrato"
                                                    options={options}
                                                    placeholder="Selecione..."
                                                    defaultValue={{
                                                        value: props.value, label: listaContrato != undefined && listaContrato.filter(emp => emp.id == props.value).length > 0
                                                            ? listaContrato.filter(emp => emp.id == props.value)[0].id + " - " + listaContrato.filter(emp => emp.id == props.value)[0].descricao
                                                            : 'Selecione...'
                                                    }}
                                                    onChange={e => MudarFornecedor(e, props)}
                                                />
                                            )
                                        },
                                        {
                                            title: 'Fornecedor', field: 'idFornecedor', cellStyle: {
                                                width: '11.25%',
                                            }, editable: 'onAdd',
                                            filtering: false,
                                            sorting: false,
                                            lookup:
                                                listaFornecedor != undefined && listaRelacao != undefined ?
                                                    listaRelacao.map(y => {
                                                        return {
                                                            id: y.idFornecedor,
                                                            descricao: listaFornecedor.filter(x => x.id == y.idFornecedor).nomeFornecedor
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectFornecedor"
                                                    options={optionsFornecedor}
                                                    placeholder="Selecione..."
                                                    defaultValue={
                                                        listaFornecedor != undefined && listaRelacao != undefined ?
                                                            listaRelacao.map(y => {
                                                                return {
                                                                    value: props.value, label: listaFornecedor.filter(x => x.id == props.value).nomeFornecedor
                                                                }
                                                            })
                                                            : 'Selecione...'
                                                    }
                                                />
                                            ),
                                            filterComponent: (props) => {
                                                return (
                                                    <Select
                                                        placeholder="Selecione..."
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={totalFornecedores}
                                                        onChange={(e) => {
                                                            props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                        }}
                                                    />
                                                )
                                            },
                                            render: rowData => listaFornecedor != undefined ?
                                                listaFornecedor.filter(k => k.id == rowData.idFornecedor)[0].nomeFornecedor : rowData.idFornecedor
                                        },
                                        {
                                            title: 'Número Nota Fiscal', field: 'numeroNotaFiscal',
                                            sorting: false,
                                            cellStyle: {
                                                width: '11.25%',
                                            }
                                        },/*
                                        {
                                            title: 'Valor Nota Fiscal', field: 'valorNotaFiscal', cellStyle: {
                                                width: '10%',
                                            }, editable: 'never',
                                            grouping: false,
                                            render: rowData =>
                                                rowData.valorNotaFiscal === null ?
                                                    null
                                                    :
                                                    rowData.valorNotaFiscal.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        },
                                        /*{
                                            title: 'Soma das Faturas', field: 'somaDasFaturas', cellStyle: {
                                                width: '8%',
                                            }, editable: 'never',
                                            render: rowData =>
                                                rowData.somaDasFaturas === null || rowData.somaDasFaturas === undefined ?
                                                    null
                                                    :
                                                    rowData.somaDasFaturas.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        },
                                        {
                                            title: 'Valor Restante', field: 'valorRestante', cellStyle: {
                                                width: '8%',
                                            }, editable: 'never',
                                            render: rowData =>
                                                rowData.valorRestante === null || isNaN(rowData.valorRestante) ?
                                                    null
                                                    :
                                                    rowData.valorRestante.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 4,
                                                        maximumFractionDigits: 4
                                                    })
                                        },*/
                                        {
                                            title: 'Usuario Criador', field: 'usuario', cellStyle: {
                                                width: '11.25%',
                                            },
                                            sorting: false,
                                            editable: 'never'
                                        },
                                        {
                                            title: 'Data Empenho', field: 'dataEmpenho', cellStyle: {
                                                width: '5.25%',
                                            },
                                            sorting: false,
                                            filtering: false,
                                            type: 'datetime'
                                        },
                                        {
                                            title: 'Data Empenho Final', field: 'dataEmpenhoFinal', cellStyle: {
                                                width: '5.25%',
                                            },
                                            sorting: false,
                                            filtering: false,
                                            type: 'datetime'
                                        },
                                        // {
                                        //     title: 'Data Criação', field: 'dataInclusao', cellStyle: {
                                        //         width: '5.25%',
                                        //     },
                                        //     sorting: false,
                                        //     editable: 'never',
                                        //     filtering: false,
                                        // },
                                        {
                                            title: 'Observação', field: 'observacao', cellStyle: {
                                                width: '11.25%',
                                            },
                                            sorting: false,
                                            validate: rowData => {
                                                if (rowData.observacao !== undefined) {
                                                    if (rowData.observacao.trim().length > 500) {
                                                        return "A observação não pode conter mais que 500 caracteres"
                                                    }
                                                    else if (rowData.observacao == ' ') {
                                                        return "Observação inválida"
                                                    }
                                                }
                                                return true;
                                            }
                                        },
                                        {
                                            title: 'Status', field: 'status', cellStyle: {
                                                width: '11.25%',
                                            },
                                            editable: 'never',
                                            sorting: false,
                                            render: rowData =>
                                                listaStatusEmpenho != undefined
                                                    ?
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].
                                                            descricao === rowData.status ?
                                                        <div className="divStatusGreenListar">{rowData.status}</div>
                                                        :
                                                        listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                            listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].
                                                                descricao === rowData.status ?
                                                            <div className="divStatusDangerListar">{rowData.status}</div>
                                                            :
                                                            listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true).length > 0 &&
                                                                listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true)[0].
                                                                    descricao + ' (Aprovada)' === rowData.status ?
                                                                <div className="divStatusGreenListar">{rowData.status}</div>
                                                                :
                                                                listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true).length > 0 &&
                                                                    listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true)[0].
                                                                        descricao + ' (Reprovada)' === rowData.status ?
                                                                    <div className="divStatusDangerListar">{rowData.status}</div>
                                                                    :
                                                                    listaStatusEmpenho.filter(x => x.statusFaturamento == true).length > 0 &&
                                                                        listaStatusEmpenho.filter(x => x.statusFaturamento == true)[0].
                                                                            descricao + ' Finalizado' === rowData.status ?
                                                                        <div className="divStatusGreenListar">{rowData.status}</div>
                                                                        :
                                                                        <div className="divStatusNormalListar">{rowData.status}</div>
                                                    :
                                                    ''
                                        }
                                    ]}
                                    data={
                                        query =>
                                            new Promise((resolve, reject) => {
                                                fetch(`${urlBase}/api/Empenho/listarCompletoPaginacao`, {
                                                    method: "POST",
                                                    headers: new Headers({
                                                        'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
                                                            `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
                                                            : '',
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json'
                                                    }),
                                                    body: JSON.stringify({
                                                        Pagina: query.page + 1,
                                                        NumeroPorPagina: query.pageSize,
                                                        NumeroEmpenho: query.filters.filter(c => c.column.field == "id").length > 0 ? query.filters.filter(c => c.column.field == "id")[0].value : null,
                                                        Descricao: query.filters.filter(c => c.column.field == "descricao").length > 0 ? query.filters.filter(c => c.column.field == "descricao")[0].value : null,
                                                        NumeroNotaFiscal: query.filters.filter(c => c.column.field == "numeroNotaFiscal").length > 0 ? query.filters.filter(c => c.column.field == "numeroNotaFiscal")[0].value : null,
                                                        ValorNotaFiscal: query.filters.filter(c => c.column.field == "valorNotaFiscal").length > 0 ? query.filters.filter(c => c.column.field == "valorNotaFiscal")[0].value : null,
                                                        Usuario: query.filters.filter(c => c.column.field == "usuario").length > 0 ? query.filters.filter(c => c.column.field == "usuario")[0].value : null,
                                                        Observacao: query.filters.filter(c => c.column.field == "observacao").length > 0 ? query.filters.filter(c => c.column.field == "observacao")[0].value : null,
                                                        Status: query.filters.filter(c => c.column.field == "status").length > 0 ? query.filters.filter(c => c.column.field == "status")[0].value : null,
                                                        OrdenacaoIdEmpenho:
                                                            query.orderBy != undefined && query.orderBy.field == "id"
                                                                ?
                                                                query.orderBy.tableData.groupSort == "asc"
                                                                    ?
                                                                    true
                                                                    :
                                                                    false
                                                                :
                                                                false,
                                                        GeralFiltro: query.search,
                                                    }),

                                                }).then(response => response.json())
                                                    .then(result => {
                                                        resolve({
                                                            data:
                                                                result.result.data != undefined ?
                                                                    result.result.data.map(empenho => {
                                                                        return {
                                                                            id: empenho.id,
                                                                            //valorRestante: isNaN(empenho.valorNotaFiscal - empenho.valorFaturamentoTotal) ? null : empenho.valorNotaFiscal - empenho.valorFaturamentoTotal,
                                                                            //somaDasFaturas: empenho.valorFaturamentoTotal,
                                                                            idFornecedor: empenho.idFornecedor,
                                                                            descricao: empenho.descricao,
                                                                            observacao: empenho.observacao,
                                                                            usuario: empenho.usuario.nome,
                                                                            idUsuario: empenho.idUsuario,
                                                                            // dataInclusao: moment(empenho.dataInclusao).format('L LT'),
                                                                            dataEmpenho: empenho.dataEmpenho,
                                                                            dataEmpenhoFinal: empenho.dataEmpenhoFinal,
                                                                            numeroNotaFiscal: empenho.numeroNotaFiscal,
                                                                            valorNotaFiscal: empenho.valorNotaFiscal,
                                                                            status: empenho.statusAtual,
                                                                            dataInclusaoSemFormato: empenho.dataInclusao,
                                                                            idContrato: empenho.idContrato
                                                                        }
                                                                    })
                                                                    :
                                                                    []

                                                            ,
                                                            page: result.result.pagina - 1,
                                                            totalCount: result.result.total,
                                                        })
                                                    })
                                            })
                                    }
                                
                                    actions={[
                                        rowData => ({
                                            icon: () => <FaSearchPlus />,
                                            onClick: (event, rowData) => { history.push(`/alterarGeralEmpenho/${rowData.id}`); }
                                        })
                                    ]}
                                    options={{
                                        minBodyHeight: 500,
                                        sorting: true,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        addRowPosition: "first",
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    localization={{
                                        header: {
                                            actions: <span className="classHeaderTabela">Ações</span>
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                saveTooltip: '',
                                                cancelTooltip: '',
                                            },
                                            deleteTooltip: '',
                                            editTooltip: '',
                                            addTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}