export function ListarUnidadeMedidaRequest() {
  return {
    type: 'LISTAR_UNIDADE_MEDIDA_REQUEST'
  }
}

export function ListarUnidadeMedidaResponse(listaUnidadeMedida) {
  return {
    type: 'LISTAR_UNIDADE_MEDIDA_RESPONSE',
    listaUnidadeMedida
  }
}

export function InserirUnidadeMedidaRequest(data) {
  return {
    type: 'INSERIR_UNIDADE_MEDIDA_REQUEST',
    data
  }
}

export function AlterarUnidadeMedidaRequest(data) {
  return {
    type: 'ALTERAR_UNIDADE_MEDIDA_REQUEST',
    data
  }
}

export function ExcluirUnidadeMedidaRequest(id) {
  return {
    type: 'EXCLUIR_UNIDADE_MEDIDA_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}