import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InserirSubitemCustoRequest } from "../../../store/modules/subitemCusto/actions";
import { ListarItemCustoCompletoRequest } from "../../../store/modules/itemCusto/actions";
import { ListarUnidadeMedidaRequest } from "../../../store/modules/unidadeMedida/actions";
import { ListarComposicaoCustoRequest } from "../../../store/modules/composicaoCusto/actions";
import { ListarFonteReceitaRequest } from "../../../store/modules/fonteReceita/actions";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import IntlCurrencyInput from "react-intl-currency-input";

export default function SubitemCusto() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const listaUnidadeMedida = useSelector(state => state.unidadeMedida.listaUnidadeMedida);
    const listaComposicaoCusto = useSelector(state => state.composicaoCusto.listaComposicaoCusto);
    const listaFonteReceita = useSelector(state => state.fonteReceita.listaFonteReceita);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    let listaItem = useSelector(state => state.itemCusto.listaCompletaItemCusto);

    const [descricaoCodigoInterno, setDescricaoCodigoInterno] = useState('');
    const [descricao, setDescricao] = useState('');
    const [unidadeMedida, setUnidadeMedida] = useState('');
    const [composicaoCusto, setComposicaoCusto] = useState('');
    const [fonteReceita, setFonteReceita] = useState('');
    const [itemCusto, setItemCusto] = useState('');
    const [valorUnitario, setValorUnitario] = useState(0);
    const [valorPrevisaoMensal, setValorPrevisaoMensal] = useState(0);
    const [quantidade, setQuantidade] = useState('');
    const [validated, setValidated] = useState(false);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                },
            },
        },
    };

    const handleChangeValorUnitario = (event, value, maskedValue) => {

        event.preventDefault();
        setValorUnitario(value);
    };

    const handleChangeValorPrevisaoMensal = (event, value, maskedValue) => {

        event.preventDefault();
        setValorPrevisaoMensal(value);
    };

    useEffect(() => {

        dispatch(ListarUnidadeMedidaRequest());
        dispatch(ListarComposicaoCustoRequest());
        dispatch(ListarFonteReceitaRequest());

        if (contratoCompleto != null) {
            var model = {
                IdContratoVersao: contratoCompleto.contratoVersaoApiModel.id
            }
            dispatch(ListarItemCustoCompletoRequest(model));
        }

    }, [contratoCompleto])

    function CadastrarSubitem(e) {

        e.preventDefault();

        //validação do campo descricaoCodigoInterno
        if (descricaoCodigoInterno == null || descricaoCodigoInterno == undefined
            || descricaoCodigoInterno.trim() == '') {
            toast.error(`Descrição Número Interno inválido`);
            return;
        }

        //validação do campo descricao
        if (descricao == null || descricao == undefined
            || descricao.trim() == '') {
            toast.error(`Descrição inválida`);
            return;
        }

        var data = {
            'Id': 0,
            'IdItemCusto': itemCusto,
            'Descricao': descricao.trim(),
            'DescricaoCodigoInterno': descricaoCodigoInterno.trim(),
            'Ativo': true,
            'IdUnidadeMedida': parseInt(unidadeMedida),
            'ValorUnitario': valorUnitario,
            'ValorPrevisaoMensal': valorPrevisaoMensal,
            'Quantidade': quantidade,
            'IdFonteReceita': fonteReceita,
            'IdComposicaoCusto': composicaoCusto,
            'IdContrato': contratoCompleto.id,
            'IdContratoVersao': contratoCompleto.contratoVersaoApiModel.id
        }

        dispatch(InserirSubitemCustoRequest(data, contratoCompleto.id, contratoCompleto.contratoVersaoApiModel.id));
    }

    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false ||
            (valorPrevisaoMensal === 0 || valorPrevisaoMensal === "") === true ||
            (valorUnitario === 0 || valorUnitario === "") === true) {

            event.preventDefault();
            setValidated(true);
        } else {

            CadastrarSubitem(event);
            setDescricao("");
            setDescricaoCodigoInterno("");
            setUnidadeMedida("");
            setComposicaoCusto("");
            setItemCusto("");
            setValorUnitario(0);
            setValorPrevisaoMensal(0);
            setQuantidade("");
            setFonteReceita("");
            setValidated(false);
        }
    };

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="tamanhoTela">
                    <Col md={12}>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formCusto">
                                <Form.Label>Item Custo</Form.Label>
                                <Form.Select onChange={e => setItemCusto(e.target.value)} id="selectGrupoCusto"
                                    value={itemCusto}
                                    required
                                    isInvalid={validated && itemCusto === "" ? true : false}
                                    isValid={validated && itemCusto !== "" ? true : false}>
                                    <option value="">Selecione...</option>
                                    {
                                        listaItem != undefined ?
                                            listaItem.map(m => (
                                                <option value={m.id} key={m.id}>{m.descricaoCodigoInterno} - {m.descricao}</option>
                                            ))
                                            :
                                            null
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">O campo item custo é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formDescricaoCodigoInterno">
                                <Form.Label>Número Interno</Form.Label>
                                <Form.Control type="text" value={descricaoCodigoInterno} onChange={e => setDescricaoCodigoInterno(e.target.value)}
                                    maxLength="70"
                                    isInvalid={validated && descricaoCodigoInterno.trim() === "" ? true : false}
                                    isValid={validated && descricaoCodigoInterno.trim() !== "" ? true : false}
                                    required />
                                <Form.Control.Feedback type="invalid">O campo número interno é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formDescricao">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control type="text" value={descricao} onChange={e => setDescricao(e.target.value)}
                                    maxLength="20"
                                    isInvalid={validated && descricao.trim() === "" ? true : false}
                                    isValid={validated && descricao.trim() !== "" ? true : false}
                                    required />
                                <Form.Control.Feedback type="invalid">O campo descrição é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formUnidadeMedida">
                                <Form.Label>Unidade de Medida</Form.Label>
                                <Form.Select onChange={e => setUnidadeMedida(e.target.value)}
                                    required
                                    isInvalid={validated && unidadeMedida === "" ? true : false}
                                    isValid={validated && unidadeMedida !== "" ? true : false}
                                    value={unidadeMedida}>
                                    <option value="">Selecione...</option>
                                    {
                                        listaUnidadeMedida != undefined ?
                                            listaUnidadeMedida.map(m => (
                                                <option value={m.id} key={m.id}>{m.descricao}</option>
                                            ))
                                            :
                                            null
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">O campo unidade de medida é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formValorUnitario">
                                <Form.Label>Valor Unitário</Form.Label>
                                <Form.Control
                                    as={IntlCurrencyInput}
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={valorUnitario}
                                    onChange={handleChangeValorUnitario}
                                    isInvalid={validated && valorUnitario === 0
                                        || validated && valorUnitario === "" ? true : false}
                                    isValid={validated && valorUnitario !== 0
                                        || validated && valorUnitario !== "" ? true : false}
                                    required />
                                <Form.Control.Feedback type="invalid">
                                    {
                                        valorUnitario === "" ? "O campo Valor Unitário é obrigatório." :
                                            "O valor precisa ser válido."
                                    }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formPrevisaoMensal">
                                <Form.Label>Valor Previsão Mensal</Form.Label>
                                <Form.Control
                                    as={IntlCurrencyInput}
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={valorPrevisaoMensal}
                                    onChange={handleChangeValorPrevisaoMensal}
                                    isInvalid={validated && valorPrevisaoMensal === 0
                                        || validated && valorPrevisaoMensal === "" ? true : false}
                                    isValid={validated && valorPrevisaoMensal !== 0
                                        || validated && valorPrevisaoMensal !== "" ? true : false}
                                    required />
                                <Form.Control.Feedback type="invalid">
                                    {
                                        valorPrevisaoMensal === "" ? "O campo valor previsão mensal é obrigatório." :
                                            "O valor precisa ser válido."
                                    }
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formQuantidade">
                                <Form.Label>Quantidade</Form.Label>
                                <Form.Control
                                    value={quantidade} onChange={e => setQuantidade(e.target.value)}
                                    type="number"
                                    maxLength="10"
                                    isInvalid={(validated && quantidade) === ""
                                        || (validated && quantidade === '0')
                                        || (validated && quantidade.includes('-'))
                                        || (validated && !(quantidade.replaceAll(",", ".") > 0)) ? true : false}
                                    isValid={validated && quantidade !== "" ? true : false}
                                    required
                                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {
                                        quantidade === "" ? "O campo quantidade é obrigatório." :
                                            "O valor precisa ser válido."
                                    }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formUnidadeMedida">
                                <Form.Label>Fonte Receita</Form.Label>
                                <Form.Select onChange={e => setFonteReceita(e.target.value)}
                                    required
                                    isInvalid={validated && fonteReceita === "" ? true : false}
                                    isValid={validated && fonteReceita !== "" ? true : false}
                                    value={fonteReceita}>
                                    <option value="">Selecione...</option>
                                    {
                                        listaFonteReceita != undefined ?
                                            listaFonteReceita.map(fonte => (
                                                <option value={fonte.id} key={fonte.id}>{fonte.nomeFonteReceita}</option>
                                            ))
                                            :
                                            null
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">O campo fonte receita é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formComposicaoCusto">
                                <Form.Label>Composicão Custo</Form.Label>
                                <Form.Select onChange={e => setComposicaoCusto(e.target.value)}
                                    required
                                    isInvalid={validated && composicaoCusto === "" ? true : false}
                                    isValid={validated && composicaoCusto !== "" ? true : false}
                                    value={composicaoCusto}>
                                    <option value="">Selecione...</option>
                                    {
                                        listaComposicaoCusto != undefined ?
                                            listaComposicaoCusto.map(m => (
                                                <option value={m.id} key={m.id}>{m.descricao}</option>
                                            ))
                                            :
                                            null
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">O campo composicao custo é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        <Row>
                            <Col md={2}>
                                {
                                    inserir == true
                                        ?
                                        <Button variant="success" type="submit">Cadastrar Subitem Custo</Button>
                                        :
                                        ''
                                }

                            </Col>
                        </Row>
                    </Col>
                </div>
            </Form>
        </>
    );
}