import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { BuscarSolicitacaoFornecimentoPorIdResponse } from '../solicitacaoFornecimento/actions';
import { ListarSolicitacaoFornecimentoVersaoStatusResponse } from '../solicitacaoFornecimentoVersaoStatus/actions';
import {
  ListarSolicitacaoFornecimentoVersaoResponse,
  ListarCompletoSolicitacaoFornecimentoVersaoResponse
} from '../solicitacaoFornecimentoVersao/actions';
import api from '../../../services/api';
import { Carregando } from './actions';
import history from '../../../services/history';

function* ListarSolicitacaoFornecimentoVersaoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/SolicitacaoFornecimentoVersao`, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarSolicitacaoFornecimentoVersaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarCompletoSolicitacaoFornecimentoVersaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersao/listarCompleto`,
      { IdSolicitacaoFornecimento: parseInt(model.idSolicitacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarCompletoSolicitacaoFornecimentoVersaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarCompletoSolicitacaoFornecimentoVersaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  

  }
}

function* InserirSolicitacaoFornecimentoVersaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/SolicitacaoFornecimentoVersao`, model.data, { headers: headerParams.token });

    let result = yield call(api.get, `/api/SolicitacaoFornecimento/${model.data.IdSolicitacaoFornecimento}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(result.data.result));

    // Listar Status
    result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`,
      { IdSolicitacaoFornecimento: model.data.IdSolicitacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(result.data.result));

    //Listar histórico versões
    const resultVersao = yield call(api.post, `/api/SolicitacaoFornecimentoVersao/listarCompleto`,
      { IdSolicitacaoFornecimento: parseInt(model.data.IdSolicitacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarCompletoSolicitacaoFornecimentoVersaoResponse(resultVersao.data.result));
    

    yield put(Carregando(true));

    toast.success(`A nova Versão da Solicitacão foi cadastrada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ListarCompletoSolicitacaoFornecimentoVersaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
 
  }
}

export default all([
  takeLatest('INSERIR_SOLICITACAO_FORNECIMENTO_VERSAO_REQUEST', InserirSolicitacaoFornecimentoVersaoRequest),
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_REQUEST', ListarSolicitacaoFornecimentoVersaoRequest),
  takeLatest('LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_VERSAO_REQUEST', ListarCompletoSolicitacaoFornecimentoVersaoRequest)
])