import produce from 'immer';

const initialState = {
  listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed: undefined,
  listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed: undefined,
  carregando: true
};

export default function empenhoAutorizacaoFornecimentoSubitemCustoMed(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_RESPONSE':
      return produce(state, draft => {
        draft.listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed = action.listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed;
      });

      case 'LISTAR_HIERARQUIA_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_RESPONSE':
      return produce(state, draft => {
        draft.listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed = action.listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed
      });

      case 'LIMPAR_ARVORE_SUBITEM':
      return produce(state, draft => {
        draft.listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed = undefined
      }); 

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}