import { Row, Col, Card, Form, Dropdown, DropdownButton } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearchPlus } from "react-icons/fa";
import { RiFilter3Line } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import { MdAssignment } from "react-icons/md";
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { AlterarAutorizacaoFornecimentoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import { ListarStatusAutorizacaoFornecimentoRequest } from "../../../store/modules/statusAutorizacaoFornecimento/actions";
import { AtualizarToken } from "../../../store/modules/global/actions";
import Header from '../../../components/Header/header';
import MaterialTable from 'material-table';
import moment from 'moment';
import Cookies from 'universal-cookie';
import urlBase from '../../../services/rotas';
import Select from 'react-select';
import 'moment/locale/pt-br';

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const tableRef = React.createRef();

    const listaAutorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.listaAutorizacaoFornecimento);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const [selectedRow, setSelectedRow] = useState(null);

    const [numeroAfCheck, setNumeroAfCheck] = useState(true);
    const [descricaoCheck, setDescricaoCheck] = useState(true);
    const [numeroOrcamentoCheck, setNumeroOrcamentoCheck] = useState(true);
    const [numeroOficioCheck, setNumeroOficioCheck] = useState(true);
    const [numeroProcessoCheck, setNumeroProcessoCheck] = useState(true);
    const [dataInclusaoCheck, setDataInclusaoCheck] = useState(true);
    const [statusCheck, setStatusCheck] = useState(true);
    const [medicaoPendenteFraseCheck, setMedicaoPendenteFraseCheck] = useState(true);
    const [assuntoCheck, setAssuntoCheck] = useState(false);
    const [valorObraCheck, setValorObraCheck] = useState(false);

    const [listaMedicaoPendente, setListaMedicaoPendente] = useState([
        { value: '1', label: "Pendente de Quantidade (Cliente)" },
        { value: '2', label: "Pendente de Quantidade (Fornecedor)" },
        { value: '3', label: "Pendente de Quantidade (Ambos)" },
    ]);

    const [listaStatus, setListaStatus] = useState([
        { value: '4', label: "Inicial" },
        { value: '6', label: "Autorização Finalizada" },
        { value: '8', label: "Autorização Cancelada" },
        { value: '9', label: "Autorização em Andamento" },
        { value: '10', label: "Autorização Recebida" },
        { value: '11', label: "Faturamento Parcial" },
        { value: '12', label: "Autorização Concluída" },
    ]);

    // Filtros
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);
    let [filtros] = useState(JSON.parse(localStorage.getItem('filtro-autorizacao-fornecimento')));

    const [numeroAfFiltro, setNumeroAfFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroAfFiltro : 0);
    const [numeroSolicitacaoFiltro, setNumeroSolicitacaoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroSolicitacaoFiltro : 0);
    const [numeroOrcamentoFiltro, setNumeroOrcamentoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroOrcamentoFiltro : 0);
    const [numeroOficioFiltro, setNumeroOficioFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroOficioFiltro : 0);
    const [numeroProcessoFiltro, setNumeroProcessoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroProcessoFiltro : 0);
    const [statusFiltro, setStatusFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.statusFiltro : 0);
    const [medicaoPendenteFiltro, setMedicaoPendenteFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.medicaoPendenteFiltro : 0);

    const [dataInicio, setDataInicio] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {
        if (token.role.filter(r => r == "AutorizacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {

        moment.locale('pt-br');
        dispatch(ListarStatusAutorizacaoFornecimentoRequest());
        dispatch(AtualizarToken());
        TrocarData(filtros);
        
    }, [])

    function TrocarData(filtros){

        if(filtros.numeroAfFiltro === '' && filtros.numeroSolicitacaoFiltro === '' && filtros.numeroOrcamentoFiltro === '' &&
           filtros.numeroOficioFiltro === '' && filtros.numeroProcessoFiltro === '' && filtros.statusFiltro === '' &&
           filtros.medicaoPendenteFiltro === '' && !dataInicio && !dataFinal){
            setDataInicio(moment().startOf('month'))
            setDataFinal(moment().endOf('month'))
        }

    }
    
    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdAssignment size={25} color={"#000"} /> Autorização Fornecimento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col>
                                        <DropdownButton variant="success" title="Colunas">
                                            <Form>
                                                <div className="tamanhoTela">
                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroAfCheck} onChange={() => setNumeroAfCheck(!numeroAfCheck)} />
                                                                <Form.Check.Label> Número da Autorização</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={descricaoCheck} onChange={() => setDescricaoCheck(!descricaoCheck)} />
                                                                <Form.Check.Label> Número Solicitação</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroOrcamentoCheck} onChange={() => setNumeroOrcamentoCheck(!numeroOrcamentoCheck)} />
                                                                <Form.Check.Label> Número Orçamento</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroOficioCheck} onChange={() => setNumeroOficioCheck(!numeroOficioCheck)} />
                                                                <Form.Check.Label> Número Oficio</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={numeroProcessoCheck} onChange={() => setNumeroProcessoCheck(!numeroProcessoCheck)} />
                                                                <Form.Check.Label> Número Processo</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={dataInclusaoCheck} onChange={() => setDataInclusaoCheck(!dataInclusaoCheck)} />
                                                                <Form.Check.Label> Data Criação</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={statusCheck} onChange={() => setStatusCheck(!statusCheck)} />
                                                                <Form.Check.Label> Status</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={medicaoPendenteFraseCheck} onChange={() => setMedicaoPendenteFraseCheck(!medicaoPendenteFraseCheck)} />
                                                                <Form.Check.Label> Medição Pendente</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={assuntoCheck} onChange={() => setAssuntoCheck(!assuntoCheck)} />
                                                                <Form.Check.Label> Assunto</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={3}>
                                                            <Form.Check>
                                                                <Form.Check.Input aria-label="option 1" checked={valorObraCheck} onChange={() => setValorObraCheck(!valorObraCheck)} />
                                                                <Form.Check.Label> Valor da Obra</Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </DropdownButton>
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col md={12}>
                                        <MaterialTable
                                            components={{
                                                Pagination: PatchedPagination,
                                            }}
                                            title=''
                                            columns={[
                                                {
                                                    title: "Número da Autorização", field: 'numeroAf',
                                                    sorting: true,
                                                    defaultFilter: numeroAfFiltro,
                                                    hidden: numeroAfCheck ? false : true,
                                                },
                                                {
                                                    title: "Número Solicitação", field: 'descricao', 
                                                    sorting: false,
                                                    hidden: descricaoCheck ? false : true,
                                                    defaultFilter: numeroSolicitacaoFiltro,
                                                    validate: rowData => {
                                                        if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                            return "Campo obrigatório"
                                                        } else if (rowData.descricao.trim().length > 200) {
                                                            return "A descrição não pode conter mais que 200 caracteres"
                                                        }
                                                        return true
                                                    }
                                                },
                                                {
                                                    title: "Número Orçamento", field: 'numeroOrcamento', 
                                                    sorting: false,
                                                    defaultFilter: numeroOrcamentoFiltro,
                                                    hidden: numeroOrcamentoCheck ? false : true,
                                                },
                                                {
                                                    title: "Número Ofício", field: 'numeroOficio', 
                                                    sorting: false,
                                                    defaultFilter: numeroOficioFiltro,
                                                    hidden: numeroOficioCheck ? false : true,
                                                },
                                                {
                                                    title: "Número Processo", field: 'numeroProcesso', 
                                                    sorting: false,
                                                    defaultFilter: numeroProcessoFiltro,
                                                    hidden: numeroProcessoCheck ? false : true,
                                                },
                                                {
                                                    title: "Data Criação", field: 'dataInclusao', 
                                                    sorting: false,
                                                    hidden: dataInclusaoCheck ? false : true,
                                                    filterComponent: (props) => {
                                                        return (
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                    <RiFilter3Line />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu
                                                                    style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                    <div>
                                                                        <Form as={Row} md={12}>
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Form.Label>Data Inicial de Criação</Form.Label>
                                                                                <Form.Control
                                                                                    style={{ minHeight: '36px' }}
                                                                                    type="date"
                                                                                    value={dataInicio != null ? moment(dataInicio).format("YYYY-MM-DD") : ''}
                                                                                    onChange={(e) => {
                                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.target.value : null);
                                                                                        setDataInicio(e != null ? e.target.value : null)
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Form.Label>Data Final de Criação</Form.Label>
                                                                                <Form.Control
                                                                                    style={{ minHeight: '36px' }}
                                                                                    type="date"
                                                                                    value={dataFinal != null ? moment(dataFinal).format("YYYY-MM-DD") : ''}
                                                                                    onChange={(e) => {
                                                                                        props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.target.value : null);
                                                                                        setDataFinal(e != null ? e.target.value : null)
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        )
                                                    },
                                                    defaultFilter: dataInicio != null ? dataInicio : dataFinal != null ? dataFinal : 0,
                                                    id: dataFinal != null ? 2 : dataInicio != null ? 1 : 0,
                                                },
                                                {
                                                    title: "Assunto", field: 'assunto',
                                                    sorting: false,
                                                    hidden: assuntoCheck ? false : true,
                                                },
                                                {
                                                    title: "Valor da Obra", field: 'valorObra', 
                                                    sorting: false,
                                                    hidden: valorObraCheck ? false : true,
                                                },
                                                {
                                                    title: 'Status', field: 'status', 
                                                    hidden: statusCheck ? false : true,
                                                    defaultFilter: statusFiltro,
                                                    filterComponent: (props) => {
                                                        return (
                                                            <Select
                                                                placeholder="Selecione..."
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={listaStatus}
                                                                onChange={(e) => {
                                                                    props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.value : null)
                                                                }}

                                                                defaultValue={
                                                                    {
                                                                        label: listaStatus.filter(v => v.value === statusFiltro).length > 0 ? listaStatus.filter(v => v.value === statusFiltro)[0].label : "",
                                                                        value: listaStatus.filter(v => v.value === statusFiltro).length > 0 ? listaStatus.filter(v => v.value === statusFiltro)[0].value : "",
                                                                    }
                                                                }
                                                            />
                                                        )
                                                    },
                                                    sorting: false,
                                                    render: rowData =>
                                                        // Verificar qual é o status de cada autorização para colorir na lista
                                                        listaStatusAutorizacaoFornecimento != undefined
                                                            && listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0
                                                            && listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0 ?
                                                            listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].
                                                                descricao === rowData.status ?
                                                                <div className="divStatusGreenListar">{rowData.status}</div>
                                                                :
                                                                listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].
                                                                    descricao === rowData.status ?
                                                                    <div className="divStatusDangerListar">{rowData.status}</div>
                                                                    :
                                                                    <div className="divStatusNormalListar">{rowData.status}</div>
                                                            :
                                                            ''
                                                },
                                                {
                                                    title: "Medição Pendente", field: 'medicaoPendenteFrase', 
                                                    hidden: medicaoPendenteFraseCheck ? false : true,
                                                    filtering: true,
                                                    sorting: false,
                                                    defaultFilter: medicaoPendenteFiltro,
                                                    filterComponent: (props) => {
                                                        return (
                                                            <Select
                                                                placeholder="Selecione..."
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={listaMedicaoPendente}
                                                                onChange={(e) => {
                                                                    props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.value : null)
                                                                }}
                                                                defaultValue={
                                                                    {
                                                                        label: listaMedicaoPendente.filter(v => v.value === medicaoPendenteFiltro).length > 0 ? listaMedicaoPendente.filter(v => v.value === medicaoPendenteFiltro)[0].label : "",
                                                                        value: listaMedicaoPendente.filter(v => v.value === medicaoPendenteFiltro).length > 0 ? listaMedicaoPendente.filter(v => v.value === medicaoPendenteFiltro)[0].value : "",
                                                                    }
                                                                }
                                                            />
                                                        )
                                                    },
                                                }
                                            ]}
                                            data={
                                                query =>
                                                    new Promise((resolve, reject) => {

                                                        filtros = {
                                                            "numeroAfFiltro": "",
                                                            "numeroSolicitacaoFiltro": "",
                                                            "numeroOrcamentoFiltro": "",
                                                            "numeroOficioFiltro": "",
                                                            "numeroProcessoFiltro": "",
                                                            "statusFiltro": "",
                                                            "medicaoPendenteFiltro": "",
                                                            "dataInicioFiltro": "",
                                                            "dataFinalFiltro": ""
                                                        };

                                                        //filtros
                                                        if (query.filters.filter(c => c.column.field == "numeroAf").length > 0) {
                                                            filtros.numeroAfFiltro = query.filters.filter(c => c.column.field == "numeroAf")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "descricao").length > 0 > 0) {
                                                            filtros.numeroSolicitacaoFiltro = query.filters.filter(c => c.column.field == "descricao")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "numeroOrcamento").length > 0) {
                                                            filtros.numeroOrcamentoFiltro = query.filters.filter(c => c.column.field == "numeroOrcamento")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "numeroOficio").length > 0) {
                                                            filtros.numeroOficioFiltro = query.filters.filter(c => c.column.field == "numeroOficio")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "numeroProcesso").length > 0) {
                                                            filtros.numeroProcessoFiltro = query.filters.filter(c => c.column.field == "numeroProcesso")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "status").length > 0) {
                                                            filtros.statusFiltro = query.filters.filter(c => c.column.field == "status")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "medicaoPendenteFrase").length > 0) {
                                                            filtros.medicaoPendenteFiltro = query.filters.filter(c => c.column.field == "medicaoPendenteFrase")[0].value;
                                                        }
                                                        if (query.filters.filter(c => c.column.field == "dataInclusao").length > 0) {
                                                            var column = query.filters.filter(c => c.column.field == "dataInclusao")[0].column;
                                                            if (column.id == 1) {
                                                                filtros.dataInicioFiltro = query.filters.filter(c => c.column.field == "dataInclusao")[0].value;
                                                            }
                                                            if (column.id == 2) {
                                                                filtros.dataFinalFiltro = query.filters.filter(c => c.column.field == "dataInclusao")[0].value;
                                                            }
                                                        }

                                                        setNumeroAfFiltro(filtros.numeroAfFiltro);
                                                        setNumeroSolicitacaoFiltro(filtros.numeroSolicitacaoFiltro);
                                                        setNumeroOrcamentoFiltro(filtros.numeroOrcamentoFiltro);
                                                        setNumeroOficioFiltro(filtros.numeroOficioFiltro);
                                                        setNumeroProcessoFiltro(filtros.numeroProcessoFiltro);
                                                        setStatusFiltro(filtros.statusFiltro);
                                                        setMedicaoPendenteFiltro(filtros.medicaoPendenteFiltro);

                                                        TrocarData(filtros);

                                                        localStorage["filtro-autorizacao-fornecimento"] = JSON.stringify(filtros);

                                                        fetch(`${urlBase}/api/AutorizacaoFornecimento/listarCompletoPaginacao`, {
                                                            method: "POST",
                                                            headers: new Headers({
                                                                'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
                                                                    `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
                                                                    : '',
                                                                'Accept': 'application/json',
                                                                'Content-Type': 'application/json'
                                                            }),
                                                            body: JSON.stringify({
                                                                Pagina: query.page + 1,
                                                                NumeroPorPagina: query.pageSize,
                                                                NumeroSolicitacao: query.filters.filter(c => c.column.field == "descricao").length > 0 ? query.filters.filter(c => c.column.field == "descricao")[0].value : null,
                                                                IdAutorizacaoFornecimento: query.filters.filter(c => c.column.field == "numeroAf").length > 0 ? query.filters.filter(c => c.column.field == "numeroAf")[0].value : null,
                                                                IdSolicitacaoFornecimento: query.filters.filter(c => c.column.field == "idSolicitacaoFornecimentoVersao").length > 0 ? query.filters.filter(c => c.column.field == "idSolicitacaoFornecimentoVersao")[0].value : null,
                                                                NumeroOrcamento: query.filters.filter(c => c.column.field == "numeroOrcamento").length > 0 ? query.filters.filter(c => c.column.field == "numeroOrcamento")[0].value : null,
                                                                NumeroOficio: query.filters.filter(c => c.column.field == "numeroOficio").length > 0 ? query.filters.filter(c => c.column.field == "numeroOficio")[0].value : null,
                                                                NumeroProcesso: query.filters.filter(c => c.column.field == "numeroProcesso").length > 0 ? query.filters.filter(c => c.column.field == "numeroProcesso")[0].value : null,
                                                                Status: query.filters.filter(c => c.column.field == "status").length > 0 ? query.filters.filter(c => c.column.field == "status")[0].value : null,
                                                                MedicaoPendente: query.filters.filter(c => c.column.field == "medicaoPendenteFrase").length > 0 ? query.filters.filter(c => c.column.field == "medicaoPendenteFrase")[0].value : null,
                                                                DataInicial: dataInicio !== null && dataInicio !== '' ? moment(dataInicio) : null,
                                                                DataFinal: dataFinal !== null && dataFinal !== '' ? moment(dataFinal) : null,
                                                                OrdenacaoIdAutorizacaoFornecimento:
                                                                    query.orderBy != undefined && query.orderBy.field == "numeroAf"
                                                                        ?
                                                                        query.orderBy.tableData.groupSort == "asc"
                                                                            ?
                                                                            true
                                                                            :
                                                                            false
                                                                        :
                                                                        false,
                                                                geralFiltro: query.search,
                                                            }),

                                                        }).then(response => response.json())
                                                            .then(result => {
                                                                resolve({
                                                                    data:
                                                                        result.result.data != undefined && result.result.data.length > 0 ?
                                                                            result.result.data.map((lista) => {
                                                                                return {
                                                                                    id: lista.id,
                                                                                    numeroAf: lista.numeroAf,
                                                                                    descricao: lista.solicitacaoFornecimentoVersao.idSolicitacaoFornecimento,
                                                                                    idSolicitacaoFornecimentoVersao: lista.idSolicitacaoFornecimentoVersao,
                                                                                    fornecedor:
                                                                                        lista.solicitacaoFornecimentoVersao != null &&
                                                                                            lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                                            lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento.fornecedor != null &&
                                                                                            lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento.fornecedor.nomeFornecedor != null ?
                                                                                            lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento.fornecedor.nomeFornecedor
                                                                                            :
                                                                                            '',
                                                                                    dataInclusao: moment(lista.dataInclusao).format('L LT'),
                                                                                    status: lista.statusAtual,
                                                                                    dataInclusaoApi: lista.dataInclusao,
                                                                                    numeroProcesso:
                                                                                        lista.solicitacaoFornecimentoVersao != null &&
                                                                                        lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                                        lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento.numeroProcesso,
                                                                                    numeroOficio: lista.numeroOficio,
                                                                                    numeroOrcamento: lista.solicitacaoFornecimentoVersao.numeroOrcamento,
                                                                                    medicaoPendente: lista.medicaoPendente,
                                                                                    medicaoPendenteFrase: lista.medicaoPendenteFrase,
                                                                                    assunto: lista.solicitacaoFornecimentoVersao != null &&
                                                                                        lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                                        lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento.assunto ? 
                                                                                        lista.solicitacaoFornecimentoVersao.solicitacaoFornecimento.assunto : '',
                                                                                    valorObra: lista.valorObra.toLocaleString('pt-br', {
                                                                                        style: 'currency', currency: 'BRL',
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                }
                                                                            })
                                                                            :
                                                                            []

                                                                    ,
                                                                    page: result.result.pagina - 1,
                                                                    totalCount: result.result.total,
                                                                })
                                                            }).catch(error => {
                                                                history.push(`/`);
                                                                toast.error('Usuário fornecedor não relacionado a autorização');
                                                            })
                                                    })
                                            }

                                            options={{
                                                debounceInterval: 1500,
                                                minBodyHeight: 500,
                                                sorting: true,
                                                paging: true,
                                                pageSize: 10, // Início
                                                emptyRowsWhenPaging: false,
                                                pageSizeOptions: [10, 50, 200, 300],
                                                filterRowStyle: {
                                                    backgroundColor: "#FAFAFA"
                                                },
                                                headerStyle: {
                                                    backgroundColor: '#454545',
                                                    color: '#FFF',
                                                    fontWeight: "bold",
                                                    lineBreak: "auto",
                                                    fontSize: "11px"
                                                },
                                                actionsColumnIndex: -1,
                                                filtering: true,
                                                rowStyle: rowData => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })
                                            }}
                                            actions={[
                                                rowData => ({
                                                    icon: () => <FaSearchPlus />,
                                                    onClick: () => history.push(`/alterarGeralAutorizacao/${rowData.id}`)
                                                }),
                                                {
                                                    icon: 'refresh',
                                                    tooltip: 'Refresh Data',
                                                    isFreeAction: true,
                                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                                }
                                            ]}
                                            style={{
                                                fontSize: "13px",
                                            }}
                                            localization={{
                                                header: {
                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                    editRow: {
                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                        saveTooltip: '',
                                                        cancelTooltip: '',
                                                    },
                                                    deleteTooltip: '',
                                                    editTooltip: '',
                                                    addTooltip: '',
                                                },
                                                toolbar: {
                                                    searchTooltip: '',
                                                    searchPlaceholder: 'Pesquisar',
                                                    exportTitle: "",
                                                    exportCSVName: "Exportar como CSV",
                                                    exportPDFName: "Exportar como PDF",
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'linhas',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: '',
                                                    previousTooltip: '',
                                                    nextTooltip: '',
                                                    lastTooltip: ''
                                                },
                                                grouping: {
                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                    groupedBy: 'Agrupado por:'
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}