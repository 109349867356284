import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarCategoriaItemSubitemCustoPrimarioResponse, ListarCompletoCategoriaItemSubitemCustoPrimarioResponse } from './actions';
import { ListarSubitemCustoCompletoResponse } from '../subitemCusto/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarCategoriaItemSubitemCustoPrimarioRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/CategoriaItemSubitemCustoPrimario`, { headers: headerParams.token });

    yield put(ListarCategoriaItemSubitemCustoPrimarioResponse(result.data.result));
    yield put(Carregando(true));
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ListarCategoriaItemSubitemCustoPrimarioResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }

}

function* InserirCategoriaItemSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    let data = {
      ListaCategoriaItemSubitemCustoPrimario: model.data
    }

    yield call(api.post, `/api/CategoriaItemSubitemCustoPrimario`, data, { headers: headerParams.token });

    var dados = {
      'IdCategoriaItem': parseInt(model.idCategoriaItem)
    }

    const result = yield call(api.post, `/api/CategoriaItemSubitemCustoPrimario/listarCompleto`, dados, { headers: headerParams.token });

    yield put(ListarCompletoCategoriaItemSubitemCustoPrimarioResponse(result.data.result));
      
    var dataS = {
      'IdContrato': parseInt(model.idContrato)
    }

    const resultSubitem = yield call(api.post, `/api/subItemCusto/listarCompleto`, dataS, { headers: headerParams.token });

    if (result.data.result == null) {
      yield put(ListarSubitemCustoCompletoResponse([]));
    } else {
      yield put(ListarSubitemCustoCompletoResponse(resultSubitem.data.result));
    }

    yield put(Carregando(true));    

    toast.success(`O item foi incluido com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarCategoriaItemSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    yield call(api.put, `/api/CategoriaItemSubitemCustoPrimario`, model.data, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Relação categoria com item foi alterado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }

}

function* ExcluirCategoriaItemSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/CategoriaItemSubitemCustoPrimario/${model.id}`, { headers: headerParams.token });

    var data = {
      'IdCategoriaItem': parseInt(model.idCategoriaItem)
    }

    const result = yield call(api.post, `/api/CategoriaItemSubitemCustoPrimario/listarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoCategoriaItemSubitemCustoPrimarioResponse(result.data.result));

    yield put(Carregando(true));    
    
      toast.success(`Relação categoria com item foi excluido com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ListarCompletoCategoriaItemSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var data = {
      'IdCategoriaItem': parseInt(model.data.IdCategoriaItem)
    }

    const result = yield call(api.post, `/api/CategoriaItemSubitemCustoPrimario/listarCompleto`, data,{ headers: headerParams.token });

    yield put(ListarCompletoCategoriaItemSubitemCustoPrimarioResponse(result.data.result));

    yield put(Carregando(true));    
  } catch (e) {

    if (e.response != null && e.response.data != null) {
      if(e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }

    yield put(ListarCompletoCategoriaItemSubitemCustoPrimarioResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }

}

export default all([
  takeLatest('LISTAR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST', ListarCategoriaItemSubitemCustoPrimarioRequest),
  takeLatest('INSERIR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST', InserirCategoriaItemSubitemCustoPrimarioRequest),
  takeLatest('ALTERAR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST', AlterarCategoriaItemSubitemCustoPrimarioRequest),
  takeLatest('EXCLUIR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST', ExcluirCategoriaItemSubitemCustoPrimarioRequest),
  takeLatest('LISTAR_COMPLETO_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_REQUEST', ListarCompletoCategoriaItemSubitemCustoPrimarioRequest)
])