import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarTipoCaracteristicaResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarTipoCaracteristicaRequest() {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.get, `/api/TipoCaracteristica`, { headers: headerParams.token });

        yield put(ListarTipoCaracteristicaResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ListarTipoCaracteristicaResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* InserirTipoCaracteristicaRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.post, `/api/TipoCaracteristica`, model.data, { headers: headerParams.token });

        toast.success(`O interessado foi cadastrado com sucesso!`);

        const result = yield call(api.get, `/api/TipoCaracteristica`, { headers: headerParams.token });

        yield put(ListarTipoCaracteristicaResponse(result.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* AlterarTipoCaracteristicaRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.put, `/api/TipoCaracteristica`, model.data, { headers: headerParams.token });

        toast.success(`TipoCaracteristica foi atualizado com sucesso!`);

        // Listar TipoCaracteristica
        const result = yield call(api.get, `/api/TipoCaracteristica`, { headers: headerParams.token });

        yield put(ListarTipoCaracteristicaResponse(result.data.result));
        yield put(Carregando(true));


    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ExcluirTipoCaracteristicaRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.delete, `/api/TipoCaracteristica/${model.id}`, { headers: headerParams.token });

        toast.success(`Tipo solicitacao id: ${model.id} foi excluida!`);

        const result = yield call(api.get, `/api/TipoCaracteristica`, { headers: headerParams.token });

        yield put(ListarTipoCaracteristicaResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

export default all([
    takeLatest('LISTAR_TIPO_CARACTERISTICA_REQUEST', ListarTipoCaracteristicaRequest),
    takeLatest('INSERIR_TIPO_CARACTERISTICA_REQUEST', InserirTipoCaracteristicaRequest),
    takeLatest('ALTERAR_TIPO_CARACTERISTICA_REQUEST', AlterarTipoCaracteristicaRequest),
    takeLatest('EXCLUIR_TIPO_CARACTERISTICA_REQUEST', ExcluirTipoCaracteristicaRequest)
])