export function ListarFonteReceitaVersaoRequest() {
  return {
    type: 'LISTAR_FONTE_RECEITA_VERSAO_REQUEST'
  }
}

export function ListarFonteReceitaVersaoResponse(listaFonteVersao) {
  return {
    type: 'LISTAR_FONTE_RECEITA_VERSAO_RESPONSE',
    listaFonteVersao
  }
}

export function BuscarFonteReceitaVersaoPorIdRequest(id) {
  return {
    type: 'BUSCAR_FONTE_RECEITA_VERSAO_POR_ID_REQUEST',
    id
  }
}

export function BuscarFonteReceitaVersaoPorIdResponse(fonteReceitaVersaoCompleta) {
  return {
    type: 'BUSCAR_FONTE_RECEITA_VERSAO_POR_ID_RESPONSE',
    fonteReceitaVersaoCompleta
  }
}

export function InserirFonteReceitaVersaoRequest(data) {
  return {
    type: 'INSERIR_FONTE_RECEITA_VERSAO_REQUEST',
    data
  }
}

export function AlterarFonteReceitaVersaoRequest(data) {
  return {
    type: 'ALTERAR_FONTE_RECEITA_VERSAO_REQUEST',
    data
  }
}

export function ExcluirFonteReceitaVersaoRequest(id, idFonteReceita) {
  return {
    type: 'EXCLUIR_FONTE_RECEITA_VERSAO_REQUEST',
    id, idFonteReceita
  }
}

export function ListarPorUltimaVersaoAnoRequest(idFonte) {
  return {
    type: 'LISTAR_POR_ULTIMA_VERSAO_ANO_REQUEST',
    idFonte
  }
}

export function ListarPorUltimaVersaoAnoResponse(listaFonteReceitaVersaoUltimo) {
  return {
    type: 'LISTAR_POR_ULTIMA_VERSAO_ANO_RESPONSE',
    listaFonteReceitaVersaoUltimo
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
