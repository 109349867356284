import { Button, Col, Card, Row, Form, DropdownButton, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IoChatboxEllipsesOutline } from 'react-icons/io5';
import { FaSearchPlus } from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ListarContratoRequest } from '../../../store/modules/contrato/actions';
import React, { useEffect, useState } from "react";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarStatusSolicitacaoFornecimentoCompletoRequest } from '../../../store/modules/statusSolicitacaoFornecimento/actions';
import { ListarCompletoSolicitacaoFornecimentoRequest } from '../../../store/modules/solicitacaoFornecimento/actions';
import { ListarAutorizacaoFornecimentoRequest } from '../../../store/modules/autorizacaoFornecimento/actions';
import { toast } from "react-toastify";
import Loading from '../../../components/Loading/loading';
import urlBase from '../../../services/rotas';
import MaterialTable from 'material-table';
import Cookies from 'universal-cookie';
import moment from 'moment'
import Header from "../../../components/Header/header";
import './listar.css';
import { AtualizarToken } from "../../../store/modules/global/actions";
import Select from 'react-select';

export default function Listar() {

    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const tableRef = React.createRef();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);
    const cookies = new Cookies();

    const listaSolicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.listaSolicitacaoFornecimento);
    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const carregando = useSelector(state => state.solicitacaoFornecimento.carregando);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);
    const headerParams = useSelector(state => state.global.token);
    const listaAutorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.listaAutorizacaoFornecimento);

    const [selectedRow, setSelectedRow] = useState(null);

    const [idCheck, setIdCheck] = useState(true);
    const [assunto, setAssunto] = useState(true);
    const [contratoCheck, setContratoCheck] = useState(false);
    const [fornecedor, setFornecedor] = useState(false);
    const [endereco, setEndereco] = useState(true);
    const [regiao, setRegiao] = useState(false);
    const [numeroProcesso, setNumeroProcesso] = useState(true);
    const [numeroOficio, setNumeroOficio] = useState(false);
    const [numeroOrcamento, setNumeroOrcamento] = useState(true);
    const [tipoSolicitacao, setTipoSolicitacao] = useState(true);
    const [prioridade, setPrioridade] = useState(false);
    const [dataCriacao, setDataCriacao] = useState(false);
    const [observacao, setObservacao] = useState(false);
    const [statusCheck, setStatusCheck] = useState(true);
    const [nomeEvento, setNomeEvento] = useState(false);
    const [dataEvento, setDataEvento] = useState(false);
    const [numeroRefletores, setNumeroRefletores] = useState(false);
    const [orcamentoEmAtraso, setOrcamentoEmAtraso] = useState(false);
    const [totalValorOrcamento, setTotalValorOrcamento] = useState(false);
    const [porteObra, setPorteObra] = useState(false);
    const [idAutorizacaoFornecimento, setIdAutorizacaoFornecimento] = useState(false);
    const [observacaoCancelamento, setObservacaoCancelamento] = useState(false);
    const [interessado, setinteressado] = useState(false);
    const [tabelaCarregando, setTabelaCarregando] = useState(false);
    const [listaData, setListaData] = useState([]);
    const [bairro, setBairro] = useState(false);
    const [dataStatusReprovado, setDataStatusReprovado] = useState(false);
    const [numeroOficioOrigem, setNumeroOficioOrigem] = useState(false);

    const [ordenacaoIdSolicitacaoFornecimento, setOrdenacaoIdSolicitacaoFornecimento] = useState(false);

    let [filtros] = useState(JSON.parse(localStorage.getItem('filtro-solicitacao-fornecimento')));

    const [idSolicitacaoFornecimentoFiltro, setIdSolicitacaoFornecimentoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.idSolicitacaoFornecimentoFiltro : "");
    const [tipoSolicitacaoFiltro, setTipoSolicitacaoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.tipoSolicitacaoFiltro : "");
    const [assuntoFiltro, setAssuntoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.assuntoFiltro : "");
    const [localFiltro, setLocalFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.localFiltro : "");
    const [statusFiltro, setStatusFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.statusFiltro : 0);
    const [numeroProcessoFiltro, setNumeroProcessoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroProcessoFiltro : "");
    const [regiaoFiltro, setRegiaoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.regiaoFiltro : "");
    const [numeroOficioFiltro, setNumeroOficioFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroOficioFiltro : "");
    const [numeroOrcamentoFiltro, setNumeroOrcamentoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroOrcamentoFiltro : "");
    const [fornecedorFiltro, setFornecedorFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.fornecedorFiltro : "");
    const [nomeEventoFiltro, setNomeEventoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.nomeEventoFiltro : "");
    const [prioridadeFiltro, setPrioridadeFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.prioridadeFiltro : "");
    const [bairroFiltro, setBairroFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.bairroFiltro : "");
    const [autorizacaoFornecimentoFiltro, setAutorizacaoFornecimentoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.autorizacaoFornecimentoFiltro : "");
    const [interessadoFiltro, setInteressadoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.interessadoFiltro : "");
    const [numeroOficioOrigemFiltro, setNumeroOficioOrigemFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroOficioOrigemFiltro : "");

    const [listaStatus, setListaStatus] = useState([
        { value: '6', label: "Inicial" },
        { value: '7', label: "Análise de Orçamento" },
        { value: '8', label: "Solicitação Aprovada" },
        { value: '10', label: "Solicitação Finalizada" },
        { value: '11', label: "Solicitação Cancelada" },
        { value: '12', label: "Orçamento Solicitado" },
        { value: '14', label: "Solicitação Recebida" },
        { value: '16', label: "Projeto" },
        { value: '17', label: "Orçamento Finalizado" },
        { value: 'Análise de Orçamento (Reprovada)', label: "Análise de Orçamento (Reprovada)" },
        { value: 'Análise de Orçamento (Aprovada)', label: "Análise de Orçamento (Aprovada)" },
    ]);

    const listaNumeroAF =
        listaAutorizacaoFornecimento !== null && listaAutorizacaoFornecimento !== undefined ?
            listaAutorizacaoFornecimento.map(lista =>{
                if(lista.numeroAf !== null && lista.numeroAf !== '' &&
                lista.numeroAf !== undefined){
                    return{
                        value: `${lista.id}`, label: lista.numeroAf
                    }
                }
            })
        :
        [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

    }, [])

    useEffect(() => {

        dispatch(ListarContratoRequest());
        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarStatusSolicitacaoFornecimentoCompletoRequest());
        dispatch(ListarAutorizacaoFornecimentoRequest());
        dispatch(ListarCompletoSolicitacaoFornecimentoRequest({
            IdSolicitacaoFornecimento: null,
            IdContrato: null,
            Servico: false
        }));

        dispatch(AtualizarToken());

    }, [])

    useEffect(() => {
        //console.log(listaSolicitacaoFornecimento)
        //constroiData();

    }, [listaSolicitacaoFornecimento])

    /*function constroiData() {

        let listaDataTemp = [];

        if (listaContrato != undefined && listaContrato.length > 0 && listaSolicitacaoFornecimento.data != undefined && listaContratoUsuario != undefined && listaSolicitacaoFornecimento.data.length > 0) {
            
            listaSolicitacaoFornecimento.data.map(solicitacaoFornecimento => {

                var contrato = [];
                if (solicitacaoFornecimento.contratoVersao != null && solicitacaoFornecimento.contratoVersao.idContrato != null) {
                    contrato = listaContrato.filter((contrato) => contrato.id === solicitacaoFornecimento.contratoVersao.idContrato)
                }

                if (contrato.length > 0 && contrato[0].privado && (listaContratoUsuario.filter((contratoUsu) =>
                    contratoUsu.idUsuario === usuario.id
                    && contratoUsu.idContrato === contrato[0].id).length === 0)) {
                    return null
                    // Verifica se é tipo servico para mostrar nessa tabela
                } else if (solicitacaoFornecimento.tipoSolicitacao.servico == false) {
                    return null
                } else {
                    listaDataTemp.push({
                        id: solicitacaoFornecimento.id,
                        idContrato: contrato[0].id + " - " + contrato[0].descricao,
                        descricao: solicitacaoFornecimento.descricaoSolicitacao,
                        numeroProcesso: solicitacaoFornecimento.numeroProcesso,
                        local: solicitacaoFornecimento.descricaoLocal,
                        numeroOficio: solicitacaoFornecimento.numeroOficio,
                        regiao:
                            solicitacaoFornecimento.regiao != null ?
                                solicitacaoFornecimento.regiao.descricao
                                : '',
                        tipo:
                            solicitacaoFornecimento.tipoSolicitacao != null ?
                                solicitacaoFornecimento.tipoSolicitacao.descricao
                                : '',
                        fornecedor:
                            solicitacaoFornecimento.fornecedor != null ?
                                solicitacaoFornecimento.fornecedor.nomeFornecedor
                                : '',
                        dataSolicitacao: moment(solicitacaoFornecimento.dataSolicitacao).format('L LT'),
                        status: solicitacaoFornecimento.statusAtual,
                        prioridade: solicitacaoFornecimento.prioridade.descricao,
                        corHex: solicitacaoFornecimento.prioridade.corHex,
                        observacao: solicitacaoFornecimento.observacao,
                        nomeEvento:
                            solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento").length > 0 ?
                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento")[0].valorVarchar : '',
                        dataEvento:
                            solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial").length > 0 ?
                                moment(solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial")[0].valorDateTime).format('L')
                                : '',
                        nrRefletores:
                            solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores").length > 0 ?
                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores")[0].valorInteger : '',
                        orcamentoEmAtraso: solicitacaoFornecimento.orcamentoEmAtraso ? 'Sim' : 'Não'
                    })
                }
            }).filter(Boolean)

            setListaData(listaDataTemp);
            setTabelaCarregando(true);
        }
    }*/

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col md={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <IoChatboxEllipsesOutline size={25} color={"#000"} /> Solicitação Fornecimento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                {
                                    listaContrato != undefined && listaContratoUsuario != undefined &&
                                        listaStatusSolicitacaoFornecimentoCompleto != undefined ?

                                        <div>
                                            <Row style={{ marginLeft: "1em" }} className="mb-3">
                                                <Col>
                                                    <Link to="/cadastroGeralSolicitacao"><Button variant="success">Nova Solicitação Fornecimento</Button></Link>
                                                </Col>
                                            </Row>

                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Solicitação Fornecimento</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col>
                                                                <DropdownButton variant="success" title="Colunas">
                                                                    <Form>
                                                                        <div className="tamanhoTela">
                                                                            <Row>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={idCheck} onChange={() => setIdCheck(!idCheck)} />
                                                                                        <Form.Check.Label> #</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={assunto} onChange={() => setAssunto(!assunto)} />
                                                                                        <Form.Check.Label> Assunto</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={contratoCheck} onChange={() => setContratoCheck(!contratoCheck)} />
                                                                                        <Form.Check.Label> Contrato</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={fornecedor} onChange={() => setFornecedor(!fornecedor)} />
                                                                                        <Form.Check.Label> Fornecedor</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                            </Row>

                                                                            <Row>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={endereco} onChange={() => setEndereco(!endereco)} />
                                                                                        <Form.Check.Label> Local</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={bairro} onChange={() => setBairro(!bairro)} />
                                                                                        <Form.Check.Label> Bairro</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={regiao} onChange={() => setRegiao(!regiao)} />
                                                                                        <Form.Check.Label> Região</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={numeroProcesso} onChange={() => setNumeroProcesso(!numeroProcesso)} />
                                                                                        <Form.Check.Label> Número Processo</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                            </Row>

                                                                            <Row>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={numeroOficio} onChange={() => setNumeroOficio(!numeroOficio)} />
                                                                                        <Form.Check.Label> Número Oficio</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={tipoSolicitacao} onChange={() => setTipoSolicitacao(!tipoSolicitacao)} />
                                                                                        <Form.Check.Label> Tipo Solicitação</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={prioridade} onChange={() => setPrioridade(!prioridade)} />
                                                                                        <Form.Check.Label> Prioridade</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={dataCriacao} onChange={() => setDataCriacao(!dataCriacao)} />
                                                                                        <Form.Check.Label> Data Criação</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                            </Row>

                                                                            <Row>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={observacao} onChange={() => setObservacao(!observacao)} />
                                                                                        <Form.Check.Label> Observação</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={statusCheck} onChange={() => setStatusCheck(!statusCheck)} />
                                                                                        <Form.Check.Label> Status</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={nomeEvento} onChange={() => setNomeEvento(!nomeEvento)} />
                                                                                        <Form.Check.Label> Nome Evento</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={orcamentoEmAtraso} onChange={() => setOrcamentoEmAtraso(!orcamentoEmAtraso)} />
                                                                                        <Form.Check.Label> Orçamento em Atraso</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                            </Row>

                                                                            <Row>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={totalValorOrcamento} onChange={() => setTotalValorOrcamento(!totalValorOrcamento)} />
                                                                                        <Form.Check.Label> Valor total do Orçamento</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={porteObra} onChange={() => setPorteObra(!porteObra)} />
                                                                                        <Form.Check.Label> Porte de Obra</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={idAutorizacaoFornecimento} onChange={() => setIdAutorizacaoFornecimento(!idAutorizacaoFornecimento)} />
                                                                                        <Form.Check.Label> Número AF</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={observacaoCancelamento} onChange={() => setObservacaoCancelamento(!observacaoCancelamento)} />
                                                                                        <Form.Check.Label>Observação Cancelamento</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                            </Row>

                                                                            <Row>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={interessado} onChange={() => setinteressado(!interessado)} />
                                                                                        <Form.Check.Label>Interessado</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={dataStatusReprovado} onChange={() => setDataStatusReprovado(!dataStatusReprovado)} />
                                                                                        <Form.Check.Label> Data Análise de Orçamento (Reprovada)</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={3}>
                                                                                    <Form.Check>
                                                                                        <Form.Check.Input aria-label="option 1" checked={numeroOficioOrigem} onChange={() => setNumeroOficioOrigem(!numeroOficioOrigem)} />
                                                                                        <Form.Check.Label> Número Oficio (Origem)</Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </Form.Group>
                                                                            </Row>
                                                                        </div>
                                                                    </Form>
                                                                </DropdownButton>
                                                            </Col>
                                                        </Row><br />

                                                        <MaterialTable
                                                            components={{
                                                                Pagination: PatchedPagination,
                                                            }}
                                                            title='Solicitação Fornecimento'
                                                            columns={[
                                                                {
                                                                    title: '', field: 'corHex', filtering: false, sorting: false,
                                                                    cellStyle: {
                                                                        width: '5%'
                                                                    },
                                                                    render: rowData =>
                                                                        <div
                                                                            style={{
                                                                                height: '25px',
                                                                                width: '15px',
                                                                                backgroundColor: rowData.corHex,
                                                                            }}></div>
                                                                },
                                                                {
                                                                    title: 'Número da Solicitação', field: 'id', sorting: true,
                                                                    hidden: idCheck ? false : true,
                                                                    defaultFilter: idSolicitacaoFornecimentoFiltro
                                                                },
                                                                {
                                                                    title: 'Tipo Solicitação', field: 'tipo', sorting: false,
                                                                    hidden: tipoSolicitacao ? false : true,
                                                                    defaultFilter: tipoSolicitacaoFiltro
                                                                },
                                                                {
                                                                    title: 'Assunto', field: 'assunto', sorting: false,
                                                                    hidden: assunto ? false : true,
                                                                    defaultFilter: assuntoFiltro
                                                                },
                                                                {
                                                                    title: 'Id/Descrição Contrato', field: 'idContrato', sorting: false,
                                                                    hidden: contratoCheck ? false : true,
                                                                },
                                                                {
                                                                    title: 'Fornecedor', field: 'fornecedor', sorting: false,
                                                                    hidden: fornecedor ? false : true,
                                                                    defaultFilter: fornecedorFiltro
                                                                },
                                                                {
                                                                    title: 'Local', field: 'local', sorting: false,
                                                                    hidden: endereco ? false : true,
                                                                    defaultFilter: localFiltro
                                                                },
                                                                {
                                                                    title: 'Bairro', field: 'bairro', sorting: false,
                                                                    hidden: bairro ? false : true,
                                                                    defaultFilter: bairroFiltro
                                                                },
                                                                {
                                                                    title: 'Região', field: 'regiao', sorting: false,
                                                                    hidden: regiao ? false : true,
                                                                    defaultFilter: regiaoFiltro
                                                                },
                                                                {
                                                                    title: 'Número Processo', field: 'numeroProcesso', sorting: false,
                                                                    hidden: numeroProcesso ? false : true,
                                                                    defaultFilter: numeroProcessoFiltro
                                                                },
                                                                {
                                                                    title: 'Número Ofício', field: 'numeroOficio', sorting: false,
                                                                    hidden: numeroOficio ? false : true,
                                                                    defaultFilter: numeroOficioFiltro
                                                                },
                                                                {
                                                                    title: 'Número Ofício (Origem)', field: 'numeroOficioOrigem', sorting: false,
                                                                    hidden: numeroOficioOrigem ? false : true,
                                                                    defaultFilter: numeroOficioOrigemFiltro
                                                                },
                                                                {
                                                                    title: 'Número Orçamento', field: 'numeroOrcamento', sorting: false,
                                                                    hidden: numeroOrcamento ? false : true,
                                                                    defaultFilter: numeroOrcamentoFiltro
                                                                },
                                                                {
                                                                    title: 'Prioridade', field: 'prioridade', sorting: false,
                                                                    hidden: prioridade ? false : true,
                                                                    defaultFilter: prioridadeFiltro
                                                                },
                                                                {
                                                                    title: 'Data Criação', field: 'dataSolicitacao', sorting: false,
                                                                    hidden: dataCriacao ? false : true,
                                                                },
                                                                {
                                                                    title: 'Nome Evento', field: 'nomeEvento', sorting: false,
                                                                    hidden: nomeEvento ? false : true,
                                                                    defaultFilter: nomeEventoFiltro
                                                                },
                                                                {
                                                                    title: 'Data Evento', field: 'dataEvento', sorting: false,
                                                                    hidden: dataEvento ? false : true,
                                                                },
                                                                {
                                                                    title: 'Observação', field: 'observacao', sorting: false,
                                                                    hidden: observacao ? false : true,
                                                                },
                                                                {
                                                                    title: 'Número de refletores', field: 'nrRefletores', sorting: false,
                                                                    hidden: numeroRefletores ? false : true,
                                                                },
                                                                {
                                                                    title: 'Orçamento Em Atraso', field: 'orcamentoEmAtraso', sorting: false,
                                                                    hidden: orcamentoEmAtraso ? false : true
                                                                },
                                                                {
                                                                    title: 'Orçamento Em Atraso', field: 'orcamentoEmAtraso', sorting: false,
                                                                    hidden: orcamentoEmAtraso ? false : true
                                                                },
                                                                {
                                                                    title: 'Valor Total Orçamento', field: 'totalValorOrcamento', sorting: false,
                                                                    hidden: totalValorOrcamento ? false : true, filtering: false
                                                                },
                                                                {
                                                                    title: 'Porte Obra', field: 'porteObra', sorting: false,
                                                                    hidden: porteObra ? false : true, filtering: false,
                                                                },
                                                                {
                                                                    title: 'Número AF', field: 'idAutorizacaoFornecimento', sorting: false,
                                                                    hidden: idAutorizacaoFornecimento ? false : true, 
                                                                    defaultFilter: autorizacaoFornecimentoFiltro,
                                                                    filterComponent: (props) => {
                                                                        return (
                                                                            <Select
                                                                                placeholder="Selecione..."
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                options={listaNumeroAF}
                                                                                onChange={(e) => {
                                                                                    props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.value : null)
                                                                                }}
                                                                                defaultValue={
                                                                                    {
                                                                                        label: listaNumeroAF.filter(v => v.value === autorizacaoFornecimentoFiltro).length > 0 ? listaNumeroAF.filter(v => v.value === autorizacaoFornecimentoFiltro)[0].label : "",
                                                                                        value: listaNumeroAF.filter(v => v.value === autorizacaoFornecimentoFiltro).length > 0 ? listaNumeroAF.filter(v => v.value === autorizacaoFornecimentoFiltro)[0].value : "",
                                                                                    }
                                                                                }
                                                                            />
                                                                        )
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Data Análise de Orçamento (Reprovada)', field: 'dataStatusReprovado',
                                                                    hidden: dataStatusReprovado ? false : true,
                                                                    filtering: false,
                                                                },
                                                                {
                                                                    title: 'Status', field: 'status', sorting: false,
                                                                    hidden: statusCheck ? false : true,
                                                                    filtering: true,
                                                                    defaultFilter: statusFiltro,
                                                                    filterComponent: (props) => {
                                                                        return (
                                                                            <Select
                                                                                placeholder="Selecione..."
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                options={listaStatus}
                                                                                onChange={(e) => {
                                                                                    props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.value : null)
                                                                                }}
                                                                                defaultValue={
                                                                                    {
                                                                                        label: listaStatus.filter(v => v.value === statusFiltro).length > 0 ? listaStatus.filter(v => v.value === statusFiltro)[0].label : "",
                                                                                        value: listaStatus.filter(v => v.value === statusFiltro).length > 0 ? listaStatus.filter(v => v.value === statusFiltro)[0].value : "",
                                                                                    }
                                                                                }
                                                                            />
                                                                        )
                                                                    },
                                                                    render: rowData =>
                                                                        listaStatusSolicitacaoFornecimentoCompleto != undefined
                                                                            ?
                                                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].
                                                                                    descricaoSolicitacaoFornecimentoVersao === rowData.status ?
                                                                                <div className="divStatusGreenListar">{rowData.status}</div>
                                                                                :
                                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].
                                                                                        descricaoSolicitacaoFornecimentoVersao === rowData.status || 'Orçamento em Atraso' == rowData.status ?
                                                                                    <div className="divStatusDangerListar">{rowData.status}</div>
                                                                                    :
                                                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true).length > 0 &&
                                                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true)[0].
                                                                                            descricaoSolicitacaoFornecimentoVersao + ' (Aprovada)' === rowData.status ?
                                                                                        <div className="divStatusGreenListar">{rowData.status}</div>
                                                                                        :
                                                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true).length > 0 &&
                                                                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true)[0].
                                                                                                descricaoSolicitacaoFornecimentoVersao + ' (Reprovada)' === rowData.status ?
                                                                                            <div className="divStatusDangerListar">{rowData.status}</div>
                                                                                            :
                                                                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.iniciaSolicitacao == true).length > 0 &&
                                                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.iniciaSolicitacao == true)[0].
                                                                                                    descricaoSolicitacaoFornecimentoVersao === rowData.status ||
                                                                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true).length > 0 &&
                                                                                                'Em ' + listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.statusAnalise == true)[0].
                                                                                                    descricaoSolicitacaoFornecimentoVersao === rowData.status || 'Orçamento Pendente' == rowData.status ?
                                                                                                <div className="divStatusNormalListar">{rowData.status}</div>
                                                                                                :
                                                                                                <div className="divStatusGreenListar">{rowData.status}</div>
                                                                            :
                                                                            ''
                                                                },
                                                                {
                                                                    title: 'Observação Cancelamento', field: 'observacaoCancelamento', sorting: false,
                                                                    hidden: observacaoCancelamento ? false : true, filtering: false,
                                                                },
                                                                {
                                                                    title: 'Interessado', field: 'interessado', sorting: false,
                                                                    hidden: interessado ? false : true, defaultFilter: interessadoFiltro,
                                                                },
                                                            ]}

                                                            data={
                                                                query =>
                                                                    new Promise((resolve, reject) => {

                                                                        filtros = {
                                                                            "idSolicitacaoFornecimentoFiltro": "",
                                                                            "tipoSolicitacaoFiltro": "",
                                                                            "assuntoFiltro": "",
                                                                            "localFiltro": "",
                                                                            "statusFiltro": "",
                                                                            "numeroProcessoFiltro": "",
                                                                            "regiaoFiltro": "",
                                                                            "numeroOficioFiltro": "",
                                                                            "numeroOrcamentoFiltro": "",
                                                                            "fornecedorFiltro": "",
                                                                            "nomeEventoFiltro": "",
                                                                            "prioridadeFiltro": "",
                                                                            "bairroFiltro": "",
                                                                            "autorizacaoFornecimentoFiltro": "",
                                                                            "interessadoFiltro": "",
                                                                            "numeroOficioOrigemFiltro": "",
                                                                        };

                                                                        //filtros
                                                                        if (query.filters.filter(c => c.column.field == "id").length > 0) {
                                                                            filtros.idSolicitacaoFornecimentoFiltro = query.filters.filter(c => c.column.field == "id")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "tipo").length > 0 > 0) {
                                                                            filtros.tipoSolicitacaoFiltro = query.filters.filter(c => c.column.field == "tipo")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "assunto").length > 0) {
                                                                            filtros.assuntoFiltro = query.filters.filter(c => c.column.field == "assunto")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "local").length > 0) {
                                                                            filtros.localFiltro = query.filters.filter(c => c.column.field == "local")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "status").length > 0) {
                                                                            filtros.statusFiltro = query.filters.filter(c => c.column.field == "status")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "numeroProcesso").length > 0) {
                                                                            filtros.numeroProcessoFiltro = query.filters.filter(c => c.column.field == "numeroProcesso")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "regiao").length > 0) {
                                                                            filtros.regiaoFiltro = query.filters.filter(c => c.column.field == "regiao")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "numeroOficio").length > 0) {
                                                                            filtros.numeroOficioFiltro = query.filters.filter(c => c.column.field == "numeroOficio")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "numeroOrcamento").length > 0) {
                                                                            filtros.numeroOrcamentoFiltro = query.filters.filter(c => c.column.field == "numeroOrcamento")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "fornecedor").length > 0) {
                                                                            filtros.fornecedorFiltro = query.filters.filter(c => c.column.field == "fornecedor")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "nomeEvento").length > 0) {
                                                                            filtros.nomeEventoFiltro = query.filters.filter(c => c.column.field == "nomeEvento")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "prioridade").length > 0) {
                                                                            filtros.prioridadeFiltro = query.filters.filter(c => c.column.field == "prioridade")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "bairro").length > 0) {
                                                                            filtros.bairroFiltro = query.filters.filter(c => c.column.field == "bairro")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento").length > 0) {
                                                                            filtros.autorizacaoFornecimentoFiltro = query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "interessado").length > 0) {
                                                                            filtros.interessadoFiltro = query.filters.filter(c => c.column.field == "interessado")[0].value;
                                                                        }
                                                                        if (query.filters.filter(c => c.column.field == "numeroOficioOrigem").length > 0) {
                                                                            filtros.numeroOficioOrigemFiltro = query.filters.filter(c => c.column.field == "numeroOficioOrigem")[0].value;
                                                                        }

                                                                        localStorage["filtro-solicitacao-fornecimento"] = JSON.stringify(filtros);

                                                                        fetch(`${urlBase}/api/SolicitacaoFornecimento/ListarCompletoPaginacao`, {
                                                                            method: "POST",
                                                                            headers: new Headers({
                                                                                'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
                                                                                    `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
                                                                                    : '',
                                                                                'Accept': 'application/json',
                                                                                'Content-Type': 'application/json'
                                                                            }),
                                                                            body: JSON.stringify({
                                                                                IdSolicitacaoFornecimento: query.filters.filter(c => c.column.field == "id").length > 0 ? query.filters.filter(c => c.column.field == "id")[0].value : null,
                                                                                TipoSolicitacao: query.filters.filter(c => c.column.field == "tipo").length > 0 ? query.filters.filter(c => c.column.field == "tipo")[0].value : null,
                                                                                Assunto: query.filters.filter(c => c.column.field == "assunto").length > 0 ? query.filters.filter(c => c.column.field == "assunto")[0].value : null,
                                                                                Local: query.filters.filter(c => c.column.field == "local").length > 0 ? query.filters.filter(c => c.column.field == "local")[0].value : null,
                                                                                Status: query.filters.filter(c => c.column.field == "status").length > 0 ? query.filters.filter(c => c.column.field == "status")[0].value : null,
                                                                                NumeroProcesso: query.filters.filter(c => c.column.field == "numeroProcesso").length > 0 ? query.filters.filter(c => c.column.field == "numeroProcesso")[0].value : null,
                                                                                Regiao: query.filters.filter(c => c.column.field == "regiao").length > 0 ? query.filters.filter(c => c.column.field == "regiao")[0].value : null,
                                                                                NumeroOficio: query.filters.filter(c => c.column.field == "numeroOficio").length > 0 ? query.filters.filter(c => c.column.field == "numeroOficio")[0].value : null,
                                                                                numeroOrcamento: query.filters.filter(c => c.column.field == "numeroOrcamento").length > 0 ? query.filters.filter(c => c.column.field == "numeroOrcamento")[0].value : null,
                                                                                Fornecedor: query.filters.filter(c => c.column.field == "fornecedor").length > 0 ? query.filters.filter(c => c.column.field == "fornecedor")[0].value : null,
                                                                                Prioridade: query.filters.filter(c => c.column.field == "prioridade").length > 0 ? query.filters.filter(c => c.column.field == "prioridade")[0].value : null,
                                                                                Bairro: query.filters.filter(c => c.column.field == "bairro").length > 0 ? query.filters.filter(c => c.column.field == "bairro")[0].value : null,
                                                                                NumeroAutorizacaoFornecimento: query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento").length > 0 ? query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento")[0].value : null,
                                                                                Interessado: query.filters.filter(c => c.column.field == "interessado").length > 0 ? query.filters.filter(c => c.column.field == "interessado")[0].value : null,
                                                                                OrdenacaoIdSolicitacaoFornecimento:
                                                                                    query.orderBy != undefined && query.orderBy.field == "id"
                                                                                        ?
                                                                                        query.orderBy.tableData.groupSort == "asc"
                                                                                            ?
                                                                                            true
                                                                                            :
                                                                                            false
                                                                                        :
                                                                                        false,
                                                                                NomeEvento: query.filters.filter(c => c.column.field == "nomeEvento").length > 0 ? query.filters.filter(c => c.column.field == "nomeEvento")[0].value : null,
                                                                                NumeroOficioOrigem: query.filters.filter(c => c.column.field == "numeroOficioOrigem").length > 0 ? query.filters.filter(c => c.column.field == "numeroOficioOrigem")[0].value : null,
                                                                                GeralFiltro: query.search,
                                                                                Pagina: query.page + 1,
                                                                                NumeroPorPagina: query.pageSize
                                                                            }),

                                                                        }).then(response => response.json())
                                                                            .then(result => {
                                                                                resolve({
                                                                                    data:
                                                                                        listaContrato != undefined && listaContrato.length > 0 && listaContratoUsuario != undefined &&
                                                                                            result.result.data != undefined && result.result.data != null && result.result.data.length > 0 ?

                                                                                            result.result.data.map(solicitacaoFornecimento => {

                                                                                                var contrato = [];
                                                                                                if (solicitacaoFornecimento.contratoVersao != null && solicitacaoFornecimento.contratoVersao.idContrato != null) {
                                                                                                    contrato = listaContrato.filter((contrato) => contrato.id === solicitacaoFornecimento.contratoVersao.idContrato)
                                                                                                }

                                                                                                if (contrato.length > 0 && contrato[0].privado && (listaContratoUsuario.filter((contratoUsu) =>
                                                                                                    contratoUsu.idUsuario === usuario.id
                                                                                                    && contratoUsu.idContrato === contrato[0].id).length === 0)) {
                                                                                                    return null
                                                                                                    // Verifica se é tipo servico para mostrar nessa tabela
                                                                                                } else if (solicitacaoFornecimento.tipoSolicitacao.servico == false) {
                                                                                                    return null
                                                                                                } else {
                                                                                                    if(solicitacaoFornecimento.dataStatusReprovado && dataStatusReprovado === false){
                                                                                                        setDataStatusReprovado(true);
                                                                                                    }

                                                                                                    return {
                                                                                                        id: solicitacaoFornecimento.id,
                                                                                                        idContrato: contrato[0].id + " - " + contrato[0].descricao,
                                                                                                        assunto: solicitacaoFornecimento.assunto,
                                                                                                        numeroProcesso: solicitacaoFornecimento.numeroProcesso,
                                                                                                        local: solicitacaoFornecimento.descricaoLocal,
                                                                                                        numeroOficio: solicitacaoFornecimento.numeroOficio,
                                                                                                        numeroOrcamento: solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null ?
                                                                                                            solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento
                                                                                                            : '',
                                                                                                        regiao:
                                                                                                            solicitacaoFornecimento.regiao != null ?
                                                                                                                solicitacaoFornecimento.regiao.descricao
                                                                                                                : '',
                                                                                                        tipo:
                                                                                                            solicitacaoFornecimento.tipoSolicitacao != null ?
                                                                                                                solicitacaoFornecimento.tipoSolicitacao.descricao
                                                                                                                : '',
                                                                                                        fornecedor:
                                                                                                            solicitacaoFornecimento.fornecedor != null ?
                                                                                                                solicitacaoFornecimento.fornecedor.nomeFornecedor
                                                                                                                : '',
                                                                                                        dataSolicitacao: moment(solicitacaoFornecimento.dataSolicitacao).format('L LT'),
                                                                                                        status: solicitacaoFornecimento.statusAtual,
                                                                                                        prioridade: solicitacaoFornecimento.prioridade.descricao,
                                                                                                        corHex: solicitacaoFornecimento.prioridade.corHex,
                                                                                                        observacao: solicitacaoFornecimento.observacao,
                                                                                                        nomeEvento:
                                                                                                            solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento").length > 0 ?
                                                                                                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento")[0].valorVarchar : '',
                                                                                                        dataEvento:
                                                                                                            solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial").length > 0 ?
                                                                                                                moment(solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial")[0].valorDateTime).format('L')
                                                                                                                : '',
                                                                                                        nrRefletores:
                                                                                                            solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores").length > 0 ?
                                                                                                                solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                                                    x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores")[0].valorInteger : '',
                                                                                                        orcamentoEmAtraso: solicitacaoFornecimento.orcamentoEmAtraso ? 'Sim' : 'Não',
                                                                                                        totalValorOrcamento: solicitacaoFornecimento.valorTotalOrcamento,
                                                                                                        porteObra: solicitacaoFornecimento.porteObra,
                                                                                                        idAutorizacaoFornecimento: solicitacaoFornecimento.idAutorizacaoFornecimento,
                                                                                                        bairro:
                                                                                                            solicitacaoFornecimento.bairro != null ?
                                                                                                                solicitacaoFornecimento.bairro.descricao
                                                                                                                : '',
                                                                                                        observacaoCancelamento: solicitacaoFornecimento.descricaoCancelar,
                                                                                                        interessado: solicitacaoFornecimento.interessado.nome,
                                                                                                        dataStatusReprovado: solicitacaoFornecimento.dataStatusReprovado ?
                                                                                                        moment(solicitacaoFornecimento.dataStatusReprovado).format('L LT')
                                                                                                        : "",
                                                                                                        numeroOficioOrigem: solicitacaoFornecimento.numeroOficioSolicitacaoOrcamento,
                                                                                                    }
                                                                                                }
                                                                                            }).filter(Boolean)
                                                                                            :
                                                                                            []

                                                                                    ,
                                                                                    page: result.result.pagina - 1,
                                                                                    totalCount: result.result.total,
                                                                                })
                                                                            })
                                                                    })
                                                            }
                                                            /*
                                                    data={listaData
    
                                                              listaContrato != undefined && listaContrato.length > 0 && listaSolicitacaoFornecimento.data != undefined && listaContratoUsuario != undefined && listaSolicitacaoFornecimento.data.length > 0 ?
    
                                                                  listaSolicitacaoFornecimento.data.map(solicitacaoFornecimento => {
    
                                                                      var contrato = [];
                                                        if (solicitacaoFornecimento.contratoVersao != null && solicitacaoFornecimento.contratoVersao.idContrato != null) {
                                                            contrato = listaContrato.filter((contrato) => contrato.id === solicitacaoFornecimento.contratoVersao.idContrato)
                                                        }
    
                                                                      if (contrato.length > 0 && contrato[0].privado && (listaContratoUsuario.filter((contratoUsu) =>
                                                        contratoUsu.idUsuario === usuario.id
                                                        && contratoUsu.idContrato === contrato[0].id).length === 0)) {
                                                                          return null
                                                                          // Verifica se é tipo servico para mostrar nessa tabela
                                                                      } else if (solicitacaoFornecimento.tipoSolicitacao.servico == false) {
                                                                          return null
                                                                      } else {
                                                                          return {
                                                            id: solicitacaoFornecimento.id,
                                                        idContrato: contrato[0].id + " - " + contrato[0].descricao,
                                                        descricao: solicitacaoFornecimento.descricaoSolicitacao,
                                                        numeroProcesso: solicitacaoFornecimento.numeroProcesso,
                                                        local: solicitacaoFornecimento.descricaoLocal,
                                                        numeroOficio: solicitacaoFornecimento.numeroOficio,
                                                        regiao:
                                                        solicitacaoFornecimento.regiao != null ?
                                                        solicitacaoFornecimento.regiao.descricao
                                                        : '',
                                                        tipo:
                                                        solicitacaoFornecimento.tipoSolicitacao != null ?
                                                        solicitacaoFornecimento.tipoSolicitacao.descricao
                                                        : '',
                                                        fornecedor:
                                                        solicitacaoFornecimento.fornecedor != null ?
                                                        solicitacaoFornecimento.fornecedor.nomeFornecedor
                                                        : '',
                                                        dataSolicitacao: moment(solicitacaoFornecimento.dataSolicitacao).format('L LT'),
                                                        status: solicitacaoFornecimento.statusAtual,
                                                        prioridade: solicitacaoFornecimento.prioridade.descricao,
                                                        corHex: solicitacaoFornecimento.prioridade.corHex,
                                                        observacao: solicitacaoFornecimento.observacao,
                                                        nomeEvento:
                                                        solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                      solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                          x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento").length > 0 ?
                                                                                      solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                        x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento")[0].valorVarchar : '',
                                                        dataEvento:
                                                        solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                      solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                          x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial").length > 0 ?
                                                                                      moment(solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                        x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial")[0].valorDateTime).format('L')
                                                        : '',
                                                        nrRefletores:
                                                        solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                      solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                          x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores").length > 0 ?
                                                                                      solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                        x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores")[0].valorInteger : '',
                                                        orcamentoEmAtraso: solicitacaoFornecimento.orcamentoEmAtraso ? 'Sim' : 'Não'
                                                                          }
                                                                      }
                                                                  }).filter(Boolean)
                                                        :
                                                        []
                                                          }*/
                                                            actions={[
                                                                rowData => ({
                                                                    icon: () => <FaSearchPlus />,
                                                                    onClick: (event, rowData) => { history.push(`/alterarGeralSolicitacao/${rowData.id}`); }
                                                                }),
                                                                ,
                                                                {
                                                                    icon: 'refresh',
                                                                    tooltip: 'Refresh Data',
                                                                    isFreeAction: true,
                                                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                                                }
                                                            ]}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                sorting: true,
                                                                actionsCellStyle: {
                                                                    width: '5%',
                                                                },
                                                                paging: true,
                                                                pageSize: 10, // Início
                                                                emptyRowsWhenPaging: false,
                                                                pageSizeOptions: [10, 50, 200, 300],
                                                                filterRowStyle: {
                                                                    backgroundColor: "#FAFAFA"
                                                                },
                                                                headerStyle: {
                                                                    backgroundColor: '#454545',
                                                                    color: '#FFF',
                                                                    fontWeight: "bold",
                                                                    fontSize: "13px",
                                                                },
                                                                actionsColumnIndex: -1,
                                                                filtering: true,
                                                                rowStyle: rowData => ({
                                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                })
                                                            }}
                                                            style={{
                                                                fontSize: "14px",
                                                            }}
                                                            localization={{
                                                                header: {
                                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                                },
                                                                body: {
                                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                    editRow: {
                                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                        saveTooltip: '',
                                                                        cancelTooltip: '',
                                                                    },
                                                                    deleteTooltip: '',
                                                                },
                                                                toolbar: {
                                                                    searchTooltip: '',
                                                                    searchPlaceholder: 'Pesquisar',
                                                                    exportTitle: "",
                                                                    exportCSVName: "Exportar como CSV",
                                                                    exportPDFName: "Exportar como PDF"
                                                                },
                                                                pagination: {
                                                                    labelRowsSelect: 'linhas',
                                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                                    firstTooltip: '',
                                                                    previousTooltip: '',
                                                                    nextTooltip: '',
                                                                    lastTooltip: ''
                                                                },
                                                                grouping: {
                                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                                    groupedBy: 'Agrupado por:'
                                                                }
                                                            }}
                                                        />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Solicitações Gerais</Accordion.Header>
                                                    <Accordion.Body>
                                                        <MaterialTable
                                                            components={{
                                                                Pagination: PatchedPagination,
                                                            }}
                                                            title='Solicitações Gerais'
                                                            columns={[
                                                                {
                                                                    title: '', field: 'corHex', filtering: false, sorting: false,
                                                                    cellStyle: {
                                                                        width: '5%'
                                                                    },
                                                                    render: rowData =>
                                                                        <div
                                                                            style={{
                                                                                height: '25px',
                                                                                width: '15px',
                                                                                backgroundColor: rowData.corHex,
                                                                            }}></div>
                                                                },
                                                                {
                                                                    title: 'Número da Solicitação', field: 'id', defaultSort: 'desc',
                                                                    sorting: true, cellStyle: {
                                                                        width: '5%',
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Tipo Solicitação', field: 'tipo', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Nome Evento', field: 'nomeEvento', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Data Evento', field: 'dataEvento', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Endereço', field: 'endereco', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Bairro', field: 'bairro', cellStyle: {
                                                                        width: '9%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Número Processo', field: 'numeroProcesso', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Número Ofício', field: 'numeroOficio', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Data Criação', field: 'dataSolicitacao', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Assunto', field: 'assunto', cellStyle: {
                                                                        width: '11.5%',
                                                                    }
                                                                },
                                                            ]}
                                                            data={

                                                                listaContrato != undefined && listaContrato.length > 0 && listaSolicitacaoFornecimento != undefined && listaContratoUsuario != undefined && listaSolicitacaoFornecimento.length > 0 ?
                                                                    listaSolicitacaoFornecimento.map(solicitacaoFornecimento => {

                                                                        var contrato = [];
                                                                        if (solicitacaoFornecimento.contratoVersao != null && solicitacaoFornecimento.contratoVersao.idContrato != null) {
                                                                            contrato = listaContrato.filter((contrato) => contrato.id === solicitacaoFornecimento.contratoVersao.idContrato)
                                                                        }

                                                                        if (contrato.length > 0 && contrato[0].privado && (listaContratoUsuario.filter((contratoUsu) =>
                                                                            contratoUsu.idUsuario === usuario.id
                                                                            && contratoUsu.idContrato === contrato[0].id).length === 0)) {
                                                                            return null
                                                                        } else if (solicitacaoFornecimento.tipoSolicitacao.servico == true) {
                                                                            return null
                                                                        }
                                                                        else {
                                                                            return {
                                                                                id: solicitacaoFornecimento.id,
                                                                                idContrato: contrato[0].id + " - " + contrato[0].descricao,
                                                                                endereco: solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.inputServico ? solicitacaoFornecimento.descricaoLocal : '',
                                                                                bairro: solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.inputServico ? solicitacaoFornecimento.bairro.descricao : '',
                                                                                numeroProcesso: solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.inputServico ? solicitacaoFornecimento.numeroProcesso : '',
                                                                                numeroOficio: solicitacaoFornecimento != null ? solicitacaoFornecimento.numeroOficio : '',
                                                                                descricao:
                                                                                    solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Descrição").length > 0 ?
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Descrição")[0].valorVarchar : '',
                                                                                tipo:
                                                                                    solicitacaoFornecimento.tipoSolicitacao != null ?
                                                                                        solicitacaoFornecimento.tipoSolicitacao.descricao
                                                                                        : '',
                                                                                nomeEvento:
                                                                                    solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento").length > 0 ?
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Nome do Evento")[0].valorVarchar : '',
                                                                                dataEvento:
                                                                                    solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial").length > 0 &&
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial")[0].valorDateTime != null ?
                                                                                        moment(solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Data Inicial")[0].valorDateTime).format('L')
                                                                                        : '',
                                                                                nrRefletores:
                                                                                    solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores").length > 0 ?
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Número de Refletores")[0].valorInteger : '',
                                                                                dataSolicitacao: moment(solicitacaoFornecimento.dataSolicitacao).format('L LT'),
                                                                                assunto:
                                                                                    solicitacaoFornecimento != null && solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel != undefined &&
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Assunto").length > 0 ?
                                                                                        solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.filter(x =>
                                                                                            x.caracteristicaResposta.caracteristica.descricaoLabel == "Assunto")[0].valorVarchar : '',
                                                                                corHex: solicitacaoFornecimento.prioridade != null ?
                                                                                    solicitacaoFornecimento.prioridade.corHex : '',
                                                                            }
                                                                        }
                                                                    }).filter(Boolean)

                                                                    :

                                                                    []
                                                            }
                                                            actions={[
                                                                rowData => ({
                                                                    icon: () => <FaSearchPlus />,
                                                                    onClick: (event, rowData) => { history.push(`/alterarGeralSolicitacao/${rowData.id}`); }
                                                                }),
                                                            ]}
                                                            options={{
                                                                minBodyHeight: 500,
                                                                paging: true,
                                                                pageSize: 10, // Início
                                                                emptyRowsWhenPaging: false,
                                                                pageSizeOptions: [10, 50, 200, 300],
                                                                filterRowStyle: {
                                                                    backgroundColor: "#FAFAFA"
                                                                },
                                                                headerStyle: {
                                                                    backgroundColor: '#454545',
                                                                    color: '#FFF',
                                                                    fontWeight: "bold",
                                                                    fontSize: "13px",
                                                                },
                                                                actionsColumnIndex: -1,
                                                                filtering: true,
                                                                rowStyle: rowData => ({
                                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                })
                                                            }}
                                                            style={{
                                                                fontSize: "14px",
                                                            }}
                                                            localization={{
                                                                header: {
                                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                                },
                                                                body: {
                                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                    editRow: {
                                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                        saveTooltip: '',
                                                                        cancelTooltip: '',
                                                                    },
                                                                    deleteTooltip: '',
                                                                },
                                                                toolbar: {
                                                                    searchTooltip: '',
                                                                    searchPlaceholder: 'Pesquisar',
                                                                    exportTitle: "",
                                                                    exportCSVName: "Exportar como CSV",
                                                                    exportPDFName: "Exportar como PDF"
                                                                },
                                                                pagination: {
                                                                    labelRowsSelect: 'linhas',
                                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                                    firstTooltip: '',
                                                                    previousTooltip: '',
                                                                    nextTooltip: '',
                                                                    lastTooltip: ''
                                                                },
                                                                grouping: {
                                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                                    groupedBy: 'Agrupado por:'
                                                                }
                                                            }}
                                                        />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                        :
                                        <Loading />

                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}