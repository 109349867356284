import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, Alert } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import {
    ListarMedicaoAutorizacaoFornecimentoOcorrenciaRequest,
    AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRequest,
    InserirMedicaoAutorizacaoFornecimentoOcorrenciaRequest
} from "../../../store/modules/medicaoAutorizacaoFornecimentoOcorrencia/actions";
import { ListarMedicaoOcorrenciaRequest } from '../../../store/modules/medicaoOcorrencia/actions';
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { ImFilePicture } from "react-icons/im";
import MaterialTable from "material-table";
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt-br';
import ModalOcorrenciaMedicao from "../../../components/ModalOcorrenciaMedicao/modalOcorrenciaMedicao";

export default function Ocorrencia() {

    const { idMedicao } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const listaMedicaoOcorrencia = useSelector(state => state.medicaoOcorrencia.listaMedicaoOcorrencia);
    const listaMedicaoAutorizacaoFornecimentoOcorrencia = useSelector(state => state.medicaoAutorizacaoFornecimentoOcorrencia.listaMedicaoAutorizacaoFornecimentoOcorrencia);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);
    const [show, setShow] = useState(false);
    const [dataId, setDataId] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedRow, setSelectedRow] = useState(null);

    const options = listaMedicaoOcorrencia != undefined && listaMedicaoOcorrencia ?
        listaMedicaoOcorrencia.map(lista => ({
            value: lista.id, label: lista.descricao
        }))
        :
        [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {
        moment.locale('pt-br');

        dispatch(ListarMedicaoOcorrenciaRequest());
        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idMedicao !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
            dispatch(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRequest(idMedicao));
        }

    }, [idMedicao])

    function AlterarMedicaoAutorizacaoFornecimentoOcorrencia(medicaoAutorizacaoFornecimentoOcorrencia, observacao) {

        let selectText = '';
        let selectSelecionado = [];

        if (document.getElementById('idSelectMedicaoOcorrencia') != null) {
            selectText = document.getElementById('idSelectMedicaoOcorrencia').innerText;
            selectSelecionado = listaMedicaoOcorrencia.filter(soli => soli.descricao == selectText);
        }

        if (selectSelecionado.length == 0) {
            toast.error(`Recurso não selecionado`);
            return;
        }

        if (observacao == ' ' || observacao == ''
            || observacao == null || observacao == undefined) {
            toast.error(`Observação Inválida`);
            return;
        }
        if (observacao.length > 500) {
            toast.error(`A observação não pode conter mais que 500 caracteres`);
            return;
        }

        if (selectSelecionado.length > 0) {

            let data = {
                'Id': medicaoAutorizacaoFornecimentoOcorrencia.id,
                'IdMedicaoOcorrencia': parseInt(selectSelecionado[0].id),
                'IdMedicaoAutorizacaoFornecimento': medicaoAutorizacaoFornecimentoOcorrencia.idMedicaoAutorizacaoFornecimento,
                'Observacao': observacao,
                'DataOcorrencia': medicaoAutorizacaoFornecimentoOcorrencia.dataOcorrenciaApi,
                'IdUsuario': parseInt(usuario.id)
            };

            dispatch(AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRequest(data, idMedicao))
        }
    }

    function InserirMedicaoAutorizacaoFornecimentoOcorrencia(medicaoAutorizacaoFornecimentoOcorrencia) {

        if (medicaoAutorizacaoFornecimentoOcorrencia.observacao == ' ' || medicaoAutorizacaoFornecimentoOcorrencia.observacao == ''
            || medicaoAutorizacaoFornecimentoOcorrencia.observacao == null || medicaoAutorizacaoFornecimentoOcorrencia.observacao == undefined) {
            toast.error(`Observação Inválida`);
            return;
        }
        if (medicaoAutorizacaoFornecimentoOcorrencia.observacao.length > 500) {
            toast.error(`A observação não pode conter mais que 500 caracteres`);
            return;
        }

        let selectText = '';
        let selectSelecionado = [];

        if (document.getElementById('idSelectMedicaoOcorrencia') != null) {
            selectText = document.getElementById('idSelectMedicaoOcorrencia').innerText;
            selectSelecionado = listaMedicaoOcorrencia.filter(soli => soli.descricao == selectText);
        }

        if (selectSelecionado.length == 0) {
            toast.error(`Recurso não selecionado`);
            return;
        }

        var data = {
            'Id': parseInt(0),
            'IdMedicaoOcorrencia': parseInt(selectSelecionado[0].id),
            'IdMedicaoAutorizacaoFornecimento': idMedicao,
            'Observacao': medicaoAutorizacaoFornecimentoOcorrencia.observacao,
            'DataOcorrencia': moment((new Date())).add('hours', 2),
            'IdUsuario': parseInt(usuario.id)
        }
        dispatch(InserirMedicaoAutorizacaoFornecimentoOcorrenciaRequest(data));
    }

    function AlterarValores(data) {
        setDataId(data);
        setShow(true);
    }

    return (
        <>
            <Form>
                <div>
                    <Col md={12}>
                        {
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: 'Número da Ocorrência',
                                        field: 'id',
                                        defaultSort: 'desc',
                                        cellStyle: {
                                            width: '10%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Observação',
                                        field: 'observacao',
                                        defaultSort: 'desc',
                                        cellStyle: {
                                            width: '22.5%'
                                        },
                                        validate: rowData => {
                                            if (rowData.observacao !== undefined && rowData.observacao.length > 500) {
                                                return "A observação não pode conter mais que 500 caracteres"
                                            }
                                            else if (rowData.observacao == undefined || rowData.observacao.trim() == '') {
                                                return "Campo obrigatório"
                                            }
                                            return true
                                        },
                                    },
                                    {
                                        title: 'Medição Ocorrência',
                                        field: 'idMedicaoOcorrencia',
                                        cellStyle: {
                                            width: '22.5%'
                                        },
                                        lookup:
                                            listaMedicaoOcorrencia != undefined && listaMedicaoOcorrencia ?
                                                listaMedicaoOcorrencia.map(medicao => {
                                                    return {
                                                        id: medicao.id, descricao: medicao.descricao
                                                    }
                                                }).reduce(function (acc, cur, i) {
                                                    acc[cur.id] = cur.descricao;
                                                    return acc;
                                                }, {})
                                                :
                                                null,
                                        editComponent: (props) => (
                                            <Select id="idSelectMedicaoOcorrencia"
                                                options={options}
                                                placeholder="Selecione..."
                                                defaultValue={{
                                                    value: props.value, label: listaMedicaoOcorrencia.filter(soli => soli.id == props.value).length > 0
                                                        ? listaMedicaoOcorrencia.filter(soli => soli.id == props.value)[0].descricao
                                                        : 'Selecione...'
                                                }}
                                            />
                                        ),

                                    },
                                    {
                                        title: 'Usuario',
                                        field: 'idUsuario',
                                        editable: 'never',
                                        defaultSort: 'desc',
                                        cellStyle: {
                                            width: '22.5%'
                                        },

                                    },
                                    {
                                        title: 'Data',
                                        field: 'dataOcorrencia',
                                        defaultSort: 'desc',
                                        cellStyle: {
                                            width: '22.5%'
                                        },
                                        editable: 'never'

                                    },
                                ]}
                                data={
                                    listaMedicaoAutorizacaoFornecimentoOcorrencia != undefined ?
                                        listaMedicaoAutorizacaoFornecimentoOcorrencia.map((lista) => {
                                            return {
                                                id: lista.id,
                                                idMedicaoOcorrencia: lista.idMedicaoOcorrencia,
                                                idMedicaoAutorizacaoFornecimento: lista.idMedicaoAutorizacaoFornecimento,
                                                observacao: lista.observacao,
                                                dataOcorrencia: moment(lista.dataOcorrencia).format('L LT'),
                                                idUsuario: lista.usuario.nome,
                                                dataOcorrenciaApi: lista.dataOcorrencia
                                            }
                                        })
                                        :
                                        []
                                }
                                editable={{
                                    onRowUpdate:
                                        idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                            listaMedicaoAutorizacaoFornecimentoStatus
                                            [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                            ?
                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                alterar == true ?
                                                    setTimeout(() => {
                                                        AlterarMedicaoAutorizacaoFornecimentoOcorrencia(newRow, newRow.observacao);
                                                        resolve()
                                                    }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)
                                            })
                                            :
                                            null,
                                    onRowAdd:
                                        idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                            listaMedicaoAutorizacaoFornecimentoStatus
                                            [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                            ?
                                            (newRow) => new Promise((resolve, reject) => {
                                                inserir == true ?
                                                    setTimeout(() => {
                                                        InserirMedicaoAutorizacaoFornecimentoOcorrencia(newRow);
                                                        resolve()
                                                    }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)
                                            })
                                            :
                                            null,
                                }}
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    addRowPosition: "first",
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => <ImFilePicture />,
                                        tooltip: 'Fotos',
                                        onClick: (event, rowData) => { AlterarValores(rowData.id) }
                                    })
                                ]}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                            saveTooltip: '',
                                            cancelTooltip: '',
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: '',
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                        }
                    </Col>
                    <Col md={12}>
                        {
                            (idMedicao != undefined) && (listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined) &&
                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                        A Medição está Cancelada!
                                    </Alert>
                                </Col>
                                :
                                (idMedicao != undefined) && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            A Medição está Finalizada!
                                        </Alert>
                                    </Col>
                                    :
                                    ''
                        }
                    </Col>
                </div>
            </Form >
            <ModalOcorrenciaMedicao
                showModal={show}
                onHide={handleClose}
                idMedicao={idMedicao}
                data={dataId}
            />
        </>
    )
}