import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarMunicipioResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';

function* ListarMunicipioRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/Municipio`, { headers: headerParams.token });

    yield put(ListarMunicipioResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    yield put(ListarMunicipioResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

export default all([
  takeLatest('LISTAR_MUNICIPIO_REQUEST', ListarMunicipioRequest)
])