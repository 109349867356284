import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarCaracteristicaRespostaResponse, BuscarCaracteristicaRespostaPorNomeResponse } from './actions';
import { BuscarTipoSolicitacaoResponse } from '../tipoSolicitacao/actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarCaracteristicaRespostaRequest() {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.get, `/api/CaracteristicaResposta`, { headers: headerParams.token });

        yield put(ListarCaracteristicaRespostaResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ListarCaracteristicaRespostaResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* InserirCaracteristicaRespostaRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.post, `/api/CaracteristicaResposta`, model.data, { headers: headerParams.token });

        toast.success(`Cadastrado realizado com sucesso!`);

        const result = yield call(api.get, `/api/CaracteristicaResposta`, { headers: headerParams.token });

        yield put(ListarCaracteristicaRespostaResponse(result.data.result));

        var data = {
            'Id': parseInt(model.idTipoSolicitacao),
            'IdSolicitacaoFornecimento': parseInt(model.idSolicitacaoFornecimento)
        }

        const resultTipo = yield call(api.post, `/api/TipoSolicitacao/BuscarCompleto`, data, { headers: headerParams.token });

        yield put(BuscarTipoSolicitacaoResponse(resultTipo.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* AlterarCaracteristicaRespostaRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.put, `/api/CaracteristicaResposta`, model.data, { headers: headerParams.token });

        toast.success(`CaracteristicaResposta foi atualizado com sucesso!`);

        // Listar CaracteristicaResposta
        const result = yield call(api.get, `/api/CaracteristicaResposta`, { headers: headerParams.token });

        yield put(ListarCaracteristicaRespostaResponse(result.data.result));
        yield put(Carregando(true));


    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ExcluirCaracteristicaRespostaRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.delete, `/api/CaracteristicaResposta/${model.id}`, { headers: headerParams.token });

        toast.success(`Tipo solicitacao id: ${model.id} foi excluida!`);

        const result = yield call(api.get, `/api/CaracteristicaResposta`, { headers: headerParams.token });

        yield put(ListarCaracteristicaRespostaResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* BuscarCaracteristicaRespostaPorNomeRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        const result = yield call(api.get, `/api/CaracteristicaResposta/BuscarPorNome/${model.descricao}`,
            { headers: headerParams.token });

        yield put(BuscarCaracteristicaRespostaPorNomeResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(BuscarCaracteristicaRespostaPorNomeResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

export default all([
    takeLatest('LISTAR_CARACTERISTICA_RESPOSTA_REQUEST', ListarCaracteristicaRespostaRequest),
    takeLatest('INSERIR_CARACTERISTICA_RESPOSTA_REQUEST', InserirCaracteristicaRespostaRequest),
    takeLatest('ALTERAR_CARACTERISTICA_RESPOSTA_REQUEST', AlterarCaracteristicaRespostaRequest),
    takeLatest('EXCLUIR_CARACTERISTICA_RESPOSTA_REQUEST', ExcluirCaracteristicaRespostaRequest),
    takeLatest('BUSCAR_CARACTERISTICA_RESPOSTA_POR_NOME_REQUEST', BuscarCaracteristicaRespostaPorNomeRequest)
])