import {
    AlterarMultaRequest, ExcluirMultaRequest,
    InserirMultaRequest, ListarMultaRequest
} from "../../../store/modules/multa/actions";
import { useEffect, useState } from "react";
import { Col, Form, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarAutorizacaoFornecimentoDoEmpenhoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt-br';
import IntlCurrencyInput from "react-intl-currency-input";
import { FaPlus } from "react-icons/fa";

export default function Multa() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const listarMulta = useSelector(state => state.multa.listaMulta);
    const carregando = useSelector(state => state.empenhoStatus.carregando);
    const listaAutorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.listaAutorizacaoFornecimento);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);
    const listaEmpenhoStatus = useSelector(state => state.empenhoStatus.listaEmpenhoStatus);

    const [valorTotalMulta, setValorTotalMulta] = useState(0);

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    const [selectedRow, setSelectedRow] = useState(null);

    const options = listaAutorizacaoFornecimento != undefined ?
        listaAutorizacaoFornecimento.map(x => ({
            value: x.id, label: x.numeroAf
        }))
        :
        [];

    useEffect(() => {

        moment.locale('pt-br');

        if (id != undefined) {
            dispatch(ListarMultaRequest(id));
            dispatch(ListarAutorizacaoFornecimentoDoEmpenhoRequest(id));
        }

    }, [id])

    useEffect(() => {

        if (listarMulta != undefined) {
            TotalMulta();
        }

    }, [listarMulta])

    function TotalMulta() {

        var total = 0.0;

        if (listarMulta != undefined) {
            listarMulta.map(multa => {
                total += multa.valorMulta;
            })
        }

        setValorTotalMulta(total);
    }

    function InserirMulta(multa) {

        if (multa.valor == '') {
            toast.error(`Valor não digitado`);
            return false;
        }

        if (document.getElementById('idSelectAF') != null && document.getElementById('idSelectAF') != undefined) {

            var selectTextAf = document.getElementById('idSelectAF').innerText;

            var idAf = listaAutorizacaoFornecimento.filter(a => a.numeroAf == selectTextAf);

            if (selectTextAf != "") {

                let data = {
                    'Id': 0,
                    'IdAutorizacaoFornecimento': idAf[0].id,
                    'IdEmpenho': id,
                    'ValorMulta': multa.valor,
                }

                dispatch(InserirMultaRequest(data))

            } else {
                toast.error("Autorização de Fornecimento não selecionada!");
            }

        }

    }

    function AlterarMulta(multa) {


        if (multa.valor == '') {
            toast.error(`Valor não digitado`);
            return false;
        }

        if (document.getElementById('idSelectAF') != null && document.getElementById('idSelectAF') != undefined) {

            var selectTextAf = document.getElementById('idSelectAF').innerText;
            if (selectTextAf != "") {

                let data = {
                    'Id': multa.idMulta,
                    'IdAutorizacaoFornecimento': parseInt(selectTextAf),
                    'IdEmpenho': id,
                    'ValorMulta': multa.valor,
                }

                dispatch(AlterarMultaRequest(data))

            } else {
                toast.error("Autorização de Fornecimento não selecionada!");
            }

        }
    }

    function ExcluirMulta(idMulta) {
        dispatch(ExcluirMultaRequest(idMulta, id));
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando&&
                        listarMulta != null 
                        ?
                            <div>
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title=''
                                    columns={[
                                        {
                                            title: 'Número Multa', field: 'idMulta', cellStyle: {
                                                width: '10%'
                                            },
                                            editable: 'never',
                                        },
                                        {
                                            title: 'Número AF', field: 'idAutorizacaoFornecimento', cellStyle: {
                                                width: '45%',
                                            },
                                            editable: 'always',
                                            filterComponent: (props) => {
                                                return (
                                                    <Select
                                                        placeholder="Selecione..."
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={options}
                                                        onChange={(e) => {
                                                            props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                        }}
                                                    />
                                                )
                                            },
                                            lookup:
                                                listaAutorizacaoFornecimento ?
                                                    listaAutorizacaoFornecimento.map(af => {
                                                        return {
                                                            id: af.id, descricao: af.numeroAf
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectAF"
                                                    options={options}
                                                    placeholder="Selecione..."
                                                    defaultValue={{
                                                        value: props.value, label: props.value
                                                    }}
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Valor', field: 'valor', cellStyle: {
                                                width: '45%'
                                            }, align: "left", type: "numeric",
                                            render: rowData =>
                                                rowData.valor === null ?
                                                    null
                                                    :
                                                    rowData.valor.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })
                                        },
                                    ]}
                                    icons={{
                                        Add: () =>
                                            <Button variant="success">
                                                <span>
                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                </span>
                                            </Button>
                                    }}
                                    data={
                                        listarMulta != undefined ?
                                            listarMulta.map(multa => {
                                                return {
                                                    idMulta: multa.id,
                                                    idAutorizacaoFornecimento: multa.idAutorizacaoFornecimento,
                                                    valor: multa.valorMulta,
                                                }
                                            })
                                            :
                                            []
                                    }
                                    editable={{
                                        onRowAdd:
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length == 0
                                                ? (newRow) => new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        InserirMulta(newRow);
                                                        resolve()
                                                    }, 1000)
                                                }) : null,


                                        onRowUpdate:
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length == 0
                                                ? (newRow, oldRow) => new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        AlterarMulta(newRow);
                                                        resolve()
                                                    }, 1000)
                                                }) : null,

                                        onRowDelete:
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length == 0
                                                ?
                                                oldData => new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        ExcluirMulta(oldData.idMulta);
                                                        resolve()
                                                    }, 1000)
                                                }) : null
                                    }}
                                    options={{
                                        minBodyHeight: 500,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    localization={{
                                        header: {
                                            actions: 'Ações'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                            },
                                            deleteTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                                <Col md={12}>
                                    <br />
                                    <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                                        <Form.Label>Total Multa Empenho</Form.Label>
                                        <Form.Control
                                            config={currencyConfig}
                                            as={IntlCurrencyInput}
                                            currency="BRL"
                                            type="text"
                                            value={valorTotalMulta}
                                            disabled
                                        />
                                    </Form.Group>
                                    <br />
                                </Col>
                                <Col md={12}>
                                    {
                                        id == undefined ?
                                            ''
                                            :
                                            listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                listaStatusEmpenho.filter(x => x.cancelaEmpenho == true).length > 0 &&
                                                listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                    listaStatusEmpenho.filter(x => x.cancelaEmpenho == true)[0].id).length > 0
                                                ?
                                                <Col md={12}>
                                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                                        O Empenho está Cancelado!
                                                    </Alert>
                                                </Col>
                                                :
                                                listaEmpenhoStatus != undefined && listaStatusEmpenho != undefined &&
                                                    listaStatusEmpenho.filter(x => x.finalizaEmpenho == true).length > 0 &&
                                                    listaEmpenhoStatus.filter(x => x.idStatusEmpenho ==
                                                        listaStatusEmpenho.filter(x => x.finalizaEmpenho == true)[0].id).length > 0
                                                    ?
                                                    <Col md={12}>
                                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                                            O Empenho está Finalizado!
                                                        </Alert>
                                                    </Col>
                                                    :
                                                    ''
                                    }
                                </Col>
                            </div>
                            :
                            <Loading />
                    }
                </Col>
            </div>
        </>
    )
}