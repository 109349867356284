import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
    Carregando, ListarMedicaoAutorizacaoFornecimentoStatusResponse,
    ValidarMedicaoAutorizacaoFornecimentoStatusResponse, ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse
} from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarMedicaoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var data = {
            'IdMedicaoAutorizacaoFornecimento': model.IdMedicaoAutorizacaoFornecimento
        }

        const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if(e.response == undefined || e.response.data.statusCode == 500){
                history.push(`/erro`);
            } else if (e.response.data.statusCode != 404) {
                toast.error(e.response.data.message);  
            } 

        yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ListarCompletoMedicaoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var data = {
            'IdMedicaoAutorizacaoFornecimento': model.IdMedicaoAutorizacaoFornecimento
        }

        const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if(e.response == undefined || e.response.data.statusCode == 500){
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);  
        }

        yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* InserirMedicaoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        if (model.validacao) {

            //Inserir Status
            yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus`, model.data, { headers: headerParams.token });

            toast.success(`Status finalizado!`);

            var data = {
                'IdMedicaoAutorizacaoFornecimento': parseInt(model.IdMedicaoAutorizacaoFornecimento)
            }

            const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

            yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));

            yield put(ValidarMedicaoAutorizacaoFornecimentoStatusResponse(null));

            //Listar status completo para o histórico de status
            const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

            yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        } else {
            // Validar Saldo
            const resultValidar = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/validar`, model.data, { headers: headerParams.token });

            var valida = false;
            var lista = resultValidar.data.result;
            lista.forEach(element => {
                if (element.saldoContratoAlemExcedente === true)
                    valida = true;
                if (element.saldoAutorizacaoExcedente === true)
                    valida = true;
            });

            if (valida) {

                yield put(ValidarMedicaoAutorizacaoFornecimentoStatusResponse(resultValidar.data.result));

            } else {
                //Inserir Status
                yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus`, model.data, { headers: headerParams.token });

                toast.success(`Status finalizado!`);

                var data = {
                    'IdMedicaoAutorizacaoFornecimento': parseInt(model.IdMedicaoAutorizacaoFornecimento)
                }

                const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

                yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));

                yield put(ValidarMedicaoAutorizacaoFornecimentoStatusResponse(null));

                //Listar status completo para o histórico de status
                const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

                yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));
            }
        }

        yield put(Carregando(true));

    } catch (e) {

        if(e.response == undefined || e.response.data.statusCode == 500){
            history.push(`/erro`);
          } 
          else 
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
              let erros = '';
              let apiErrors = e.response.data.errors;
              for (var modelProperty in apiErrors) {
                  apiErrors[modelProperty].forEach(function (e) {
                      erros = erros + e + ' ';
                  });
              }
              toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* AlterarMedicaoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.put, `/api/MedicaoAutorizacaoFornecimentoStatus`, model.data, { headers: headerParams.token });

        toast.success(`Status foi Alterado!`);

        var data = {
            'IdMedicaoAutorizacaoFornecimento': model.IdMedicaoAutorizacaoFornecimento
        }

        const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));

        //Listar status completo para o histórico de status
        const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if(e.response == undefined || e.response.data.statusCode == 500){
            history.push(`/erro`);
          } 
          else 
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
              let erros = '';
              let apiErrors = e.response.data.errors;
              for (var modelProperty in apiErrors) {
                  apiErrors[modelProperty].forEach(function (e) {
                      erros = erros + e + ' ';
                  });
              }
              toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ExcluirMedicaoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var data = {
            'IdMedicaoAutorizacaoFornecimento': model.IdMedicaoAutorizacaoFornecimento
        }

        yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ExcluirCompleto`, data, { headers: headerParams.token });
        yield put(Carregando(true));

        toast.success(`Status excluido!`);

        const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));

        //Listar status completo para o histórico de status
        const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if(e.response == undefined || e.response.data.statusCode == 500){
                history.push(`/erro`);
            } else if (e.response.data.statusCode != 404) {
                toast.error(e.response.data.message);  
            } 

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }

}

function* ReabrirMedicaoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`,
            { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

        if (result.data.result.length > 0 && result.data.result[result.data.result.length - 1].statusAutorizacaoFornecimento.finalizaAutorizacao == false) {

            yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ReabrirAutorizacaoFornecimentoStatus`,
                model.data, { headers: headerParams.token });

            // listar tudo dnvo
            var data = {
                'IdMedicaoAutorizacaoFornecimento': parseInt(model.data.IdMedicaoAutorizacaoFornecimento)
            }

            const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

            yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(result.data.result));

            //Listar status completo para o histórico de status
            var resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

            yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        } else {
            toast.success(`Não é possível reabrir medição sendo que a AF está finalizada!`);
        }

        yield put(Carregando(true));

    } catch (e) {

        if(e.response == undefined || e.response.data.statusCode == 500){
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);  
        }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

export default all([
    takeLatest('LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ListarMedicaoAutorizacaoFornecimentoStatusRequest),
    takeLatest('LISTAR_COMPLETO_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ListarCompletoMedicaoAutorizacaoFornecimentoStatusRequest),
    takeLatest('INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', InserirMedicaoAutorizacaoFornecimentoStatusRequest),
    takeLatest('ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', AlterarMedicaoAutorizacaoFornecimentoStatusRequest),
    takeLatest('EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ExcluirMedicaoAutorizacaoFornecimentoStatusRequest),
    takeLatest('REABRIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ReabrirMedicaoAutorizacaoFornecimentoStatusRequest),
])