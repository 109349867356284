import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarCategoriaItemResponse } from './actions';
import { ListarCategoriaItemSubitemCustoPrimarioResponse } from '../categoriaItemSubitemCustoPrimario/actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarCategoriaItemRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/CategoriaItem`, { headers: headerParams.token });

    yield put(ListarCategoriaItemResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarCategoriaItemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirCategoriaItemRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/CategoriaItem`, model.data, { headers: headerParams.token });

    toast.success(`A Solicitacao foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/CategoriaItem`, { headers: headerParams.token });

    yield put(ListarCategoriaItemResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarCategoriaItemRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    yield call(api.put, `/api/CategoriaItem`, model.data, { headers: headerParams.token });

    toast.success(`Categoria item foi atualizado com sucesso!`);

    // Listar Catgoria Item
    const result = yield call(api.get, `/api/CategoriaItem`, { headers: headerParams.token });

    yield put(ListarCategoriaItemResponse(result.data.result));
    
    yield put(Carregando(true));    
    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirCategoriaItemRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    yield call(api.delete, `/api/CategoriaItem/${model.id}`, { headers: headerParams.token });

    toast.success(`Categoria item id: ${model.id} foi excluida!`);

    const result = yield call(api.get, `/api/CategoriaItem`, { headers: headerParams.token });

    yield put(ListarCategoriaItemResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_CATEGORIA_ITEM_REQUEST', ListarCategoriaItemRequest),
  takeLatest('INSERIR_CATEGORIA_ITEM_REQUEST', InserirCategoriaItemRequest),
  takeLatest('ALTERAR_CATEGORIA_ITEM_REQUEST', AlterarCategoriaItemRequest),
  takeLatest('EXCLUIR_CATEGORIA_ITEM_REQUEST', ExcluirCategoriaItemRequest)
])