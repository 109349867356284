import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Col, Button } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from "material-table";
import Loading from "../../../components/Loading/loading";
import { AlterarFornecedorContatoRequest, InserirFornecedorContatoRequest, ListarCompletoFornecedorContatoRequest } from "../../../store/modules/fornecedorContato/actions";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { FaPlus } from "react-icons/fa";

export default function Contato() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const carregando = useSelector(state => state.fornecedorConta.carregando);
    const listaFornecedorContato = useSelector(state => state.fornecedorContato.listaFornecedorContato);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {

        if (id !== undefined) {
            dispatch(ListarCompletoFornecedorContatoRequest(id));
        }

    }, [id])

    function ValidacoesInserir(contato) {

        let telefone = document.getElementById('idTelefone');
        let DDD = document.getElementById('idDDD');

        if (contato.nomeContato == '' || contato.nomeContato == ' ' || contato.nomeContato == null) {
            toast.error(`Nome Contrato inválido`);
            return false;
        }

        else if (contato.email !== null && contato.email !== undefined && !ValidaEmail(contato.email)) {
            toast.error(`Email com formato inválido`);
            return false;
        }

        else if (telefone.value !== null && telefone.value !== '(__)' && !ValidaTelefone(telefone.value)) {
            toast.error(`Telefone com formato inválido`);
            return false;
        }

        else if (DDD.value !== null && !ValidacaoDdd(DDD.value)) {
            toast.error(`DDD com formato inválido`);
            return false;
        }
        else if (ValidacaoDdd(DDD.value) && DDD.value !== '(__)' && DDD.value !== '') {
            var ddd = DDD.value;
            var numeroDDD = ddd.split('(', ddd.length);
            var numeroDDD1 = numeroDDD[1].split(')', ddd.length);
            contato.numeroDDD = numeroDDD1[0];
        }

        return true;

    }

    function AlterarContato(contato) {

        let data = {
            'Id': parseInt(contato.codigoInterno),
            'IdFornecedor': parseInt(id),
            'NomeContato': contato.nomeContato.toString(),
            'NumeroDddTelefone': parseInt(contato.numeroDDD),
            'NumeroTelefone': contato.numeroTelefone,
            'Email': contato.email.toString(),
            'Ativo': contato.ativo,
            'Gerencial': contato.gerencial,
        }

        dispatch(AlterarFornecedorContatoRequest(data))
    }

    function InserirContato(contato) {

        let data = {
            'Id': parseInt(0),
            'IdFornecedor': parseInt(id),
            'NomeContato': contato.nomeContato.toString(),
            'NumeroDddTelefone': parseInt(contato.numeroDDD),
            'NumeroTelefone': contato.numeroTelefone,
            'Email': contato.email.toString(),
            'Ativo': contato.ativo == undefined ? true : contato.ativo,
            'Gerencial': contato.gerencial == undefined ? true : contato.gerencial,
        }

        dispatch(InserirFornecedorContatoRequest(data));
    }


    function ValidaEmail(emailParametro) {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (emailRegex.test(String(emailParametro))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidacaoDdd(ddd) {
        if (ddd === '(__)' || ddd === '') {
            return false
        }
        const numeroDDD = ddd.split('(', ddd.length);
        const numeroDDD1 = numeroDDD[1].split(')', ddd.length);
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(numeroDDD1[0]))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone(telefone) {
        const telefoneRegex = /^\d{1}\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Form>
                <div>
                    <Col md={12}>
                        {
                            carregando ?
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={''}
                                    columns={[
                                        {
                                            title: 'Código', field: 'codigoInterno', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                width: '5%',
                                            }
                                        },
                                        {
                                            title: 'Nome Contato',
                                            field: 'nomeContato',
                                            defaultSort: 'desc',
                                            cellStyle: {
                                                width: '20%',
                                            },
                                            validate: rowData => {
                                                if (rowData.nomeContato === undefined || rowData.nomeContato.trim() === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.nomeContato.length > 70) {
                                                    return "O campo Nome Contato não pode conter mais que 50 caracteres"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Número DDD',
                                            type: 'numeric',
                                            align: 'left',
                                            field: 'numeroDDD',
                                            cellStyle: {
                                                width: '10%'
                                            },
                                            validate: rowData => {
                                                if (rowData.numeroDDD === undefined || rowData.numeroDDD === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                return true
                                            },
                                            editComponent: (props) => (
                                                <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                    mask={"(99)"} id="idDDD" />
                                            )
                                        },
                                        {
                                            title: 'Número Telefone',
                                            field: 'numeroTelefone',
                                            align: 'left',
                                            cellStyle: {
                                                width: '10%'
                                            },
                                            validate: rowData => {
                                                if (rowData.numeroTelefone === undefined || rowData.numeroTelefone === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.numeroTelefone.length > 10) {
                                                    return "O campo Número Telefone  não pode conter mais que 10 caracteres"
                                                }
                                                return true
                                            },
                                            editComponent: (props) => (
                                                <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                    mask={"99999 9999"} id="idTelefone" />
                                            )
                                        },
                                        {
                                            title: 'E-mail',
                                            field: 'email',
                                            defaultSort: 'desc',
                                            cellStyle: {
                                                width: '50%',
                                            },
                                            validate: rowData => {
                                                if (rowData.email === undefined || rowData.email === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.email.length > 150) {
                                                    return "O campo E-mail  não pode conter mais que 150 caracteres"
                                                } else if (!ValidaEmail(rowData.email)) {
                                                    return "E-mail inválido"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Ativo',
                                            field: 'ativo',
                                            cellStyle: {
                                                width: '5%'
                                            },
                                            editComponent: (props) => (
                                                <input
                                                    type="checkbox"
                                                    checked={props.value == false ? props.value : true}
                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                />
                                            ),
                                            render: rowData => (<input type="checkbox" checked={rowData.ativo} onChange={() => { }} />)
                                        },
                                        {
                                            title: 'Gerencial',
                                            field: 'gerencial',
                                            cellStyle: {
                                                width: '5%'
                                            },
                                            editComponent: (props) => (
                                                <input
                                                    type="checkbox"
                                                    checked={props.value == false ? props.value : true}
                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                />
                                            ),
                                            render: rowData => (<input type="checkbox" checked={rowData.gerencial} onChange={() => { }} />)
                                        }
                                    ]}
                                    data={
                                        listaFornecedorContato != undefined && listaFornecedorContato.length > 0 ?
                                            listaFornecedorContato.map((contato) => {
                                                return {
                                                    codigoInterno: contato.id,
                                                    nomeContato: contato.nomeContato,
                                                    numeroDDD: contato.numeroDddTelefone,
                                                    numeroTelefone: contato.numeroTelefone,
                                                    email: contato.email,
                                                    ativo: contato.ativo,
                                                    gerencial: contato.gerencial
                                                }
                                            })
                                            :
                                            []
                                    }
                                    icons={{
                                        Add: () =>
                                            <Button variant="success">
                                                <span>
                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                </span>
                                            </Button>
                                    }}
                                    editable={{
                                        onRowAdd:
                                            (newRow) => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    if (!ValidacoesInserir(newRow)) {
                                                        reject();
                                                    }
                                                    else {
                                                        InserirContato(newRow);
                                                        resolve()
                                                    }
                                                }, 1000)
                                            }),
                                        onRowUpdate:
                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    if (!ValidacoesInserir(newRow)) {
                                                        reject();
                                                    }
                                                    else {
                                                        AlterarContato(newRow);
                                                        resolve()
                                                    }
                                                }, 1000)
                                            })
                                    }}
                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        minBodyHeight: 500,
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        exportButton: false,
                                        exportAllData: true,
                                        addRowPosition: "first",
                                        filtering: true,
                                        //grouping: true,
                                        exportFileName: 'tabelaExportada',
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    actions={[
                                    ]}
                                    localization={{
                                        header: {
                                            actions: <span className="classHeaderTabela">Ações</span>
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                saveTooltip: '',
                                                cancelTooltip: '',
                                            },
                                            deleteTooltip: '',
                                            editTooltip: '',
                                            addTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                                :
                                <Loading />
                        }
                    </Col>
                </div>
            </Form >
        </>
    )
}

