import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarUsuarioFornecedorResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarUsuarioFornecedorRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/UsuarioFornecedor`, { headers: headerParams.token });

    yield put(ListarUsuarioFornecedorResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarUsuarioFornecedorResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirUsuarioFornecedorRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/UsuarioFornecedor`, model.data, { headers: headerParams.token });

    toast.success(`UsuÁrio Fornecedor de Medida foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/UsuarioFornecedor`, { headers: headerParams.token });

    yield put(ListarUsuarioFornecedorResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarUsuarioFornecedorRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/UsuarioFornecedor`, model.data, { headers: headerParams.token });

    toast.success(`Usuário Fornecedor de Medida foi atualizada com sucesso!`);

    const result = yield call(api.get, `/api/UsuarioFornecedor`, { headers: headerParams.token });

    yield put(ListarUsuarioFornecedorResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirUsuarioFornecedorRequest(model) {

  try {
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/UsuarioFornecedor/${model.id}`, { headers: headerParams.token });

    toast.success(`Usuário Fornecedor de Medida id: ${model.id} foi excluida!`);

    const result = yield call(api.get, `/api/UsuarioFornecedor`, { headers: headerParams.token });

    yield put(ListarUsuarioFornecedorResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarCompletoUsuarioFornecedorRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/UsuarioFornecedor/listarCompleto`,
      { IdUsuario: parseInt(model.idUsuario) }, { headers: headerParams.token });
    
    yield put(ListarUsuarioFornecedorResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
       
    yield put(ListarUsuarioFornecedorResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));   
  
  }
}


export default all([
  takeLatest('LISTAR_USUARIO_FORNECEDOR_REQUEST', ListarUsuarioFornecedorRequest),
  takeLatest('INSERIR_USUARIO_FORNECEDOR_REQUEST', InserirUsuarioFornecedorRequest),
  takeLatest('ALTERAR_USUARIO_FORNECEDOR_REQUEST', AlterarUsuarioFornecedorRequest),
  takeLatest('EXCLUIR_USUARIO_FORNECEDOR_REQUEST', ExcluirUsuarioFornecedorRequest),
  takeLatest('LISTAR_COMPLETO_USUARIO_FORNECEDOR_REQUEST', ListarCompletoUsuarioFornecedorRequest)
])