import produce from 'immer';

const initialState = { listaMedicaoAutorizacaoFornecimentoOcorrencia: undefined,
                       medicaoAutorizacaoFornecimentoOcorrenciaCompleto: undefined };

export default function medicaoAutorizacaoFornecimentoOcorrencia(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_RESPONSE':
        return produce(state, draft => {
            draft.listaMedicaoAutorizacaoFornecimentoOcorrencia = action.listaMedicaoAutorizacaoFornecimentoOcorrencia
        });
        
    case 'BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_POR_ID_RESPONSE':
        return produce(state, draft => {
            draft.medicaoAutorizacaoFornecimentoOcorrenciaCompleto = action.medicaoAutorizacaoFornecimentoOcorrenciaCompleto;
        });
    
     case 'CARREGANDO':
        return produce(state, draft => {
            draft.carregando = action.status
        });  
    
    default:
      return state;
  }
}