import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarGrupoFuncaoAcessoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarGrupoFuncaoAcessoRequest(model){

  try{

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/GrupoFuncaoAcesso`, { headers:headerParams.token });
  
    const listaPermissoes = result.data.result.filter(i => i.idGrupo === parseInt(model.id))

    yield put(ListarGrupoFuncaoAcessoResponse(listaPermissoes)); 
    yield put(Carregando(true));    
  
  }catch(e){

    if (e.response != null && e.response.data != null) {
      if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }
  
    yield put(ListarGrupoFuncaoAcessoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* AlterarGrupoFuncaoAcessoRequest(model){

  try{
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/GrupoFuncaoAcesso`, model.data,  { headers:headerParams.token });

    const result = yield call(api.get, `/api/GrupoFuncaoAcesso`, { headers:headerParams.token });
  
    const listaPermissoes = result.data.result.filter(i => i.idGrupo === parseInt(model.data.IdGrupo))

    yield put(ListarGrupoFuncaoAcessoResponse(listaPermissoes)); 
    yield put(Carregando(true));

  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 
  
    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_GRUPO_FUNCAO_ACESSO_REQUEST', ListarGrupoFuncaoAcessoRequest),
  takeLatest('ALTERAR_GRUPO_FUNCAO_ACESSO_REQUEST', AlterarGrupoFuncaoAcessoRequest)
])