import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarCompletoMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function HistoricoStatus() {

    const { idAutorizacaoFornecimento, idMedicao } = useParams();
    const dispatch = useDispatch();

    const listaCompletoMedicaoAutorizacaoFornecimentoStatus =
        useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaCompletoMedicaoAutorizacaoFornecimentoStatus);
    const carregando = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.carregando);

    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {

        moment.locale('pt-br');

        if (idMedicao != undefined) {
            dispatch(ListarCompletoMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

    }, [idMedicao])

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: 'Status', field: 'status', cellStyle: {
                                            width: '25%'
                                        },
                                        render: rowData =>
                                            rowData.statusCancela == true ?
                                                <div className="divStatusDanger">{rowData.status}</div>
                                                :
                                                <div className="divStatusGreen">{rowData.status}</div>
                                    },
                                    {
                                        title: 'Nome usuário', field: 'usuario', cellStyle: {
                                            width: '25%'
                                        }
                                    },
                                    {
                                        title: 'Data', field: 'dataInclusao', defaultSort: 'desc', cellStyle: {
                                            width: '25%'
                                        }
                                    },
                                    {
                                        title: 'Observação', field: 'observacaoCancelado', cellStyle: {
                                            width: '25%'
                                        }
                                    }
                                ]}
                                data={
                                    listaCompletoMedicaoAutorizacaoFornecimentoStatus != undefined ?
                                        listaCompletoMedicaoAutorizacaoFornecimentoStatus.map(medicaoStatus => {
                                            return {
                                                status:
                                                    medicaoStatus.statusMedicaoAutorizacaoFornecimento != null ?
                                                        medicaoStatus.statusMedicaoAutorizacaoFornecimento.descricao : '',
                                                dataInclusao: moment(medicaoStatus.dataStatus).format('L LTS'),
                                                usuario: medicaoStatus.usuario != null ? medicaoStatus.usuario.nome : '',
                                                observacaoCancelado: medicaoStatus.observacaoCancelado,
                                                statusCancela: medicaoStatus.statusMedicaoAutorizacaoFornecimento.cancelaMedicao
                                            }
                                        })
                                        :
                                        []
                                }
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                                }}
                                localization={{
                                    header: {
                                        actions: 'Ações'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                        },
                                        deleteTooltip: ''
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                            :
                            <Loading />
                    }
                </Col>
            </div>
        </>
    )
}