import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Alert, Row, Col, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ListarTipoDocumentoArquivoRequest } from "../../../store/modules/tipoDocumentoArquivo/actions";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import {
    AlterarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest,
    BaixarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest, ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest, InserirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest, ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest
} from "../../../store/modules/autorizacaoFornecimentoSubitemCustoMedicaoDocArq/actions";
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { toast } from "react-toastify";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function DocumentoArquivo() {

    const { idMedicao, idMedicaoSubItem } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const documentos = useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicaoDocArq.listaAutorizacaoFornecimentoSubitemCustoMedicaoDocArq);
    const listaTipoDocumentoArquivo = useSelector(state => state.tipoDocumentoArquivo.listaTipoDocumentoArquivo);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);

    const [listaFile, setListaFile] = useState([]);
    const [observacao, setObservacao] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [tipoDocumentoArquivo, setTipoDocumentoArquivo] = useState();
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setTipoDocumentoArquivo();
        setObservacao('');
        setValidated(false);
    }
    const handleShow = () => setShow(true);

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idMedicao !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

        if (idMedicaoSubItem != undefined) {
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(idMedicaoSubItem));
            dispatch(ListarTipoDocumentoArquivoRequest());
        }

    }, [idMedicaoSubItem])

    const handleSubmit = (event) => {

        if (tipoDocumentoArquivo == undefined || tipoDocumentoArquivo == '') {
            event.preventDefault();
            setValidated(true);
        } else {
            InserirDocumentoArquvio(event);
            setValidated(false);
        }
    };

    function InserirDocumentoArquvio() {

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdSubItem', idMedicaoSubItem);
                formData.append('IdUsuario', usuario.id);
                formData.append('IdTipoDocumentoArquivo', parseInt(tipoDocumentoArquivo));
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setListaFile([]);
            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(formData, idMedicaoSubItem));
            handleClose();
        } else {
            toast.error('Para continuar insira um documento');
        }

    }

    function AlterarDocumentoArquvio(documentoArquivo, novoValorCampoEditado, colunaEditada) {

        // Valida alteração do campo nome arquivo
        if (colunaEditada.field == "nomeArquivo") {

            if (novoValorCampoEditado == null || novoValorCampoEditado == undefined
                || novoValorCampoEditado == ' ' || novoValorCampoEditado == '') {

                toast.error(`Nome do arquivo inválido`);
                return;
            }

            if (novoValorCampoEditado.length > 150) {
                toast.error(`O nome do arquivo não pode conter mais que 150 caracteres`);
                return;
            }
        }

        // Valida alteração do campo observação
        if (colunaEditada.field == "observacao") {
            if (novoValorCampoEditado.length > 500) {
                toast.error(`A observação não pode conter mais que 500 caracteres`);
                return;
            }
        }

        if (colunaEditada != null) {

            let data = {
                'Id': documentoArquivo.id,
                'IdAutorizacaoFornecimentoSubitemCustoMedicao': idMedicaoSubItem,
                'NomeArquivo': colunaEditada.field == "observacao" ? documentoArquivo.nomeArquivo : novoValorCampoEditado,
                'DescricaoExtensaoArquivo': documentoArquivo.descricaoExtensaoArquivo,
                'DataInclusao': documentoArquivo.dataInclusaoApi,
                'IdTipoDocumentoArquivo': documentoArquivo.idTipoDocumentoArquivo,
                'DocumentoArquivoGuid': documentoArquivo.documentoArquivoGuid,
                'IdUsuario': documentoArquivo.idUsuario,
                'Observacao': colunaEditada.field == "observacao" ? novoValorCampoEditado : documentoArquivo.observacao
            };

            dispatch(AlterarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(data, idMedicaoSubItem));
        }
    }

    function ExcluirDocumentoArquvio(idDocumentoArquivo) {
        dispatch(ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(idDocumentoArquivo, idMedicaoSubItem));
    }

    function BaixarDocumentoArquvio(idDocumentoArquivo) {
        dispatch(BaixarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(idDocumentoArquivo));
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                    listaMedicaoAutorizacaoFornecimentoStatus
                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                    ?
                                    <Button variant="success" onClick={handleShow} style={{ width: '100%' }}>Adicionar Documentos</Button>
                                    :
                                    null
                            }
                            columns={[
                                {
                                    title: "Número do Documento", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                        width: '10%',
                                    }
                                },
                                {
                                    title: "Nome Arquivo", field: 'nomeArquivo', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Tipo Documento Arquivo", field: 'tipoDocumentoArquivo', editable: 'never', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Data Inclusão", field: 'dataInclusao', editable: 'never', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Observação", field: 'observacao', cellStyle: {
                                        width: '22.5%'
                                    },
                                    render: rowData => rowData.observacao == '' ?
                                        <div className="spanVazio"></div>
                                        : rowData.observacao
                                }
                            ]}
                            data={
                                documentos != undefined && listaTipoDocumentoArquivo != undefined ?
                                    documentos.map(doc => {
                                        return {
                                            id: doc.id,
                                            nomeArquivo: doc.nomeArquivo,
                                            tipoDocumentoArquivo:
                                                doc.tipoDocumentoArquivo != null ?
                                                    doc.tipoDocumentoArquivo.descricao
                                                    : '',
                                            dataInclusao: moment(doc.dataInclusao).format('L LT'),
                                            observacao: doc.observacao,

                                            // Info para alteração de documento arquivo
                                            dataInclusaoApi: doc.dataInclusao,
                                            descricaoExtensaoArquivo: doc.descricaoExtensaoArquivo,
                                            documentoArquivoGuid: doc.documentoArquivoGuid,
                                            idTipoDocumentoArquivo: doc.idTipoDocumentoArquivo,
                                            idUsuario: doc.idUsuario

                                        }
                                    })
                                    :
                                    []
                            }
                            cellEditable={{
                                onCellEditApproved:
                                    (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                AlterarDocumentoArquvio(rowData, newValue, columnDef);
                                                resolve()
                                            }, 1000)
                                        });
                                    }
                            }}
                            editable={{
                                onRowDelete:
                                    idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaMedicaoAutorizacaoFornecimentoStatus
                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1] != null && listaMedicaoAutorizacaoFornecimentoStatus
                                    [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento != null &&
                                        listaMedicaoAutorizacaoFornecimentoStatus
                                        [listaMedicaoAutorizacaoFornecimentoStatus.length - 1].statusMedicaoAutorizacaoFornecimento.somenteLeitura == false
                                        ?
                                        oldData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    ExcluirDocumentoArquvio(oldData.id);
                                                    resolve()
                                                }, 1000)
                                            })
                                        :
                                        null
                            }}
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                actionsColumnIndex: -1,
                                filtering: true,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                {
                                    icon: 'download',
                                    onClick: (event, rowData) => BaixarDocumentoArquvio(rowData.id)
                                },
                            ]}
                            localization={{
                                header: {
                                    actions: 'Ações'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: '',
                                    searchPlaceholder: 'Pesquisar',
                                    exportTitle: "",
                                    exportCSVName: "Exportar como CSV",
                                    exportPDFName: "Exportar como PDF",
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                </Col>
                <Col md={12}>
                    {
                        idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined
                            ?
                            // Verificação se o status atual é o cancelado
                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                        A Medição está Cancelada!
                                    </Alert>
                                </Col>
                                :
                                // Verificação se o status atual é o finalizado
                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            A Medição está Finalizada!
                                        </Alert>
                                    </Col>
                                    :
                                    ''
                            :
                            ''
                    }
                </Col>
            </div>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Documentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formTipoDocumentoArquivo">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[documentos]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDocumentos">
                                <Form.Label>Tipo documento arquivo</Form.Label>
                                <Form.Select
                                    onChange={(e) => setTipoDocumentoArquivo(e.target.value)}
                                    value={tipoDocumentoArquivo}
                                    required
                                    isInvalid={validated && tipoDocumentoArquivo == undefined
                                        || validated && tipoDocumentoArquivo == '' ? true : false}
                                    isValid={validated && tipoDocumentoArquivo !== undefined
                                        || validated && tipoDocumentoArquivo !== '' ? true : false}>
                                    <option value="">Selecione...</option>
                                    {
                                        listaTipoDocumentoArquivo != undefined ?
                                            listaTipoDocumentoArquivo.map(m => (
                                                <option value={m.id} key={m.id}>{m.descricao}</option>
                                            ))
                                            :
                                            null
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmit}>Salvar Documentos</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}