import produce from 'immer';

const initialState = { listaStatusSolicitacaoFornecimento: undefined,
  listaStatusSolicitacaoFornecimentoCompleto: undefined,
  carregando: true };

export default function tipoSolicitacao(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaStatusSolicitacaoFornecimento = action.listaStatusSolicitacaoFornecimento;
      });

      case 'LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_COMPLETO_RESPONSE':
      return produce(state, draft => {
        draft.listaStatusSolicitacaoFornecimentoCompleto = action.listaStatusSolicitacaoFornecimentoCompleto;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}