import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarContratoVersaoSubitemCustoPrimarioResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarContratoVersaoSubitemCustoPrimarioRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/ContratoVersaoSubitemCustoPrimario`, { headers: headerParams.token });

    yield put(ListarContratoVersaoSubitemCustoPrimarioResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarContratoVersaoSubitemCustoPrimarioResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* InserirContratoVersaoSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/ContratoVersaoSubitemCustoPrimario`, model.data, { headers: headerParams.token });
    
    yield put(Carregando(true));    

    toast.success(`O relação foi incluida com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));        
  }
}

function* AlterarContratoVersaoSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/ContratoVersaoSubitemCustoPrimario`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ContratoVersaoSubitemCustoPrimario`, { headers: headerParams.token });

    yield put(ListarContratoVersaoSubitemCustoPrimarioResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Permissão alterada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }

}

function* ExcluirContratoVersaoSubitemCustoPrimarioRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/ContratoVersaoSubitemCustoPrimario/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ContratoVersaoSubitemCustoPrimario`, { headers: headerParams.token });

    yield put(ListarContratoVersaoSubitemCustoPrimarioResponse(result.data.result));
    yield put(Carregando(true));    
    
    toast.success(`O Usuario foi excluido com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }

}

export default all([
  takeLatest('LISTAR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST', ListarContratoVersaoSubitemCustoPrimarioRequest),
  takeLatest('INSERIR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST', InserirContratoVersaoSubitemCustoPrimarioRequest),
  takeLatest('ALTERAR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST', AlterarContratoVersaoSubitemCustoPrimarioRequest),
  takeLatest('EXCLUIR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_REQUEST', ExcluirContratoVersaoSubitemCustoPrimarioRequest)
])