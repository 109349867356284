import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IoDocumentText } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { Col, Card, Button, Form, Dropdown, Row } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { FaSearchPlus } from "react-icons/fa";
import {
    AlterarMedicaoAutorizacaoFornecimentoRequest,
    InserirMedicaoAutorizacaoFornecimentoRequest
} from "../../../store/modules/medicaoAutorizacaoFornecimento/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { ListarUsuarioRequest } from "../../../store/modules/usuario/actions";
import { ListarAutorizacaoFornecimentoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import { toast } from "react-toastify";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import MaterialTable, { MTableFilterRow } from "material-table";
import Header from "../../../components/Header/header";
import moment from 'moment';
import Select from 'react-select';
import 'moment/locale/pt-br';
import Cookies from 'universal-cookie';
import urlBase from '../../../services/rotas';
import { AtualizarToken } from "../../../store/modules/global/actions";
import { FaPlus } from "react-icons/fa";
import ptBR from "date-fns/locale/pt-BR";
import { RiFilter3Line } from "react-icons/ri";

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();
    const tableRef = React.createRef();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const cookies = new Cookies();
    const [query, setQuery] = useState("");

    const carregando = useSelector(state => state.medicaoAutorizacaoFornecimento.carregando);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const listaAutorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.listaAutorizacaoFornecimento);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dataMedicao, setDataMedicao] = useState('');

    // Filtros

    let [filtros] = useState(JSON.parse(localStorage.getItem('filtro-medicao-autorizacao-fornecimento')));
    const [numeroAfFiltro, setNumeroAfFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.numeroAfFiltro : 0);
    const [descricaoFiltro, setDescricaoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.descricaoFiltro : '');
    const [totalMedicaoFiltro, setTotalMedicaoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.totalMedicaoFiltro : '');
    const [usuarioFiltro, setUsuarioFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.usuarioFiltro : '');
    const [observacaoFiltro, setObservacaoFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.observacaoFiltro : '');
    const [statusFiltro, setStatusFiltro] = useState(filtros != null && usuario.historicoFiltro ? filtros.statusFiltro : 0);

    const [dataInicio, setDataInicio] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);

    const options = listaAutorizacaoFornecimento != undefined ?
        listaAutorizacaoFornecimento.map(af => ({
            value: af.id, label: af.numeroAf
        }))
        :
        [];

    const optionsStatus = [
        { value: '1', label: "Inicial" },
        { value: '3', label: "Medição Finalizada" },
        { value: '4', label: "Cancelada" },
        { value: '5-1', label: "Pendente de Quantidade (Cliente)" },
        { value: '5-2', label: "Pendente de Quantidade (Fornecedor)" },
        { value: '5-3', label: "Pendente de Quantidade (Ambos)" },
        { value: '5-4', label: "Pendente de Quantidade (Nenhum)" },

    ];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AutorizacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AutorizacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "AutorizacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarUsuarioRequest());
        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());
        dispatch(ListarAutorizacaoFornecimentoRequest());
        dispatch(AtualizarToken());
        TrocarData(filtros);
        
    }, [])


    function ValidacoesInserir(medicao) {

        // Valida o campo medição descrição
        if (medicao.descricao == ' ' || medicao.descricao == ''
            || medicao.descricao == null || medicao.descricao == undefined) {
            toast.error(`Descrição Inválida`);
            return false;
        }

        if (medicao.descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return false;
        }

        // Valida o campo Data Medição
        if (medicao.dataApi == undefined || medicao.dataApi == null) {
            toast.error('É preciso selecionar a data da medição');
            return false;
        }

        // Valida o campo medição observação
        if (medicao.observacao !== undefined) {
            if (medicao.observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return false;
            }
        }

        if (document.getElementById('idSelectAF') != null && document.getElementById('idSelectAF') != undefined) {

            var selectTextAf = document.getElementById('idSelectAF').innerText;
            var selectSelecionadoAf = listaAutorizacaoFornecimento.filter(af => af.numeroAf == selectTextAf);
            if (selectSelecionadoAf.length > 0) {
                if (listaContratoUsuario !== undefined && usuario !== undefined &&
                    selectSelecionadoAf != null && selectSelecionadoAf[0].solicitacaoFornecimentoVersao != null &&
                    selectSelecionadoAf[0].solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                    selectSelecionadoAf[0].solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                    selectSelecionadoAf[0].solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null
                    && listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id &&
                        x.idContrato == selectSelecionadoAf[0].solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato).length == 0) {
                    toast.error(`Você não tem permissão para iniciar uma medição relacionada ao 
                            contrato ${selectSelecionadoAf[0].solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato}`);
                    return false;
                }
            }
        }

        if (medicao.idAutorizacaoFornecimento !== undefined && medicao.idAutorizacaoFornecimento !== null &&
            listaAutorizacaoFornecimento !== null && listaAutorizacaoFornecimento !== undefined) {

            var afSelecionada = listaAutorizacaoFornecimento.filter(x => x.id == medicao.idAutorizacaoFornecimento)[0]
            if (afSelecionada !== undefined && afSelecionada !== null) {
                if (listaContratoUsuario !== undefined && usuario !== undefined &&
                    listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id &&
                        x.idContrato == afSelecionada.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato).length == 0) {
                    toast.error(`Você não tem permissão para iniciar uma medição relacionada ao 
                            contrato ${afSelecionada.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato}`);
                    return false;
                }
            }

        }

        return true;

    }

    function AlterarMedicao(medicao) {

        let data = {
            'Id': medicao.id,
            'IdAutorizacaoFornecimento': parseInt(medicao.idAutorizacaoFornecimento),
            'IdUsuario': medicao.idUsuario,
            'DataMedicao': medicao.dataApi,
            'Observacao': medicao.observacao.trim(),
            'Descricao': medicao.descricao
        }

        dispatch(AlterarMedicaoAutorizacaoFornecimentoRequest(data));

    }

    function InserirMedicao(medicao) {

        if (document.getElementById('idSelectAF') != null && document.getElementById('idSelectAF') != undefined) {

            var selectTextAf = document.getElementById('idSelectAF').innerText;
            var selectSelecionadoAf = listaAutorizacaoFornecimento.filter(af => af.numeroAf == selectTextAf);
            if (selectSelecionadoAf.length > 0) {

                let data = {
                    'Id': parseInt(0),
                    'IdAutorizacaoFornecimento': parseInt(selectSelecionadoAf[0].id),
                    'IdUsuario': parseInt(usuario.id),
                    'DataMedicao': medicao.dataApi,
                    'DataInclusao': moment((new Date())).add('hours', 2),
                    'Observacao': medicao.observacao,
                    'Descricao': medicao.descricao
                }

                dispatch(InserirMedicaoAutorizacaoFornecimentoRequest(data));

                setDataMedicao(null);
            } else {
                toast.error("Autorização de Fornecimento não selecionada!");
            }

        }
    }

    function TrocarData(filtros){

        if(filtros.numeroAfFiltro === '' && filtros.descricaoFiltro === '' && filtros.statusFiltro === '' && 
        filtros.usuarioFiltro === '' && filtros.observacaoFiltro === '' && !dataInicio && !dataFinal){
            setDataInicio(moment().startOf('month'))
            setDataFinal(moment().endOf('month'))
        }

    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <IoDocumentText size={25} color={"#000"} /> Medição Autorização Fornecimento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                        FilterRow: (props) => (
                                            <MTableFilterRow
                                                {...props}
                                                onFilterChanged={(columnId, val) => {
                                                    props.onFilterChanged(columnId, val);
                                                    // Do what you want to

                                                }}
                                            />
                                        ),
                                    }}
                                    title={''}
                                    tableRef={tableRef}
                                    columns={[
                                        {
                                            title: 'Número AF', field: 'idAutorizacaoFornecimento', cellStyle: {
                                                width: '10%',
                                            },
                                            editable: 'onAdd',
                                            filterComponent: (props) => {
                                                return (
                                                    <Select
                                                        placeholder="Selecione..."
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={options}
                                                        onChange={(e) => {
                                                            props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                        }}
                                                        defaultValue={numeroAfFiltro !== 0 && numeroAfFiltro !== null && numeroAfFiltro !== '' && options.length > 0
                                                            && options.filter(x => x.value == parseInt(numeroAfFiltro)).length > 0 ?
                                                            { value: options.filter(x => x.value == parseInt(numeroAfFiltro))[0].value, label: options.filter(x => x.value == parseInt(numeroAfFiltro))[0].label }
                                                            : ''}
                                                    />
                                                )
                                            },
                                            lookup:
                                                listaAutorizacaoFornecimento ?
                                                    listaAutorizacaoFornecimento.map(af => {
                                                        return {
                                                            id: af.id, descricao: af.numeroAf
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectAF"
                                                    options={options}
                                                    placeholder="Selecione..."
                                                    defaultValue={{
                                                        value: props.value, label: listaAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimento.filter(soli => soli.id == props.value).length > 0
                                                            ? listaAutorizacaoFornecimento.filter(soli => soli.id == props.value)[0].descricao
                                                            : 'Selecione...'
                                                    }}
                                                />
                                            ),
                                            defaultFilter: numeroAfFiltro

                                        },
                                        {
                                            title: 'Nº Medição',
                                            field: 'id',
                                            cellStyle: {
                                                width: '9%'
                                            },
                                            editable: 'never',
                                            filtering: false
                                        },
                                        {
                                            title: 'Descrição',
                                            field: 'descricao',
                                            align: 'left',
                                            editPlaceholder: 'Descrição',
                                            cellStyle: {
                                                width: '9%'
                                            }, validate: rowData => {
                                                if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.descricao.length > 200) {
                                                    return "A descrição não pode conter mais que 200 caracteres"
                                                }
                                                return true
                                            },
                                            defaultFilter: descricaoFiltro
                                        },
                                        {
                                            title: 'Total Medição',
                                            field: 'totalMedicao',
                                            type: 'numeric',
                                            align: 'left',
                                            cellStyle: {
                                                width: '10.5%'
                                            },
                                            editable: 'never',
                                            render: rowData =>
                                                rowData.totalMedicao === null ?
                                                    null
                                                    :
                                                    rowData.totalMedicao.toLocaleString('pt-br', {
                                                        style: 'currency', currency: 'BRL',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }),
                                            defaultFilter: totalMedicaoFiltro
                                        },
                                        {
                                            title: 'Data Inclusao',
                                            field: 'dataInclusao',
                                            cellStyle: {
                                                width: '13.5%'
                                            },
                                            editable: 'never',
                                            filterComponent: (props) => {
                                                return (
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <RiFilter3Line />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu
                                                            style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                            <div>
                                                                <Form as={Row} md={12}>
                                                                    <Form.Group as={Col} md={6}>
                                                                        <Form.Label>Data Inicial de Criação</Form.Label>
                                                                        <Form.Control
                                                                            style={{ minHeight: '36px' }}
                                                                            type="date"
                                                                            value={dataInicio != null ? moment(dataInicio).format("YYYY-MM-DD") : ''}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.target.value : null);
                                                                                setDataInicio(e != null ? e.target.value : null)
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md={6}>
                                                                        <Form.Label>Data Final de Criação</Form.Label>
                                                                        <Form.Control
                                                                            style={{ minHeight: '36px' }}
                                                                            type="date"
                                                                            value={dataFinal != null ? moment(dataFinal).format("YYYY-MM-DD") : ''}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.target.value : null);
                                                                                setDataFinal(e != null ? e.target.value : null)
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </Form>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                )
                                            },
                                            defaultFilter: dataInicio != null ? dataInicio : dataFinal != null ? dataFinal : 0,
                                            id: dataFinal != null ? 2 : dataInicio != null ? 1 : 0,
                                        },
                                        {
                                            title: 'Data Medição',
                                            field: 'dataApi',
                                            cellStyle: {
                                                width: '13.5%'
                                            },
                                            filtering: false,
                                            type: 'datetime'
                                            /*editComponent: (props) => (
                                                <Form.Control
                                                    type="date"
                                                    value={dataMedicao}
                                                    onChange={e => setDataMedicao(e.target.value)}
                                                     />
                                            ),*/
                                        },
                                        {
                                            title: 'Usuario',
                                            field: 'usuario',
                                            type: 'text',
                                            align: 'left',
                                            cellStyle: {
                                                width: '13.5%'
                                            },
                                            editable: 'never',
                                            defaultFilter: usuarioFiltro
                                        },
                                        {
                                            title: 'Observação',
                                            field: 'observacao',
                                            align: 'left',
                                            editPlaceholder: 'Observação',
                                            cellStyle: {
                                                width: '13.5%'
                                            }, validate: rowData => {
                                                if (rowData.observacao !== undefined) {
                                                    if (rowData.observacao.trim().length > 500) {
                                                        return "A observação não pode conter mais que 500 caracteres"
                                                    }
                                                }
                                                return true;
                                            },
                                            defaultFilter: observacaoFiltro
                                        },
                                        {
                                            title: 'Status', field: 'status',
                                            filtering: true,
                                            cellStyle: {
                                                width: '13.5%'
                                            },
                                            filterComponent: (props) => {
                                                return (
                                                    <Select
                                                        placeholder="Selecione..."
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={optionsStatus}
                                                        defaultValue={{
                                                            value: optionsStatus.filter(v => v.value === statusFiltro).length > 0 ? optionsStatus.filter(v => v.value === statusFiltro)[0].value : "",
                                                            label: optionsStatus.filter(v => v.value === statusFiltro).length > 0 ? optionsStatus.filter(v => v.value === statusFiltro)[0].label : ""
                                                        }}
                                                        onChange={(e) => {
                                                            props.onFilterChanged(props.columnDef.tableData.id, e != null ? e.value : null)
                                                        }}

                                                    />
                                                )
                                            }, editable: 'never',
                                            render: rowData =>
                                                //verifica qual é o status da medição
                                                listaStatusMedicaoAutorizacaoFornecimento != undefined
                                                    && listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0
                                                    && listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 ?
                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].
                                                        descricao === rowData.status ?
                                                        <div className="divStatusGreenListar">{rowData.status}</div>
                                                        :
                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].
                                                            descricao === rowData.status ?
                                                            <div className="divStatusDangerListar">{rowData.status}</div>
                                                            :
                                                            <div className="divStatusNormalListar">{rowData.status}</div>
                                                    :
                                                    '',
                                            defaultFilter: statusFiltro
                                        }

                                    ]}
                                    onSearchChange={setQuery}
                                    icons={{
                                        Add: () =>
                                            <Button variant="success">
                                                <span>
                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                </span>
                                            </Button>
                                    }}
                                    data={
                                        query =>
                                            new Promise((resolve, reject) => {

                                                filtros = {
                                                    "numeroAfFiltro": '',
                                                    "descricaoFiltro": '',
                                                    "totalMedicaoFiltro": '',
                                                    "usuarioFiltro": '',
                                                    "observacaoFiltro": '',
                                                    "statusFiltro": '',
                                                    "dataInicioFiltro": "",
                                                    "dataFinalFiltro": ""
                                                };

                                                //filtros
                                                if (query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento").length > 0) {
                                                    filtros.numeroAfFiltro = query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento")[0].value;
                                                }
                                                if (query.filters.filter(c => c.column.field == "descricao").length > 0 > 0) {
                                                    filtros.descricaoFiltro = query.filters.filter(c => c.column.field == "descricao")[0].value;
                                                }
                                                if (query.filters.filter(c => c.column.field == "usuario").length > 0) {
                                                    filtros.usuarioFiltro = query.filters.filter(c => c.column.field == "usuario")[0].value;
                                                }
                                                if (query.filters.filter(c => c.column.field == "observacao").length > 0) {
                                                    filtros.observacaoFiltro = query.filters.filter(c => c.column.field == "observacao")[0].value;
                                                }
                                                if (query.filters.filter(c => c.column.field == "status").length > 0) {
                                                    filtros.statusFiltro = query.filters.filter(c => c.column.field == "status")[0].value;
                                                }
                                                if (query.filters.filter(c => c.column.field == "dataInclusao").length > 0) {
                                                    var column = query.filters.filter(c => c.column.field == "dataInclusao")[0].column;
                                                    if (column.id == 1) {
                                                        filtros.dataInicioFiltro = query.filters.filter(c => c.column.field == "dataInclusao")[0].value;
                                                    }
                                                    if (column.id == 2) {
                                                        filtros.dataFinalFiltro = query.filters.filter(c => c.column.field == "dataInclusao")[0].value;
                                                    }
                                                }

                                                setNumeroAfFiltro(filtros.numeroAfFiltro);
                                                setDescricaoFiltro(filtros.descricaoFiltro);
                                                setStatusFiltro(filtros.statusFiltro);
                                                setUsuarioFiltro(filtros.usuarioFiltro);
                                                setObservacaoFiltro(filtros.observacaoFiltro);

                                                TrocarData(filtros);

                                                localStorage["filtro-medicao-autorizacao-fornecimento"] = JSON.stringify(filtros);

                                                fetch(`${urlBase}/api/MedicaoAutorizacaoFornecimento/listarTodasCompletoPaginacao`, {
                                                    method: "POST",
                                                    headers: new Headers({
                                                        'Authorization': JSON.parse(localStorage.getItem('token')) !== null ?
                                                            `Bearer ${cookies.get('token')}${JSON.parse(localStorage.getItem('token')).tokenCodificado}`
                                                            : '',
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json'
                                                    }),
                                                    body: JSON.stringify({
                                                        Pagina: query.page + 1,
                                                        NumeroPorPagina: query.pageSize,
                                                        NumeroAf: query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento").length > 0 ? query.filters.filter(c => c.column.field == "idAutorizacaoFornecimento")[0].value : null,
                                                        Descricao: query.filters.filter(c => c.column.field == "descricao").length > 0 ? query.filters.filter(c => c.column.field == "descricao")[0].value : null,
                                                        DataInicio: /*query.filters.filter(c => c.column.field == "data").length > 0 ? query.filters.filter(c => c.column.field == "data")[0].value :*/ null,
                                                        Usuario: query.filters.filter(c => c.column.field == "usuario").length > 0 ? query.filters.filter(c => c.column.field == "usuario")[0].value : null,
                                                        Observacao: query.filters.filter(c => c.column.field == "observacao").length > 0 ? query.filters.filter(c => c.column.field == "observacao")[0].value : null,
                                                        Status: query.filters.filter(c => c.column.field == "status").length > 0 ? query.filters.filter(c => c.column.field == "status")[0].value : null,
                                                        DataInicial: dataInicio !== null && dataInicio !== '' ? moment(dataInicio) : null,
                                                        DataFinal: dataFinal !== null && dataFinal !== '' ? moment(dataFinal) : null,
                                                        geralFiltro: query.search,
                                                    }),

                                                }).then(response => response.json())
                                                    .then(result => {
                                                        resolve({
                                                            data:
                                                                result.result.data != undefined && result.result.data.length > 0 ?
                                                                    result.result.data.map((lista) => {
                                                                        return {
                                                                            id: lista.id,
                                                                            idAutorizacaoFornecimento: lista.idAutorizacaoFornecimento,
                                                                            numeroAf: lista.numeroAf,
                                                                            dataMedicao: moment(lista.dataMedicao).format('L LT'),
                                                                            dataInclusao: moment(lista.dataInclusao).format('L LT'),
                                                                            usuario: lista.usuario.nome,
                                                                            observacao: lista.observacao,
                                                                            status: lista.statusAtual,
                                                                            descricao: lista.descricao,
                                                                            dataApi: lista.dataMedicao,
                                                                            idUsuario: lista.idUsuario,
                                                                            totalMedicao: lista.valorTotalMedicao
                                                                        }
                                                                    })
                                                                    :
                                                                    []
                                                            ,
                                                            page: result.result.pagina - 1,
                                                            totalCount: result.result.total,
                                                        })
                                                    })
                                            })
                                    }
                                    editable={{
                                        onRowAdd:
                                            listaContratoUsuario !== undefined && usuario !== undefined &&
                                                listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id).length > 0 ?
                                                (newRow) => new Promise((resolve, reject) => {
                                                    inserir == true ?
                                                        setTimeout(() => {
                                                            if (!ValidacoesInserir(newRow)) {
                                                                reject();
                                                            }
                                                            else {
                                                                InserirMedicao(newRow);
                                                                resolve()
                                                            }
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                                :
                                                null,
                                        onRowUpdate:
                                            listaContratoUsuario !== undefined && usuario !== undefined &&
                                                listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id).length > 0 ?
                                                (newRow, oldRow) => new Promise((resolve, reject) => {
                                                    alterar == true ?
                                                        setTimeout(() => {
                                                            if (!ValidacoesInserir(newRow)) {
                                                                reject();
                                                            }
                                                            else {
                                                                AlterarMedicao(newRow);
                                                                resolve()
                                                            }
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                                :
                                                null,
                                    }}
                                    options={{
                                        debounceInterval: 1500,
                                        minBodyHeight: 500,
                                        sorting: false,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        addRowPosition: 'first',
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: () => <FaSearchPlus />,
                                            onClick: () => history.push(`/alterarGeralMedicao/${rowData.id}/${rowData.idAutorizacaoFornecimento}`)
                                        })
                                    ]}
                                    localization={{
                                        header: {
                                            actions: <span className="classHeaderTabela">Ações</span>
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                saveTooltip: '',
                                                cancelTooltip: '',
                                            },
                                            deleteTooltip: '',
                                            editTooltip: '',
                                            addTooltip: '',
                                            dateTimePickerLocalization: ptBR
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    )
}