import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarFaturamentoResponse, ListarCompletoFaturamentoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarFaturamentoRequest() {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/Faturamento`, { headers: headerParams.token });

    yield put(ListarFaturamentoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    yield put(ListarFaturamentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarCompletoFaturamentoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/Faturamento/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarCompletoFaturamentoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    yield put(ListarCompletoFaturamentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirFaturamentoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const resultFatu = yield call(api.post, `/api/Faturamento`, model.data, { headers: headerParams.token });

    toast.success(`Faturamento ${resultFatu.data.result} foi cadastrado com sucesso!`);

    // Listar completo
    const resultComFiltro = yield call(api.post, `/api/Faturamento/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarCompletoFaturamentoResponse(resultComFiltro.data.result));

    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarFaturamentoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/Faturamento`, model.data, { headers: headerParams.token });

    toast.success(`Faturamento foi atualizado com sucesso!`);

    // Listar completo
    const resultComFiltro = yield call(api.post, `/api/Faturamento/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarCompletoFaturamentoResponse(resultComFiltro.data.result));

    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirFaturamentoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/Faturamento/${model.id}`, { headers: headerParams.token });

    toast.success(`Faturamento id: ${model.id} foi excluido!`);

    // Listar completo
    const resultComFiltro = yield call(api.post, `/api/Faturamento/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarCompletoFaturamentoResponse(resultComFiltro.data.result));
    
    yield put(Carregando(true))    

  } catch (e) {
    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
    toast.error(e.response.data.message);
  }
}

export default all([
  takeLatest('LISTAR_FATURAMENTO_REQUEST', ListarFaturamentoRequest),
  takeLatest('LISTAR_COMPLETO_FATURAMENTO_REQUEST', ListarCompletoFaturamentoRequest),
  takeLatest('INSERIR_FATURAMENTO_REQUEST', InserirFaturamentoRequest),
  takeLatest('ALTERAR_FATURAMENTO_REQUEST', AlterarFaturamentoRequest),
  takeLatest('EXCLUIR_FATURAMENTO_REQUEST', ExcluirFaturamentoRequest)
])