import produce from 'immer';

const initialState = {
    listaCnae: undefined,
    carregando: true
};

export default function cnae(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_CNAE_RESPONSE':
            return produce(state, draft => {
                draft.listaCnae = action.listaCnae
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}