import { useEffect, useState } from "react";
import { Row, Col, Card, Form, Dropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { IoResize } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarRelatorioStatusSolicitacaoRequest } from "../../../store/modules/relatorio/actions";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom";
import { RiFilter3Line } from "react-icons/ri";
import moment from 'moment';

export default function Listar() {

    const dispatch = useDispatch();
    const history = useHistory();
    const carregando = useSelector(state => state.relatorio.carregando);
    const listaRelatorioStatusSolicitacao = useSelector(state => state.relatorio.listaRelatorioStatusSolicitacao);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState([]);
    const [carregaPage, setCarregaPage] = useState(true);

    const [dataInicio, setDataInicio] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [visualizarTudo, setVisualizarTudo] = useState(false);
    const [menu, setMenu] = useState(true);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    
    useEffect(() => {
        //Permissão de Visualizar
        if (token.role.filter(r => r == "RelatorioMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {

        setCarregaPage(false);
        dispatch(ListarRelatorioStatusSolicitacaoRequest(null, null, null));

        setDataInicio(moment().startOf('month'));
        setDataFinal(moment().endOf('month'));

    }, [])

    useEffect(() => {

        if (listaRelatorioStatusSolicitacao !== null &&
            listaRelatorioStatusSolicitacao !== undefined) {
            constroiHierarquia();
        }

    }, [listaRelatorioStatusSolicitacao])

   function constroiHierarquia() {

        let hierarquiaCompleta = [];

        listaRelatorioStatusSolicitacao.forEach((lista) => {
            hierarquiaCompleta.push({
                id: lista[0].idSolicitacaoFornecimento,
                nomeObra: null,
                Oficio: null,
                Processo: null,
                Orçamento: null,
                Chegada: null,
                saida: null,
                dias: null,
                Usuario: null,
                Valor: null,
                status: null,
                proximaDemanda: null
            });
            lista.map(x => {
                hierarquiaCompleta.push({
                    marcadorUltimo: lista[lista.length - 1].idSolicitacaoFornecimentoStatus == x.idSolicitacaoFornecimentoStatus ?
                    true : false,
                    id: x.idSolicitacaoFornecimento,
                    Oficio: x.numeroOficio,
                    Processo: x.numeroProcesso,
                    nomeObra: x.nomeObra,
                    Orçamento: x.numeroOrcamento,
                    Chegada: x.dataCriacao,
                    saida:  x.dataSaida ? x.dataSaida : " - ",
                    dias: x.quantidadeDias,
                    Usuario: x.usuario,
                    Valor: "R$" + x.valorOrcamento.toFixed(2),
                    parentId: x.idSolicitacaoFornecimento,
                    status: x.statusSolicitacao,
                    proximaDemanda: x.proximaDemanda,
                });
            })
        })
        setListaHierarquiaCompleta(hierarquiaCompleta);
        setCarregaPage(true);
    }

    function FiltrarData() {

        var dataIni = dataInicio === '' ? null : dataInicio;
        var dataFin = dataFinal === '' ? null : dataFinal;

        dispatch(ListarRelatorioStatusSolicitacaoRequest(dataIni, dataFin, visualizarTudo));

        if(dataInicio === ''){
            setDataInicio(null);
        }
        if(dataFinal === ''){
            setDataFinal(null);
        }

        setCarregaPage(false);
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <IoResize size={25} color={"#000"} /> Relatório Status Solicitação
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregaPage ?
                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Número Solicitação', field: 'id', editable: 'never', defaultSort:"desc",
                                                            },
                                                            {
                                                                title: 'Nome da Obra', field: 'nomeObra', editable: 'never'
                                                            },
                                                            {
                                                                title: 'N° Oficio', field: 'Oficio', editable: 'never'
                                                            },
                                                            {
                                                                title: 'N° Processo', field: 'Processo', editable: 'never'
                                                            },
                                                            /*{
                                                                title: 'N° Orçamento', field: 'Orçamento', editable: 'never'
                                                            },*/
                                                            {
                                                                title: 'Data Chegada Status', field: 'Chegada', editable: 'never',
                                                                filterComponent:(props) => {
                                                                    return (
                                                                        <Col  style={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'flex'}}>
                                                                            <Dropdown drop='down-centered' align={"start"} autoClose={false} focusFirstItemOnShow={false}>
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" autoClose={false}> 
                                                                                    <RiFilter3Line />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu show={menu} 
                                                                                    style={{ padding: "6px", borderRadius: "4px", minWidth: "30rem" }}>
                                                                                    <div>
                                                                                        <Form as={Row} md={12}>
                                                                                            <Form.Group as={Col} md={6}>
                                                                                                <Form.Label>Data Chegada Status</Form.Label>
                                                                                                <Form.Control
                                                                                                    style={{ minHeight: '36px' }}
                                                                                                    type="date"
                                                                                                    value={dataInicio != null ? moment(dataInicio).format("YYYY-MM-DD") : ''}
                                                                                                    onChange={(e) => {
                                                                                                        setDataInicio(e != null ? e.target.value : null)
                                                                                                    }}
                                                                                                />
                                                                                            </Form.Group>
                                                                                            <Form.Group as={Col} md={6}>
                                                                                                <Form.Label>Data Saída Status</Form.Label>
                                                                                                <Form.Control
                                                                                                    style={{ minHeight: '36px' }}
                                                                                                    type="date"
                                                                                                    value={dataFinal != null ? moment(dataFinal).format("YYYY-MM-DD") : ''}
                                                                                                    onChange={(e) => {
                                                                                                        setDataFinal(e != null ? e.target.value : null)
                                                                                                    }}
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Form>
                                                                                    </div>
                                                                                    <Form.Check
                                                                                        size="lg"
                                                                                        label="Ver Tudo"
                                                                                        type="switch"
                                                                                        id="custom-switch"
                                                                                        onChange={() => { setVisualizarTudo(!visualizarTudo) }}
                                                                                        checked={visualizarTudo}
                                                                                    />
                                                                                    <Dropdown.Item className="dropdownItemClass">
                                                                                        <div style={{ margin: 5, justifyContent: 'flex-end', flexDirection: 'row', display: 'flex'}}>
                                                                                            <Button variant="success" onClick={() => {FiltrarData()}}
                                                                                            disabled={carregaPage ? false : true}>Atualizar</Button>
                                                                                        </div>
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </Col> 
                                                                    )
                                                                },
                                                                render: rowData => (
                                                                    rowData.Chegada ?
                                                                        <span>{moment(rowData.Chegada).format('L')}</span>
                                                                    :
                                                                        null
                                                                )
                                                            },
                                                            {
                                                                title: 'Data Saída Status', field: 'saida', editable: 'never',
                                                                filtering: false,
                                                                render: rowData => (
                                                                    rowData.saida !== null ?
                                                                        rowData.saida !== " - " ?
                                                                            <span>{moment(rowData.saida).format('L')}</span>
                                                                        :
                                                                            <span> - </span>
                                                                    :
                                                                        null    
                                                                )
                                                            },
                                                            {
                                                                title: 'Quant. Dias', field: 'dias', editable: 'never',
                                                                customFilterAndSearch: (term, rowData) => term == rowData.dias,
                                                            },
                                                            {
                                                                title: 'Usuário', field: 'Usuario', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Valor Orçamento', field: 'Valor', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Status', field: 'status', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Próxima Demanda', field: 'proximaDemanda', editable: 'never',
                                                                render: rowData => (
                                                                    rowData.marcadorUltimo ?
                                                                        <span>{rowData.proximaDemanda}</span>
                                                                        :
                                                                        <span style={{color: '#9D9999'}}>{rowData.proximaDemanda}</span>
                                                                )
                                                            },
                                                            {
                                                                title: 'm', field: 'marcadorUltimo', editable: 'never', hidden: true
                                                            },
                                                        ]}
                                                        data={listaHierarquiaCompleta}
                                                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            maxBodyHeight: '500px',
                                                            paging: true,
                                                            pageSize: 50, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                :
                                                <Col md={6} style={{ margin: 'auto', display: 'grid' }}>
                                                    <Row style={{ margin: 'auto' }}>
                                                        <h5 style={{ textAlign: 'right' }}>Dados extensos! Isso pode levar alguns minutos...</h5>
                                                    </Row>
                                                    <Row style={{ margin: 'auto'}}>
                                                        <Loading/>
                                                    </Row>
                                                </Col>
                                                
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}