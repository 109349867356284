export function ListarStatusEmpenhoRequest() {
    return {
      type: 'LISTAR_STATUS_EMPENHO_REQUEST'
    }
  }
  
  export function ListarStatusEmpenhoResponse(listaStatusEmpenho) {
    return {
      type: 'LISTAR_STATUS_EMPENHO_RESPONSE',
      listaStatusEmpenho
    }
  }
  
  export function InserirStatusEmpenhoRequest(data) {
    return {
      type: 'INSERIR_STATUS_EMPENHO_REQUEST',
      data
    }
  }
  
  export function AlterarStatusEmpenhoRequest(data) {
    return {
      type: 'ALTERAR_STATUS_EMPENHO_REQUEST',
      data
    }
  }
  
  export function ExcluirStatusEmpenhoRequest(id) {
    return {
      type: 'EXCLUIR_STATUS_EMPENHO_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }