import produce from 'immer';

const initialState = {
    fornecedorContatoCompleta: null,
    listaFornecedorContato: undefined,
    carregando: true
};

export default function fornecedorContato(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_FORNECEDOR_CONTATO_RESPONSE':
            return produce(state, draft => {
                draft.listaFornecedorContato = action.listaFornecedorContato;
            });

        case 'BUSCAR_FORNECEDOR_CONTATO_POR_ID_RESPONSE':
            return produce(state, draft => {
                draft.fornecedorContatoCompleta = action.fornecedorContatoCompleta;
            });
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}