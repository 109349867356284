export function ListarContratoRequest() {
  return {
    type: 'LISTAR_CONTRATO_REQUEST'
  }
}

export function ListarContratoResponse(listaContrato) {
  return {
    type: 'LISTAR_CONTRATO_RESPONSE',
    listaContrato
  }
}

export function BuscarContratoPorIdRequest(id) {
  return {
    type: 'BUSCAR_CONTRATO_POR_ID_REQUEST',
    id
  }
}

export function BuscarContratoPorIdResponse(contratoCompleto) {
  return {
    type: 'BUSCAR_CONTRATO_POR_ID_RESPONSE',
    contratoCompleto
  }
}

export function InserirContratoRequest(data) {
  return {
    type: 'INSERIR_CONTRATO_REQUEST',
    data
  }
}

export function InserirContratoResponse(novoId) {
  return {
    type: 'INSERIR_CONTRATO_RESPONSE',
    novoId
  }
}

export function AlterarContratoRequest(data) {
  return {
    type: 'ALTERAR_CONTRATO_REQUEST',
    data
  }
}

export function ExcluirContratoRequest(id) {
  return {
    type: 'EXCLUIR_CONTRATO_REQUEST',
    id
  }
}

export function LimparNovoId() {
  return {
    type: 'LIMPAR_NOVO_ID',
  }
}

export function ImportarContratoExcelRequest(data, idContrato) {
  return {
    type: 'IMPORTAR_CONTRATO_EXCEL',
    data,
    idContrato
  }
}

export function ImportarContratoExistenteExcelRequest(listaObjeto) {
  return {
    type: 'IMPORTAR_CONTRATO_EXISTENTE_EXCEL',
    listaObjeto
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}