export function ListarAutorizacaoFornecimentoSubitemCustoRequest(data) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST',
    data
  }
}

export function ListarAutorizacaoFornecimentoSubitemCustoResponse(listaAutorizacaoFornecimentoSubitemCusto) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_RESPONSE',
    listaAutorizacaoFornecimentoSubitemCusto
  }
}

export function ListarCompletoAutorizacaoFornecimentoSubitemCustoRequest(data) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST',
    data
  }
}

export function ListarCompletoAutorizacaoFornecimentoSubitemCustoResponse(listaCompletoAutorizacaoFornecimentoSubitemCusto) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_RESPONSE',
    listaCompletoAutorizacaoFornecimentoSubitemCusto
  }
}

export function BuscarAutorizacaoFornecimentoSubitemCustoPorIdResponse(autorizacaoFornecimentoSubitemCustoCompleto) {
  return {
    type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_POR_ID_RESPONSE',
    autorizacaoFornecimentoSubitemCustoCompleto
  }
}

export function InserirAutorizacaoFornecimentoSubitemCustoComFotoRequest(data, idAutorizacaoFornecimento, idContratoVersao, formData) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_COM_FOTO_REQUEST',
    data, idAutorizacaoFornecimento, idContratoVersao, formData
  }
}

export function InserirAutorizacaoFornecimentoSubitemCustoRequest(data, idAutorizacaoFornecimento, idContratoVersao) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST',
    data, idAutorizacaoFornecimento, idContratoVersao
  }
}

export function AlterarAutorizacaoFornecimentoSubitemCustoRequest(data, validado) {
  return {
    type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST',
    data, validado
  }
}

export function ExcluirAutorizacaoFornecimentoSubitemCustoRequest(data) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_REQUEST',
    data
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}

