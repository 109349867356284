export function ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest(idSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST', idSolicitacaoFornecimento
  }
}

export function ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse(listaSolicitacaoFornecimentoVersaoSubitemCusto) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_RESPONSE',
    listaSolicitacaoFornecimentoVersaoSubitemCusto
  }
}

export function InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest(data, idSolicitacaoFornecimento, idContratoVersao) {
  return {
    type: 'INSERIR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST',
    data, idSolicitacaoFornecimento, idContratoVersao
  }
}

export function AlterarSolicitacaoFornecimentoVersaoSubitemCustoRequest(data, idSolicitacaoFornecimento) {
  return {
    type: 'ALTERAR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST',
    data, idSolicitacaoFornecimento
  }
}

export function ExcluirSolicitacaoFornecimentoVersaoSubitemCustoRequest(id) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST',
    id
  }
}

export function ExcluirSolicitacaoFornecimentoVersaoSubitemCustoPorIdSolicitacaoRequest(idSolicitacaoFornecimentoVersao, idSolicitacaoFornecimento) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_POR_ID_SOLICITACAO_REQUEST',
    idSolicitacaoFornecimentoVersao,
    idSolicitacaoFornecimento
  }
}

export function ImportarSolicitacaoFornecimentoVersaoSubitemCustoExcelRequest(listaImportacao, idSolicitacaoFornecimento, idContratoVersao) {
  return {
    type: 'IMPORTACAO_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST',
    listaImportacao, idSolicitacaoFornecimento, idContratoVersao
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}