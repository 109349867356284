import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import {
    ListarOrgaoRequest, AlterarOrgaoRequest,
    InserirOrgaoRequest, ExcluirOrgaoRequest
} from '../../../store/modules/orgao/actions';
import { IoPeopleOutline } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";
import InputMask from "react-input-mask";


export default function Interessado() {

    const dispatch = useDispatch();
    const history = useHistory();
    const carregando = useSelector(state => state.orgao.carregando);
    const listaOrgao = useSelector(state => state.orgao.listaOrgao);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarOrgaoRequest())
    }, [])

    function ValidacoesInserir(orgao) {

        //valida o campo descrição
        if (orgao.descricao == null || orgao.descricao == undefined
            || orgao.descricao.trim() == '' || orgao.descricao.length > 100) {
            toast.error(`Orgão inválido`);
            return false;
        }

        return true;

    }

    function InserirOrgao(orgao) {

        let data = {
            'Id': 0,
            'Descricao': orgao.descricao.trim(),
            'Ativo': orgao.ativo,
            'NomeSecretario': orgao.nomeSecretario,
            'Cnpj': orgao.cnpj,
            'NomeLogradouro': orgao.nomeLogradouro,
            'NumeroLocal': orgao.numeroLocal,
            'NumeroDddFixo': orgao.numeroDddFixo,
            'NumeroTelefoneFixo': orgao.numeroTelefoneFixo,
            'NumeroDdd': orgao.numeroDdd,
            'NumeroTelefone': orgao.numeroTelefone,
        }

        dispatch(InserirOrgaoRequest(data))
    }

    function AlterarOrgao(orgao) {

        let data = {
            'Id': parseInt(orgao.id),
            'Descricao': orgao.descricao.trim(),
            'Ativo': orgao.ativo,
            'NomeSecretario': orgao.nomeSecretario,
            'Cnpj': orgao.cnpj,
            'NomeLogradouro': orgao.nomeLogradouro,
            'NumeroLocal': orgao.numeroLocal,
            'NumeroDddFixo': orgao.numeroDddFixo,
            'NumeroTelefoneFixo': orgao.numeroTelefoneFixo,
            'NumeroDdd': orgao.numeroDdd,
            'NumeroTelefone': orgao.numeroTelefone,
        }

        dispatch(AlterarOrgaoRequest(data))

    }

    function ExcluirOrgao(idOrgao) {
        dispatch(ExcluirOrgaoRequest(idOrgao))
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <IoPeopleOutline size={25} color={"#000"} /> Órgão
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregando ?

                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Número do Orgão', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                    width: '5%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Descrição', field: 'descricao', sorting: true, editPlaceholder: 'Descrição', validate: rowData => {
                                                                    if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    else if (rowData.descricao.trim().length > 100) {
                                                                        return "A descricao não pode conter mais que 100 caracteres"
                                                                    }
                                                                    return true
                                                                }
                                                            },
                                                            {
                                                                title: 'Ativo', field: 'ativo', sorting: true,
                                                                customFilterAndSearch: (term, rowData) => term == rowData.ativo.toString(),
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={[{ value: 'ativado', label: 'Ativado' },
                                                                            { value: 'desativado', label: 'Desativado' },]}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData => (<input type="checkbox" checked={rowData.ativo} onChange={() => { }} />)
                                                            },
                                                            {
                                                                title: 'Nome Secretário', field: 'nomeSecretario', editPlaceholder: 'Nome Secretário',
                                                            },
                                                            {
                                                                title: 'CNPJ', field: 'cnpj', editPlaceholder: 'CNPJ',
                                                                editComponent: (props) => (
                                                                    <InputMask value={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"99.999.999/9999-99"} id="Juridica" className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'Logradouro', field: 'nomeLogradouro', editPlaceholder: 'Logradouro',
                                                            },
                                                            {
                                                                title: 'Número', field: 'numeroLocal', editPlaceholder: 'Número',
                                                            },
                                                            {
                                                                title: 'DDD', field: 'numeroDdd', editPlaceholder: 'DDD',
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"99"} id="idDDD" style={{ width: "100%" }}
                                                                        className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'Telefone', field: 'numeroTelefone', editPlaceholder: 'Descrição',
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"99999 9999"} id="idTelefone" style={{ width: "100%" }}
                                                                        className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'DDD Fixo', field: 'numeroDddFixo', editPlaceholder: 'DDD Fixo',
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        style={{ width: "100%" }} id="idDDDFixo" maxLength={2}
                                                                        className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'Telefone Fixo', field: 'numeroTelefoneFixo', editPlaceholder: 'Telefone Fixo',
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"9999 9999"} id="idTelefoneFixo" style={{ width: "100%" }}
                                                                        className="InputMask" />
                                                                ),

                                                            },

                                                        ]}
                                                        data={
                                                            listaOrgao != undefined && listaOrgao != null && listaOrgao.length > 0 ?
                                                                listaOrgao.map(orgao => {
                                                                    return {
                                                                        id: orgao.id,
                                                                        descricao: orgao.descricao,
                                                                        ativo: orgao.ativo,
                                                                        nomeSecretario: orgao.nomeSecretario,
                                                                        cnpj: orgao.cnpj,
                                                                        nomeLogradouro: orgao.nomeLogradouro,
                                                                        numeroLocal: orgao.numeroLocal,
                                                                        numeroDddFixo: orgao.numeroDddFixo,
                                                                        numeroTelefoneFixo: orgao.numeroTelefoneFixo,
                                                                        numeroDdd: orgao.numeroDdd,
                                                                        numeroTelefone: orgao.numeroTelefone
                                                                    }
                                                                })
                                                                :
                                                                []
                                                        }
                                                        icons={{
                                                            Add: () =>
                                                                <Button variant="success">
                                                                    <span>
                                                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                                                    </span>
                                                                </Button>
                                                        }}
                                                        editable={{
                                                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            InserirOrgao(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),

                                                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            AlterarOrgao(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),

                                                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        ExcluirOrgao(oldData.id);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            })
                                                        }}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            })

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Loading />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}