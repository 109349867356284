import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { LoginUsuarioResponse, LoginUsuarioFail, ListarUsuarioResponse, BuscarUsuarioPorIdResponse, Carregando, InserirNovaSenhaResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import 'moment/locale/pt-br';
import moment from 'moment';

function* ListarUsuarioRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Usuario`, { headers: headerParams.token });

    yield put(ListarUsuarioResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarUsuarioResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BuscarUsuarioPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Usuario/${parseInt(model.id)}`, { headers: headerParams.token });

    yield put(BuscarUsuarioPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    toast.error(e.response.data.message);
  }
}

function* InserirUsuarioRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var result = yield call(api.post, `/api/Usuario`, model.data, { headers: headerParams.token });

    history.push(`/alterarUsuario/${result.data.result}`);

    //Inserir Usuario Fornecedor

    if (model.data.UsuarioFornecedor) {

      model.usuarioFornecedor.IdUsuario = result.data.result;

      yield call(api.post, `/api/UsuarioFornecedor`, model.usuarioFornecedor, { headers: headerParams.token });

    }

    const resultBusca = yield call(api.get, `/api/Usuario/${result.data.result}`, { headers: headerParams.token });

    yield put(BuscarUsuarioPorIdResponse(resultBusca.data.result));

    toast.success(`Usuario foi cadastrado com sucesso!`);

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarUsuarioRequest(model) {


  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const retorno = yield call(api.put, `/api/Usuario`, model.data, { headers: headerParams.token });

    //Alterar Usuario Fornecedor
    if (model.data.UsuarioFornecedor) {

      const usuarioFornecedor = yield call(api.post, `/api/UsuarioFornecedor/listarCompleto`,
        { IdUsuario: parseInt(model.usuarioFornecedor.IdUsuario) }, { headers: headerParams.token });

      if (usuarioFornecedor.data.result != null && usuarioFornecedor.data.result.length > 0) {
        yield call(api.put, `/api/UsuarioFornecedor`, model.usuarioFornecedor, { headers: headerParams.token });
      } else {
        yield call(api.post, `/api/UsuarioFornecedor`, model.usuarioFornecedor, { headers: headerParams.token });
      }

    } else {

      yield call(api.delete, `/api/UsuarioFornecedor/${model.usuarioFornecedor.Id}`, { headers: headerParams.token });

    }

    if (retorno.data.result) {
      localStorage.removeItem('token');
      history.push('/');
    }

    toast.success(`Usuario foi alterado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* LoginUsuarioRequest(model) {

  try {

    const result = yield call(api.post, `/api/Acesso/login`, model.data);

    let token = result.data.result.token;

    let tokenCookie = token.substr(0, 36);
    let tokenStorage = token.substr(36, token.length);

    const cookies = new Cookies();
    cookies.set('token', tokenCookie, { path: '/', maxAge: 7200 });

    let decoded = jwt_decode(token);

    var today = new Date();
    today.setHours(today.getHours() + 10);

    let dataSuccess = {
      token: decoded,
      tokenCodificado: tokenStorage,
      usuario: result.data.result.usuario,
      hora: today.toString()
    }

    yield put(LoginUsuarioResponse(dataSuccess));

    // filtros

    localStorage.setItem('filtro-medicao-autorizacao-fornecimento',
      JSON.stringify(
        {
          "numeroAfFiltro": "",
          "descricaoFiltro": "",
          "totalMedicaoFiltro": "",
          "usuarioFiltro": "",
          "observacaoFiltro": "",
          "statusFiltro": "",
          "dataInicioFiltro": "",
          "dataFinalFiltro": ""
        })
    );

    localStorage.setItem('filtro-autorizacao-fornecimento',
      JSON.stringify(
        {
          "numeroAfFiltro": "",
          "numeroSolicitacaoFiltro": "",
          "numeroOrcamentoFiltro": "",
          "numeroOficioFiltro": "",
          "numeroProcessoFiltro": "",
          "statusFiltro": "",
          "medicaoPendenteFiltro": "",
          "dataInicioFiltro": "",
          "dataFinalFiltro": ""
        })
    );

    localStorage.setItem('filtro-solicitacao-fornecimento',
      JSON.stringify(
        {
          "idSolicitacaoFornecimentoFiltro": "",
          "tipoSolicitacaoFiltro": "",
          "assuntoFiltro": "",
          "localFiltro": "",
          "statusFiltro": "",
          "numeroProcessoFiltro": "",
          "regiaoFiltro": "",
          "numeroOficioFiltro": "",
          "numeroOrcamentoFiltro": "",
          "fornecedorFiltro": "",
          "nomeEventoFiltro": "",
          "prioridadeFiltro": "",
          "autorizacaoFornecimentoFiltro": "",
          "interessadoFiltro": ""
        })
    );

    if (decoded.role != null && decoded.role.filter(d => d == 'DashboardVerValores').length > 0) {
      history.push('/dashboard');
    } else {
      history.push('/bemVindo');
    }


    toast.success("Bem-vindo!")

  } catch (e) {

    yield put(LoginUsuarioFail());
    history.push('/');

    toast.error(e.response.data.message);
  }
}

function* VerificarAtivacaoRequest(model) {

  try {

    const result = yield call(api.get, `/api/Acesso/${parseInt(model.id)}`);

    if (result.data.result) {
      history.push('/');
    }

  } catch (e) {

    yield put(ErrorResponse(e));
    toast.error(e.response.data.message);
  }
}

function* AtivarUsuarioRequest(model) {

  try {

    yield call(api.post, `/api/Acesso/ativacaoConta`, model.data);
    history.push('/');

  } catch (e) {

    yield put(ErrorResponse(e));
    toast.error(e.response.data.message);
  }
}

function* InserirNovaSenhaRequest(model) {

  try {

   const result = yield call(api.post, `/api/Acesso/NovaSenha`, { Email: model.email });
   yield put(InserirNovaSenhaResponse(result.data.result));
   toast.success(`Nova senha enviada para o seu E-mail, verifique a caixa de entrada ou a lixeira!`);  
  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }
    yield put(ErrorResponse(e));
  }
}

function* InserirNovaSenhaPageUsuarioRequest(model) {

  try {

   const result = yield call(api.post, `/api/Acesso/NovaSenhaPageUsuario`, { Email: model.email });
   yield put(InserirNovaSenhaResponse(result.data.result));
   toast.success(`Uma nova senha foi gerada`);  
  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }
    yield put(ErrorResponse(e));
  }
}

export default all([
  takeLatest('LISTAR_USUARIO_REQUEST', ListarUsuarioRequest),
  takeLatest('BUSCAR_USUARIO_POR_ID_REQUEST', BuscarUsuarioPorIdRequest),
  takeLatest('INSERIR_USUARIO_REQUEST', InserirUsuarioRequest),
  takeLatest('ALTERAR_USUARIO_REQUEST', AlterarUsuarioRequest),
  takeLatest('LOGIN_USUARIO_REQUEST', LoginUsuarioRequest),
  takeLatest('VERIFICA_ATIVACAO_REQUEST', VerificarAtivacaoRequest),
  takeLatest('ATIVAR_USUARIO_REQUEST', AtivarUsuarioRequest),
  takeLatest('INSERIR_NOVA_SENHA_REQUEST', InserirNovaSenhaRequest),
  takeLatest('INSERIR_NOVA_SENHA_PAGE_USUARIO_REQUEST', InserirNovaSenhaPageUsuarioRequest),
])