import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse,
        Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';

function* ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(model){

  try {
    
    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubitemCustoMedOcor: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse(result.data.result)); 
    yield put(Carregando(true));    
    
  }catch(e){

   if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }
    
    //yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));
  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubitemCustoMedOcor: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse(result.data.result)); 
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Fotos id: ' + listaId + ' cadastrada com sucesso!');
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    
  }
}

function* AlterarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubitemCustoMedOcor: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse(result.data.result)); 
    yield put(Carregando(true));    
  
    toast.success(`Ocorrência Registro Fotográfico foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));      
    yield put(Carregando(true));    
  }
}

function* ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(model){

  try{

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/${model.idFoto}/${'0'}`, { headers:headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubitemCustoMedOcor: parseInt(model.data) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoResponse(result.data.result)); 
    yield put(Carregando(true));    

    toast.success(`Ocorrência Registro Fotográfico id: ${model.idFoto} foi excluido!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    
  }
}

function* CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoMedicaoAutorizacaoFornecimentoSubItemOcorrencia").appendChild(elem).style.width = "100%";        
        });
  
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* BaixarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeRegistroFotografico}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/AutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFoto/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST', ListarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST', ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST', AlterarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest),
  takeLatest('CARREGAR_IMAGEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST', CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest),
  takeLatest('BAIXAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_OCOR_REG_FOTO_REQUEST', BaixarAutorizacaoFornecimentoSubitemCustoMedicaoOcorRegFotoRequest),
])