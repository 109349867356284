import produce from 'immer';

const initialState = { listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto: undefined,
  carregando: true};

export default function medicaoAutorizacaoFornecimentoOcorrenciaRegFoto(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_RESPONSE':
        return produce(state, draft => {
            draft.listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto = action.listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto
        });
    
     case 'CARREGANDO':
        return produce(state, draft => {
            draft.carregando = action.status
        });  
    
    default:
      return state;
  }
}