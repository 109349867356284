import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  BuscarAutorizacaoFornecimentoPorIdResponse,
  BuscarCompletoAutorizacaoFornecimentoResponse, Carregando,
  ListarAutorizacaoFornecimentoResponse, ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse
} from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarAutorizacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/AutorizacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarAutorizacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/AutorizacaoFornecimento/ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem`,
      {
        IdEmpenho: model.idEmpenho,
        IdContrato: model.idContrato,
        IdFornecedor: model.idFornecedor
      }, { headers: headerParams.token });

    yield put(ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse(result.data.result))

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/AutorizacaoFornecimento`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/AutorizacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Autorizacao Fornecimento foi cadastrado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/AutorizacaoFornecimento`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/AutorizacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Autorizacao Fornecimento foi atualizado com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/AutorizacaoFornecimento/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.get, `/api/AutorizacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Autorizacao Fornecimento id: ${model.id} foi excluido!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BuscarCompletoAutorizacaoFornecimentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/AutorizacaoFornecimento/listarCompleto`,
      { IdAutorizacaoFornecimento: model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    if (result.data.result != null && result.data.result.length > 0) {
      yield put(BuscarCompletoAutorizacaoFornecimentoResponse(result.data.result[0]));
    }
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ErrorResponse(e));
    yield put(BuscarCompletoAutorizacaoFornecimentoResponse(undefined));
    yield put(Carregando(true));

  }

}

function* BuscarAutorizacaoFornecimentoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/AutorizacaoFornecimento/${model.idAutorizacaoFornecimento}`, { headers: headerParams.token });

    if (result.data.result != null) {
      yield put(BuscarAutorizacaoFornecimentoPorIdResponse(result.data.result));
    }
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(BuscarCompletoAutorizacaoFornecimentoResponse(undefined));
    yield put(Carregando(true));

  }

}

function* ListarCompletoAutorizacaoFornecimentoComRedirecionamentoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/AutorizacaoFornecimento/listarCompleto`,
      { IdSolicitacaoFornecimento: model.idSolicitacaoFornecimento }, { headers: headerParams.token });

    if (result.data.result != null && result.data.result.length > 0) {
      history.push(`/alterarGeralAutorizacao/${result.data.result[0].id}`);
    }

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarAutorizacaoFornecimentoResponse([]));
    yield put(Carregando(true));
  }

}

function* ListarAutorizacaoFornecimentoDoEmpenhoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/AutorizacaoFornecimento/FiltroEmpenho/${model.idEmpenho}`, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarAutorizacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarAutorizacaoFornecimentoRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_REQUEST', InserirAutorizacaoFornecimentoRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_REQUEST', ExcluirAutorizacaoFornecimentoRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_REQUEST', AlterarAutorizacaoFornecimentoRequest),
  takeLatest('LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_REQUEST', ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemRequest),
  takeLatest('BUSCAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_REQUEST', BuscarCompletoAutorizacaoFornecimentoRequest),
  takeLatest('LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_COM_REDIRECIONAMENTO_REQUEST', ListarCompletoAutorizacaoFornecimentoComRedirecionamentoRequest),
  takeLatest('BUSCAR_AUTORIZACAO_FORNECIMENTO_POR_ID_REQUEST', BuscarAutorizacaoFornecimentoPorIdRequest),
  takeLatest('LISTAR_AUTORIZACAO_DO_EMPENHO_FORNECIMENTO_REQUEST', ListarAutorizacaoFornecimentoDoEmpenhoRequest),
])
