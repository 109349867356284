import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarEventoAdicionalResponse, BuscarEventoAdicionalPorIdResponse } from './actions';
import { BuscarSolicitacaoFornecimentoPorIdResponse } from '../solicitacaoFornecimento/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarEventoAdicionalRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/EventoAdicional/SolicitacaoFornecimento/${model.idSolicitacaoFornecimento}`, { headers: headerParams.token });

    yield put(ListarEventoAdicionalResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarEventoAdicionalResponse([]));
    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    

  }
}

function* BuscarEventoAdicionalPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/EventoAdicional/${model.id}`, { headers: headerParams.token });

    yield put(BuscarEventoAdicionalPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(BuscarEventoAdicionalPorIdResponse(null));
    yield put(Carregando(true));

  }
}

function* InserirEventoAdicionalRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/EventoAdicional`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/EventoAdicional/SolicitacaoFornecimento/${model.idSolicitacaoFornecimento}`, { headers: headerParams.token });

    yield put(ListarEventoAdicionalResponse(result.data.result));

    const resultSoli = yield call(api.get, `/api/SolicitacaoFornecimento/${model.idSolicitacaoFornecimento}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultSoli.data.result));

    yield put(Carregando(true));
    
    toast.success(`Evento adicional foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(Carregando(true));    

  }
}

function* AlterarEventoAdicionalRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/EventoAdicional`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/EventoAdicional/SolicitacaoFornecimento/${model.idSolicitacaoFornecimento}`, { headers: headerParams.token });

    yield put(ListarEventoAdicionalResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Evento adicional foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(Carregando(true));
    
  }
}

function* ExcluirEventoAdicionalRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/EventoAdicional/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.get, `/api/EventoAdicional/SolicitacaoFornecimento/${model.idSolicitacaoFornecimento}`, { headers: headerParams.token });

    yield put(ListarEventoAdicionalResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Evento adicional id: ${model.id} foi excluido!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(Carregando(true));
    
  }
}

export default all([
  takeLatest('LISTAR_EVENTO_ADICIONAL_REQUEST', ListarEventoAdicionalRequest),
  takeLatest('BUSCAR_EVENTO_ADICIONAL_POR_ID_REQUEST', BuscarEventoAdicionalPorIdRequest),
  takeLatest('INSERIR_EVENTO_ADICIONAL_REQUEST', InserirEventoAdicionalRequest),
  takeLatest('ALTERAR_EVENTO_ADICIONAL_REQUEST', AlterarEventoAdicionalRequest),
  takeLatest('EXCLUIR_EVENTO_ADICIONAL_REQUEST', ExcluirEventoAdicionalRequest)
])