import produce from 'immer';

const initialState = {
  listaRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem: [],
  listaCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem: undefined,
  carregando: true
};

export default function regFotoSubitemAutorizacaoMedicaoUltrapassarSubitem(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_RESPONSE':
      return produce(state, draft => {
        draft.listaRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem = action.listaRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem;
      });
    
    case 'LISTAR_COMPLETO_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_RESPONSE':
        return produce(state, draft => {
          draft.listaCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem = action.listaCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem;
        });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });    

    default:
      return state;
  }
}