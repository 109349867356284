export function ListarContratoUsuarioRequest() {
  return {
    type: 'LISTAR_CONTRATO_USUARIO_REQUEST'
  }
}

export function ListarContratoUsuarioResponse(listaContratoUsuario) {
  return {
    type: 'LISTAR_CONTRATO_USUARIO_RESPONSE',
    listaContratoUsuario
  }
}

export function InserirContratoUsuarioRequest(data) {
  return {
    type: 'INSERIR_CONTRATO_USUARIO_REQUEST',
    data
  }
}

export function AlterarContratoUsuarioRequest(data) {
  return {
    type: 'ALTERAR_CONTRATO_USUARIO_REQUEST',
    data
  }
}

export function ExcluirContratoUsuarioRequest(id) {
  return {
    type: 'EXCLUIR_CONTRATO_USUARIO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}