import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { useEffect, useState } from "react";
import img from '../../assets/login/img_login.png';
import moment from 'moment';
import cabecalho from '../../assets/novoCabecalhoCor.jpg';
import rodape from '../../assets/novoRodapeCor.jpg';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: '10',
    paddingBottom: 65,
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '15',
  },
  linha: {
    borderBottom: '1px solid #0000',
    width: '100%',
  },
  espaco: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    width: '100%',
  },
  section: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },

  rowCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  bordaCompleta: {
    border: '1px solid #0000',
    margin: 1
  },
  comeco: {
    width: "25%",
  },

  meio: {
    width: "50%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  linhaCompletaCentralizada: {
    width: "100%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  fim: {
    width: "25%",
  },
  linhaMetadeComeco: {
    width: "50%",
  },
  linhaMetadeFim: {
    width: "50%",
  },
  linhaSemiCompleta: {
    width: "85%",
  },
  fimLinhaSemiCompleta: {
    width: "15%",
  },
  linhaCompleta: {
    width: "100%",
  },
  width8: {
    width: "8%"
  },
  width10: {
    width: "10%"
  },
  width35: {
    width: "35%"
  },
  width15: {
    width: "15%"
  },
  width16: {
    width: "16%"
  },
  width20: {
    width: "20%"
  },
  width80: {
    width: "80%"
  },
  cabecalho: {
    color: '#fff',
    backgroundColor: '#212529',
    bordercolor: '#32383e'
  },
  cinza: {
    backgroundColor: '#e9ecef',
  },
  logo: {
    width: '200',
    height: '100'
  },
  top20: {
    top: '20'
  },
  top40: {
    top: '40'
  },
  top60: {
    top: '60'
  },
  recuo: {
    textIndent: '40px'
  },
  centralizarTexto: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',

  },
  bottom5: {
    marginBottom: '5px'
  },
  bottom30: {
    marginBottom: '30px'
  },
  bottom65: {
    marginBottom: '65px'
  },
  bottom40: {
    marginBottom: '45px'
  },
  fontSize5: {
    fontSize: '5'
  },
  fontSize4: {
    fontSize: '4'
  },
  fontSize8: {
    fontSize: '8'
  },
  fontSize45: {
    fontSize: '4.5'
  },
  fontSize2: {
    fontSize: '2'
  },
  width50: {
    width: '50'
  },
  width5: {
    width: '5%'
  },
  flex: {
    flex: 1
  },
  maxHeight: {
    maxHeight: '50px'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  comecoMaior: {
    width: "40%",
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',
    padding: '5',
  },
  rodape: {
    width: '585',
    height: '50',
  },
  fimMetade: {
    width: "35%",
  },
  espacoGrande: {
    paddingTop: '10',
    paddingBottom: '10',
  },
});


export default function PdfEmpenho({ data, assinante }) {


  useEffect(() => {
    moment.locale('pt-br');
  }, [])


  return (
    <Document>

      {
        data.listaDescricao.length > 0 ?

          // Página Resumo
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>

              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho}
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  CONTRATO: {data.descricaoContrato}
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  RESUMO GERAL
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  Periodo: {moment(data.dataEmpenho).format('L')} até {moment(data.dataEmpenhoFinal).format('L')}
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                  <Text style={styles.width80}>DESCRIÇÃO</Text>
                  <Text style={styles.width20}>VALOR (R$)</Text>
                </View>
                <View style={[styles.linha]}></View>
                {
                  data.listaDescricao && data.listaDescricao.length > 0 ?
                    data.listaDescricao.map((c, i) => (
                      <View>
                        {
                          (i + 1) % 2 == 0
                            ?
                            <View key={i} style={styles.row} break>
                              <Text style={[styles.width80]}>{c}</Text>
                              <Text style={[styles.width20]}>{data.listaValores[i]}</Text>
                            </View>
                            :
                            <View key={i} style={[styles.row, styles.cinza]} break>
                              <Text style={[styles.width80]}>{c}</Text>
                              <Text style={[styles.width20]}>{data.listaValores[i]}</Text>
                            </View>
                        }

                        <View style={[styles.linha]}></View>
                      </View>
                    ))
                    :
                    ''
                }
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompleta]}>
                  {
                    data != null && data.listaValores.length > 2 && data.listaValores[2].indexOf('-') == 0 ?
                      "* Diferença a favor da Prestadora de Serviços"
                      :
                      "* Diferença a favor da Prefeitura Municipal de Fortaleza"
                  }

                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                  <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho}</Text>
                  <Text style={styles.width20}>{data.totalFinal}</Text>
                </View>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fimMetade}>
                  Fortaleza, {moment(data.dataEmpenhoFinal).format('LL')}
                </Text>
              </View>

              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                <Text style={styles.comeco}>De Acordo, </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  _____________________________________
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  _____________________________________
                </Text>
              </View>
              <Text style={[styles.meio]}></Text>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  {assinante}
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Cândido Araújo Martins Neto
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  Secretário da Secretaria de Conservação e Serviços Públicos
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Coordenador da COEIP
                </Text>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          :
          // Página Resumo sem valores
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>

              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho}
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  CONTRATO: {data.descricaoContrato}
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  RESUMO GERAL
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  Periodo: {moment(data.dataEmpenho).format('L')} até {moment(data.dataEmpenhoFinal).format('L')}
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                  <Text style={styles.width80}>DESCRIÇÃO</Text>
                  <Text style={styles.width20}>VALOR (R$)</Text>
                </View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.cinza]} break>
                  <Text style={[styles.width80]}>Nenhum resultado encontrado</Text>
                  <Text style={[styles.width20]}>R$ 0,00</Text>
                </View>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompleta]}>
                  {
                    data != null && data.listaValores.length > 2 && data.listaValores[2].indexOf('-') == 0 ?
                      "* Diferença a favor da Prestadora de Serviços"
                      :
                      "* Diferença a favor da Prefeitura Municipal de Fortaleza"
                  }

                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                  <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho}</Text>
                  <Text style={styles.width20}>{data.totalFinal}</Text>
                </View>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fimMetade}>
                  Fortaleza, {moment(data.dataEmpenhoFinal).format('LL')}
                </Text>
              </View>

              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                <Text style={styles.comeco}>De Acordo, </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  _____________________________________
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  _____________________________________
                </Text>
              </View>
              <Text style={[styles.meio]}></Text>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  {assinante}
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Cândido Araújo Martins Neto
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  Secretário da Secretaria de Conservação e Serviços Públicos
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Coordenador da COEIP
                </Text>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
      }
      {
        data.listaValorEntregaDefinitiva.length > 0 ?

          // Página Entrega Definitiva
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.titulo, styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (A - Entrega Definitiva)
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  Em {moment(data.dataEmpenhoFinal).format('LL')} constatamos a execução dos
                  Serviços de obras, conforme as Autorizações de Serviço - AS, a saber:
                </Text>
              </View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={styles.table}>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                  <Text style={styles.width15}>AF Nº</Text>
                  <Text style={styles.width15}>QTD DE UDS</Text>
                  <Text style={styles.width10}>% FATURADA NESTE TRO</Text>
                  <Text style={styles.width15}>VALOR A FATURAR</Text>
                  <Text style={styles.width15}>DIFERENÇA</Text>
                  <Text style={styles.width15}>DATA DE RECEBIMENTO DA OBRA</Text>
                  <Text style={styles.width15}>OBSERVAÇÃO</Text>
                </View>
                <View style={[styles.linha]}></View>
                {
                  data.listaValorEntregaDefinitiva && data.listaValorEntregaDefinitiva.length > 0 ?
                    data.listaValorEntregaDefinitiva.map((c, i) => (
                      <View>
                        {
                          (i + 1) % 2 == 0
                            ?
                            <View key={i} style={[styles.row, styles.centralizarTexto]} break>
                              <Text style={styles.width15}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={styles.width15}>{c.valor}</Text>
                              <Text style={styles.width10}>{c.porcentagem}</Text>
                              <Text style={styles.width15}>{c.valor}</Text>
                              <Text style={styles.width15}>{c.diferenca}</Text>
                              <Text style={styles.width15}>{moment(c.data).format('L')}</Text>
                              <Text style={styles.width15}>{c.observacao}</Text>
                            </View>
                            :
                            <View key={i} style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                              <Text style={styles.width15}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={styles.width15}>{c.valor}</Text>
                              <Text style={styles.width10}>{c.porcentagem}</Text>
                              <Text style={styles.width15}>{c.valor}</Text>
                              <Text style={styles.width15}>{c.diferenca}</Text>
                              <Text style={styles.width15}>{moment(c.data).format('L')}</Text>
                              <Text style={styles.width15}>{c.observacao}</Text>
                            </View>

                        }
                        <View style={[styles.linha]}></View>
                      </View>
                    ))
                    :
                    ''
                }
              </View>
              {
                data.listaValores != null && data.listaValores.length > 0 ?
                  <View style={styles.table}>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.linha]}></View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (A - Entrega Definitiva)</Text>
                      <Text style={styles.width20}>{data.listaValores[0]}</Text>
                    </View>
                  </View>
                  :
                  ''
              }
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.row]} >
                  <Text>As obras autorizadas conforme AS acima, foram executadas em conformidade com seus respectivos
                    orçamentos, ressaltando as obras relacionadas na planilha TRO(Diferenças), dentro dos prazos estabelecidos,
                    ficando a Empresa FM RODRIGUES {`&`} CIA LTDA. autorizada a incluir a cobrança da fatura referente a estes
                    serviços no relatório até a data de {moment(data.dataEmpenhoFinal).format('L')}.
                  </Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.row, styles.centralizarTexto]} >
                  <Text>Total de AS. Definitivas: {data.listaValorEntregaDefinitiva.length}</Text>
                </View>
              </View>

              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View wrap={false} style={{marginTop: "auto", transform: 'translateY(15px)'}}>
                <View style={[styles.espacoGrande]}></View>
                <View style={[styles.espacoGrande]}></View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                  <Text style={styles.comeco}>De Acordo, </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    _____________________________________
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    _____________________________________
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    {assinante}
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Cândido Araújo Martins Neto
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    Secretário da Secretaria de Conservação e Serviços Públicos
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Coordenador da COEIP
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          :
          // Página Entrega Definitiva sem valores
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.titulo, styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (A - Entrega Definitiva)
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  Em {moment(data.dataEmpenhoFinal).format('LL')} constatamos a execução dos
                  Serviços de obras, conforme as Autorizações de Serviço - AS, a saber:
                </Text>
              </View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={styles.table}>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                  <Text style={styles.width15}>AF Nº</Text>
                  <Text style={styles.width15}>QTD DE UDS</Text>
                  <Text style={styles.width10}>% FATURADA NESTE TRO</Text>
                  <Text style={styles.width15}>VALOR A FATURAR</Text>
                  <Text style={styles.width15}>DIFERENÇA</Text>
                  <Text style={styles.width15}>DATA DE RECEBIMENTO DA OBRA</Text>
                  <Text style={styles.width15}>OBSERVAÇÃO</Text>
                </View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                  <Text style={[styles.linhaCompletaCentralizada]}>Nenhum resultado encontrado</Text>
                </View>
              </View>
              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                  <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (A - Entrega Definitiva)</Text>
                  <Text style={styles.width20}>R$ 0,00</Text>
                </View>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.row]} >
                  <Text>As obras autorizadas conforme AS acima, foram executadas em conformidade com seus respectivos
                    orçamentos, ressaltando as obras relacionadas na planilha TRO(Diferenças), dentro dos prazos estabelecidos,
                    ficando a Empresa FM RODRIGUES {`&`} CIA LTDA. autorizada a incluir a cobrança da fatura referente a estes
                    serviços no relatório até a data de {moment(data.dataEmpenhoFinal).format('L')}.
                  </Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.row, styles.centralizarTexto]} >
                  <Text>Total de AS. Definitivas: {data.listaValorEntregaDefinitiva.length}</Text>
                </View>
              </View>

              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                <Text style={styles.comeco}>De Acordo, </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  _____________________________________
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  _____________________________________
                </Text>
              </View>
              <Text style={[styles.meio]}></Text>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  {assinante}
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Cândido Araújo Martins Neto
                </Text>
              </View>
              <Text style={[styles.meio]}></Text>
              <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  Secretário da Secretaria de Conservação e Serviços Públicos
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Coordenador da COEIP
                </Text>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
      }
      {
        data.listaValorEntregaParcial.length > 0 ?

          // Página Entrega Parcial
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.titulo, styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (B - Entrega Parcial)
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  Em {moment(data.dataEmpenhoFinal).format('LL')} constatamos a execução dos
                  Serviços de obras, conforme as Autorizações de Serviço - AS, a saber:
                </Text>
              </View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={styles.table}>
                <View style={[styles.espaco]}fixed></View>
                <View style={[styles.espaco]}fixed></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]}>
                  <Text style={styles.width10}>AF Nº</Text>
                  <Text style={styles.width10}>TOTAL</Text>
                  <Text style={styles.width10}>NESTE EMP.</Text>
                  <Text style={styles.width10}>ACUM. EMP. ANT.(%)</Text>
                  <Text style={styles.width10}>NEST EMP.(%)</Text>
                  <Text style={styles.width10}>ACUMULADA(%)</Text>
                  <Text style={styles.width10}>VALOR A FATURAR R$</Text>
                  <Text style={styles.width10}>DATA</Text>
                  <Text style={styles.width10}>STATUS</Text>
                  <Text style={styles.width10}>OBSERVAÇÃO</Text>
                </View>
                <View style={[styles.linha]}></View>
                {
                  data.listaValorEntregaParcial && data.listaValorEntregaParcial.length > 0 ?
                    data.listaValorEntregaParcial.map((c, i) => (
                      <View>
                        {
                          (i + 1) % 2 == 0
                            ?
                            <View key={i} style={[styles.row, styles.centralizarTexto]} break>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.total}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.empenhoAtual}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.porcentagemEmpenhoAnterior}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.porcentagemEmpenhoAtual}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.porcentagemAcumulada}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.valorFaturar}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{moment(c.data).format('L')}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.status}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.observacao}</Text>
                            </View>
                            :
                            <View key={i} style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.total}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.empenhoAtual}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.porcentagemEmpenhoAnterior}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.porcentagemEmpenhoAtual}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.porcentagemAcumulada}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.valorFaturar}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{moment(c.data).format('L')}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.status}</Text>
                              <Text style={[styles.width10, styles.fontSize8]}>{c.observacao}</Text>
                            </View>
                        }
                        <View style={[styles.linha]}></View>
                      </View>
                    ))
                    :
                    ''
                }
              </View>
              {
                data.listaValores != null && data.listaValores.length > 0 ?
                  <View style={styles.table}>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.linha]}></View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (B - Entrega Parcial)</Text>
                      <Text style={styles.width20}>{data.listaValores[1]}</Text>
                    </View>
                  </View>
                  :
                  ''
              }
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.row]} >
                  <Text style={styles.width20}>Total de AS. Parciais: {data.listaValorEntregaParcial.length}</Text>
                </View>
              </View>

              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View wrap={false} style={{marginTop: "auto"}}>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                  <Text style={styles.comeco}>De Acordo, </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    _____________________________________
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    _____________________________________
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    {assinante}
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Cândido Araújo Martins Neto
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    Secretário da Secretaria de Conservação e Serviços Públicos
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Coordenador da COEIP
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          :
          // Página Entrega Parcial sem valores
          <Page size="A4" style={styles.page} wrap>
          <View style={[styles.section]}>
            <View style={[styles.rowCabecalho]}>
              <Text style={styles.comeco}></Text>
              <Text style={[styles.titulo, styles.meio]}>
                <Image style={styles.logo} src={cabecalho} />
              </Text>
              <Text style={styles.fim}></Text>
            </View>
            <View style={[styles.espaco]}></View>
            <View style={[styles.espaco]}></View>
            <View style={[styles.rowCabecalho]}>
              <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (B - Entrega Parcial)
              </Text>
            </View>
            <View style={[styles.rowCabecalho]}>
              <Text style={[styles.linhaCompletaCentralizada]}>
                Em {moment(data.dataEmpenhoFinal).format('LL')} constatamos a execução dos
                Serviços de obras, conforme as Autorizações de Serviço - AS, a saber:
              </Text>
            </View>
            <View style={[styles.espaco]} fixed></View>
            <View style={[styles.espaco]} fixed></View>
            <View style={styles.table}>
              <View style={[styles.espaco]}fixed></View>
              <View style={[styles.espaco]}fixed></View>
              <View style={[styles.linha]}></View>
              <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]}>
                <Text style={styles.width10}>AF Nº</Text>
                <Text style={styles.width10}>TOTAL</Text>
                <Text style={styles.width10}>NESTE EMP.</Text>
                <Text style={styles.width10}>ACUM. EMP. ANT.(%)</Text>
                <Text style={styles.width10}>NEST EMP.(%)</Text>
                <Text style={styles.width10}>ACUMULADA(%)</Text>
                <Text style={styles.width10}>VALOR A FATURAR R$</Text>
                <Text style={styles.width10}>DATA</Text>
                <Text style={styles.width10}>STATUS</Text>
                <Text style={styles.width10}>OBSERVAÇÃO</Text>
              </View>
              <View style={[styles.linha]}></View>
              <View style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                <Text style={[styles.linhaCompletaCentralizada]}>Nenhum resultado encontrado</Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={[styles.espaco]}></View>
              <View style={[styles.linha]}></View>
              <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (B - Entrega Parcial)</Text>
                <Text style={styles.width20}>R$ 0,00</Text>
              </View>
            </View>
            <View style={[styles.espaco]}></View>
            <View style={[styles.espaco]}></View>

            <View style={styles.table}>
              <View style={[styles.espaco]}></View>
              <View style={[styles.row]} >
                <Text style={styles.width20}>Total de AS. Parciais: {data.listaValorEntregaParcial.length}</Text>
              </View>
            </View>

            <View style={[styles.espaco]}></View>
            <View style={[styles.espaco]}></View>

            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
              <Text style={styles.comeco}>De Acordo, </Text>
              <Text style={[styles.meio]}></Text>
              <Text style={styles.fim}></Text>
            </View>
            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
              <Text style={[styles.linhaMetadeComeco]}>
                _____________________________________
              </Text>
              <Text style={[styles.linhaMetadeFim]}>
                _____________________________________
              </Text>
            </View>
            <Text style={[styles.meio]}></Text>
            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
              <Text style={[styles.linhaMetadeComeco]}>
                {assinante}
              </Text>
              <Text style={[styles.linhaMetadeFim]}>
                Cândido Araújo Martins Neto
              </Text>
            </View>
            <Text style={[styles.meio]}></Text>
            <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
              <Text style={[styles.linhaMetadeComeco]}>
                Secretário da Secretaria de Conservação e Serviços Públicos
              </Text>
              <Text style={[styles.linhaMetadeFim]}>
                Coordenador da COEIP
              </Text>
            </View>
          </View>
          <Text style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
          </Page>
      }
      {
        data.listaValorEntregaDiferenca.length > 0 ?

          // Página Diferença
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.titulo, styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (C - Diferença)
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.linhaCompletaCentralizada]}>
                  Em {moment(data.dataEmpenhoFinal).format('LL')} constatamos,
                  após fiscalização desta SCSP, diferenças nos quantitativos dos seguintes orçamentos / AF,
                  conforme o que preceitua a cláusula décima do contrato {data.idContrato}, assinado entre as partes.
                </Text>
              </View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={styles.table}>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                  <Text style={styles.width16}>AF Nº</Text>
                  <Text style={styles.width16}>ITEM</Text>
                  <Text style={styles.width16}>QUANT. FISCALIZADA</Text>
                  <Text style={styles.width16}>DIFERENÇA</Text>
                  <Text style={styles.width16}>VALOR</Text>
                  <Text style={styles.width16}>VALOR A DEDUZIR (R$)</Text>
                </View>
                <View style={[styles.linha]}></View>
                {
                  data.listaValorEntregaDiferenca && data.listaValorEntregaDiferenca.length > 0 ?
                    data.listaValorEntregaDiferenca.map((c, i) => (
                      <View>
                        {
                          (i + 1) % 2 == 0
                            ?
                            <View key={i} style={[styles.row, styles.centralizarTexto]} break>
                              <Text style={styles.width16}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={styles.width16}>{c.item}</Text>
                              <Text style={styles.width16}>{c.quantidade}</Text>
                              <Text style={styles.width16}>{c.diferenca}</Text>
                              <Text style={styles.width16}>{c.valor}</Text>
                              <Text style={styles.width16}>{c.valorTotal}</Text>
                            </View>
                            :
                            <View key={i} style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                              <Text style={styles.width16}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={styles.width16}>{c.item}</Text>
                              <Text style={styles.width16}>{c.quantidade}</Text>
                              <Text style={styles.width16}>{c.diferenca}</Text>
                              <Text style={styles.width16}>{c.valor}</Text>
                              <Text style={styles.width16}>{c.valorTotal}</Text>
                            </View>
                        }
                        <View style={[styles.linha]}></View>
                      </View>
                    ))
                    :
                    ''
                }
              </View>
              {
                data.listaValores != null && data.listaValores.length > 0 ?
                  <View style={styles.table}>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.linha]}></View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (C - Entrega Diferença)</Text>
                      <Text style={styles.width20}>{data.listaValores[2]}</Text>
                    </View>
                  </View>
                  :
                  ''
              }
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View wrap={false} style={{marginTop: "auto"}}>
                <View style={[styles.espacoGrande]}></View>
                <View style={[styles.espacoGrande]}></View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                  <Text style={styles.comeco}>De Acordo, </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    _____________________________________
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    _____________________________________
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    {assinante}
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Cândido Araújo Martins Neto
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    Secretário da Secretaria de Conservação e Serviços Públicos
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Coordenador da COEIP
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          :
          // Página Diferença sem valores
          <Page size="A4" style={styles.page} wrap>
          <View style={[styles.section]}>
            <View style={[styles.rowCabecalho]}>
              <Text style={styles.comeco}></Text>
              <Text style={[styles.titulo, styles.meio]}>
                <Image style={styles.logo} src={cabecalho} />
              </Text>
              <Text style={styles.fim}></Text>
            </View>
            <View style={[styles.espaco]}></View>
            <View style={[styles.espaco]}></View>
            <View style={[styles.rowCabecalho]}>
              <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (C - Diferença)
              </Text>
            </View>
            <View style={[styles.rowCabecalho]}>
              <Text style={[styles.linhaCompletaCentralizada]}>
                Em {moment(data.dataEmpenhoFinal).format('LL')} constatamos,
                após fiscalização desta SCSP, diferenças nos quantitativos dos seguintes orçamentos / AF,
                conforme o que preceitua a cláusula décima do contrato {data.idContrato}, assinado entre as partes.
              </Text>
            </View>
            <View style={[styles.espaco]} fixed></View>
            <View style={[styles.espaco]} fixed></View>
            <View style={styles.table}>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.linha]}></View>
              <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                <Text style={styles.width16}>AF Nº</Text>
                <Text style={styles.width16}>ITEM</Text>
                <Text style={styles.width16}>QUANT. FISCALIZADA</Text>
                <Text style={styles.width16}>DIFERENÇA</Text>
                <Text style={styles.width16}>VALOR</Text>
                <Text style={styles.width16}>VALOR A DEDUZIR (R$)</Text>
              </View>
              <View style={[styles.linha]}></View>
              <View style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                <Text style={[styles.linhaCompletaCentralizada]}>Nenhum resultado encontrado</Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={[styles.espaco]}></View>
              <View style={[styles.linha]}></View>
              <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (C - Entrega Diferença)</Text>
                <Text style={styles.width20}>R$ 0,00</Text>
              </View>
            </View>
            <View style={[styles.espaco]}></View>
            <View style={[styles.espaco]}></View>

            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
              <Text style={styles.comeco}>De Acordo, </Text>
              <Text style={[styles.meio]}></Text>
              <Text style={styles.fim}></Text>
            </View>
            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
              <Text style={[styles.linhaMetadeComeco]}>
                _____________________________________
              </Text>
              <Text style={[styles.linhaMetadeFim]}>
                _____________________________________
              </Text>
            </View>
            <Text style={[styles.meio]}></Text>
            <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
              <Text style={[styles.linhaMetadeComeco]}>
                {assinante}
              </Text>
              <Text style={[styles.linhaMetadeFim]}>
                Cândido Araújo Martins Neto
              </Text>
            </View>
            <Text style={[styles.meio]}></Text>
            <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
              <Text style={[styles.linhaMetadeComeco]}>
                Secretário da Secretaria de Conservação e Serviços Públicos
              </Text>
              <Text style={[styles.linhaMetadeFim]}>
                Coordenador da COEIP
              </Text>
            </View>
          </View>
          <Text style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
          </Page>
      }
      {
        data.listaValorMulta.length > 0 ?

          // Página Multas
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.titulo, styles.meio, styles.top20]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (D - Multas)
                </Text>
              </View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={styles.table}>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                  <Text style={styles.width20}>AF Nº</Text>
                  <Text style={styles.width20}>VALOR DA OBRA (R$)</Text>
                  <Text style={styles.width20}>VALOR DA OBRA (UDS)</Text>
                  <Text style={styles.width20}>VALOR A DEDUZIR (R$)</Text>
                  <Text style={styles.width20}>DESCRIÇÃO</Text>
                </View>
                <View style={[styles.linha]}></View>
                {
                  data.listaValorMulta && data.listaValorMulta.length > 0 ?
                    data.listaValorMulta.map((c, i) => (
                      <View>
                        {
                          (i + 1) % 2 == 0
                            ?
                            <View key={i} style={[styles.row, styles.centralizarTexto]} break>
                              <Text style={styles.width20}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={styles.width20}>{c.valorNotaFiscal}</Text>
                              <Text style={styles.width20}>{c.valorNotaFiscal}</Text>
                              <Text style={styles.width20}>{c.valorMultaReais}</Text>
                              <Text style={[styles.width20, styles.fontSize5]}>{c.descricao}</Text>
                            </View>
                            :
                            <View key={i} style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                              <Text style={styles.width20}>{c.idAutorizacaoFornecimento}</Text>
                              <Text style={styles.width20}>{c.valorNotaFiscal}</Text>
                              <Text style={styles.width20}>{c.valorNotaFiscal}</Text>
                              <Text style={styles.width20}>{c.valorMultaReais}</Text>
                              <Text style={[styles.width20, styles.fontSize5]}>{c.descricao}</Text>
                            </View>
                        }

                        <View style={[styles.linha]}></View>
                      </View>
                    ))
                    :
                    ''
                }
              </View>
              {
                data.listaValores != null && data.listaValores.length > 0 ?
                  <View style={styles.table}>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.linha]}></View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (D - Multas)</Text>
                      <Text style={styles.width20}>{data.listaValores[3]}</Text>
                    </View>
                  </View>
                  :
                  ''
              }
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View wrap={false} style={{marginTop: "auto"}}>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                  <Text style={styles.comeco}>De Acordo, </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    _____________________________________
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    _____________________________________
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    {assinante}
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Cândido Araújo Martins Neto
                  </Text>
                </View>
                <Text style={[styles.meio]}></Text>
                <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    Secretário da Secretaria de Conservação e Serviços Públicos
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    Coordenador da COEIP
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          :

          // Página Multas sem valores
          <Page size="A4" style={styles.page} wrap>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}></Text>
                <Text style={[styles.titulo, styles.meio, styles.top20]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                  TERMO DE RECEBIMENTO DE OBRA - TRO Nº {data.descricaoEmpenho} (D - Multas)
                </Text>
              </View>
              <View style={[styles.espaco]} fixed></View>
              <View style={[styles.espaco]} fixed></View>
              <View style={styles.table}>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.espaco]} fixed></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                  <Text style={styles.width20}>AF Nº</Text>
                  <Text style={styles.width20}>VALOR DA OBRA (R$)</Text>
                  <Text style={styles.width20}>VALOR DA OBRA (UDS)</Text>
                  <Text style={styles.width20}>VALOR A DEDUZIR (R$)</Text>
                  <Text style={styles.width20}>DESCRIÇÃO</Text>
                </View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.cinza, styles.centralizarTexto]} break>
                  <Text style={[styles.linhaCompletaCentralizada]}>Nenhum resultado encontrado</Text>
                </View>
              </View>
              <View style={styles.table}>
                <View style={[styles.espaco]}></View>
                <View style={[styles.linha]}></View>
                <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                  <Text style={styles.width80}>TOTAL DO TRO {data.descricaoEmpenho} (D - Multas)</Text>
                  <Text style={styles.width20}>R$ 0,00</Text>
                </View>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>

              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                <Text style={styles.comeco}>De Acordo, </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom40, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  _____________________________________
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  _____________________________________
                </Text>
              </View>
              <Text style={[styles.meio]}></Text>
              <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom30, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  {assinante}
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Cândido Araújo Martins Neto
                </Text>
              </View>
              <Text style={[styles.meio]}></Text>
              <View style={[styles.rowCabecalho, styles.bold, styles.alinharBottom, styles.bottom5, styles.centralizarTexto]}>
                <Text style={[styles.linhaMetadeComeco]}>
                  Secretário da Secretaria de Conservação e Serviços Públicos
                </Text>
                <Text style={[styles.linhaMetadeFim]}>
                  Coordenador da COEIP
                </Text>
              </View>
            </View>
            <Text style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>

      }

    </Document >
  )
}