import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarManutencoesPorMesResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';
import urlBase from '../../../services/rotas';

function* ListarManutencoesPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/ManutencoesPorMes`, { headers: headerParams.token });

    yield put(ListarManutencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ListarManutencoesPorMesResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* InserirManutencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      "Lista": model.data[0]
    }

    yield call(api.post, `/api/ManutencoesPorMes`, data, { headers: headerParams.token });

    toast.success(`As Manutenções Por Mês foram cadastradas com sucesso!`);

    const result = yield call(api.get, `/api/ManutencoesPorMes`, { headers: headerParams.token });

    yield put(ListarManutencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.status == 500 || e.response.data.status == 400) {
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);
    }

    yield put(ListarManutencoesPorMesResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BaixarPadraoImportacaoManutencoesPorMesRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/ManutencoesPorMes/BaixarPadraoImportacaoManutencoesPorMesRequest`,
      { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (excel) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(excel);
        elem.download = 'Padrão Importação Manutenção por Mês'
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if (e.response != null) {
      if (e.response.data != null) {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);
        }
      }
    }
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ExcluirManutencoesPorMesRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/ManutencoesPorMes/${parseInt(model.ano)}`, { headers: headerParams.token });

    toast.success(`Manutenções do ano: ${model.ano} foram excluidas!`);

    const result = yield call(api.get, `/api/ManutencoesPorMes`, { headers: headerParams.token });

    yield put(ListarManutencoesPorMesResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  
  }
}

export default all([
  takeLatest('LISTAR_MANUTENCOES_POR_MES_REQUEST', ListarManutencoesPorMesRequest),
  takeLatest('INSERIR_MANUTENCOES_POR_MES_REQUEST', InserirManutencoesPorMesRequest),
  takeLatest('BAIXAR_PADRAO_IMPORTACAO_MANUTENCOES_POR_MES_REQUEST', BaixarPadraoImportacaoManutencoesPorMesRequest),
  takeLatest('EXCLUIR_MANUTENCOES_POR_MES_REQUEST', ExcluirManutencoesPorMesRequest)
])