import { Row, Col, Card, Button, } from "react-bootstrap";
import { MdOutlinePerson } from 'react-icons/md'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSearchPlus } from "react-icons/fa";
import { ListarUsuarioRequest, LimparUsuarioRequest } from '../../../store/modules/usuario/actions';
import { Link, useHistory } from "react-router-dom";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import Header from "../../../components/Header/header";
import './listar.css';
import { toast } from "react-toastify";
import Select from 'react-select';

export default function Listar() {

    const dispatch = useDispatch();
    const history = useHistory();

    const listaUsuario = useSelector(state => state.usuario.listaUsuario);

    const [selectedRow, setSelectedRow] = useState(null);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);

    useEffect(() => {

        dispatch(ListarUsuarioRequest());
        dispatch(LimparUsuarioRequest());

    }, [])

    useEffect(() => {
        if (token.role.filter(r => r == "UsuarioVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdOutlinePerson size={25} color={"#000"} /> Usuários
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col md={12}>
                                        <MaterialTable
                                            components={{
                                                Pagination: PatchedPagination,
                                            }}
                                            title={<Link to="/cadastrarusuario"><Button variant="success" style={{ width: '100%' }}>Novo Usuario</Button></Link>}
                                            columns={[
                                                {
                                                    title: "Número Usuário", field: 'id', editable: 'never', defaultSort: 'asc', cellStyle: {
                                                        width: '10%',

                                                    }
                                                },
                                                {
                                                    title: "Nome", field: 'nome', cellStyle: {
                                                        width: '24%'
                                                    }
                                                },
                                                {
                                                    title: "Email", field: 'email', cellStyle: {
                                                        width: '24%'
                                                    }
                                                },
                                                {
                                                    title: "Grupo", field: 'grupoAcesso', cellStyle: {
                                                        width: '14%'
                                                    }
                                                },
                                                {
                                                    title: "Fornecedor/Cliente", field: 'usuarioFornecedor', cellStyle: {
                                                        width: '14%'
                                                    }
                                                },
                                                {
                                                    title: "Status", field: 'status', cellStyle: {
                                                        width: '14%'
                                                    }
                                                },
                                                {
                                                    title: "Usuário Validado", field: 'validado', cellStyle: {
                                                        width: '14%'
                                                    },
                                                }
                                            ]}
                                            data={
                                                listaUsuario != undefined ?
                                                    listaUsuario.map(usuario => {
                                                        return {
                                                            id: usuario.id,
                                                            nome: usuario.nome,
                                                            email: usuario.email,
                                                            grupoAcesso: usuario.grupoAcesso.nomeGrupo,
                                                            status: usuario.ativo ? 'Ativado' : 'Desativado',
                                                            usuarioFornecedor: usuario.usuarioFornecedor ? 'Fornecedor' : 'Cliente',
                                                            validado: usuario.validado ? 'Sim' : 'Não',
                                                        }
                                                    })
                                                    :
                                                    []
                                            }
                                            options={{
                                                minBodyHeight: 500,
                                                paging: true,
                                                pageSize: 10, // Início
                                                emptyRowsWhenPaging: false,
                                                pageSizeOptions: [10, 20, 30, 50],
                                                headerStyle: {
                                                    backgroundColor: '#454545',
                                                    color: '#FFF',
                                                    fontWeight: "bold",
                                                    lineBreak: "auto",
                                                    fontSize: "13px"
                                                },
                                                filterRowStyle: {
                                                    backgroundColor: "#FAFAFA"
                                                },
                                                actionsColumnIndex: -1,
                                                filtering: true,
                                                rowStyle: rowData => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <FaSearchPlus />,
                                                    tooltip: '',
                                                    onClick: (event, rowData) => { history.push(`/alterarusuario/${rowData.id}`); }
                                                }
                                            ]}
                                            style={{
                                                fontSize: "13px",
                                            }}
                                            localization={{
                                                header: {
                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                    editRow: {
                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                                    },
                                                    deleteTooltip: '',
                                                    editTooltip: '',
                                                    addTooltip: ''
                                                },
                                                toolbar: {
                                                    searchTooltip: '',
                                                    searchPlaceholder: 'Pesquisar',
                                                    exportTitle: "",
                                                    exportCSVName: "Exportar como CSV",
                                                    exportPDFName: "Exportar como PDF",
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'linhas',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: '',
                                                    previousTooltip: '',
                                                    nextTooltip: '',
                                                    lastTooltip: ''
                                                },
                                                grouping: {
                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                    groupedBy: 'Agrupado por:'
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}