import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { InserirOrgaoRequest, ListarOrgaoRequest } from "../../store/modules/orgao/actions";


export default function ModalCadastroOrgao({ show, onHide }) {

    const dispatch = useDispatch();
    const listaBairro = useSelector(state => state.bairro.listaBairro);

    const [validated, setValidated] = useState(false);
    const [descricao, setDescricao] = useState('');
    const [ativo, setAtivo] = useState('');
    const [nomeSecretario, setNomeSecretario] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [nomeLogradouro, setNomeLogradouro] = useState('');
    const [numeroLocal, setNumeroLocal] = useState('');
    const [numeroDddFixo, setNumeroDddFixo] = useState('');
    const [numeroTelefoneFixo, setNumeroTelefoneFixo] = useState('');
    const [numeroDdd, setNumeroDdd] = useState('');
    const [numeroTelefone, setNumeroTelefone] = useState('');

    useEffect(() => {
        moment.locale('pt-br');
    }, [dispatch])

    useEffect(() => {
        dispatch(ListarOrgaoRequest())
    }, [])


    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {

            event.preventDefault();
        } else {

            if (ValidacoesInserir(event)) {
                InserirInteressado();
            };

        }

        setValidated(true);
    };

    function ValidacoesInserir(e) {

        e.preventDefault();

        //valida o campo descrição
        if (descricao == null || descricao == undefined
            || descricao.trim() == '' || descricao.length > 100) {
            toast.error(`Orgão inválido`);
            return false;
        }

        return true;

    }

    function InserirInteressado() {

        let data = {
            'Id': 0,
            'Descricao': descricao,
            'Ativo': true,
            'NomeSecretario': nomeSecretario,
            'Cnpj': cnpj,
            'NomeLogradouro': nomeLogradouro,
            'NumeroLocal': numeroLocal,
            'NumeroDddFixo': numeroDddFixo !== '' ? numeroDddFixo : null,
            'NumeroTelefoneFixo': numeroTelefoneFixo,
            'NumeroDdd': numeroDdd !== '' ? numeroDdd : null,
            'NumeroTelefone': setNumeroTelefone,
        }

        dispatch(InserirOrgaoRequest(data))

        setDescricao('');
        setAtivo('');
        setNomeSecretario('');
        setCnpj('');
        setNomeLogradouro('');
        setNumeroLocal('');
        setNumeroDddFixo('');
        setNumeroTelefoneFixo('');
        setNumeroDdd('');
        setNumeroTelefone('');
        onHide();

    }

    return (

        <Modal
            size="xl"
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastrar interessado
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="tamanhoTela">
                        <Col md={12}>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formDescricao">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        type="text"
                                        maxLength={150}
                                        value={descricao}
                                        onChange={e => setDescricao(e.target.value)}
                                        className="InputMask"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formDeputadoVereador">
                                    <Form.Label>Nome Secretário</Form.Label>
                                    <Form.Control
                                        type="text"
                                        maxLength={150}
                                        value={nomeSecretario}
                                        onChange={e => setNomeSecretario(e.target.value)}
                                        className="InputMask"
                                    />
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formBairro">
                                    <Form.Label>CNPJ</Form.Label>
                                    <InputMask
                                        value={cnpj}
                                        onChange={(e) => setCnpj(e.target.value)}
                                        mask={"99.999.999/9999-99"}
                                        id="Juridica"
                                        className="InputMask"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formCep">
                                    <Form.Label>Logradouro</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nomeLogradouro}
                                        onChange={e => setNomeLogradouro(e.target.value)}
                                        className="InputMask"
                                    />
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={12} controlId="formDddTelefone">
                                    <Form.Label>Local</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={numeroLocal}
                                        onChange={e => setNumeroLocal(e.target.value)}
                                        className="InputMask"
                                    />
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={2} controlId="formDddTelefoneFixo">
                                    <Form.Label>DDD Fixo</Form.Label>
                                    <InputMask
                                        defaultValue={numeroDddFixo}
                                        onChange={(e) => setNumeroDddFixo(e.target.value)}
                                        mask={"99"}
                                        id="idDddFixo"
                                        style={{ width: "100%" }}
                                        className="InputMask"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={4} controlId="formTelefoneFixo">
                                    <Form.Label>Telefone Fixo</Form.Label>
                                    <InputMask
                                        defaultValue={numeroTelefoneFixo}
                                        onChange={(e) => setNumeroTelefoneFixo(e.target.value)}
                                        mask={"9999 9999"}
                                        id="idTelefoneFixo"
                                        style={{ width: "100%" }}
                                        className="InputMask"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={2} controlId="formTelefone">
                                    <Form.Label>DDD</Form.Label>
                                    <InputMask
                                        defaultValue={numeroDdd}
                                        onChange={(e) => setNumeroDdd(e.target.value)}
                                        maxLength={2}
                                        id="idDdd"
                                        style={{ width: "100%" }}
                                        className="InputMask"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={4} controlId="formTelefoneFixo">
                                    <Form.Label>Telefone</Form.Label>
                                    <InputMask
                                        defaultValue={numeroTelefone}
                                        onChange={(e) => setNumeroTelefone(e.target.value)}
                                        mask={"9 9999 9999"}
                                        id="idTelefone"
                                        style={{ width: "100%" }}
                                        className="InputMask"
                                    />
                                </Form.Group>

                            </Row>


                        </Col>
                    </div><br />
                    <Col md={12}>
                        <Button type="submit"
                            variant="success" style={{ width: '50%' }}>Cadastrar</Button>
                    </Col>
                </Form>
            </Modal.Body>
        </Modal>
    );
}