import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import {
  Carregando, ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse} from './actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import history from '../../../services/history';

function* ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
 
  }
}

function* InserirMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/listarCompleto`,
     { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Foto id: ' + listaId + ' cadastrada com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
        }
      }

    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
      
  }
}

function* AlterarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Foto cadastrada com sucesso!`);    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ExcluirMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/${model.id}/${'0'}`, { headers: headerParams.token });

    toast.success(`Documento id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.idMedicao) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
 
  }
}

function* BaixarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeRegistroFotografico}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {

     if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* CarregarImagemMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
  
    fetch(`${urlBase}/api/MedicaoAutorizacaoFornecimentoRegistroFotografico/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoMedicaoAutorizacaoFornecimento").appendChild(elem).style.width = "100%";        
        });
    
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', ListarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', InserirMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', AlterarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', ExcluirMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('BAIXAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', BaixarMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('CARREGAR_IMAGEM_MEDICAO_AUTORIZACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', CarregarImagemMedicaoAutorizacaoFornecimentoRegistroFotograficoRequest)
])