import produce from 'immer';

const initialState = {
  listaEmpenhoStatus: undefined,
  listaValidacaoEmpenhoStatus: undefined,
  listaCompletoEmpenhoStatus: undefined,
  listarMultaEmpenho: undefined,
  listarDiferencaEmpenho: undefined,
  carregando: true
};

export default function empenhoStatus(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_EMPENHO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaEmpenhoStatus = action.listaEmpenhoStatus;
      });

    case 'LISTAR_COMPLETO_EMPENHO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletoEmpenhoStatus = action.listaCompletoEmpenhoStatus;
      });

    case 'VALIDAR_EMPENHO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaValidacaoEmpenhoStatus = action.listaValidacaoEmpenhoStatus;
      });

    case 'LIMPAR_LISTA_VALIDACAO_EMPENHO':
      return produce(state, draft => {
        draft.listaValidacaoEmpenhoStatus = undefined
      });

    case 'LISTAR_MULTA_EMPENHO_RESPONSE':
      return produce(state, draft => {
        draft.listarMultaEmpenho = action.listarMultaEmpenho
      });
    
    case 'LISTAR_DIFERENCA_EMPENHO_RESPONSE':
      return produce(state, draft => {
        draft.listarDiferencaEmpenho = action.listarDiferencaEmpenho
      });    
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}