import { Col, Button, Card } from "react-bootstrap";
import { IoDocumentText } from 'react-icons/io5';
import { FaSearchPlus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { ListarClienteRequest } from '../../../store/modules/cliente/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ListarContratoRequest } from '../../../store/modules/contrato/actions';
import { ListarOrigemContratoRequest } from '../../../store/modules/origemContrato/actions';
import { useEffect, useState } from "react";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { useHistory } from "react-router-dom";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import Header from '../../../components/Header/header';
import Loading from '../../../components/Loading/loading';
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';
import './listar.css';

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const carregando = useSelector(state => state.contrato.carregando);
    const listaCliente = useSelector(state => state.cliente.listaCliente);
    const listaOrigemContrato = useSelector(state => state.origemContrato.listaOrigemContrato);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {
        if (token.role.filter(r => r == "ContratoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {

        moment.locale('pt-br');
        dispatch(ListarClienteRequest());
        dispatch(ListarContratoRequest());
        dispatch(ListarOrigemContratoRequest());
        dispatch(ListarContratoUsuarioRequest());

    }, [])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <IoDocumentText size={25} color={"#000"} /> Contratos
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                {
                                    carregando ?
                                        <MaterialTable
                                            components={{
                                                Pagination: PatchedPagination,
                                            }}
                                            title={<Link to="/cadastroGeralContrato"><Button variant="success" style={{ width: '100%' }}>Novo Contrato</Button></Link>}
                                            columns={[
                                                {
                                                    title: 'Número do Contrato', field: 'id', defaultSort: 'desc', cellStyle: {
                                                        width: '10%',
                                                    }
                                                },
                                                {
                                                    title: 'Descrição', field: 'descricao', cellStyle: {
                                                        width: '15%',
                                                    }
                                                },
                                                {
                                                    title: 'Cliente', field: 'idCliente', cellStyle: {
                                                        width: '15%',
                                                    }
                                                },
                                                {
                                                    title: 'Origem', field: 'idOrigemContrato', cellStyle: {
                                                        width: '15%',
                                                    }
                                                },
                                                {
                                                    title: 'Número Origem', field: 'numeroOrigemContrato', cellStyle: {
                                                        width: '15%',
                                                    }
                                                },
                                                {
                                                    title: 'Data Criação', field: 'dataContrato', cellStyle: {
                                                        width: '15%',
                                                    }
                                                },
                                                {
                                                    title: 'Observação', field: 'observacao', cellStyle: {
                                                        width: '15%',
                                                    }
                                                }
                                            ]}
                                            data={
                                                listaContrato != undefined && listaCliente != undefined && listaOrigemContrato != undefined && listaContratoUsuario != undefined ?
                                                    listaContrato.map(contrato => {

                                                        // Para mostrar na lista, verifica se o contrato é privado e se o usuario tem permissão
                                                        if (contrato.privado && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                            && contratoUsu.idContrato === contrato.id).length === 0)) {
                                                            return null
                                                        }
                                                        else {
                                                            return {
                                                                id: contrato.id,
                                                                idCliente: contrato.cliente.nome,
                                                                descricao: contrato.descricao,
                                                                observacao: contrato.observacao,
                                                                numeroOrigemContrato: contrato.numeroOrigemContrato,
                                                                idOrigemContrato:
                                                                    contrato.origemContrato != null ?
                                                                        contrato.origemContrato.descricao
                                                                        : '',
                                                                dataContrato: moment(contrato.dataInclusaoSistema).format('L LT')
                                                            }
                                                        }
                                                    }).filter(Boolean)
                                                    :
                                                    []
                                            }
                                            actions={[
                                                rowData => ({
                                                    icon: () => <FaSearchPlus />,
                                                    onClick: (event, rowData) => { history.push(`/alterarGeralContrato/${rowData.id}`); }
                                                })
                                            ]}
                                            options={{
                                                minBodyHeight: 500,
                                                paging: true,
                                                pageSize: 10, // Início
                                                emptyRowsWhenPaging: false,
                                                pageSizeOptions: [10, 50, 200, 300],
                                                filterRowStyle: {
                                                    backgroundColor: "#FAFAFA"
                                                },
                                                headerStyle: {
                                                    backgroundColor: '#454545',
                                                    color: '#FFF',
                                                    fontWeight: "bold",
                                                    lineBreak: "auto",
                                                    fontSize: "13px"
                                                },
                                                actionsColumnIndex: -1,
                                                filtering: true,
                                                rowStyle: rowData => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })
                                            }}
                                            style={{
                                                lineBreak: "strict",
                                                fontSize: "13px"
                                            }}
                                            localization={{
                                                header: {
                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                    editRow: {
                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                        saveTooltip: '',
                                                        cancelTooltip: '',
                                                    },
                                                    deleteTooltip: '',
                                                },
                                                toolbar: {
                                                    searchTooltip: '',
                                                    searchPlaceholder: 'Pesquisar',
                                                    exportTitle: "",
                                                    exportCSVName: "Exportar como CSV",
                                                    exportPDFName: "Exportar como PDF",
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'linhas',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: '',
                                                    previousTooltip: '',
                                                    nextTooltip: '',
                                                    lastTooltip: ''
                                                },
                                                grouping: {
                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                    groupedBy: 'Agrupado por:'
                                                }
                                            }}
                                        />
                                        :
                                        <Loading />
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}