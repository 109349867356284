import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse } from './actions';
import { BuscarContratoVersaoPorIdResponse } from '../contratoVersao/actions';
import { ListarSolicitacaoFornecimentoVersaoStatusResponse } from '../solicitacaoFornecimentoVersaoStatus/actions';
import { BuscarSolicitacaoFornecimentoPorIdResponse } from '../solicitacaoFornecimento/actions';
import { ListarCompletoSolicitacaoFornecimentoVersaoResponse } from '../solicitacaoFornecimentoVersao/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, CriarTxt, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var data = {
      'IdSolicitacaoFornecimento': model.idSolicitacaoFornecimento
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto`, model.data, { headers: headerParams.token });

    toast.success(`O Item foi incluido com sucesso!`);

    var data = {
      'IdSolicitacaoFornecimento': model.idSolicitacaoFornecimento
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse(result.data.result));

    // Buscar Solicitação
    const resultBusca = yield call(api.get, `/api/SolicitacaoFornecimento/${parseInt(model.idSolicitacaoFornecimento)}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultBusca.data.result));

    // Atualizar historico
    const resultVersao = yield call(api.post, `/api/SolicitacaoFornecimentoVersao/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarCompletoSolicitacaoFornecimentoVersaoResponse(resultVersao.data.result));

    const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
        const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

        yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* AlterarSolicitacaoFornecimentoVersaoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/SolicitacaoFornecimentoVersaoSubitemCusto`, model.data, { headers: headerParams.token });

    toast.success(`Item alterado com sucesso!`);

    var data = {
      'IdSolicitacaoFornecimento': model.idSolicitacaoFornecimento
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSolicitacaoFornecimentoVersaoSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));

    toast.success(`O Item foi excluido com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSolicitacaoFornecimentoVersaoSubitemCustoPorIdSolicitacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/${model.idSolicitacaoFornecimentoVersao}`, { headers: headerParams.token });

    toast.success(`Os Itens foram excluídos com sucesso!`);

    var data = {
      'IdSolicitacaoFornecimento': model.idSolicitacaoFornecimento
    }

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ImportarSolicitacaoFornecimentoVersaoSubitemCustoExcelRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/ImportacaoSolicitacaoSubitemCusto`, model.listaImportacao,
      { headers: headerParams.token });

    toast.success(`O Item foi incluido com sucesso!`);

    var data = {
      'IdSolicitacaoFornecimento': model.idSolicitacaoFornecimento
    }

    // Buscar Solicitação
    const resultBusca = yield call(api.get, `/api/SolicitacaoFornecimento/${parseInt(model.idSolicitacaoFornecimento)}`, { headers: headerParams.token });

    yield put(BuscarSolicitacaoFornecimentoPorIdResponse(resultBusca.data.result));

    // Buscar status
    const resultStatus = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoStatusResponse(resultStatus.data.result));

    // Buscar lista subitem
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoVersaoSubitemCusto/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoVersaoSubitemCustoResponse(result.data.result));

    // Atualizar modal de inserir
    const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));

    // Atualizar historico
    const resultVersao = yield call(api.post, `/api/SolicitacaoFornecimentoVersao/listarCompleto`,
      data, { headers: headerParams.token });

    yield put(ListarCompletoSolicitacaoFornecimentoVersaoResponse(resultVersao.data.result));

    yield put(Carregando(true));

  } catch (e) {

    toast.error("Erro na importação de SubItems");
    yield put(CriarTxt(e.response.data.message));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST', ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest),
  takeLatest('INSERIR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST', InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest),
  takeLatest('ALTERAR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST', AlterarSolicitacaoFornecimentoVersaoSubitemCustoRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST', ExcluirSolicitacaoFornecimentoVersaoSubitemCustoRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_POR_ID_SOLICITACAO_REQUEST', ExcluirSolicitacaoFornecimentoVersaoSubitemCustoPorIdSolicitacaoRequest),
  takeLatest('IMPORTACAO_SOLICITACAO_FORNECIMENTO_VERSAO_SUBITEM_CUSTO_REQUEST', ImportarSolicitacaoFornecimentoVersaoSubitemCustoExcelRequest)
])