import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { VerificarAtivacaoRequest, AtivarUsuarioRequest } from '../../../store/modules/usuario/actions';
import { useDispatch } from "react-redux";
import './ativacaoConta.css';

export default function AtivacaoConta() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const [codigo, setCodigo] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');

    useEffect(() => {
        verificaAtivacao();
    }, [dispatch])

    function verificaAtivacao() {
        dispatch(VerificarAtivacaoRequest(id));
    }

    function validaSenha(senha) {
        const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
        return senhaRegex.test(String(senha))
    }

    function AtivarConta() {

        if (senha !== confirmarSenha) {
            toast.error('As senhas são diferentes');
            return;
        }

        if (!validaSenha(senha)) {
            toast.error('Senha precisar conter números, letras maiúsculas, minúsculas, caracteres especiais e mínimo 8 digitos');
            return;
        }

        let data = {
            'IdUsuario': id,
            'Senha': senha,
            'CodigoAtivacao': codigo
        }

        dispatch(AtivarUsuarioRequest(data));
    }

    return (
        <>
            <Container className="divAtivacaoConta">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <span>
                                    Ative sua conta e cadastre sua senha
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Container className="divBody">
                                    <Row>
                                        <Col md={12}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={12} controlId="formNome">
                                                    <Form.Label>Código enviado para o E-mail</Form.Label>
                                                    <Form.Control type="text" value={codigo} onChange={e => setCodigo(e.target.value)} />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formSenha">
                                                    <Form.Label>Senha para acessar o site</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        value={senha}
                                                        onChange={(e) => setSenha(e.target.value)} />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} controlId="formConfirmarSenha">
                                                    <Form.Label>Confirmar Senha</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        value={confirmarSenha}
                                                        onChange={(e) => setConfirmarSenha(e.target.value)} />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={12} controlId="formNome">
                                                    <Button variant="success" onClick={() => { AtivarConta() }}>Ativar Conta</Button>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}