export function ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(idEmpenho) {
  return {
    type: 'LISTAR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST', idEmpenho
  }
}

export function ListarEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed) {
  return {
    type: 'LISTAR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_RESPONSE',
    listaEmpenhoAutorizacaoFornecimentoSubitemCustoMed
  }
}

export function InserirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(data, idEmpenho, idContrato, idFornecedor) {
  return {
    type: 'INSERIR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST',
    data, idEmpenho, idContrato, idFornecedor
  }
}

export function AlterarEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(data) {
  return {
    type: 'ALTERAR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST',
    data
  }
}

export function ExcluirEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(id, idEmpenho, idContrato, idFornecedor) {
  return {
    type: 'EXCLUIR_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST',
    id, idEmpenho, idContrato, idFornecedor
  }
}

export function ExcluirListaSubitensAutorizacaoMedicaoRequest(listaId, idEmpenho, idContrato, idFornecedor) {
  return {
    type: 'EXCLUIR_LISTA_SUBITENS_AUTORIZACAO_MEDICAO_REQUEST',
    listaId, idEmpenho, idContrato, idFornecedor
  }
}

export function ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedRequest(idEmpenho, idContrato, idFornecedor) {
  return {
    type: 'LISTAR_HIERARQUIA_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_REQUEST',
    idEmpenho, idContrato, idFornecedor
  }
}

export function ListarHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMedResponse(listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed) {
  return {
    type: 'LISTAR_HIERARQUIA_EMPENHO_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MED_RESPONSE',
    listaHierarquiaEmpenhoAutorizacaoFornecimentoSubitemCustoMed
  }
}

export function LimparArvoreSubitem() {
  return {
    type: 'LIMPAR_ARVORE_SUBITEM'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}