import produce from 'immer';

const initialState = {
  listaAutorizacaoFornecimentoStatus: undefined,
  listaCompletoAutorizacaoFornecimentoStatus: undefined,
  carregando: true,
  modalConfirmacaoStatusFinalizado: false
};

export default function autorizacaoFornecimentoStatus(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaAutorizacaoFornecimentoStatus = action.listaAutorizacaoFornecimentoStatus;
      });

    case 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletoAutorizacaoFornecimentoStatus = action.listaCompletoAutorizacaoFornecimentoStatus;
      });

      case 'MODAL_CONFIRMACAO_STATUS_FINALIZADO':
      return produce(state, draft => {
        draft.modalConfirmacaoStatusFinalizado = action.modalConfirmacaoStatusFinalizado;
      });

    case 'LIMPAR_MODAL_CONFIRMACAO_STATUS_FINALIZADO':
      return produce(state, draft => {
        draft.modalConfirmacaoStatusFinalizado = false
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}