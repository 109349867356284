import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { ListarStatusAutorizacaoFornecimentoRequest } from "../../../store/modules/statusAutorizacaoFornecimento/actions";
import { ListarAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/autorizacaoFornecimentoStatus/actions";
import {
    ListarMedicaoAutorizacaoFornecimentoStatusRequest, InserirMedicaoAutorizacaoFornecimentoStatusRequest,
    LimparListaValidacaoMedicao, ReabrirMedicaoAutorizacaoFornecimentoStatusRequest
} from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { BuscarMedicaoAutorizacaoFornecimentoPorIdRequest } from '../../../store/modules/medicaoAutorizacaoFornecimento/actions';
import { BuscarCompletoAutorizacaoFornecimentoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import { ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest } from "../../../store/modules/autorizacaoFornecimentoSubitemCustoMedicao/actions";
import { toast } from 'react-toastify';
import ModalVerificacaoSaldoSubitem from "../../../components/ModalVerificacaoSaldoSubitem/modalVerificacaoSaldoSubitem";
import MaterialTable from 'material-table';
import './status.css';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function Status() {

    const { idAutorizacaoFornecimento, idMedicao } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const medicaoAutorizacaoFornecimentoCompleto = useSelector(state => state.medicaoAutorizacaoFornecimento.medicaoAutorizacaoFornecimentoCompleto);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaAutorizacaoFornecimentoStatus = useSelector(state => state.autorizacaoFornecimentoStatus.listaAutorizacaoFornecimentoStatus);
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);
    const listaValidacaoMedicaoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaValidacaoMedicaoStatus);
    const listaAutorizacaoFornecimentoSubitemCustoMedicao = useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicao.listaAutorizacaoFornecimentoSubitemCustoMedicao);

    const [descricaoObservacaoCancelado, setDescricaoObservacaoCancelado] = useState("");
    const [show, setShow] = useState(false);
    const [showValidacao, setShowValidacao] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showNovoStatus, setShowNovoStatus] = useState(false);
    const [status, setStatus] = useState('');
    const [statusExcluir, setStatusExcluir] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [validated, setValidated] = useState(false);
    const [dados, setDados] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const handleCloseNovoStatus = () => setShowNovoStatus(false);
    const handleShowNovoStatus = () => setShowNovoStatus(true);
    const handleCloseValidacao = () => setShowValidacao(false);
    const handleShowValidacao = () => setShowValidacao(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarStatusAutorizacaoFornecimentoRequest())
        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idMedicao != undefined) {
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(idMedicao));
            dispatch(BuscarMedicaoAutorizacaoFornecimentoPorIdRequest(idMedicao));
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

        if (idAutorizacaoFornecimento != undefined)
            dispatch(ListarAutorizacaoFornecimentoStatusRequest(idAutorizacaoFornecimento));

    }, [idMedicao, idAutorizacaoFornecimento])

    useEffect(() => {
        if (medicaoAutorizacaoFornecimentoCompleto != null) {
            dispatch(BuscarCompletoAutorizacaoFornecimentoRequest(medicaoAutorizacaoFornecimentoCompleto.idAutorizacaoFornecimento));
        }
    }, [medicaoAutorizacaoFornecimentoCompleto])

    useEffect(() => {
        if (listaValidacaoMedicaoStatus != null && listaValidacaoMedicaoStatus != undefined) {
            handleShowValidacao();
        }
    }, [listaValidacaoMedicaoStatus])

    const handleSubmit = (event) => {

        if (descricaoObservacaoCancelado.trim() == '') {
            event.preventDefault();
        } else {
            CancelarMedicao(event);
        }

        setValidated(true);
    };

    function InserirMedicaoStatus() {

        let proximoStatus = '';
        var validaSubitem = true;

        if (listaStatusMedicaoAutorizacaoFornecimento != undefined) {
            proximoStatus = listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.descricao == status);
        }

        // Verificar subitem ao finalizar
        if (listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined) {
            listaAutorizacaoFornecimentoSubitemCustoMedicao.map(subitem => {
                if (subitem.ativo == true && subitem.quantidadeCliente != subitem.quantidadeFornecedor) {
                    validaSubitem = false;
                }
            })
        }

        if (validaSubitem == true) {

            if (proximoStatus.length > 0) {
                let data = {
                    'Id': 0,
                    'IdMedicaoAutorizacaoFornecimento': parseInt(idMedicao),
                    'IdStatusMedicaoAutorizacaoFornecimento': proximoStatus[0].id,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'ObservacaoCancelado': ''
                }

                setDados(data);
                dispatch(InserirMedicaoAutorizacaoFornecimentoStatusRequest(data, idMedicao, false));
                handleCloseStatus();
            }

        } else {
            toast.error("Algum item não esta aprovado!");
        }

    }

    function InserirMedicaoStatusClick(statusParametro) {
        setStatus(statusParametro);
        handleShowStatus();
    }

    function CancelarMedicao(e) {

        e.preventDefault();

        let proximoStatus = [];

        if (listaStatusMedicaoAutorizacaoFornecimento != undefined) {
            proximoStatus = listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true);
        }

        if (proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdMedicaoAutorizacaoFornecimento': parseInt(idMedicao),
                'IdStatusMedicaoAutorizacaoFornecimento': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'ObservacaoCancelado': descricaoObservacaoCancelado
            }

            dispatch(InserirMedicaoAutorizacaoFornecimentoStatusRequest(data, idMedicao, true));
            setDescricaoObservacaoCancelado('');
            setValidated(false);
            handleClose();
        }
    }

    function InserirNovoStatusMedicao() {

        let proximoStatus = [];

        if (listaStatusMedicaoAutorizacaoFornecimento != undefined) {
            proximoStatus = listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.iniciaMedicao == true);
        }

        if (proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdMedicaoAutorizacaoFornecimento': parseInt(idMedicao),
                'IdStatusMedicaoAutorizacaoFornecimento': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'ObservacaoCancelado': ''
            }

            dispatch(InserirMedicaoAutorizacaoFornecimentoStatusRequest(data, idMedicao, true));
            setValidated(false)
        }

        handleCloseNovoStatus();
    }

    function InserirMedicaoStatusCancelado(statusParametro) {
        setStatus(statusParametro);
        handleShow();
    }

    function ResetarStatus(idStatus) {
        setStatusExcluir(idStatus);
        handleShowNovoStatus();
    }

    function ReabrirMedicao() {
        dispatch(ReabrirMedicaoAutorizacaoFornecimentoStatusRequest(
            {
                'IdMedicaoAutorizacaoFornecimento': idMedicao,
                'IdUsuario': usuario.id
            }, idAutorizacaoFornecimento
        ))
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                <div>
                                    {
                                        inserir == true ?
                                            (idMedicao != undefined && idAutorizacaoFornecimento != undefined &&
                                                autorizacaoFornecimentoCompleto != null &&
                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                                listaContratoUsuario != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined &&
                                                listaStatusMedicaoAutorizacaoFornecimento != undefined
                                                ?
                                                // Verifica se o usuário tem permissao de cancelar
                                                (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                    && contratoUsu.idContrato === autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato
                                                    && contratoUsu.cancelaMedicao === true).length === 0)
                                                    ? ''
                                                    :
                                                    // Verifica se medição esta cancelada ou finalizada
                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                                        listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                                        ||
                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                        listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                                        ?
                                                        ''
                                                        :
                                                        <Button
                                                            variant={"danger"}
                                                            style={{ width: '100%' }}
                                                            onClick={() => InserirMedicaoStatusCancelado("Cancelado")}>
                                                            Cancelar Medição
                                                        </Button>
                                                :
                                                null)
                                            :
                                            ''
                                    }
                                    {
                                        inserir == true ?
                                            ((idMedicao != undefined) && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0 ?
                                                <Button
                                                    variant={"danger"}
                                                    style={{ width: '100%' }}
                                                    onClick={() => ReabrirMedicao()}>
                                                    Reabrir Medição
                                                </Button>
                                                :
                                                '')
                                            :
                                            ''
                                    }
                                </div>
                            }
                            columns={[
                                {
                                    title: 'Status', field: 'status', cellStyle: {
                                        width: '40%'
                                    }
                                },
                                {
                                    title: 'Nome', field: 'usuario', cellStyle: {
                                        width: '20%'
                                    }
                                },
                                {
                                    title: 'Data', field: 'dataInclusao', cellStyle: {
                                        width: '20%'
                                    }
                                },
                                {
                                    title: 'Ação', field: 'aprovacao', cellStyle: {
                                        width: '20%'
                                    }
                                }
                            ]}
                            data={
                                idMedicao != undefined &&
                                    listaStatusMedicaoAutorizacaoFornecimento != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined ?
                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento != 5).map(status => {
                                        return {
                                            observacao: status.observacaoCancelado,
                                            status:
                                                status.statusMedicaoAutorizacaoFornecimento != null &&
                                                    status.statusMedicaoAutorizacaoFornecimento.cancelaMedicao == true ?
                                                    <div className="divStatusDanger">{status.statusMedicaoAutorizacaoFornecimento.descricao}</div>
                                                    : <div className="divStatusGreen">{status.statusMedicaoAutorizacaoFornecimento.descricao}</div>,
                                            usuario:
                                                status.usuario != null &&
                                                status.usuario.nome,
                                            dataInclusao: moment(status.dataStatus).format('L LT'),
                                            aprovacao:
                                                // Se o status da medição for cancelado, libera o botão para reativar
                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                                    listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0 ?
                                                    status.statusMedicaoAutorizacaoFornecimento != null &&
                                                        status.statusMedicaoAutorizacaoFornecimento.cancelaMedicao == true ?
                                                        <Button
                                                            disabled={
                                                                idAutorizacaoFornecimento != undefined &&
                                                                    autorizacaoFornecimentoCompleto != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                                                    listaContratoUsuario != undefined
                                                                    ?
                                                                    // Ve se o usuário possui permissao de reativar medição (cancelaMedicao)
                                                                    (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                        && contratoUsu.idContrato === autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato
                                                                        && contratoUsu.cancelaMedicao === true).length === 0)
                                                                        ? true
                                                                        :
                                                                        // Verifica se a autorização esta cancelada ou finalizada, se sim, a medição não poderá ser reativada
                                                                        (listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined) &&
                                                                            listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                                listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0
                                                                            && idAutorizacaoFornecimento != undefined
                                                                            ||
                                                                            (listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined) &&
                                                                            listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                                listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0
                                                                            && idAutorizacaoFornecimento != undefined
                                                                            ?
                                                                            true
                                                                            :
                                                                            false
                                                                    :
                                                                    null
                                                            }
                                                            variant="success"
                                                            onClick={() => ResetarStatus(status.id)}
                                                        >Reativar Medição</Button>
                                                        :
                                                        ''
                                                    :
                                                    ''
                                        }
                                    }).concat(
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.id != 5).map(statusSol => {
                                            if (listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                            ) {
                                                return null
                                            } else {
                                                if (statusSol.idStatusMedicaoAutorizacaoFornecimentoAnterior == null || (listaMedicaoAutorizacaoFornecimentoStatus.
                                                    filter(x => x.idStatusMedicaoAutorizacaoFornecimento == statusSol.id))[0] != undefined) {
                                                    return null
                                                } else {
                                                    return {
                                                        id: '',
                                                        status: <div className="divStatusNormal">{statusSol.descricao}</div>,
                                                        usuario: '',
                                                        dataInclusao: '',
                                                        aprovacao:
                                                            // Verifica se o status atual é o anterior dele, se sim, libera o botao de mudar status
                                                            (listaMedicaoAutorizacaoFornecimentoStatus.filter
                                                                (x => x.idStatusMedicaoAutorizacaoFornecimento == statusSol.idStatusMedicaoAutorizacaoFornecimentoAnterior).length > 0)
                                                                ?
                                                                <Button
                                                                    variant="success"
                                                                    disabled={
                                                                        autorizacaoFornecimentoCompleto != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != undefined && listaContratoUsuario != undefined ?
                                                                            statusSol.permissaoFinalizar == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                && contratoUsu.idContrato === autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato && contratoUsu.finalizaMedicao === true).length === 0)
                                                                                ? true : false
                                                                            :
                                                                            null
                                                                    }
                                                                    onClick={() => InserirMedicaoStatusClick(statusSol.descricao)}>
                                                                    Mudar Status
                                                                </Button>
                                                                :
                                                                ''
                                                    }
                                                }
                                            }
                                        }).filter(Boolean)
                                    )
                                    :
                                    []
                            }
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                filtering: true,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'error',
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Obervação Cancelado: ' + rowData.observacao}</span>,
                                    hidden: rowData.observacao == '' || rowData.observacao == undefined ? true : false,
                                })
                            ]}
                            localization={{
                                header: {
                                    actions: 'Info'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: 'Pesquisar'
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                </Col>
            </div >

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Cancelar essa Solicitação?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricaoObservacaoCancelado}
                                        onChange={e => setDescricaoObservacaoCancelado(e.target.value)}
                                        required
                                        maxLength="150"
                                        isInvalid={validated && descricaoObservacaoCancelado.trim() === "" ? true : false}
                                        isValid={validated && descricaoObservacaoCancelado.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={handleSubmit}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleClose}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatus} onHide={handleCloseStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Mudar de Status?
                                {
                                    listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined &&
                                        listaAutorizacaoFornecimentoSubitemCustoMedicao.filter(x => x.ativo == false) ?
                                        ' O item que tiver inativo será descartado.'
                                        :
                                        ''
                                }

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={InserirMedicaoStatus}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNovoStatus} onHide={handleCloseNovoStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Ao avançar o status irá para o Inicial, deseja prosseguir?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={InserirNovoStatusMedicao}>
                                Sim
                            </Button>
                            :
                            ''
                    }
                    <Button variant="danger" onClick={handleCloseNovoStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalVerificacaoSaldoSubitem
                show={showValidacao}
                onHide={handleCloseValidacao}
                solicitacao={false}
                medicao={true}
                empenho={false}
            />

        </>
    )
}