import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const SubMenu = ({ item }) => {

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      {
        (JSON.parse(localStorage.getItem('token')).token.role.filter(r => r == `${item.permissao}Menu`).length == 1)?
        <Link id={item.id} className="menuLateral_link" to={item.path} onClick={item.subNav && showSubnav}>

          <div>
            {item.icon}
            <SidebarLabel className='textoMenu'>{item.title}</SidebarLabel>
          </div>

          <div>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
                ? item.iconClosed
                : null}
          </div>
        </Link>
        :
        ''
      }
      {
        subnav && item.subNav != null && item.subNav != undefined &&
        item.subNav.map((item, index) => {
          return (

            (JSON.parse(localStorage.getItem('token')).token.role.filter(r => r == `${item.permissao}Menu`).length == 1) ?
              <Link className="menuLateral_sub_link" to={item.path} key={index}>
                <div>
                  {item.icon}
                  <SidebarLabel>{item.title}</SidebarLabel>
                </div>
              </Link>
            :
              ''

          );
        })}
    </>
  );
};

export default SubMenu;