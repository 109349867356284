import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarFonteReceitaVersaoResponse, BuscarFonteReceitaVersaoPorIdResponse, ListarPorUltimaVersaoAnoResponse, Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarFonteReceitaVersaoRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FonteReceitaVersao`, { headers: headerParams.token });

    yield put(ListarFonteReceitaVersaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ListarFonteReceitaVersaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* BuscarFonteReceitaVersaoPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FonteReceitaVersao/${model.id}`, { headers: headerParams.token });

    yield put(BuscarFonteReceitaVersaoPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(BuscarFonteReceitaVersaoPorIdResponse(null));
    yield put(Carregando(true));    

  }
}

function* InserirFonteReceitaVersaoRequest(model) {

  try {  

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/FonteReceitaVersao`, model.data, { headers: headerParams.token });
    
    const result = yield call(api.get, `/api/FonteReceitaVersao/listarporultimaversaoano/${model.data.IdFonteReceita}`,
      { headers: headerParams.token });

    yield put(ListarPorUltimaVersaoAnoResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Fonte Receita Versao foi cadastrada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(Carregando(true));   
  }
}

function* AlterarFonteReceitaVersaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/FonteReceitaVersao`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/FonteReceitaVersao/listarporultimaversaoano/${model.data.IdFonteReceita}`,
      { headers: headerParams.token });

    yield put(ListarPorUltimaVersaoAnoResponse(result.data.result));
    yield put(Carregando(true));    

    toast.success(`Fonte Receita Versao foi atualizada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(Carregando(true));    
  }
}

function* ExcluirFonteReceitaVersaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/FonteReceitaVersao/${model.id}`, { headers: headerParams.token });

    toast.success(`Fonte Receita Versao id: ${model.id} foi excluida!`);

    const result = yield call(api.get, `/api/FonteReceitaVersao/listarporultimaversaoano/${model.idFonteReceita}`,
      { headers: headerParams.token });

    yield put(ListarPorUltimaVersaoAnoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(Carregando(true));    
  }
}

function* ListarPorUltimaVersaoAnoRequest(model) {

  try {
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    const result = yield call(api.get, `/api/FonteReceitaVersao/listarporultimaversaoano/${model.idFonte}`,
      { headers: headerParams.token });

    yield put(ListarPorUltimaVersaoAnoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if (e.response != null && e.response.data != null) {
      if(e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }

    yield put(ListarFonteReceitaVersaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_FONTE_RECEITA_VERSAO_REQUEST', ListarFonteReceitaVersaoRequest),
  takeLatest('BUSCAR_FONTE_RECEITA_VERSAO_POR_ID_REQUEST', BuscarFonteReceitaVersaoPorIdRequest),
  takeLatest('INSERIR_FONTE_RECEITA_VERSAO_REQUEST', InserirFonteReceitaVersaoRequest),
  takeLatest('ALTERAR_FONTE_RECEITA_VERSAO_REQUEST', AlterarFonteReceitaVersaoRequest),
  takeLatest('EXCLUIR_FONTE_RECEITA_VERSAO_REQUEST', ExcluirFonteReceitaVersaoRequest),
  takeLatest('LISTAR_POR_ULTIMA_VERSAO_ANO_REQUEST', ListarPorUltimaVersaoAnoRequest)
])