import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse, Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import history from '../../../services/history';

function* ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(model){

  try {
    
    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimentoOcorrencia: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse(result.data.result)); 
    yield put(Carregando(true));    
    
  }catch(e){
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));
 
  }
}

function* InserirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimentoOcorrencia: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse(result.data.result)); 
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Fotos id: ' + listaId + ' cadastrada com sucesso!');
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);  
            }    
          }
        }
      }
  
    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));

  }
}

function* AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(model){

  try {

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto`, model.data, { headers:headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimentoOcorrencia: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse(result.data.result)); 
    yield put(Carregando(true));    
  
    toast.success(`Ocorrência Registro Fotográfico foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);  
            }    
          }
        }
      }
  
    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));
  }
}

function* ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(model){

  try{

    yield put(Carregando(false));     
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/${model.idFoto}/${'0'}`, { headers:headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimentoOcorrencia: parseInt(model.data) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse(result.data.result)); 
    yield put(Carregando(true));    

    toast.success(`Ocorrência Registro Fotográfico id: ${model.idFoto} foi excluido!`);
  
  } catch (e) {
    
    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
       
    yield put(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoResponse([]));
    yield put(Carregando(true));    
    yield put(ErrorResponse(e));

  }
}

function* CarregarImagemMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    fetch(`${urlBase}/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto").appendChild(elem).style.width = "100%";        
        });
  
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* BaixarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeRegistroFotografico}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/MedicaoAutorizacaoFornecimentoOcorrenciaRegFoto/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST', ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest),
  takeLatest('INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST', InserirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest),
  takeLatest('EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST', ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest),
  takeLatest('ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST', AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest),
  takeLatest('CARREGAR_IMAGEM_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST', CarregarImagemMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest),
  takeLatest('BAIXAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_OCORRENCIA_REG_FOTO_REQUEST', BaixarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest),
])