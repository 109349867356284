import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Dashboard from "../pages/PageDashboard/dashboard";
import Erro from "../pages/PageErro/erro";
import Login from "../pages/PageLogin/Login/login";
import Grupo from '../pages/PageGrupoAcesso/Listar/listar';
import Usuario from '../pages/PageUsuario/Listar/listar';
import PermissoesGrupo from '../pages/PageGrupoAcesso/Permissao/permissao';
import Perfil from '../pages/PageUsuario/Perfil/perfil';
import AtivacaoConta from '../pages/PageLogin/AtivacaoConta/ativacaoConta';
import CadastrarUsuario from '../pages/PageUsuario/Usuario/usuario';
import Contrato from '../pages/PageContrato/Listar/listar';
import CadastroGeralContrato from '../pages/PageContrato/Abas/abas';
import VisualizarContratoCompleto from '../pages/PageContrato/Visualizar/visualizar';
import FonteReceita from '../pages/PageFonteReceita/Listar/listar';
import CadastroGeralFonte from '../pages/PageFonteReceita/Abas/abas';
import UnidadeMedida from '../pages/PageAuxiliar/UnidadeMedida/unidadeMedida';
import ComposicaoCusto from '../pages/PageAuxiliar/ComposicaoCusto/composicaoCusto';
import TipoRecurso from '../pages/PageAuxiliar/TipoRecurso/tipoRecurso';
import CadastrarGeralSolicitacao from '../pages/PageSolicitacaoFornecimento/Abas/abas';
import Regiao from '../pages/PageAuxiliar/Regiao/regiao';
import TipoSolicitacao from '../pages/PageAuxiliar/TipoSolicitacao/tipoSolicitacao';
import SolicitacaoFornecimento from '../pages/PageSolicitacaoFornecimento/Listar/listar';
import TipoDocumentoArquivo from '../pages/PageAuxiliar/TipoDocumentoArquivo/tipoDocumentoArquivo';
import AutorizacaoFornecimento from '../pages/PageAutorizacaoFornecimento/Listar/listar';
import CadastroGeralAutorizacao from '../pages/PageAutorizacaoFornecimento/Abas/abas';
import CadastrarGeralMedicao from '../pages/PageMedicaoAutorizacaoFornecimento/Abas/abas';
import CadastrarGeralSubitem from '../pages/PageMedicaoAutorizacaoFornecimentoSubItem/Abas/abas';
import Fornecedor from '../pages/PageFornecedor/Listar/listar';
import CadastroGeralFornecedor from '../pages/PageFornecedor/Abas/abas';
import Prioridade from '../pages/PageAuxiliar/Prioridade/prioridade';
import Empenho from '../pages/PageEmpenho/Listar/listar';
import CadastrarGeralEmpenho from '../pages/PageEmpenho/Abas/abas';
import MedicaoAutorizacaoFornecimento from '../pages/PageMedicaoAutorizacaoFornecimento/Listar/listar';
import CadastroGeralEmpenho from '../pages/PageEmpenho/Abas/abas';
import Faturamento from '../pages/PageFaturamento/Faturamento/faturamento';
import Relatorio from '../pages/PageRelatorio/RelatoriosGerais/relatorio';
import CategoriaItem from '../pages/PageAuxiliar/CategoriaItem/categoriaItem';
import NotFound from "../pages/PageNotFound/notFound";
import BemVindo from '../pages/PageBemVindo/bemVindo';
import Interessado from '../pages/PageAuxiliar/Interessado/interessado';
import Orgao from '../pages/PageAuxiliar/Orgao/orgao';
import Assinante from '../pages/PageAuxiliar/Assinante/assinante';
import HistoricoSolicitacao from '../pages/PageSolicitacaoFornecimento/HistoricoSolicitacao/historicoSolicitacao';
import RelatorioStatusSolicitacao from '../pages/PageRelatorio/RelatorioStatusSolicitacao/listar';
import RelatorioStatusAutorizacao from '../pages/PageRelatorio/RelatorioStatusAutorizacao/relatorioStatusAutorizacao';
import RelatorioStatusMedicao from '../pages/PageRelatorio/RelatorioStatusMedicao/relatorioStatusMedicao';
import RelatorioStatusEmpenho from '../pages/PageRelatorio/RelatorioStatusEmpenho/relatorioStatusEmpenho';
import RelatorioSolicitacao from '../pages/PageRelatorio/RelatorioSolicitacao/relatorioSolicitacao';
import ObrasIntervencoesPorMes from '../pages/PageObrasIntervencoesPorMes/Listar/listar';
import Meses from '../pages/PageAuxiliar/Meses/meses';
import ManutencoesPorMes from '../pages/PageRelatorio/RelatorioManutencoesPorMes/relatorioManutencoesPorMes';

export default function Routes() {

    return (
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/bemVindo" component={BemVindo} isPrivate/>
            <Route path="/dashboard" component={Dashboard} isPrivate />
            <Route path="/grupoAcesso" component={Grupo} isPrivate />
            <Route path="/usuario" component={Usuario} isPrivate />
            <Route path="/cadastrarUsuario" component={CadastrarUsuario} isPrivate />
            <Route path="/alterarUsuario/:id" component={CadastrarUsuario} isPrivate />
            <Route path="/permissoesGrupo/:id" component={PermissoesGrupo} isPrivate />
            <Route path="/perfil" component={Perfil} isPrivate />
            <Route path="/ativacaoConta/:id" component={AtivacaoConta} />
            <Route path="/contrato" component={Contrato} isPrivate />
            <Route path="/empenho" component={Empenho} isPrivate />
            <Route path="/cadastrarGeralEmpenho" component={CadastrarGeralEmpenho} isPrivate />
            <Route path="/alterarGeralContrato/:id" component={CadastroGeralContrato} isPrivate />
            <Route path="/cadastroGeralContrato/" component={CadastroGeralContrato} isPrivate />
            <Route path="/visualizarContratoCompleto/" component={VisualizarContratoCompleto} isPrivate />
            <Route path="/fonteReceita" component={FonteReceita} isPrivate />
            <Route path="/alterarGeralFonteReceita/:id" component={CadastroGeralFonte} isPrivate />
            <Route path="/cadastroGeralFonteReceita" component={CadastroGeralFonte} isPrivate />
            <Route path="/unidadeMedida" component={UnidadeMedida} isPrivate />
            <Route path="/composicaoCusto" component={ComposicaoCusto} isPrivate />
            <Route path="/tipoRecurso" component={TipoRecurso} isPrivate />
            <Route path="/cadastroGeralSolicitacao" component={CadastrarGeralSolicitacao} isPrivate />
            <Route path="/alterarGeralSolicitacao/:id" component={CadastrarGeralSolicitacao} isPrivate />
            <Route path="/regiao" component={Regiao} isPrivate />
            <Route path="/tipoSolicitacao" component={TipoSolicitacao} isPrivate />
            <Route path="/solicitacaoFornecimento" component={SolicitacaoFornecimento} isPrivate />
            <Route path="/tipoDocumentoArquivo" component={TipoDocumentoArquivo} isPrivate />
            <Route path='/autorizacaoFornecimento' component={AutorizacaoFornecimento} isPrivate />
            <Route path="/alterarGeralAutorizacao/:idAutorizacaoFornecimento" component={CadastroGeralAutorizacao} isPrivate />
            <Route path="/alterarGeralMedicao/:idMedicao/:idAutorizacaoFornecimento" component={CadastrarGeralMedicao} isPrivate />
            <Route path="/medicaoAutorizacaoFornecimento" component={MedicaoAutorizacaoFornecimento} isPrivate />
            <Route path="/cadastrarGeralMedicao/:idAutorizacaoFornecimento" component={CadastrarGeralMedicao} isPrivate />
            <Route path="/cadastrarGeralSubitem/:idMedicao/:idAutorizacaoFornecimento" component={CadastrarGeralSubitem} isPrivate />
            <Route path="/alterarGeralSubitem/:idMedicaoSubItem/:idMedicao/:idAutorizacaoFornecimento" component={CadastrarGeralSubitem} isPrivate />
            <Route path="/fornecedor" component={Fornecedor} isPrivate />
            <Route path="/alterarGeralFornecedor/:id" component={CadastroGeralFornecedor} isPrivate />
            <Route path="/cadastroGeralFornecedor/" component={CadastroGeralFornecedor} isPrivate />
            <Route path="/prioridade" component={Prioridade} isPrivate />
            <Route path="/cadastroGeralEmpenho" component={CadastroGeralEmpenho} isPrivate />
            <Route path="/alterarGeralEmpenho/:id" component={CadastroGeralEmpenho} isPrivate />
            <Route path="/faturamento" component={Faturamento} isPrivate />
            <Route path="/relatorio" component={Relatorio} isPrivate />
            <Route path="/categoriaItem" component={CategoriaItem} isPrivate />
            <Route path="/interessado" component={Interessado} isPrivate />
            <Route path="/orgao" component={Orgao} isPrivate />
            <Route path="/assinante" component={Assinante} isPrivate />
            <Route path="/historicoSolicitacao/:id" component={HistoricoSolicitacao} isPrivate />
            <Route path="/relatorioStatusSolicitacao" component={RelatorioStatusSolicitacao} isPrivate />
            <Route path="/relatorioStatusAutorizacao" component={RelatorioStatusAutorizacao} isPrivate />
            <Route path="/relatorioStatusMedicao" component={RelatorioStatusMedicao} isPrivate />
            <Route path="/relatorioStatusEmpenho" component={RelatorioStatusEmpenho} isPrivate />
            <Route path="/relatorioSolicitacao" component={RelatorioSolicitacao} isPrivate />
            <Route path="/obrasIntervencoesPorMes" component={ObrasIntervencoesPorMes} isPrivate />
            <Route path="/manutencoesPorMes" component={ManutencoesPorMes} isPrivate />
            <Route path="/meses" component={Meses} isPrivate />
            <Route path="/erro" component={Erro} isPrivate/>
            <Route path="*" component={NotFound} />
        </Switch>
    );
}