import { useEffect, useState } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BuscarMedicaoAutorizacaoFornecimentoPorIdRequest, LimparMedicao } from "../../../store/modules/medicaoAutorizacaoFornecimento/actions";
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarAutorizacaoFornecimentoSubitemCustoRequest } from "../../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { toast } from "react-toastify";
import Loading from '../../../components/Loading/loading';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function MedicaoAutorizacaoFornecimento() {

    const { idMedicao } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const medicaoAutorizacaoFornecimentoCompleto = useSelector(state => state.medicaoAutorizacaoFornecimento.medicaoAutorizacaoFornecimentoCompleto);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);

    const [numeroMedicaoAF, setNumeroMedicaoAF] = useState("");
    const [idUsuario, setIdUsuario] = useState("");
    const [observacao, setObservacao] = useState("");
    const [numeroAf, setNumeroAf] = useState("");
    const [dataInclusao, setDataInclusao] = useState("");
    const [carregandoTime, setCarregandoTime] = useState(false);
    const [valorObra, setValorObra] = useState('');
    const [valorTotalMedicao, setValorTotalMedicao] = useState(0);


    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "MedicaoAutorizacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idMedicao !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
            dispatch(BuscarMedicaoAutorizacaoFornecimentoPorIdRequest(idMedicao));
        }

    }, [])

    useEffect(() => {

        var valorObraAtual = 0;

        if (listaAutorizacaoFornecimentoSubitemCusto != undefined) {

            listaAutorizacaoFornecimentoSubitemCusto.map(lista => {

                valorObraAtual += (lista.subitemCusto.valorUnitario * lista.quantidade)
            })

        }
        setValorObra("R$ " + valorObraAtual.toFixed(2));

    }, [listaAutorizacaoFornecimentoSubitemCusto])

    useEffect(() => {

        if (medicaoAutorizacaoFornecimentoCompleto != null && medicaoAutorizacaoFornecimentoCompleto.idAutorizacaoFornecimento != null) {
            var data = {
                'IdAutorizacaoFornecimento': parseInt(medicaoAutorizacaoFornecimentoCompleto.idAutorizacaoFornecimento)
            }

            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));
        }

        if (medicaoAutorizacaoFornecimentoCompleto != null && medicaoAutorizacaoFornecimentoCompleto.usuario != null
            && medicaoAutorizacaoFornecimentoCompleto.id == parseInt(idMedicao) && medicaoAutorizacaoFornecimentoCompleto.autorizacaoFornecimento !== undefined &&
            medicaoAutorizacaoFornecimentoCompleto.autorizacaoFornecimento !== null) {
            carregarMedicaoAutorizacaoFornecimento();
        }

    }, [medicaoAutorizacaoFornecimentoCompleto])

    useEffect(() => () =>
        dispatch(LimparMedicao())
        , []);

    function carregarMedicaoAutorizacaoFornecimento() {
        setNumeroMedicaoAF(medicaoAutorizacaoFornecimentoCompleto.id);
        setIdUsuario(medicaoAutorizacaoFornecimentoCompleto.usuario.nome);
        setObservacao(medicaoAutorizacaoFornecimentoCompleto.observacao);
        setNumeroAf(medicaoAutorizacaoFornecimentoCompleto.autorizacaoFornecimento.numeroAf);
        setDataInclusao(medicaoAutorizacaoFornecimentoCompleto.dataMedicao);
        setValorTotalMedicao("R$ " + medicaoAutorizacaoFornecimentoCompleto.valorTotalMedicao.toFixed(2));
        setCarregandoTime(true);
    }

    return (
        <>
            {
                carregandoTime ?
                    <div>
                        <Form>
                            <div className="tamanhoTela">
                                <Row className="mb-3" type='hidden'>
                                    <Form.Group as={Col} md={6} controlId="formNumeroMedicaoAF">
                                        <Form.Label>Número Medição</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroMedicaoAF}
                                            onChange={(e) => setNumeroMedicaoAF(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formNumeroMedicaoAF">
                                        <Form.Label>Número AF</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroAf}
                                            onChange={(e) => setNumeroAf(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="formUsuario">
                                        <Form.Label>Usuario</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={idUsuario}
                                            onChange={(e) => setIdUsuario(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formData">
                                        <Form.Label>Data Inclusão</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                medicaoAutorizacaoFornecimentoCompleto != undefined ?
                                                    moment(medicaoAutorizacaoFornecimentoCompleto.dataMedicao).format('L LT')
                                                    :
                                                    ''
                                            }
                                            onChange={(e) => setDataInclusao(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3" type='hidden'>
                                    <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                        <Form.Label>Valor da Obra</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={valorObra}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                        <Form.Label>Valor da Medição</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={valorTotalMedicao}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3" type='hidden'>
                                    <Form.Group as={Col} md={6} controlId="formObservacao">
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={observacao}
                                            maxLength="500"
                                            onChange={(e) => setObservacao(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>
                            </div>
                        </Form >
                        <Col md={12}>
                            {
                                idMedicao == undefined
                                    ?
                                    ''
                                    :
                                    (idMedicao != undefined) && (listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined) &&
                                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                                        listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                                        ?
                                        <Col md={12}>
                                            <Alert variant={`danger`} style={{ width: '100%' }}>
                                                A Medição está Cancelada!
                                            </Alert>
                                        </Col>
                                        :
                                        (idMedicao != undefined) && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined &&
                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                            listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                            ?
                                            <Col md={12}>
                                                <Alert variant={`success`} style={{ width: '100%' }}>
                                                    A Medição está Finalizada!
                                                </Alert>
                                            </Col>
                                            :
                                            ''
                            }
                        </Col>
                    </div>
                    :
                    <Loading />
            }
        </>
    )
}