import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarSubitemCustoResponse, ListarSubitemCustoCompletoResponse } from './actions';
import { BuscarContratoPorIdRequest } from '../contrato/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import {  Carregando } from '../contrato/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarSubitemCustoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/SubitemCusto`, { headers: headerParams.token });

    yield put(ListarSubitemCustoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarSubitemCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirSubitemCustoRequest(model) {

  try {
    
    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/SubItemCusto`, model.data, { headers: headerParams.token });

    yield put(BuscarContratoPorIdRequest(model.idContrato));

    var data = {
      'IdContrato': parseInt(model.data.IdContrato)
    }

    // Listar subitem completo
    const result = yield call(api.post, `/api/subItemCusto/listarCompleto`, data, { headers: headerParams.token });

    if (result.data.result == null) {
      yield put(ListarSubitemCustoCompletoResponse([]));
    } else {
      yield put(ListarSubitemCustoCompletoResponse(result.data.result));
    }

    yield put(Carregando(true));

    toast.success(`SubItem foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/SubItemCusto`, model.data, { headers: headerParams.token });

    /*
    const result = yield call(api.post, `/api/subItemCusto/listar`, { IdContratoVersao: model.idContratoVersao },
      { headers: headerParams.token });

    if (result.data.result == null) {
      yield put(ListarSubitemCustoCompletoResponse([]));
    } else {
      yield put(ListarSubitemCustoCompletoResponse(result.data.result));
    }*/
      yield put(BuscarContratoPorIdRequest(model.idContrato));
    yield put(Carregando(true));

    toast.success(`Subitem foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSubitemCustoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/SubItemCusto/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));

    toast.success(`Subitem id: ${model.id} foi excluido!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarSubitemCustoCompletoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/subItemCusto/listarCompleto`, model.data, { headers: headerParams.token });

    if (result.data.result == null) {
      yield put(ListarSubitemCustoCompletoResponse([]));
    } else {
      yield put(ListarSubitemCustoCompletoResponse(result.data.result));
    }

    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarSubitemCustoCompletoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_SUBITEM_CUSTO_REQUEST', ListarSubitemCustoRequest),
  takeLatest('INSERIR_SUBITEM_CUSTO_REQUEST', InserirSubitemCustoRequest),
  takeLatest('ALTERAR_SUBITEM_CUSTO_REQUEST', AlterarSubitemCustoRequest),
  takeLatest('EXCLUIR_SUBITEM_CUSTO_REQUEST', ExcluirSubitemCustoRequest),
  takeLatest('LISTAR_SUBITEM_CUSTO_COMPLETO_REQUEST', ListarSubitemCustoCompletoRequest)
])