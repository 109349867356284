export function ListarTipoContratoVersaoRequest() {
  return {
    type: 'LISTAR_TIPO_CONTRATO_VERSAO_REQUEST'
  }
}

export function ListarTipoContratoVersaoResponse(listaTipoContratoVersao) {
  return {
    type: 'LISTAR_TIPO_CONTRATO_VERSAO_RESPONSE',
    listaTipoContratoVersao
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}