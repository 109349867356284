export function ListarBancoRequest() {
    return {
        type: 'LISTAR_BANCO_REQUEST'
    }
}

export function ListarBancoResponse(listaBanco) {
    return {
        type: 'LISTAR_BANCO_RESPONSE',
        listaBanco
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}