import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InserirItemCustoRequest } from "../../../store/modules/itemCusto/actions";
import { ListarGrupoCustoCompletoRequest } from "../../../store/modules/grupoCusto/actions";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

export default function ItemCusto() {

    const { id } = useParams();
    const dispatch = useDispatch();

    let listaGrupoCusto = useSelector(state => state.grupoCusto.listaCompletaGrupoCusto);
    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);

    const [idGrupoCusto, setIdGrupoCusto] = useState('');
    const [descricaoCodigoInterno, setDescricaoCodigoInterno] = useState('');
    const [descricao, setDescricao] = useState('');
    const [validated, setValidated] = useState(false);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);

    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "ContratoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    useEffect(() => {

        if (contratoCompleto != null) {
            var model = {
                IdContratoVersao: contratoCompleto.contratoVersaoApiModel.id
            }
            dispatch(ListarGrupoCustoCompletoRequest(model));
        }

    }, [contratoCompleto])

    function CadastrarItemCusto(e) {

        if (descricaoCodigoInterno == null || descricaoCodigoInterno == undefined
            || descricaoCodigoInterno.trim() == '') {
            toast.error(`Descrição Número Interno inválida`);
            return;
        }

        if (descricao == null || descricao == undefined
            || descricao.trim() == '') {
            toast.error(`Descrição inválida`);
            return;
        }

        e.preventDefault();

        var data = {
            'Id': 0,
            'IdGrupoCusto': idGrupoCusto,
            'DescricaoCodigoInterno': descricaoCodigoInterno.trim(),
            'Descricao': descricao.trim(),
            'IdContratoVersao': contratoCompleto.contratoVersaoApiModel.id
        }

        dispatch(InserirItemCustoRequest(data, id));
    }

    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {

            event.preventDefault();
            setValidated(true);
        } else {

            CadastrarItemCusto(event);
            setDescricao("");
            setDescricaoCodigoInterno("");
            setIdGrupoCusto("");
            setValidated(false);
        }
    };

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="divBody">
                    <Row>
                        <Col md={12}>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formCusto">
                                    <Form.Label>Grupo Custo</Form.Label>
                                    <Form.Select onChange={e => setIdGrupoCusto(e.target.value)} value={idGrupoCusto} id="selectItem"
                                        required
                                        isInvalid={validated && idGrupoCusto === "" ? true : false}
                                        isValid={validated && idGrupoCusto !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaGrupoCusto != undefined ?
                                                listaGrupoCusto.map(m => (
                                                    <option value={m.id} key={m.id}>{m.descricaoCodigoInterno} - {m.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo grupo custo é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formDescricaoCodigoInterno">
                                    <Form.Label>Número Interno</Form.Label>
                                    <Form.Control type="text" value={descricaoCodigoInterno} onChange={e => setDescricaoCodigoInterno(e.target.value)}
                                        maxLength="150"
                                        isInvalid={validated && descricaoCodigoInterno.trim() === "" ? true : false}
                                        isValid={validated && descricaoCodigoInterno.trim() !== "" ? true : false}
                                        required />
                                    <Form.Control.Feedback type="invalid">O campo número interno é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} controlId="formDescricao">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control type="text" value={descricao} onChange={e => setDescricao(e.target.value)}
                                        maxLength="500"
                                        isInvalid={validated && descricao.trim() === "" ? true : false}
                                        isValid={validated && descricao.trim() !== "" ? true : false}
                                        required />
                                    <Form.Control.Feedback type="invalid">O campo descrição é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md={2}>
                                    {
                                        inserir == true
                                            ?
                                            <Button variant="success" type="submit">Cadastrar Item Custo</Button>
                                            :
                                            ''
                                    }

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    );
}