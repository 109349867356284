export function ListarOrigemContratoRequest() {
  return {
    type: 'LISTAR_ORIGEM_CONTRATO_REQUEST'
  }
}

export function ListarOrigemContratoResponse(listaOrigemContrato) {
  return {
    type: 'LISTAR_ORIGEM_CONTRATO_RESPONSE',
    listaOrigemContrato
  }
}

export function InserirOrigemContratoRequest(data) {
  return {
    type: 'INSERIR_ORIGEM_CONTRATO_REQUEST',
    data
  }
}

export function AlterarOrigemContratoRequest(data) {
  return {
    type: 'ALTERAR_ORIGEM_CONTRATO_REQUEST',
    data
  }
}

export function ExcluirOrigemContratoRequest(id) {
  return {
    type: 'EXCLUIR_ORIGEM_CONTRATO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}