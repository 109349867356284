import { createStore, applyMiddleware } from 'redux';
import { toast } from 'react-toastify';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const enhancer = applyMiddleware(sagaMiddleware);

const store = createStore(rootReducer, enhancer);

const rootSagaTask = sagaMiddleware.run(rootSaga);

rootSagaTask.toPromise().catch(e => {
    toast.error(e);
});

export default store;