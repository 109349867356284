import produce from 'immer';

const initialState = {
    listaGrupoCusto: undefined,
    listaCompletaGrupoCusto: undefined,
    carregando: true
};

export default function grupoCusto(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_GRUPO_CUSTO_RESPONSE':
            return produce(state, draft => {
                draft.listaGrupoCusto = action.listaGrupoCusto;
            });

        case 'LISTAR_GRUPO_CUSTO_COMPLETO_RESPONSE':
            return produce(state, draft => {
                draft.listaCompletaGrupoCusto = action.listaCompletaGrupoCusto;
            });
        
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}