import produce from 'immer';

const initialState = {
    listaLimiteValor: undefined,
    carregando: true
};

export default function limiteValor(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_LIMITE_VALOR_RESPONSE':
            return produce(state, draft => {
                draft.listaGrupoCusto = action.listaGrupoCusto;
            });

        
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}