import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse,
  ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse } from './actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import history from '../../../services/history';

function* ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(model) {

  try {

    //yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var data = {
      'IdAutorizacaoFornecimentoSubitem': model.idAutorizacaoSubitem,
      'IdAutorizacaoFornecimentoMedicaoSubitem': model.idAutorizacaoSubitemMedicao
    }

    const result = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/listarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse(result.data.result));
    //yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem`, { headers: headerParams.token });

    yield put(ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BaixarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeRegistroFotografico}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });

    yield put(Carregando(true));
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(Carregando(true));
    yield put(ErrorResponse(e));
  }
}

function* CarregarImagemRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
  
    fetch(`${urlBase}/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem").appendChild(elem).style.width = "100%";        
        });
    
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${model.id}/AutorizacaoFornecimentoSubitem`, model.data, { headers: headerParams.token });
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Foto id: ' + listaId + ' cadastrada com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
        }
      }

    yield put(ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
      
  }
}

function* InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemMedicaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${model.id}/AutorizacaoFornecimentoSubitemMedicao`, model.data, { headers: headerParams.token });
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Foto id: ' + listaId + ' cadastrada com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
        }
      }

    yield put(ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
      
  }
}

export default all([
  takeLatest('LISTAR_COMPLETO_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST', ListarCompletoRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest),
  takeLatest('LISTAR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST', ListarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest),
  takeLatest('BAIXAR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST', BaixarRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest),
  takeLatest('CARREGAR_IMAGEM_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_REQUEST', CarregarImagemRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemRequest),
  takeLatest('INSERIR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_AUTORIZACAO_REQUEST', InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemAutorizacaoRequest),
  takeLatest('INSERIR_REG_FOTO_SUBITEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REQUEST', InserirRegFotoSubitemAutorizacaoMedicaoUltrapassarSubitemMedicaoRequest),
])