import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Button } from "react-bootstrap";
import {
    ListarTipoDocumentoArquivoRequest, ExcluirTipoDocumentoArquivoRequest,
    InserirTipoDocumentoArquivoRequest, AlterarTipoDocumentoArquivoRequest
} from "../../../store/modules/tipoDocumentoArquivo/actions";
import { IoFileTrayOutline } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import Header from "../../../components/Header/header";
import './tipoDocumentoArquivo.css';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

export default function TipoDocumentoArquivo() {

    const dispatch = useDispatch();
    const history = useHistory();
    const listaTipoDocumentoArquivo = useSelector(state => state.tipoDocumentoArquivo.listaTipoDocumentoArquivo);
    const carregando = useSelector(state => state.tipoDocumentoArquivo.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarTipoDocumentoArquivoRequest());
    }, [])


    function ValidacoesInserir(tipoDocumentoArquivo) {

        //validação do nome do tipo arquivo
        if (tipoDocumentoArquivo.nomeTipoArquivo == null || tipoDocumentoArquivo.nomeTipoArquivo == undefined
            || tipoDocumentoArquivo.nomeTipoArquivo.trim() == '' || tipoDocumentoArquivo.nomeTipoArquivo.length > 50) {
            toast.error(`Nome do tipo arquivo inválido`);
            return false;
        }

        return true;

    }

    function AdicionarTipoDocumentoArquivo(tipoDocumentoArquivo) {

        let data = {
            'Id': 0,
            'Descricao': tipoDocumentoArquivo.nomeTipoArquivo.trim()
        }

        dispatch(InserirTipoDocumentoArquivoRequest(data));
    }

    function AlterarTipoDocumentoArquivo(tipoDocumentoArquivo) {

        let data = {
            'Id': tipoDocumentoArquivo.id,
            'Descricao': tipoDocumentoArquivo.nomeTipoArquivo.trim()
        }

        dispatch(AlterarTipoDocumentoArquivoRequest(data));
    }

    function ExcluirTipoDocumentoArquivo(idTipoDocumentoArquivo) {
        dispatch(ExcluirTipoDocumentoArquivoRequest(idTipoDocumentoArquivo));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col md={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <IoFileTrayOutline size={25} color={"#000"} /> Tipo Documento arquivo
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                {
                                    carregando ?
                                        <MaterialTable
                                            components={{
                                                Pagination: PatchedPagination,
                                            }}
                                            title=""
                                            columns={[
                                                {
                                                    title: 'Número do Tipo de Documento', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                        width: '20%',
                                                    }
                                                },
                                                {
                                                    title: 'Nome', field: 'nomeTipoArquivo', sorting: true, editPlaceholder: 'Nome', validate: rowData => {
                                                        if (rowData.nomeTipoArquivo === undefined || rowData.nomeTipoArquivo.trim() === "") {
                                                            return "Campo Obrigatório"
                                                        } else if (rowData.nomeTipoArquivo.trim().length > 50) {
                                                            return "O nome não pode conter mais que 50 caracteres"
                                                        }
                                                        return true
                                                    },
                                                    cellStyle: {
                                                        width: '80%'
                                                    }
                                                },
                                            ]}
                                            data={
                                                listaTipoDocumentoArquivo != undefined ?
                                                    listaTipoDocumentoArquivo
                                                        .map(tp => {
                                                            return {
                                                                id: tp.id,
                                                                nomeTipoArquivo: tp.descricao,
                                                            }
                                                        })
                                                    :
                                                    []
                                            }
                                            icons={{
                                                Add: () =>
                                                    <Button variant="success">
                                                        <span>
                                                            <FaPlus size={15} color={"#fff"} /> Inserir
                                                        </span>
                                                    </Button>
                                            }}
                                            editable={{
                                                onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                    TodasOperacoes ?
                                                        setTimeout(() => {
                                                            if (!ValidacoesInserir(newRow)) {
                                                                reject();
                                                            }
                                                            else {
                                                                AdicionarTipoDocumentoArquivo(newRow);
                                                                resolve()
                                                            }
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                }),

                                                onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                    TodasOperacoes ?
                                                        setTimeout(() => {
                                                            if (!ValidacoesInserir(newRow)) {
                                                                reject();
                                                            }
                                                            else {
                                                                AlterarTipoDocumentoArquivo(newRow);
                                                                resolve()
                                                            }
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                }),

                                                onRowDelete: oldData => new Promise((resolve, reject) => {
                                                    TodasOperacoes ?
                                                        setTimeout(() => {
                                                            ExcluirTipoDocumentoArquivo(oldData.id);
                                                            resolve()
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                            }}
                                            options={{
                                                minBodyHeight: 500,
                                                paging: true,
                                                pageSize: 10, // Início
                                                emptyRowsWhenPaging: false,
                                                addRowPosition: "first",
                                                pageSizeOptions: [10, 50, 200, 300],
                                                headerStyle: {
                                                    backgroundColor: '#454545',
                                                    color: '#FFF',
                                                    fontWeight: "bold",
                                                    lineBreak: "auto",
                                                    fontSize: "13px"
                                                },
                                                actionsColumnIndex: -1,
                                                filtering: true,
                                                filterRowStyle: {
                                                    backgroundColor: "#FAFAFA"
                                                },
                                                rowStyle: rowData => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })

                                            }}
                                            style={{
                                                fontSize: "13px",
                                            }}
                                            localization={{
                                                header: {
                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                    editRow: {
                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                        saveTooltip: '',
                                                        cancelTooltip: '',
                                                    },
                                                    deleteTooltip: '',
                                                    editTooltip: '',
                                                    addTooltip: ''
                                                },
                                                toolbar: {
                                                    searchTooltip: '',
                                                    searchPlaceholder: 'Pesquisar',
                                                    exportTitle: "",
                                                    exportCSVName: "Exportar como CSV",
                                                    exportPDFName: "Exportar como PDF",
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'linhas',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: '',
                                                    previousTooltip: '',
                                                    nextTooltip: '',
                                                    lastTooltip: ''
                                                },
                                                grouping: {
                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                    groupedBy: 'Agrupado por:'
                                                }
                                            }}
                                        />
                                        :
                                        <Loading />
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    )
}