import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RiBarChartGroupedLine } from "react-icons/ri";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarRelatorioStatusAutorizacaoRequest } from "../../../store/modules/relatorio/actions";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom";
import { RiInformationLine } from "react-icons/ri";

export default function RelatorioStatusAutorizacao() {

    const dispatch = useDispatch();
    const history = useHistory();
    const carregando = useSelector(state => state.relatorio.carregando);
    const listaRelatorioStatusAutorizacao = useSelector(state => state.relatorio.listaRelatorioStatusAutorizacao);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState([]);
    const [carregaPage, setCarregaPage] = useState(true);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    
    useEffect(() => {
        //Permissão de Visualizar
        if (token.role.filter(r => r == "RelatorioMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {

        setCarregaPage(false);
        dispatch(ListarRelatorioStatusAutorizacaoRequest());

    }, [])

    useEffect(() => {

        if (listaRelatorioStatusAutorizacao !== null &&
            listaRelatorioStatusAutorizacao !== undefined) {
            constroiHierarquia();
        }

    }, [listaRelatorioStatusAutorizacao])

   function constroiHierarquia() {

        let hierarquiaCompleta = [];

        listaRelatorioStatusAutorizacao.forEach((lista) => {
            hierarquiaCompleta.push({
                id: lista[0].idAutorizacaoFornecimento,
                af: lista[0].numeroAutorizacaoFornecimento,
                idSolicitacao: null,
                Chegada: null,
                saida: null,
                dias: null,
                Usuario: null,
                Valor: null,
                status: null,
                proximaDemanda: null,
                cancelado: null
            });
            lista.map(x => {
                hierarquiaCompleta.push({
                    marcadorUltimo: lista[lista.length - 1].idAutorizacaoFornecimentoStatus == x.idAutorizacaoFornecimentoStatus ?
                        true : false,
                    id: null,
                    af: x.numeroAutorizacaoFornecimento,
                    idSolicitacao: x.idSolicitacaoFornecimento,
                    Chegada: x.dataCriacao,
                    saida:  x.dataSaida ? x.dataSaida : " - ",
                    dias: x.quantidadeDias,
                    Usuario: x.usuario,
                    Valor: "R$" + x.valorOrcamento.toFixed(2),
                    parentId: x.idAutorizacaoFornecimento,
                    status: x.statusAutorizacao,
                    proximaDemanda: x.proximaDemanda,
                    cancelado: x.cancelado,
                });
            })
        })
        setListaHierarquiaCompleta(hierarquiaCompleta);
        setCarregaPage(true);
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <RiBarChartGroupedLine size={25} color={"#000"} /> Relatório Status Autorização
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregaPage ?
                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: '#', field: 'id', editable: 'never', hidden: true, defaultSort:"desc",
                                                            },
                                                            {
                                                                title: 'Número Autorização', field: 'af', editable: 'never', 
                                                            },
                                                            {
                                                                title: 'Número Solicitação', field: 'idSolicitacao', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Data Chegada Status', field: 'Chegada', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Data Saída Status', field: 'saida', editable: 'never' 
                                                            },
                                                            {
                                                                title: 'Quant. Dias', field: 'dias', editable: 'never' 
                                                            },
                                                            {
                                                                title: 'Usuário', field: 'Usuario', editable: 'never'
                                                            },
                                                            {
                                                                title: (<span>
                                                                            Valor Orçamento 
                                                                            <RiInformationLine size={18} title="O valor informado não inclui os itens adicionados após a solicitação"/>
                                                                        </span>), 
                                                                field: 'Valor', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Status', field: 'status', editable: 'never',
                                                                render: rowData => (
                                                                    rowData.cancelado ?
                                                                        <span>
                                                                            {rowData.status + " "}
                                                                            <RiInformationLine size={15} color={'#9D9999'} title="Apenas cliente pode reabrir a autorização"/>
                                                                        </span>
                                                                        :
                                                                        <span>{rowData.status}</span>
                                                                )
                                                            },
                                                            {
                                                                title: 'Próxima Demanda', field: 'proximaDemanda', editable: 'never',
                                                                render: rowData => (
                                                                    rowData.marcadorUltimo ?
                                                                        <span>{rowData.proximaDemanda}</span>
                                                                        :
                                                                        <span style={{color: '#9D9999'}}>{rowData.proximaDemanda}</span>
                                                                )
                                                            },
                                                            {
                                                                title: 'm', field: 'marcadorUltimo', editable: 'never', hidden: true
                                                            },
                                                            {
                                                                field: 'cancelado', hidden: true, editable: 'never'
                                                            },
                                                        ]}
                                                        data={listaHierarquiaCompleta}
                                                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            maxBodyHeight: '500px',
                                                            paging: true,
                                                            pageSize: 50, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px",                                                           
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            })

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                :
                                                <Col md={6} style={{ margin: 'auto', display: 'grid' }}>
                                                    <Row style={{ margin: 'auto' }}>
                                                        <h5 style={{ textAlign: 'right' }}>Dados extensos! Isso pode levar alguns minutos...</h5>
                                                    </Row>
                                                    <Row style={{ margin: 'auto'}}>
                                                        <Loading/>
                                                    </Row>
                                                </Col>
                                                
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}