import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarHistoricoAutorizacaoFornecimentoEmpenhoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarHistoricoAutorizacaoFornecimentoEmpenhoRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var data = {
            'IdAutorizacaoFornecimento': parseInt(model.IdAutorizacaoFornecimento)
        }

        const result = yield call(api.post, `/api/HistoricoAutorizacaoFornecimentoEmpenho/listar`, data, { headers: headerParams.token });

        yield put(ListarHistoricoAutorizacaoFornecimentoEmpenhoResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ListarHistoricoAutorizacaoFornecimentoEmpenhoResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}


export default all([
    takeLatest('LISTAR_HISTORICO_AUTORIZACAO_FORNECIMENTO_EMPENHO_REQUEST', ListarHistoricoAutorizacaoFornecimentoEmpenhoRequest),
])