import produce from 'immer';

const initialState = {
  listaSolicitacaoFornecimentoVersaoStatus: undefined,
  listaValidacaoSolicitacaoStatus: undefined,
  carregando: true
};

export default function solicitacaoFornecimentoVersaoStatus(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimentoVersaoStatus = action.listaSolicitacaoFornecimentoVersaoStatus;
      });

    case 'VALIDAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_RESPONSE':
      return produce(state, draft => {
        draft.listaValidacaoSolicitacaoStatus = action.listaValidacaoSolicitacaoStatus;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    case 'LIMPAR_LISTA_VALIDACAO_SOLICITACAO':
      return produce(state, draft => {
        draft.listaValidacaoSolicitacaoStatus = undefined
      });

      case 'LIMPAR_SOLICITACAO_STATUS':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimentoVersaoStatus = undefined
      });

    default:
      return state;
  }
}