import produce from 'immer';

const initialState = {
    fornecedorContaCompleta: null,
    listaFornecedorConta: undefined,
    carregando: true
};

export default function fornecedorConta(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_FORNECEDOR_CONTA_RESPONSE':
            return produce(state, draft => {
                draft.listaFornecedorConta = action.listaFornecedorConta;
            });

        case 'BUSCAR_FORNECEDOR_CONTA_POR_ID_RESPONSE':
            return produce(state, draft => {
                draft.fornecedorContaCompleta = action.fornecedorContaCompleta;
            });
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}