import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarSolicitacaoFornecimentoRegistroFotograficoResponse } from './actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import history from '../../../services/history';

function* ListarCompletoSolicitacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdSolicitacao: parseInt(model.idSolicitacao) }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ListarSolicitacaoFornecimentoRegistroFotograficoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoRegistroFotografico`, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirSolicitacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/SolicitacaoFornecimentoRegistroFotografico`, model.data, { headers: headerParams.token });    

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdSolicitacao: parseInt(model.idSolicitacao) }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });

    yield put(Carregando(true));

    toast.success('Registro(s) Fotográfico: ' + listaId + ' cadastrado(s) com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarSolicitacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/SolicitacaoFornecimentoRegistroFotografico`, model.data, { headers: headerParams.token });    
    
    const result = yield call(api.post, `/api/SolicitacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdSolicitacao: parseInt(model.idSolicitacao) }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Registro fotográfico alterado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirSolicitacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/SolicitacaoFornecimentoRegistroFotografico/${model.id}/${'0'}`, { headers: headerParams.token });
  
    toast.success(`Registro fotográfico id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/SolicitacaoFornecimentoRegistroFotografico/listarCompleto`,
      { IdSolicitacao: parseInt(model.idSolicitacao) }, { headers: headerParams.token });

    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
        
    yield put(ListarSolicitacaoFornecimentoRegistroFotograficoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BaixarSolicitacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/SolicitacaoFornecimentoRegistroFotografico/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeRegistroFotografico}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/SolicitacaoFornecimentoRegistroFotografico/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });

    yield put(Carregando(true));
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(Carregando(true));
    yield put(ErrorResponse(e));
  }
}

function* CarregarImagemSolicitacaoFornecimentoRegistroFotograficoRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
  
    fetch(`${urlBase}/api/SolicitacaoFornecimentoRegistroFotografico/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoSolicitacaoFornecimentoRegistroFotografico").appendChild(elem).style.width = "100%";        
        });
    
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_COMPLETO_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', ListarCompletoSolicitacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('LISTAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', ListarSolicitacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('INSERIR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', InserirSolicitacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('ALTERAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', AlterarSolicitacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('EXCLUIR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', ExcluirSolicitacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('BAIXAR_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', BaixarSolicitacaoFornecimentoRegistroFotograficoRequest),
  takeLatest('CARREGAR_IMAGEM_SOLICITACAO_FORNECIMENTO_REGISTRO_FOTOGRAFICO_REQUEST', CarregarImagemSolicitacaoFornecimentoRegistroFotograficoRequest)
])