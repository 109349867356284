import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { AtualizarPerfilResponse, Carregando } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* AtualizarPerfilRequest({ data }){

  try{
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.put, `/api/Usuario`, data, { headers:headerParams.token });    
  
    if(result.data.statusCode === 200){

      if (result.data.result) {
        localStorage.removeItem('token');
        history.push('/');
      }
      
      yield put(AtualizarPerfilResponse(data)); 
      toast.success("Salvo com sucesso!")

    }
    yield put(Carregando(true));
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));    
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('ATUALIZAR_PERFIL_REQUEST', AtualizarPerfilRequest),
])