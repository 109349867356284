import { Container, Row, Form, Col, Card, Button, Modal } from "react-bootstrap";
import { MdOutlinePerson } from 'react-icons/md';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListarMunicipiosRequest } from "../../../store/modules/municipio/actions";
import { ListarGrupoAcessoRequest } from '../../../store/modules/grupoAcesso/actions';
import { ListarClienteRequest } from '../../../store/modules/cliente/actions';
import { ListarTipoDocumentoRequest } from '../../../store/modules/tipoDocumento/actions';
import { InserirUsuarioRequest, BuscarUsuarioPorIdRequest, AlterarUsuarioRequest, InserirNovaSenhaPageUsuarioRequest } from '../../../store/modules/usuario/actions';
import { Link, useHistory, useParams } from "react-router-dom";
import { ListarFornecedorRequest } from "../../../store/modules/fornecedor/actions";
import { ListarContratoRequest } from "../../../store/modules/contrato/actions";
import { ListarCompletoUsuarioFornecedorRequest } from "../../../store/modules/usuarioFornecedor/actions";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Header from "../../../components/Header/header";
import 'moment/locale/pt-br';
import moment from 'moment';
import { RodarScriptTelefoneInteressado } from "../../../store/modules/global/actions";

export default function Usuario() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    let [usuarioAtual] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const usuario = useSelector(state => state.usuario.usuario);
    const listaMunicipio = useSelector(state => state.municipio.listaMunicipio);
    const listaGrupoAcesso = useSelector(state => state.grupoAcesso.listaGrupoAcesso);
    const listaCliente = useSelector(state => state.cliente.listaCliente);
    const listaTipoDocumento = useSelector(state => state.tipoDocumento.listaTipoDocumento);
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);
    const listaUsuarioFornecedor = useSelector(state => state.usuarioFornecedor.listaUsuarioFornecedor);
    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const senha = useSelector(state => state.usuario.senha);

    const [nome, setNome] = useState('');
    const [username, setUsername] = useState('');
    const [cliente, setCliente] = useState('');
    const [grupoAcesso, setGrupoAcesso] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [documento, setDocumento] = useState('');
    const [email, setEmail] = useState('');
    const [ddd, setDdd] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [ativo, setAtivo] = useState(true);
    const [fornecedor, setFornecedor] = useState(true);
    const [idFornecedor, setIdFornecedor] = useState(0);
    const [idUsuarioFornecedor, setIdUsuarioFornecedor] = useState(0);
    const [bairro, setBairro] = useState('');
    const [preferencia, setPreferencia] = useState(true);
    const [idContrato, setIdContrato] = useState(0);
    const [cargo, setCargo] = useState('');
    const [historicoFiltro, setHistoricoFiltro] = useState(false);
    const [validarConta, setValidarConta] = useState(false);
    const [modalSenha, setModalSenha] = useState(false);

    const [validated, setValidated] = useState(false);
    const [validatedDocumento, setValidatedDocumento] = useState(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);

    useEffect(() => {

        moment.locale('pt-br');

        //Permissão de Visualizar
        if (token.role.filter(r => r == "UsuarioVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "UsuarioInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "UsuarioAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        dispatch(ListarContratoRequest());
        dispatch(ListarFornecedorRequest());

        if (id != undefined) {
            if (usuario == null || usuario.id != parseInt(id)) {
                dispatch(BuscarUsuarioPorIdRequest(id));
                dispatch(ListarCompletoUsuarioFornecedorRequest(id))
            }
        }


        dispatch(ListarMunicipiosRequest());
        dispatch(ListarGrupoAcessoRequest());
        dispatch(ListarClienteRequest());
        dispatch(ListarTipoDocumentoRequest());


    }, [usuario])

    useEffect(() => {

        if (id != null && usuario != null) {

            var ObjetoJsonPreferencias = JSON.parse(usuario.jsonPreferencias);

            setNome(usuario.nome);
            setUsername(usuario.userName);
            setCliente(usuario.idCliente);
            setGrupoAcesso(usuario.idGrupo);
            setTipoDocumento(usuario.idTipoDocumento);
            setDocumento(usuario.documento);
            setEmail(usuario.email);
            setDdd(usuario.ddd);
            setTelefone(usuario.telefone);
            setCep(usuario.cep);
            setLogradouro(usuario.logradouro);
            setNumero(usuario.numero);
            setComplemento(usuario.complemento);
            setMunicipio(usuario.idMunicipio);
            setBairro(usuario.bairro);
            setAtivo(usuario.ativo);
            setFornecedor(usuario.usuarioFornecedor);
            setCargo(usuario.cargo);
            setIdContrato(ObjetoJsonPreferencias.IdContrato);
            setHistoricoFiltro(usuario.historicoFiltro);
            setValidarConta(usuario.validado);

        }

        if (id != null && usuario != null && ObjetoJsonPreferencias != null && ObjetoJsonPreferencias.IdContrato == 0) {
            setPreferencia(false);
        }

        if (listaUsuarioFornecedor != undefined && listaUsuarioFornecedor.length > 0) {
            setIdFornecedor(listaUsuarioFornecedor[0].idFornecedor);
            setIdUsuarioFornecedor(listaUsuarioFornecedor[0].id);
        }

    }, [usuario, listaUsuarioFornecedor])


    function CadastrarUsuario(e) {

        e.preventDefault();

        //validação do campo Nome
        if (nome.trim() == '' || nome == null || nome == undefined) {
            toast.error(`Nome Inválido`);
            return;
        }
        if (nome.length > 100) {
            toast.error(`O Nome não pode conter mais que 100 caracteres`);
            return;
        }

        //validação do campo username
        if (username.trim() == ''
            || username == null || username == undefined) {
            toast.error(`Username Inválido`);
            return;
        }
        if (username.length > 100) {
            toast.error(`O campo "username" não pode conter mais que 100 caracteres`);
            return;
        }

        //validação do campo email
        if (email.trim() == ''
            || email == null || email == undefined) {
            toast.error(`Email Inválido`);
            return;
        }
        if (email.length > 150) {
            toast.error(`O email não pode conter mais que 150 caracteres`);
            return;
        }

        //validação do campo logradouro
        if (logradouro.trim() == ''
            || logradouro == null || logradouro == undefined) {
            toast.error(`Logradouro Inválido`);
            return;
        }
        if (logradouro.length > 150) {
            toast.error(`O logradouro não pode conter mais que 150 caracteres`);
            return;
        }

        //validação do campo numero
        if (numero.trim() == ''
            || numero == null || numero == undefined) {
            toast.error(`Numero Inválido`);
            return;
        }
        if (numero.length > 10) {
            toast.error(`O numero não pode conter mais que 10 caracteres`);
            return;
        }

        //validação do campo bairro
        if (bairro.trim() == ''
            || bairro == null || bairro == undefined) {
            toast.error(`Bairro Inválido`);
            return;
        }
        if (bairro.length > 100) {
            toast.error(`O bairro não pode conter mais que 100 caracteres`);
            return;
        }

        //validação do cargo
        if (cargo.trim() == '' || cargo == null || cargo == undefined) {
            toast.error(`Cargo Inválido`);
            return;
        }
        if (cargo.length > 150) {
            toast.error(`O Cargo não pode conter mais que 150 caracteres`);
            return;
        }

        var jsonString = JSON.stringify({ "IdContrato": idContrato }, null, ' ');

        var data = {
            'Id': 0,
            'IdCliente': cliente,
            'Nome': nome.trim(),
            'UserName': username,
            'Senha': "",
            'IdGrupo': grupoAcesso,
            'VersaoMaiorUltimoAcesso': 0,
            'VersaoMenorUltimoAcesso': 0,
            'VersaoPatchUltimoAcesso': 0,
            'ApiRefreshToken': '0',
            'JsonPreferencias': jsonString.toString(),
            'Salt1': "",
            'Salt2': "",
            'IdTipoDocumento': tipoDocumento,
            'Documento': documento,
            'Email': email,
            'DDD': ddd,
            'Telefone': telefone,
            'Logradouro': logradouro,
            'Numero': numero,
            'Complemento': complemento,
            'Bairro': bairro,
            'Cep': cep,
            'IdMunicipio': municipio,
            'Ativo': ativo,
            'DataAtivacao': moment((new Date())).add('hours', 2),
            'DataInclusao': moment((new Date())).add('hours', 2),
            'DataAlteracao': moment((new Date())).add('hours', 2),
            'CodigoAtivacao': '',
            'UsuarioFornecedor': fornecedor,
            'Cargo': cargo,
            'HistoricoFiltro': historicoFiltro,
            'Validado': validarConta
        }

        var usuarioFornecedor = {
            Id: idUsuarioFornecedor,
            IdUsuario: id == undefined ? 0 : id,
            IdFornecedor: parseInt(idFornecedor)
        }

        if (id != null) {
            data.Id = usuario.id;
            data.Senha = usuario.senha;
            data.VersaoMaiorUltimoAcesso = usuario.versaoMaiorUltimoAcesso;
            data.VersaoMenorUltimoAcesso = usuario.versaoMenorUltimoAcesso;
            data.VersaoPatchUltimoAcesso = usuario.versaoPatchUltimoAcesso;
            data.ApiRefreshToken = usuario.apiRefreshToken;
            data.Salt1 = usuario.salt1;
            data.Salt2 = usuario.salt2;
            data.DataInclusao = usuario.dataInclusao;
            data.DataAlteracao = moment((new Date())).add('hours', 2);
            data.Ativo = ativo;
            if(usuario.validado !== validarConta){
                data.Validado = validarConta;
                data.DataAtivacao = moment((new Date())).add('hours', 2);
            }
            else{
                data.Validado = validarConta;
                data.DataAtivacao = usuario.DataAtivacao;
            }
            data.IdUsuarioAtual = usuarioAtual.id;
            if (usuario.cargo.trim() != '') {
                data.Cargo = usuario.cargo;
            }
            data.HistoricoFiltro = historicoFiltro;

            dispatch(AlterarUsuarioRequest(data, usuarioFornecedor));

        } else {
            dispatch(InserirUsuarioRequest(data, usuarioFornecedor));
        }

    }

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false || ValidaTelefone() === false
            || ValidaDdd() === false || ValidaCep() === false
            || ValidaEmail() === false || (!isValidCPF(documento) && tipoDocumento === '3') === true
            || (!isValidCPF(documento) && tipoDocumento === 3) === true) {
            event.preventDefault();
        } else {
            CadastrarUsuario(event);
        }

        setValidated(true);
    };

    function VerificarTipoDocumento(event) {
        setTipoDocumento(event.target.value);
        setDocumento("");
        setValidatedDocumento(false);
    }

    function ValidarDocumento(event) {
        setDocumento(event.target.value);

        if (tipoDocumento === '3' || tipoDocumento === 3) {
            if (isValidCPF(event.target.value)) {
                setValidatedDocumento(true);
            } else {
                setValidatedDocumento(false);
            }
        }
    }

    function isValidCPF(cpf) {
        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/igm, '')
        if (
            !cpf ||
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        ) {
            return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(9, 10))) return false
        soma = 0
        for (var i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(10, 11))) return false
        return true
    }

    function ValidaDdd() {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(ddd))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone() {
        const telefoneRegex = /^\d{1}\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaCep() {
        const cepRegex = /^\d{2}.\d{3}-\d{3}$/;
        if (cepRegex.test(String(cep))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaEmail() {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (emailRegex.test(String(email))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaContrato(idContrato) {
        if (listaContrato !== undefined && listaContrato !== null) {
            var lista = listaContrato.filter(x => x.id == idContrato);
            if (lista.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    function ValidaFornecedor(idFornecedor) {
        if (listaFornecedor !== undefined && listaFornecedor !== null) {
            var lista = listaFornecedor.filter(x => x.id == idFornecedor);
            if (lista.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    function rodaScriptInteressadoTelefone() {
        dispatch(RodarScriptTelefoneInteressado());
    }


    function novaSenha (){
        dispatch(InserirNovaSenhaPageUsuarioRequest(email));
        setModalSenha(true)
    }
    function fecharModalSenha(){
        setModalSenha(false);
        localStorage.removeItem('novaSenha');
    }
    
    
    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdOutlinePerson size={25} color={"#000"} /> Usuário
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {
                                <Form noValidate onSubmit={handleSubmit}>
                                    <div className="divBody">
                                        <Row>
                                            <Col md={12}>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formNome">
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            type="text"
                                                            value={nome}
                                                            onChange={e => setNome(e.target.value)}
                                                            required
                                                            maxLength="100"
                                                            isInvalid={validated && nome.trim() === "" ? true : false}
                                                            isValid={validated && nome.trim() !== "" ? true : false} />
                                                        <Form.Control.Feedback type="invalid">O campo Nome é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formUsername">
                                                        <Form.Label>Username</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            type="text"
                                                            value={username}
                                                            onChange={e => setUsername(e.target.value)}
                                                            required
                                                            maxLength="100"
                                                            isInvalid={validated && username.trim() === "" ? true : false}
                                                            isValid={validated && username.trim() !== "" ? true : false} />
                                                        <Form.Control.Feedback type="invalid">O campo Username é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={12} controlId="formEmail">
                                                        <Form.Label>E-mail</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            type="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                            required
                                                            maxLength="150"
                                                            isInvalid={validated && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(String(email)) ? true : false}
                                                            isValid={validated && /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(String(email)) ? true : false} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                email === "" ? "O campo Email é obrigatório." : "Forneça um Email válido"
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formCliente">
                                                        <Form.Label>Cliente</Form.Label>
                                                        <Form.Select
                                                            disabled={ativo ? false : true}
                                                            onChange={(e) => setCliente(e.target.value)}
                                                            value={cliente}
                                                            required
                                                            isInvalid={validated && cliente === "" ? true : false}
                                                            isValid={validated && cliente !== "" ? true : false}>
                                                            <option value="">Selecione...</option>
                                                            {
                                                                listaCliente != undefined && listaCliente != null ?
                                                                    listaCliente.map(m => (
                                                                        <option value={m.id} key={m.id}>{m.nome}</option>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">O campo Cliente é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formAcesso">
                                                        <Form.Label>Grupo de Acesso</Form.Label>
                                                        <Form.Select
                                                            disabled={ativo && usuarioAtual && usuarioAtual.grupoAcesso &&
                                                                usuarioAtual.grupoAcesso.nomeGrupo === "Super Administrador" ? false : true}
                                                            onChange={(e) => setGrupoAcesso(e.target.value)}
                                                            value={grupoAcesso}
                                                            required
                                                            isInvalid={validated && grupoAcesso === "" ? true : false}
                                                            isValid={validated && grupoAcesso !== "" ? true : false}>
                                                            <option value="">Selecione...</option>
                                                            {
                                                                listaGrupoAcesso != undefined ?
                                                                    listaGrupoAcesso.map(m => (
                                                                        <option value={m.id} key={m.id}>{m.nomeGrupo}</option>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">O campo Grupo de Acesso é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formTipoDocumento">
                                                        <Form.Label>Tipo de Documento</Form.Label>
                                                        <Form.Select
                                                            disabled={ativo ? false : true}
                                                            onChange={VerificarTipoDocumento}
                                                            value={tipoDocumento}
                                                            required
                                                            isInvalid={validated && tipoDocumento === "" ? true : false}
                                                            isValid={validated && tipoDocumento !== "" ? true : false}>
                                                            <option value="">Selecione...</option>
                                                            {
                                                                listaTipoDocumento != undefined ?
                                                                    listaTipoDocumento.map(m => (
                                                                        <option value={m.id} key={m.id}>{m.descricao}</option>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">O campo Tipo de Documento é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formDocumento">
                                                        <Form.Label>Documento</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            as={InputMask}
                                                            value={documento}
                                                            onChange={ValidarDocumento}
                                                            maxLength={tipoDocumento === '3' ? "" : "70"}
                                                            mask={tipoDocumento === '3' ? "999.999.999-99" : null}
                                                            required
                                                            isInvalid={validated && !validatedDocumento && tipoDocumento === '3'
                                                                || validated && !validatedDocumento && tipoDocumento === 3
                                                                || validated && tipoDocumento !== '3' && documento === "" ? true : false}
                                                            isValid={validated && validatedDocumento && tipoDocumento === '3'
                                                                || validated && tipoDocumento !== '3' && documento !== "" ? true : false}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {
                                                                documento === "" ? "O campo Documento é obrigatório." : "Forneça um Documento válido"
                                                            }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={1} controlId="formDDD">
                                                        <Form.Label>DDD</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            as={InputMask}
                                                            mask='99'
                                                            value={ddd}
                                                            onChange={(e) => setDdd(e.target.value)}
                                                            required
                                                            isInvalid={validated && !/^\d{2}$/.test(String(ddd)) ? true : false}
                                                            isValid={validated && /^\d{2}$/.test(String(ddd)) ? true : false}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo DDD é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={5} controlId="formTelefone">
                                                        <Form.Label>Telefone</Form.Label><br />
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            as={InputMask}
                                                            mask='99999 9999'
                                                            value={telefone}
                                                            onChange={(e) => setTelefone(e.target.value)}
                                                            required
                                                            isInvalid={validated && !/^\d{1}\d{4} \d{4}$/.test(String(telefone))
                                                                && !/^\d{1}\d{4}\d{4}$/.test(String(telefone))
                                                                ? true : false}
                                                            isValid={(validated && /^\d{1}\d{4} \d{4}$/.test(String(telefone)))
                                                                || (validated && /^\d{1}\d{4}\d{4}$/.test(String(telefone)))
                                                                ? true : false} >
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">O campo Telefone é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formCep">
                                                        <Form.Label>Cep</Form.Label><br />
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            as={InputMask}
                                                            mask='99.999-999'
                                                            value={cep}
                                                            onChange={(e) => setCep(e.target.value)}
                                                            required
                                                            isInvalid={validated && !/^\d{2}.\d{3}-\d{3}$/.test(String(cep)) ? true : false}
                                                            isValid={validated && /^\d{2}.\d{3}-\d{3}$/.test(String(cep)) ? true : false}>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">O campo Cep é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formLogradouro">
                                                        <Form.Label>Logradouro</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            maxLength="150"
                                                            type="text"
                                                            value={logradouro}
                                                            onChange={(e) => setLogradouro(e.target.value)}
                                                            required
                                                            isInvalid={validated && logradouro.trim() === "" ? true : false}
                                                            isValid={validated && logradouro.trim() !== "" ? true : false} />
                                                        <Form.Control.Feedback type="invalid">O campo Logradouro é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={2} controlId="formNumero">
                                                        <Form.Label>Número</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            maxLength="10"
                                                            type="text"
                                                            value={numero}
                                                            onChange={(e) => setNumero(e.target.value)}
                                                            required
                                                            isInvalid={validated && numero.trim() === "" ? true : false}
                                                            isValid={validated && numero.trim() !== "" ? true : false} />
                                                        <Form.Control.Feedback type="invalid">O campo Número é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={4} controlId="formComplemento">
                                                        <Form.Label>Complemento</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            type="text"
                                                            value={complemento}
                                                            onChange={(e) => setComplemento(e.target.value)}
                                                            maxLength="250" />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formBairro">
                                                        <Form.Label>Bairro</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            type="text"
                                                            value={bairro}
                                                            onChange={(e) => setBairro(e.target.value)}
                                                            required
                                                            maxLength="100"
                                                            isInvalid={validated && bairro.trim() === "" ? true : false}
                                                            isValid={validated && bairro.trim() !== "" ? true : false} />
                                                        <Form.Control.Feedback type="invalid">O campo Bairro é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formMunicipio">
                                                        <Form.Label>Município</Form.Label>
                                                        <Form.Select
                                                            disabled={ativo ? false : true}
                                                            onChange={(e) => setMunicipio(e.target.value)}
                                                            value={municipio}
                                                            required
                                                            isInvalid={validated && municipio === "" ? true : false}
                                                            isValid={validated && municipio !== "" ? true : false}>
                                                            <option value="">Selecione...</option>
                                                            {
                                                                listaMunicipio != undefined ?
                                                                    listaMunicipio.map(m => (
                                                                        <option value={m.id} key={m.id}>{m.nome}</option>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">O campo Município é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={4}>
                                                        <Form.Check
                                                            size="lg"
                                                            label="Ativar conta"
                                                            type="switch"
                                                            id="custom-switch"
                                                            onChange={() => setAtivo(!ativo)}
                                                            checked={ativo}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={4}>
                                                        <Form.Check
                                                            size="lg"
                                                            label="Preferências"
                                                            type="switch"
                                                            id="custom-switch"
                                                            onChange={() => { setPreferencia(!preferencia); setIdContrato(0); }}
                                                            checked={preferencia}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={4}>
                                                        <Form.Check
                                                            size="lg"
                                                            label="Fornecedor"
                                                            type="switch"
                                                            id="custom-switch"
                                                            onChange={() => { setFornecedor(!fornecedor); setIdFornecedor(0); }}
                                                            checked={fornecedor}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6}>
                                                        {
                                                            preferencia ?
                                                                <div>
                                                                    <Form.Group as={Col} md={12}>
                                                                        <Form.Label>Preferência contrato</Form.Label>
                                                                        <Form.Select
                                                                            disabled={ativo ? false : true}
                                                                            onChange={(e) => setIdContrato(e.target.value)}
                                                                            value={idContrato}
                                                                            required
                                                                            isInvalid={(validated && idContrato == 0) || (validated && idContrato !== 0 && !ValidaContrato(idContrato)) ? true : false}
                                                                            isValid={validated && ValidaContrato(idContrato) && idContrato != 0 ? true : false}>
                                                                            <option value="">Selecione...</option>
                                                                            {
                                                                                listaContrato != undefined ?
                                                                                    listaContrato.map(m => (
                                                                                        <option value={m.id} key={m.id}>{m.descricao}</option>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">O campo Preferências é obrigatório.</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                                :
                                                                <div></div>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={6}>
                                                        {
                                                            fornecedor ?
                                                                <div>
                                                                    <Form.Group as={Col} md={12}>
                                                                        <Form.Label>Fornecedor</Form.Label>
                                                                        <Form.Select
                                                                            disabled={ativo ? false : true}
                                                                            onChange={(e) => setIdFornecedor(e.target.value)}
                                                                            value={idFornecedor}
                                                                            required
                                                                            isInvalid={(validated && idFornecedor == 0) || (validated && idFornecedor !== 0 && !ValidaFornecedor(idFornecedor)) ? true : false}
                                                                            isValid={validated && ValidaFornecedor(idFornecedor) && idFornecedor != 0 ? true : false}>
                                                                            <option value="">Selecione...</option>
                                                                            {
                                                                                listaFornecedor != undefined ?
                                                                                    listaFornecedor.map(m => (
                                                                                        <option value={m.id} key={m.id}>{m.nomeFornecedor}</option>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">O campo Fornecedor é obrigatório.</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                                :
                                                                <div></div>
                                                        }
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formCargo">
                                                        <Form.Label>Cargo</Form.Label>
                                                        <Form.Control
                                                            disabled={ativo ? false : true}
                                                            type="text"
                                                            value={cargo}
                                                            onChange={e => setCargo(e.target.value)}
                                                            required
                                                            maxLength="150"
                                                            isInvalid={validated && cargo.trim() === "" ? true : false}
                                                            isValid={validated && cargo.trim() !== "" ? true : false} />
                                                        <Form.Control.Feedback type="invalid">
                                                            O campo Cargo é obrigatório.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={2}>
                                                        <Form.Label></Form.Label>
                                                        <Form.Check
                                                            size="lg"
                                                            label="Habilitar histórico de filtros"
                                                            type="switch"
                                                            id="custom-switch"
                                                            onChange={() => { setHistoricoFiltro(!historicoFiltro); }}
                                                            checked={historicoFiltro}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={4}>
                                                        <Form.Label></Form.Label>
                                                        <Form.Check
                                                            size="lg"
                                                            label="Validar Conta"
                                                            type="switch"
                                                            id="custom-switch"
                                                            onChange={() => { setValidarConta(!validarConta); }}
                                                            checked={validarConta}
                                                            disabled={validarConta}
                                                        />
                                                    </Form.Group>
                                                    <Modal
                                                        show={modalSenha}
                                                        onHide={() => setModalSenha(false)}
                                                        dialogClassName="modal-90w"
                                                        aria-labelledby="example-custom-modal-styling-title"
                                                    >
                                                        <Modal.Header>
                                                        <Modal.Title id="example-custom-modal-styling-title">
                                                           Nova Senha
                                                        </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                          {senha}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="primary" onClick={fecharModalSenha}>
                                                                Fechar
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="cardBodyButtomSubmit">
                                                        <Link to="/Usuario">
                                                            <Button variant="secondary">Voltar</Button>
                                                        </Link>
                                                        {
                                                            inserir == true && id == undefined ?
                                                                <Button variant="success" type="submit" id="idCadastrar">
                                                                    Cadastrar
                                                                </Button>
                                                                :
                                                                ''

                                                        }
                                                        {
                                                            alterar == true && id != undefined ?
                                                                <Button variant="success" type="submit" id="idCadastrar">
                                                                    Alterar
                                                                </Button>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            <Button onClick={novaSenha} hidden={usuarioAtual && usuarioAtual.grupoAcesso &&
                                                            usuarioAtual.grupoAcesso.nomeGrupo === "Super Administrador" ? false : true}>
                                                                Nova Senha
                                                            </Button>
                                                        }
                                                        {
                                                            localStorage.getItem('script') != null ?
                                                                <Button onClick={rodaScriptInteressadoTelefone}>RODAR SCRIPT</Button>
                                                                :
                                                                ''
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </div >

        </>
    );
}