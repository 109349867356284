export function ListarItemCustoRequest(){
    return {
      type: 'LISTAR_ITEM_CUSTO_REQUEST'
    }
  }
  

export function ListarItemCustoResponse(listaItemCusto){
  return {
    type: 'LISTAR_ITEM_CUSTO_RESPONSE',
    listaItemCusto
  }
}

export function InserirItemCustoRequest(data, idContrato){
  return {
    type: 'INSERIR_ITEM_CUSTO_REQUEST',
    data, idContrato
  }
}

export function AlterarItemCustoRequest(data, idContrato){
  return {
    type: 'ALTERAR_ITEM_CUSTO_REQUEST',
    data, idContrato
  }
}

export function ExcluirItemCustoRequest(id){
  return {
    type: 'EXCLUIR_ITEM_CUSTOREQUEST',
    id
  }
}

export function ListarItemCustoCompletoRequest(id) {
    return {
        type: 'LISTAR_ITEM_CUSTO_COMPLETO_REQUEST',
        id
    }
}

export function ListarItemCustoCompletoResponse(listaCompletaItemCusto) {
    return {
        type: 'LISTAR_ITEM_CUSTO_COMPLETO_RESPONSE',
        listaCompletaItemCusto
    }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}