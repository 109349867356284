import { useEffect, useState } from "react";
import { Col, Button, Modal, Row } from "react-bootstrap";
import {
    ListarEventoAdicionalRequest, ExcluirEventoAdicionalRequest,
    InserirEventoAdicionalRequest, AlterarEventoAdicionalRequest
} from '../../../store/modules/eventoAdicional/actions';
import { useDispatch, useSelector } from 'react-redux';
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarTipoSolicitacaoRequest } from "../../../store/modules/tipoSolicitacao/actions";
import { ListarOrgaoRequest } from "../../../store/modules/orgao/actions";
import { ListarUsuarioRequest } from "../../../store/modules/usuario/actions";
import {
    ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest
    , ListarDadosExportarPDFSolicitacaoFornecimentoRequest
} from "../../../store/modules/relatorio/actions";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { BiExport } from "react-icons/bi";
import { FaSearchPlus } from "react-icons/fa";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import PdfOrcamentoEvento from "../../../components/pdfOrcamentoEvento/pdfOrcamentoEvento";
import Select from 'react-select';
import ModalInserirEventoAdicional from "../../../components/ModalInserirEventoAdicional/modalInserirEventoAdicional";

export default function EventoAdicional() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const listaEventoAdicional = useSelector(state => state.eventoAdicional.listaEventoAdicional);
    const listaTipoSolicitacao = useSelector(state => state.tipoSolicitacao.listaTipoSolicitacao);
    const carregando = useSelector(state => state.eventoAdicional.carregando);
    const listaDadosExportarPDFSolicitacaoFornecimentoEvento = useSelector(state => state.relatorio.listaDadosExportarPDFSolicitacaoFornecimentoEvento);
    const listaUsuario = useSelector(state => state.usuario.listaUsuario);
    const listaOrgao = useSelector(state => state.orgao.listaOrgao);

    const [idEventoAdicional, setIdEventoAdicional] = useState(undefined);
    const [show, setShow] = useState(false);
    const [showModalInserirEventoAdicional, setShowModalInserirEventoAdicional] = useState(false);

    const handleCloseModalInserirEventoAdicional = () => { setShowModalInserirEventoAdicional(false); setIdEventoAdicional(undefined) };
    const handleShowModalInserirEventoAdicional = () => setShowModalInserirEventoAdicional(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    //const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        dispatch(ListarUsuarioRequest());
        dispatch(ListarOrgaoRequest());
        dispatch(ListarTipoSolicitacaoRequest());

        if (id != undefined)
            dispatch(ListarEventoAdicionalRequest(parseInt(id)));

    }, [])

    const options = listaTipoSolicitacao != undefined ?
        listaTipoSolicitacao.filter(x => x.eventoEspecial == true).map(tipoSolicitacao => ({
            value: tipoSolicitacao.id, label: tipoSolicitacao.descricao
        }))
        :
        [];

    const optionsUsuario = listaUsuario != undefined ?
        listaUsuario.map(usuario => ({
            value: usuario.id, label: usuario.nome
        }))
        :
        [];

    const optionsOrgao = listaOrgao != undefined ?
        listaOrgao.map(orgao => ({
            value: orgao.id, label: orgao.descricao
        }))
        :
        [];

    function ValidacoesInserir(eventoAdicional) {

        //valida o campo descrição
        /*if (eventoAdicional.descricao == null || eventoAdicional.descricao == undefined ||
            eventoAdicional.descricao.trim() == '' || eventoAdicional.descricao.length > 50) {
            toast.error(`Composição custo inválida`);
            return false;
        }*/

        return true;

    }

    function InserirEventoAdicional(eventoAdicional) {

        let selectText = '';
        let selectSelecionado = [];

        let selectTextAutorizador = '';
        let selectSelecionadoAutorizador = [];

        let selectTextDestinatario = '';
        let selectSelecionadoDestinatario = [];

        let selectTextPagador = '';
        let selectSelecionadoPagador = [];
        /*
                if (document.getElementById('idSelectTipoSolicitacao') != null && listaTipoSolicitacao != undefined) {
                    selectText = document.getElementById('idSelectTipoSolicitacao').innerText;
                    selectSelecionado = listaTipoSolicitacao.filter(contrato => contrato.descricao == selectText);
                }
        
                if (document.getElementById('idSelectAutorizador') != null && listaUsuario != undefined) {
                    selectTextDestinatario = document.getElementById('idSelectAutorizador').innerText;
                    selectSelecionadoDestinatario = listaUsuario.filter(contrato => contrato.nome == selectText);
                }
        
                if (document.getElementById('selectSelecionadoDestinatario') != null && listaUsuario != undefined) {
                    selectTextDestinatario = document.getElementById('selectSelecionadoDestinatario').innerText;
                    selectSelecionadoDestinatario = listaUsuario.filter(contrato => contrato.nome == selectText);
                }
        
                if (document.getElementById('idSelectPagador') != null && listaOrgao != undefined) {
                    selectTextDestinatarioPagador = document.getElementById('idSelectPagador').innerText;
                    selectSelecionadoDestinatarioPagador = listaOrgao.filter(contrato => contrato.descricao == selectText);
                }*/



        if (selectSelecionado.length > 0 && selectSelecionadoAutorizador.length > 0 &&
            selectSelecionadoDestinatario.length > 0 && selectSelecionadoPagador.length > 0) {

            let data = {
                'Id': 0,
                'IdTipoSolicitacao': parseInt(selectSelecionado[0].id),
                'IdSolicitacaoFornecimento': parseInt(id),
                'Autorizador': selectTextAutorizador,
                'Destinatario': selectTextDestinatario,
                'Pagador': selectTextPagador,
                'Observacao': eventoAdicional.observacao,
                'CargaEstimada': eventoAdicional.cargaEstimada,
                'NumeroRefletores': eventoAdicional.numeroRefletores,
                'NumeroOficio': eventoAdicional.numeroOficio
            }

            dispatch(InserirEventoAdicionalRequest(data, parseInt(id)))
        }

    }

    function AlterarEventoAdicional(eventoAdicional) {

        let data = {
            'Id': eventoAdicional.id,
            'IdTipoSolicitacao': eventoAdicional.idTipoSolicitacao,
            'IdSolicitacaoFornecimento': eventoAdicional.idSolicitacaoFornecimento,
            'Autorizador': eventoAdicional.autorizador,
            'Destinatario': eventoAdicional.destinatario,
            'Pagador': eventoAdicional.pagador,
            'Observacao': eventoAdicional.observacao,
            'CargaEstimada': eventoAdicional.cargaEstimadaUm,
            'NumeroRefletores': eventoAdicional.numeroRefletores,
            'NumeroOficio': eventoAdicional.numeroOficio
        }

        dispatch(AlterarEventoAdicionalRequest(data, parseInt(id)))
    }

    function ExcluirEventoAdicional(eventoAdicional) {

        if (listaTipoSolicitacao != undefined && listaTipoSolicitacao.filter(x => x.id == parseInt(eventoAdicional.idTipoSolicitacao)).length > 0 &&
            listaTipoSolicitacao.filter(x => x.id == parseInt(eventoAdicional.idTipoSolicitacao))[0].servico) {
            toast.error('Não é possível excluir um evento adicional com serviço!');
        } else {
            dispatch(ExcluirEventoAdicionalRequest(eventoAdicional.id, parseInt(id)));
        }
    }

    function ExportarPdf(rowData) {
        dispatch(ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest({
            'IdSolicitacaoFornecimento': id,
            'IdEventoAdicional': parseInt(rowData.id)
        }))
        handleShow();
    }

    function AbrirModalEvento(id) {
        setIdEventoAdicional(id);
        handleShowModalInserirEventoAdicional();
    }

    return (
        <>
            <div>
                <Col xs={12}>
                    <MaterialTable
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        title={
                            <Button variant="success" onClick={handleShowModalInserirEventoAdicional} style={{ width: '100%' }}>Novo Evento Adicional</Button>
                        }
                        columns={[
                            {
                                title: 'Número Ofício', field: 'numeroOficio', cellStyle: {
                                    width: '9%',
                                }, editable: 'never', defaultSort: 'desc'
                            },
                            {

                                title: 'Tipo Solicitação', field: 'idTipoSolicitacao', cellStyle: {
                                    width: '13%',
                                },
                                editable: 'onAdd',
                                filtering: false,
                                sorting: false,
                                filterComponent: (props) => {
                                    return (
                                        <Select
                                            placeholder="Selecione..."
                                            isClearable={true}
                                            isSearchable={true}
                                            options={options}
                                            onChange={(e) => {
                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.label : false)
                                            }}
                                        />
                                    )
                                },
                                lookup:
                                    listaTipoSolicitacao != undefined ?
                                        listaTipoSolicitacao.map(tipoSolicitacao => {
                                            return {
                                                id: tipoSolicitacao.id, descricao: tipoSolicitacao.descricao
                                            }
                                        }).reduce(function (acc, cur, i) {
                                            acc[cur.id] = cur.descricao;
                                            return acc;
                                        }, {})
                                        :
                                        null,
                                editComponent: (props) => (
                                    <Select id="idSelectTipoSolicitacao"
                                        options={options}
                                        placeholder="Selecione..."
                                        defaultValue={{
                                            value: props.value, label: listaTipoSolicitacao != undefined && listaTipoSolicitacao.filter(emp => emp.id == props.value).length > 0
                                                ? listaTipoSolicitacao.filter(emp => emp.id == props.value)[0].descricao
                                                : 'Selecione...'
                                        }}
                                    />
                                )
                            },
                            {
                                title: 'Autorizador', field: 'autorizador', cellStyle: {
                                    width: '13%',
                                }
                            },
                            {
                                title: 'Destinatário', field: 'destinatario', cellStyle: {
                                    width: '13%',
                                }
                            },
                            {
                                title: 'Pagador', field: 'pagador', cellStyle: {
                                    width: '13%',
                                }
                            },
                            {
                                title: 'Observação', field: 'observacao', cellStyle: {
                                    width: '13%',
                                }
                            },
                            {
                                title: 'Carga Estimada', field: 'cargaEstimada', cellStyle: {
                                    width: '13%',
                                }
                            },
                            {
                                title: 'Número Refletores', field: 'numeroRefletores', cellStyle: {
                                    width: '13%',
                                }
                            }
                        ]}
                        data={
                            listaEventoAdicional != undefined ?
                                listaEventoAdicional.map(eventoAdicional => {
                                    return {
                                        id: eventoAdicional.id,
                                        idTipoSolicitacao: eventoAdicional.idTipoSolicitacao,
                                        idSolicitacaoFornecimento: eventoAdicional.idSolicitacaoFornecimento,
                                        autorizador: eventoAdicional.autorizador,
                                        destinatario: eventoAdicional.destinatario,
                                        pagador: eventoAdicional.pagador,
                                        observacao: eventoAdicional.observacao,
                                        cargaEstimada: eventoAdicional.cargaEstimada,
                                        numeroRefletores: eventoAdicional.numeroRefletores,
                                        numeroOficio: eventoAdicional.numeroOficio
                                    }
                                })
                                :
                                []
                        }
                        icons={{
                            Add: () =>
                                <Button variant="success">
                                    <span>
                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                    </span>
                                </Button>
                        }}
                        editable={{
                            /*onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                TodasOperacoes ?
                                    setTimeout(() => {
                                        if (!ValidacoesInserir(newRow)) {
                                            reject();
                                        }
                                        else {
                                            InserirEventoAdicional(newRow);
                                            resolve()
                                        }
                                    }, 1000)
                                    :
                                    setTimeout(() => {
                                        toast.error(`Você não tem permissão`);
                                        resolve()
                                    }, 1000)
                            }),*/

                            /*
                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                TodasOperacoes ?
                                    setTimeout(() => {
                                        if (!ValidacoesInserir(newRow)) {
                                            reject();
                                        }
                                        else {
                                            AlterarEventoAdicional(newRow);
                                            resolve()
                                        }
                                    }, 1000)
                                    :
                                    setTimeout(() => {
                                        toast.error(`Você não tem permissão`);
                                        resolve()
                                    }, 1000)
                            }),*/

                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    ExcluirEventoAdicional(oldData);
                                    resolve()
                                }, 1000)
                            })
                        }}
                        options={{
                            minBodyHeight: 500,
                            paging: true,
                            pageSize: 10, // Início
                            emptyRowsWhenPaging: false,
                            addRowPosition: "first",
                            pageSizeOptions: [10, 50, 200, 300],
                            headerStyle: {
                                backgroundColor: '#454545',
                                color: '#FFF',
                                fontWeight: "bold",
                                lineBreak: "auto",
                                fontSize: "13px"
                            },
                            actionsColumnIndex: -1,
                            filtering: true
                        }}
                        style={{
                            fontSize: "13px",
                        }}
                        actions={[
                            rowData => ({
                                icon: () => <FaSearchPlus />,
                                onClick: (event, rowData) => { AbrirModalEvento(rowData.id) }
                            }),
                            rowData => ({
                                icon: () => <BiExport />,
                                tooltip: <span style={{ fontSize: '1.5em' }}>{'Exportar pdf'}</span>,
                                onClick: (event, rowData) => { ExportarPdf(rowData) }
                            })
                        ]}
                        localization={{
                            header: {
                                actions: <span className="classHeaderTabela">Ações</span>
                            },
                            body: {
                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                editRow: {
                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                    saveTooltip: '',
                                    cancelTooltip: '',
                                },
                                deleteTooltip: '',
                                editTooltip: '',
                                addTooltip: '',
                            },
                            toolbar: {
                                searchTooltip: '',
                                searchPlaceholder: 'Pesquisar',
                                exportTitle: "",
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                            },
                            pagination: {
                                labelRowsSelect: 'linhas',
                                labelDisplayedRows: '{count} de {from}-{to}',
                                firstTooltip: '',
                                previousTooltip: '',
                                nextTooltip: '',
                                lastTooltip: ''
                            },
                            grouping: {
                                placeholder: 'Arraste a coluna aqui para agrupar',
                                groupedBy: 'Agrupado por:'
                            }
                        }}
                    />
                </Col>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Deseja exportar o evento adicional?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Row>
                        <Col md={12}>
                            {
                                listaDadosExportarPDFSolicitacaoFornecimentoEvento != null ?
                                    <PDFDownloadLink document={
                                        <PdfOrcamentoEvento
                                            data={listaDadosExportarPDFSolicitacaoFornecimentoEvento != null ? listaDadosExportarPDFSolicitacaoFornecimentoEvento : null}
                                        />} fileName="solicitacaoFornecimentoEvento.pdf">
                                        <Button
                                            onClick={handleClose}
                                            variant="success"
                                            style={{ width: '100%' }}>
                                            Exportar
                                        </Button>
                                        {({ blob, url, loading, error }) => (loading ? 'Carregando autorização fornecimento Evento...' : 'Exportar como PDF')}
                                    </PDFDownloadLink>
                                    :
                                    <Loading />
                            }

                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <ModalInserirEventoAdicional
                show={showModalInserirEventoAdicional}
                onHide={handleCloseModalInserirEventoAdicional}
                idEventoAdicional={idEventoAdicional != undefined ? idEventoAdicional : undefined}
            />

        </>
    )
}