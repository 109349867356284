import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
    ListarCategoriaItemRequest, ExcluirCategoriaItemRequest,
    InserirCategoriaItemRequest, AlterarCategoriaItemRequest
} from '../../../store/modules/categoriaItem/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MdCategory } from "react-icons/md";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarContratoRequest } from '../../../store/modules/contrato/actions';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import ModalCategoriaItemSubitemCustoPrimario from "../../../components/ModalCategoriaItemSubitemCustoPrimario/modalCategoriaItemSubitemCustoPrimario";
import MaterialTable from 'material-table';
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";

export default function CategoriaItem() {

    const dispatch = useDispatch();
    const history = useHistory();

    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const listaCategoriaItem = useSelector(state => state.categoriaItem.listaCategoriaItem);
    const carregando = useSelector(state => state.categoriaItem.carregando);

    const [selectedRow, setSelectedRow] = useState(null);
    const [show, setShow] = useState(false);
    const [idCategoriaItem, setIdCategoriaItem] = useState('');
    const [idContrato, setIdContrato] = useState('');
    const [selectContrato, setSelectContrato] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = listaContrato != undefined ?
        listaContrato.map(contrato => ({
            value: contrato.id, label: contrato.descricao
        }))
        :
        [];

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {

        dispatch(ListarContratoRequest());
        dispatch(ListarCategoriaItemRequest());


    }, [])

    var selectTextContrato = '';
    var selectSelecionadoContrato = [];

    function ValidacoesInserir(categoriaItem) {

        // Pega o contrato selecionado
        if (document.getElementById('idSelectContrato') != null && listaContrato != undefined) {
            selectTextContrato = document.getElementById('idSelectContrato').innerText;
            selectSelecionadoContrato = listaContrato.filter(contrato => contrato.descricao == selectTextContrato);
        }

        //Validação do campo descrição
        if (categoriaItem.descricao == null || categoriaItem.descricao == undefined
            || categoriaItem.descricao.length > 50 || categoriaItem.descricao.trim() == '') {
            toast.error(`Descrição da categoria inválida`);
            return false;
        }

        if (selectSelecionadoContrato.length > 0) {
            return true;
        } else {
            toast.error(`Contrato não selecionado`);
            return false;
        }


    }

    function InserirCategoriaItem(categoriaItem) {

        let data = {
            'Id': parseInt(0),
            'Descricao': categoriaItem.descricao.trim(),
            'IdContrato': parseInt(selectSelecionadoContrato[0].id)
        }

        dispatch(InserirCategoriaItemRequest(data));

    }

    function ValidacoesAlterar(categoriaItem) {

        //Validação do campo descrição
        if (categoriaItem.descricao == null || categoriaItem.descricao == undefined
            || categoriaItem.descricao.length > 50 || categoriaItem.descricao.trim() == '') {
            toast.error(`Descrição da categoria inválida`);
            return false;
        }

        return true;

    }

    function AlterarCategoriaItem(categoriaItem) {

        let data = {
            'Id': categoriaItem.id,
            'Descricao': categoriaItem.descricao.trim(),
            'IdContrato': categoriaItem.idContrato
        }

        dispatch(AlterarCategoriaItemRequest(data))
    }

    function ExcluirCategoriaItem(id) {
        dispatch(ExcluirCategoriaItemRequest(id));
    }

    function VisualizarSubitem(categoriaItem) {
        setIdCategoriaItem(categoriaItem.id);
        setIdContrato(categoriaItem.idContrato);
        setShow(true);
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <MdCategory size={25} color={"#000"} /> Categoria Item
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregando ?

                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Número da Categoria', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                    width: '10%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Descrição', field: 'descricao', sorting: true, editPlaceholder: 'Descrição', validate: rowData => {
                                                                    if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    else if (rowData.descricao.trim().length > 50) {
                                                                        return "A Descrição não pode conter mais que 50 caracteres"
                                                                    }
                                                                    return true
                                                                }, cellStyle: { width: '45%' }
                                                            },
                                                            {
                                                                title: 'Contrato', field: 'idContrato', cellStyle: {
                                                                    width: '45%',
                                                                }, editable: 'onAdd',
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={options}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value ? e.value.toString() : false)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                lookup:
                                                                    listaContrato != undefined ?
                                                                        listaContrato.map(contrato => {
                                                                            return {
                                                                                id: contrato.id, descricao: contrato.descricao
                                                                            }
                                                                        }).reduce(function (acc, cur, i) {
                                                                            acc[cur.id] = cur.descricao;
                                                                            return acc;
                                                                        }, {})
                                                                        :
                                                                        null,
                                                                editComponent: (props) => (
                                                                    <Select id="idSelectContrato"
                                                                        options={options}
                                                                        placeholder="Selecione..."
                                                                        defaultValue={{
                                                                            value: props.value, label: listaContrato != undefined && listaContrato.filter(emp => emp.id == props.value).length > 0
                                                                                ? listaContrato.filter(emp => emp.id == props.value)[0].descricao
                                                                                : 'Selecione...'
                                                                        }}
                                                                    />
                                                                )
                                                            },
                                                        ]}
                                                        data={
                                                            listaCategoriaItem != undefined ?
                                                                listaCategoriaItem.map(categoriaItem => {
                                                                    return {
                                                                        id: categoriaItem.id,
                                                                        descricao: categoriaItem.descricao,
                                                                        idContrato: categoriaItem.idContrato
                                                                    }
                                                                })
                                                                :
                                                                []
                                                        }
                                                        icons={{
                                                            Add: () =>
                                                                <Button variant="success">
                                                                    <span>
                                                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                                                    </span>
                                                                </Button>
                                                        }}
                                                        editable={{
                                                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes == true ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            InserirCategoriaItem(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes == true ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesAlterar(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            AlterarCategoriaItem(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                TodasOperacoes == true ?
                                                                    setTimeout(() => {
                                                                        ExcluirCategoriaItem(oldData.id);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            })
                                                        }}
                                                        actions={[
                                                            rowData => ({
                                                                icon: 'visibility',
                                                                onClick: (event, rowData) => { VisualizarSubitem(rowData) }
                                                            })
                                                        ]}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            addRowPosition: "first",
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            })

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Loading />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <ModalCategoriaItemSubitemCustoPrimario
                show={show}
                onHide={handleClose}
                idCategoriaItem={idCategoriaItem}
                idContrato={idContrato}
            />
        </>
    )
}