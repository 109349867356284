import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
    ListarRegiaoRequest, ExcluirRegiaoRequest,
    InserirRegiaoRequest, AlterarRegiaoRequest
} from '../../../store/modules/regiao/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IoMapOutline } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

export default function Regiao() {

    const dispatch = useDispatch();
    const history = useHistory();
    const listaRegiao = useSelector(state => state.regiao.listaRegiao);
    const carregando = useSelector(state => state.regiao.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarRegiaoRequest());
    }, [])

    function ValidacoesInserir(regiao) {

        //valida o campo descrição
        if (regiao.descricao == null || regiao.descricao == undefined
            || regiao.descricao.trim() == '' || regiao.descricao.length > 150) {
            toast.error(`Região inválida`);
            return false;
        }

        return true;

    }

    function InserirRegiao(regiao) {

        let data = {
            'Id': 0,
            'Descricao': regiao.descricao.trim(),
        }

        dispatch(InserirRegiaoRequest(data))
    }

    function AlterarRegiao(regiao) {

        let data = {
            'Id': regiao.id,
            'Descricao': regiao.descricao.trim()
        }

        dispatch(AlterarRegiaoRequest(data))
    }

    function ExcluirRegiao(idRegiao) {
        dispatch(ExcluirRegiaoRequest(idRegiao));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <IoMapOutline size={25} color={"#000"} /> Regiao
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregando ?

                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Número da Região', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                    width: '20%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Nome', field: 'descricao', sorting: true, editPlaceholder: 'Nome', validate: rowData => {
                                                                    if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    else if (rowData.descricao.trim().length > 150) {
                                                                        return "O nome não pode conter mais que 150 caracteres"
                                                                    }
                                                                    return true
                                                                }, cellStyle: { width: '80%' }
                                                            },
                                                        ]}
                                                        data={
                                                            listaRegiao != undefined ?
                                                                listaRegiao.map(regiao => {
                                                                    return {
                                                                        id: regiao.id,
                                                                        descricao: regiao.descricao,
                                                                    }
                                                                })
                                                                :
                                                                []
                                                        }
                                                        icons={{
                                                            Add: () =>
                                                                <Button variant="success">
                                                                    <span>
                                                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                                                    </span>
                                                                </Button>
                                                        }}
                                                        editable={{
                                                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            InserirRegiao(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            AlterarRegiao(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),

                                                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        ExcluirRegiao(oldData.id);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            })
                                                        }}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            })

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Loading />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}