import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse } from './actions';
import history from '../../../services/history';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';

function* ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubItemCustoMedicao: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));   
  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/listarCompleto`,
     { IdAutorizacaoFornecimentoSubItemCustoMedicao: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse(result.data.result));
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Fotos id: ' + listaId + ' cadastrada com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse([]));    
  }
}

function* AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubItemCustoMedicao: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Foto cadastrada com sucesso!`);    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse([]));    
  }
}

function* ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/${model.id}/${'0'}`, { headers: headerParams.token });

    toast.success(`Documento id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/listarCompleto`,
      { IdAutorizacaoFornecimentoSubItemCustoMedicao: parseInt(model.idMedicao) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }   

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse([]));

  }
}

function* BaixarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeRegistroFotografico}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoResponse([]));    

  }
}

function* CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest(model) {

  try {

    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
  
    fetch(`${urlBase}/api/AutorizacaoFornecimentoSubitemCustoMedicaoRegFoto/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
        .then(function (myBlob) {
            let elem = window.document.createElement('img');
            elem.src = window.URL.createObjectURL(myBlob);
            document.getElementById("divImagemFotoMedicaoAutorizacaoFornecimentoSubItem").appendChild(elem).style.width = "100%";        
        });
    
  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST', ListarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST', AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST', ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest),
  takeLatest('BAIXAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST', BaixarAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest),
  takeLatest('CARREGAR_IMAGEM_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_REQUEST', CarregarImagemAutorizacaoFornecimentoSubitemCustoMedicaoRegFotoRequest)
])