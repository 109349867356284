import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import {
    ListarInteressadoRequest, ExcluirInteressadoRequest,
    InserirInteressadoRequest, AlterarInteressadoRequest
} from '../../../store/modules/interessado/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IoPeopleOutline } from "react-icons/io5";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { ListarBairroRequest } from "../../../store/modules/bairro/actions";
import InputMask from "react-input-mask";
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";


export default function Interessado() {

    const dispatch = useDispatch();
    const history = useHistory();
    const listaInteressado = useSelector(state => state.interessado.listaInteressado);
    const carregando = useSelector(state => state.interessado.carregando);
    const listaBairro = useSelector(state => state.bairro.listaBairro);
    const [selectedRow, setSelectedRow] = useState(null);

    const options = listaBairro ?
        listaBairro.map(bairro => ({
            value: bairro.id, label: bairro.descricao
        }))
        :
        [];

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarBairroRequest());
        dispatch(ListarInteressadoRequest());
    }, [])

    function ValidacoesInserir(interessado) {

        let numeroTelefone = document.getElementById('idTelefone');
        let numeroDdd = document.getElementById('idDDD');
        let numeroTelefoneFixo = document.getElementById('idTelefoneFixo');
        let numeroDddFixo = document.getElementById('idDDDFixo');

        // Valida o campo nome
        if (interessado.nome == null || interessado.nome == undefined
            || interessado.nome.length > 100 || interessado.nome.trim() == '') {
            toast.error(`Interessado inválido`);
            return false;
        }

        // Valida o campo endereco
        if (interessado.endereco == null || interessado.endereco == undefined
            || interessado.endereco.length > 200 || interessado.endereco.trim() == '') {
            toast.error(`Endereço inválido`);
            return false;
        }

        // Valida o campo telefone
        if (numeroTelefone.value != null && numeroTelefone.value != undefined && numeroTelefone.value != '') {
            if (!ValidaTelefone(numeroTelefone.value)) {
                toast.error(`Telefone com formato inválido`);
                return false;
            }
        }

        // Valida o campo ddd
        if (numeroDdd.value != null && numeroDdd.value != undefined && numeroDdd.value != '') {
            if (!ValidacaoDdd(numeroDdd.value)) {
                toast.error(`DDD com formato inválido`);
                return false;
            }
        }

        // Valida o campo telefone fixo
        if (numeroTelefoneFixo.value != null && numeroTelefoneFixo.value != undefined && numeroTelefoneFixo.value != '') {
            if (!ValidaTelefoneFixo(numeroTelefoneFixo.value)) {
                toast.error(`Telefone Fixo com formato inválido`);
                return false;
            }
        }

        // Valida o campo ddd fixo
        if (numeroDddFixo.value != null && numeroDddFixo.value != undefined && numeroDddFixo.value != '') {
            if (!ValidacaoDddFixo(numeroDddFixo.value)) {
                toast.error(`DDD Fixo com formato inválido`);
                return false;
            }
        }

        if ((numeroDddFixo.value == null || numeroDddFixo.value == undefined || numeroDddFixo.value == '') &&
            (numeroTelefoneFixo.value == null || numeroTelefoneFixo.value == undefined || numeroTelefoneFixo.value == '') &&
            (numeroTelefone.value == null || numeroTelefone.value == undefined || numeroTelefone.value == '') &&
            (numeroDdd.value == null || numeroDdd.value == undefined || numeroDdd.value == '')) {
            toast.error(`É necessário que tenha pelo menos um tipo de telefone`);
            return false;
        }

        // Valida o campo cargo
        if (interessado.cargo !== null && interessado.cargo !== undefined && interessado.cargo !== "") {
            if (interessado.cargo.length > 150) {
                toast.error(`Cargo inválido`);
                return false;
            }
        }

        return true;

    }

    function InserirInteressado(interessado) {

        var selectTextBairro = '';
        var selectSelecionadoBairro = [];

        // Pega o Bairro selecionado
        if (document.getElementById('idSelectBairro') != null && listaBairro != undefined) {
            selectTextBairro = document.getElementById('idSelectBairro').innerText;
            selectSelecionadoBairro = listaBairro.filter(bairro => bairro.descricao == selectTextBairro);
        }

        if (selectSelecionadoBairro.length > 0) {

            let data = {
                'Id': 0,
                'Nome': interessado.nome.trim(),
                'Endereco': interessado.endereco,
                'Cep': interessado.cep === undefined || interessado.cep === '' ? null : interessado.cep,
                'idBairro': selectSelecionadoBairro[0].id,
                'NumeroDddFixo': interessado.numeroDddFixo === undefined || interessado.numeroDddFixo === '' ? null : interessado.numeroDddFixo,
                'NumeroTelefoneFixo': interessado.numeroTelefoneFixo === undefined || interessado.numeroTelefoneFixo === '' ? null : interessado.numeroTelefoneFixo,
                'NumeroDdd': interessado.numeroDdd === undefined || interessado.numeroDdd === '' ? null : interessado.numeroDdd,
                'NumeroTelefone': interessado.numeroTelefone === undefined || interessado.numeroTelefone === '' ? null : interessado.numeroTelefone,
                'DeputadoVereador': interessado.deputadoVereador != undefined ? interessado.deputadoVereador : false,
                'Cargo': interessado.cargo === undefined || interessado.cargo === '' ? null : interessado.cargo,
            }

            dispatch(InserirInteressadoRequest(data))

        } else {
            toast.error(`Bairro não selecionado`);
            return false;
        }
    }

    function AlterarInteressado(interessado) {

        var selectTextBairro = '';
        var selectSelecionadoBairro = [];

        // Pega o Bairro selecionado
        if (document.getElementById('idSelectBairro') != null && listaBairro != undefined) {
            selectTextBairro = document.getElementById('idSelectBairro').innerText;
            selectSelecionadoBairro = listaBairro.filter(bairro => bairro.descricao == selectTextBairro);
        }

        if (selectSelecionadoBairro.length > 0) {

            let data = {
                'Id': interessado.id,
                'Nome': interessado.nome.trim(),
                'Endereco': interessado.endereco,
                'Cep': interessado.cep === undefined || interessado.cep === '' ? null : interessado.cep,
                'idBairro': selectSelecionadoBairro[0].id,
                'NumeroDddFixo': interessado.numeroDddFixo === undefined || interessado.numeroDddFixo === '' ? null : interessado.numeroDddFixo,
                'NumeroTelefoneFixo': interessado.numeroTelefoneFixo === undefined || interessado.numeroTelefoneFixo === '' ? null : interessado.numeroTelefoneFixo,
                'NumeroDdd': interessado.numeroDdd === undefined || interessado.numeroDdd === '' ? null : interessado.numeroDdd,
                'NumeroTelefone': interessado.numeroTelefone === undefined || interessado.numeroTelefone === '' ? null : interessado.numeroTelefone,
                'DeputadoVereador': interessado.deputadoVereador != undefined ? interessado.deputadoVereador : false,
                'Cargo': interessado.cargo === undefined || interessado.cargo === '' ? null : interessado.cargo,
            }

            dispatch(AlterarInteressadoRequest(data))

        } else {
            toast.error(`Bairro não selecionado`);
            return false;
        }


    }

    function ValidacaoDdd(ddd) {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(ddd))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone(telefone) {
        const telefoneRegex = /^\d{1}\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidacaoDddFixo(dddFixo) {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(dddFixo))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefoneFixo(telefoneFixo) {
        const telefoneRegex = /^\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefoneFixo)) || telefoneRegex2.test(String(telefoneFixo))) {
            return true;
        } else {
            return false;
        }
    }

    function ExcluirInteressado(idInteressado) {
        dispatch(ExcluirInteressadoRequest(idInteressado));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <IoPeopleOutline size={25} color={"#000"} /> Interessado
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                carregando ?

                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Número do Interessado', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                                    width: '5%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Nome', field: 'nome', sorting: true, editPlaceholder: 'Nome', validate: rowData => {
                                                                    if (rowData.nome === undefined || rowData.nome.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    else if (rowData.nome.trim().length > 100) {
                                                                        return "O nome não pode conter mais que 100 caracteres"
                                                                    }
                                                                    return true
                                                                }, cellStyle: { width: '10%' }
                                                            },
                                                            {
                                                                title: 'Cargo', field: 'cargo', cellStyle: {
                                                                    width: '5%',
                                                                },
                                                                validate: rowData => {
                                                                    if (rowData.cargo !== null && rowData.cargo !== undefined && rowData.cargo !== "") {
                                                                        if (rowData.cargo.length > 150) {
                                                                            return "O cargo não pode conter mais que 150 caracteres"
                                                                        }
                                                                    }
                                                                    return true
                                                                }
                                                            },
                                                            {
                                                                title: 'Endereço', field: 'endereco',
                                                                validate: rowData => {
                                                                    if (rowData.endereco === undefined || rowData.endereco.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    else if (rowData.endereco.trim().length > 200) {
                                                                        return "O endereço não pode conter mais que 200 caracteres"
                                                                    }
                                                                    return true
                                                                },
                                                                cellStyle: {
                                                                    width: '10%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Bairro', field: 'bairro',
                                                                filtering: false,
                                                                searchable: false,
                                                                lookup:
                                                                    listaBairro != undefined && listaBairro.length > 0 ?

                                                                        listaBairro.map(bairro => {
                                                                            return {
                                                                                id: bairro.id, descricao: bairro.descricao
                                                                            }
                                                                        }).reduce(function (acc, cur, i) {
                                                                            acc[cur.id] = cur.descricao;
                                                                            return acc;
                                                                        }, {})
                                                                        :
                                                                        null,
                                                                editComponent: (props) => (
                                                                    <Select id="idSelectBairro"
                                                                        options={options}
                                                                        placeholder="Selecione..."
                                                                        defaultValue={{
                                                                            value: props.value, label: listaBairro.filter(bairro => bairro.id == props.value).length > 0
                                                                                ? listaBairro.filter(bairro => bairro.id == props.value)[0].descricao
                                                                                : 'Selecione...'
                                                                        }}
                                                                    />
                                                                ),
                                                                cellStyle: {
                                                                    width: '15%',
                                                                }
                                                            },
                                                            {
                                                                title: 'Cep', field: 'cep', cellStyle: {
                                                                    width: '10%',
                                                                },
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={'99.999-999'} id="idCep" style={{ width: "100%" }} className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'DDD', field: 'numeroDddFixo', cellStyle: {
                                                                    width: '5%',
                                                                },
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        style={{ width: "100%" }} id="idDDDFixo" maxLength={2} className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'Telefone Fixo', field: 'numeroTelefoneFixo', cellStyle: {
                                                                    width: '10%',
                                                                },
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"9999 9999"} id="idTelefoneFixo" style={{ width: "100%" }} className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'DDD', field: 'numeroDdd', cellStyle: {
                                                                    width: '5%',
                                                                },
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"99"} id="idDDD" style={{ width: "100%" }} className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'Telefone', field: 'numeroTelefone', cellStyle: {
                                                                    width: '10%',
                                                                },
                                                                editComponent: (props) => (
                                                                    <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                                        mask={"99999 9999"} id="idTelefone" style={{ width: "100%" }} className="InputMask" />
                                                                ),
                                                            },
                                                            {
                                                                title: 'Deputado Vereador', field: 'deputadoVereador', cellStyle: {
                                                                    width: '5%',
                                                                },
                                                                customFilterAndSearch: (term, rowData) => term == rowData.deputadoVereador.toString(),
                                                                filterComponent: (props) => {
                                                                    return (
                                                                        <Select
                                                                            placeholder="Selecione..."
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            options={[{ value: 'ativado', label: 'Ativado' },
                                                                            { value: 'desativado', label: 'Desativado' },]}
                                                                            onChange={(e) => {
                                                                                props.onFilterChanged(props.columnDef.tableData.id, e != null && e.value == 'ativado' ? 'true' :
                                                                                    e != null && e.value == 'desativado' ? 'false' : null)
                                                                            }}
                                                                        />
                                                                    )
                                                                },
                                                                editComponent: (props) => (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={props.value}
                                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                                    />
                                                                ),
                                                                render: rowData => (<input type="checkbox" checked={rowData.deputadoVereador} onChange={() => { }} />)
                                                            }
                                                        ]}
                                                        data={
                                                            listaInteressado != undefined && listaInteressado.length > 0 ?
                                                                listaInteressado.map(interessado => {
                                                                    return {
                                                                        id: interessado.id,
                                                                        nome: interessado.nome,
                                                                        endereco: interessado.endereco,
                                                                        cep: interessado.cep,
                                                                        numeroDddFixo: interessado.numeroDddFixo,
                                                                        numeroTelefoneFixo: interessado.numeroTelefoneFixo,
                                                                        numeroDdd: interessado.numeroDdd,
                                                                        numeroTelefone: interessado.numeroTelefone,
                                                                        bairro: interessado.idBairro,
                                                                        deputadoVereador: interessado.deputadoVereador,
                                                                        cargo: interessado.cargo
                                                                    }
                                                                })
                                                                :
                                                                []
                                                        }
                                                        icons={{
                                                            Add: () =>
                                                                <Button variant="success">
                                                                    <span>
                                                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                                                    </span>
                                                                </Button>
                                                        }}
                                                        editable={{
                                                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            InserirInteressado(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),

                                                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            AlterarInteressado(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),

                                                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        ExcluirInteressado(oldData.id);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            })
                                                        }}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            })

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Loading />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}