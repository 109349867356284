export function ListarCaracteristicaRequest() {
    return {
        type: 'LISTAR_CARACTERISTICA_REQUEST'
    }
}

export function ListarCaracteristicaResponse(listaCaracteristica) {
    return {
        type: 'LISTAR_CARACTERISTICA_RESPONSE',
        listaCaracteristica
    }
}

export function InserirCaracteristicaRequest(data) {
    return {
        type: 'INSERIR_CARACTERISTICA_REQUEST',
        data
    }
}

export function AlterarCaracteristicaRequest(data) {
    return {
        type: 'ALTERAR_CARACTERISTICA_REQUEST',
        data
    }
}

export function ExcluirCaracteristicaRequest(id) {
    return {
        type: 'EXCLUIR_CARACTERISTICA_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}