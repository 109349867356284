import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Alert, Button } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { FaSearchPlus } from "react-icons/fa";
import { ListarMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/medicaoAutorizacaoFornecimento/actions";
import {
    AlterarMedicaoAutorizacaoFornecimentoRequest,
    InserirMedicaoAutorizacaoFornecimentoRequest
} from "../../../store/modules/medicaoAutorizacaoFornecimento/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { ListarUsuarioRequest } from "../../../store/modules/usuario/actions";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import MaterialTable from "material-table";
import moment from 'moment';
import ptBR from "date-fns/locale/pt-BR";
import 'moment/locale/pt-br';

export default function Medicoes() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { idAutorizacaoFornecimento } = useParams();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const listaMedicaoAutorizacaoFornecimento = useSelector(state => state.medicaoAutorizacaoFornecimento.listaMedicaoAutorizacaoFornecimento);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const listaAutorizacaoFornecimentoStatus = useSelector(state => state.autorizacaoFornecimentoStatus.listaAutorizacaoFornecimentoStatus);
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const [selectedRow, setSelectedRow] = useState(null);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AutorizacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AutorizacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "AutorizacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');
        dispatch(ListarUsuarioRequest());
        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());

        if (idAutorizacaoFornecimento !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));
        }

    }, [])

    function ValidacoesInserir(medicao) {

        // Valida o campo medição descrição
        if (medicao.descricao == ' ' || medicao.descricao == ''
            || medicao.descricao == null || medicao.descricao == undefined) {
            toast.error(`Descrição Inválida`);
            return false;
        }

        if (medicao.descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return false;
        }

        // Valida o campo Data Medição
        if (medicao.dataApi == undefined || medicao.dataApi == null) {
            toast.error('É preciso selecionar a data da medição');
            return false;
        }

        // Valida o campo medição observação
        if (medicao.observacao !== undefined) {
            if (medicao.observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return false;
            }
        }

        return true;

    }

    function AlterarMedicao(medicao) {

        let data = {
            'Id': medicao.id,
            'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
            'IdUsuario': medicao.idUsuario,
            'DataMedicao': medicao.dataApi,
            'Observacao': medicao.observacao.trim(),
            'Descricao': medicao.descricao
        }

        dispatch(AlterarMedicaoAutorizacaoFornecimentoRequest(data));
        if (idAutorizacaoFornecimento != undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));
        }
    }

    function InserirMedicao(medicao) {


        // Verifica permissão do usuário
        var lista = [];

        if (listaContratoUsuario !== undefined && autorizacaoFornecimentoCompleto != null) {
            lista = listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)
        }

        if (lista.length > 0 && lista[0].editaQuantidadeSubitemMedicao === true) {

            let data = {
                'Id': parseInt(0),
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                'IdUsuario': parseInt(usuario.id),
                'DataInclusao': moment((new Date())).add('hours', 2),
                'DataMedicao': medicao.dataApi,
                'Observacao': medicao.observacao,
                'Descricao': medicao.descricao
            }

            dispatch(InserirMedicaoAutorizacaoFornecimentoRequest(data));

            if (idAutorizacaoFornecimento != undefined) {
                dispatch(ListarMedicaoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));
            }

        } else {
            toast.error("Você não possui permissão para criar uma medição!");
        }
    }

    return (
        <>
            <Form>
                <div>
                    <Col md={12}>
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={''}
                            columns={[
                                {
                                    title: 'Número da Medição',
                                    field: 'id',
                                    defaultSort: 'desc',
                                    cellStyle: {
                                        width: '12.5%'
                                    },
                                    editable: 'never'
                                },
                                {
                                    title: 'Descrição',
                                    field: 'descricao',
                                    align: 'left',
                                    editPlaceholder: 'Descrição',
                                    cellStyle: {
                                        width: '12.5%'
                                    }, validate: rowData => {
                                        if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                            return "Campo Obrigatório"
                                        }
                                        else if (rowData.descricao.length > 200) {
                                            return "A descrição não pode conter mais que 200 caracteres"
                                        }
                                        return true
                                    },
                                    editable: idAutorizacaoFornecimento !== undefined && listaAutorizacaoFornecimentoStatus !== undefined && listaStatusAutorizacaoFornecimento !== undefined &&
                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao === true).length > 0 &&
                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ===
                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao === true)[0].id).length > 0
                                    ? 'never' : 'always'
                                },
                                {
                                    title: 'Total Medição',
                                    field: 'totalMedicao',
                                    type: 'numeric',
                                    align: 'left',
                                    cellStyle: {
                                        width: '12.5%'
                                    },
                                    editable: 'never',
                                    render: rowData =>
                                        rowData.totalMedicao === null ?
                                            null
                                            :
                                            rowData.totalMedicao.toLocaleString('pt-br', {
                                                style: 'currency', currency: 'BRL',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                },
                                {
                                    title: 'Data Inclusão',
                                    field: 'dataInclusao',
                                    cellStyle: {
                                        width: '12.5%'
                                    },
                                    editable: 'never'
                                },
                                {
                                    title: 'Data Medição',
                                    field: 'dataApi',
                                    cellStyle: {
                                        width: '12.5%'
                                    },
                                    filtering: false,
                                    type: 'datetime',
                                    editable: 'always'
                                },
                                {
                                    title: 'Usuario',
                                    field: 'usuario',
                                    type: 'numeric',
                                    align: 'left',
                                    cellStyle: {
                                        width: '12.5%'
                                    },
                                    editable: 'never'
                                },
                                {
                                    title: 'Observação',
                                    field: 'observacao',
                                    align: 'left',
                                    editPlaceholder: 'Observação',
                                    cellStyle: {
                                        width: '12.5%'
                                    }, validate: rowData => {
                                        if (rowData.observacao !== undefined) {
                                            if (rowData.observacao.trim().length > 500) {
                                                return "A observação não pode conter mais que 500 caracteres"
                                            }
                                        }
                                        return true;
                                    },
                                    editable: idAutorizacaoFornecimento !== undefined && listaAutorizacaoFornecimentoStatus !== undefined && listaStatusAutorizacaoFornecimento !== undefined &&
                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao === true).length > 0 &&
                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ===
                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao === true)[0].id).length > 0
                                    ? 'never' : 'always'
                                },
                                {
                                    title: 'Status', field: 'status', cellStyle: {
                                        width: '12.5%',
                                    },
                                    editable: 'never',
                                    render: rowData =>
                                        //verifica qual é o status da medição
                                        listaStatusMedicaoAutorizacaoFornecimento != undefined
                                            && listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0
                                            && listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 ?
                                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].
                                                descricao === rowData.status ?
                                                <div className="divStatusGreenListar">{rowData.status}</div>
                                                :
                                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].
                                                    descricao === rowData.status ?
                                                    <div className="divStatusDangerListar">{rowData.status}</div>
                                                    :
                                                    <div className="divStatusNormalListar">{rowData.status}</div>
                                            :
                                            ''
                                }
                            ]}
                            data={
                                listaMedicaoAutorizacaoFornecimento != undefined ?
                                    listaMedicaoAutorizacaoFornecimento.map((lista) => {
                                        return {
                                            id: lista.id,
                                            idAutorizacaoFornecimento: lista.idAutorizacaoFornecimento,
                                            dataMedicao: moment(lista.dataMedicao).format('L LT'),
                                            dataInclusao: moment(lista.dataInclusao).format('L LT'),
                                            usuario: lista.usuario.nome,
                                            observacao: lista.observacao,
                                            status: lista.statusAtual,
                                            descricao: lista.descricao,
                                            dataApi: lista.dataMedicao,
                                            idUsuario: lista.idUsuario,
                                            totalMedicao: lista.valorTotalMedicao
                                        }
                                    })
                                    :
                                    []
                            }
                            editable={{
                                onRowAdd:
                                    // Verifica se esta em um status que só permite leitura
                                    listaContratoUsuario !== undefined && usuario !== undefined && autorizacaoFornecimentoCompleto != undefined &&
                                        autorizacaoFornecimentoCompleto != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                        listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id &&
                                            x.idContrato == autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato).length > 0 ?
                                        idAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimentoStatus != undefined && listaAutorizacaoFornecimentoStatus
                                        [listaAutorizacaoFornecimentoStatus.length - 1] != null && listaAutorizacaoFornecimentoStatus
                                        [listaAutorizacaoFornecimentoStatus.length - 1].statusAutorizacaoFornecimento != null &&
                                            listaAutorizacaoFornecimentoStatus
                                            [listaAutorizacaoFornecimentoStatus.length - 1].statusAutorizacaoFornecimento.somenteLeitura == false
                                            ?
                                            (newRow) => new Promise((resolve, reject) => {
                                                inserir == true ?
                                                    setTimeout(() => {
                                                        if (!ValidacoesInserir(newRow)) {
                                                            reject();
                                                        }
                                                        else {
                                                            InserirMedicao(newRow);
                                                            resolve()
                                                        }
                                                    }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)

                                            })
                                            :
                                            null
                                        :
                                        null,
                                onRowUpdate:
                                    // Verifica se esta em um status que só permite leitura
                                    listaContratoUsuario !== undefined && usuario !== undefined && autorizacaoFornecimentoCompleto != undefined &&
                                        autorizacaoFornecimentoCompleto != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                        listaContratoUsuario.filter(x => x.iniciarMedicao == true && x.idUsuario == usuario.id &&
                                            x.idContrato == autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato).length > 0 ?
                                        
                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                alterar == true ?
                                                    setTimeout(() => {
                                                        if (!ValidacoesInserir(newRow)) {
                                                            reject();
                                                        }
                                                        else {
                                                            AlterarMedicao(newRow);
                                                            resolve()
                                                        }
                                                    }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)
                                            })
                                        :
                                        null
                            }}
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                addRowPosition: 'first',
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                actionsColumnIndex: -1,
                                filtering: true,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                rowData => ({
                                    icon: () => <FaSearchPlus />,
                                    onClick: () => history.push(`/alterarGeralMedicao/${rowData.id}/${idAutorizacaoFornecimento}`)
                                })
                            ]}
                            icons={{
                                Add: () =>
                                    <Button variant="success">
                                        <span>
                                            <FaPlus size={15} color={"#fff"} /> Inserir
                                        </span>
                                    </Button>
                            }}
                            localization={{
                                header: {
                                    actions: <span className="classHeaderTabela">Ações</span>
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                        saveTooltip: '',
                                        cancelTooltip: '',
                                    },
                                    deleteTooltip: '',
                                    dateTimePickerLocalization: ptBR,
                                    editTooltip: '',
                                    addTooltip: '',
                                },
                                toolbar: {
                                    searchTooltip: '',
                                    searchPlaceholder: 'Pesquisar',
                                    exportTitle: "",
                                    exportCSVName: "Exportar como CSV",
                                    exportPDFName: "Exportar como PDF",
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    </Col>
                    <Col md={12}>
                        {
                            //Verifica se autorização esta cancelada ou finalizada
                            idAutorizacaoFornecimento !== undefined ?
                                listaAutorizacaoFornecimentoStatus !== undefined && listaStatusAutorizacaoFornecimento !== undefined &&
                                    listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao === true).length > 0 &&
                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                        listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao === true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`danger`} style={{ width: '100%' }}>
                                            A Autorização está Cancelada!
                                        </Alert>
                                    </Col>
                                    :
                                    listaAutorizacaoFornecimentoStatus !== undefined && listaStatusAutorizacaoFornecimento !== undefined &&
                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao === true).length > 0 &&
                                        listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ===
                                            listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao === true)[0].id).length > 0
                                        ?
                                        <Col md={12}>
                                            <Alert variant={`success`} style={{ width: '100%' }}>
                                                A Autorização está Finalizada!
                                            </Alert>
                                        </Col>
                                        :
                                        ''
                                :
                                ''
                        }
                    </Col>
                </div>
            </Form >
        </>
    )
}