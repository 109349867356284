export function ListarSolicitacaoFornecimentoCaracteristicaRespostaRequest() {
    return {
        type: 'LISTAR_INTERESSADO_REQUEST'
    }
}

export function ListarSolicitacaoFornecimentoCaracteristicaRespostaResponse(listaSolicitacaoFornecimentoCaracteristicaResposta) {
    return {
        type: 'LISTAR_INTERESSADO_RESPONSE',
        listaSolicitacaoFornecimentoCaracteristicaResposta
    }
}

export function InserirSolicitacaoFornecimentoCaracteristicaRespostaRequest(data) {
    return {
        type: 'INSERIR_INTERESSADO_REQUEST',
        data
    }
}

export function AlterarSolicitacaoFornecimentoCaracteristicaRespostaRequest(data) {
    return {
        type: 'ALTERAR_INTERESSADO_REQUEST',
        data
    }
}

export function ExcluirSolicitacaoFornecimentoCaracteristicaRespostaRequest(id) {
    return {
        type: 'EXCLUIR_INTERESSADO_REQUEST',
        id
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}