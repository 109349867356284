import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {
    BuscarInteressadoPorNomeRequest,
    InserirInteressadoRequest,
    ListarInteressadoRequest
} from "../../store/modules/interessado/actions";
import { toast } from "react-toastify";
import { ListarBairroRequest } from "../../store/modules/bairro/actions";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import './modalCadastroInteressado.css';


export default function ModalCadastroInteressado({ show, onHide }) {

    const dispatch = useDispatch();
    const listaBairro = useSelector(state => state.bairro.listaBairro);

    const [validated, setValidated] = useState(false);
    const [idBairro, setIdBairro] = useState('');
    const [nome, setNome] = useState('');
    const [endereco, setEndereco] = useState('');
    const [cep, setCep] = useState('');
    const [dddTelefone, setDddTelefone] = useState('');
    const [telefone, setTelefone] = useState('');
    const [dddTelefoneFixo, setDddTelefoneFixo] = useState('');
    const [telefoneFixo, setTelefoneFixo] = useState('');
    const [deputadoVereador, setDeputadoVereador] = useState(false);
    const [cargo, setCargo] = useState('');

    useEffect(() => {
        moment.locale('pt-br');
    }, [dispatch])

    useEffect(() => {
        dispatch(ListarBairroRequest());
        dispatch(ListarInteressadoRequest());
    }, [])


    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {

            event.preventDefault();
        } else {

            if (ValidacoesInserir(event)) {
                InserirInteressado();
            };

        }

        setValidated(true);
    };

    function ValidacoesInserir(e) {

        e.preventDefault();

        // Valida o campo nome
        if (nome == null || nome == undefined
            || nome.length > 100 || nome.trim() == '') {
            toast.error(`Nome inválido`);
            return false;
        }

        // Valida o campo endereco
        if (endereco == null || endereco == undefined
            || endereco.length > 200 || endereco.trim() == '') {
            toast.error(`Endereço inválido`);
            return false;
        }
        // Valida o campo bairro
        if (idBairro == null || idBairro == undefined || idBairro == '') {
            toast.error(`Bairro não selecionado`);
            return false;
        }

        // Valida o campo telefone
        if (telefone != null && telefone != undefined && telefone != '') {
            if (!ValidaTelefone(telefone)) {
                toast.error(`Telefone com formato inválido`);
                return false;
            }
        }

        // Valida o campo ddd
        if (dddTelefone != null && dddTelefone != undefined && dddTelefone != '') {
            if (!ValidacaoDdd(dddTelefone)) {
                toast.error(`DDD com formato inválido`);
                return false;
            }
        }

        // Valida o campo telefone fixo
        if (telefoneFixo != null && telefoneFixo != undefined && telefoneFixo != '') {
            if (!ValidaTelefoneFixo(telefoneFixo)) {
                toast.error(`Telefone Fixo com formato inválido`);
                return false;
            }
        }

        // Valida o campo ddd fixo
        if (dddTelefoneFixo != null && dddTelefoneFixo != undefined && dddTelefoneFixo != '') {
            if (!ValidacaoDddFixo(dddTelefoneFixo)) {
                toast.error(`DDD Fixo com formato inválido`);
                return false;
            }
        }

        if (((dddTelefoneFixo != '') && (telefoneFixo != '')) || ((telefone != '') && (dddTelefone != ''))) {
            return true;
        } else {
            toast.error(`É necessário que tenha pelo menos um tipo de telefone`);
            return false;
        }

    }

    function InserirInteressado() {

        let data = {
            'Id': 0,
            'Nome': nome.trim(),
            'Endereco': endereco,
            // 'Cep': cep === undefined || cep === '' ? null : cep,
            'Cep': cep,
            'idBairro': idBairro,
            'NumeroDddFixo': dddTelefoneFixo === undefined || dddTelefoneFixo === '' ? null : dddTelefoneFixo.trim(),
            'NumeroTelefoneFixo': telefoneFixo === undefined || telefoneFixo === '' ? null : telefoneFixo.trim(),
            'NumeroDdd': dddTelefone === undefined || dddTelefone === '' ? null : dddTelefone.trim(),
            'NumeroTelefone': telefone === undefined || telefone === '' ? null : telefone.trim(),
            'DeputadoVereador': deputadoVereador != undefined ? deputadoVereador : false,
            'Cargo': cargo === undefined || cargo === '' ? null : cargo,
        }

        dispatch(InserirInteressadoRequest(data))
        dispatch(BuscarInteressadoPorNomeRequest(nome.trim()));

        setIdBairro('');
        setNome('');
        setEndereco('');
        setCep('');
        setDddTelefone('');
        setTelefone('');
        setDddTelefoneFixo('');
        setTelefoneFixo('');
        setCargo('');
        onHide();

    }


    function ValidacaoDdd(ddd) {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(ddd))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone(telefone) {
        const telefoneRegex = /^\d{1}\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidacaoDddFixo(dddFixo) {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(dddFixo))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefoneFixo(telefoneFixo) {
        const telefoneRegex = /^\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefoneFixo)) || telefoneRegex2.test(String(telefoneFixo))) {
            return true;
        } else {
            return false;
        }
    }

    return (

        <Modal
            size="xl"
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastrar interessado
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="tamanhoTela">
                        <Col md={12}>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formNome">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Nome é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formEndereço">
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={endereco}
                                        onChange={e => setEndereco(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo Endereço é obrigatório.</Form.Control.Feedback>
                                </Form.Group>


                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formBairro">
                                    <Form.Label>Bairro</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setIdBairro(e.target.value)}
                                        value={idBairro}
                                        required
                                        isInvalid={validated && idBairro === "" ? true : false}
                                        isValid={validated && idBairro !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaBairro != undefined ?
                                                listaBairro.map(m => (
                                                    <option value={m.id} key={m.id}>{m.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo Contrato é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formCep">
                                    <Form.Label>Cep</Form.Label>
                                    <InputMask defaultValue={cep} onChange={(e) => setCep(e.target.value)}
                                        mask={'99.999-999'} id="idCep" style={{ width: "100%" }}
                                        className="inputEditado" />
                                    {/* <Form.Control.Feedback type="invalid">O campo Cep é obrigatório.</Form.Control.Feedback> */}
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={2} controlId="formDddTelefone">
                                    <Form.Label>DDD</Form.Label>
                                    <InputMask defaultValue={dddTelefone} onChange={(e) => setDddTelefone(e.target.value)}
                                        mask={"99"} style={{ width: "100%" }}
                                        className="inputEditado" />
                                    <Form.Control.Feedback type="invalid">O campo DDD é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={4} controlId="formTelefone">
                                    <Form.Label>Telefone</Form.Label>
                                    <InputMask defaultValue={telefone} onChange={(e) => setTelefone(e.target.value)}
                                        mask={"99999 9999"} style={{ width: "100%" }}
                                        className="inputEditado" />
                                    <Form.Control.Feedback type="invalid">O campo Telefone é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={2} controlId="formDddTelefoneFixo">
                                    <Form.Label>DDD Fixo</Form.Label>
                                    <InputMask defaultValue={dddTelefoneFixo} onChange={(e) => setDddTelefoneFixo(e.target.value)}
                                        mask={"99"} style={{ width: "100%" }}
                                        className="inputEditado" />
                                    <Form.Control.Feedback type="invalid">O campo DDD Fixo é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={4} controlId="formTelefoneFixo">
                                    <Form.Label>Telefone Fixo</Form.Label>
                                    <InputMask defaultValue={telefoneFixo} onChange={(e) => setTelefoneFixo(e.target.value)}
                                        mask={"9999 9999"} style={{ width: "100%" }}
                                        className="inputEditado" />
                                    <Form.Control.Feedback type="invalid">O campo Telefone Fixo é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} controlId="formCargo">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        maxLength={150}
                                        value={cargo}
                                        onChange={e => setCargo(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                            
                            <Row className="mb-3">
                                <Form.Group as={Col} md={2} controlId="formDeputadoVereador">
                                    <input
                                        type="checkbox"
                                        checked={deputadoVereador}
                                        className="form-check-input"
                                        onChange={(e) => setDeputadoVereador(e.target.checked)}
                                    />
                                    <Form.Label style={{ marginLeft: "10px" }}>Deputado vereador</Form.Label>
                                </Form.Group>

                            </Row>
                        </Col>
                    </div>
                    <Col md={12}>
                        <Button type="submit" 
                        variant="success" style={{ width: '50%' }}>Cadastrar</Button>
                    </Col>
                </Form>
            </Modal.Body>
        </Modal>
    );
}