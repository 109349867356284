import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarGrupoCustoResponse, ListarGrupoCustoCompletoResponse, Carregando } from './actions';
import { BuscarContratoPorIdRequest } from '../contrato/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarGrupoCustoRequest(){

  try{

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/grupoCusto`, { headers:headerParams.token });

    yield put(ListarGrupoCustoResponse(result.data.result)); 
    yield put(Carregando(true));    
  
  }catch(e){

    if (e.response != null && e.response.data != null) {
      if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }
  
    yield put(ListarGrupoCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirGrupoCustoRequest(model){

  try{
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/grupoCusto`, model.data, { headers:headerParams.token });

    yield put(BuscarContratoPorIdRequest(model.idContrato));
    yield put(Carregando(true));    

    toast.success(`Grupo Custo foi cadastrado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));   
    yield put(Carregando(true));    

  }
}

function* AlterarGrupoCustoRequest(model){

  try{
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
      yield call(api.put, `/api/grupoCusto`, model.data, { headers: headerParams.token });

      

      yield put(Carregando(true));
      yield put(BuscarContratoPorIdRequest(model.idContrato));
    toast.success(`Grupo Custo foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));     
    yield put(Carregando(true));    
  }
}

function* ExcluirGrupoCustoRequest(model){

  try{ 

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/grupoCusto/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Grupo Custo id: ${model.id} foi excluido!`);
  
  }catch(e){

    if (e.response != null && e.response.data != null) {
      if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }
  
    yield put(ErrorResponse(e));      
    yield put(Carregando(true));    
  }
}

function* ListarGrupoCustoCompletoRequest(model) {

  try {   

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/grupoCusto/listar`, model.id, { headers:headerParams.token });

    if(result.data.result == null){
      yield put(ListarGrupoCustoCompletoResponse([]));
    }else{
      yield put(ListarGrupoCustoCompletoResponse(result.data.result));
    }
    yield put(Carregando(true));    

  } catch (e) {

    if (e.response != null && e.response.data != null) {
      if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    }

    yield put(ListarGrupoCustoCompletoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
    takeLatest('LISTAR_GRUPO_CUSTO_REQUEST', ListarGrupoCustoRequest),
    takeLatest('INSERIR_GRUPO_CUSTO_REQUEST', InserirGrupoCustoRequest),
    takeLatest('ALTERAR_GRUPO_CUSTO_REQUEST', AlterarGrupoCustoRequest),
    takeLatest('EXCLUIR_GRUPO_CUSTO_REQUEST', ExcluirGrupoCustoRequest),
    takeLatest('LISTAR_GRUPO_CUSTO_COMPLETO_REQUEST', ListarGrupoCustoCompletoRequest)
])