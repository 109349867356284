import { useEffect, useState } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarCompletoSolicitacaoFornecimentoVersaoRequest } from "../../../store/modules/solicitacaoFornecimentoVersao/actions";
import { BaixarDocumentoRequest } from "../../../store/modules/global/actions";
import {
    ListarEventoAdicionalRequest, ExcluirEventoAdicionalRequest,
    InserirEventoAdicionalRequest, AlterarEventoAdicionalRequest
} from '../../../store/modules/eventoAdicional/actions';
import { ListarSolicitacaoFornecimentoHistoricoComRedirecionamentoRequest } from "../../../store/modules/solicitacaoFornecimentoHistorico/actions";
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function HistoricoVersao() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const listaEventoAdicional = useSelector(state => state.eventoAdicional.listaEventoAdicional);
    const listaCompletaSolicitacaoFornecimentoVersao =
        useSelector(state => state.solicitacaoFornecimentoVersao.listaCompletaSolicitacaoFornecimentoVersao);
    const listaSolicitacaoFornecimentoVersaoStatus = useSelector(state =>
        state.solicitacaoFornecimentoVersaoStatus.listaSolicitacaoFornecimentoVersaoStatus);
    const carregando = useSelector(state => state.solicitacaoFornecimentoVersao.carregando);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const documentos = useSelector(state => state.solicitacaoFornecimentoDocumentoArquivo.listaSolicitacaoFornecimentoDocumentoArquivo);

    const [listaHierarquiaCompleta, setListaHierarquiaCompleta] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (id != undefined)
            dispatch(ListarEventoAdicionalRequest(parseInt(id)));
    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        if (id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.servico ||
            id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao != null &&
            solicitacaoFornecimento.tipoSolicitacao.semOficio &&
            listaEventoAdicional != undefined && listaEventoAdicional.filter(x => x.tipoSolicitacao.servico == true).length > 0) {

            dispatch(ListarCompletoSolicitacaoFornecimentoVersaoRequest(id));
        }

    }, [id, listaSolicitacaoFornecimentoVersaoStatus])

    useEffect(() => {

        if (id != undefined) {
            constroiHierarquia();
        }

    }, [listaSolicitacaoFornecimentoVersaoStatus, listaCompletaSolicitacaoFornecimentoVersao])

    function constroiHierarquia() {

        let hierarquiaCompleta = [];

        if (listaCompletaSolicitacaoFornecimentoVersao != undefined) {
            listaCompletaSolicitacaoFornecimentoVersao.map((itemVersao) => {
                hierarquiaCompleta.push({
                    id: itemVersao.id,
                    observacao: itemVersao.observacao,
                    numeroOrcamento: itemVersao.numeroOrcamento,
                    dataVersao: moment(itemVersao.dataVersao).format('L LT'),
                    usuario: itemVersao.usuario.nome,
                    parentId: null,
                    status: null,
                    dataStatus: null,
                    statusReprovado: null,
                    statusAprovado: null,
                    statusCancela: null
                });
                itemVersao.solicitacaoFornecimentoVersaoStatus.map((solicitacaoFornecimentoVersaoStatus) => {
                    if (solicitacaoFornecimentoVersaoStatus.statusSolicitacaoFornecimento != null &&
                        solicitacaoFornecimentoVersaoStatus.statusSolicitacaoFornecimento.statusAnalise &&
                        (solicitacaoFornecimentoVersaoStatus.statusAprovado == false && solicitacaoFornecimentoVersaoStatus.statusReprovado == false) ||
                        solicitacaoFornecimentoVersaoStatus.statusSolicitacaoFornecimento != null &&
                        solicitacaoFornecimentoVersaoStatus.statusSolicitacaoFornecimento.solicitaOrcamentoSolicitacao &&
                        solicitacaoFornecimentoVersaoStatus.analiseConcluida == false) {

                    } else {
                        hierarquiaCompleta.push({
                            parentId: solicitacaoFornecimentoVersaoStatus.idSolicitacaoFornecimentoVersao,
                            id: solicitacaoFornecimentoVersaoStatus.id,
                            observacao: solicitacaoFornecimentoVersaoStatus.observacaoCancelado == '' ? solicitacaoFornecimentoVersaoStatus.observacaoReprovado
                                : solicitacaoFornecimentoVersaoStatus.observacaoCancelado,
                            numeroOrcamento: null,
                            dataVersao: null,
                            usuario: solicitacaoFornecimentoVersaoStatus.usuario.nome,
                            status: solicitacaoFornecimentoVersaoStatus.statusSolicitacaoFornecimento.descricaoSolicitacaoFornecimentoVersao,
                            dataStatus: moment(solicitacaoFornecimentoVersaoStatus.dataStatus).format('L LTS'),
                            statusReprovado: solicitacaoFornecimentoVersaoStatus.statusReprovado,
                            statusAprovado: solicitacaoFornecimentoVersaoStatus.statusAprovado,
                            statusCancela: solicitacaoFornecimentoVersaoStatus.statusSolicitacaoFornecimento.cancelaSolicitacao
                        });
                    }
                })
            })
        }

        setListaHierarquiaCompleta(hierarquiaCompleta);
    }

    function BaixarDocumento(rowData) {

        var idDoc = documentos != undefined &&
            documentos.filter(m => moment(m.dataInclusao).format('L LTS') == rowData.dataStatus &&
                rowData.statusReprovado == true).length > 0 ?
            documentos.filter(m => moment(m.dataInclusao).format('L LTS') == rowData.dataStatus)[0].id
            :
            ''

        dispatch(BaixarDocumentoRequest(idDoc));
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title={
                                    <Button
                                        style={{ width: '100%' }}
                                        variant="success"
                                        onClick={() => dispatch(ListarSolicitacaoFornecimentoHistoricoComRedirecionamentoRequest(id))}
                                        >
                                        Histórico Solicitação
                                    </Button>
                                }
                                columns={[
                                    {
                                        title: "Número de Orçamento", field: 'numeroOrcamento', editable: 'never', cellStyle: {
                                            width: '15%'
                                        }
                                    },
                                    {
                                        title: "Data Versão", field: 'dataVersao', editable: 'never', cellStyle: {
                                            width: '15%'
                                        }
                                    },
                                    {
                                        title: "Data Status", field: 'dataStatus', defaultSort: 'desc', editable: 'never', cellStyle: {
                                            width: '15%'
                                        }
                                    },
                                    {
                                        title: "Status", field: 'status', cellStyle: {
                                            width: '20%'
                                        },
                                        render: rowData =>
                                            rowData.status == null ?
                                                ''
                                                :
                                                rowData.statusAprovado ?
                                                    <div className="divStatusGreen">{rowData.status} (Aprovada)</div>
                                                    :
                                                    rowData.statusReprovado ?
                                                        <div className="divStatusDanger">{rowData.status} (Reprovada)</div>
                                                        :
                                                        rowData.statusCancela ?
                                                            <div className="divStatusDanger">{rowData.status}</div>
                                                            :
                                                            <div className="divStatusGreen">{rowData.status}</div>
                                    },
                                    {
                                        title: "Observação", field: 'observacao', cellStyle: {
                                            width: '20%'
                                        }
                                    },
                                    {
                                        title: "Nome Usuário", field: 'usuario', editable: 'never', cellStyle: {
                                            width: '15%'
                                        }
                                    }
                                ]}
                                data={listaHierarquiaCompleta}
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.parentId ?
                                            '#dcdcdc'
                                            : rowData.parentId ?
                                                '#BCB8B8'
                                                :
                                                '#BCB8B8'
                                    })
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'download',
                                        onClick: (event, rowData) => BaixarDocumento(rowData),
                                        hidden: documentos != undefined &&
                                            documentos.filter(m => moment(m.dataInclusao).format('L LTS') == rowData.dataStatus &&
                                                rowData.statusReprovado == true).length == 0
                                            ? true
                                            :
                                            false

                                    })
                                ]}
                                localization={{
                                    header: {
                                        actions: <span className="classHeaderTabela">Ações</span>
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: ''
                                    },
                                    toolbar: {
                                        searchTooltip: 'Pesquisar',
                                        searchPlaceholder: 'Pesquisar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    },
                                    toolbar: {
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    }
                                }}
                            />
                            :
                            <Loading />
                    }
                </Col>
            </div>
        </>
    )
}