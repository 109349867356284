export function ListarSolicitacaoFornecimentoVersaoStatusRequest(IdSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST',
    IdSolicitacaoFornecimento
  }
}
export function ListarSolicitacaoFornecimentoVersaoStatusResponse(listaSolicitacaoFornecimentoVersaoStatus) {
  return {
    type: 'LISTAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_RESPONSE',
    listaSolicitacaoFornecimentoVersaoStatus
  }
}

export function InserirSolicitacaoFornecimentoVersaoStatusRequest(data, IdSolicitacaoFornecimento, verificacao) {
  return {
    type: 'INSERIR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST',
    data, IdSolicitacaoFornecimento, verificacao
  }
}

export function AlterarSolicitacaoFornecimentoVersaoStatusRequest(data, IdSolicitacaoFornecimento, validacao) {
  return {
    type: 'ALTERAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST',
    data, IdSolicitacaoFornecimento, validacao
  }
}

export function ExcluirSolicitacaoFornecimentoVersaoStatusRequest(id, IdSolicitacaoFornecimento) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_REQUEST',
    id, IdSolicitacaoFornecimento
  }
}

export function ExcluirSolicitacaoFornecimentoVersaoStatusMantendoOsItensRequest(id, IdSolicitacaoFornecimento) {
  return {
    type: 'EXCLUIR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_MANTENDO_OS_ITENS_REQUEST',
    id, IdSolicitacaoFornecimento
  }
}

export function ValidarSolicitacaoFornecimentoVersaoStatusResponse(listaValidacaoSolicitacaoStatus) {
  return {
    type: 'VALIDAR_SOLICITACAO_FORNECIMENTO_VERSAO_STATUS_RESPONSE',
    listaValidacaoSolicitacaoStatus
  }
}

export function LimparListaValidacaoSolicitacao() {
  return {
    type: 'LIMPAR_LISTA_VALIDACAO_SOLICITACAO'
  }
}

export function LimparSolicitacaoStatus() {
  return {
    type: 'LIMPAR_SOLICITACAO_STATUS'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}