import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { Carregando, ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse } from './actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';
import history from '../../../services/history';

function* ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
 
  }
}

function* InserirMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    var resultId = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/listarCompleto`,
     { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    
    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
        listaId = listaId.concat(element + ' ' );
    });
    
    yield put(Carregando(true));

    toast.success('Documentos id: ' + listaId + ' cadastrados com sucesso!');

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
        if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
        }
      }

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo`, model.data, { headers: headerParams.token });

    toast.success(`Documentos atualizado com sucesso!`);

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ExcluirMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/${model.id}/${'0'}`, { headers: headerParams.token });

    toast.success(`Documento id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.idMedicao) }, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }  

    yield put(ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* BaixarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/${model.id}`, { headers:headerParams.token });

    const nomeArquivo = `${result.data.result.nomeArquivo}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/MedicaoAutorizacaoFornecimentoDocumentoArquivo/${model.id}/${'0'}/baixar`, {headers: headerParams.token})
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', ListarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', InserirMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', AlterarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', ExcluirMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest),
  takeLatest('BAIXAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_REQUEST', BaixarMedicaoAutorizacaoFornecimentoDocumentoArquivoRequest)
])