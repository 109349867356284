import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import brokenCellphone from '../../assets/broken-cellphone.png';

export default function Erro() {
    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>

            <h1>Ops! Um erro da nossa parte ocorreu.</h1>
            <div>
                <img src={brokenCellphone} alt='img' />
            </div>

            <br></br>

            <span className='new-line'>
                <p>
                    <h6>
                    {`Esse evento de erro já foi registrado e em breve nossos engenheiros serão notificados. Se preferir, use nossa
                    ferramenta de suporte (em breve) para notificar nossa equipe imediatamente.`}
                    </h6>
                </p>
            </span>

            <Link to="/">
                <Button variant="success">Voltar para o site</Button>
            </Link>
        </div>
    )
}