import produce from 'immer';

const initialState =
{
    listaMedicaoAutorizacaoFornecimento: undefined,
    medicaoAutorizacaoFornecimentoCompleto: null,
    listaTodasMedicaoAutorizacaoFornecimento: undefined
};

export default function medicaoAutorizacaoFornecimento(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_RESPONSE':
            return produce(state, draft => {
                draft.listaMedicaoAutorizacaoFornecimento = action.listaMedicaoAutorizacaoFornecimento
            });

        case 'BUSCAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_POR_ID_RESPONSE':
            return produce(state, draft => {
                draft.medicaoAutorizacaoFornecimentoCompleto = action.medicaoAutorizacaoFornecimentoCompleto
            });

        case 'LISTAR_TODAS_MEDICAO_AUTORIZACAO_FORNECIMENTO_RESPONSE':
            return produce(state, draft => {
                draft.listaTodasMedicaoAutorizacaoFornecimento = action.listaTodasMedicaoAutorizacaoFornecimento;
            });

        case 'LIMPAR_MEDICAO':
            return produce(state, draft => {
                draft.medicaoAutorizacaoFornecimentoCompleto = null
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}