import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card } from "react-bootstrap";
import {
    ListarCompletoFaturamentoRequest, ExcluirFaturamentoRequest,
    InserirFaturamentoRequest, AlterarFaturamentoRequest
} from "../../../store/modules/faturamento/actions";
import { MdOutlinePayments } from "react-icons/md";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarEmpenhoRequest } from "../../../store/modules/empenho/actions";
import { ListarStatusEmpenhoRequest } from "../../../store/modules/statusEmpenho/actions";
import ModalFaturamentoDocumento from "../../../components/ModalFaturamentoDocumento/modalFaturamentoDocumento";
import Select from 'react-select';
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import Header from "../../../components/Header/header";
import moment from 'moment';
import 'moment/locale/pt-br';
import { useHistory } from "react-router-dom";

export default function Faturamento() {

    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaFaturamento = useSelector(state => state.faturamento.listaCompletaFaturamento);
    const listaEmpenho = useSelector(state => state.empenho.listaEmpenho);
    const carregando = useSelector(state => state.faturamento.carregando);
    const listaStatusEmpenho = useSelector(state => state.statusEmpenho.listaStatusEmpenho);

    const [selectedRow, setSelectedRow] = useState(null);
    const [dataId, setDataId] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "FaturamentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
        //Permissão inserir e editar
        if (token.role.filter(r => r == "FaturamentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "FaturamentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    const options = listaEmpenho != undefined && listaStatusEmpenho != undefined && listaStatusEmpenho.filter(x => x.statusAprovaDuasPartes == true).length > 0 ?
        listaEmpenho.filter(empenho => empenho.statusFaturamentoAndamento == true
        ).map(empenho => ({
            value: empenho.id, label: empenho.descricao
        }))
        :
        [];

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarStatusEmpenhoRequest());
        dispatch(ListarEmpenhoRequest());
        dispatch(ListarCompletoFaturamentoRequest(null));

    }, [])

    function AdicionarFaturamento(faturamento) {

        let selectText = '';
        let selectSelecionado = [];

        // Buscar o empenho selecionado
        if (document.getElementById('idSelectSubItem') != null && listaEmpenho != undefined) {
            selectText = document.getElementById('idSelectSubItem').innerText;
            selectSelecionado = listaEmpenho.filter(empenho => empenho.descricao == selectText);
        }

        // Validar descrição
        if (faturamento.descricao == null || faturamento.descricao == undefined
            || faturamento.descricao == ' ' || faturamento.descricao.length > 50
            || faturamento.descricao == '') {
            toast.error(`Descrição inválida`);
            return;
        }

        if (faturamento.descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return;
        }

        // Validar observação
        if (faturamento.observacao !== undefined) {
            if (faturamento.observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return;
            }
            else if (faturamento.observacao == ' ') {
                toast.error(`Observação Inválida`);
                return;
            }
        }

        if (selectSelecionado.length > 0) {
            let data = {
                'Id': 0,
                'Descricao': faturamento.descricao.trim(),
                'Observacao': faturamento.observacao,
                'IdEmpenho': parseInt(selectSelecionado[0].id),
                'IdUsuario': usuario.id,
                'DataPagamento': moment((new Date())).add('hours', 2),
                'ValorOriginal': parseFloat(faturamento.valorOriginal),
                'ValorPago': parseFloat(faturamento.valorPago),
                'Cancelado': false,
                'IdUsuarioCancelamento': null,
                'ObservacaoCancelamento': '',
                'DataCancelamento': null,
            }
            dispatch(InserirFaturamentoRequest(data));

        } else {
            toast.error(`Empenho não selecionado.`);
        }
    }

    function AlterarFaturamento(faturamento) {

        // Validar descrição
        if (faturamento.descricao == null || faturamento.descricao == undefined
            || faturamento.descricao == ' ' || faturamento.descricao.length > 50
            || faturamento.descricao == '') {
            toast.error(`Descrição inválida`);
            return;
        }

        if (faturamento.descricao.length > 200) {
            toast.error(`A descrição não pode conter mais que 200 caracteres`);
            return;
        }

        // Validar observação
        if (faturamento.observacao !== undefined) {
            if (faturamento.observacao.trim().length > 500) {
                toast.error('A observação não pode conter mais que 500 caracteres');
                return;
            }
            else if (faturamento.observacao == ' ') {
                toast.error(`Observação Inválida`);
                return;
            }
        }

        let data = {
            'Id': faturamento.id,
            'Descricao': faturamento.descricao.trim(),
            'Observacao': faturamento.observacao,
            'IdEmpenho': faturamento.idEmpenho,
            'IdUsuario': faturamento.idUsuario,
            'DataPagamento': faturamento.data,
            'ValorOriginal': parseFloat(faturamento.valorOriginal),
            'ValorPago': parseFloat(faturamento.valorPago),
            'Cancelado': faturamento.cancelado,
            'IdUsuarioCancelamento': faturamento.idUsuarioCancelamento == '' ? null : faturamento.idUsuarioCancelamento,
            'ObservacaoCancelamento': faturamento.observacaoCancelamento,
            'DataCancelamento': faturamento.dataCancelamentoApi == '' ? null : faturamento.dataCancelamentoApi
        }

        dispatch(AlterarFaturamentoRequest(data));
    }

    function AlterarValores(data) {
        setDataId(data);
        setShow(true);
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col md={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdOutlinePayments size={25} color={"#000"} /> Faturamento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                {
                                    carregando ?
                                        <MaterialTable
                                            components={{
                                                Pagination: PatchedPagination,
                                            }}
                                            title=""
                                            columns={[
                                                {
                                                    title: '#', field: 'id', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                        width: '10%',
                                                    }, editable: 'never'
                                                },
                                                {
                                                    title: 'Descrição', field: 'descricao', sorting: true, editPlaceholder: 'descricao', validate: rowData => {
                                                        if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                            return "Campo Obrigatório"
                                                        } else if (rowData.descricao.trim().length > 200) {
                                                            return "A descrição não pode conter mais que 200 caracteres"
                                                        }
                                                        return true
                                                    },
                                                    cellStyle: {
                                                        width: '10%'
                                                    }
                                                },
                                                {
                                                    title: 'Observação', field: 'observacao', sorting: true, editPlaceholder: 'observacao',
                                                    validate: rowData => {
                                                        if (rowData.observacao !== undefined) {
                                                            if (rowData.observacao.trim().length > 500) {
                                                                return "A observação não pode conter mais que 500 caracteres"
                                                            }
                                                            else if (rowData.observacao == ' ') {
                                                                return "Observação inválida"
                                                            }
                                                        }
                                                        return true;
                                                    },
                                                    cellStyle: {
                                                        width: '10%'
                                                    }
                                                },
                                                {
                                                    title: 'Valor Original', field: 'valorOriginal', cellStyle: {
                                                        width: '10%'
                                                    }, type: 'numeric', validate: rowData => {
                                                        if (rowData.valorOriginal === undefined || rowData.valorOriginal === "" || isNaN(rowData.valorOriginal)) {
                                                            return "Campo Obrigatório"
                                                        }
                                                        return true
                                                    },
                                                    render: rowData =>
                                                        rowData.valorOriginal === null ?
                                                            null
                                                            :
                                                            rowData.valorOriginal.toLocaleString('pt-br', {
                                                                style: 'currency', currency: 'BRL',
                                                                minimumFractionDigits: 4,
                                                                maximumFractionDigits: 4
                                                            })
                                                },
                                                {
                                                    title: 'Valor Pago', field: 'valorPago', cellStyle: {
                                                        width: '10%',
                                                    }, type: 'numeric', validate: rowData => {
                                                        if (rowData.valorPago === undefined || rowData.valorPago === "" || isNaN(rowData.valorPago)) {
                                                            return "Campo Obrigatório"
                                                        }
                                                        return true
                                                    },
                                                    render: rowData =>
                                                        rowData.valorPago === null ?
                                                            null
                                                            :
                                                            rowData.valorPago.toLocaleString('pt-br', {
                                                                style: 'currency', currency: 'BRL',
                                                                minimumFractionDigits: 4,
                                                                maximumFractionDigits: 4
                                                            })
                                                },
                                                {
                                                    title: 'Empenho', filtering: false, field: 'idEmpenho', cellStyle: {
                                                        width: '10%',
                                                    }, editable: 'onAdd',
                                                    lookup:
                                                        listaEmpenho != undefined ?
                                                            listaEmpenho.map(empenho => {
                                                                return {
                                                                    id: empenho.id, descricao: empenho.descricao
                                                                }
                                                            }).reduce(function (acc, cur, i) {
                                                                acc[cur.id] = cur.descricao;
                                                                return acc;
                                                            }, {})
                                                            :
                                                            null,
                                                    editComponent: (props) => (
                                                        <Select id="idSelectSubItem"
                                                            options={options}
                                                            placeholder="Selecione..."
                                                            defaultValue={{
                                                                value: props.value, label: listaEmpenho.filter(emp => emp.id == props.value).length > 0
                                                                    ? listaEmpenho.filter(emp => emp.id == props.value)[0].descricao
                                                                    : 'Selecione...'
                                                            }}
                                                        />
                                                    ),
                                                },
                                                {
                                                    title: 'Usuario', field: 'usuario', editable: 'never', cellStyle: {
                                                        width: '10%',
                                                    }
                                                },
                                                {
                                                    title: 'Data Pagamento', field: 'dataPagamento', editable: 'never', cellStyle: {
                                                        width: '10%',
                                                    }
                                                },
                                                {
                                                    title: 'Cancelado', field: 'cancelado', cellStyle: {
                                                        width: '10%',
                                                    }, editable: 'onUpdate',
                                                    editComponent: (props) => (
                                                        <input
                                                            type="checkbox"
                                                            checked={props.value == true ? props.value : false}
                                                            onChange={(e) => props.onChange(e.target.checked)}
                                                        />
                                                    ),
                                                    render: rowData => (<input type="checkbox" checked={rowData.cancelado} onChange={() => { }} />)
                                                },
                                                {
                                                    title: 'Observação Cancelamento', field: 'observacaoCancelamento', editable: 'onUpdate', cellStyle: {
                                                        width: '10%',
                                                    }
                                                },
                                                {
                                                    title: 'Usuario Cancelamento', field: 'nomeUsuarioCancelamento', editable: 'never', cellStyle: {
                                                        width: '10%',
                                                    }
                                                },
                                                {
                                                    title: 'Data Cancelamento', field: 'dataCancelamento', editable: 'never', cellStyle: {
                                                        width: '10%',
                                                    }
                                                }
                                            ]}
                                            data={
                                                listaFaturamento != undefined ?
                                                    listaFaturamento.map(faturamento => {
                                                        return {
                                                            id: faturamento.id,
                                                            descricao: faturamento.descricao,
                                                            observacao: faturamento.observacao,
                                                            usuario: faturamento.usuario.nome,
                                                            idEmpenho: faturamento.idEmpenho,
                                                            idUsuario: faturamento.idUsuario,
                                                            dataPagamento: moment(faturamento.dataPagamento).format('L LT'),
                                                            data: faturamento.dataPagamento,
                                                            valorOriginal: faturamento.valorOriginal,
                                                            valorPago: faturamento.valorPago,
                                                            cancelado: faturamento.cancelado,
                                                            idUsuarioCancelamento: faturamento.idUsuarioCancelamento == null ? '' : faturamento.idUsuarioCancelamento,
                                                            nomeUsuarioCancelamento: faturamento.idUsuarioCancelamento == null ? '' : faturamento.usuarioCancelamento.nome,
                                                            observacaoCancelamento: faturamento.observacaoCancelamento,
                                                            dataCancelamentoApi: faturamento.dataCancelamento == null ? '' : faturamento.dataCancelamento,
                                                            dataCancelamento: faturamento.dataCancelamento == null ? '' : moment(faturamento.dataCancelamento).format('L LT')
                                                        }
                                                    })
                                                    :
                                                    []
                                            }
                                            editable={{
                                                onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                    inserir == true ?
                                                        setTimeout(() => {
                                                            AdicionarFaturamento(newRow);
                                                            resolve()
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                }),

                                                onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                    alterar == true ?
                                                        setTimeout(() => {
                                                            AlterarFaturamento(newRow);
                                                            resolve()
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            toast.error(`Você não tem permissão`);
                                                            resolve()
                                                        }, 1000)
                                                })
                                            }}
                                            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                            options={{
                                                minBodyHeight: 500,
                                                headerStyle: {
                                                    backgroundColor: '#454545',
                                                    color: '#FFF',
                                                    fontWeight: "bold"
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                grouping: true,
                                                exportFileName: 'tabelaExportada',
                                                rowStyle: rowData => ({
                                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                })

                                            }}
                                            actions={[
                                                rowData => ({
                                                    icon: 'description',
                                                    tooltip: 'Documentos',
                                                    onClick: (event, rowData) => { AlterarValores(rowData.id) }
                                                })
                                            ]}
                                            localization={{
                                                header: {
                                                    actions: <span className="classHeaderTabela">Ações</span>
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                    editRow: {
                                                        deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                        saveTooltip: '',
                                                        cancelTooltip: '',
                                                    },
                                                    deleteTooltip: '',
                                                    editTooltip: '',
                                                    addTooltip: ''
                                                },
                                                toolbar: {
                                                    searchTooltip: '',
                                                    searchPlaceholder: 'Pesquisar',
                                                    exportTitle: "",
                                                    exportCSVName: "Exportar como CSV",
                                                    exportPDFName: "Exportar como PDF",
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'linhas',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: '',
                                                    previousTooltip: '',
                                                    nextTooltip: '',
                                                    lastTooltip: ''
                                                },
                                                grouping: {
                                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                                    groupedBy: 'Agrupado por:'
                                                }
                                            }}
                                        />
                                        :
                                        <Loading />
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>

            <ModalFaturamentoDocumento
                showModal={show}
                onHide={handleClose}
                idFaturamento={dataId}
            />
        </>
    )
}