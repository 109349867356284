import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import moment from 'moment';
import MaterialTable from "material-table";
import { BuscarCaracteristicaRespostaPorNomeRequest, LimparlistaCaracteristicaRespostaPorNome } from "../../store/modules/caracteristicaResposta/actions";
import { toast } from "react-toastify";
import ModalInserirDestinatario from "../ModalInserirDestinatario/modalInserirDestinatario";

export default function ModalDestinatario({ show, onHide, destinatarioSelecinado, idSolicitacaoFornecimento, idTipoSolicitacao }) {

  const dispatch = useDispatch();


  const listaCaracteristicaRespostaPorNome = useSelector(state => state.caracteristicaResposta.listaCaracteristicaRespostaPorNome);
  const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);

  const [selectedRow, setSelectedRow] = useState(null);
  const [destinatario, setDestinatario] = useState('');
  const [showDestinatario, setShowDestinatario] = useState(false);

  const handleCloseDestinatario = () => setShowDestinatario(false);
  const handleShowDestinatario = () => setShowDestinatario(true);

  useEffect(() => {
    moment.locale('pt-br');
  }, [dispatch])

  useEffect(() => {

  }, [])

  function Selecionar() {

    if (listaCaracteristicaRespostaPorNome != undefined && listaCaracteristicaRespostaPorNome.length > 0) {
      destinatarioSelecinado(listaCaracteristicaRespostaPorNome[selectedRow]);
      dispatch(LimparlistaCaracteristicaRespostaPorNome());
      setDestinatario('');
      onHide();
    } else {
      toast.error('Destinatario não selecionado, busque o destinatario para depois selecionar.');
    }

  }

  function Buscar() {

    dispatch(BuscarCaracteristicaRespostaPorNomeRequest(destinatario));

  }

  function CadastrarDestinatario() {
    handleShowDestinatario();
  }

  return (

    <>
      <Modal
        size="xl"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Selecione a linha da tabela para selecionar o destinatário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MaterialTable
            components={{
              Pagination: PatchedPagination,
            }}
            title={
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  Destinatario
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    value={destinatario}
                    onChange={e => setDestinatario(e.target.value)} />
                </Col>
                <Col sm="2">
                  <Button variant="success" onClick={Buscar}>Buscar</Button>
                </Col>
                <Col sm="4">
                  <Button variant="success" onClick={CadastrarDestinatario}>Cadastrar Destinatario</Button>
                </Col>
              </Form.Group>

            }
            columns={[
              {
                title: "Código", field: 'id', defaultSort: 'desc', cellStyle: {
                  width: '5%'
                }
              },
              {
                title: "Nome", field: 'nome', sorting: true, cellStyle: {
                  width: '10%'
                }
              }
            ]}
            data={
              listaCaracteristicaRespostaPorNome != undefined ?
                listaCaracteristicaRespostaPorNome
                  .map((i) => {
                    return {
                      id: i.id,
                      nome: i.descricao
                    }
                  })
                :
                []
            }
            options={{
              minBodyHeight: 500,
              paging: true,
              pageSize: 10, // Início
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 50, 200, 300],
              filterRowStyle: {
                backgroundColor: "#FAFAFA"
              },
              headerStyle: {
                backgroundColor: '#454545',
                color: '#FFF',
                fontWeight: "bold"
              },
              actionsColumnIndex: -1,
              filtering: true,
              rowStyle: rowData => ({
                backgroundColor: selectedRow === rowData.tableData.id ? "#6ABAC9" : "#FFF",
              }),
              selection: false
            }}
            onRowClick={(evt, selectedRow) => {
              setSelectedRow(selectedRow.tableData.id);
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Nenhum registro para exibir',
                editRow: {
                  deleteText: 'Você tem certeza que deseja excluir essa linha?',
                  saveTooltip: '',
                  cancelTooltip: '',
                },
                deleteTooltip: '',
                editTooltip: '',
                addTooltip: '',
              },
              toolbar: {
                searchTooltip: '',
                searchPlaceholder: 'Pesquisar',
                exportTitle: "",
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                nRowsSelected: '{0} linha(s) selecionadas'
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{count} de {from}-{to}',
                firstTooltip: '',
                previousTooltip: '',
                nextTooltip: '',
                lastTooltip: ''
              },
              grouping: {
                placeholder: 'Arraste a coluna aqui para agrupar',
                groupedBy: 'Agrupado por:'
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={Selecionar} style={{ width: '100%' }}>Selecionar</Button>
        </Modal.Footer>
      </Modal>

      <ModalInserirDestinatario
        show={showDestinatario}
        onHide={handleCloseDestinatario}
        idSolicitacaoFornecimento={idSolicitacaoFornecimento}
        idTipoSolicitacao={idTipoSolicitacao}
      />
    </>
  );
}