export function ListarContratoVersaoSubitemCustoConsumidoRequest() {
    return {
      type: 'LISTAR_CONTRATO_VERSAO_SUBITEM_CUSTO_CONSUMIDO_REQUEST'
    }
  }
  
  export function ListarContratoVersaoSubitemCustoConsumidoResponse(listaContratoVersaoSubitemCustoConsumido) {
    return {
      type: 'LISTAR_CONTRATO_VERSAO_SUBITEM_CUSTO_CONSUMIDO_RESPONSE',
      listaContratoVersaoSubitemCustoConsumido
    }
  }
  
  export function InserirContratoVersaoSubitemCustoConsumidoRequest(data) {
    return {
      type: 'INSERIR_CONTRATO_VERSAO_SUBITEM_CUSTO_CONSUMIDO_REQUEST',
      data
    }
  }
  
  export function AlterarContratoVersaoSubitemCustoConsumidoRequest(data) {
    return {
      type: 'ALTERAR_CONTRATO_VERSAO_SUBITEM_CUSTO_CONSUMIDO_REQUEST',
      data
    }
  }
  
  export function ExcluirContratoVersaoSubitemCustoConsumidoRequest(id) {
    return {
      type: 'EXCLUIR_CONTRATO_VERSAO_SUBITEM_CUSTO_CONSUMIDO_REQUEST',
      id
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }