import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap'
import { LoginUsuarioRequest } from '../../../store/modules/usuario/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IoEyeSharp } from "react-icons/io5";
import ModalEsqueceuSenha from '../../../components/ModalEsqueceuSenha/modalEsqueceuSenha';
import img from '../../../assets/login/img_login.png';
import './login.css';

export default function Login() {

    const dispatch = useDispatch();

    const [Email, setEmail] = useState('');
    const [Senha, setSenha] = useState('');

    const [modalShow, setModalShow] = useState(false);

    function Login(e) {
        e.preventDefault();

        if (Email === '' || Senha === '') {
            toast.error('Ops, é necessário preencher todos os campos');
            return;
        }

        var data = {
            'Email': Email,
            'Senha': Senha
        }

        dispatch(LoginUsuarioRequest(data));
    }

    function EsqueceuSenha() {
        setModalShow(true);
    }

    function VerSenha() {
        let input = document.getElementById('password');
        if (input.getAttribute('type') == 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    return (
        <>
            <Container className='divLogin'>
                <Row className='rowLogin'>
                    <Col md={{ span: 6 }} className='colBannerLogin'>
                        <Row className='imagemLogin'>
                            <img src={img} alt='img' />
                        </Row>
                    </Col>
                    <Col md={{ span: 6 }} className='ColFormLogin'>
                        <span className='tituloLogin'>Login</span>
                        <Form onSubmit={Login}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className='labelLogin'>Email</Form.Label>
                                <Form.Control
                                    type="email" placeholder='email@fortaleza.com'
                                    onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="form-group" controlId="formBasicPassword">
                                <Form.Label className='labelLogin'>Senha</Form.Label>

                            </Form.Group>
                            <InputGroup>
                                <Form.Control
                                    type="password"
                                    placeholder='**************'
                                    onChange={(e) => setSenha(e.target.value)}
                                    id="password"
                                />
                                <InputGroup.Text onClick={VerSenha} className="campoCssOlho">
                                    <IoEyeSharp className='cssOlho' size={25} />
                                </InputGroup.Text>
                            </InputGroup>
                            <Form.Group className="mb-3" controlId="formBasicAcessar">
                                <Button variant="primary" type="submit" className="btnAcessarLogin">
                                    Acessar
                                </Button>
                            </Form.Group>
                            <div className='linkEsqueciSenha'>
                                <a onClick={EsqueceuSenha}>Esqueci a senha</a>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <ModalEsqueceuSenha
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Container>
        </>


    )
}