import { Container, Row, Col, Form, Button, Modal, Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { InserirItensContratoVersaoRequest } from '../../store/modules/contratoVersao/actions';
import { ImportarContratoExcelRequest } from "../../store/modules/contrato/actions";
import { CriarTxt } from "../../store/modules/global/actions";
import { DropzoneArea } from 'material-ui-dropzone';
import { toast } from "react-toastify";
import { ListarCompletoSolicitacaoFornecimentoRequest } from '../../store/modules/solicitacaoFornecimento/actions';
import * as XLSX from "xlsx";
import moment from 'moment';
import ModalSolicitacaoAjusteImportacao from "../ModalSolicitacaoAjusteImportacao/modalSolicitacaoAjusteImportacao";

export default function ModalContratoVersao({ idContratoCompleto, listaTipoContratoVersao,
    dataInicioContratoForm, dataFimContratoForm, valorIndiceReajusteForm, valorIndiceMultaForm, valorIndiceQuantidadeItemExcedenteForm, show, onHide, liberaExcedenteGlobal }) {

    const { id } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const contratoCompleto = useSelector(state => state.contrato.contratoCompleto);
    const listaSolicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.listaSolicitacaoFornecimento);

    const [dataInicioContrato, setDataInicioContrato] = useState(moment((new Date())).add('hours', 2).toISOString().substr(0, 10));
    const [dataFimContrato, setDataFimContrato] = useState(moment((new Date())).add('hours', 2).toISOString().substr(0, 10));
    const [valorIndiceReajuste, setValorIndiceReajuste] = useState('');
    const [valorIndiceMulta, setValorIndiceMulta] = useState('');
    const [valorIndiceQuantidadeItemExcedente, setValorIndiceQuantidadeItemExcedente] = useState('');
    const [idTipoContratoVersao, setIdTipoContratoVersao] = useState('');
    const [validated, setValidated] = useState(false);
    const [validatedDataFim, setValidatedDataFim] = useState(true);
    const [dados, setDados] = useState('');
    const [tipo, setTipo] = useState('');
    const [items, setItems] = useState([]);

    const [modal, setModal] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSolicitacao, setShowSolicitacao] = useState(false);
    const [showSolicitacaoImportacao, setShowSolicitacaoImportacao] = useState(false);

    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const handleSolicitacaoImportacaoClose = () => setShowSolicitacaoImportacao(false);
    const handleSolicitacaoImportacaoShow = () => setShowSolicitacaoImportacao(true);

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setItems(d);
        });
    };

    useEffect(() => {

        moment.locale('pt-br');

        if (idContratoCompleto != null && idContratoCompleto != undefined) {

            dispatch(ListarCompletoSolicitacaoFornecimentoRequest({
                IdSolicitacaoFornecimento: null,
                IdContrato: idContratoCompleto,
                Servico: true
            }));
        }

    }, [idContratoCompleto])

    useEffect(() => {

        setDataInicioContrato(dataInicioContratoForm);
        setDataFimContrato(dataFimContratoForm);
        setValorIndiceReajuste(valorIndiceReajusteForm);
        setValorIndiceMulta(valorIndiceMultaForm);
        setValorIndiceQuantidadeItemExcedente(valorIndiceQuantidadeItemExcedenteForm);

    }, [dataInicioContratoForm, dataFimContratoForm, valorIndiceReajusteForm, valorIndiceMultaForm, valorIndiceQuantidadeItemExcedenteForm])

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false || ValidaDataInicioFim() === false ||
            (valorIndiceMulta > 1 || valorIndiceMulta < 0 || valorIndiceMulta === "") === true ||
            (valorIndiceReajuste > 1 || valorIndiceReajuste < -1 || valorIndiceReajuste === "") === true ||
            (valorIndiceQuantidadeItemExcedente > 1 || valorIndiceQuantidadeItemExcedente < 0 || valorIndiceQuantidadeItemExcedente === "") === true) {
            event.preventDefault();
        } else {

            event.preventDefault();

            var data = {
                'Id': 0,
                'IdContrato': idContratoCompleto,
                'IdTipoContratoVersao': idTipoContratoVersao,
                'DataVersao': moment((new Date())).add('hours', 2),
                'DataInicioContrato': dataInicioContrato,
                'DataFimContrato': dataFimContrato,
                'ValorIndiceReajuste': parseFloat(valorIndiceReajuste),
                'ValorIndiceMulta': parseFloat(valorIndiceMulta),
                'GruposCusto': contratoCompleto.gruposCusto,
                'AlterarSub': false,
                'IdUsuario': usuario.id,
                'ValorIndiceQuantidadeItemExcedente': parseFloat(valorIndiceQuantidadeItemExcedente),
                'LiberaExcedenteGlobal': liberaExcedenteGlobal,
                'ListaIdSolicitacaoFornecimento': null,
                'ListaIdSolicitacaoFornecimentoCancelar': null
            }

            if ((listaSolicitacaoFornecimento == undefined) || (listaSolicitacaoFornecimento != undefined &&
                listaSolicitacaoFornecimento.filter(s => s.statusAtual != "Solicitação Finalizada" && s.statusAtual != "").length == 0)) {

                //dispatch(InserirItensContratoVersaoRequest(data, idContratoCompleto));

            }

            setDados(data);
            setTipo("Ajustes");
            handleSolicitacaoImportacaoShow();
            onHide(true);

        }

        setValidated(true);
    };

    function ValidaDataInicioFim() {

        var verificarDatainicio = dataInicioContrato.split("-");
        var verificarDatafim = dataFimContrato.split("-");

        if (verificarDatafim >= verificarDatainicio) {
            return true;
        } else {
            return false;
        }
    }

    function VerificarDataInicioContrato(event) {
        setDataInicioContrato(event.target.value);

        var verificarDatainicio = event.target.value.split("-");
        var verificarDatafim = dataFimContrato.split("-");

        if (verificarDatafim >= verificarDatainicio) {
            setValidatedDataFim(true);
        } else {
            setValidatedDataFim(false);
        }
    }

    function VerificarDataFimContrato(event) {
        setDataFimContrato(event.target.value);

        var verificarDatafim = event.target.value.split("-");
        var verificarDatainicio = dataInicioContrato.split("-");

        if (verificarDatafim >= verificarDatainicio) {
            setValidatedDataFim(true);
        } else {
            setValidatedDataFim(false);
        }
    }

    function handleImport() {
        if (idTipoContratoVersao === '' || ValidaDataInicioFim() === false ||
            (valorIndiceMulta === 0 || valorIndiceMulta === "") === true ||
            (valorIndiceReajuste === 0 || valorIndiceReajuste === "") === true ||
            (valorIndiceQuantidadeItemExcedente === 0 || valorIndiceQuantidadeItemExcedente === "") === true) {
            toast.error('Preencha todos os campos.');
        } else {
            setModal(!modal)
        }
    }

    function vezesLetraAparece(frase, letra) {
        var resultado = 0;

        for (const letraAtual of frase) {
            if (letraAtual === letra) {
                resultado++;
            }
        }

        return resultado;
    }

    function ImportarArquivo(e) {

        e.preventDefault();

        if (idTipoContratoVersao === '' || ValidaDataInicioFim() === false ||
            (valorIndiceMulta === 0 || valorIndiceMulta === "") === true ||
            (valorIndiceReajuste === 0 || valorIndiceReajuste === "") === true ||
            (valorIndiceQuantidadeItemExcedente === 0 || valorIndiceQuantidadeItemExcedente === "") === true) {
            toast.error('Preencha todos os campos.');
        } else {
            try {

                if (items.length > 0) {

                    let lista = [];
                    let continued = true;
                    let wrongText = "";
                    let count = 0;

                    var data = {
                        'idPrimeiraVersaoContrato': contratoCompleto.contratoVersaoApiModel.id,
                        'idTipoContratoVersao': idTipoContratoVersao,
                        'dataInicioContrato': dataInicioContrato,
                        'dataFimContrato': dataFimContrato,
                        'valorIndiceReajuste': parseFloat(valorIndiceReajuste),
                        'valorIndiceMulta': parseFloat(valorIndiceMulta),
                        'pageImport': true,
                        'idUsuario': usuario.id,
                        'valorIndiceQuantidadeItemExcedente': parseFloat(valorIndiceQuantidadeItemExcedente),
                        'liberaExcedenteGlobal': liberaExcedenteGlobal
                    }

                    items.forEach(item => {

                        if (item.__EMPTY_5 != undefined &&
                            vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 0 &&
                            Number.isInteger(item.__EMPTY_1)) {
                            lista.push({ 'item': item, 'tipo': 'grupo', 'contrato': data });
                        }

                        if (vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 1 &&
                            (item.__EMPTY_7 == undefined || item.__EMPTY_7 == '') &&
                            (item.__EMPTY_8 == undefined || item.__EMPTY_8 == '')) {
                            lista.push({ 'item': item, 'tipo': 'item', 'contrato': data });

                        } else
                            if (vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 1 &&
                                item.__EMPTY_7 != undefined &&
                                item.__EMPTY_8 != undefined) {
                                lista.push({ 'item': item, 'tipo': 'subitemSemItem', 'contrato': data });
                            }

                        if (vezesLetraAparece(item.__EMPTY_1.toString(), '.') == 2 && item.__EMPTY_7 != undefined) {

                            if (item.__EMPTY_1 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "ITEM". \n`;
                            }
                            if (item.__EMPTY_2 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "FONTE". \n`;
                            }
                            if (item.__EMPTY_3 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "COMPOSIÇÃO". \n`;
                            }
                            if (item.__EMPTY_4 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "CÓDIGO". \n`;
                            }
                            if (item.__EMPTY_5 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "DESCRIÇÃO". \n`;
                            }
                            if (item.__EMPTY_6 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "UNIDADE". \n`;
                            }
                            if (item.__EMPTY_7 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "QUANTIDADE". \n`;
                            }
                            if (item.__EMPTY_8 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "VALOR UNITÁRIO". \n`;
                            }
                            if (item.__EMPTY_9 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "VALOR TOTAL". \n`;
                            }
                            if (item.__EMPTY_10 == undefined) {
                                continued = false;
                                wrongText += `linha com erro: Linha: ${count - 2} campo "VALOR PREVISÃO MENSAL". \n`;
                            }

                            lista.push({ 'item': item, 'tipo': 'subitem', 'contrato': data });
                        }

                        count++;
                    });

                    if (!continued) {
                        lista = [];
                        continued = true;
                    }

                    if (lista.length > 0) {

                        if ((listaSolicitacaoFornecimento == undefined) || (listaSolicitacaoFornecimento != undefined &&
                            listaSolicitacaoFornecimento.filter(s => s.statusAtual != "Solicitação Finalizada" && s.statusAtual != "").length == 0)) {

                            dispatch(ImportarContratoExcelRequest({
                                'ListaObjeto': lista,
                                'ListaIdSolicitacaoFornecimento': null,
                                'ListaIdSolicitacaoFornecimentoCancelar': null
                            }, id));

                        }
                        setModal(false);
                        setDados(lista);
                        setTipo("Importacao");
                        handleSolicitacaoImportacaoShow();
                        onHide();
                    } else {
                        handleShowError();
                        dispatch(CriarTxt(wrongText));
                        setMessageError(`Existe algo errado com o excel importado. \nLINHA INFORMADA A PARTIR DO CABEÇALHO DA TABELA: \nERROS: \n\n${wrongText}`);
                    }
                } else {
                    handleShowError();
                    setMessageError("Selecione o Excel antes de Importar!");
                }

            } catch (error) {
                handleShowError();
                setMessageError("CAMPOS IMPORTANTES ESTÃO FALTANDO NO EXCEL, VERIFIQUE NOVAMENTE ANTES DE IMPORTAR");
            }
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Nova Versão Contrato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="Duplicar" id="tab-duplicar" className="mb-3">
                        <Tab eventKey="Duplicar" title="Duplicar Subitens">
                            <Form noValidate onSubmit={handleSubmit}>
                                <Container className="divBody">
                                    <Row>
                                        <Col md={12}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formDataInicioContrato">
                                                    <Form.Label>Data início contrato</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        value={dataInicioContrato}
                                                        onChange={VerificarDataInicioContrato}
                                                        required
                                                        isInvalid={validated ? false : false}
                                                        isValid={validated ? true : false} />
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} controlId="formDataFimContrato">
                                                    <Form.Label>Data fim contrato</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        value={dataFimContrato}
                                                        onChange={VerificarDataFimContrato}
                                                        required
                                                        isInvalid={validated && !validatedDataFim ? true : false}
                                                        isValid={validated && validatedDataFim ? true : false} />
                                                    <Form.Control.Feedback type="invalid">A data do fim não pode ser antes da data do início do contrato.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formValorIndiceReajuste">
                                                    <Form.Label>Valor índice reajuste</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={valorIndiceReajuste}
                                                        onChange={(e) => setValorIndiceReajuste(e.target.value)}
                                                        isInvalid={validated && valorIndiceReajuste === ""
                                                            || validated && valorIndiceReajuste > 1
                                                            || validated && valorIndiceReajuste < -1 ? true : false}
                                                        isValid={validated && valorIndiceReajuste !== ""
                                                            || validated && valorIndiceReajuste < 1
                                                            || validated && valorIndiceReajuste > -1 ? true : false}
                                                        required
                                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            valorIndiceReajuste === "" ?
                                                                'O campo Valor índice reajuste é obrigatório.'
                                                                :
                                                                'O Valor índice reajuste deve estar no intervalo de -1 a 1.'

                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} controlId="formValorIndiceMulta">
                                                    <Form.Label>Valor índice multa</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={valorIndiceMulta}
                                                        onChange={(e) => setValorIndiceMulta(e.target.value)}
                                                        isInvalid={validated && valorIndiceMulta < 0
                                                            || validated && valorIndiceMulta > 1
                                                            || validated && valorIndiceMulta === "" ? true : false}
                                                        isValid={validated && valorIndiceMulta > 0
                                                            || validated && valorIndiceMulta < 1
                                                            || validated && valorIndiceMulta !== "" ? true : false}
                                                        required
                                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            valorIndiceMulta === "" ?
                                                                'O campo Valor índice multa é obrigatório.'
                                                                :
                                                                'O Valor índice multa deve estar no intervalo de 0 a 1.'

                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formIdCliente">
                                                    <Form.Label>Tipo Contrato Versão</Form.Label>
                                                    <Form.Select
                                                        onChange={(e) => setIdTipoContratoVersao(e.target.value)}
                                                        value={idTipoContratoVersao}
                                                        required
                                                        isInvalid={validated && idTipoContratoVersao === "" ? true : false}
                                                        isValid={validated && idTipoContratoVersao !== "" ? true : false}>
                                                        <option value="">Selecione...</option>
                                                        {
                                                            listaTipoContratoVersao !== null ?
                                                                listaTipoContratoVersao.map(m => (
                                                                    <option value={m.id} key={m.id}>{m.descricao}</option>
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">O campo Tipo Contrato Versão é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} controlId="formValorIndiceQuantidadeItemExcedente">
                                                    <Form.Label>Valor índice qtd. itens excedentes</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={valorIndiceQuantidadeItemExcedente}
                                                        onChange={(e) => setValorIndiceQuantidadeItemExcedente(e.target.value)}
                                                        isInvalid={validated && valorIndiceQuantidadeItemExcedente < 0
                                                            || validated && valorIndiceQuantidadeItemExcedente > 1
                                                            || validated && valorIndiceQuantidadeItemExcedente === "" ? true : false}
                                                        isValid={validated && valorIndiceQuantidadeItemExcedente > 0
                                                            || validated && valorIndiceQuantidadeItemExcedente < 1
                                                            || validated && valorIndiceQuantidadeItemExcedente !== "" ? true : false}
                                                        required
                                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            valorIndiceQuantidadeItemExcedente === "" ?
                                                                'O campo Valor índice quantidade de itens excedente é obrigatório.'
                                                                :
                                                                'O Valor índice quantidade de itens excedente deve estar no intervalo de 0 a 1.'

                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Col md={6}>
                                                <Button type="submit"
                                                    variant="success" >Duplicar subitens reajustando</Button>
                                            </Col>

                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Tab>
                        <Tab eventKey="Importar" title="Importar Subitens">
                            <Form noValidate>
                                <Container className="divBody">
                                    <Row>
                                        <Col md={12}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formDataInicioContrato">
                                                    <Form.Label>Data início contrato</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        value={dataInicioContrato}
                                                        onChange={VerificarDataInicioContrato}
                                                        required
                                                        isInvalid={validated ? false : false}
                                                        isValid={validated ? true : false} />
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} controlId="formDataFimContrato">
                                                    <Form.Label>Data fim contrato</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        value={dataFimContrato}
                                                        onChange={VerificarDataFimContrato}
                                                        required
                                                        isInvalid={validated && !validatedDataFim ? true : false}
                                                        isValid={validated && validatedDataFim ? true : false} />
                                                    <Form.Control.Feedback type="invalid">A data do fim não pode ser antes da data do início do contrato.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formValorIndiceReajuste">
                                                    <Form.Label>Valor índice reajuste</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={valorIndiceReajuste}
                                                        onChange={(e) => setValorIndiceReajuste(e.target.value)}
                                                        isInvalid={validated && valorIndiceReajuste === ""
                                                            || validated && valorIndiceReajuste > 1
                                                            || validated && valorIndiceReajuste < -1 ? true : false}
                                                        isValid={validated && valorIndiceReajuste !== ""
                                                            || validated && valorIndiceReajuste < 1
                                                            || validated && valorIndiceReajuste > -1 ? true : false}
                                                        required
                                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            valorIndiceReajuste === "" ?
                                                                'O campo Valor índice reajuste é obrigatório.'
                                                                :
                                                                'O Valor índice reajuste deve estar no intervalo de -1 a 1.'

                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} controlId="formValorIndiceMulta">
                                                    <Form.Label>Valor índice multa</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={valorIndiceMulta}
                                                        onChange={(e) => setValorIndiceMulta(e.target.value)}
                                                        isInvalid={validated && valorIndiceMulta < 0
                                                            || validated && valorIndiceMulta > 1
                                                            || validated && valorIndiceMulta === "" ? true : false}
                                                        isValid={validated && valorIndiceMulta > 0
                                                            || validated && valorIndiceMulta < 1
                                                            || validated && valorIndiceMulta !== "" ? true : false}
                                                        required
                                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            valorIndiceMulta === "" ?
                                                                'O campo Valor índice multa é obrigatório.'
                                                                :
                                                                'O Valor índice multa deve estar no intervalo de 0 a 1.'

                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md={6} controlId="formIdCliente">
                                                    <Form.Label>Tipo Contrato Versão</Form.Label>
                                                    <Form.Select
                                                        onChange={(e) => setIdTipoContratoVersao(e.target.value)}
                                                        value={idTipoContratoVersao}
                                                        required
                                                        isInvalid={validated && idTipoContratoVersao === "" ? true : false}
                                                        isValid={validated && idTipoContratoVersao !== "" ? true : false}>
                                                        <option value="">Selecione...</option>
                                                        {
                                                            listaTipoContratoVersao !== null ?
                                                                listaTipoContratoVersao.map(m => (
                                                                    <option value={m.id} key={m.id}>{m.descricao}</option>
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">O campo Tipo Contrato Versão é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6} controlId="formValorIndiceQuantidadeItemExcedente">
                                                    <Form.Label>Valor índice qtd. itens excedentes</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={valorIndiceQuantidadeItemExcedente}
                                                        onChange={(e) => setValorIndiceQuantidadeItemExcedente(e.target.value)}
                                                        isInvalid={validated && valorIndiceQuantidadeItemExcedente < 0
                                                            || validated && valorIndiceQuantidadeItemExcedente > 1
                                                            || validated && valorIndiceQuantidadeItemExcedente === "" ? true : false}
                                                        isValid={validated && valorIndiceQuantidadeItemExcedente > 0
                                                            || validated && valorIndiceQuantidadeItemExcedente < 1
                                                            || validated && valorIndiceQuantidadeItemExcedente !== "" ? true : false}
                                                        required
                                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            valorIndiceQuantidadeItemExcedente === "" ?
                                                                'O campo Valor índice quantidade de itens excedente é obrigatório.'
                                                                :
                                                                'O Valor índice quantidade de itens excedente deve estar no intervalo de 0 a 1.'

                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group>
                                                    <div>
                                                        <DropzoneArea
                                                            initialFiles={[]}
                                                            onChange={(files) => {
                                                                if (files.length > 0) {
                                                                    readExcel(files[0])
                                                                } else {
                                                                    setItems([])
                                                                }
                                                            }}
                                                            filesLimit={1}
                                                            acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                                            showPreviewsInDropzone={true}
                                                            useChipsForPreview
                                                            getFileLimitExceedMessage={() => `Só é permitido adicionar 1 arquivo`}
                                                            getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                                            getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                                            disableRejectionFeedback={true}
                                                            dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                            maxFileSize={30000000}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                            <Button
                                                variant="success"
                                                style={{ display: 'inline-block' }}
                                                onClick={ImportarArquivo}>Importar</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>

            <Modal
                show={showError}
                onHide={handleCloseError}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="divModal">
                        <p>{messageError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCloseError}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalSolicitacaoAjusteImportacao
                idContrato={id}
                show={showSolicitacaoImportacao}
                onHide={handleSolicitacaoImportacaoClose}
                dados={dados}
                tipo={tipo}
            />
        </>
    );
}