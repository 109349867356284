import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { BuscarOrgaoPorNomeResponse, Carregando, ListarOrgaoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarOrgaoRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Orgao`, { headers: headerParams.token });

    yield put(ListarOrgaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ListarOrgaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* InserirOrgaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/Orgao`, model.data, { headers: headerParams.token });

    toast.success(`O Orgao foi cadastrado com sucesso!`);

    const result = yield call(api.get, `/api/Orgao`, { headers: headerParams.token });

    yield put(ListarOrgaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarOrgaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/Orgao`, model.data, { headers: headerParams.token });

    toast.success(`Orgao atualizado com sucesso!`);

    const result = yield call(api.get, `/api/Orgao`, { headers: headerParams.token });

    yield put(ListarOrgaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirOrgaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/Orgao/${model.id}`, { headers: headerParams.token });

    toast.success(`Orgao id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/Orgao`, { headers: headerParams.token });

    yield put(ListarOrgaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }


    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* BuscarOrgaoPorNomeRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Orgao/BuscarPorNome/${model.orgao}`,
      { headers: headerParams.token });

    yield put(BuscarOrgaoPorNomeResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(BuscarOrgaoPorNomeResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_ORGAO_REQUEST', ListarOrgaoRequest),
  takeLatest('INSERIR_ORGAO_REQUEST', InserirOrgaoRequest),
  takeLatest('ALTERAR_ORGAO_REQUEST', AlterarOrgaoRequest),
  takeLatest('EXCLUIR_ORGAO_REQUEST', ExcluirOrgaoRequest),
  takeLatest('BUSCAR_ORGAO_POR_NOME_REQUEST', BuscarOrgaoPorNomeRequest),
])