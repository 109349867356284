export function ListarFaturamentoDocumentoArquivoRequest(idFaturamento) {
  return {
    type: 'LISTAR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST', idFaturamento
  }
}

export function ListarFaturamentoDocumentoArquivoResponse(listaFaturamentoDocumentoArquivo) {
  return {
    type: 'LISTAR_FATURAMENTO_DOCUMENTO_ARQUIVO_RESPONSE',
    listaFaturamentoDocumentoArquivo
  }
}

export function InserirFaturamentoDocumentoArquivoRequest(data, idFaturamento) {
  return {
    type: 'INSERIR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    data, idFaturamento
  }
}

export function AlterarFaturamentoDocumentoArquivoRequest(data, idFaturamento) {
  return {
    type: 'ALTERAR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    data, idFaturamento
  }
}

export function ExcluirFaturamentoDocumentoArquivoRequest(id, idFaturamento) {
  return {
    type: 'EXCLUIR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id, idFaturamento
  }
}

export function BaixarFaturamentoDocumentoArquivoRequest(id) {
  return {
    type: 'BAIXAR_FATURAMENTO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}