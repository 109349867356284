import produce from 'immer';

const initialState = {
  listaGrupoFuncaoAcesso: undefined,
  carregando: true
};

export default function permissoesGrupo(state = initialState, action){
  
  switch(action.type){

    case 'LISTAR_GRUPO_FUNCAO_ACESSO_RESPONSE':
      return produce(state, draft => {
        draft.listaGrupoFuncaoAcesso = action.listaGrupoFuncaoAcesso;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}