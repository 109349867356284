import produce from 'immer';

const initialState = {
  listaRelatorioMedicaoSubItem: [],
  listaRelatorioAutorizacaoFornecimentoSubitemCusto: [],
  listaDadosExportarPDFAutorizacaoFornecimento: null,
  listaDadosExportarPDFSolicitacaoFornecimento: [],
  listaDadosExportarPDFEmpenho: null,
  listaDadosExportarPDFSolicitacaoFornecimentoEvento: [],
  listaDadosExportarPDFEmpenhoBoletimMedicao: null,
  listaRelatorioStatusSolicitacao: null,
  listaRelatorioStatusAutorizacao: null,
  listaRelatorioStatusMedicao: null,
  listaRelatorioStatusEmpenho: null,
  listaRelatorioSolicitacao: null,
  carregandoGlobal: null,
  carregando: true
};

export default function relatorio(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_RELATORIO_AUTORIZACAO_FORNECIMENTO_SUBITEM_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioAutorizacaoFornecimentoSubitemCusto = action.listaRelatorioAutorizacaoFornecimentoSubitemCusto;
      });

    case 'LISTAR_RELATORIO_MEDICAO_SUBITEM_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioMedicaoSubItem = action.listaRelatorioMedicaoSubItem;
      });

    case 'LISTAR_DADOS_EXPORTAR_PDF_AUTORIZACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaDadosExportarPDFAutorizacaoFornecimento = action.listaDadosExportarPDFAutorizacaoFornecimento;
      });

    case 'LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaDadosExportarPDFSolicitacaoFornecimento = action.listaDadosExportarPDFSolicitacaoFornecimento;
      });

    case 'LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_RESPONSE':
      return produce(state, draft => {
        draft.listaDadosExportarPDFEmpenho = action.listaDadosExportarPDFEmpenho;
      });

    case 'LISTAR_DADOS_EXPORTAR_PDF_SOLICITACAO_FORNECIMENTO_EVENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaDadosExportarPDFSolicitacaoFornecimentoEvento = action.listaDadosExportarPDFSolicitacaoFornecimentoEvento;
      });

    case 'LISTAR_DADOS_EXPORTAR_PDF_EMPENHO_BOLETIM_MEDICAO_RESPONSE':
      return produce(state, draft => {
        draft.listaDadosExportarPDFEmpenhoBoletimMedicao = action.listaDadosExportarPDFEmpenhoBoletimMedicao;
      });

    case 'LIMPAR_RELATORIO_EMPENHO':
      return produce(state, draft => {
        draft.listaDadosExportarPDFEmpenho = null
      });

    case 'LISTAR_RELATORIO_STATUS_SOLICITACAO_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioStatusSolicitacao = action.listaRelatorioStatusSolicitacao;
      });

    case 'LISTAR_RELATORIO_STATUS_AUTORIZACAO_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioStatusAutorizacao = action.listaRelatorioStatusAutorizacao;
      });

    case 'LISTAR_RELATORIO_STATUS_MEDICAO_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioStatusMedicao = action.listaRelatorioStatusMedicao;
      });

    case 'LISTAR_RELATORIO_STATUS_EMPENHO_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioStatusEmpenho = action.listaRelatorioStatusEmpenho;
      })

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    case 'CARREGANDO_GLOBAL':
      return produce(state, draft => {
        draft.carregandoGlobal = action.status 
      });

    case 'LISTAR_RELATORIO_SOLICITACAO_RESPONSE':
      return produce(state, draft => {
        draft.listaRelatorioSolicitacao = action.listaRelatorioSolicitacao;
      });

    default:
      return state;
  }
}