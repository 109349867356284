import produce from 'immer';

const initialState = {
  listaEventoAdicional: undefined,
  carregando: true,
  eventoAdicionalCompleto: null
};

export default function eventoAdicional(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_EVENTO_ADICIONAL_RESPONSE':
      return produce(state, draft => {
        draft.listaEventoAdicional = action.listaEventoAdicional;
      });

      case 'BUSCAR_EVENTO_ADICIONAL_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.eventoAdicionalCompleto = action.eventoAdicionalCompleto;
      });

      case 'LIMPAR_EVENTO_ADICIONAL':
      return produce(state, draft => {
        draft.eventoAdicionalCompleto = null
      }); 

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}