import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import Routes from './routes';
import history from './services/history';
import store from './store';
import 'react-toastify/dist/ReactToastify.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IconContext } from "react-icons/lib" 
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Chart,
  ArcElement,
  BarElement
} from 'chart.js'

Chart.register(CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  ChartDataLabels);

function App() {

  return (
    <>
      <Provider store={store}>
        <Router history={history}>
          <ToastContainer autoClose={3000} />
          <Routes />
        </Router>
      </Provider>
    </>
  );
}

export default App;
