import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarGrupoAcessoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarGrupoAcessoRequest() {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/GrupoAcesso`, { headers: headerParams.token });

    yield put(ListarGrupoAcessoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarGrupoAcessoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true))
    
  }
}

function* InserirGrupoAcessoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/GrupoAcesso`, model.data, { headers: headerParams.token });

    toast.success(`Grupo ${model.data.nomeGrupo} foi cadastrado com sucesso!`);

    const result = yield call(api.get, `/api/GrupoAcesso`, { headers: headerParams.token });

    yield put(ListarGrupoAcessoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarGrupoAcessoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/GrupoAcesso`, model.data, { headers: headerParams.token });

    toast.success(`Grupo foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/GrupoAcesso`, { headers: headerParams.token });

    yield put(ListarGrupoAcessoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirGrupoAcessoRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/GrupoAcesso/${model.id}`, { headers: headerParams.token });

    toast.success(`Grupo Acesso id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/GrupoAcesso`, { headers: headerParams.token });

    yield put(ListarGrupoAcessoResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
  }
}

export default all([
  takeLatest('LISTAR_GRUPO_ACESSO_REQUEST', ListarGrupoAcessoRequest),
  takeLatest('INSERIR_GRUPO_ACESSO_REQUEST', InserirGrupoAcessoRequest),
  takeLatest('ALTERAR_GRUPO_ACESSO_REQUEST', AlterarGrupoAcessoRequest),
  takeLatest('EXCLUIR_GRUPO_ACESSO_REQUEST', ExcluirGrupoAcessoRequest)
])