import produce from 'immer';

const initialState = {
  solicitacaoFornecimentoCompleto: null,
  listaSolicitacaoFornecimento: undefined,
  listarSolicitacaoFornecimentoCamposVerificacaoRapida: undefined,
  coordenadasPorEndereco: undefined,
  carregando: true
};

export default function solicitacaoFornecimento(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SOLICITACAO_FORNECIMENTO_RESPONSE':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimento = action.listaSolicitacaoFornecimento;
      });

    case 'BUSCAR_SOLICITACAO_FORNECIMENTO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.solicitacaoFornecimentoCompleto = action.solicitacaoFornecimentoCompleto;
      });

    case 'LIMPAR_SOLICITACAO_FORNECIMENTO':
      return produce(state, draft => {
        draft.solicitacaoFornecimentoCompleto = null
      }); 
    
    case 'LISTAR_SOLICITACAO_FORNECIMENTO_CAMPOS_VERIFICACAO_RAPIDA_RESPONSE':
      return produce(state, draft => {
        draft.listarSolicitacaoFornecimentoCamposVerificacaoRapida
          = action.listarSolicitacaoFornecimentoCamposVerificacaoRapida;
      });
    
    case 'BUSCAR_COORDENADAS_POR_ENDERECO_RESPONSE':
      return produce(state, draft => {
        draft.coordenadasPorEndereco = action.coordenadasPorEndereco;
      });    
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}