import { Col, Card, Tabs, Tab } from "react-bootstrap";
import { MdAssignment } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../../../components/Header/header";
import MedicaoAutorizacaoFornecimento from '../MedicaoAutorizacaoFornecimento/medicaoAutorizacaoFornecimento'
import Subitem from '../Subitem/subitem';
import Status from '../Status/status';
import RegistroFotografico from '../RegistroFotografico/registroFotografico';
import DocumentoArquivo from '../DocumentoArquivo/documentoArquivo';
import Ocorrencia from '../Ocorrencia/ocorrencia';
import HistoricoStatus from '../HistoricoStatus/historicoStatus';
import { BuscarAutorizacaoFornecimentoPorIdRequest } from "../../../store/modules/autorizacaoFornecimento/actions";

export default function Abas() {

    const { idMedicao, idAutorizacaoFornecimento } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const autorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimento);
    
    useEffect(() => {
        dispatch(BuscarAutorizacaoFornecimentoPorIdRequest(idAutorizacaoFornecimento));
    }, [])

    useEffect(() => {
        
        if (autorizacaoFornecimento != null &&
            autorizacaoFornecimento != undefined &&
            usuario.usuarioFornecedor == true &&
            autorizacaoFornecimento.solicitacaoFornecimentoVersao.solicitacaoFornecimento.idFornecedor != usuario.fornecedor.idFornecedor) {
            history.push('/');
        }
    }, [autorizacaoFornecimento])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdAssignment size={25} color={"#000"} /> Medição Autorização Fornecimento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Tabs defaultActiveKey="MedicaoAutorizacaoFornecimento" id="tab-fonte" className="mb-3">
                                <Tab eventKey="MedicaoAutorizacaoFornecimento" title="Info">
                                    <MedicaoAutorizacaoFornecimento/>
                                </Tab>
                                <Tab eventKey="Subitem" title="Item" disabled={idMedicao ? false : true}>
                                    <Subitem/>
                                </Tab>
                                <Tab eventKey="Foto" title="Registro Fotográfico" disabled={idMedicao ? false : true}>
                                    <RegistroFotografico/>
                                </Tab>
                                <Tab eventKey="Documento" title="Documentos" disabled={idMedicao ? false : true}>
                                    <DocumentoArquivo/>
                                </Tab>
                                <Tab eventKey="Ocorrencia" title="Ocorrência" disabled={idMedicao ? false : true}>
                                    <Ocorrencia/>
                                </Tab>
                                <Tab eventKey="Status" title="Status" disabled={idMedicao ? false : true}>
                                    <Status/>
                                </Tab> 
                                <Tab eventKey="HistoricoStatus" title="Historico Status" disabled={idMedicao ? false : true}>
                                    <HistoricoStatus/>
                                </Tab>                                
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}