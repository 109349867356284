import produce from 'immer';

const initialState = {
    listaStatusMedicaoAutorizacaoFornecimento: undefined,
    carregando: true
};

export default function tipoAutorizacao(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_STATUS_MEDICAO_AUTORIZACAO_FORNECIMENTO_RESPONSE':
            return produce(state, draft => {
                draft.listaStatusMedicaoAutorizacaoFornecimento = action.listaStatusMedicaoAutorizacaoFornecimento;
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}