import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Col, Button } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Select from 'react-select';
import MaterialTable from "material-table";
import Loading from "../../../components/Loading/loading";
import {
    AlterarFornecedorContaRequest,
    InserirFornecedorContaRequest,
    ListarCompletoFornecedorContaRequest
} from "../../../store/modules/fornecedorConta/actions";
import { ListarBancoRequest } from "../../../store/modules/banco/actions";
import { ListarTipoPixRequest } from "../../../store/modules/tipoPix/actions";
import { ListarTipoPessoaRequest } from "../../../store/modules/tipoPessoa/actions";
import './conta.css';
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { FaPlus } from "react-icons/fa";

export default function Conta() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const carregando = useSelector(state => state.fornecedorConta.carregando);
    const listaFornecedorConta = useSelector(state => state.fornecedorConta.listaFornecedorConta);
    const listaBanco = useSelector(state => state.banco.listaBanco);
    const listaTipoPix = useSelector(state => state.tipoPix.listaTipoPix);
    const listaTipoPessoa = useSelector(state => state.tipoPessoa.listaTipoPessoa);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectPix, setSelectPix] = useState(null);
    const [selectPessoa, setSelectPessoa] = useState(null);
    const [selectBanco, setSelectBanco] = useState(null);

    const optionsTipoPessoa = listaTipoPessoa != undefined && listaTipoPessoa ?
        listaTipoPessoa.map(lista => ({
            value: lista.id, label: lista.descricao
        }))
        :
        [];

    const optionsTipoPix = listaTipoPix != undefined && listaTipoPix ?
        listaTipoPix.map(lista => ({
            value: lista.id, label: lista.descricao
        }))
        :
        [];

    const optionsBanco = listaBanco != undefined && listaBanco ?
        listaBanco.map(lista => ({
            value: lista.id, label: lista.descricao
        }))
        :
        [];

    useEffect(() => {

        dispatch(ListarBancoRequest());
        dispatch(ListarTipoPixRequest());
        dispatch(ListarTipoPessoaRequest());

        if (id !== undefined) {
            dispatch(ListarCompletoFornecedorContaRequest(id));
        }

    }, [id])


    function AlterarConta(conta) {


        let data = {
            'Id': parseInt(conta.codigoInterno),
            'IdFornecedor': parseInt(id),
            'IdBanco': parseInt(conta.banco),
            'NumeroAgencia': conta.numeroAgencia.toString(),
            'NumeroConta': conta.numeroConta.toString(),
            'IdTipoPix': parseInt(conta.tipoPix),
            'NumeroPix': conta.numeroPix,
            'IdTipoPessoa': parseInt(conta.tipoPessoa),
            'NumeroDocumento': conta.numeroDocumento,
            'Ativa': conta.ativa == undefined ? true : conta.ativa,
        }

        dispatch(AlterarFornecedorContaRequest(data))
    }

    function ValidacoesInserir(conta) {

        let email = document.getElementById('idEmail');
        let telefone = document.getElementById('idTelefone');
        let cpf = document.getElementById('Fisica');
        let cnpj = document.getElementById('Juridica');

        let selectTipoPessoaText = document.getElementById('idSelectTipoPessoa').innerText;
        let selectTipoPessoaSelecionado = listaTipoPessoa.filter(soli => soli.descricao == selectTipoPessoaText);

        if (selectTipoPessoaSelecionado.length == 0) {
            toast.error(`Tipo Pessoa não selecionado`);
            return false;
        }
        if (selectTipoPessoaSelecionado[0].descricao == 'Pessoa Física') {
            if (cpf.value !== null && !isValidCPF(cpf.value)) {
                toast.error(`CPF com formato inválido`);
                return false;
            }
        }
        if (selectTipoPessoaSelecionado[0].descricao == 'Pessoa Jurídica') {
            if (cnpj.value !== null && !isValidCNPJ(cnpj.value)) {
                toast.error(`CNPJ com formato inválido`);
                return false;
            }
        }

        let selectTipoPixText = document.getElementById('idSelectTipoPix').innerText;
        let selectTipoPixSelecionado = listaTipoPix.filter(soli => soli.descricao == selectTipoPixText);

        if (selectTipoPixSelecionado.length == 0) {
            toast.error(`Tipo Pix não selecionado`);
            return false;
        }
        if (selectTipoPixSelecionado[0].descricao == "Email") {
            if (email.value !== null && !ValidaEmail(email.value)) {
                toast.error(`Email com formato inválido`);
                return false;
            }
        }
        else if (selectTipoPixSelecionado[0].descricao == "Telefone") {
            if (telefone.value !== null && !ValidaTelefone(telefone.value)) {
                toast.error(`Telefone com formato inválido`);
                return false;
            }
        }

        let selectBancoText = document.getElementById('idSelectBanco').innerText;
        let selectBancoSelecionado = listaBanco.filter(soli => soli.descricao == selectBancoText);

        if (selectBancoSelecionado.length == 0) {
            toast.error(`Banco não selecionado`);
            return false;
        }

        if (conta.numeroConta == null || conta.numeroConta == '' || conta.numeroConta == undefined || isNaN(conta.numeroConta)) {
            toast.error(`Número da conta não mencionado`);
            return false;
        }

        if (conta.numeroAgencia == null || conta.numeroAgencia == '' || conta.numeroAgencia == undefined || isNaN(conta.numeroAgencia)) {
            toast.error(`Número da agência não mencionado`);
            return false;
        }

        if (selectBancoSelecionado.length > 0 && selectTipoPessoaSelecionado.length > 0 && selectTipoPixSelecionado.length > 0) {
            setSelectBanco(parseInt(selectBancoSelecionado[0].id));
            setSelectPessoa(parseInt(selectTipoPessoaSelecionado[0].id));
            setSelectPix(parseInt(selectTipoPixSelecionado[0].id));

            return true;
        }

    }

    function InserirContas(conta) {

        let data = {
            'Id': 0,
            'IdFornecedor': parseInt(id),
            'IdBanco': parseInt(conta.banco),
            'NumeroAgencia': conta.numeroAgencia.toString(),
            'NumeroConta': conta.numeroConta.toString(),
            'IdTipoPix': parseInt(conta.tipoPix),
            'NumeroPix': conta.numeroPix,
            'IdTipoPessoa': parseInt(conta.tipoPessoa),
            'NumeroDocumento': conta.numeroDocumento,
            'Ativa': conta.ativa == undefined ? true : conta.ativa,
        }

        dispatch(InserirFornecedorContaRequest(data));
    }

    function isValidCNPJ(cnpj) {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return false;
        if (cnpj.length != 14)
            return false;
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false;

        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;

        return true;

    }

    function isValidCPF(cpf) {
        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/igm, '')
        if (
            !cpf ||
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        ) {
            return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(9, 10))) return false
        soma = 0
        for (var i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(10, 11))) return false
        return true
    }

    function ValidaEmail(email) {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (emailRegex.test(String(email))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidacaoDdd(ddd) {
        const numeroDDD = ddd.split('(', ddd.length);
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(numeroDDD[1]))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone(telefone) {
        const stringDDD = telefone.split(')', telefone.length);
        const stringTelefone = telefone.split(')', telefone.length);
        let telefoneValidado = false;
        telefoneValidado = ValidacaoTelefone(stringTelefone[1]);
        telefoneValidado = ValidacaoDdd(stringDDD[0]);
        return telefoneValidado;
    }

    function ValidacaoTelefone(telefone) {
        const telefoneRegex = /^\d{1} \d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    function AlterarEstadoPix(e, props) {
        props.rowData.numeroPix = '';
        return props.onChange(e.value);
    }

    function AlterarEstadoPessoa(e, props) {
        props.rowData.numeroDocumento = '';
        return props.onChange(e.value);
    }

    return (
        <>
            <Form>
                <div>
                    <Col md={12}>
                        {
                            carregando ?
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={''}
                                    columns={[
                                        {
                                            title: 'Código', field: 'codigoInterno', editable: 'never', defaultSort: 'desc', cellStyle: {
                                                width: '5%',
                                            }
                                        },
                                        {
                                            title: 'Banco',
                                            field: 'banco',
                                            filtering: false,
                                            align: 'left',
                                            cellStyle: {
                                                width: '13%'
                                            },
                                            lookup:
                                                listaBanco != undefined && listaBanco.length > 0 ?
                                                    listaBanco.map(b => {
                                                        return {
                                                            id: b.id, descricao: b.descricao
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectBanco"
                                                    options={optionsBanco}
                                                    placeholder="Selecione..."
                                                    defaultValue={{
                                                        value: props.value, label: listaBanco.filter(p => p.id == props.value).length > 0
                                                            ? listaBanco.filter(p => p.id == props.value)[0].descricao
                                                            : 'Selecione...'
                                                    }}
                                                    onChange={(e) => { props.onChange(e.value) }}
                                                />
                                            ),

                                        },
                                        {
                                            title: 'Número Agência',
                                            type: 'numeric',
                                            align: 'left',
                                            field: 'numeroAgencia',
                                            cellStyle: {
                                                width: '13%'
                                            }, validate: rowData => {
                                                if (rowData.numeroAgencia === undefined || rowData.numeroAgencia === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.numeroAgencia.length > 50) {
                                                    return "O campo número Agência não pode conter mais que 50 caracteres"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Número Conta',
                                            type: 'numeric',
                                            align: 'left',
                                            field: 'numeroConta',
                                            align: 'left',
                                            cellStyle: {
                                                width: '13%'
                                            }, validate: rowData => {
                                                if (rowData.numeroConta === undefined || rowData.numeroConta === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.numeroConta.length > 50) {
                                                    return "O campo número Conta não pode conter mais que 50 caracteres"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Tipo Pix',
                                            field: 'tipoPix',
                                            filtering: false,
                                            align: 'left',
                                            cellStyle: {
                                                width: '13%'
                                            },
                                            //editable: 'onAdd',
                                            lookup:
                                                listaTipoPix != undefined && listaTipoPix.length > 0 ?
                                                    listaTipoPix.map(b => {
                                                        return {
                                                            id: b.id, descricao: b.descricao
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectTipoPix"
                                                    options={optionsTipoPix}
                                                    placeholder="Selecione..."
                                                    defaultValue={{
                                                        value: props.value, label: listaTipoPix.filter(p => p.id == props.value).length > 0
                                                            ? listaTipoPix.filter(p => p.id == props.value)[0].descricao
                                                            : 'Selecione...'
                                                    }}
                                                    onChange={(e) => { AlterarEstadoPix(e, props) }}
                                                //props.onChange(e.value)
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Número Pix', field: 'numeroPix', align: 'left', cellStyle: {
                                                width: '13%',
                                            },
                                            editComponent: (props) => (
                                                props.rowData.tipoPix == 2 ?
                                                    <InputMask value={props.value} type={"email"} onChange={(e) => props.onChange(e.target.value)}
                                                        id="idEmail" />
                                                    :
                                                    props.rowData.tipoPix == 1 ?
                                                        <InputMask defaultValue={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                            mask={"(99)999999999"} id="idTelefone" />
                                                        :
                                                        <InputMask disabled />
                                            ),
                                            validate: rowData => {
                                                if (rowData.numeroPix === undefined || rowData.numeroPix === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.numeroPix.length > 500) {
                                                    return "O campo não pode conter mais que 500 caracteres"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Tipo Pessoa',
                                            field: 'tipoPessoa',
                                            filtering: false,
                                            align: 'left',
                                            cellStyle: {
                                                width: '13%'
                                            },
                                            lookup:
                                                listaTipoPessoa != undefined && listaTipoPessoa.length > 0 ?
                                                    listaTipoPessoa.map(b => {
                                                        return {
                                                            id: b.id, descricao: b.descricao
                                                        }
                                                    }).reduce(function (acc, cur, i) {
                                                        acc[cur.id] = cur.descricao;
                                                        return acc;
                                                    }, {})
                                                    :
                                                    null,
                                            editComponent: (props) => (
                                                <Select id="idSelectTipoPessoa"
                                                    options={optionsTipoPessoa}
                                                    defaultValue={{
                                                        value: props.value, label: listaTipoPessoa.filter(p => p.id == props.value).length == 0
                                                            ? 'Selecione...'
                                                            : listaTipoPessoa.filter(p => p.id == props.value)[0].descricao

                                                    }}
                                                    onChange={(e) => { AlterarEstadoPessoa(e, props) }}
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Número Documento',
                                            field: 'numeroDocumento',
                                            cellStyle: {
                                                width: '10%',
                                            },
                                            editComponent: (props) => (
                                                props.rowData.tipoPessoa === 1 ?
                                                    <InputMask value={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                        mask={"999.999.999-99"} id="Fisica" />
                                                    :
                                                    props.rowData.tipoPessoa === 2 ?
                                                        <InputMask value={props.value} onChange={(e) => props.onChange(e.target.value)}
                                                            mask={"99.999.999/9999-99"} id="Juridica" />
                                                        :
                                                        <InputMask disabled />
                                            ),
                                            validate: rowData => {
                                                if (rowData.numeroDocumento === undefined || rowData.numeroDocumento === "") {
                                                    return "Campo Obrigatório"
                                                }
                                                else if (rowData.numeroDocumento.length > 50) {
                                                    return "O campo número Documento não pode conter mais que 50 caracteres"
                                                }
                                                return true
                                            }
                                        },
                                        {
                                            title: 'Ativa',
                                            field: 'ativa',
                                            cellStyle: {
                                                width: '4%'
                                            },
                                            editComponent: (props) => (
                                                <input
                                                    type="checkbox"
                                                    checked={props.value == false ? props.value : true}
                                                    onChange={(e) => props.onChange(e.target.checked)}
                                                />
                                            ),
                                            render: rowData => (<input type="checkbox" checked={rowData.ativa} onChange={() => { }} />)
                                        }
                                    ]}
                                    icons={{
                                        Add: () =>
                                            <Button variant="success">
                                                <span>
                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                </span>
                                            </Button>
                                    }}
                                    data={
                                        listaFornecedorConta != undefined && listaFornecedorConta.length > 0 ?
                                            listaFornecedorConta.map((lista) => {
                                                return {
                                                    codigoInterno: lista.id,
                                                    banco: lista.idBanco,
                                                    numeroAgencia: lista.numeroAgencia,
                                                    numeroConta: lista.numeroConta,
                                                    tipoPix: lista.idTipoPix,
                                                    numeroPix: lista.numeroPix,
                                                    tipoPessoa: lista.idTipoPessoa,
                                                    numeroDocumento: lista.numeroDocumento,
                                                    ativa: lista.ativa
                                                }
                                            })
                                            :
                                            []
                                    }
                                    editable={{
                                        onRowAdd:
                                            (newRow) => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    if (!ValidacoesInserir(newRow)) {
                                                        reject();
                                                    }
                                                    else {
                                                        InserirContas(newRow);
                                                        resolve()
                                                    }
                                                }, 1000)
                                            }),
                                        onRowUpdate:
                                            (newRow, oldRow) => new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    if (!ValidacoesInserir(newRow)) {
                                                        reject();
                                                    }
                                                    else {
                                                        AlterarConta(newRow);
                                                        resolve()
                                                    }
                                                }, 1000)
                                            })
                                    }}
                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        minBodyHeight: 500,
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        exportButton: false,
                                        addRowPosition: "first",
                                        exportAllData: true,
                                        filtering: true,
                                        //grouping: true,
                                        exportFileName: 'tabelaExportada',
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    actions={[
                                    ]}
                                    localization={{
                                        header: {
                                            actions: <span className="classHeaderTabela">Ações</span>
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                saveTooltip: '',
                                                cancelTooltip: '',
                                            },
                                            deleteTooltip: '',
                                            editTooltip: '',
                                            addTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                                :
                                <Loading />
                        }
                    </Col>
                </div>
            </Form >
        </>
    )
}

