import { Col, Card, Tabs, Tab } from "react-bootstrap";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
    ListarEventoAdicionalRequest, ExcluirEventoAdicionalRequest,
    InserirEventoAdicionalRequest, AlterarEventoAdicionalRequest
} from '../../../store/modules/eventoAdicional/actions';
import SolicitacaoFornecimento from "../SolicitacaoFornecimento/solicitacaoFornecimento";
import Mapa from "../Mapa/mapa";
import Subitem from "../Subitem/subitem";
import Documento from "../DocumentoArquivo/documentoArquivo";
import Status from "../Status/status";
import Header from "../../../components/Header/header";
import RegistroFotografico from "../RegistroFotografico/registroFotografico";
import HistoricoVersao from "../HistoricoVersao/historicoVersao";
import EventoAdicional from "../EventoAdicional/eventoAdicional";
import EventoResposta from "../EventoResposta/eventoResposta";

export default function Abas() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const listaEventoAdicional = useSelector(state => state.eventoAdicional.listaEventoAdicional);

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const history = useHistory();
    useEffect(() => {
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {
        if (id != undefined)
            dispatch(ListarEventoAdicionalRequest(parseInt(id)));
    }, [])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <IoChatboxEllipsesOutline size={25} color={"#000"} /> Solicitação Fornecimento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {
                                id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao != null && solicitacaoFornecimento.tipoSolicitacao.servico ?
                                    id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao != null && solicitacaoFornecimento.tipoSolicitacao.id == 147 ?
                                        <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                            <Tab eventKey="Solicitacao" title="Solicitação">
                                                <SolicitacaoFornecimento />
                                            </Tab>
                                            <Tab eventKey="Mapa" title="Mapa" disabled={id ? false : true}>
                                                <Mapa />
                                            </Tab>
                                            <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                <RegistroFotografico />
                                            </Tab>
                                            <Tab eventKey="Documentos" title="Documentos" disabled={id ? false : true}>
                                                <Documento />
                                            </Tab>
                                            <Tab eventKey="Status" title="Status" disabled={id ? false : true}>
                                                <Status />
                                            </Tab>
                                            <Tab eventKey="HistoricoVersao" title="Histórico Versão" disabled={id ? false : true}>
                                                <HistoricoVersao />
                                            </Tab>
                                        </Tabs>
                                        :
                                        id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao != null && solicitacaoFornecimento.tipoSolicitacao.id == 130 ?
                                            <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                <Tab eventKey="Solicitacao" title="Solicitação">
                                                    <SolicitacaoFornecimento />
                                                </Tab>
                                                <Tab eventKey="Mapa" title="Mapa" disabled={id ? false : true}>
                                                    <Mapa />
                                                </Tab>
                                                <Tab eventKey="Subitem Custo" title="Item Custo" disabled={id ? false : true}>
                                                    <Subitem />
                                                </Tab>
                                                <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                    <RegistroFotografico />
                                                </Tab>
                                                <Tab eventKey="Documentos" title="Documentos" disabled={id ? false : true}>
                                                    <Documento />
                                                </Tab>
                                                <Tab eventKey="Status" title="Status" disabled={id ? false : true}>
                                                    <Status />
                                                </Tab>
                                                <Tab eventKey="HistoricoVersao" title="Histórico Versão" disabled={id ? false : true}>
                                                    <HistoricoVersao />
                                                </Tab>
                                                <Tab eventKey="Evento Resposta" title="Evento Resposta">
                                                    <EventoResposta />
                                                </Tab>
                                            </Tabs>
                                            :
                                            <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                <Tab eventKey="Solicitacao" title="Solicitação">
                                                    <SolicitacaoFornecimento />
                                                </Tab>
                                                <Tab eventKey="Mapa" title="Mapa" disabled={id ? false : true}>
                                                    <Mapa />
                                                </Tab>
                                                <Tab eventKey="Subitem Custo" title="Item Custo" disabled={id ? false : true}>
                                                    <Subitem />
                                                </Tab>
                                                <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                    <RegistroFotografico />
                                                </Tab>
                                                <Tab eventKey="Documentos" title="Documentos" disabled={id ? false : true}>
                                                    <Documento />
                                                </Tab>
                                                <Tab eventKey="Status" title="Status" disabled={id ? false : true}>
                                                    <Status />
                                                </Tab>
                                                <Tab eventKey="HistoricoVersao" title="Histórico Versão" disabled={id ? false : true}>
                                                    <HistoricoVersao />
                                                </Tab>
                                            </Tabs>
                                    :
                                    id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao != null && solicitacaoFornecimento.tipoSolicitacao.semOficio &&
                                        listaEventoAdicional != undefined && listaEventoAdicional.filter(x => x.tipoSolicitacao.servico == true).length > 0 ?
                                        <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                            <Tab eventKey="Solicitacao" title="Solicitação">
                                                <SolicitacaoFornecimento />
                                            </Tab>
                                            <Tab eventKey="Mapa" title="Mapa" disabled={id ? false : true}>
                                                <Mapa />
                                            </Tab>
                                            <Tab eventKey="Subitem Custo" title="Item Custo" disabled={id ? false : true}>
                                                <Subitem />
                                            </Tab>
                                            <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                <RegistroFotografico />
                                            </Tab>
                                            <Tab eventKey="Documentos" title="Documentos" disabled={id ? false : true}>
                                                <Documento />
                                            </Tab>
                                            <Tab eventKey="Status" title="Status" disabled={id ? false : true}>
                                                <Status />
                                            </Tab>
                                            <Tab eventKey="HistoricoVersao" title="Histórico Versão" disabled={id ? false : true}>
                                                <HistoricoVersao />
                                            </Tab>
                                            <Tab eventKey="Evento Adicional" title="Evento Adicional"
                                                disabled={id && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.semOficio ? false : true}>
                                                <EventoAdicional />
                                            </Tab>
                                            <Tab eventKey="Evento Resposta" title="Evento Resposta">
                                                <EventoResposta />
                                            </Tab>
                                        </Tabs>
                                        :
                                        id == undefined || (solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.semOficio == false) ?
                                            id != undefined && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.eventoResposta == true ?
                                                <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                    <Tab eventKey="Solicitacao" title="Solicitação">
                                                        <SolicitacaoFornecimento />
                                                    </Tab>
                                                    <Tab eventKey="Evento Resposta" title="Evento Resposta">
                                                        <EventoResposta />
                                                    </Tab>
                                                    <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                        <RegistroFotografico />
                                                    </Tab>
                                                </Tabs>
                                                :
                                                id != undefined ?
                                                    (solicitacaoFornecimento.tipoSolicitacao.id == 148 || solicitacaoFornecimento.tipoSolicitacao.id == 149 || solicitacaoFornecimento.tipoSolicitacao.id == 150) ?
                                                        <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                            <Tab eventKey="Solicitacao" title="Solicitação">
                                                                <SolicitacaoFornecimento />
                                                            </Tab>
                                                            <Tab eventKey="Documentos" title="Documentos" disabled={id ? false : true}>
                                                                <Documento />
                                                            </Tab>
                                                        </Tabs>
                                                        :
                                                        <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                            <Tab eventKey="Solicitacao" title="Solicitação">
                                                                <SolicitacaoFornecimento />
                                                            </Tab>
                                                            <Tab eventKey="Evento Resposta" title="Evento Resposta">
                                                                <EventoResposta />
                                                            </Tab>
                                                            <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                                <RegistroFotografico />
                                                            </Tab>
                                                        </Tabs>
                                                    :
                                                    <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                        <Tab eventKey="Solicitacao" title="Solicitação">
                                                            <SolicitacaoFornecimento />
                                                        </Tab>
                                                    </Tabs>
                                            :
                                            <Tabs defaultActiveKey="Solicitacao" id="tab-fonte" className="mb-3">
                                                <Tab eventKey="Solicitacao" title="Solicitação">
                                                    <SolicitacaoFornecimento />
                                                </Tab>
                                                <Tab eventKey="Evento Adicional" title="Evento Adicional"
                                                    disabled={id && solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.semOficio ? false : true}>
                                                    <EventoAdicional />
                                                </Tab>
                                                <Tab eventKey="Evento Resposta" title="Evento Resposta">
                                                    <EventoResposta />
                                                </Tab>
                                                <Tab eventKey="RegistroFotografico" title="Registro Fotográfico" disabled={id ? false : true}>
                                                    <RegistroFotografico />
                                                </Tab>
                                            </Tabs>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}