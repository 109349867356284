import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarContratoVersaoRequest } from "../../../store/modules/contratoVersao/actions";
import { ListarTipoContratoVersaoRequest } from '../../../store/modules/tipoContratoVersao/actions';
import MaterialTable from 'material-table';
import Loading from "../../../components/Loading/loading";
import moment from 'moment';
import 'moment/locale/pt-br';

export default function HistoricoVersao() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const listaContratoVersao = useSelector(state => state.contratoVersao.listaContratoVersao);
    const listaTipoContratoVersao = useSelector(state => state.tipoContratoVersao.listaTipoContratoVersao);
    const carregando = useSelector(state => state.contratoDocumentoArquivo.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {

        moment.locale('pt-br');

    }, [])


    useEffect(() => {

        dispatch(ListarTipoContratoVersaoRequest());
        if (id != undefined) {
            dispatch(ListarContratoVersaoRequest(id));
        }

    }, [id])

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        carregando ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: "Número da Versão", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                            width: '10%',
                                        }
                                    },
                                    {
                                        title: "Data Início Versão", field: 'dataVersao', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Tipo Contrato Versão", field: 'tipoContratoVersao', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Valor Índice Reajuste", field: 'valorIndiceReajuste', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Valor Índice Multa", field: 'valorIndiceMulta', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    },
                                    {
                                        title: "Valor Índice Item Excedente", field: 'valorIndiceQuantidadeItemExcedente', editable: 'never', cellStyle: {
                                            width: '18%'
                                        }
                                    }
                                ]}
                                data={
                                    listaContratoVersao != undefined && listaTipoContratoVersao != undefined ?
                                        listaContratoVersao.map(contratoVersao => {
                                            return {
                                                id: contratoVersao.id,
                                                dataVersao: moment(contratoVersao.dataVersao).format('L LT'),
                                                tipoContratoVersao:
                                                    contratoVersao.tipoContratoVersao != null ?
                                                        contratoVersao.tipoContratoVersao.descricao
                                                        :
                                                        '',
                                                valorIndiceReajuste: contratoVersao.valorIndiceReajuste,
                                                valorIndiceMulta: contratoVersao.valorIndiceMulta,
                                                valorIndiceQuantidadeItemExcedente: contratoVersao.valorIndiceQuantidadeItemExcedente
                                            }
                                        })
                                        :
                                        []
                                }
                                options={{
                                    minBodyHeight: 500,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    })
                                }}
                                localization={{
                                    header: {
                                        actions: 'Ações'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                        },
                                        deleteTooltip: ''
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />
                            :
                            <Loading />
                    }
                </Col>
            </div>
        </>
    )
}