import { Row, Button, Modal, ModalFooter, Col, Container, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    ListarAutorizacaoFornecimentoSubitemCustoRequest
} from "../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import { InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest } from "../../store/modules/autorizacaoFornecimentoSubitemCustoMedicao/actions";
import { BuscarCompletoAutorizacaoFornecimentoRequest } from "../../store/modules/autorizacaoFornecimento/actions";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import { BuscarContratoVersaoPorIdRequest } from '../../store/modules/contratoVersao/actions';
import {
    ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest,
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest,
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest
} from "../../store/modules/autorizacaoFornecimentoSubitemCustoMedicao/actions";
import { ListarContratoUsuarioRequest } from '../../store/modules/contratoUsuario/actions';
import { BuscarUsuarioPorIdRequest } from "../../store/modules/usuario/actions";
import { toast } from "react-toastify";
import { DropzoneArea } from 'material-ui-dropzone';
import { ListarMedicaoAutorizacaoFornecimentoRequest } from "../../store/modules/medicaoAutorizacaoFornecimento/actions";
import Select from 'react-select';
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalSubitemMedicao({ idUsuario, show, onHide, valorObraReais, valorTotalMedicaoReais, valorObraSemItensAdd }) {

    const { idMedicao, idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const contratoVersao = useSelector(state => state.contratoVersao.contratoVersaoCompleto);
    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const listaAutorizacaoFornecimentoSubitemCustoMedicao =
        useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicao.listaAutorizacaoFornecimentoSubitemCustoMedicao);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const medicaoAutorizacaoFornecimentoCompleto = useSelector(state => state.medicaoAutorizacaoFornecimento.medicaoAutorizacaoFornecimentoCompleto);
    const listaMedicaoAutorizacaoFornecimento = useSelector(state => state.medicaoAutorizacaoFornecimento.listaMedicaoAutorizacaoFornecimento);

    const options = contratoVersao ?
        contratoVersao.subitemCusto.map(subitemUnidade => ({
            value: subitemUnidade.id, label: subitemUnidade.descricao
        }))
        :
        [];

    const [showConfirmacao, setShowConfirmacao] = useState(false);
    const [listaDataCompleta, setListaDataCompleta] = useState('');
    const [listaSubitem, setListaSubitem] = useState([]);
    const [observacaoSubitem, setObservacaoSubitem] = useState('');
    const [validated, setValidated] = useState(false);
    const [porcentagem, setPorcentagem] = useState(0);
    const [totalSelecionado, setTotalSelecionado] = useState(0);
    const [showFotoMedicao, setShowFotoMedicao] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [listaFile, setListaFile] = useState([]);
    const [dataMedicao, setDataMedicao] = useState('');

    const handleCloseConfirmacao = () => setShowConfirmacao(false);
    const handleShowConfirmacao = () => setShowConfirmacao(true);

    const handleCloseFotoMedicao = () => setShowFotoMedicao(false);
    const handleShowFotoMedicao = () => setShowFotoMedicao(true);

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarContratoUsuarioRequest());

        if (idAutorizacaoFornecimento !== undefined) {

            dispatch(BuscarCompletoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));

            var data = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento)
            }

            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));
        }

        if (usuario != null)
            dispatch(BuscarUsuarioPorIdRequest(usuario.id));

    }, [])

    useEffect(() => {

        if (autorizacaoFornecimentoCompleto != null) {
            dispatch(BuscarContratoVersaoPorIdRequest(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.id));
            dispatch(ListarMedicaoAutorizacaoFornecimentoRequest(autorizacaoFornecimentoCompleto.id));
        }

        if (idMedicao != undefined && idMedicao != null)
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(idMedicao));

    }, [autorizacaoFornecimentoCompleto])

    useEffect(() => {
        constroiData();
    }, [listaAutorizacaoFornecimentoSubitemCusto, listaAutorizacaoFornecimentoSubitemCustoMedicao])

    const handleSubmit = (event) => {

        if (observacaoSubitem.trim() == '') {
            event.preventDefault();
        } else {

            let valorOrcamento = parseFloat(valorObraSemItensAdd);
            var valorComItensAdicionados = 0.0;
            var validacao = 0;
            var validacaoExcedendoValor = 0;
            var totalItensSelecionados = 0.0;
            let valorTotalExcedente = 0.0;

            if (listaAutorizacaoFornecimentoSubitemCusto) {
                listaAutorizacaoFornecimentoSubitemCusto.forEach(subitem => {
                    valorComItensAdicionados += subitem.quantidade * subitem.subitemCusto.valorUnitario;
                })
            }

            listaSubitem.forEach(subitem => {
                var quantidadeExcedida = subitem.quantidadeMedicao;
                var valor = quantidadeExcedida * subitem.valorUnitario;
                totalItensSelecionados += valor
            });

            if (contratoVersao) {
                valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
            }

            var valorTodasMedicoes = 0.0;
            if (listaMedicaoAutorizacaoFornecimento) {
                listaMedicaoAutorizacaoFornecimento.forEach(medicao => {
                    valorTodasMedicoes += medicao.valorTotalMedicao;
                });
            }

            var teste = totalItensSelecionados + valorTodasMedicoes;
            if (teste > valorTotalExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
                validacaoExcedendoValor = validacaoExcedendoValor + 1;
                /*toast.error('O valor total dos itens selecionados esta ultrapassando o valor máximo da autorização');
                return;*/
            }

            /*
            listaSubitem.forEach(subitem => {
                if(subitem.quantidadeMedicao > subitem.saldoContratoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao &&
                    subitem.quantidadeMedicao > subitem.saldoAutorizacaoTotal ) {
                    validacao = validacao + 1;
                    if(contratoVersao && valorOrcamento){
                        valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
                        let valorTotalMed = ((subitem.quantidadeMedicao - subitem.quantidade) * subitem.valorUnitario) + valorComItensAdicionados;
                        if(valorTotalMed > valorTotalExcedente){
                            validacaoExcedendoValor = validacaoExcedendoValor + 1;
                            toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                            return;
                        }
                    }
                }
            })*/

            if (/*validacao > 0 && */validacaoExcedendoValor === 1) {
                setDataMedicao(event)
                setObservacao(observacaoSubitem);
                handleShowFotoMedicao();
                return;
            } else {
                InserirSubitemConfirmado(event);
            }

            /*
            if(validacao === 0){
                
            }
            */
        }

        setValidated(true);
    };

    function constroiData() {

        let dataCompleta = [];

        if (listaAutorizacaoFornecimentoSubitemCusto != undefined && listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined) {
            listaAutorizacaoFornecimentoSubitemCusto.map((lista) => {

                if (listaAutorizacaoFornecimentoSubitemCustoMedicao.find(x => x.idAutorizacaoFornecimentoSubitemCusto == lista.id) == undefined &&
                    listaAutorizacaoFornecimentoSubitemCustoMedicao.find(x => x.autorizacaoFornecimentoSubitemCusto.subitemCusto.id == lista.subitemCusto.id) == undefined &&
                    dataCompleta.find(x => x.subitem == lista.subitemCusto.id) == undefined) {
                    dataCompleta.push({
                        id: lista.id,
                        codigoInterno: lista.subitemCusto.descricaoCodigoInterno,
                        saldoContrato: lista.saldo,
                        descricao: lista.subitemCusto.descricao,
                        subitem: lista.subitemCusto.id,
                        quantidade: lista.quantidade,
                        unidadeMedida: lista.subitemCusto.unidadeMedida != undefined ? lista.subitemCusto.unidadeMedida.descricao : '',
                        saldoContratoExcedente: lista.saldoContratoExcedente,
                        quantidadeConsumidaAutorizacao: lista.quantidadeConsumidaAutorizacao,
                        valorUnitario: lista.subitemCusto.valorUnitario,
                        valorTotalEmpenhado: lista.subitemCusto.valorUnitario * lista.quantidadeConsumidaAutorizacao,
                        valorTotalSubitem: lista.subitemCusto.valorUnitario * lista.quantidade,
                        saldoAutorizacao: lista.saldoAutorizacao,
                        saldoContrato: lista.saldo,
                        quantidadeMedicao: 0,
                        saldoAutorizacaoTotal: lista.saldoAutorizacaoTotal,
                        total: 0,
                        quantidadeSubitemAutorizacaoTotal: lista.quantidadeSubitemAutorizacaoTotal,
                        quantidadeSubitemMedicaoTotal: lista.quantidadeSubitemMedicaoTotal,
                        quantidadeOutrasMedicoes: lista.quantidadeOutrasMedicoes
                    });
                }
            })
        }

        setListaDataCompleta(dataCompleta);
    }

    function Selecionar(data) {
        setListaSubitem(data);
    }

    function InserirMedicaoSubitem() {

        var verificacao = true;

        var validacaoExcedendoValor = 0;
        var totalItensSelecionados = 0.0;
        let valorTotalExcedente = 0.0;
        let valorOrcamento = parseFloat(valorObraSemItensAdd);
        listaSubitem.forEach(subitem => {
            var quantidadeExcedida = subitem.quantidadeMedicao;
            var valor = quantidadeExcedida * subitem.valorUnitario;
            totalItensSelecionados += valor
        });

        if (contratoVersao) {
            valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
        }

        var valorTodasMedicoes = 0.0;
        if (listaMedicaoAutorizacaoFornecimento) {
            listaMedicaoAutorizacaoFornecimento.forEach(medicao => {
                valorTodasMedicoes += medicao.valorTotalMedicao;
            });
        }

        var teste = totalItensSelecionados + valorTodasMedicoes;
        if (teste > valorTotalExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
            validacaoExcedendoValor = validacaoExcedendoValor + 1;
        }

        if (validacaoExcedendoValor === 1) {
            verificacao = false;
            handleShowConfirmacao();
            return;
        }
        

        listaSubitem.forEach(subitem => {

            if (subitem.quantidadeMedicao + subitem.quantidadeSubitemMedicaoTotal > subitem.quantidadeSubitemAutorizacaoTotal) {
                //toast.error('Subitem: ' + subitem.descricao + ') esta ultrapassando o saldo da autorização');
                verificacao = false;
                handleShowConfirmacao();
                return;
            }

            if (subitem.quantidadeMedicao > subitem.saldoContratoExcedente && contratoVersao != null && contratoVersao.liberaExcedenteGlobal == false &&
                contratoVersao.liberaExcedenteSubitem == false && listaContratoUsuario && listaContratoUsuario.filter(a => a.id == contratoVersao.idContrato && a.idUsuario == usuario.id)[0] &&
                listaContratoUsuario.filter(a => a.idContrato == contratoVersao.idContrato && a.idUsuario == usuario.id)[0].editaExcedenteSubitemAutorizacao === false) {
                toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                verificacao = false;
                return;
            }

            /*
            if (subitem.quantidadeMedicao == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }*/

        });

        if (verificacao && listaSubitem.length > 0 && usuarioCompleto != null) {
            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    Id: parseInt(0),
                    IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                    IdAutorizacaoFornecimentoSubitemCusto: subitem.id,
                    IdUsuarioUltimaAlteracao: usuarioCompleto.id,
                    DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                    QuantidadeCliente: parseFloat(usuarioCompleto.usuarioFornecedor ? 0 : subitem.quantidadeMedicao),
                    QuantidadeFornecedor: parseFloat(usuarioCompleto.usuarioFornecedor ? subitem.quantidadeMedicao : 0),
                    AlteracaoFornecedor: usuarioCompleto.usuarioFornecedor,
                    Ativo: true,
                    ListaAutorizacaoFornecimentoSubitemCustoMedicaoApiModel: { listaSubitemInsercao }
                }
            })

            let data = {
                Id: listaSubitemInsercao[0].Id,
                IdMedicaoAutorizacaoFornecimento: listaSubitemInsercao[0].IdMedicaoAutorizacaoFornecimento,
                IdAutorizacaoFornecimentoSubitemCusto: listaSubitemInsercao[0].IdAutorizacaoFornecimentoSubitemCusto,
                IdUsuarioUltimaAlteracao: listaSubitemInsercao[0].IdUsuarioUltimaAlteracao,
                DataUltimaAlteracao: listaSubitemInsercao[0].DataUltimaAlteracao,
                QuantidadeCliente: listaSubitemInsercao[0].QuantidadeCliente,
                QuantidadeFornecedor: listaSubitemInsercao[0].QuantidadeFornecedor,
                AlteracaoFornecedor: listaSubitemInsercao[0].AlteracaoFornecedor,
                Ativo: listaSubitemInsercao[0].Ativo,
                ListaAutorizacaoFornecimentoSubitemCustoMedicaoApiModel: listaSubitemInsercao
            }

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest(data, idAutorizacaoFornecimento));
            setListaSubitem([]);
            onHide(true);

        } else {
            //toast.error('Selecione pelo menos um subitem');
        }
        setPorcentagem(0);
    }

    function InserirSubitemConfirmado(event) {

        event.preventDefault();

        var verificacao = true;

        listaSubitem.forEach(subitem => {

            if (subitem.quantidade > subitem.saldoContratoExcedente && contratoVersao != null && contratoVersao.liberaExcedenteGlobal == false &&
                contratoVersao.liberaExcedenteSubitem == false && listaContratoUsuario && listaContratoUsuario.filter(a => a.id == contratoVersao.idContrato && a.idUsuario == usuario.id)[0] &&
                listaContratoUsuario.filter(a => a.idContrato == contratoVersao.idContrato && a.idUsuario == usuario.id)[0].editaExcedenteSubitemAutorizacao === false) {
                toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                verificacao = false;
                return;
            }

            /*
            if (subitem.quantidade == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }*/

        });

        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                    DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                    Quantidade: parseFloat(subitem.quantidadeMedicao),
                    IdSubItemCusto: parseInt(subitem.subitem),
                    Observacao: observacaoSubitem
                }
            })

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest(listaSubitemInsercao, idAutorizacaoFornecimento, contratoVersao != null ? contratoVersao.id : 0));
            setListaSubitem([]);
            handleCloseConfirmacao();
            onHide(true);
        }

        setPorcentagem(0);
    }

    function InserirSubitemConfirmadoComFoto(event) {

        event.preventDefault();

        var verificacao = true;
        var valorSubitemAdicionado = 0.0;
        var valorEcedente = 0.0;

        if (listaSubitem.length > 0) {

            var dados = listaSubitem.map((subitem) => {
                valorSubitemAdicionado += subitem.quantidadeMedicao * subitem.valorUnitario;
                return parseInt(subitem.id)
            })
        }

        if(valorTotalMedicaoReais && valorObraSemItensAdd && contratoVersao && contratoVersao.valorIndiceQuantidadeItemExcedente){
            valorSubitemAdicionado = valorTotalMedicaoReais + valorSubitemAdicionado + parseFloat(valorObraSemItensAdd);
            var valorEcedente = parseFloat(valorObraSemItensAdd) + parseFloat(valorObraSemItensAdd) * contratoVersao.valorIndiceQuantidadeItemExcedente;
        }

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdAutorizacaoFornecimentoSubitemCustoMedicao', null);
                formData.append('IdMedicao', parseInt(idMedicao));
                formData.append('IdUsuario', usuario.id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setListaFile([]);
            handleCloseFotoMedicao();
        } else {
            toast.error('Para continuar insira um documento');
        }

        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                    DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                    Quantidade: parseFloat(subitem.quantidadeMedicao),
                    IdSubItemCusto: parseInt(subitem.subitem),
                    Observacao: observacaoSubitem
                }
            })

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest(listaSubitemInsercao, idAutorizacaoFornecimento, contratoVersao != null ? contratoVersao.id : 0, formData, dados,
                valorSubitemAdicionado, parseFloat(valorEcedente)));
            setListaSubitem([]);
            handleCloseConfirmacao();
            onHide(true);
        }

        setPorcentagem(0);
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable='true'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        listaAutorizacaoFornecimentoSubitemCusto != undefined && listaAutorizacaoFornecimentoSubitemCusto.length > 0 ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: "Código Interno", field: 'codigoInterno', cellStyle: {
                                            width: '8%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Descrição Item', field: 'subitem', cellStyle: {
                                            width: '22%',
                                        },
                                        editable: 'never',
                                        filtering: false,
                                        lookup:
                                            contratoVersao ?
                                                contratoVersao.subitemCusto.map(subitemUnidade => {
                                                    return {
                                                        id: subitemUnidade.id, descricao: subitemUnidade.descricao
                                                    }
                                                }).reduce(function (acc, cur, i) {
                                                    acc[cur.id] = cur.descricao;
                                                    return acc;
                                                }, {})
                                                :
                                                null,
                                        editComponent: (props) => (
                                            <Select id="idSelectSubItem"
                                                options={options}
                                                placeholder="Selecione..."
                                                defaultValue={{
                                                    value: props.value, label: contratoVersao.subitemCusto.filter(soli => soli.id == props.value).length > 0
                                                        ? contratoVersao.subitemCusto.filter(soli => soli.id == props.value)[0].descricao
                                                        : 'Selecione...'
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: 'Quantidade Outras Medições', field: 'quantidadeOutrasMedicoes', editable: 'never',
                                        //hidden: saldoQuantidadeContrato ? false : true
                                    },
                                    {
                                        title: 'Quantidade Medição', align: "left", type: "numeric",
                                        field: 'quantidadeMedicao', cellStyle: {
                                            width: '20%',
                                        }, validate: rowData => {
                                            if (rowData.quantidadeMedicao === undefined || rowData.quantidadeMedicao === "" || isNaN(rowData.quantidadeMedicao)) {
                                                return "Campo Obrigatório"
                                            } else if (rowData.quantidadeMedicao.toString().length > 10) {
                                                return "A quantidade não pode conter mais que 10 dígitos"
                                            } else if (rowData.quantidadeMedicao > rowData.saldoAutorizacao) {
                                                return "Quantidade ultrapassou o saldo da autorização"
                                            }
                                            return true
                                        },
                                    },
                                    {
                                        title: "Valor Total", field: 'total', cellStyle: {
                                            width: '7%'
                                        }, editable: 'never',
                                        render: rowData =>
                                            rowData.total === null ?
                                                null
                                                :
                                                rowData.total.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },

                                    {
                                        title: 'Saldo Total Quantidade Autorização', field: 'saldoAutorizacaoTotal', editable: 'never', cellStyle: {
                                            width: '7%',
                                        }
                                    },
                                    {
                                        title: 'Saldo Quantidade Contrato (excedente)', field: 'saldoContrato', editable: 'never', cellStyle: {
                                            width: '7%',
                                        },
                                        render: rowData => rowData.saldoContrato + ' (' + rowData.saldoContratoExcedente + ')'
                                    },
                                    {
                                        title: "Valor Unitário", field: 'valorUnitario', cellStyle: {
                                            width: '7%'
                                        }, editable: 'never',
                                        render: rowData =>
                                            rowData.valorUnitario === null ?
                                                null
                                                :
                                                rowData.valorUnitario.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: 'Unidade de Medida',
                                        field: 'unidadeMedida',
                                        cellStyle: {
                                            width: '7%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: '#',
                                        field: 'idAutorizacaoFornecimentoSubitemCusto',
                                        editable: 'never', hidden: true
                                    }
                                ]}
                                data={listaDataCompleta}
                                options={{
                                    minBodyHeight: 500,
                                    showTextRowsSelected: false,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    selection: true,
                                    selectionProps: rowData => ({
                                        color: 'primary'
                                    }),
                                    showSelectAllCheckbox: true
                                }}
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                rowData.quantidadeMedicao = newValue
                                                rowData.total = newValue * rowData.valorUnitario
                                                rowData.tableData.checked = true;
                                                var novaLista = listaSubitem;
                                                var tem = novaLista.filter(a => a == rowData);
                                                if (tem.length == 0) {
                                                    novaLista.push(rowData)
                                                }
                                                Selecionar(novaLista);

                                                var soma = 0;

                                                novaLista.map(s => {
                                                    soma += s.quantidadeMedicao * s.valorUnitario;
                                                });

                                                setTotalSelecionado(soma);

                                                setPorcentagem(((100 * (soma + valorTotalMedicaoReais)) / (valorObraReais)).toFixed(2) + '%');

                                                resolve()
                                            }, 1000)
                                        });
                                    },
                                    cellStyle: {
                                        minWidth: 250
                                    }
                                }}
                                onSelectionChange={
                                    rowData =>
                                        Selecionar(rowData)
                                }
                                localization={{
                                    header: {
                                        actions: <span className="classHeaderTabela">Ações</span>
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                            saveTooltip: '',
                                            cancelTooltip: '',
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: '',
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                        nRowsSelected: '{0} linha(s) selecionada'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />

                            :
                            <div className="divBody">
                                No momento não possui Itens para serem inseridos.
                            </div>
                    }
                </Modal.Body>
                <ModalFooter>
                    <Container>
                        <Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md={4} controlId="formDescricao">
                                    <Form.Label>% Valor em relação a Autorização de Fornecimento: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={porcentagem}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={6} controlId="formDescricao"></Form.Group>
                                <Form.Group as={Col} md={2} controlId="formDescricao">
                                    <Button
                                        style={{ width: '100%', marginTop: '25px' }}
                                        variant="success"
                                        disabled={
                                            listaAutorizacaoFornecimentoSubitemCusto != undefined && listaAutorizacaoFornecimentoSubitemCusto.length > 0 ? false : true
                                        }
                                        type="submit"
                                        onClick={() => InserirMedicaoSubitem()}>
                                        Adicionar Itens
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>

            <Modal show={showConfirmacao} onHide={handleCloseConfirmacao} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Quantidade passando a quantidade do item da autorização, ao prosseguir, a quantidade será alterada na autorização. Deseja continuar?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={observacaoSubitem}
                                        onChange={e => setObservacaoSubitem(e.target.value)}
                                        required
                                        maxLength="500"
                                        isInvalid={validated && observacaoSubitem.trim() === "" ? true : false}
                                        isValid={validated && observacaoSubitem.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmit}>
                        Sim
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirmacao}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFotoMedicao} onHide={handleCloseFotoMedicao}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Fotográfico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <span>O valor da obra foi excedido. Inclua uma foto e uma justificativa para esta ação</span><br />
                            <Form.Group as={Col} md={12} controlId="formRegistroFotografico">
                                <div className="divModal"><br />
                                    <DropzoneArea
                                        //initialFiles={[listaRegistroFotografico]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="success" disabled={listaFile.length > 0 ? false : true}
                            onClick={() => InserirSubitemConfirmadoComFoto(dataMedicao)}>Salvar Registro Fotográfico</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}