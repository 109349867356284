import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ListarContratoRequest } from "../../store/modules/contrato/actions";
import { ListarPrioridadeRequest } from "../../store/modules/prioridade/actions";
import { ListarStatusSolicitacaoFornecimentoCompletoRequest } from "../../store/modules/statusSolicitacaoFornecimento/actions";
import { ListarStatusAutorizacaoFornecimentoRequest } from "../../store/modules/statusAutorizacaoFornecimento/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";
import { ListarAutorizacaoFornecimentoRequest } from "../../store/modules/autorizacaoFornecimento/actions";
import { ListarEmpenhoRequest } from '../../store/modules/empenho/actions';
import { RiCheckboxCircleLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Loading from '../Loading/loading'
import Select from 'react-select';

export default function ModalFiltroRelatorio({ show, onHide, BaixarRelatorioTodasSolicitacoes,
    BaixarRelatorioTodasAutorizacoes, BaixarRelatorioTodasMedicoes, BaixarRelatorioTodosSubitens,
    BaixarRelatorioStatusSolicitacao, RelatorioSelecionado, BaixarRelatorioTodosSubitensTro, BaixarRelatorioGlobal }) {

    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [idContrato, setIdContrato] = useState('');    
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);    
    const [data, setData] = useState(null);
    const [idPrioridade, setIdPrioridade] = useState(null);
    const [idStatusSolicitacao, setIdStatusSolicitacao] = useState(null);
    const [idStatusAF, setIdStatusAF] = useState(null);
    const [idStatusMedicao, setIdStatusMedicao] = useState(null);
    const [idAF, setIdAF] = useState(''); 
    const [idEmpenho, setIdEmpenho] = useState(''); 
    const [solicitacaoStatus, setSolicitacaoStatus] = useState(null); 
    const [autorizacaoStatus, setAutorizacaoStatus] = useState(null); 
    const [medicaoStatus, setMedicaoStatus] = useState(null); 
    const [empenhoStatus, setEmpenhoStatus] = useState(null); 
    const [showGerar, setShowGerar] = useState(false); 
    const [validaCheck, setValidaCheck] = useState(false); 

    const onHideGerar = () => setShowGerar(false);
    
    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const listaPrioridade = useSelector(state => state.prioridade.listaPrioridade);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const listaStatusMedicao = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaAutorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.listaAutorizacaoFornecimento);
    const listaEmpenho = useSelector(state => state.empenho.listaEmpenho);
    const carregandoGlobal = useSelector(state => state.relatorio.carregandoGlobal);

    const options = listaAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimento ?
        listaAutorizacaoFornecimento.filter(x => 
        x.solicitacaoFornecimentoVersao.solicitacaoFornecimento != undefined &&
        x.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != undefined &&
        x.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato !== undefined && 
        x.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato == idContrato)
        .map(af => (
        af.solicitacaoFornecimentoVersao.solicitacaoFornecimento != undefined &&
        af.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != undefined &&
        af.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato !== undefined &&
        af.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato == idContrato ?
        { value: af.id, label: af.numeroAf } 
        :
        ''
    ))
    :
    [];

    const optionsEmpenho = listaEmpenho != undefined && listaEmpenho ?
        listaEmpenho.filter(x => 
        x.idContrato !== undefined && x.idContrato == idContrato).map(e => (
            e.idContrato !== undefined && e.idContrato == idContrato ?
                { value: e.id, label: e.descricao } 
            :
            ''
    ))
    :
    [];

    useEffect(() => {
        dispatch(ListarEmpenhoRequest());
        dispatch(ListarContratoRequest());
        dispatch(ListarPrioridadeRequest());
        dispatch(ListarStatusSolicitacaoFornecimentoCompletoRequest());
        dispatch(ListarStatusAutorizacaoFornecimentoRequest());
        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());
        dispatch(ListarAutorizacaoFornecimentoRequest());
    }, [])

    const handleSubmit = (event) => {

        event.preventDefault();

        if (idContrato == undefined || idContrato == '') {
            setValidated(true);
        } else {

     

            switch (RelatorioSelecionado) {
                case 'BaixarRelatorioTodasSolicitacoes':

                    let dataSolicitacao = {
                        'IdContrato': idContrato != null ? parseInt(idContrato) : null,
                        'IdStatus': idStatusSolicitacao != null ? parseInt(idStatusSolicitacao): null,
                        'DataInicio':  dataInicio != null? dataInicio : null,
                        'DataFim': dataFim != null? dataFim : null,
                        'IdPrioridade': idPrioridade != null ? parseInt(idPrioridade) : null
                    }                           
                    BaixarRelatorioTodasSolicitacoes(dataSolicitacao);
                    onHide();
                    break;
                
                case 'BaixarRelatorioTodasAutorizacoes':
               
                    let dataAutorizacao = {
                        'IdContrato': idContrato != null ? parseInt(idContrato) : null,
                        'IdStatus': idStatusAF != null ? parseInt(idStatusAF): null,
                        'DataInicio': dataInicio != null? dataInicio : null,
                        'DataFim': dataFim != null? dataFim : null,
                        'IdPrioridade': idPrioridade != null ? parseInt(idPrioridade) : null
                    }                           
                    BaixarRelatorioTodasAutorizacoes(dataAutorizacao);
                    onHide();
                    break;
                
                case 'BaixarRelatorioTodasMedicoes':
            
                    let dataMedicao = {
                        'IdContrato': idContrato != null ? parseInt(idContrato) : null,
                        'IdStatus': idStatusMedicao != null ? parseInt(idStatusMedicao): null,
                        'DataInicio': dataInicio != null? dataInicio : null,
                        'DataFim': dataFim != null? dataFim : null,
                        'IdAutorizacao': idAF != null ? parseInt(idAF) : null
                    }                           
                    BaixarRelatorioTodasMedicoes(dataMedicao);
                    onHide();
                    break;
                
                case 'BaixarRelatorioTodosSubitens':
                    BaixarRelatorioTodosSubitens(idContrato);
                    onHide();
                    break; 

                case 'BaixarRelatorioTodosSubitensTro':

                    let dataSubitem = {
                        'IdContrato': idContrato != null ? parseInt(idContrato) : null,
                        'IdEmpenho': idEmpenho != null ? parseInt(idEmpenho): null,
                    } 
                    BaixarRelatorioTodosSubitensTro(dataSubitem);
                    onHide();
                    break; 

                case 'BaixarRelatorioStatusSolicitacao':
                    BaixarRelatorioStatusSolicitacao(idContrato);
                    onHide();
                    break;

                case 'BaixarRelatorioGlobal':

                    if(!solicitacaoStatus && !autorizacaoStatus && !medicaoStatus && !empenhoStatus){
                        toast.error('Selecione a opção de exportação');
                        setValidaCheck(true);
                        break;
                    }
                    else{
                        let dataGlobal = {
                            'IdContrato': idContrato != null ? parseInt(idContrato) : null,
                            'DataInicio': dataInicio != null? dataInicio : null,
                            'DataFim': dataFim != null? dataFim : null,
                            'SolicitacaoStatus': solicitacaoStatus != null? solicitacaoStatus : null,
                            'AutorizacaoStatus': autorizacaoStatus != null? autorizacaoStatus : null,
                            'MedicaoStatus': medicaoStatus != null? medicaoStatus : null,
                            'EmpenhoStatus': empenhoStatus != null? empenhoStatus : null,
                        }
                        BaixarRelatorioGlobal(dataGlobal);
                        setShowGerar(true);
                        onHide();
                        break;
                    }
            }
            setValidated(false);
            setIdContrato('');
            setDataInicio(null);
            setDataFim(null);
            setIdPrioridade(null);
            setIdStatusSolicitacao(null);
            setIdStatusAF(null);
            setIdStatusMedicao(null);
            setIdAF('');
            setIdEmpenho('');
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Escolha os filtros
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={6} controlId="formIdContrato">
                            <Form.Label>Contrato</Form.Label>
                            <Form.Select
                                style={{ minHeight: '36px' }}
                                onChange={(e) => setIdContrato(e.target.value)}
                                value={idContrato}
                                required
                                isInvalid={validated && idContrato === "" ? true : false}
                                isValid={validated && idContrato !== "" ? true : false}>
                                <option value="">Selecione...</option>
                                {
                                    listaContrato != undefined ?
                                        listaContrato.map(m => (
                                            <option value={m.id} key={m.id}>{m.descricao}</option>
                                        ))
                                        :
                                        null
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">O contrato é obrigatório.</Form.Control.Feedback>
                        </Form.Group>
                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodosSubitensTro' ?  
                                <Form.Group as={Col} md={6} controlId="formPrioridades">
                                    <Form.Label>Empenho</Form.Label>
                                    <Select id="idSelectEmpenho"
                                        options={optionsEmpenho}
                                        placeholder="Selecione..."
                                        defaultValue={{
                                            value: idEmpenho, label: listaEmpenho != undefined && listaEmpenho.filter(soli => soli.id == idEmpenho).length > 0
                                                ? listaEmpenho.filter(soli => soli.id == idEmpenho)[0].descricao
                                                : 'Selecione...'
                                        }}
                                        onChange={(e) => {setIdEmpenho(e.value)}}
                                    />
                                </Form.Group>                              
                            :
                                ''                            
                        }

                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasSolicitacoes' ?                            
                                <Form.Group as={Col} md={6} controlId="formStatusSolicitacao">
                                    <Form.Label>Status Solicitação</Form.Label>
                                    <Form.Select
                                        style={{ minHeight: '36px' }}
                                        onChange={(e) => setIdStatusSolicitacao(e.target.value)}
                                        value={idStatusSolicitacao}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaStatusSolicitacaoFornecimentoCompleto != undefined ?
                                                listaStatusSolicitacaoFornecimentoCompleto.map(s => (
                                                    <option value={s.id} key={s.id}>{s.descricaoSolicitacaoFornecimentoVersao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                </Form.Group>                            
                            :
                                ''
                        }

                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasAutorizacoes' ?  
                                <Form.Group as={Col} md={6} controlId="formStatusContrato">
                                    <Form.Label>Status Autorização Fornecimento</Form.Label>
                                    <Form.Select
                                        style={{ minHeight: '36px' }}
                                        onChange={(e) => setIdStatusAF(e.target.value)}
                                        value={idStatusAF}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaStatusAutorizacaoFornecimento != undefined ?
                                                listaStatusAutorizacaoFornecimento.map(s => (
                                                    <option value={s.id} key={s.id}>{s.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                </Form.Group>                                
                            :
                                ''                            
                        }  

                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasMedicoes' ?  
                                <Form.Group as={Col} md={6} controlId="formStatusContrato">
                                    <Form.Label>Status Medição</Form.Label>
                                    <Form.Select
                                        style={{ minHeight: '36px' }}
                                        onChange={(e) => setIdStatusMedicao(e.target.value)}
                                        value={idStatusMedicao}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaStatusMedicao != undefined ?
                                                listaStatusMedicao.map(s => (
                                                    <option value={s.id} key={s.id}>{s.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                </Form.Group>                                
                            :
                                ''                            
                        }              
                    </Row>
                    <Row className="mb-3">

                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasAutorizacoes' || RelatorioSelecionado == 'BaixarRelatorioGlobal' ?
                                <Form.Group as={Col} md={6} controlId="formDataInicio">
                                    <Form.Label>Data Inicial de Criação</Form.Label>
                                    <Form.Control
                                        style={{ minHeight: '36px' }}
                                        type="date"
                                        value={dataInicio}
                                        onChange={e => setDataInicio(e.target.value)}
                                    />
                                </Form.Group>                              
                            :
                                RelatorioSelecionado == 'BaixarRelatorioTodasSolicitacoes' ?                            
                                    <Form.Group as={Col} md={6} controlId="formDataInicio">
                                        <Form.Label>Data Início</Form.Label>
                                        <Form.Control
                                            style={{ minHeight: '36px' }}
                                            type="date"
                                            value={dataInicio}
                                            onChange={e => setDataInicio(e.target.value)}
                                        />
                                    </Form.Group>                        
                                :
                                    RelatorioSelecionado == 'BaixarRelatorioTodasMedicoes'?
                                        <Form.Group as={Col} md={6} controlId="formDataInicio">
                                            <Form.Label>Data Inicial da Medição</Form.Label>
                                            <Form.Control
                                                style={{ minHeight: '36px' }}
                                                type="date"
                                                value={dataInicio}
                                                onChange={e => setDataInicio(e.target.value)}
                                            />
                                        </Form.Group>  
                                    : 
                                        ''                           
                        }                     
                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasAutorizacoes' || RelatorioSelecionado == 'BaixarRelatorioGlobal' ?  
                                <Form.Group as={Col} md={6} controlId="formDataFim">
                                    <Form.Label>Data Final de Criação</Form.Label>
                                    <Form.Control
                                        style={{ minHeight: '36px' }}
                                        type="date"
                                        value={dataFim}
                                        onChange={e => setDataFim(e.target.value)}
                                    />
                                </Form.Group>                              
                            :
                            RelatorioSelecionado == 'BaixarRelatorioTodasSolicitacoes'?  
                                <Form.Group as={Col} md={6} controlId="formDataFim">
                                    <Form.Label>Data Final</Form.Label>
                                    <Form.Control
                                        style={{ minHeight: '36px' }}
                                        type="date"
                                        value={dataFim}
                                        onChange={e => setDataFim(e.target.value)}
                                    />
                                </Form.Group>                              
                            :
                                RelatorioSelecionado == 'BaixarRelatorioTodasMedicoes'?  
                                    <Form.Group as={Col} md={6} controlId="formDataFim">
                                        <Form.Label>Data Final da Medição</Form.Label>
                                        <Form.Control
                                            style={{ minHeight: '36px' }}
                                            type="date"
                                            value={dataFim}
                                            onChange={e => setDataFim(e.target.value)}
                                        />
                                    </Form.Group>                              
                                :
                                    ''
                        }   
                    </Row>   
                    <Row className="mb-3">
                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasAutorizacoes' || RelatorioSelecionado == 'BaixarRelatorioTodasSolicitacoes'?  
                                <Form.Group as={Col} md={6} controlId="formPrioridades">
                                    <Form.Label>Prioridade</Form.Label>
                                    <Form.Select
                                        style={{ minHeight: '36px' }}
                                        onChange={(e) => setIdPrioridade(e.target.value)}
                                        value={idPrioridade}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaPrioridade != undefined ?
                                                listaPrioridade.map(p => (
                                                    <option value={p.id} key={p.id}>{p.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                </Form.Group>                              
                            :
                                ''                            
                        }    
                        
                        {
                            RelatorioSelecionado == 'BaixarRelatorioGlobal'?  
                                <Row>
                                    <Form.Group as={Col} md={2} controlId="formCheck1">
                                        <Form.Check
                                            checked={solicitacaoStatus}
                                            label='Solicitação'
                                            onChange={(e) => setSolicitacaoStatus(e.target.checked)}
                                            isInvalid={validaCheck && !solicitacaoStatus && !autorizacaoStatus && !medicaoStatus && !empenhoStatus}
                                        />
                                    </Form.Group>  
                                    <Form.Group as={Col} md={2} controlId="formCheck2">
                                        <Form.Check
                                            checked={autorizacaoStatus}
                                            label='Autorização'
                                            onChange={(e) => setAutorizacaoStatus(e.target.checked)}
                                            isInvalid={validaCheck && !solicitacaoStatus && !autorizacaoStatus && !medicaoStatus && !empenhoStatus}
                                        />
                                    </Form.Group>  
                                    <Form.Group as={Col} md={2} controlId="formCheck3">
                                        <Form.Check
                                            checked={medicaoStatus}
                                            label='Medição'
                                            onChange={(e) => setMedicaoStatus(e.target.checked)}
                                            isInvalid={validaCheck && !solicitacaoStatus && !autorizacaoStatus && !medicaoStatus && !empenhoStatus}
                                        />
                                    </Form.Group>  
                                    <Form.Group as={Col} md={2} controlId="formCheck4">
                                        <Form.Check
                                            checked={empenhoStatus}
                                            label='Empenho'
                                            onChange={(e) => setEmpenhoStatus(e.target.checked)}
                                            isInvalid={validaCheck && !solicitacaoStatus && !autorizacaoStatus && !medicaoStatus && !empenhoStatus}
                                        />
                                    </Form.Group> 
                                </Row>                           
                            :
                                ''                            
                        }         

                        {
                            RelatorioSelecionado == 'BaixarRelatorioTodasMedicoes'?  
                                <Form.Group as={Col} md={6} controlId="formPrioridades">
                                    <Form.Label>Autorização Fornecimento</Form.Label>
                                    <Select id="idSelectAF"
                                        options={options}
                                        placeholder="Selecione..."
                                        defaultValue={{
                                            value: idAF, label: listaAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimento.filter(soli => soli.id == idAF).length > 0
                                                ? listaAutorizacaoFornecimento.filter(soli => soli.id == idAF)[0].descricao
                                                : 'Selecione...'
                                        }}
                                        onChange={(e) => {setIdAF(e.value)}}
                                    />
                                </Form.Group>                              
                            :
                                ''                            
                        }                               
                    </Row>                  
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmit}>Gerar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                show={showGerar}
                onHide={onHideGerar}
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <strong style={{textAlign: 'center'}}>Devido a dados extensos, a exportação poderá levar algum minutos...</strong>
                        {
                            !carregandoGlobal ?
                                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <Loading/>
                                    <span style={{opacity: '0.4'}}>O Relatório está sendo gerado</span>
                                </div>
                            :
                                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin:'2%'}}>
                                    <RiCheckboxCircleLine size={20} color="green" style={{marginRight: '1%'}}/>
                                    <strong>O Relatório foi finalizado.</strong>
                                </div>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowGerar(false)} style={{ width: '100%' }}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}