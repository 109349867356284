import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { ListarCompletoEmpenhoStatusRequest } from "../../../store/modules/empenhoStatus/actions";
import { useDispatch } from "react-redux";
import Empenho from '../Empenho/empenho';
import Status from '../Status/status';
import Definitivo from '../Definitivo/definitivo';
import Parcial from '../Parcial/parcial';
import DocumentoArquivo from '../DocumentoArquivo/documentoArquivo';
import HistoricoStatus from '../HistoricoStatus/historicoStatus';
import Header from "../../../components/Header/header";
import Multa from "../Multa/multa";
import Diferenca from "../Diferenca/diferenca";

export default function Abas() {

    const { id } = useParams();
    const dispatch = useDispatch();

    //Permissão de Visualizar
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const history = useHistory();
    useEffect(() => {
        if (token.role.filter(r => r == "EmpenhoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {

        if (id != undefined) {
            dispatch(ListarCompletoEmpenhoStatusRequest(id));
        }

    }, [])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <span>
                                    <FaMoneyCheckAlt size={25} color={"#000"} /> Empenho
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Tabs defaultActiveKey="Empenho" id="tab-empenho" className="mb-3">
                                    <Tab eventKey="Empenho" title="Empenho">
                                        <Empenho />
                                    </Tab>
                                    <Tab eventKey="Definitivo" title="Definitivo" disabled={id ? false : true}>
                                        <Definitivo />
                                    </Tab>
                                    <Tab eventKey="Parcial" title="Parcial" disabled={id ? false : true}>
                                        <Parcial />
                                    </Tab>
                                    <Tab eventKey="Diferença" title="Diferença" disabled={id ? false : true}>
                                        <Diferenca />
                                    </Tab>

                                    <Tab eventKey="Multas" title="Multas" disabled={id ? false : true}>
                                        <Multa />
                                    </Tab>
                                    <Tab eventKey="DocumentoArquivo" title="Documentos" disabled={id ? false : true}>
                                        <DocumentoArquivo />
                                    </Tab>
                                    <Tab eventKey="Status" title="Status" disabled={id ? false : true}>
                                        <Status />
                                    </Tab>

                                    <Tab eventKey="HistoricoStatus" title="Histórico Status" disabled={id ? false : true}>
                                        <HistoricoStatus />
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}