export function ListarMedicaoAutorizacaoFornecimentoStatusRequest(IdMedicaoAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    IdMedicaoAutorizacaoFornecimento
  }
}

export function ListarMedicaoAutorizacaoFornecimentoStatusResponse(listaMedicaoAutorizacaoFornecimentoStatus) {
  return {
    type: 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE',
    listaMedicaoAutorizacaoFornecimentoStatus
  }
}

export function ListarCompletoMedicaoAutorizacaoFornecimentoStatusRequest(IdMedicaoAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_COMPLETO_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    IdMedicaoAutorizacaoFornecimento
  }
}

export function ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(listaCompletoMedicaoAutorizacaoFornecimentoStatus) {
  return {
    type: 'LISTAR_COMPLETO_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE',
    listaCompletoMedicaoAutorizacaoFornecimentoStatus
  }
}

export function InserirMedicaoAutorizacaoFornecimentoStatusRequest(data, IdMedicaoAutorizacaoFornecimento, validacao) {
  return {
    type: 'INSERIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    data, IdMedicaoAutorizacaoFornecimento, validacao
  }
}

export function AlterarMedicaoAutorizacaoFornecimentoStatusRequest(data, IdMedicaoAutorizacaoFornecimento) {
  return {
    type: 'ALTERAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    data, IdMedicaoAutorizacaoFornecimento
  }
}

export function ExcluirMedicaoAutorizacaoFornecimentoStatusRequest(IdMedicaoAutorizacaoFornecimento) {
  return {
    type: 'EXCLUIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    IdMedicaoAutorizacaoFornecimento
  }
}

export function ValidarMedicaoAutorizacaoFornecimentoStatusResponse(listaValidacaoMedicaoStatus) {
  return {
    type: 'VALIDAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE',
    listaValidacaoMedicaoStatus
  }
}

export function ReabrirMedicaoAutorizacaoFornecimentoStatusRequest(data, idAutorizacaoFornecimento) {
  return {
    type: 'REABRIR_MEDICAO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    data,
    idAutorizacaoFornecimento
  }
}

export function LimparListaValidacaoMedicao() {
  return {
    type: 'LIMPAR_LISTA_VALIDACAO_MEDICAO'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}