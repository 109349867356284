export function ListarBairroRequest() {
  return {
    type: 'LISTAR_BAIRRO_REQUEST'
  }
}

export function ListarBairroResponse(listaBairro) {
  return {
    type: 'LISTAR_BAIRRO_RESPONSE',
    listaBairro
  }
}

export function InserirBairroRequest(data) {
  return {
    type: 'INSERIR_BAIRRO_REQUEST',
    data
  }
}

export function AlterarBairroRequest(data) {
  return {
    type: 'ALTERAR_BAIRRO_REQUEST',
    data
  }
}

export function ExcluirBairroRequest(id) {
  return {
    type: 'EXCLUIR_BAIRRO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}