import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Alert, Row, Col, Modal, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    AlterarSolicitacaoFornecimentoRegistroFotograficoRequest, ListarCompletoSolicitacaoFornecimentoRegistroFotograficoRequest,
    ExcluirSolicitacaoFornecimentoRegistroFotograficoRequest, InserirSolicitacaoFornecimentoRegistroFotograficoRequest,
    BaixarSolicitacaoFornecimentoRegistroFotograficoRequest, CarregarImagemSolicitacaoFornecimentoRegistroFotograficoRequest
} from "../../../store/modules/solicitacaoFornecimentoRegistroFotografico/actions";
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarStatusSolicitacaoFornecimentoRequest } from '../../../store/modules/statusSolicitacaoFornecimento/actions';
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function RegistroFotografico() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const listaRegistroFotografico = useSelector(state => state.solicitacaoFornecimentoRegistroFotografico.listaSolicitacaoFornecimentoRegistroFotografico);
    const listaSolicitacaoFornecimentoVersaoStatus = useSelector(state =>
        state.solicitacaoFornecimentoVersaoStatus.listaSolicitacaoFornecimentoVersaoStatus);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);

    const [listaFile, setListaFile] = useState([]);
    const [observacao, setObservacao] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [showFoto, setShowFoto] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFotoShow = () => setShowFoto(true);
    const handleFotoClose = () => setShowFoto(false);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        //dispatch(ListarStatusSolicitacaoFornecimentoRequest());

        if (id != undefined) {
            dispatch(ListarCompletoSolicitacaoFornecimentoRegistroFotograficoRequest(id));
        }

    }, [id])

    function InserirRegistroFotografico() {

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdUsuario', usuario.id);
                formData.append('IdSolicitacao', id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setObservacao('');
            setListaFile([]);
            dispatch(InserirSolicitacaoFornecimentoRegistroFotograficoRequest(formData, id));
            handleClose();
        } else {
            toast.error('Para continuar insira um documento');
        }
    }

    function AlterarRegistroFotografico(registroFotografico, novoValorCampoEditado, colunaEditada) {

        // Valida alteração do campo nome arquivo
        if (colunaEditada.field == "nomeRegistroFotografico") {

            if (novoValorCampoEditado == null || novoValorCampoEditado == undefined
                || novoValorCampoEditado == ' ' || novoValorCampoEditado == '') {

                toast.error(`Nome do registro fotográfico inválido`);
                return;
            }

            if (novoValorCampoEditado.length > 150) {
                toast.error(`O nome do registro fotográfico não pode conter mais que 150 caracteres`);
                return;
            }
        }

        // Valida alteração do campo observação
        if (colunaEditada.field == "observacao") {
            if (novoValorCampoEditado.length > 500) {
                toast.error(`A observação não pode conter mais que 500 caracteres`);
                return;
            }
        }

        if (colunaEditada != null) {

            let data = {
                'Id': registroFotografico.id,
                'IdSolicitacaoFornecimento': registroFotografico.idSolicitacaoFornecimento,
                'Latitude': '0',
                'Longitude': '0',
                'FotoGuid': registroFotografico.fotoGuid,
                'DataInclusao': registroFotografico.dataInclusaoApi,
                'IdUsuario': usuario.id,
                'NomeRegistroFotografico': colunaEditada.field == "observacao" ? registroFotografico.nomeRegistroFotografico : novoValorCampoEditado,
                'DescricaoExtensaoArquivo': registroFotografico.descricaoExtensaoArquivo,
                'Observacao': colunaEditada.field == "observacao" ? novoValorCampoEditado : registroFotografico.observacao
            };

            dispatch(AlterarSolicitacaoFornecimentoRegistroFotograficoRequest(data, id));
        }
    }

    function ExcluirRegistroFotografico(idRegistroFotografico) {
        dispatch(ExcluirSolicitacaoFornecimentoRegistroFotograficoRequest(idRegistroFotografico, id));
    }

    function BaixarRegistroFotografico(idRegistroFotografico) {
        dispatch(BaixarSolicitacaoFornecimentoRegistroFotograficoRequest(idRegistroFotografico));
    }

    function VisualizarRegistroFotografico(linha) {
        dispatch(CarregarImagemSolicitacaoFornecimentoRegistroFotograficoRequest(linha.id));
        handleFotoShow();
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                inserir == true
                                    ?
                                    (listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != null && listaSolicitacaoFornecimentoVersaoStatus
                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null && listaSolicitacaoFornecimentoVersaoStatus
                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == false ||
                                        (solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.eventoResposta == true) ||
                                        (solicitacaoFornecimento != null && (solicitacaoFornecimento.idTipoSolicitacao == 139 || solicitacaoFornecimento.idTipoSolicitacao == 142 ||
                                            solicitacaoFornecimento.idTipoSolicitacao == 143 || solicitacaoFornecimento.idTipoSolicitacao == 144)) || usuario.id == 22
                                        ?
                                        <Button variant="success" onClick={handleShow} style={{ width: '100%' }}>Adicionar Registro Fotográfico</Button>
                                        :
                                        '')
                                    :
                                    ''
                            }
                            columns={[
                                {
                                    title: "Número do Registro", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                        width: '10%',
                                    }
                                },
                                {
                                    title: "Nome", field: 'nomeRegistroFotografico', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Usuário", field: 'usuario', editable: 'never', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Data", field: 'dataInclusao', editable: 'never', cellStyle: {
                                        width: '22.5%'
                                    }
                                },
                                {
                                    title: "Observação", field: 'observacao', cellStyle: {
                                        width: '22.5%'
                                    },
                                    render: rowData => rowData.observacao == '' ?
                                        <div className="spanVazio"></div>
                                        : rowData.observacao
                                }
                            ]}
                            data={
                                listaRegistroFotografico != undefined ?
                                    listaRegistroFotografico.map(doc => {
                                        return {
                                            id: doc.id,
                                            nomeRegistroFotografico: doc.nomeRegistroFotografico,
                                            usuario: doc.usuario.nome,
                                            dataInclusao: moment(doc.dataInclusao).format('L LT'),
                                            observacao: doc.observacao,

                                            // Info para alteração de documento arquivo
                                            dataInclusaoApi: doc.dataInclusao,
                                            descricaoExtensaoArquivo: doc.descricaoExtensaoArquivo,
                                            fotoGuid: doc.fotoGuid,
                                            idSolicitacaoFornecimento: doc.idSolicitacaoFornecimento,
                                            idUsuario: doc.idUsuario
                                        }
                                    })
                                    :
                                    []
                            }
                            cellEditable={{
                                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                    return new Promise((resolve, reject) => {
                                        alterar == true ?
                                            setTimeout(() => {
                                                AlterarRegistroFotografico(rowData, newValue, columnDef);
                                                resolve()
                                            }, 1000)
                                            :
                                            setTimeout(() => {
                                                toast.error(`Você não tem permissão`);
                                                resolve()
                                            }, 1000)
                                    });
                                }
                            }}
                            editable={{
                                isDeleteHidden: () => listaSolicitacaoFornecimentoVersaoStatus != undefined &&
                                listaSolicitacaoFornecimentoVersaoStatus
                                    .filter(x => x.statusSolicitacaoFornecimento
                                        .descricaoSolicitacaoFornecimentoVersao == "Reprovado/Encerrado" ||
                                        x.statusSolicitacaoFornecimento.cancelaSolicitacao == true ||
                                        x.statusSolicitacaoFornecimento.finalizaSolicitacao == true).length == 1,
                                onRowDelete:
                                    (id != undefined)
                                        /*listaSolicitacaoFornecimentoVersaoStatus != undefined)
                                        listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.statusAtual == true).length > 0 &&
                                        listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.statusAtual == true)[0]
                                            .statusSolicitacaoFornecimento.permiteAlterarSolicitacao)*/
                                        ?
                                        oldData =>
                                            new Promise((resolve, reject) => {
                                                excluir == true
                                                    ?
                                                    (listaSolicitacaoFornecimentoVersaoStatus != undefined &&
                                                        listaSolicitacaoFornecimentoVersaoStatus
                                                            .filter(x => x.statusSolicitacaoFornecimento
                                                                .descricaoSolicitacaoFornecimentoVersao == "Reprovado/Encerrado" ||
                                                                x.statusSolicitacaoFornecimento.cancelaSolicitacao == true ||
                                                                x.statusSolicitacaoFornecimento.finalizaSolicitacao == true).length == 1)
                                                        ?
                                                        setTimeout(() => {
                                                            resolve()
                                                            toast.error('Não é possível realizar operações pois essa solicitação está Reprovada/Encerrada');
                                                        }, 1000)
                                                        :
                                                        setTimeout(() => {
                                                            ExcluirRegistroFotografico(oldData.id);
                                                            resolve()
                                                        }, 1000)
                                                    :
                                                    setTimeout(() => {
                                                        toast.error(`Você não tem permissão`);
                                                        resolve()
                                                    }, 1000)
                                            })
                                        : null,
                            }}
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                actionsColumnIndex: -1,
                                exportButton: false,
                                exportAllData: true,
                                filtering: true,
                                grouping: false,
                                exportFileName: 'tabelaExportada',
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'visibility',
                                    onClick: (event, rowData) => { VisualizarRegistroFotografico(rowData) }
                                }),
                                {
                                    icon: 'download',
                                    onClick: (event, rowData) => BaixarRegistroFotografico(rowData.id)
                                },
                            ]}
                            localization={{
                                header: {
                                    actions: 'Ações'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: '',
                                    searchPlaceholder: 'Pesquisar',
                                    exportTitle: "",
                                    exportCSVName: "Exportar como CSV",
                                    exportPDFName: "Exportar como PDF",
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                    {
                        id != undefined && listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                            listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0
                            ?
                            <Col md={12}>
                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                    A Solicitação está Cancelada!
                                </Alert>
                            </Col>
                            :
                            listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`success`} style={{ width: '100%' }}>
                                        A Solicitação está Finalizada!
                                    </Alert>
                                </Col>
                                :
                                listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null && listaSolicitacaoFornecimentoVersaoStatus
                                [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == true ?
                                    <Col md={12}>
                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                            Status atual desta solicitação só permite leitura!
                                        </Alert>
                                    </Col>
                                    :
                                    ''
                    }
                </Col>
            </div>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Fotográfico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formRegistroFotografico">
                                <div className="divModal">
                                    <DropzoneArea
                                        initialFiles={[listaRegistroFotografico]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="success" onClick={InserirRegistroFotografico}>Salvar Registro Fotográfico</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showFoto} onHide={handleFotoClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formFoto">
                                <div id="divImagemFotoSolicitacaoFornecimentoRegistroFotografico">
                                </div>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>

        </>
    )
}