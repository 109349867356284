import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, Carregando, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';

function* BaixarDocumentoRequest(model) {

  try {


    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/SolicitacaoFornecimentoDocumentoArquivo/${model.id}`, { headers: headerParams.token });

    const nomeArquivo = `${result.data.result.nomeArquivo}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/SolicitacaoFornecimentoDocumentoArquivo/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    toast.error(e.response.data.message);
  }
}

function* RodarScriptTelefoneInteressado() {

  try {

    yield call(api.get, `/api/interessado/ArrumandoTelefone`);

  } catch (e) {
    yield put(ErrorResponse(e));
  }
}
export default all([
  takeLatest('BAIXAR_DOCUMENTO_REQUEST', BaixarDocumentoRequest),
  takeLatest('RODAR_SCRIPT_TELEFONE_INTERESSADO', RodarScriptTelefoneInteressado)
])