import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarFornecedorResponse, BuscarFornecedorPorIdResponse, Carregando } from './actions';
import { AtualizarToken } from '../global/actions';
import { toast } from 'react-toastify';
import { ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarFornecedorRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);    

    const result = yield call(api.get, `/api/Fornecedor/listarCompleto`, { headers: headerParams.token });

    yield put(ListarFornecedorResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarFornecedorResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* BuscarFornecedorPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/Fornecedor/${model.id}`, { headers: headerParams.token });

    yield put(BuscarFornecedorPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(BuscarFornecedorPorIdResponse(null));
    yield put(Carregando(true));
    
  }
}

function* InserirFornecedorRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var result = yield call(api.post, `/api/Fornecedor`, model.data, { headers: headerParams.token });

    const resultBusca = yield call(api.get, `/api/Fornecedor/${result.data.result}`, { headers: headerParams.token });

    yield put(BuscarFornecedorPorIdResponse(resultBusca.data.result));
    yield put(Carregando(true));

    history.push(`/alterarGeralFornecedor/${result.data.result}`);

    toast.success(`Fornecedor foi cadastrado com sucesso!`); 

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarFornecedorRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/Fornecedor`, model.data, { headers: headerParams.token });
    yield put(Carregando(true));
    
    if (model.data.IdTipoPessoa == 1) {

      if (model.data.FornecedorPessoaFisicaApiModel.Id != 0) {
        //Altera Física
        yield call(api.put, `/api/FornecedorPessoaFisica`, model.data.FornecedorPessoaFisicaApiModel, { headers: headerParams.token });    
      } else {
        // Cria Física
        yield call(api.post, `/api/FornecedorPessoaFisica`, model.data.FornecedorPessoaFisicaApiModel, { headers: headerParams.token });    
      }    
    
      //Exclui jurídica
      if (model.data.FornecedorPessoaJuridicaApiModel.Id != 0) {
        yield call(api.delete, `/api/FornecedorPessoaJuridica/${model.data.FornecedorPessoaJuridicaApiModel.Id}`, { headers: headerParams.token });  
      }
      
    } else if (model.data.IdTipoPessoa == 2) {

      if (model.data.FornecedorPessoaJuridicaApiModel.Id != 0) {
        //Alterar Jurídica
        yield call(api.put, `/api/FornecedorPessoaJuridica`, model.data.FornecedorPessoaJuridicaApiModel, { headers: headerParams.token });   
      } else {
        //Cria Jurídica
        yield call(api.post, `/api/FornecedorPessoaJuridica`, model.data.FornecedorPessoaJuridicaApiModel, { headers: headerParams.token });   
      }
           
      //Exclui Física
      if (model.data.FornecedorPessoaFisicaApiModel.Id != 0) {
        yield call(api.delete, `/api/FornecedorPessoaFisica/${model.data.FornecedorPessoaFisicaApiModel.Id}`, { headers: headerParams.token });  
      }
      
    }

    toast.success(`Fornecedor foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ExcluirFornecedorRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/Fornecedor/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));

    toast.success(`Fornecedor id: ${model.id} foi excluido!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  
  }
}

export default all([
  takeLatest('LISTAR_FORNECEDOR_REQUEST', ListarFornecedorRequest),
  takeLatest('BUSCAR_FORNECEDOR_POR_ID_REQUEST', BuscarFornecedorPorIdRequest),
  takeLatest('INSERIR_FORNECEDOR_REQUEST', InserirFornecedorRequest),
  takeLatest('ALTERAR_FORNECEDOR_REQUEST', AlterarFornecedorRequest),
  takeLatest('EXCLUIR_FORNECEDOR_REQUEST', ExcluirFornecedorRequest)
])