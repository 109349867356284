import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse,
  BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdResponse, Carregando
} from './actions';
import { toast } from 'react-toastify';
import {
  BuscarContratoVersaoPorIdResponse
} from '../contratoVersao/actions';
import {
  ListarAutorizacaoFornecimentoSubitemCustoResponse,
  ListarCompletoAutorizacaoFornecimentoSubitemCustoResponse
} from '../autorizacaoFornecimentoSubitemCusto/actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { ListarRelatorioMedicaoSubItemResponse } from '../relatorio/actions';
import { BuscarMedicaoAutorizacaoFornecimentoPorIdResponse } from '../medicaoAutorizacaoFornecimento/actions';
import { ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse, ListarMedicaoAutorizacaoFornecimentoStatusResponse } from '../medicaoAutorizacaoFornecimentoStatus/actions';

function* ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.id) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse([]));
    yield put(Carregando(true));
    yield put(ErrorResponse(e));

  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const idSubItem = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/listarCompleto`,
      { 'IdMedicaoAutorizacaoFornecimento': parseInt(model.data.IdMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(result.data.result));

    var retorno = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(retorno.data.result));

    const resultAuto = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(resultAuto.data.result));

    // LISTAR STATUS
    var data = {
      'IdMedicaoAutorizacaoFornecimento': model.data.IdMedicaoAutorizacaoFornecimento
    }

    const resultStatus = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(resultStatus.data.result));

    //Listar status completo para o histórico de status
    const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

    const resultMed = yield call(api.get, `/api/MedicaoAutorizacaoFornecimento/${model.data.IdMedicaoAutorizacaoFornecimento}`, { headers: headerParams.token });

    yield put(BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(resultMed.data.result));

    yield put(Carregando(true));

    toast.success(`Item medição foi inserido sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);
            }
          }
        }
      }

    yield put(ListarRelatorioMedicaoSubItemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/AutorizacaoFornecimentoSubitemCustoMedicao`, model.data, { headers: headerParams.token });

    toast.success(`Autorizacao Fornecimento Item Custo Medicao foi atualizado com sucesso!`);

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.data.IdMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(result.data.result));

    var retorno = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(retorno.data.result));

    const resultMedicao = yield call(api.get, `/api/MedicaoAutorizacaoFornecimento/${model.data.IdMedicaoAutorizacaoFornecimento}`, { headers: headerParams.token });

    yield put(BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(resultMedicao.data.result));

    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);
            }
          }
        }
      }

    yield put(ListarRelatorioMedicaoSubItemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/${model.data.id}`, { headers: headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/listarCompleto`,
      { IdMedicaoAutorizacaoFornecimento: parseInt(model.data.idMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Autorizacao Fornecimento Item Custo Medicao id: ${model.id} foi excluido!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/${model.idAutorizacaoFornecimentoSubitemCustoMedicao}`, { headers: headerParams.token });

    if (result.data.result != null) {
      yield put(BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdResponse(result.data.result));
    }
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(ErrorResponse(e));
    yield put(BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdResponse(undefined));
    yield put(Carregando(true));

  }

}

function* InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var dadosResquest = {
      IdAutorizacaoFornecimentoSubitemCusto: model.dados,
      IdAutorizacaoFornecimento: parseInt(model.idAutorizacaoFornecimento)
    }

    if(model.dados !== null){
      var dataAfSubitem = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/ListaId`, dadosResquest, { headers: headerParams.token });
    }

    var dataId = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/inserirSubitemMedicaoSemAutorizacao`, model.data, { headers: headerParams.token });

    if(model.dados !== null){
      for (var x = 0; x < model.dados.length; x++) {

        var dataAfMedicaoSubitem = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/${dataId.data.result[x]}`, { headers: headerParams.token });

        var dataAfSubitemAtual = yield call(api.get, `/api/AutorizacaoFornecimentoSubitemCusto/${dataAfMedicaoSubitem.data.result.idAutorizacaoFornecimentoSubitemCusto}`, { headers: headerParams.token });

        if(dataAfSubitem && dataAfSubitem.data && dataAfSubitem.data.result && dataAfSubitemAtual && dataAfSubitemAtual.data && dataAfSubitemAtual.data.result &&
          (dataAfSubitemAtual.data.result.quantidade > dataAfSubitem.data.result.filter(x => x.idSubitemCusto == dataAfSubitemAtual.data.result.idSubitemCusto)[0].quantidade ||
          model.data.filter(x => x.IdSubItemCusto == dataAfSubitemAtual.data.result.idSubitemCusto)[0].Quantidade > dataAfSubitem.data.result.filter(x => x.idSubitemCusto == dataAfSubitemAtual.data.result.idSubitemCusto)[0].quantidade) ||
          model.valorSubitemMedicoes > model.valorEcedente){
          var resultId = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${dataAfSubitemAtual.data.result.id}/AutorizacaoFornecimentoSubitem`, model.formData, { headers: headerParams.token });
        }
        
      }
    }else{
      if(dataId !== null){
        for (var x = 0; x < dataId.data.result.length; x++) {
          var resultId = yield call(api.post, `/api/RegFotoSubitemAutorizacaoMedicaoUltrapassarSubitem/${dataId.data.result[x]}/AutorizacaoFornecimentoSubitemMedicao`, model.formData, { headers: headerParams.token });
        }
      }
    }
   
    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/listarCompleto`,
      { 'IdMedicaoAutorizacaoFornecimento': parseInt(model.data[0].IdMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(result.data.result));

    var retorno = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(retorno.data.result));

    const resultAuto = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(resultAuto.data.result));
    yield put(ListarCompletoAutorizacaoFornecimentoSubitemCustoResponse(resultAuto.data.result));

    const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));

    // LISTAR STATUS
    var data = {
      'IdMedicaoAutorizacaoFornecimento': model.data[0].IdMedicaoAutorizacaoFornecimento
    }

    const resultStatus = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(resultStatus.data.result));

    //Listar status completo para o histórico de status
    const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

    const resultMed = yield call(api.get, `/api/MedicaoAutorizacaoFornecimento/${model.data[0].IdMedicaoAutorizacaoFornecimento}`, { headers: headerParams.token });

    yield put(BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(resultMed.data.result));

    yield put(Carregando(true));

    toast.success(`Item medição foi inserido sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);
            }
          }
        }
      }

    yield put(ListarRelatorioMedicaoSubItemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/inserirSubitemMedicaoSemAutorizacao`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCustoMedicao/listarCompleto`,
      { 'IdMedicaoAutorizacaoFornecimento': parseInt(model.data[0].IdMedicaoAutorizacaoFornecimento) }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(result.data.result));

    var retorno = yield call(api.post, `/api/Relatorio/ListarRelatorioMedicaoSubItem`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarRelatorioMedicaoSubItemResponse(retorno.data.result));

    const resultAuto = yield call(api.post, `/api/AutorizacaoFornecimentoSubitemCusto/listarCompleto`,
      { 'IdAutorizacaoFornecimento': model.idAutorizacaoFornecimento }, { headers: headerParams.token });

    yield put(ListarAutorizacaoFornecimentoSubitemCustoResponse(resultAuto.data.result));
    yield put(ListarCompletoAutorizacaoFornecimentoSubitemCustoResponse(resultAuto.data.result));

    const resultContratoVersao = yield call(api.get, `/api/ContratoVersao/${model.idContratoVersao}`, { headers: headerParams.token });

    yield put(BuscarContratoVersaoPorIdResponse(resultContratoVersao.data.result));

    // LISTAR STATUS
    var data = {
      'IdMedicaoAutorizacaoFornecimento': model.data[0].IdMedicaoAutorizacaoFornecimento
    }

    const resultStatus = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

    yield put(ListarMedicaoAutorizacaoFornecimentoStatusResponse(resultStatus.data.result));

    //Listar status completo para o histórico de status
    const resultCompleto = yield call(api.post, `/api/MedicaoAutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

    yield put(ListarCompletoMedicaoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

    const resultMed = yield call(api.get, `/api/MedicaoAutorizacaoFornecimento/${model.data[0].IdMedicaoAutorizacaoFornecimento}`, { headers: headerParams.token });

    yield put(BuscarMedicaoAutorizacaoFornecimentoPorIdResponse(resultMed.data.result));

    yield put(Carregando(true));

    toast.success(`Item medição foi inserido sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`)
    }
    else
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
          apiErrors[modelProperty].forEach(function (e) {
            erros = erros + e + ' ';
          });
        }
        toast.error(erros);
      } else {
        if (e.response != null) {
          if (e.response.data != null) {
            if (e.response.data.statusCode != 404) {
              toast.error(e.response.data.message);
            }
          }
        }
      }

    yield put(ListarRelatorioMedicaoSubItemResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

export default all([
  takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST', ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest),
  takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST', ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRequest),
  takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST', AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest),
  takeLatest('BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_POR_ID_REQUEST', BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_SEM_AUTORIZACAO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest),
  takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_SEM_AUTORIZACAO_COM_FOTO_REQUEST', InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest)
])