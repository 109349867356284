import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    InserirFonteReceitaRequest, AlterarFonteReceitaRequest,
    BuscarFonteReceitaPorIdRequest
} from "../../../store/modules/fonteReceita/actions";
import { useParams } from 'react-router-dom';

export default function FonteReceita() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const fonteReceitaCompleta = useSelector(state => state.fonteReceita.fonteReceitaCompleta);

    const [nomeFonteReceita, setNomeFonteReceita] = useState('');
    const [numeroCodigoReceita, setNumeroCodigoReceita] = useState('');
    const [validated, setValidated] = useState(false);     

    useEffect(() => {
        if (id != undefined)
            dispatch(BuscarFonteReceitaPorIdRequest(id));
    }, [])

    useEffect(() => {

        if (fonteReceitaCompleta != null && fonteReceitaCompleta.id == parseInt(id)) {
            setNomeFonteReceita(fonteReceitaCompleta.nomeFonteReceita);
            setNumeroCodigoReceita(fonteReceitaCompleta.numeroCodigoReceita);
        }

    }, [fonteReceitaCompleta])

    function InserirFonteReceita(e) {

        e.preventDefault();

        let data = {
            'Id': fonteReceitaCompleta == null ? parseInt(0) : fonteReceitaCompleta.id,
            'NumeroCodigoReceita': numeroCodigoReceita,
            'NomeFonteReceita': nomeFonteReceita.trim()
        }

        if (id == undefined) {
            dispatch(InserirFonteReceitaRequest(data));
        } else {
            dispatch(AlterarFonteReceitaRequest(data));
        }
    }

    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false) {
            event.preventDefault();
        } else {
            InserirFonteReceita(event);
        }

        setValidated(true);
    };

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="divBody">
                    <Col md={12}>

                        <Row className="mb-3">

                            <Form.Group as={Col} md={6} controlId="formIdCliente">
                                <Form.Label>Nome Fonte Receita</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength="60"
                                    value={nomeFonteReceita}
                                    onChange={e => setNomeFonteReceita(e.target.value)}
                                    required
                                    isInvalid={validated && nomeFonteReceita === "" ? true : false}
                                    isValid={validated && nomeFonteReceita !== "" ? true : false} 
                                    disabled />
                                <Form.Control.Feedback type="invalid">O campo nome fonte receita é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md={6} controlId="formDescricao">
                                <Form.Label>Número Código Receita</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength="50"
                                    value={numeroCodigoReceita}
                                    onChange={e => setNumeroCodigoReceita(e.target.value)}
                                    required
                                    isInvalid={validated && numeroCodigoReceita === "" ? true : false}
                                    isValid={validated && numeroCodigoReceita !== "" ? true : false}
                                    disabled />
                                <Form.Control.Feedback type="invalid">O campo número código receita é obrigatório.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                    </Col>
                </div>
            </Form>
        </>
    );
}