import produce from 'immer';

const initialState = {
  listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto: undefined,
  carregando: true
};

export default function autorizacaoFornecimentoSubitemCustoMedicaoRegFoto(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_MEDICAO_REGISTRO_FOTOGRAFICO_RESPONSE':
      return produce(state, draft => {
        draft.listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto = action.listaAutorizacaoFornecimentoSubitemCustoMedicaoRegFoto;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}