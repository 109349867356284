import { Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSearchPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import { MdAttachMoney } from "react-icons/md";
import { ListarFonteReceitaRequest, ExcluirFonteReceitaRequest, InserirFonteReceitaRequest, AlterarFonteReceitaRequest } from "../../../store/modules/fonteReceita/actions";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import Header from '../../../components/Header/header';
import Loading from '../../../components/Loading/loading';
import MaterialTable from 'material-table';
import { FaPlus } from "react-icons/fa";

export default function Listar() {

    const history = useHistory();
    const dispatch = useDispatch();

    const listaFonteReceita = useSelector(state => state.fonteReceita.listaFonteReceita);
    const carregando = useSelector(state => state.fonteReceita.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "FonteReceitaMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "FonteReceitaInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "FonteReceitaAlterar").length == 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r == "FonteReceitaExcluir").length == 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarFonteReceitaRequest());
    }, [])

    function ValidacoesInserir(fonteReceita) {

        if (fonteReceita.nomeFonteReceita == null || fonteReceita.nomeFonteReceita == undefined
            || fonteReceita.nomeFonteReceita == ' ' || fonteReceita.nomeFonteReceita.length > 60) {
            toast.error(`Nome fonte receita inválido`);
            return false;
        }

        if (fonteReceita.numeroCodigoReceita == null || fonteReceita.numeroCodigoReceita == undefined
            || fonteReceita.numeroCodigoReceita == ' ' || fonteReceita.numeroCodigoReceita.length > 60) {
            toast.error(`Número fonte receita inválido`);
            return false;
        }

        return true;

    }

    function InserirFonteReceita(fonteReceita) {

        let data = {
            'Id': 0,
            'NomeFonteReceita': fonteReceita.nomeFonteReceita.trim(),
            'NumeroCodigoReceita': fonteReceita.numeroCodigoReceita.trim(),
        }

        dispatch(InserirFonteReceitaRequest(data))
    }

    function AlterarFonteReceita(fonteReceita) {

        let data = {
            'Id': fonteReceita.id,
            'NomeFonteReceita': fonteReceita.nomeFonteReceita.trim(),
            'NumeroCodigoReceita': fonteReceita.numeroCodigoReceita.trim(),
        }

        dispatch(AlterarFonteReceitaRequest(data))
    }

    function ExcluirFonteReceita(id) {
        dispatch(ExcluirFonteReceitaRequest(id));
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdAttachMoney size={25} color={"#000"} /> Fonte Receita
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col md={12}>
                                        {
                                            carregando ?
                                                <MaterialTable
                                                    components={{
                                                        Pagination: PatchedPagination,
                                                    }}
                                                    title=''
                                                    columns={[
                                                        {
                                                            title: "Número da Fonte", field: 'id', defaultSort: 'desc', cellStyle: {
                                                                width: '15%'
                                                            },
                                                            editable: 'never'
                                                        },
                                                        {
                                                            title: "Nome Fonte Receita", field: 'nomeFonteReceita', cellStyle: {
                                                                width: '45%'
                                                            }, validate: rowData => {
                                                                if (rowData.nomeFonteReceita === undefined || rowData.nomeFonteReceita.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                }
                                                                else if (rowData.nomeFonteReceita.trim().length > 60) {
                                                                    return "O nome não pode conter mais que 60 caracteres"
                                                                }
                                                                return true
                                                            }
                                                        },
                                                        {
                                                            title: "Número Código Receita", field: 'numeroCodigoReceita', cellStyle: {
                                                                width: '45%'
                                                            }, validate: rowData => {
                                                                if (rowData.numeroCodigoReceita === undefined || rowData.numeroCodigoReceita.trim() === "") {
                                                                    return "Campo Obrigatório"
                                                                }
                                                                else if (rowData.numeroCodigoReceita.trim().length > 60) {
                                                                    return "O número não pode conter mais que 60 caracteres"
                                                                }
                                                                return true
                                                            }
                                                        },
                                                    ]}
                                                    data={
                                                        listaFonteReceita != undefined ?
                                                            listaFonteReceita.map((fonte) => {
                                                                return {
                                                                    id: fonte.id,
                                                                    nomeFonteReceita: fonte.nomeFonteReceita,
                                                                    numeroCodigoReceita: fonte.numeroCodigoReceita
                                                                }
                                                            })
                                                            :
                                                            []
                                                    }
                                                    editable={{
                                                        onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                            inserir == true ?
                                                                setTimeout(() => {
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else {
                                                                        InserirFonteReceita(newRow);
                                                                        resolve()
                                                                    }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        }),

                                                        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                            alterar == true ?
                                                                setTimeout(() => {
                                                                    if (!ValidacoesInserir(newRow)) {
                                                                        reject();
                                                                    }
                                                                    else {
                                                                        AlterarFonteReceita(newRow);
                                                                        resolve()
                                                                    }
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        }),

                                                        onRowDelete: oldData => new Promise((resolve, reject) => {
                                                            excluir == true ?
                                                                setTimeout(() => {
                                                                    ExcluirFonteReceita(oldData.id);
                                                                    resolve()
                                                                }, 1000)
                                                                :
                                                                setTimeout(() => {
                                                                    toast.error(`Você não tem permissão`);
                                                                    resolve()
                                                                }, 1000)
                                                        })
                                                    }}
                                                    options={{
                                                        minBodyHeight: 500,
                                                        paging: true,
                                                        pageSize: 10, // Início
                                                        emptyRowsWhenPaging: false,
                                                        addRowPosition: "first",
                                                        pageSizeOptions: [10, 50, 200, 300],
                                                        headerStyle: {
                                                            backgroundColor: '#454545',
                                                            color: '#FFF',
                                                            fontWeight: "bold",
                                                            lineBreak: "auto",
                                                            fontSize: "13px"
                                                        },
                                                        actionsColumnIndex: -1,
                                                        filtering: true,
                                                        rowStyle: rowData => ({
                                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                        }),
                                                        filterRowStyle: {
                                                            backgroundColor: "#FAFAFA"
                                                        },
                                                    }}
                                                    actions={[
                                                        {
                                                            icon: () => <FaSearchPlus />,
                                                            onClick: (event, rowData) => { history.push(`/alterarGeralFonteReceita/${rowData.id}`); }
                                                        }
                                                    ]}
                                                    icons={{
                                                        Add: () =>
                                                            <Button variant="success">
                                                                <span>
                                                                    <FaPlus size={15} color={"#fff"} /> Inserir
                                                                </span>
                                                            </Button>
                                                    }}
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                    localization={{
                                                        header: {
                                                            actions: <span className="classHeaderTabela">Ações</span>
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                            editRow: {
                                                                deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                saveTooltip: '',
                                                                cancelTooltip: '',
                                                            },
                                                            deleteTooltip: '',
                                                            editTooltip: '',
                                                            addTooltip: '',
                                                        },
                                                        toolbar: {
                                                            searchTooltip: '',
                                                            searchPlaceholder: 'Pesquisar',
                                                            exportTitle: "",
                                                            exportCSVName: "Exportar como CSV",
                                                            exportPDFName: "Exportar como PDF",
                                                        },
                                                        pagination: {
                                                            labelRowsSelect: 'linhas',
                                                            labelDisplayedRows: '{count} de {from}-{to}',
                                                            firstTooltip: '',
                                                            previousTooltip: '',
                                                            nextTooltip: '',
                                                            lastTooltip: ''
                                                        },
                                                        grouping: {
                                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                                            groupedBy: 'Agrupado por:'
                                                        }
                                                    }}
                                                />
                                                :
                                                <Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}