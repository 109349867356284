import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarCnaeResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';

function* ListarCnaeRequest(){
  
  try{

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Cnae`, { headers: headerParams.token });

    yield put(ListarCnaeResponse(result.data.result)); 
    yield put(Carregando(true));    
  
  }catch(e){
  
    yield put(ListarCnaeResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    

  }
}

export default all([
  takeLatest('LISTAR_CNAE_REQUEST', ListarCnaeRequest)
])