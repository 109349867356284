import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarPrioridadeResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarPrioridadeRequest() {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/Prioridade`, { headers: headerParams.token });

    yield put(ListarPrioridadeResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarPrioridadeResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirPrioridadeRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/Prioridade`, model.data, { headers: headerParams.token });

    toast.success(`Prioridade ${model.data.Descricao} foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/Prioridade`, { headers: headerParams.token });

    yield put(ListarPrioridadeResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarPrioridadeRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/Prioridade`, model.data, { headers: headerParams.token });

    toast.success(`Prioridade foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/Prioridade`, { headers: headerParams.token });

    yield put(ListarPrioridadeResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirPrioridadeRequest(model) {

  try {

    yield put(Carregando(false))    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/Prioridade/${model.id}`, { headers: headerParams.token });

    toast.success(`Prioridade id: ${model.id} foi excluido!`);

    const result = yield call(api.get, `/api/Prioridade`, { headers: headerParams.token });

    yield put(ListarPrioridadeResponse(result.data.result));
    yield put(Carregando(true))    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ErrorResponse(e));
    yield put(Carregando(true))    
  }
}

export default all([
  takeLatest('LISTAR_PRIORIDADE_REQUEST', ListarPrioridadeRequest),
  takeLatest('INSERIR_PRIORIDADE_REQUEST', InserirPrioridadeRequest),
  takeLatest('ALTERAR_PRIORIDADE_REQUEST', AlterarPrioridadeRequest),
  takeLatest('EXCLUIR_PRIORIDADE_REQUEST', ExcluirPrioridadeRequest)
])