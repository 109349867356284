import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarStatusSolicitacaoFornecimentoResponse, ListarStatusSolicitacaoFornecimentoCompletoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';

function* ListarStatusSolicitacaoFornecimentoRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/StatusSolicitacaoFornecimento`, { headers: headerParams.token });

    yield put(ListarStatusSolicitacaoFornecimentoResponse(result.data.result));
    yield put(Carregando(true)); 
  } catch (e) {

    yield put(ListarStatusSolicitacaoFornecimentoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true)); 
  }
}

function* ListarStatusSolicitacaoFornecimentoCompletoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/StatusSolicitacaoFornecimento/ListarCompleto`, {idSolicitacaoFornecimento: model.idSolicitacaoFornecimento}, { headers: headerParams.token });

    yield put(ListarStatusSolicitacaoFornecimentoCompletoResponse(result.data.result));
    yield put(Carregando(true)); 
  } catch (e) {

    yield put(ListarStatusSolicitacaoFornecimentoCompletoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true)); 
  }
}

export default all([
    takeLatest('LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_REQUEST', ListarStatusSolicitacaoFornecimentoRequest),
    takeLatest('LISTAR_STATUS_SOLICITACAO_FORNECIMENTO_COMPLETO_REQUEST', ListarStatusSolicitacaoFornecimentoCompletoRequest)
  ])