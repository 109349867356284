import { Row, Button, Modal, ModalFooter, Col, Form, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import { BuscarContratoVersaoPorIdRequest } from '../../store/modules/contratoVersao/actions';
import { ListarTipoSolicitacaoRequest } from '../../store/modules/tipoSolicitacao/actions';
import { BuscarSolicitacaoFornecimentoPorIdRequest, InserirSolicitacaoFornecimentoRequest, AlterarSolicitacaoFornecimentoRequest } from '../../store/modules/solicitacaoFornecimento/actions';
import { InserirAutorizacaoFornecimentoSubitemCustoRequest,
    InserirAutorizacaoFornecimentoSubitemCustoComFotoRequest } from '../../store/modules/autorizacaoFornecimentoSubitemCusto/actions';
import {
    ListarSolicitacaoFornecimentoVersaoSubitemCustoRequest,
    ExcluirSolicitacaoFornecimentoVersaoSubitemCustoPorIdSolicitacaoRequest,
    InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest
} from '../../store/modules/solicitacaoFornecimentoVersaoSubitemCusto/actions';
import { ListarContratoUsuarioRequest } from '../../store/modules/contratoUsuario/actions';
import {
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest,
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest
} from '../../store/modules/autorizacaoFornecimentoSubitemCustoMedicao/actions';
import { ListarUnidadeMedidaRequest } from '../../store/modules/unidadeMedida/actions';
import { BuscarMedicaoAutorizacaoFornecimentoPorIdRequest } from '../../store/modules/medicaoAutorizacaoFornecimento/actions';
import { toast } from "react-toastify";
import { DropzoneArea } from 'material-ui-dropzone';
import Select from 'react-select';
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalSubitemAutorizacao({ idContratoVersao, show, onHide, idAutorizacaoFornecimento, idSolicitacaoFornecimento, numeroOrcamentoSolicitacao, idMedicao, idAuto,
    totalOrcamento, totalOrcamentoAdicionados }) {

    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const contratoVersao = useSelector(state => state.contratoVersao.contratoVersaoCompleto);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const listaSolicitacaoFornecimentoVersaoSubitemCusto =
        useSelector(state => state.solicitacaoFornecimentoVersaoSubitemCusto.listaSolicitacaoFornecimentoVersaoSubitemCusto);
    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const tipoSolicitacaoCompleto = useSelector(state => state.tipoSolicitacao.tipoSolicitacaoCompleto);
    const listaAutorizacaoFornecimentoSubitemCustoMedicao =
        useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicao.listaAutorizacaoFornecimentoSubitemCustoMedicao);
    const listaUnidadeMedida = useSelector(state => state.unidadeMedida.listaUnidadeMedida);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const medicaoAutorizacaoFornecimentoCompleto = useSelector(state => state.medicaoAutorizacaoFornecimento.medicaoAutorizacaoFornecimentoCompleto);
    const listaMedicaoAutorizacaoFornecimento = useSelector(state => state.medicaoAutorizacaoFornecimento.listaMedicaoAutorizacaoFornecimento);
    
    const options = contratoVersao ?
        contratoVersao.subitemCusto.map(subitemUnidade => ({
            value: subitemUnidade.id, label: subitemUnidade.descricao
        }))
        :
        [];

    const [listaDataCompleta, setListaDataCompleta] = useState('');
    const [listaSubitem, setListaSubitem] = useState([]);
    const [numeroOrcamento, setNumeroOrcamento] = useState('');
    const [dados, setDados] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [dataSolicitacao, setDataSolicitacao] = useState('');
    const [novaSolicitacao, setNovaSolictacao] = useState(false);
    const [observacaoSubitem, setObservacaoSubitem] = useState('');
    const [validated, setValidated] = useState(false);
    const [showConfirmacao, setShowConfirmacao] = useState(false);
    const [showFoto, setShowFoto] = useState(false);
    const [showFotoMedicao, setShowFotoMedicao] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [listaFile, setListaFile] = useState([]);
    const [dataMedicao, setDataMedicao] = useState('');

    const handleCloseConfirmacao = () => setShowConfirmacao(false);
    const handleShowConfirmacao = () => setShowConfirmacao(true);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleCloseFoto = () => setShowFoto(false);
    const handleShowFoto = () => setShowFoto(true);

    const handleCloseFotoMedicao = () => setShowFotoMedicao(false);
    const handleShowFotoMedicao = () => setShowFotoMedicao(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoVerValores").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => {

        if(idMedicao){
            dispatch(BuscarMedicaoAutorizacaoFornecimentoPorIdRequest(idMedicao))
        }
        
    }, [idMedicao])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarUnidadeMedidaRequest());
        dispatch(ListarTipoSolicitacaoRequest());
        dispatch(BuscarContratoVersaoPorIdRequest(idContratoVersao));

        if (idSolicitacaoFornecimento != undefined) { }
        //dispatch(BuscarSolicitacaoFornecimentoPorIdRequest(idSolicitacaoFornecimento));
    }, [])

    useEffect(() => {
        constroiData();
    }, [contratoVersao])

    const handleSubmit = (event) => {

        //Verifica se foi inserido algo na descrição de cancelamento
        if (observacaoSubitem.trim() == '') {
            event.preventDefault();
        } else {
            ValidacaoInserirSubitemMedicao()
            setDataMedicao(event)
            //InserirSubitemMedicao(event);
        }

        setValidated(true);
    };

    function constroiData() {

        let dataCompleta = [];

        if (contratoVersao != null && contratoVersao.subitemCusto != null & contratoVersao.subitemCusto != undefined && listaUnidadeMedida != undefined) {

            if (idAutorizacaoFornecimento != undefined) {

                contratoVersao.subitemCusto.map((subitem) => {

                    if ((listaAutorizacaoFornecimentoSubitemCusto != undefined &&
                        listaAutorizacaoFornecimentoSubitemCusto.find(x => x.idSubitemCusto == subitem.id) == undefined) ||
                        (listaAutorizacaoFornecimentoSubitemCusto != undefined &&
                            listaAutorizacaoFornecimentoSubitemCusto.filter(x => x.idSubitemCusto == subitem.id && x.inseridoAposSolicitacao == true).length == 0)) {
                        dataCompleta.push({
                            id: subitem.id,
                            descricao: subitem.descricaoCodigoInterno + " - " + subitem.descricao,
                            quantidade: 0,
                            valorUnitario: subitem.valorUnitario,
                            valorTotal: 0,
                            saldoContratoExcedente: subitem.saldoExcedente,
                            saldoContrato: subitem.saldo,
                            observacao: subitem.observacao,
                            unidadeMedida: listaUnidadeMedida != undefined ? listaUnidadeMedida.filter(y => y.id == subitem.idUnidadeMedida)[0].descricao : ''
                        });
                    }
                })

            } else if (idSolicitacaoFornecimento != undefined) {

                contratoVersao.subitemCusto.map((subitem) => {

                    if (listaSolicitacaoFornecimentoVersaoSubitemCusto != undefined) {
                        dataCompleta.push({
                            id: subitem.id,
                            descricao: subitem.descricaoCodigoInterno + " - " + subitem.descricao,
                            quantidade: 0,
                            valorUnitario: subitem.valorUnitario,
                            valorTotal: 0,
                            saldoContratoExcedente: subitem.saldoExcedente,
                            saldoContrato: subitem.saldo,
                            unidadeMedida: listaUnidadeMedida != undefined ? listaUnidadeMedida.filter(y => y.id == subitem.idUnidadeMedida)[0].descricao : ''
                        });
                    }
                })
            } else {

                contratoVersao.subitemCusto.map((subitem) => {

                    if (listaAutorizacaoFornecimentoSubitemCustoMedicao != undefined && listaAutorizacaoFornecimentoSubitemCusto &&
                        listaAutorizacaoFornecimentoSubitemCustoMedicao.find(x => x.autorizacaoFornecimentoSubitemCusto.idSubitemCusto == subitem.id) == undefined &&
                        listaAutorizacaoFornecimentoSubitemCusto.find(x => x.idSubitemCusto == subitem.id) == undefined) {
                        dataCompleta.push({
                            id: subitem.id,
                            descricao: subitem.descricaoCodigoInterno + " - " + subitem.descricao,
                            quantidade: 0,
                            valorUnitario: subitem.valorUnitario,
                            valorTotal: 0,
                            saldoContratoExcedente: subitem.saldoExcedente,
                            saldoContrato: subitem.saldo,
                            unidadeMedida: listaUnidadeMedida != undefined ? listaUnidadeMedida.filter(y => y.id == subitem.idUnidadeMedida)[0].descricao : ''
                        });
                    }
                })

            }

            if (numeroOrcamentoSolicitacao !== null && numeroOrcamentoSolicitacao !== undefined && numeroOrcamentoSolicitacao !== '') {
                setNumeroOrcamento(numeroOrcamentoSolicitacao)
            }
        }

        setListaDataCompleta(dataCompleta);
    }

    function Selecionar(data) {
        setListaSubitem(data);
    }

    function InserirSubitemAutorizacao() {

        var verificacao = true;
        var valor = totalOrcamento.split('$', totalOrcamento.length);
        let valorOrcamento = parseFloat(valor[1]);
        var valorAdicionados = totalOrcamentoAdicionados.split('$', totalOrcamentoAdicionados.length)
        let valorTotalAdicionados = parseFloat(valorAdicionados[1]);

        listaSubitem.forEach(subitem => {
            valorTotalAdicionados += subitem.quantidade * subitem.valorUnitario;
        });

        listaSubitem.forEach(subitem => {

            if(subitem.quantidade > subitem.saldoContratoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
                if(contratoVersao && valorOrcamento && valorTotalAdicionados){
                    let valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
                    if(valorTotalAdicionados > valorTotalExcedente){
                        verificacao = false;
                        return;
                    }
                }
            }

            if (subitem.quantidade == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }

            if (subitem.observacao == undefined || subitem.observacao == null || subitem.observacao == '') {
                toast.error('Observação é obrigatório');
                verificacao = false;
                return;
            }

            if (subitem.observacao != undefined && subitem.observacao != null && subitem.observacao.length > 500) {
                toast.error('Observação não pode conter mais que 500 caracteres');
                verificacao = false;
                return;
            }

        });

        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    'Id': 0,
                    'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                    'IdSubitemCusto': subitem.id,
                    'Quantidade': parseFloat(subitem.quantidade),
                    'Cancelado': false,
                    'Finalizado': false,
                    'InseridoAposSolicitacao': true,
                    'InseridoAposSolicitacaoAprovado': false,
                    'Observacao': listaSubitem.filter(x => x.id == subitem.id)[0].observacao,
                    'IdUsuario': parseInt(usuario.id)
                }
            })

            let data = {
                'Id': 0,
                'IdAutorizacaoFornecimento': listaSubitemInsercao[0].IdAutorizacaoFornecimento,
                'IdSubitemCusto': listaSubitemInsercao[0].IdSubitemCusto,
                'Quantidade': listaSubitemInsercao[0].Quantidade,
                'Cancelado': false,
                'Finalizado': false,
                'InseridoAposSolicitacao': true,
                'InseridoAposSolicitacaoAprovado': false,
                ListaAutorizacaoFornecimentoSubitemCustoApiModel: listaSubitemInsercao,
                'Observacao': listaSubitem.filter(x => x.id == listaSubitemInsercao[0].IdSubitemCusto)[0].observacao,
                'IdUsuario': parseInt(usuario.id)
            }

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoRequest(data, idAutorizacaoFornecimento, contratoVersao.id));
            setListaSubitem([]);
            onHide(true);
        }
    }

    function InserirSubitemAutorizacaoComFoto() {

        var verificacao = true;

        listaSubitem.forEach(subitem => {

            if (subitem.quantidade == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }

            if (subitem.observacao == undefined || subitem.observacao == null || subitem.observacao == '') {
                toast.error('Observação é obrigatório');
                verificacao = false;
                return;
            }

            if (subitem.observacao != undefined && subitem.observacao != null && subitem.observacao.length > 500) {
                toast.error('Observação não pode conter mais que 500 caracteres');
                verificacao = false;
                return;
            }

        });

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdAutorizacaoFornecimentoSubitemCusto', null);
                formData.append('IdAutorizacaoFornecimentoSubitemCustoMedicao', null);
                formData.append('IdUsuario', usuario.id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setListaFile([]);
            handleCloseFoto();
        } else {
            toast.error('Para continuar insira um documento');
        }

        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    'Id': 0,
                    'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                    'IdSubitemCusto': subitem.id,
                    'Quantidade': parseFloat(subitem.quantidade),
                    'Cancelado': false,
                    'Finalizado': false,
                    'InseridoAposSolicitacao': true,
                    'InseridoAposSolicitacaoAprovado': false,
                    'Observacao': listaSubitem.filter(x => x.id == subitem.id)[0].observacao,
                    'IdUsuario': parseInt(usuario.id)
                }
            })

            let data = {
                'Id': 0,
                'IdAutorizacaoFornecimento': listaSubitemInsercao[0].IdAutorizacaoFornecimento,
                'IdSubitemCusto': listaSubitemInsercao[0].IdSubitemCusto,
                'Quantidade': listaSubitemInsercao[0].Quantidade,
                'Cancelado': false,
                'Finalizado': false,
                'InseridoAposSolicitacao': true,
                'InseridoAposSolicitacaoAprovado': false,
                ListaAutorizacaoFornecimentoSubitemCustoApiModel: listaSubitemInsercao,
                'Observacao': listaSubitem.filter(x => x.id == listaSubitemInsercao[0].IdSubitemCusto)[0].observacao,
                'IdUsuario': parseInt(usuario.id)
            }

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoComFotoRequest(data, idAutorizacaoFornecimento, contratoVersao.id,
                formData));
            setListaSubitem([]);
            onHide(true);
        }
    }

    function InserirSubitemSolicitacao() {

        var verificacao = true;

        listaSubitem.forEach(subitem => {

            if (subitem.quantidade > subitem.saldoContratoExcedente && contratoVersao != null && contratoVersao.liberaExcedenteGlobal == false &&
                contratoVersao.liberaExcedenteSubitem == false) {
                toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                verificacao = false;
                return;
            }
            if (subitem.quantidade == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }

        });

        if (numeroOrcamento.trim() == '' && solicitacaoFornecimento !== undefined) {
            if (solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento !== '') {
                setNumeroOrcamento(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento)
            }
            else {
                toast.error('Número Orçamento é obrigatório');
                verificacao = false;
                return;
            }
        }


        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    'Id': 0,
                    'IdSolicitacaoFornecimentoVersao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id,
                    'IdSubItemCusto': parseInt(subitem.id),
                    'Ativo': true,
                    'Quantidade': parseInt(subitem.quantidade),
                }
            })

            let data = {
                'Id': 0,
                'IdSolicitacaoFornecimentoVersao': listaSubitemInsercao[0].IdSolicitacaoFornecimentoVersao,
                'IdSubItemCusto': listaSubitemInsercao[0].IdSubItemCusto,
                'Ativo': listaSubitemInsercao[0].Ativo,
                'Quantidade': listaSubitemInsercao[0].Quantidade,
                ListaSolicitacaoFornecimentoVersaoSubitemCustoApiModel: listaSubitemInsercao,
                ListaSolicitacaoFornecimentoVersaoApiModel: {
                    'Id': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id,
                    'IdSolicitacaoFornecimento': solicitacaoFornecimento.id,
                    'Observacao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.observacao,
                    'NumeroOrcamento': numeroOrcamento !== '' ? numeroOrcamento : solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento,
                    'DataVersao': novaSolicitacao ? moment((new Date())).add('hours', 2) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.dataVersao,
                    'IdUsuario': usuario.id
                }
            }
            setDataSolicitacao(data);

            if (idSolicitacaoFornecimento !== undefined && idSolicitacaoFornecimento !== null && numeroOrcamento.trim() !== '' && numeroOrcamento !== solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento
                && solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento.trim() !== "") {
                setNovaSolictacao(true);
            }

            if (solicitacaoFornecimento !== null && solicitacaoFornecimento !== undefined &&
                numeroOrcamento.trim() !== '') {
                let solicitacaoCompleta = {
                    'Id': solicitacaoFornecimento.id,
                    'IdContratoVersao': solicitacaoFornecimento.idContratoVersao,
                    'IdTipoSolicitacao': solicitacaoFornecimento.idTipoSolicitacao,
                    'NumeroProcesso': solicitacaoFornecimento.numeroProcesso,
                    'NumeroOficio': solicitacaoFornecimento.numeroOficio,
                    'DescricaoSolicitacao': solicitacaoFornecimento.descricaoSolicitacao,
                    'Observacao': solicitacaoFornecimento.observacao,
                    'DataSolicitacao': solicitacaoFornecimento.dataSolicitacao,
                    'Endereco': solicitacaoFornecimento.endereco,
                    'Latitude': solicitacaoFornecimento.latitude,
                    'Longitude': solicitacaoFornecimento.longitude,
                    'IdRegiao': parseInt(solicitacaoFornecimento.idRegiao),
                    'IdUsuario': usuario.id,
                    'IdContrato': parseInt(solicitacaoFornecimento.idContrato),
                    'IdFornecedor': parseInt(solicitacaoFornecimento.idFornecedor),
                    'IdPrioridade': parseInt(solicitacaoFornecimento.idPrioridade),
                    'PrazoDias': parseInt(solicitacaoFornecimento.prazoDias),
                    'IdBairro': parseInt(solicitacaoFornecimento.idBairro),
                    'IdAutorizador': parseInt(solicitacaoFornecimento.idAutorizador),
                    'IdInteressado': parseInt(solicitacaoFornecimento.idInteressado),
                    'IdDestinatario': parseInt(solicitacaoFornecimento.idDestinatario),
                    'DDD': parseInt(solicitacaoFornecimento.ddd),
                    'Telefone': solicitacaoFornecimento.telefone,
                    'TipoSolicitacaoServico': tipoSolicitacaoCompleto != null ? tipoSolicitacaoCompleto.servico : false,
                    'DescricaoLocal': solicitacaoFornecimento.descricaoLocal,
                    'Assunto': solicitacaoFornecimento.assunto,
                    'SolicitacaoFornecimentoVersaoApiModel': {
                        'Id': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id,
                        'IdSolicitacaoFornecimento': solicitacaoFornecimento.id,
                        'Observacao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.observacao,
                        'NumeroOrcamento': numeroOrcamento !== '' ? numeroOrcamento : solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento,
                        'DataVersao': novaSolicitacao ? moment((new Date())).add('hours', 2) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.dataVersao,
                        'IdUsuario': usuario.id,
                    },
                    'SolicitacaoFornecimentoVersaoStatusApiModel': {
                        'Id': solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.id,
                        'IdSolicitacaoFornecimentoVersao': solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.idSolicitacaoFornecimentoVersao,
                        'IdStatusSolicitacaoFornecimento': solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.idStatusSolicitacaoFornecimento,
                        'IdUsuario': usuario.id,
                        'DataStatus': novaSolicitacao ? moment((new Date())).add('hours', 2) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.dataStatus,
                        'ObservacaoReprovado': solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.observacaoReprovado,
                        'ObservacaoCancelado': '',
                        'StatusReprovado': false,
                        'StatusAprovado': false,
                        'AnaliseConcluida': false
                    }
                }

                setDados(solicitacaoCompleta);

                if (idSolicitacaoFornecimento !== undefined && idSolicitacaoFornecimento !== null && numeroOrcamento.trim() !== '' && numeroOrcamento !== solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento
                    && solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento.trim() !== "") {
                    handleShow();
                } else if (idSolicitacaoFornecimento !== undefined && numeroOrcamento == solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento) {
                    dispatch(InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest(data, idSolicitacaoFornecimento, contratoVersao.id));
                    setListaSubitem([]);
                    onHide(true);
                    setNumeroOrcamento('');
                }
                else {
                    //dispatch(AlterarSolicitacaoFornecimentoRequest(solicitacaoCompleta));
                    dispatch(InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest(data, idSolicitacaoFornecimento, contratoVersao.id));
                    setListaSubitem([]);
                    onHide(true);
                    setNumeroOrcamento('');
                }
            }
        }
    }

    function InserirSubitemMedicao(event) {

        //event.preventDefault();
        var verificacao = true;

        listaSubitem.forEach(subitem => {

            if (subitem.quantidade > subitem.saldoContratoExcedente && contratoVersao != null && contratoVersao.liberaExcedenteGlobal == false &&
                contratoVersao.liberaExcedenteSubitem == false && listaContratoUsuario && listaContratoUsuario.filter(a => a.id == contratoVersao.idContrato && a.idUsuario == usuario.id)[0] &&
                listaContratoUsuario.filter(a => a.idContrato == contratoVersao.idContrato && a.idUsuario == usuario.id)[0].editaExcedenteSubitemAutorizacao === false) {
                toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                verificacao = false;
                return;
            }

            if (subitem.quantidade == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }

        });

        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                    DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                    Quantidade: parseFloat(subitem.quantidade),
                    IdSubItemCusto: parseInt(subitem.id),
                    Observacao: observacaoSubitem
                }
            })

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest(listaSubitemInsercao, idAuto, idContratoVersao));
            setListaSubitem([]);
            handleCloseConfirmacao();
            onHide(true);
        }
    }

    function InserirSubitemMedicaoComFoto(event) {

        event.preventDefault();
        var verificacao = true;
        var dados = null;

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdAutorizacaoFornecimentoSubitemCustoMedicao', null);
                formData.append('IdUsuario', usuario.id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setListaFile([]);
            handleCloseFotoMedicao();
        } else {
            toast.error('Para continuar insira um documento');
        }

        listaSubitem.forEach(subitem => {

            if (subitem.quantidade == 0) {
                toast.error('Item: ' + subitem.descricao + ' não pode ter quantidade zero');
                verificacao = false;
                return;
            }

        });

        if (verificacao && listaSubitem.length > 0) {

            var listaSubitemInsercao = listaSubitem.map((subitem) => {
                return {
                    IdMedicaoAutorizacaoFornecimento: parseInt(idMedicao),
                    DataUltimaAlteracao: moment((new Date())).add('hours', 2),
                    Quantidade: parseFloat(subitem.quantidade),
                    IdSubItemCusto: parseInt(subitem.id),
                    Observacao: observacaoSubitem
                }
            })

            dispatch(InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest(listaSubitemInsercao, idAuto, idContratoVersao, formData, dados));
            setListaSubitem([]);
            handleCloseConfirmacao();
            onHide(true);
        }
    }

    function LimparSubitens() {

        dispatch(InserirSolicitacaoFornecimentoVersaoSubitemCustoRequest(dataSolicitacao, idSolicitacaoFornecimento, contratoVersao.id));
        //dispatch(AlterarSolicitacaoFornecimentoRequest(dados));

        if (solicitacaoFornecimento !== undefined) {
            dispatch(ExcluirSolicitacaoFornecimentoVersaoSubitemCustoPorIdSolicitacaoRequest(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id, idSolicitacaoFornecimento))
        }

        /*if (idSolicitacaoFornecimento != undefined)
            dispatch(BuscarSolicitacaoFornecimentoPorIdRequest(idSolicitacaoFornecimento));*/


        setNumeroOrcamento('');
        setListaSubitem([]);
        handleClose();
        onHide(true);

        /*} else {
            toast.error('Para a nova versão o número de orçamento não pode ser vazio!');
        }*/
    }

    function ValidacaoInserirSubitemAutorizacao(){

        var valor = totalOrcamento.split('$', totalOrcamento.length);
        let valorOrcamento = parseFloat(valor[1]);
        var valorAdicionados = totalOrcamentoAdicionados.split('$', totalOrcamentoAdicionados.length)
        let valorTotalAdicionados = parseFloat(valorAdicionados[1]);
        var validacao = 0;
        
        listaSubitem.forEach(subitem => {
            valorTotalAdicionados += subitem.quantidade * subitem.valorUnitario;
        });

        listaSubitem.forEach(subitem => {

            if(subitem.quantidade > subitem.saldoContratoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao) {
                if(contratoVersao && valorOrcamento && valorTotalAdicionados){
                    let valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
                    if(valorTotalAdicionados > valorTotalExcedente){
                        toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                        return;
                    }
                    else{
                        validacao = validacao + 1;
                        handleShowFoto();
                        return;
                    }
                }
            }

        });

        if(validacao === 0){
            InserirSubitemAutorizacao();
        }
    }

    function ValidacaoInserirSubitemMedicao(){

        var valor = totalOrcamento.split('$', totalOrcamento.length);
        let valorComItensAdicionados = parseFloat(valor[1]);
        var valorOrcamento = 0.0;
        var validacao = 0;
        var totalItensSelecionados = 0.0;
        let valorTotalExcedente = 0.0;
        var validacaoExcedendoValor = 0;

        if(listaAutorizacaoFornecimentoSubitemCusto){
            listaAutorizacaoFornecimentoSubitemCusto.forEach(subitem => {
                if(subitem.inseridoAposSolicitacao == false){
                    valorOrcamento += subitem.quantidade * subitem.subitemCusto.valorUnitario;
                }
            })
        }

        if(contratoVersao){
            valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
        }

        listaSubitem.forEach(subitem => {
            totalItensSelecionados += subitem.valorTotal
        })

        var valorTodasMedicoes = 0.0;
            if(listaMedicaoAutorizacaoFornecimento){
                listaMedicaoAutorizacaoFornecimento.forEach(medicao => {
                    valorTodasMedicoes += medicao.valorTotalMedicao;
                });
            }

        var teste = totalItensSelecionados + valorTodasMedicoes;
        if(teste > valorTotalExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao){
            validacaoExcedendoValor = validacaoExcedendoValor + 1;
            /*toast.error('O valor total dos itens selecionados esta ultrapassando o saldo do contrato');
            return;*/
        }

        /*
        listaSubitem.forEach(subitem => {

            if(subitem.quantidade > subitem.saldoContratoExcedente && contratoVersao && contratoVersao.liberaExcedenteAutorizacao && medicaoAutorizacaoFornecimentoCompleto) {
                validacao = validacao + 1;
                if(contratoVersao && valorOrcamento){
                    valorTotalExcedente = valorOrcamento + valorOrcamento * contratoVersao.valorIndiceQuantidadeItemExcedente;
                    let valorTotalMed = (subitem.quantidade * subitem.valorUnitario) + valorComItensAdicionados;
                    if(valorTotalMed > valorTotalExcedente){
                        toast.error('Item: ' + subitem.descricao + ' esta ultrapassando o saldo do contrato');
                        return;
                    }
                    else{
                        setObservacao(observacaoSubitem);
                        handleShowFotoMedicao();
                        return;
                    }
                }
            }

        });*/

        if(/*validacao === 0*/ validacaoExcedendoValor === 1){
            setObservacao(observacaoSubitem);
            handleShowFotoMedicao();
        }
        else
        {
            InserirSubitemMedicao(dataMedicao);
        }

    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable='true'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        contratoVersao != null && contratoVersao.subitemCusto != null & contratoVersao.subitemCusto != undefined ?
                            <MaterialTable
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                title=''
                                columns={[
                                    {
                                        title: 'Código/Descrição Item', field: 'descricao', cellStyle: {
                                            width: idAutorizacaoFornecimento != undefined ? '15%' : '30%',
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: 'Quantidade', field: 'quantidade', align: 'left', type: "numeric", cellStyle: {
                                            width: '30%',
                                        }, validate: rowData => {
                                            if (rowData.quantidade === undefined || rowData.quantidade === "" || isNaN(rowData.quantidade)) {
                                                return "Campo Obrigatório"
                                            } else if (rowData.quantidadeMedicao.toString().length > 10) {
                                                return "A quantidade não pode conter mais que 10 dígitos"
                                            } else if (rowData.quantidadeMedicao > rowData.saldoAutorizacao) {
                                                return "Quantidade ultrapassou o saldo da autorização"
                                            }
                                            return true
                                        },
                                    },
                                    {
                                        title: 'Saldo Contrato (Excedente)', field: 'saldoContrato', cellStyle: {
                                            width: '10%'
                                        },
                                        editable: 'never',
                                        render: rowData => rowData.saldoContrato + ' (' + rowData.saldoContratoExcedente + ')'
                                    },
                                    {
                                        title: 'Unidade de Medida',
                                        field: 'unidadeMedida',
                                        cellStyle: {
                                            width: '10%'
                                        },
                                        editable: 'never'
                                    },
                                    {
                                        title: "Valor Unitário", field: 'valorUnitario', cellStyle: {
                                            width: '10%'
                                        }, editable: 'never',
                                        render: rowData =>
                                            rowData.valorUnitario === null ?
                                                null
                                                :
                                                rowData.valorUnitario.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: "Valor Total", field: 'valorTotal', cellStyle: {
                                            width: '10%'
                                        }, editable: 'never',
                                        render: rowData =>
                                            rowData.valorTotal === null ?
                                                null
                                                :
                                                rowData.valorTotal.toLocaleString('pt-br', {
                                                    style: 'currency', currency: 'BRL',
                                                    minimumFractionDigits: 4,
                                                    maximumFractionDigits: 4
                                                })
                                    },
                                    {
                                        title: 'Observação', field: 'observacao', cellStyle: {
                                            width: '15%'
                                        },
                                        hidden: idAutorizacaoFornecimento != undefined ? false : true,
                                        validate: rowData => {
                                            if (rowData.observacao != null && rowData.observacao != undefined && rowData.observacao.length > 500) {
                                                return "A observacao não pode conter mais que 500 dígitos"
                                            }
                                            return true
                                        },
                                        render: rowData => rowData.observacao == '' || rowData.observacao == undefined ?
                                            <div className="spanVazio"></div>
                                            : rowData.observacao
                                    }
                                ]}
                                data={listaDataCompleta}
                                options={{
                                    minBodyHeight: 500,
                                    showTextRowsSelected: false,
                                    paging: true,
                                    pageSize: 10, // Início
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [10, 50, 200, 300],
                                    filterRowStyle: {
                                        backgroundColor: "#FAFAFA"
                                    },
                                    headerStyle: {
                                        backgroundColor: '#454545',
                                        color: '#FFF',
                                        fontWeight: "bold"
                                    },
                                    actionsColumnIndex: -1,
                                    filtering: true,
                                    selection: true,
                                    selectionProps: rowData => ({
                                        color: 'primary'
                                    }),
                                    showSelectAllCheckbox: true
                                }}
                                cellEditable={{
                                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                        return new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                if (columnDef.field == 'quantidade') {
                                                    rowData.quantidade = newValue
                                                    rowData.valorTotal = newValue * rowData.valorUnitario
                                                }
                                                else {
                                                    rowData.observacao = newValue
                                                }
                                                rowData.tableData.checked = true;
                                                var novaLista = listaSubitem;
                                                var tem = novaLista.filter(a => a == rowData);
                                                if (tem.length == 0) {
                                                    novaLista.push(rowData)
                                                }
                                                Selecionar(novaLista);
                                                resolve()
                                            }, 1000)
                                        });
                                    }
                                }}
                                onSelectionChange={
                                    (rowData) =>
                                        Selecionar(rowData)
                                }
                                localization={{
                                    header: {
                                        actions: <span className="classHeaderTabela">Ações</span>
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nenhum registro para exibir',
                                        editRow: {
                                            deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                            saveTooltip: '',
                                            cancelTooltip: '',
                                        },
                                        deleteTooltip: '',
                                        editTooltip: '',
                                        addTooltip: '',
                                    },
                                    toolbar: {
                                        searchTooltip: '',
                                        searchPlaceholder: 'Pesquisar',
                                        exportTitle: "",
                                        exportCSVName: "Exportar como CSV",
                                        exportPDFName: "Exportar como PDF",
                                        nRowsSelected: '{0} linha(s) selecionada'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'linhas',
                                        labelDisplayedRows: '{count} de {from}-{to}',
                                        firstTooltip: '',
                                        previousTooltip: '',
                                        nextTooltip: '',
                                        lastTooltip: ''
                                    },
                                    grouping: {
                                        placeholder: 'Arraste a coluna aqui para agrupar',
                                        groupedBy: 'Agrupado por:'
                                    }
                                }}
                            />

                            :
                            <div className="divBody">
                                No momento não possui Itens para serem inseridos.
                            </div>
                    }
                    {
                        solicitacaoFornecimento !== null && idSolicitacaoFornecimento ?
                            <Form>
                                <br />
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formEndereco">
                                        <Form.Label>Número Orçamento</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroOrcamento}
                                            onChange={e => setNumeroOrcamento(e.target.value)}
                                            maxLength="50"
                                        />
                                    </Form.Group>
                                </Row>
                            </Form>
                            :
                            null
                    }
                </Modal.Body>
                <ModalFooter>
                    <Row className="mb-3">
                        <Button
                            type="submit"
                            variant="success"
                            onClick={() => idAutorizacaoFornecimento ? ValidacaoInserirSubitemAutorizacao() :
                                idSolicitacaoFornecimento ?
                                    InserirSubitemSolicitacao() : handleShowConfirmacao()}>
                            Adicionar Itens
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={12}>
                                Foi alterado o número de orçamento. Ao ser alterado, todos os itens do orçamento anterior serão excluídos. Deseja continuar?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={LimparSubitens}>
                                Sim
                            </Button>
                            :
                            ''

                    }
                    {
                        alterar == true ?
                            <Button variant="danger" onClick={handleClose}>
                                Não
                            </Button>
                            :
                            ''
                    }

                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmacao} onHide={handleCloseConfirmacao} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Também será criado um item na autorização, deseja continuar?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={observacaoSubitem}
                                        onChange={e => setObservacaoSubitem(e.target.value)}
                                        required
                                        maxLength="500"
                                        isInvalid={validated && observacaoSubitem.trim() === "" ? true : false}
                                        isValid={validated && observacaoSubitem.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmit}>
                        Sim
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirmacao}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFoto} onHide={handleCloseFoto}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Fotográfico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <span>O valor da obra foi excedido. Inclua uma foto e uma justificativa para esta ação</span><br/>
                            <Form.Group as={Col} md={12} controlId="formRegistroFotografico">
                                <div className="divModal"><br/>
                                    <DropzoneArea
                                        //initialFiles={[listaRegistroFotografico]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="success" disabled={listaFile.length > 0 ? false : true} 
                        onClick={InserirSubitemAutorizacaoComFoto}>Salvar Registro Fotográfico</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showFotoMedicao} onHide={handleCloseFotoMedicao}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Registro Fotográfico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Row className="mb-3">
                            <span>O valor da obra foi excedido. Inclua uma foto e uma justificativa para esta ação</span><br/>
                            <Form.Group as={Col} md={12} controlId="formRegistroFotografico">
                                <div className="divModal"><br/>
                                    <DropzoneArea
                                        //initialFiles={[listaRegistroFotografico]}
                                        onChange={(files) => setListaFile(files)}
                                        filesLimit={10}
                                        acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                        showPreviewsInDropzone={true}
                                        useChipsForPreview
                                        getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                        getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                        getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                        disableRejectionFeedback={true}
                                        dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        maxFileSize={30000000}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacao}
                                    onChange={e => setObservacao(e.target.value)} />
                                <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Button variant="success" disabled={listaFile.length > 0 ? false : true}
                        onClick={() => InserirSubitemMedicaoComFoto(dataMedicao)}>Salvar Registro Fotográfico</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}