export function ListarGrupoAcessoRequest(){
    return {
      type: 'LISTAR_GRUPO_ACESSO_REQUEST'
    }
  }
  

export function ListarGrupoAcessoResponse(listaGrupoAcesso){
  return {
    type: 'LISTAR_GRUPO_ACESSO_RESPONSE',
    listaGrupoAcesso
  }
}

export function InserirGrupoAcessoRequest(data){
  return {
    type: 'INSERIR_GRUPO_ACESSO_REQUEST',
    data
  }
}

export function AlterarGrupoAcessoRequest(data){
  return {
    type: 'ALTERAR_GRUPO_ACESSO_REQUEST',
    data
  }
}

export function ExcluirGrupoAcessoRequest(id){
  return {
    type: 'EXCLUIR_GRUPO_ACESSO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
