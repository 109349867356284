import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarBairroResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarBairroRequest() {

  try {

    //yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Bairro`, { headers: headerParams.token });

    yield put(ListarBairroResponse(result.data.result));
   // yield put(Carregando(true));  
    var teste = 'a'

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ListarBairroResponse([]));
    yield put(ErrorResponse(e));
    //yield put(Carregando(true));
    
  }
}

function* InserirBairroRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/Bairro`, model.data, { headers: headerParams.token });

    toast.success(`O Bairro foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/Bairro`, { headers: headerParams.token });

    yield put(ListarBairroResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarBairroRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/Bairro`, model.data, { headers: headerParams.token });

    toast.success(`Bairro foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/Bairro`, { headers: headerParams.token });

    yield put(ListarBairroResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirBairroRequest(model) {

  try {
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/Bairro/${model.id}`, { headers: headerParams.token });

    toast.success(`Bairro id: ${model.id} foi excluida!`);

    const result = yield call(api.get, `/api/Bairro`, { headers: headerParams.token });

    yield put(ListarBairroResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_BAIRRO_REQUEST', ListarBairroRequest),
  takeLatest('INSERIR_BAIRRO_REQUEST', InserirBairroRequest),
  takeLatest('ALTERAR_BAIRRO_REQUEST', AlterarBairroRequest),
  takeLatest('EXCLUIR_BAIRRO_REQUEST', ExcluirBairroRequest)
])