import produce from 'immer';

const initialState = {
  listaHistoricoAutorizacaoFornecimentoEmpenho: undefined,
  carregando: true
};

export default function historicoAutorizacaoFornecimentoEmpenho(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_HISTORICO_AUTORIZACAO_FORNECIMENTO_EMPENHO_RESPONSE':
      return produce(state, draft => {
        draft.listaHistoricoAutorizacaoFornecimentoEmpenho = action.listaHistoricoAutorizacaoFornecimentoEmpenho;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });     

    default:
      return state;
  }
}