export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(id) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST',
    id
  }
}

export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqResponse(listaAutorizacaoFornecimentoSubitemCustoMedicaoDocArq) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_RESPONSE',
    listaAutorizacaoFornecimentoSubitemCustoMedicaoDocArq
  }
}

export function InserirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(data, id) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST',
    data,
    id
  }
}

export function AlterarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(data, id) {
  return {
    type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST',
    data,
    id
  }
}

export function ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(id, idMedicao) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST',
    id,
    idMedicao
  }
}

export function BaixarAutorizacaoFornecimentoSubitemCustoMedicaoDocArqRequest(id) {
  return {
    type: 'BAIXAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_DOC_ARQ_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}