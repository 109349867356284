import produce from 'immer';

const initialState = {
  listaInteressado: undefined,
  listaInteressadoPorNome: undefined,
  carregando: true
};

export default function interessado(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_INTERESSADO_RESPONSE':
      return produce(state, draft => {
        draft.listaInteressado = action.listaInteressado;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    case 'BUSCAR_INTERESSADO_POR_NOME_RESPONSE':
      return produce(state, draft => {
        draft.listaInteressadoPorNome = action.listaInteressadoPorNome
      });    
    
    case 'LIMPAR_LISTA_INTERESSADO_POR_NOME':
      return produce(state, draft => {
        draft.listaInteressadoPorNome = undefined
      });      

    default:
      return state;
  }
}