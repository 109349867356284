import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
    Carregando, ListarAutorizacaoFornecimentoStatusResponse,
    ListarCompletoAutorizacaoFornecimentoStatusResponse,
    ModalConfimacaoStatusFinalizado
} from './actions';
import { ListarMedicaoAutorizacaoFornecimentoResponse } from '../medicaoAutorizacaoFornecimento/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var data = {
            'IdAutorizacaoFornecimento': model.IdAutorizacaoFornecimento
        }

        const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarAutorizacaoFornecimentoStatusResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ListarAutorizacaoFornecimentoStatusResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ListarCompletoAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        var data = {
            'IdAutorizacaoFornecimento': model.IdAutorizacaoFornecimento
        }

        const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse(result.data.result));
        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse([]));
        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* InserirAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        if (model.validacao) {

            yield call(api.post, `/api/AutorizacaoFornecimentoStatus`, model.data, { headers: headerParams.token });

            toast.success(`Status finalizado!`);

            var data = {
                'IdAutorizacaoFornecimento': model.IdAutorizacaoFornecimento
            }

            // Buscar lista de status
            const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

            yield put(ListarAutorizacaoFornecimentoStatusResponse(result.data.result));

            // Buscar lista de status completo para o histórico

            const resultCompleto = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

            yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

            // Listando medições atualizadas:
            const resultMedicao = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarStatusAtuais`,
                { IdAutorizacaoFornecimento: parseInt(model.IdAutorizacaoFornecimento) }, { headers: headerParams.token });

            yield put(ListarMedicaoAutorizacaoFornecimentoResponse(resultMedicao.data.result));

        } else {

            // Validar Saldo
            const resultValidar = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/verificarConsumidoAutorizacao`, model.data, { headers: headerParams.token });

            var valida = resultValidar.data.result;

            if (valida) {

                yield put(ModalConfimacaoStatusFinalizado(resultValidar.data.result));

            } else {

                yield call(api.post, `/api/AutorizacaoFornecimentoStatus`, model.data, { headers: headerParams.token });

                toast.success(`Status finalizado!`);

                var data = {
                    'IdAutorizacaoFornecimento': model.IdAutorizacaoFornecimento
                }

                // Buscar lista de status
                const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

                yield put(ListarAutorizacaoFornecimentoStatusResponse(result.data.result));

                // Buscar lista de status completo para o histórico

                const resultCompleto = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

                yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

                // Listando medições atualizadas:
                const resultMedicao = yield call(api.post, `/api/MedicaoAutorizacaoFornecimento/listarStatusAtuais`,
                    { IdAutorizacaoFornecimento: parseInt(model.IdAutorizacaoFornecimento) }, { headers: headerParams.token });

                yield put(ListarMedicaoAutorizacaoFornecimentoResponse(resultMedicao.data.result));
            }
        }

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`)
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* AlterarAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.put, `/api/AutorizacaoFornecimentoStatus`, model.data, { headers: headerParams.token });

        toast.success(`Status foi Alterado!`);

        var data = {
            'IdAutorizacaoFornecimento': model.IdAutorizacaoFornecimento
        }

        const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarAutorizacaoFornecimentoStatusResponse(result.data.result));

        // Buscar lista de status completo para o histórico

        const resultCompleto = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`)
        }
        else
            if (e.response.data.errors != null && e.response.data.errors != undefined) {
                let erros = '';
                let apiErrors = e.response.data.errors;
                for (var modelProperty in apiErrors) {
                    apiErrors[modelProperty].forEach(function (e) {
                        erros = erros + e + ' ';
                    });
                }
                toast.error(erros);
            } else {
                toast.error(e.response.data.message);
            }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

function* ExcluirAutorizacaoFornecimentoStatusRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.delete, `/api/AutorizacaoFornecimentoStatus/${model.id}`, { headers: headerParams.token });
        yield put(Carregando(true));

        toast.success(`O ultimo Status foi excluido!`);

        var data = {
            'IdAutorizacaoFornecimento': model.id
        }

        const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarAutorizacaoFornecimentoStatusResponse(result.data.result));

        // Buscar lista de status completo para o histórico

        const resultCompleto = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }

}

function* ExcluirAutorizacaoFornecimentoStatusReabrirAutorizacaoRequest(model) {

    try {

        yield put(Carregando(false));
        yield put(AtualizarToken());
        var headerParams = yield select((state) => state.global);

        yield call(api.delete, `/api/AutorizacaoFornecimentoStatus/ReabrirAutorizacao/${model.id}`, { headers: headerParams.token });
        yield put(Carregando(true));

        toast.success(`O ultimo Status foi excluido!`);

        var data = {
            'IdAutorizacaoFornecimento': model.id
        }

        const result = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/listarStatusAtuais`, data, { headers: headerParams.token });

        yield put(ListarAutorizacaoFornecimentoStatusResponse(result.data.result));

        // Buscar lista de status completo para o histórico

        const resultCompleto = yield call(api.post, `/api/AutorizacaoFornecimentoStatus/ListarCompleto`, data, { headers: headerParams.token });

        yield put(ListarCompletoAutorizacaoFornecimentoStatusResponse(resultCompleto.data.result));

        yield put(Carregando(true));

    } catch (e) {

        if (e.response == undefined || e.response.data.statusCode == 500) {
            history.push(`/erro`);
        } else if (e.response.data.statusCode != 404) {
            toast.error(e.response.data.message);
        }

        yield put(ErrorResponse(e));
        yield put(Carregando(true));
    }
}

export default all([
    takeLatest('LISTAR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ListarAutorizacaoFornecimentoStatusRequest),
    takeLatest('LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ListarCompletoAutorizacaoFornecimentoStatusRequest),
    takeLatest('INSERIR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', InserirAutorizacaoFornecimentoStatusRequest),
    takeLatest('ALTERAR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', AlterarAutorizacaoFornecimentoStatusRequest),
    takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST', ExcluirAutorizacaoFornecimentoStatusRequest),
    takeLatest('EXCLUIR_AUTORIZACAO_FORNECIMENTO_STATUS_REABRIR_AUTORIZACAO_REQUEST', ExcluirAutorizacaoFornecimentoStatusReabrirAutorizacaoRequest),
])