export function ListarFornecedorContatoRequest() {
  return {
    type: 'LISTAR_FORNECEDOR_CONTATO_REQUEST'
  }
}

export function ListarCompletoFornecedorContatoRequest(idFornecedor) {
  return {
    type: 'LISTAR_COMPLETO_FORNECEDOR_CONTATO_REQUEST',
    idFornecedor
  }
}

export function ListarFornecedorContatoResponse(listaFornecedorContato) {
  return {
    type: 'LISTAR_FORNECEDOR_CONTATO_RESPONSE',
    listaFornecedorContato
  }
}

export function BuscarFornecedorContatoPorIdRequest(id) {
  return {
    type: 'BUSCAR_FORNECEDOR_CONTATO_POR_ID_REQUEST',
    id
  }
}

export function BuscarFornecedorContatoPorIdResponse(fornecedorContatoCompleta) {
  return {
    type: 'BUSCAR_FORNECEDOR_CONTATO_POR_ID_RESPONSE',
    fornecedorContatoCompleta
  }
}

export function InserirFornecedorContatoRequest(data) {
  return {
    type: 'INSERIR_FORNECEDOR_CONTATO_REQUEST',
    data
  }
}

export function AlterarFornecedorContatoRequest(data) {
  return {
    type: 'ALTERAR_FORNECEDOR_CONTATO_REQUEST',
    data
  }
}

export function ExcluirFornecedorContatoRequest(id) {
  return {
    type: 'EXCLUIR_FORNECEDOR_CONTATO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}