import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarClienteResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarClienteRequest(){
  
  try{

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Cliente`, { headers: headerParams.token });

    yield put(ListarClienteResponse(result.data.result)); 
    yield put(Carregando(true));    
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 
  
    yield put(ListarClienteResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    

  }
}

export default all([
  takeLatest('LISTAR_CLIENTE_REQUEST', ListarClienteRequest)
])