import {
    IoBarChart, IoPersonSharp, IoPeopleSharp, IoDocumentText,
    IoResize, IoMapOutline, IoNewspaperOutline, IoPeopleOutline,
    IoFileTrayOutline, IoChatboxEllipsesOutline, IoTimeOutline,
    IoPersonCircleOutline
} from "react-icons/io5";
import {
    MdAttachMoney, MdSegment, MdAssignment,
    MdOutlineLocalShipping, MdCategory
} from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GrResources } from "react-icons/gr";
import { RiMenuAddLine, RiUser3Line, RiFileExcel2Line,
    RiBarChartGroupedLine, RiListCheck2, RiRefund2Line,
    RiFileChartFill, RiCalendarTodoFill, RiTodoLine } from "react-icons/ri";
import React, { useState } from 'react';
import * as RiIcons from 'react-icons/ri';
import { SiMicrosoftexcel } from "react-icons/si";

export const SidebarData = [
    {
        id: "menuLateral_dashboard",
        title: 'Dashboard',
        path: '/dashboard',
        icon: <IoBarChart className='menuLateral_icon' />,
        permissao: 'Dashboard',
    },
    {
        id: "menuLateral_usuario",
        title: 'Usuario',
        path: '/usuario',
        icon: <IoPersonSharp className='menuLateral_icon' />,
        permissao: 'Usuario',
    },
    {
        id: "menuLateral_grupos",
        title: 'Grupo Acesso',
        path: '/grupoAcesso',
        icon: <IoPeopleSharp className='menuLateral_icon' />,
        permissao: 'GrupoAcesso',
    },
    {
        id: "menuLateral_itenscontrato",
        title: 'Fonte Receita',
        path: '/fonteReceita',
        icon: <MdAttachMoney className='menuLateral_icon' />,
        permissao: 'FonteReceita',
    },
    {
        id: "menuLateral_itenscontrato",
        title: 'Fornecedor',
        path: '/fornecedor',
        icon: <MdOutlineLocalShipping className='menuLateral_icon' />,
        permissao: 'Fornecedor',
    },
    {
        id: "menuLateral_relatorio",
        title: 'Relatório',
        path: '#',
        icon: <SiMicrosoftexcel className='menuLateral_icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        permissao: 'Relatorio',

        subNav: [
            {
                title: 'Relatórios Gerais',
                path: '/relatorio',
                icon: <RiFileExcel2Line className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Relatório Solicitação',
                path: '/relatorioSolicitacao',
                icon: <RiFileChartFill className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Relatório Status Solicitação',
                path: '/relatorioStatusSolicitacao',
                icon: <IoResize className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Relatório Status Autorização',
                path: '/relatorioStatusAutorizacao',
                icon: <RiBarChartGroupedLine className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Relatório Status Medição',
                path: '/relatorioStatusMedicao',
                icon: <RiListCheck2 className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Relatório Status Empenho',
                path: '/relatorioStatusEmpenho',
                icon: <RiRefund2Line className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Obras/Interv. Por Mês',
                path: '/obrasIntervencoesPorMes',
                icon: <RiCalendarTodoFill className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            },
            {
                title: 'Manutenções Por Mês',
                path: '/manutencoesPorMes',
                icon: <RiTodoLine className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Relatorio'
            }
        ]
    },
    {
        id: "menuLateral_itenscontrato",
        title: 'Auxiliares',
        path: '#',
        icon: <MdSegment className='menuLateral_icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        permissao: 'Auxiliares',

        subNav: [
            {
                title: 'Unidade de Medida',
                path: '/unidadeMedida',
                icon: <IoResize className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Composição Custo',
                path: '/composicaoCusto',
                icon: <RiMenuAddLine className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Tipo Recurso',
                path: '/tipoRecurso',
                icon: <GrResources className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Regiao',
                path: '/regiao',
                icon: <IoMapOutline className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Tipo Solicitação',
                path: '/tipoSolicitacao',
                icon: <IoNewspaperOutline className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Tipo Documento Arquivo',
                path: '/tipoDocumentoArquivo',
                icon: <IoFileTrayOutline className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Prioridade',
                path: '/prioridade',
                icon: <IoTimeOutline className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Categoria Item',
                path: '/categoriaItem',
                icon: <MdCategory className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Interessado',
                path: '/interessado',
                icon: <RiUser3Line className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Órgão',
                path: '/orgao',
                icon: <IoPeopleOutline className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Assinante',
                path: '/assinante',
                icon: <IoPersonCircleOutline className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            },
            {
                title: 'Meses',
                path: '/meses',
                icon: <RiCalendarTodoFill className='menuLateral_sub_icon' />,
                cName: 'sub-nav',
                permissao: 'Auxiliares'
            }
        ]
    },
    {
        id: "menuLateral_contrato",
        title: 'Contrato',
        path: '/contrato',
        icon: <IoDocumentText className='menuLateral_icon' />,
        permissao: 'Contrato',
    },
    {
        id: "solicitacaoFornecimento",
        title: 'Solicitação Fornecimento',
        path: '/solicitacaoFornecimento',
        icon: <IoChatboxEllipsesOutline className='menuLateral_icon' />,
        permissao: 'SolicitacaoFornecimento',
    },
    {
        id: "autorizacaoFornecimento",
        title: 'Autorização Fornecimento',
        path: '/autorizacaoFornecimento',
        icon: <MdAssignment className='menuLateral_icon' />,
        permissao: 'AutorizacaoFornecimento',
    },
    {
        id: "medicaoAutorizacaoFornecimento",
        title: 'Medição Autorização F.',
        path: '/medicaoAutorizacaoFornecimento',
        icon: <MdAssignment className='menuLateral_icon' />,
        permissao: 'AutorizacaoFornecimento',
    },
    {
        id: "empenho",
        title: 'Empenho',
        path: '/empenho',
        icon: <FaMoneyCheckAlt className='menuLateral_icon' />,
        permissao: 'Empenho',
    },
    /* Caso precise de faturamento
    {
        id: "menuLateral_empenho",
        title: 'Empenho/Faturamento',
        path: '#',
        icon: <MdSegment className='menuLateral_icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,        

        subNav: [
            {
                id: "empenho",
                title: 'Empenho',
                path: '/empenho',
                icon: <FaMoneyCheckAlt className='menuLateral_sub_icon' />,
                permissao: 'Empenho',
            },
            {
                id: "faturamento",
                title: 'Faturamento',
                path: '/faturamento',
                icon: <MdOutlinePayments className='menuLateral_sub_icon' />,
                permissao: 'Faturamento',
            }
        ]
    }*/
];