export function RodarScriptTelefoneInteressado() {
  return {
    type: 'RODAR_SCRIPT_TELEFONE_INTERESSADO',
  }
}


export function CriarTxt(text) {
  return {
    type: 'CRIAR_TXT',
    text
  }
}

export function ErrorResponse(text) {
  return {
    type: 'ERROR_RESPONSE',
    text
  }
}

export function BaixarDocumentoRequest(id) {
  return {
    type: 'BAIXAR_DOCUMENTO_REQUEST',
    id
  }
}

export function AtualizarToken() {
  return {
    type: 'ATUALIZAR_TOKEN'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}
