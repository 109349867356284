export function ListarCnaeRequest() {
    return {
        type: 'LISTAR_CNAE_REQUEST'
    }
}

export function ListarCnaeResponse(listaCnae) {
    return {
        type: 'LISTAR_CNAE_RESPONSE',
        listaCnae
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}