import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { useEffect } from "react";
import img from '../../assets/login/img_login.png';
import cabecalho from '../../assets/novoCabecalhoCor.jpg';
import rodape from '../../assets/novoRodapeCor.jpg';
import moment from 'moment';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: '10',
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '15',
  },
  linha: {
    borderBottom: '1px solid #0000',
    width: '100%',
  },
  espaco: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    width: '100%',
  },
  section: {
    margin: 10,
    paddingLeft: 10,
    paddingRight: 10,
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },

  rowCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 2,
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    fontSize: '11',
  },
  bordaCompleta: {
    border: '1px solid #0000',
    margin: 1
  },
  comeco: {
    width: "25%",
  },

  meio: {
    width: "50%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },

  comecoMaior: {
    width: "40%",
  },
  meio: {
    width: "50%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },

  fim: {
    width: "35%",
  },
  linhaMetadeInicio: {
    width: "50%",
  },
  linhaMetadeFim: {
    width: "50%",
  },
  linhaMetade: {
    width: "45%",
  },
  linhaSemiCompleta: {
    width: "85%",
  },
  fimLinhaSemiCompleta: {
    width: "15%",
  },
  linhaCompleta: {
    width: "100%",
  },
  width8: {
    width: "8%"
  },
  width10: {
    width: "10%"
  },
  width20: {
    width: "20%"
  },
  cabecalho: {
    color: '#fff',
    backgroundColor: '#212529',
    bordercolor: '#32383e'
  },
  cinza: {
    backgroundColor: '#e9ecef',
  },
  logo: {
    width: '200',
    height: '100'
  },
  rodape: {
    width: '585',
    height: '50',
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',
    padding: '5',
  },
  top20: {
    top: '20'
  },
  top40: {
    top: '40'
  },
  top60: {
    top: '60'
  },
  recuo: {
    textIndent: '40px'
  },
  centralizarTexto: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
});

export default function PdfSolicitacaoFornecimento({ data }) {

  useEffect(() => {
    moment.locale('pt-br');
  }, [])

  return (
    <Document>
      {
        data != null ?

          <Page size="A4" style={styles.page}>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comecoMaior}></Text>
                <Text style={[styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={[styles.comeco, styles.bold]}>
                  OFÍCIO Nº: {data.numeroOficio}
                </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={[styles.fim, styles.bold]}>
                  Fortaleza, {moment(moment((data.data)).add('hours', 2)).format('LL')}
                </Text>
              </View>
              {data.destinatario == 'Sem Destinatário' ?
                <View></View>
                :
                <View>
                  <View style={[styles.rowCabecalho, styles.bold]} >
                    <Text style={styles.linhaMetadeInicio}>
                      Sr(a): {data.destinatario}
                    </Text>
                    <Text style={styles.meio}></Text>
                    <Text style={styles.fim}></Text>
                  </View>
                  <View style={[styles.rowCabecalho, styles.bold]} >
                    <Text style={styles.linhaMetadeInicio}>
                      Gerente de Contrato
                    </Text>
                    <Text style={styles.meio}></Text>
                    <Text style={styles.fim}></Text>
                  </View>
                  <View style={[styles.rowCabecalho, styles.bold]} >
                    <Text style={styles.linhaCompleta}>
                      Rua Padre Severiano - Messejana, Fortaleza - CE, 60871-565
                    </Text>
                    <Text style={styles.meio}></Text>
                    <Text style={styles.fim}></Text>
                  </View>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.rowCabecalho, styles.bold]} >
                    <Text style={[styles.linhaCompleta]}>
                      Prezado(a) Senhor(a).
                    </Text>
                  </View>
                  <View style={[styles.rowCabecalho, styles.bold]} >
                    <Text style={[styles.linhaCompleta]}>
                      Solicitamos análise e posterior orçamento, se necessário, do abaixo discriminado:
                    </Text>
                  </View>
                </View>
              }


              <View style={[styles.espaco]}></View>
              {
                data.interessado == 'Sem Interessado' ?
                  <View></View>
                  :
                  <View style={[styles.rowCabecalho]} >
                    <Text style={[styles.linhaCompleta]}>
                      INTERESSADO: {data.interessado}
                    </Text>
                  </View>
              }

              <View style={[styles.espaco]}></View>
              <View>
                <Text style={[styles.linhaCompleta]}>
                  1. PROCESSO Nº: {data.numeroProcesso}
                </Text>
              </View>
              <View style={[styles.rowCabecalho]} >
                <Text style={[styles.linhaCompleta]}>
                  ASSUNTO: {data.assunto}
                </Text>
              </View>

              <View style={[styles.rowCabecalho]} >
                <Text style={[styles.linhaCompleta]}>
                  OBS: {data.observacao}
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]} >
                <Text style={[styles.linhaCompleta]}>
                  LOCAL: {data.local + ', ' + data.bairro}
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              {
                data.interessado == 'Sem Interessado' ?
                  <View></View>
                  :
                  <View>
                    <View style={[styles.rowCabecalho]} >
                      <Text style={[styles.linhaCompleta]}>
                        CONTATO: {data.interessado}
                      </Text>
                    </View>
                    <View style={[styles.rowCabecalho]} >
                      <Text style={[styles.linhaCompleta]}>
                        {data.telefone ? 'Telefone/Celular : (' + data.ddd + ')' + ' ' + data.telefone + '  ' : ''}
                        {data.telefoneFixo ? 'Telefone : (' + data.dddFixo + ')' + ' ' + data.telefoneFixo + ' ' : ''}
                      </Text>
                    </View>
                  </View>
              }

              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]} >
                <Text style={styles.linhaSemiCompleta}>
                  Atenciosamente,
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  ________________________________________________________
                </Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  {data.autorizador}
                </Text>
              </View>
              <View style={[styles.rowCabecalho]} >
                <Text style={[styles.linhaSemiCompleta, styles.bold]}>
                  COORDENADOR DA COEIP
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
            </View>
            <View style={styles.alinharBottom}>
              <Image style={styles.rodape} src={rodape} />
            </View>
          </Page>
          :
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>
                ERRO AO GERAR PDF
              </Text>
            </View>
          </Page>

      }
    </Document >
  )
}