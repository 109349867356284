export function ListarAutorizacaoFornecimentoStatusRequest(IdAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    IdAutorizacaoFornecimento
  }
}

export function ListarAutorizacaoFornecimentoStatusResponse(listaAutorizacaoFornecimentoStatus) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE',
    listaAutorizacaoFornecimentoStatus
  }
}

export function ListarCompletoAutorizacaoFornecimentoStatusRequest(IdAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    IdAutorizacaoFornecimento
  }
}

export function ListarCompletoAutorizacaoFornecimentoStatusResponse(listaCompletoAutorizacaoFornecimentoStatus) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_STATUS_RESPONSE',
    listaCompletoAutorizacaoFornecimentoStatus
  }
}

export function InserirAutorizacaoFornecimentoStatusRequest(data, IdAutorizacaoFornecimento, validacao) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    data, IdAutorizacaoFornecimento, validacao
  }
}

export function AlterarAutorizacaoFornecimentoStatusRequest(data, IdAutorizacaoFornecimento) {
  return {
    type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    data, IdAutorizacaoFornecimento
  }
}

export function ExcluirAutorizacaoFornecimentoStatusRequest(id, IdAutorizacaoFornecimento) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_STATUS_REQUEST',
    id, IdAutorizacaoFornecimento
  }
}

export function ExcluirAutorizacaoFornecimentoStatusReabrirAutorizacaoRequest(id) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_STATUS_REABRIR_AUTORIZACAO_REQUEST',
    id
  }
}

export function ModalConfimacaoStatusFinalizado(modalConfirmacaoStatusFinalizado) {
  return {
    type: 'MODAL_CONFIRMACAO_STATUS_FINALIZADO',
    modalConfirmacaoStatusFinalizado
  }
}

export function LimparModalConfimacaoStatusFinalizado() {
  return {
    type: 'LIMPAR_MODAL_CONFIRMACAO_STATUS_FINALIZADO'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}