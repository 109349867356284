import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarEmpenhoDocumentoArquivoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import urlBase from '../../../services/rotas';

function* ListarEmpenhoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/EmpenhoDocumentoArquivo/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarEmpenhoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirEmpenhoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    var resultId = yield call(api.post, `/api/EmpenhoDocumentoArquivo`, model.data, { headers: headerParams.token });

    var lista = resultId.data.result;
    var listaId = '';
    lista.forEach(element => {
      listaId = listaId.concat(element + ' ');
    });

    toast.success('Documento id: ' + listaId + 'cadastrados com sucesso!');

    const result = yield call(api.post, `/api/EmpenhoDocumentoArquivo/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoDocumentoArquivoResponse(result.data.result));

    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarEmpenhoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/EmpenhoDocumentoArquivo`, model.data, { headers: headerParams.token });

    toast.success(`Documento foi atualizado com sucesso!`);

    const result = yield call(api.post, `/api/EmpenhoDocumentoArquivo/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirEmpenhoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false))
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/EmpenhoDocumentoArquivo/${model.id}/${'0'}`, { headers: headerParams.token });

    toast.success(`Empenho Documento Arquivo id: ${model.id} foi excluido!`);

    const result = yield call(api.post, `/api/EmpenhoDocumentoArquivo/listarCompleto`,
     { IdEmpenho: parseInt(model.idEmpenho) }, { headers: headerParams.token });

    yield put(ListarEmpenhoDocumentoArquivoResponse(result.data.result));
    yield put(Carregando(true))

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true))
  }
}

function* BaixarEmpenhoDocumentoArquivoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/EmpenhoDocumentoArquivo/${model.id}`, { headers: headerParams.token });

    const nomeArquivo = `${result.data.result.nomeArquivo}${result.data.result.descricaoExtensaoArquivo}`

    fetch(`${urlBase}/api/EmpenhoDocumentoArquivo/${model.id}/${'0'}/baixar`, { headers: headerParams.token })
      .then(response => response.blob())
      .then(function (myBlob) {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(myBlob);
        elem.download = nomeArquivo;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST', ListarEmpenhoDocumentoArquivoRequest),
  takeLatest('INSERIR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST', InserirEmpenhoDocumentoArquivoRequest),
  takeLatest('ALTERAR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST', AlterarEmpenhoDocumentoArquivoRequest),
  takeLatest('EXCLUIR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST', ExcluirEmpenhoDocumentoArquivoRequest),
  takeLatest('BAIXAR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST', BaixarEmpenhoDocumentoArquivoRequest)
])