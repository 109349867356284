export function ListarMesesRequest() {
    return {
      type: 'LISTAR_MESES_REQUEST'
    }
  }
  
  export function ListarMesesResponse(listaMeses) {
    return {
      type: 'LISTAR_MESES_RESPONSE',
      listaMeses
    }
  }

  export function InserirMesesRequest(data) {
    return {
      type: 'INSERIR_MESES_REQUEST',
      data
    }
  }
  
  export function AlterarMesesRequest(data) {
    return {
      type: 'ALTERAR_MESES_REQUEST',
      data
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }