export function ListarEmpenhoDocumentoArquivoRequest(idEmpenho) {
  return {
    type: 'LISTAR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST', idEmpenho
  }
}

export function ListarEmpenhoDocumentoArquivoResponse(listaEmpenhoDocumentoArquivo) {
  return {
    type: 'LISTAR_EMPENHO_DOCUMENTO_ARQUIVO_RESPONSE',
    listaEmpenhoDocumentoArquivo
  }
}

export function InserirEmpenhoDocumentoArquivoRequest(data, idEmpenho) {
  return {
    type: 'INSERIR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST',
    data, idEmpenho
  }
}

export function AlterarEmpenhoDocumentoArquivoRequest(data, idEmpenho) {
  return {
    type: 'ALTERAR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST',
    data, idEmpenho
  }
}

export function ExcluirEmpenhoDocumentoArquivoRequest(id, idEmpenho) {
  return {
    type: 'EXCLUIR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST',
    id, idEmpenho
  }
}

export function BaixarEmpenhoDocumentoArquivoRequest(id) {
  return {
    type: 'BAIXAR_EMPENHO_DOCUMENTO_ARQUIVO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}