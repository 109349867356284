import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarNotificacaoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarNotificacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/Notificacao/ListarCompleto`, { idUsuario: model.idUsuario },
      { headers: headerParams.token });

    yield put(ListarNotificacaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      //toast.error(e.response.data.message);
    }

    yield put(ListarNotificacaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* InserirNotificacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/Notificacao`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/Notificacao/ListarCompleto`, { 'IdUsuario': model.data.IdUsuario },
      { headers: headerParams.token });

    yield put(ListarNotificacaoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Uma notificação foi cadastrada com sucesso!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(Carregando(true));
  }
}

function* AlterarNotificacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/Notificacao`, model.data, { headers: headerParams.token });

    const result = yield call(api.post, `/api/Notificacao/ListarCompleto`, { 'IdUsuario': model.data.IdUsuario },
      { headers: headerParams.token });

    yield put(ListarNotificacaoResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    yield put(ListarNotificacaoResponse([]));
    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(Carregando(true));
  }
}

function* ExcluirNotificacaoRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/Notificacao/${model.id}`, { headers: headerParams.token });

    const result = yield call(api.post, `/api/Notificacao/ListarCompleto`, { 'IdUsuario': model.data.IdUsuario },
      { headers: headerParams.token });

    yield put(ListarNotificacaoResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Notificação id: ${model.id} foi excluido!`);

  } catch (e) {

    if (e.response == undefined || e.response.data.statusCode == 500) {
      history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
      toast.error(e.response.data.message);
    }

    yield put(Carregando(true));

  }
}

export default all([
  takeLatest('LISTAR_NOTIFICACAO_REQUEST', ListarNotificacaoRequest),
  takeLatest('INSERIR_NOTIFICACAO_REQUEST', InserirNotificacaoRequest),
  takeLatest('ALTERAR_NOTIFICACAO_REQUEST', AlterarNotificacaoRequest),
  takeLatest('EXCLUIR_NOTIFICACAO_REQUEST', ExcluirNotificacaoRequest)
])