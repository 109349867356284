export function ListarSolicitacaoFornecimentoHistoricoRequest(data) {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_REQUEST',
      data
    }
  }
  
  export function ListarSolicitacaoFornecimentoHistoricoResponse(listaSolicitacaoFornecimentoHistorico) {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_RESPONSE',
      listaSolicitacaoFornecimentoHistorico
    }
  }

  export function ListarSolicitacaoFornecimentoHistoricoComRedirecionamentoRequest(idSolicitacao) {
    return {
      type: 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_COM_REDIRECIONAMENTO_REQUEST',
      idSolicitacao
    }
  }
  
  export function Carregando(status) {
    return {
      type: 'CARREGANDO',
      status
    }
  }