import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RiFileChartFill } from "react-icons/ri";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { toast } from "react-toastify";
import { ListarRelatorioSolicitacaoRequest } from "../../../store/modules/relatorio/actions";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';

export default function RelatorioSolicitacao() {

    const dispatch = useDispatch();
    const history = useHistory();
    const carregando = useSelector(state => state.relatorio.carregando);
    const listaRelatorioSolicitacao = useSelector(state => state.relatorio.listaRelatorioSolicitacao);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    
    useEffect(() => {
        //Permissão de Visualizar
        if (token.role.filter(r => r == "RelatorioMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }
    }, [])

    useEffect(() => {

        dispatch(ListarRelatorioSolicitacaoRequest());
        
    }, [])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className="divHeader">
                                <Row>
                                    <Col md={{ span: 10 }}>
                                        <span>
                                            <RiFileChartFill size={25} color={"#000"} /> Relatório Solicitação
                                        </span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="divBody">
                                    <Row>
                                        <Col>
                                            {
                                                listaRelatorioSolicitacao ?
                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: '', field: 'Prioridade', filtering: false, sorting: false, export: false,
                                                                cellStyle: {
                                                                    width: '5%'
                                                                },
                                                                render: rowData =>
                                                                    <div
                                                                        style={{
                                                                            height: '25px',
                                                                            width: '15px',
                                                                            backgroundColor: rowData.Prioridade,
                                                                        }}></div>
                                                            },
                                                            {
                                                                title: 'Número da Solicitação', field: 'Id', editable: 'never', defaultSort:"desc",
                                                            },
                                                            {
                                                                title: 'Tipo Solicitação', field: 'TipoSolicitacao', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Assunto', field: 'Assunto', editable: 'never', 
                                                            },
                                                            {
                                                                title: 'Interessado', field: 'Interessado', editable: 'never',
                                                            },
                                                            {
                                                                title: 'Local', field: 'Local', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Data', field: 'Data', editable: 'never', type: 'date'
                                                            },
                                                            {
                                                                title: 'Fornecedor', field: 'Fornecedor', editable: 'never'
                                                            },
                                                            {
                                                                title: 'Valor Orçamento', field: 'ValorTotalOrcamento', editable: 'never',
                                                                type: 'currency', currencySetting:{ currencyCode:'BRL', locale: 'pt-br'},
                                                                align: 'left',
                                                            },
                                                        ]}
                                                        data={listaRelatorioSolicitacao ? 
                                                            listaRelatorioSolicitacao.map(lista =>{
                                                                return{
                                                                    Prioridade: lista.prioridade.corHex,
                                                                    Id: lista.id,
                                                                    Assunto: lista.assunto,
                                                                    Local: lista.descricaoLocal,
                                                                    Interessado: lista.interessado.nome,
                                                                    Data: lista.dataSolicitacao,
                                                                    TipoSolicitacao: lista.tipoSolicitacao ? 
                                                                        lista.tipoSolicitacao.descricao : '',
                                                                    Fornecedor: lista.fornecedor.nomeFornecedor,
                                                                    ValorTotalOrcamento: parseFloat(lista.valorTotalOrcamento),
                                                                }
                                                            })
                                                        :
                                                            []
                                                        }
                                                        options={{
                                                            exportButton: true,
                                                            exportAllData: true,
                                                            exportFileName: "RelatorioSolicitacao",
                                                            columnsButton: true,
                                                            minBodyHeight: 500,
                                                            maxBodyHeight: '500px',
                                                            paging: true,
                                                            pageSize: 50, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            }),
                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: ''
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                                addRemoveColumns: "Adicionar ou remover colunas"
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                :
                                                <Col md={6} style={{ margin: 'auto', display: 'grid' }}>
                                                    <Row style={{ margin: 'auto' }}>
                                                        <h5 style={{ textAlign: 'right' }}>Dados extensos! Isso pode levar alguns minutos...</h5>
                                                    </Row>
                                                    <Row style={{ margin: 'auto'}}>
                                                        <Loading/>
                                                    </Row>
                                                </Col>
                                                
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}