import produce from 'immer';

const initialState = {
  listaMeses: undefined,
  carregando: true
};

export default function meses(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MESES_RESPONSE':
      return produce(state, draft => {
        draft.listaMeses = action.listaMeses;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}