import produce from 'immer';

const initialState = {
  listaOrgao: undefined,
  listaOrgaoPorNome: undefined,
  carregando: true
};

export default function orgao(state = initialState, action) {


  switch (action.type) {

    case 'LISTAR_ORGAO_RESPONSE':
      return produce(state, draft => {
        draft.listaOrgao = action.listaOrgao;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    case 'BUSCAR_ORGAO_POR_NOME_RESPONSE':
      return produce(state, draft => {
        draft.listaOrgaoPorNome = action.listaOrgaoPorNome
      });

    case 'LIMPAR_LISTA_ORGAO_POR_NOME':
      return produce(state, draft => {
        draft.listaOrgaoPorNome = undefined
      });

    default:
      return state;
  }
}