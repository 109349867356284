export function ListarPrioridadeRequest() {
  return {
    type: 'LISTAR_PRIORIDADE_REQUEST'
  }
}

export function ListarPrioridadeResponse(listaPrioridade) {
  return {
    type: 'LISTAR_PRIORIDADE_RESPONSE',
    listaPrioridade
  }
}

export function InserirPrioridadeRequest(data) {
  return {
    type: 'INSERIR_PRIORIDADE_REQUEST',
    data
  }
}

export function AlterarPrioridadeRequest(data) {
  return {
    type: 'ALTERAR_PRIORIDADE_REQUEST',
    data
  }
}

export function ExcluirPrioridadeRequest(id) {
  return {
    type: 'EXCLUIR_PRIORIDADE_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}