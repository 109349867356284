import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import { ListarCompletoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/autorizacaoFornecimentoStatus/actions";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function HistoricoStatus() {

    const { idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();

    const listaCompletoAutorizacaoFornecimentoStatus = useSelector(state => state.autorizacaoFornecimentoStatus.listaCompletoAutorizacaoFornecimentoStatus);

    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {

        moment.locale('pt-br');

        if (idAutorizacaoFornecimento != undefined) {
            dispatch(ListarCompletoAutorizacaoFornecimentoStatusRequest(idAutorizacaoFornecimento));
        }

    }, [idAutorizacaoFornecimento])

    return (
        <>
            <div>
                <Col md={12}>
                    <MaterialTable
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        title=''
                        columns={[
                            {
                                title: 'Status', field: 'status', cellStyle: {
                                    width: '25%'
                                },
                                render: rowData =>
                                    rowData.statusCancela == true ?
                                        <div className="divStatusDanger">{rowData.status}</div>
                                        :
                                        <div className="divStatusGreen">{rowData.status}</div>
                            },
                            {
                                title: 'Observação', field: 'observacao', cellStyle: {
                                    width: '25%'
                                }
                            },
                            {
                                title: 'Data', field: 'dataStatus', defaultSort: 'desc', cellStyle: {
                                    width: '25%'
                                }
                            },
                            {
                                title: 'Nome usuário', field: 'usuario', cellStyle: {
                                    width: '25%'
                                }
                            }
                        ]}
                        data={
                            listaCompletoAutorizacaoFornecimentoStatus != undefined ?
                                listaCompletoAutorizacaoFornecimentoStatus.map(autorizacaoStatus => {
                                    return {
                                        status:
                                            autorizacaoStatus.statusAutorizacaoFornecimento != null ?
                                                autorizacaoStatus.statusAutorizacaoFornecimento.descricao : '',
                                        dataStatus: moment(autorizacaoStatus.dataStatus).format('L LTS'),
                                        usuario: autorizacaoStatus.usuario != null ? autorizacaoStatus.usuario.nome : '',
                                        observacao: autorizacaoStatus.observacao,
                                        statusCancela: autorizacaoStatus.statusAutorizacaoFornecimento.cancelaAutorizacao
                                    }
                                })
                                :
                                []
                        }
                        options={{
                            minBodyHeight: 500,
                            paging: true,
                            pageSize: 10, // Início
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [10, 50, 200, 300],
                            filterRowStyle: {
                                backgroundColor: "#FAFAFA"
                            },
                            headerStyle: {
                                backgroundColor: '#454545',
                                color: '#FFF',
                                fontWeight: "bold"
                            },
                            actionsColumnIndex: -1,
                            filtering: true,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        localization={{
                            header: {
                                actions: 'Ações'
                            },
                            body: {
                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                editRow: {
                                    deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                },
                                deleteTooltip: ''
                            },
                            toolbar: {
                                searchTooltip: '',
                                searchPlaceholder: 'Pesquisar',
                                exportTitle: "",
                                exportCSVName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                            },
                            pagination: {
                                labelRowsSelect: 'linhas',
                                labelDisplayedRows: '{count} de {from}-{to}',
                                firstTooltip: '',
                                previousTooltip: '',
                                nextTooltip: '',
                                lastTooltip: ''
                            },
                            grouping: {
                                placeholder: 'Arraste a coluna aqui para agrupar',
                                groupedBy: 'Agrupado por:'
                            }
                        }}
                    />
                </Col>
            </div>
        </>
    )
}