export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(id) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST',
    id
  }
}

export function ListarAutorizacaoFornecimentoSubitemCustoMedicaoResponse(listaAutorizacaoFornecimentoSubitemCustoMedicao) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_RESPONSE',
    listaAutorizacaoFornecimentoSubitemCustoMedicao
  }
}

export function InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest(data, idAutorizacaoFornecimento) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST',
    data,
    idAutorizacaoFornecimento
  }
}

export function AlterarAutorizacaoFornecimentoSubitemCustoMedicaoRequest(data, idAutorizacaoFornecimento) {
  return {
    type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST',
    data,
    idAutorizacaoFornecimento
  }
}

export function ExcluirAutorizacaoFornecimentoSubitemCustoMedicaoRequest(data) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_REQUEST',
    data
  }
}

export function BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdRequest(idAutorizacaoFornecimentoSubitemCustoMedicao) {
  return {
    type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_POR_ID_REQUEST',
    idAutorizacaoFornecimentoSubitemCustoMedicao
  }
}

export function BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdResponse(autorizacaoFornecimentoSubitemCustoMedicaoCompleto) {
  return {
    type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_POR_ID_RESPONSE',
    autorizacaoFornecimentoSubitemCustoMedicaoCompleto
  }
}


export function InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoRequest(data, idAutorizacaoFornecimento, idContratoVersao) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_SEM_AUTORIZACAO_REQUEST',
    data,
    idAutorizacaoFornecimento, idContratoVersao
  }
}

export function InserirAutorizacaoFornecimentoSubitemCustoMedicaoSemAutorizacaoComFotoRequest(data, idAutorizacaoFornecimento, idContratoVersao, formData, dados, valorSubitemMedicoes, valorEcedente) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_SUBITEM_CUSTO_MEDICAO_SEM_AUTORIZACAO_COM_FOTO_REQUEST',
    data,
    idAutorizacaoFornecimento, idContratoVersao, formData, dados, valorSubitemMedicoes, valorEcedente
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}