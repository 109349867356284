export function ListarComposicaoCustoRequest() {
  return {
    type: 'LISTAR_COMPOSICAO_CUSTO_REQUEST'
  }
}

export function ListarComposicaoCustoResponse(listaComposicaoCusto) {
  return {
    type: 'LISTAR_COMPOSICAO_CUSTO_RESPONSE',
    listaComposicaoCusto
  }
}

export function InserirComposicaoCustoRequest(data) {
  return {
    type: 'INSERIR_COMPOSICAO_CUSTO_REQUEST',
    data
  }
}

export function AlterarComposicaoCustoRequest(data) {
  return {
    type: 'ALTERAR_COMPOSICAO_CUSTO_REQUEST',
    data
  }
}

export function ExcluirComposicaoCustoRequest(id) {
  return {
    type: 'EXCLUIR_COMPOSICAO_CUSTO_REQUEST',
    id
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}