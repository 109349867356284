import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { ListarContratoFornecedorResponse, BuscarContratoFornecedorPorIdResponse, Carregando } from './actions';
import history from '../../../services/history';
import api from '../../../services/api';

function* ListarContratoFornecedorRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/ContratoFornecedor/`, { headers: headerParams.token });

    yield put(ListarContratoFornecedorResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ListarContratoFornecedorResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* ListarCompletoContratoFornecedorRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.post, `/api/ContratoFornecedor/listarCompleto`,
      { IdContrato: model.idContratoVersao }, { headers: headerParams.token });

    yield put(ListarContratoFornecedorResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }   

    yield put(ListarContratoFornecedorResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));

  }
}

function* BuscarContratoFornecedorPorIdRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/ContratoFornecedor/${model.id}`, { headers: headerParams.token });

    yield put(BuscarContratoFornecedorPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }  

    yield put(ErrorResponse(e));
    yield put(BuscarContratoFornecedorPorIdResponse(null));
    yield put(Carregando(true));
  }
}

function* InserirContratoFornecedorRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.post, `/api/ContratoFornecedor`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/ContratoFornecedor`, { headers: headerParams.token });

    yield put(ListarContratoFornecedorResponse(result.data.result));
    yield put(Carregando(true));

    toast.success(`Fornecedor foi inserido com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarContratoFornecedorRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.put, `/api/ContratoFornecedor`, model.data, { headers: headerParams.token });

    toast.success(`Fornecedor foi alterado com sucesso!`);

    const result = yield call(api.get, `/api/ContratoFornecedor/${model.data.Id}`, { headers: headerParams.token });

    yield put(BuscarContratoFornecedorPorIdResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`)
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirContratoFornecedorRequest(model) {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/ContratoFornecedor/${model.id}`, { headers: headerParams.token });

    toast.success(`O Fornecedor foi excluido com sucesso!`);

    const result = yield call(api.get, `/api/ContratoFornecedor`, { headers: headerParams.token });

    yield put(ListarContratoFornecedorResponse(result.data.result));
    yield put(Carregando(true));

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_CONTRATO_FORNECEDOR_REQUEST', ListarContratoFornecedorRequest),
  takeLatest('BUSCAR_CONTRATO_FORNECEDOR_POR_ID_REQUEST', BuscarContratoFornecedorPorIdRequest),
  takeLatest('INSERIR_CONTRATO_FORNECEDOR_REQUEST', InserirContratoFornecedorRequest),
  takeLatest('ALTERAR_CONTRATO_FORNECEDOR_REQUEST', AlterarContratoFornecedorRequest),
  takeLatest('EXCLUIR_CONTRATO_FORNECEDOR_REQUEST', ExcluirContratoFornecedorRequest),
  takeLatest('LISTAR_COMPLETO_CONTRATO_FORNECEDOR_REQUEST', ListarCompletoContratoFornecedorRequest)
])