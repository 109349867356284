import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { useEffect } from "react";
import img from '../../assets/login/img_login.png';
import moment from 'moment';
import cabecalho from '../../assets/novoCabecalhoCor.jpg';
import rodape from '../../assets/novoRodapeCor.jpg';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    fontSize: '10',
    paddingBottom: 65,
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '15',
  },
  linha: {
    borderBottom: '1px solid #0000',
    width: '100%',
  },
  espaco: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    width: '100%',
  },
  section: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },

  rowCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
  },
  bold: {
    fontWeight: 'bold',
  },
  bordaCompleta: {
    border: '1px solid #0000',
    margin: 1
  },
  comeco: {
    width: "25%",
  },

  meio: {
    width: "50%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  linhaCompletaCentralizada: {
    width: "100%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  fim: {
    width: "25%",
  },
  linhaMetadeComeco: {
    width: "50%",
  },
  linhaMetadeFim: {
    width: "50%",
  },
  linhaSemiCompleta: {
    width: "85%",
  },
  fimLinhaSemiCompleta: {
    width: "15%",
  },
  linhaCompleta: {
    width: "100%",
  },
  width8: {
    width: "8%"
  },
  width10: {
    width: "10%"
  },
  width35: {
    width: "35%"
  },
  width15: {
    width: "15%"
  },
  width20: {
    width: "20%"
  },
  width80: {
    width: "80%"
  },
  cabecalho: {
    color: '#fff',
    backgroundColor: '#212529',
    bordercolor: '#32383e'
  },
  cinza: {
    backgroundColor: '#e9ecef',
  },
  logo: {
    width: '200',
    height: '100'
  },
  top20: {
    top: '20'
  },
  top40: {
    top: '40'
  },
  top60: {
    top: '60'
  },
  recuo: {
    textIndent: '40px'
  },
  centralizarTexto: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',

  },
  bottom5: {
    marginBottom: '5px'
  },
  bottom20: {
    marginBottom: '20px'
  },
  bottom65: {
    marginBottom: '65px'
  },
  bottom40: {
    marginBottom: '40px'
  },
  fontSize5: {
    fontSize: '5'
  },
  fontSize65: {
    fontSize: '6.5'
  },
  fontSize4: {
    fontSize: '4'
  },
  fontSize8: {
    fontSize: '8'
  },
  fontSize45: {
    fontSize: '4.5'
  },
  fontSize2: {
    fontSize: '2'
  },
  width50: {
    width: '50'
  },
  width5: {
    width: '5%'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  comecoMaior: {
    width: "40%",
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',
    padding: '5',
  },
  rodape: {
    width: '585',
    height: '50',
  },
});


export default function PdfEmpenhoBoletimMedicao({ data }) {

  useEffect(() => {
    moment.locale('pt-br');
  }, [])

  return (
    <Document>
      {
        data && data.listaAutorizacaoFornecimentoBoletimMedicao.length > 0 ?
          data.listaAutorizacaoFornecimentoBoletimMedicao != null && data.listaAutorizacaoFornecimentoBoletimMedicao.length > 0 ?
            data.listaAutorizacaoFornecimentoBoletimMedicao.map((c, i) => (
              // Página Resumo
              <Page size="A4" style={styles.page} fixed>
                <View style={[styles.section]}>
                  <View style={[styles.rowCabecalho]}>
                    <Text style={styles.comeco}></Text>
                    <Text style={[styles.meio]}>
                      <Image style={styles.logo} src={cabecalho} />
                    </Text>
                    <Text style={styles.fim}></Text>
                  </View>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.rowCabecalho]}>
                    <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                      Boletim de Medição
                    </Text>
                  </View>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.espaco]}></View>

                  {/* CABEÇALHO */}
                  <View style={[styles.rowCabecalho]} fixed>
                    <Text style={styles.comeco}>
                      AF Nº: {c.numeroAutorizacaoFornecimento}
                    </Text>
                    <Text style={[styles.meio]}></Text>
                    <Text style={styles.fim}>
                      Status: {c.status}
                    </Text>
                  </View>
                  <View style={[styles.rowCabecalho]}>
                    <Text style={styles.comeco}>
                      Processo Nº: {c.numeroProcesso}
                    </Text>
                    <Text style={[styles.meio]}></Text>
                    <Text style={styles.fim}>
                      Período de: {moment(c.periodo).format('L')}
                    </Text>
                  </View>
                  <View style={[styles.rowCabecalho]}>
                    <Text style={styles.linhaCompleta}>
                      Obras: {c.obra}
                    </Text>
                  </View>
                  {/* CABEÇALHO FIM */}

                  {/* TABELA */}
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.espaco]}></View>
                  <View style={styles.table}>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.linha]}></View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                      <Text style={styles.width10}>Item</Text>
                      <Text style={styles.width20}>Desc. Atividade</Text>
                      <Text style={styles.width10}>Un</Text>
                      <Text style={styles.width10}>V. Unit. (R$)</Text>
                      <Text style={styles.width10}>Qt. Contratada</Text>
                      <Text style={styles.width10}>Total</Text>
                      <Text style={styles.width10}>Anterior</Text>
                      <Text style={styles.width10}>No Periodo</Text>
                      <Text style={styles.width10}>R$</Text>
                    </View>
                    <View key={i} style={[styles.linha]}></View>
                    {
                      c.listaSubItemsMedicao != null && c.listaSubItemsMedicao.length > 0 ?
                        c.listaSubItemsMedicao.map((s, i) => (
                          <View>
                            {
                              (i + 1) % 2 == 0
                                ?
                                <View key={i} style={styles.row} break>
                                  <Text style={styles.width10}>{s.item}</Text>
                                  <Text style={[styles.width20, styles.fontSize8]}>{s.descricaoAtividade}</Text>
                                  <Text style={styles.width10}>{s.unidade}</Text>
                                  <Text style={styles.width10}>{s.valorUnidade}</Text>
                                  <Text style={styles.width10}>{s.quantidadeContratada}</Text>
                                  <Text style={styles.width10}>{s.total}</Text>
                                  <Text style={styles.width10}>{s.anterior}</Text>
                                  <Text style={styles.width10}>{s.numeroPeriodo}</Text>
                                  <Text style={styles.width10}>{s.valorUnitario}</Text>
                                </View>
                                :
                                <View key={i} style={[styles.row, styles.cinza]} break>
                                  <Text style={styles.width10}>{s.item}</Text>
                                  <Text style={[styles.width20, styles.fontSize8]}>{s.descricaoAtividade}</Text>
                                  <Text style={styles.width10}>{s.unidade}</Text>
                                  <Text style={styles.width10}>{s.valorUnidade}</Text>
                                  <Text style={styles.width10}>{s.quantidadeContratada}</Text>
                                  <Text style={styles.width10}>{s.total}</Text>
                                  <Text style={styles.width10}>{s.anterior}</Text>
                                  <Text style={styles.width10}>{s.numeroPeriodo}</Text>
                                  <Text style={styles.width10}>{s.valorUnitario}</Text>
                                </View>

                            }
                            <View style={[styles.linha]}></View>
                          </View>
                        ))
                        :
                        ''
                    }
                  </View>
                  { /* TOTAIS */}
                  <View style={[styles.table, styles.fontSize8]}>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.linha]}></View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>Total da Medição (R$)</Text>
                      <Text style={styles.width20}>{c.totalMedicaoString}</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>Total Contrato</Text>
                      <Text style={styles.width20}>{c.totalContratoString}</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>Total Faturado</Text>
                      <Text style={styles.width20}>{c.totalFaturadoString}</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>Saldo Contratual</Text>
                      <Text style={styles.width20}>{c.totalContratualString}</Text>
                    </View>
                    <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                      <Text style={styles.width80}>Realizado (%)</Text>
                      <Text style={styles.width20}>{c.totalRealizadoString}</Text>
                    </View>
                  </View>
                  { /* FIM TOTAIS */}
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.espaco]}></View>
                  {/* TABELA FIM */}

                  {/* VISTOS */}
                  {/* }
                  <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom65]}>
                    <Text style={styles.comeco}>VISTOS:</Text>
                    <Text style={[styles.meio]}></Text>
                    <Text style={styles.fim}></Text>
                  </View>
                  { */}
                  <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom20, styles.centralizarTexto, styles.fontSize8]}>
                    <Text style={[styles.linhaMetadeComeco]}>
                      _____________________________________
                    </Text>
                    <Text style={[styles.linhaMetadeFim]}>
                      _____________________________________
                    </Text>
                  </View>
                  <View style={[styles.rowCabecalho, styles.alinharBottom, styles.centralizarTexto, styles.fontSize8]}>
                    <Text style={[styles.linhaMetadeComeco]}>
                      FISCALIZAÇÃO
                    </Text>
                    <Text style={[styles.linhaMetadeFim]}>
                      FM RODRIGUES & CIA LTDA.
                    </Text>
                  </View>
                  {/* FIM VISTOS */}
                </View>
                <Text style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
                {/* <View style={styles.alinharBottom}>
                  <Image style={styles.rodape} src={rodape} />
                </View> */}
              </Page>
            ))
            :
            ''
          :
            <Page size="A4" style={styles.page} fixed>
              <View style={[styles.section]}>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.comeco}></Text>
                  <Text style={[styles.meio]}>
                    <Image style={styles.logo} src={cabecalho} />
                  </Text>
                  <Text style={styles.fim}></Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.rowCabecalho]}>
                  <Text style={[styles.titulo, styles.linhaCompletaCentralizada]}>
                    Boletim de Medição
                  </Text>
                </View>
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>

                {/* CABEÇALHO */}
                <View style={[styles.rowCabecalho]} fixed>
                  <Text style={styles.comeco}>
                    AF Nº:
                  </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={styles.fim}>
                    Status:
                  </Text>
                </View>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.comeco}>
                    Processo Nº:
                  </Text>
                  <Text style={[styles.meio]}></Text>
                  <Text style={styles.fim}>
                    Data Empenho: {moment(data.dataEmpenho).format('L')}
                  </Text>
                </View>
                <View style={[styles.rowCabecalho]}>
                  <Text style={styles.linhaCompleta}>
                    Obras:
                  </Text>
                </View>
                {/* CABEÇALHO FIM */}

                {/* TABELA */}
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                <View style={styles.table}>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.linha]}></View>
                  <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto, styles.fontSize5]} >
                    <Text style={styles.width10}>Item</Text>
                    <Text style={styles.width20}>Desc. Atividade</Text>
                    <Text style={styles.width10}>Un</Text>
                    <Text style={styles.width10}>V. Unit. (R$)</Text>
                    <Text style={styles.width10}>Qt. Contratada</Text>
                    <Text style={styles.width10}>Total</Text>
                    <Text style={styles.width10}>Anterior</Text>
                    <Text style={styles.width10}>No Periodo</Text>
                    <Text style={styles.width10}>R$</Text>
                  </View>
                  <View style={[styles.linha]}></View>

                  <View style={[styles.row, styles.cinza]} break>
                    <Text style={[styles.linhaCompletaCentralizada, styles.fontSize65]}>Nenhum resultado encontrado</Text>
                  </View>

                </View>

                { /* TOTAIS */}
                <View style={[styles.table, styles.fontSize8]}>
                  <View style={[styles.espaco]}></View>
                  <View style={[styles.linha]}></View>
                  <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                    <Text style={styles.width80}>Total da Medição (R$)</Text>
                    <Text style={styles.width20}>R$ 0,00</Text>
                  </View>
                  <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                    <Text style={styles.width80}>Total Contrato</Text>
                    <Text style={styles.width20}>R$ 0,00</Text>
                  </View>
                  <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                    <Text style={styles.width80}>Total Faturado</Text>
                    <Text style={styles.width20}>R$ 0,00</Text>
                  </View>
                  <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                    <Text style={styles.width80}>Saldo Contratual</Text>
                    <Text style={styles.width20}>R$ 0,00</Text>
                  </View>
                  <View style={[styles.row, styles.bold, styles.cabecalho, styles.centralizarTexto]} >
                    <Text style={styles.width80}>Realizado (%)</Text>
                    <Text style={styles.width20}>0,00 %</Text>
                  </View>
                </View>
                { /* FIM TOTAIS */}
                <View style={[styles.espaco]}></View>
                <View style={[styles.espaco]}></View>
                {/* TABELA FIM */}

                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.bottom20, styles.centralizarTexto, styles.fontSize8]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    _____________________________________
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    _____________________________________
                  </Text>
                </View>
                <View style={[styles.rowCabecalho, styles.alinharBottom, styles.centralizarTexto, styles.fontSize8]}>
                  <Text style={[styles.linhaMetadeComeco]}>
                    FISCALIZAÇÃO
                  </Text>
                  <Text style={[styles.linhaMetadeFim]}>
                    FM RODRIGUES & CIA LTDA.
                  </Text>
                </View>
                {/* FIM VISTOS */}
              </View>
              <Text style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
              {/* <View style={styles.alinharBottom}>
                <Image style={styles.rodape} src={rodape} />
              </View> */}
            </Page>
      }

    </Document >
  )
}