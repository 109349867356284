import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { ListarClienteRequest } from '../../../store/modules/cliente/actions';
import { useDispatch, useSelector } from "react-redux";
import { InserirFornecedorRequest, AlterarFornecedorRequest, BuscarFornecedorPorIdRequest, ListarFornecedorRequest } from "../../../store/modules/fornecedor/actions";
import { ListarMunicipiosRequest } from "../../../store/modules/municipio/actions";
import { ListarTipoPessoaRequest } from "../../../store/modules/tipoPessoa/actions";
import { useParams } from 'react-router-dom';
import InputMask from "react-input-mask";
import 'moment/locale/pt-br';
import moment from 'moment';



export default function Fornecedor() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);

    const listaCliente = useSelector(state => state.cliente.listaCliente);
    const listaTipoPessoa = useSelector(state => state.tipoPessoa.listaTipoPessoa);
    const listaMunicipio = useSelector(state => state.municipio.listaMunicipio);
    const fornecedorCompleto = useSelector(state => state.fornecedor.fornecedorCompleto);

    const [idCliente, setIdCliente] = useState('');
    const [idTipoPessoa, setIdTipoPessoa] = useState('');
    const [nomeFornecedor, setNomeFornecedor] = useState('');
    const [descricaoSite, setDescricaoSite] = useState('');
    const [numeroIdentidade, setNumeroIdentidade] = useState('');
    const [descricaoOrgaoEmissor, setDescricaoOrgaoEmissor] = useState('');
    const [numeroCpf, setNumeroCpf] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [numeroCgc, setNumeroCgc] = useState('');
    const [numeroInscricaoEstadual, setNumeroInscricaoEstadual] = useState('');
    const [email, setEmail] = useState('');
    const [dddFixo, setDddFixo] = useState('');
    const [dddCelular, setDddCelular] = useState('');
    const [telefoneFixo, setTelefoneFixo] = useState('');
    const [telefoneCelular, setTelefoneCelular] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numeroLocal, setNumeroLocal] = useState('');
    const [descricaoComplemento, setDescricaoComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cep, setCep] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [ativo, setAtivo] = useState(false);
    const [validated, setValidated] = useState(false);

    const lista = listaFornecedor != undefined ?
        listaFornecedor.map(fornecedor => {
            return {
                nomeFornecedor: fornecedor.nomeFornecedor
            }
        })
        :
        []

    useEffect(() => {
        
        moment.locale('pt-br');

        dispatch(ListarClienteRequest());
        dispatch(ListarMunicipiosRequest());
        dispatch(ListarFornecedorRequest());
        dispatch(ListarTipoPessoaRequest());
        if (id != undefined)
            dispatch(BuscarFornecedorPorIdRequest(id));
    }, [])

    useEffect(() => {
        if (fornecedorCompleto != null && fornecedorCompleto.id == parseInt(id)) {
            carregaFornecedor();
        }
    }, [fornecedorCompleto])

    function InserirFornecedor(e) {

        e.preventDefault();

        var data = {
            'Id': fornecedorCompleto == null ? parseInt(0) : fornecedorCompleto.id,
            'IdCliente': parseInt(idCliente),
            'NomeFornecedor': nomeFornecedor.trim(),
            'Site': descricaoSite,
            'Email': email,
            'DddTelefoneFixo': parseInt(dddFixo),
            'DddTelefoneCelular': parseInt(dddCelular),
            'IdTipoPessoa': idTipoPessoa,
            'NumeroTelefoneFixo': telefoneFixo,
            'NumeroCelular': telefoneCelular,
            'NomeLogradouro': logradouro,
            'NumeroLocal': numeroLocal,
            'Complemento': descricaoComplemento,
            'Cep': cep,
            'Bairro': bairro,
            'IdMunicipio': parseInt(municipio),
            'Ativo': ativo,
            'DataInclusao': moment((new Date())).add('hours', 2),
            'DataAlteracao': moment((new Date())).add('hours', 2),
            'FornecedorPessoaFisicaApiModel': {
                'Id': fornecedorCompleto == null ? parseInt(0) : fornecedorCompleto.fornecedorPessoaFisicaApiModel == null
                    ? parseInt(0) : fornecedorCompleto.fornecedorPessoaFisicaApiModel.id,
                'IdFornecedor': id == undefined ? parseInt(0) : parseInt(id),
                'NumeroIdentidade': numeroIdentidade,
                'OrgaoEmissor': descricaoOrgaoEmissor,
                'NumeroCpf': numeroCpf
            },
            'FornecedorPessoaJuridicaApiModel': {
                'Id': fornecedorCompleto == null ? parseInt(0) :
                    fornecedorCompleto.fornecedorPessoaJuridicaApiModel == null
                        ? parseInt(0) : fornecedorCompleto.fornecedorPessoaJuridicaApiModel.id,
                'IdFornecedor': id == undefined ? parseInt(0) : parseInt(id),
                'nomeFantasia': nomeFantasia,
                'numeroCgc': numeroCgc,
                'numeroInscricaoEstadual': numeroInscricaoEstadual
            }
        }

        if (id == undefined) {
            dispatch(InserirFornecedorRequest(data));
        } else {
            dispatch(AlterarFornecedorRequest(data));
        }
    }

    function carregaFornecedor() {

        // Fornecedor
        setIdCliente(fornecedorCompleto.idCliente);
        setNomeFornecedor(fornecedorCompleto.nomeFornecedor);
        setDescricaoSite(fornecedorCompleto.site);
        setEmail(fornecedorCompleto.email);
        setCep(fornecedorCompleto.cep);
        setDddFixo(fornecedorCompleto.dddTelefoneFixo);
        setTelefoneFixo(fornecedorCompleto.numeroTelefoneFixo);
        setDddCelular(fornecedorCompleto.dddTelefoneCelular);
        setTelefoneCelular(fornecedorCompleto.numeroCelular);
        setLogradouro(fornecedorCompleto.nomeLogradouro);
        setNumeroLocal(fornecedorCompleto.numeroLocal);
        setDescricaoComplemento(fornecedorCompleto.complemento);
        setBairro(fornecedorCompleto.bairro);
        setAtivo(fornecedorCompleto.ativo);
        setMunicipio(fornecedorCompleto.idMunicipio);
        setIdTipoPessoa(fornecedorCompleto.idTipoPessoa);

        // Fornecedor pessoa fisíca
        setNumeroIdentidade(fornecedorCompleto.fornecedorPessoaFisicaApiModel == null ? '' : fornecedorCompleto.fornecedorPessoaFisicaApiModel.numeroIdentidade);
        setDescricaoOrgaoEmissor(fornecedorCompleto.fornecedorPessoaFisicaApiModel == null ? '' : fornecedorCompleto.fornecedorPessoaFisicaApiModel.orgaoEmissor);
        setNumeroCpf(fornecedorCompleto.fornecedorPessoaFisicaApiModel == null ? '' : fornecedorCompleto.fornecedorPessoaFisicaApiModel.numeroCpf);
        
        // Fornecedor pessoa jurídica
        setNomeFantasia(fornecedorCompleto.fornecedorPessoaJuridicaApiModel == null ? '' : fornecedorCompleto.fornecedorPessoaJuridicaApiModel.nomeFantasia);
        setNumeroCgc(fornecedorCompleto.fornecedorPessoaJuridicaApiModel == null ? '' : fornecedorCompleto.fornecedorPessoaJuridicaApiModel.numeroCgc);
        setNumeroInscricaoEstadual(fornecedorCompleto.fornecedorPessoaJuridicaApiModel == null ? '' : fornecedorCompleto.fornecedorPessoaJuridicaApiModel.numeroInscricaoEstadual);
    }

    const handleSubmit = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false || (!isValidCPF(numeroCpf) === true && idTipoPessoa == 1) ||
            /^\d{2}$/.test(String(dddFixo)) == false || /^\d{4} \d{4}$/.test(String(telefoneFixo)) == false ||
            /^\d{2}$/.test(String(dddCelular)) == false || /^\d{1}\d{4} \d{4}$/.test(String(telefoneCelular)) == false) {
            event.preventDefault();
        } else {
            InserirFornecedor(event);
        }

        setValidated(true);
    };

    function isValidCPF(cpf) {
        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/igm, '')
        if (
            !cpf ||
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        ) {
            return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(9, 10))) return false
        soma = 0
        for (var i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(10, 11))) return false
        return true
    }

    return (
        <>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="divBody">
                    <Row>
                        <Col md={12}>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formDescricaoObjeto">
                                    <Form.Label>Nome Fornecedor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nomeFornecedor}
                                        onChange={e => setNomeFornecedor(e.target.value)}
                                        required
                                        maxLength="70"
                                        isInvalid={validated && nomeFornecedor.trim() === "" ?
                                            true
                                            :
                                            (lista !== null ?
                                                lista.forEach(element => {
                                                    if (element.nomeFornecedor === nomeFornecedor) {
                                                        return true
                                                    }
                                                })
                                                :
                                                false
                                            )
                                        }
                                        isValid={validated && nomeFornecedor.trim() !== "" ?
                                            (lista !== undefined ?
                                                lista.forEach(element => {
                                                    if (element.nomeFornecedor === nomeFornecedor) {
                                                        return false
                                                    }
                                                })
                                                :
                                                true
                                            )
                                            : false} />
                                    <Form.Control.Feedback type="invalid">O campo Nome Fornecedor é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                        maxLength="150"
                                        isInvalid={validated && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(String(email)) ? true : false}
                                        isValid={validated && /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(String(email)) ? true : false} />
                                    <Form.Control.Feedback type="invalid">
                                        {
                                            email === "" ? "O campo Email é obrigatório." : "Forneça um Email válido"
                                        }
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formDescricaoObjeto">
                                    <Form.Label>Site</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricaoSite}
                                        onChange={e => setDescricaoSite(e.target.value)}
                                        required
                                        maxLength="200"
                                        isInvalid={validated && descricaoSite.trim() === "" ? true : false}
                                        isValid={validated && descricaoSite.trim() !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Site é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formIdCliente">
                                    <Form.Label>Cliente</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setIdCliente(e.target.value)}
                                        value={idCliente}
                                        required
                                        isInvalid={validated && idCliente === "" ? true : false}
                                        isValid={validated && idCliente !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaCliente != undefined ?
                                                listaCliente.map(m => (
                                                    <option value={m.id} key={m.id}>{m.nome}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo Cliente é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={1} controlId="formDDD">
                                    <Form.Label>DDD</Form.Label>
                                    <Form.Control
                                        as={InputMask}
                                        mask='99'
                                        value={dddFixo}
                                        onChange={(e) => setDddFixo(e.target.value)}
                                        required
                                        isInvalid={validated && !/^\d{2}$/.test(String(dddFixo)) ? true : false}
                                        isValid={validated && /^\d{2}$/.test(String(dddFixo)) ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo DDD é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={5} controlId="formTelefone">
                                    <Form.Label>Telefone Fixo</Form.Label><br />
                                    <Form.Control
                                        as={InputMask}
                                        mask='9999 9999'
                                        value={telefoneFixo}
                                        onChange={(e) => setTelefoneFixo(e.target.value)}
                                        required
                                        isInvalid={validated && !/^\d{4} \d{4}$/.test(String(telefoneFixo))
                                            && !/^\d{4}\d{4}$/.test(String(telefoneFixo))
                                            ? true : false}
                                        isValid={(validated) && (/^\d{4} \d{4}$/.test(String(telefoneFixo)) || /^\d{4}\d{4}$/.test(String(telefoneFixo)))
                                            ? true : false} >
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">O campo Telefone Fixo é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={1} controlId="formDDD">
                                    <Form.Label>DDD</Form.Label>
                                    <Form.Control
                                        as={InputMask}
                                        mask='99'
                                        value={dddCelular}
                                        onChange={(e) => setDddCelular(e.target.value)}
                                        required
                                        isInvalid={validated && !/^\d{2}$/.test(String(dddCelular)) ? true : false}
                                        isValid={validated && /^\d{2}$/.test(String(dddCelular)) ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo DDD é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={5} controlId="formTelefone">
                                    <Form.Label>Telefone Celular</Form.Label><br />
                                    <Form.Control
                                        as={InputMask}
                                        mask='99999 9999'
                                        value={telefoneCelular}
                                        onChange={(e) => setTelefoneCelular(e.target.value)}
                                        required
                                        isInvalid={validated && !/^\d{1}\d{4} \d{4}$/.test(String(telefoneCelular))
                                            && !/^\d{1}\d{4}\d{4}$/.test(String(telefoneCelular))
                                            ? true : false}
                                        isValid={(validated && /^\d{1}\d{4} \d{4}$/.test(String(telefoneCelular)))
                                            || (validated && /^\d{1}\d{4}\d{4}$/.test(String(telefoneCelular)))
                                            ? true : false} >
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">O campo Telefone Celular é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formNumeroInterno">
                                    <Form.Label>Logradouro</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={logradouro}
                                        onChange={e => setLogradouro(e.target.value)}
                                        required
                                        maxLength="150"
                                        isInvalid={validated && logradouro.trim() === "" ? true : false}
                                        isValid={validated && logradouro.trim() !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Logradouro é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formNumeroOrigem">
                                    <Form.Label>Número</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={numeroLocal}
                                        onChange={e => setNumeroLocal(e.target.value)}
                                        required
                                        maxLength="10"
                                        isInvalid={validated && numeroLocal.trim() === "" ? true : false}
                                        isValid={validated && numeroLocal.trim() !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Número é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formNumeroOrigem">
                                    <Form.Label>Complemento</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricaoComplemento}
                                        onChange={e => setDescricaoComplemento(e.target.value)}
                                        maxLength="250" />
                                    <Form.Control.Feedback type="invalid">O campo Complemento é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formBairro">
                                    <Form.Label>Bairro</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={bairro}
                                        onChange={(e) => setBairro(e.target.value)}
                                        required
                                        maxLength="100"
                                        isInvalid={validated && bairro.trim() === "" ? true : false}
                                        isValid={validated && bairro.trim() !== "" ? true : false} />
                                    <Form.Control.Feedback type="invalid">O campo Bairro é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formCep">
                                    <Form.Label>Cep</Form.Label><br />
                                    <Form.Control
                                        as={InputMask}
                                        mask='99.999-999'
                                        value={cep}
                                        onChange={(e) => setCep(e.target.value)}
                                        required
                                        isInvalid={validated && !/^\d{2}.\d{3}-\d{3}$/.test(String(cep)) ? true : false}
                                        isValid={validated && /^\d{2}.\d{3}-\d{3}$/.test(String(cep)) ? true : false}>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">O campo Cep é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="formMunicipio">
                                    <Form.Label>Município</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setMunicipio(e.target.value)}
                                        value={municipio}
                                        required
                                        isInvalid={validated && municipio === "" ? true : false}
                                        isValid={validated && municipio !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaMunicipio != undefined ?
                                                listaMunicipio.map(m => (
                                                    <option value={m.id} key={m.id}>{m.nome}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo Município é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">

                                <Form.Group as={Col} md={6} controlId="formIdCliente">
                                    <Form.Label>Tipo Pessoa</Form.Label>
                                    <Form.Select
                                        onChange={(e) => setIdTipoPessoa(e.target.value)}
                                        value={idTipoPessoa}
                                        required
                                        isInvalid={validated && idTipoPessoa === "" ? true : false}
                                        isValid={validated && idTipoPessoa !== "" ? true : false}>
                                        <option value="">Selecione...</option>
                                        {
                                            listaTipoPessoa != undefined ?
                                                listaTipoPessoa.map(m => (
                                                    <option value={m.id} key={m.id}>{m.descricao}</option>
                                                ))
                                                :
                                                null
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">O campo Tipo Pessoa é obrigatório.</Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            {
                                idTipoPessoa == 1 ?
                                    <div>
                                        <Row className="mb-3">

                                            <Form.Group as={Col} md={6} controlId="formBairro">
                                                <Form.Label>Número Identidade</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={numeroIdentidade}
                                                    onChange={(e) => setNumeroIdentidade(e.target.value)}
                                                    required
                                                    maxLength="50"
                                                    isInvalid={validated && numeroIdentidade.trim() === "" ? true : false}
                                                    isValid={validated && numeroIdentidade.trim() !== "" ? true : false} />
                                                <Form.Control.Feedback type="invalid">O campo Identidade é obrigatório.</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md={6} controlId="formBairro">
                                                <Form.Label>Órgão Emissor</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={descricaoOrgaoEmissor}
                                                    onChange={(e) => setDescricaoOrgaoEmissor(e.target.value)}
                                                    required
                                                    maxLength="70"
                                                    isInvalid={validated && descricaoOrgaoEmissor.trim() === "" ? true : false}
                                                    isValid={validated && descricaoOrgaoEmissor.trim() !== "" ? true : false} />
                                                <Form.Control.Feedback type="invalid">O campo Descricao Orgrao Emissor é obrigatório.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">

                                            <Form.Group as={Col} md={6} controlId="formBairro">
                                                <Form.Label>CPF</Form.Label>
                                                <Form.Control
                                                    as={InputMask}
                                                    mask='999.999.999-99'
                                                    value={numeroCpf}
                                                    onChange={(e) => setNumeroCpf(e.target.value)}
                                                    required
                                                    isInvalid={validated && numeroCpf === "" || validated && !isValidCPF(numeroCpf) ? true : false}
                                                    isValid={validated && numeroCpf !== "" ? true : false} />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        numeroCpf === "" ? "O campo CPF é obrigatório." : "Forneça um CPF válido"
                                                    }
                                                </Form.Control.Feedback>
                                                <br />
                                                <Form>
                                                    <Row>
                                                        <Form.Group as={Col} md={6}>
                                                            <Form.Check
                                                                size="lg"
                                                                label="Ativar fornecedor"
                                                                type="switch"
                                                                id="custom-switch"
                                                                onChange={() => setAtivo(!ativo)}
                                                                defaultChecked={ativo}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </Form>
                                            </Form.Group>

                                        </Row>
                                    </div>
                                    :
                                    idTipoPessoa == 2 ?
                                        <div>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md={6} controlId="formBairro">
                                                    <Form.Label>Nome Fantasia</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={nomeFantasia}
                                                        onChange={(e) => setNomeFantasia(e.target.value)}
                                                        required
                                                        maxLength="100"
                                                        isInvalid={validated && nomeFantasia.trim() === "" ? true : false}
                                                        isValid={validated && nomeFantasia.trim() !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">O campo Nome Fantasia é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} controlId="formBairro">
                                                    <Form.Label>Número CGC</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={numeroCgc}
                                                        onChange={(e) => setNumeroCgc(e.target.value)}
                                                        required
                                                        maxLength="18"
                                                        isInvalid={validated && numeroCgc.trim() === "" ? true : false}
                                                        isValid={validated && numeroCgc.trim() !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">O campo Número CGC é obrigatório.</Form.Control.Feedback>
                                                </Form.Group>

                                            </Row>

                                            <Row className="mb-3">

                                                <Form.Group as={Col} md={6} controlId="formBairro">
                                                    <Form.Label>Número Inscrição Estadual</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={numeroInscricaoEstadual}
                                                        onChange={(e) => setNumeroInscricaoEstadual(e.target.value)}
                                                        required
                                                        maxLength="9"
                                                        isInvalid={validated && numeroInscricaoEstadual.trim() === "" ? true : false}
                                                        isValid={validated && numeroInscricaoEstadual.trim() !== "" ? true : false} />
                                                    <Form.Control.Feedback type="invalid">O campo Número Inscricao Estadual é obrigatório.</Form.Control.Feedback>
                                                    <br />
                                                    <Form>
                                                        <Row>
                                                            <Form.Group as={Col} md={6}>
                                                                <Form.Check
                                                                    size="lg"
                                                                    label="Ativar fornecedor"
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    onChange={() => setAtivo(!ativo)}
                                                                    checked={ativo}
                                                                />
                                                            </Form.Group>
                                                        </Row>
                                                    </Form>

                                                </Form.Group>

                                            </Row>
                                        </div>
                                        :
                                        null
                            }
                            <Row>
                                <Col md={1}>
                                    <Button variant="success" type="submit" style={{ width: '100%' }}>{id == undefined ? 'Cadastrar' : 'Editar'}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form >
        </>
    );
}