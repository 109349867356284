import produce from 'immer';

const initialState = {
    listaItemCusto: undefined,
    listaCompletaItemCusto: undefined,
    carregando: true
};

export default function itemCusto(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_ITEM_CUSTO_RESPONSE':
            return produce(state, draft => {
                draft.listaItemCusto = action.listaItemCusto;
            });

        case 'LISTAR_ITEM_CUSTO_COMPLETO_RESPONSE':
            return produce(state, draft => {
                draft.listaCompletaItemCusto = action.listaCompletaItemCusto;
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });       
        
        default:
            return state;
    }
}