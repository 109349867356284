import produce from 'immer';

const initialState = {
  contratoCompleto: null,
  listaContrato: undefined,
  carregando: true
};

export default function contrato(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_CONTRATO_RESPONSE':
      return produce(state, draft => {
        draft.listaContrato = action.listaContrato;
      });

    case 'BUSCAR_CONTRATO_POR_ID_RESPONSE':
      return produce(state, draft => {
        draft.contratoCompleto = action.contratoCompleto;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}