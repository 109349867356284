import produce from 'immer';

const initialState = {
    listaSolicitacaoFornecimentoHistorico: undefined,
    carregando: true
};

export default function solicitacaoFornecimentoHistorico(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_SOLICITACAO_FORNECIMENTO_HISTORICO_RESPONSE':
      return produce(state, draft => {
        draft.listaSolicitacaoFornecimentoHistorico = action.listaSolicitacaoFornecimentoHistorico;
      });
    
      case 'CARREGANDO':
        return produce(state, draft => {
          draft.carregando = action.status
        });

    default:
      return state;
  }
}