import { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import {
    ListarPrioridadeRequest, ExcluirPrioridadeRequest,
    InserirPrioridadeRequest, AlterarPrioridadeRequest
} from '../../../store/modules/prioridade/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IoTimeOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import Header from "../../../components/Header/header";
import Loading from "../../../components/Loading/loading";
import MaterialTable from 'material-table';
import './prioridade.css';
import { useHistory } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

export default function Prioridade() {

    const dispatch = useDispatch();
    const history = useHistory();
    const listaPrioridade = useSelector(state => state.prioridade.listaPrioridade);
    const carregando = useSelector(state => state.prioridade.carregando);
    const [selectedRow, setSelectedRow] = useState(null);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [TodasOperacoes, setTodasOperacoes] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "AuxiliaresMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "AuxiliaresInserir").length == 1 && token.role.filter(r => r == "AuxiliaresAlterar").length == 1) {
            setTodasOperacoes(true);
        }

    }, [])

    useEffect(() => {
        dispatch(ListarPrioridadeRequest());
    }, [])

    function ExcluirPrioridade(id) {
        dispatch(ExcluirPrioridadeRequest(id));
    }

    function ValidacoesInserir(prioridade) {

        //valida o campo descrição
        if (prioridade.descricao == null || prioridade.descricao == undefined
            || prioridade.descricao.length > 150 || prioridade.descricao.trim() == '') {
            toast.error(`Descrição inválida`);
            return false;
        }

        //valida o campo tempoParaExecucao
        if (isNaN(prioridade.tempoParaExecucao) || prioridade.tempoParaExecucao == undefined
            || prioridade.tempoParaExecucao == null) {
            toast.error(`Prazo horas inválido`);
            return false;
        }

        return true;

    }

    function InserirPrioridade(prioridade) {

        let data = {
            'Id': 0,
            'Descricao': prioridade.descricao.trim(),
            'CorHex': document.getElementById('colorInput').value,
            'TempoParaExecucao': parseInt(prioridade.tempoParaExecucao)
        }

        dispatch(InserirPrioridadeRequest(data))
    }

    function AlterarPrioridade(prioridade) {

        let data = {
            'Id': prioridade.id,
            'Descricao': prioridade.descricao.trim(),
            'CorHex': document.getElementById('colorInput').value,
            'TempoParaExecucao': parseInt(prioridade.tempoParaExecucao)
        };

        dispatch(AlterarPrioridadeRequest(data))
    }

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col md={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <Row>
                                <Col md={{ span: 10 }}>
                                    <span>
                                        <IoTimeOutline size={25} color={"#000"} /> Prioridade
                                    </span>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="divBody">
                                <Row>
                                    <Col>
                                        {
                                            carregando ?
                                                <div>

                                                    <MaterialTable
                                                        components={{
                                                            Pagination: PatchedPagination,
                                                        }}
                                                        title=""
                                                        columns={[
                                                            {
                                                                title: 'Cor', field: 'corHex', sorting: true, editPlaceholder: 'cor', grouping: false, cellStyle: { width: '10%' },
                                                                editComponent: (props) => (
                                                                    <>
                                                                        <Form.Control
                                                                            type="color"
                                                                            id="colorInput"
                                                                            defaultValue={props.value}
                                                                            title="Escolha sua Cor"
                                                                        />
                                                                    </>
                                                                ),
                                                                filtering: false,
                                                                render: rowData =>
                                                                    <div
                                                                        style={{
                                                                            height: '25px',
                                                                            width: '15px',
                                                                            backgroundColor: rowData.corHex,
                                                                        }}>
                                                                    </div>
                                                            },
                                                            {
                                                                title: 'Descricao', field: 'descricao', sorting: true, editPlaceholder: 'descrição',
                                                                validate: rowData => {
                                                                    if (rowData.descricao === undefined || rowData.descricao.trim() === "") {
                                                                        return "Campo Obrigatório"
                                                                    } else if (rowData.descricao.trim().length > 150) {
                                                                        return "A descrição não pode conter mais que 150 caracteres"
                                                                    }
                                                                    return true
                                                                }, cellStyle: { width: '45%' },

                                                            },
                                                            {
                                                                title: 'Tempo para Execução', type: "numeric", align: "left", field: 'tempoParaExecucao', sorting: true, editPlaceholder: 'tempo',
                                                                validate: rowData => {
                                                                    if (rowData.tempoParaExecucao === undefined) {
                                                                        return "Campo Obrigatório"
                                                                    }
                                                                    return true
                                                                }, cellStyle: { width: '45%' }
                                                            }
                                                        ]}
                                                        data={
                                                            listaPrioridade != undefined ?
                                                                listaPrioridade.map(prioridade => {
                                                                    return {
                                                                        id: prioridade.id,
                                                                        descricao: prioridade.descricao,
                                                                        corHex: prioridade.corHex,
                                                                        tempoParaExecucao: prioridade.tempoParaExecucao
                                                                    }
                                                                })
                                                                :
                                                                []
                                                        }
                                                        icons={{
                                                            Add: () =>
                                                                <Button variant="success">
                                                                    <span>
                                                                        <FaPlus size={15} color={"#fff"} /> Inserir
                                                                    </span>
                                                                </Button>
                                                        }}
                                                        editable={{
                                                            onRowAdd: (newRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            InserirPrioridade(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        if (!ValidacoesInserir(newRow)) {
                                                                            reject();
                                                                        }
                                                                        else {
                                                                            AlterarPrioridade(newRow);
                                                                            resolve()
                                                                        }
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                            onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                TodasOperacoes ?
                                                                    setTimeout(() => {
                                                                        ExcluirPrioridade(oldData.id);
                                                                        resolve()
                                                                    }, 1000)
                                                                    :
                                                                    setTimeout(() => {
                                                                        toast.error(`Você não tem permissão`);
                                                                        resolve()
                                                                    }, 1000)
                                                            }),
                                                        }}
                                                        options={{
                                                            minBodyHeight: 500,
                                                            paging: true,
                                                            pageSize: 10, // Início
                                                            emptyRowsWhenPaging: false,
                                                            addRowPosition: "first",
                                                            pageSizeOptions: [10, 50, 200, 300],
                                                            headerStyle: {
                                                                backgroundColor: '#454545',
                                                                color: '#FFF',
                                                                fontWeight: "bold",
                                                                lineBreak: "auto",
                                                                fontSize: "13px"
                                                            },
                                                            filterRowStyle: {
                                                                backgroundColor: "#FAFAFA"
                                                            },
                                                            actionsColumnIndex: -1,
                                                            filtering: true,
                                                            rowStyle: rowData => ({
                                                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                            })

                                                        }}
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                        localization={{
                                                            header: {
                                                                actions: <span className="classHeaderTabela">Ações</span>
                                                            },
                                                            body: {
                                                                emptyDataSourceMessage: 'Nenhum registro para exibir',
                                                                editRow: {
                                                                    deleteText: 'Você tem certeza que deseja excluir essa linha?',
                                                                    saveTooltip: '',
                                                                    cancelTooltip: '',
                                                                },
                                                                deleteTooltip: '',
                                                                editTooltip: '',
                                                                addTooltip: '',
                                                            },
                                                            toolbar: {
                                                                searchTooltip: '',
                                                                searchPlaceholder: 'Pesquisar',
                                                                exportTitle: "",
                                                                exportCSVName: "Exportar como CSV",
                                                                exportPDFName: "Exportar como PDF",
                                                            },
                                                            pagination: {
                                                                labelRowsSelect: 'linhas',
                                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                                firstTooltip: '',
                                                                previousTooltip: '',
                                                                nextTooltip: '',
                                                                lastTooltip: ''
                                                            },
                                                            grouping: {
                                                                placeholder: 'Arraste a coluna aqui para agrupar',
                                                                groupedBy: 'Agrupado por:'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <Loading />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    )
}