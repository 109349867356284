import { useEffect, useState } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    InserirAutorizacaoFornecimentoSubitemCustoMedicaoRequest,
    BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdRequest
} from "../../../store/modules/autorizacaoFornecimentoSubitemCustoMedicao/actions";
import {
    BuscarUsuarioPorIdRequest
} from "../../../store/modules/usuario/actions";
import { ListarAutorizacaoFornecimentoSubitemCustoRequest } from "../../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import { ListarMedicaoAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/medicaoAutorizacaoFornecimentoStatus/actions";
import { ListarStatusMedicaoAutorizacaoFornecimentoRequest } from "../../../store/modules/statusMedicaoAutorizacaoFornecimento/actions";

export default function Subitem() {

    const { idMedicaoSubItem, idMedicao, idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const autorizacaoFornecimentoSubitemCustoMedicaoCompleto = useSelector(state => state.autorizacaoFornecimentoSubitemCustoMedicao.autorizacaoFornecimentoSubitemCustoMedicaoCompleto);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const listaMedicaoAutorizacaoFornecimentoStatus = useSelector(state => state.medicaoAutorizacaoFornecimentoStatus.listaMedicaoAutorizacaoFornecimentoStatus);

    const [idSubItemCusto, setIdSubItemCusto] = useState();
    const [quantidadeCliente, setQuantidadeCliente] = useState();
    const [quantidadeFornecedor, setQuantidadeFornecedor] = useState();

    useEffect(() => {

        dispatch(ListarStatusMedicaoAutorizacaoFornecimentoRequest());
        if (idMedicao !== undefined) {
            dispatch(ListarMedicaoAutorizacaoFornecimentoStatusRequest(idMedicao));
        }

        // lista de subitens, exemplo: poste, lampada, etc.
        if (idAutorizacaoFornecimento != undefined) {
            var data = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento)
            }
            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));
        }
        // subitem da medição        
        if (idMedicaoSubItem != undefined) {
            dispatch(BuscarAutorizacaoFornecimentoSubitemCustoMedicaoPorIdRequest(idMedicaoSubItem));
        }

        dispatch(BuscarUsuarioPorIdRequest(usuario.id));

    }, [idMedicaoSubItem, idAutorizacaoFornecimento])

    useEffect(() => {

        if (autorizacaoFornecimentoSubitemCustoMedicaoCompleto != undefined && idMedicaoSubItem != undefined) {
            setIdSubItemCusto(autorizacaoFornecimentoSubitemCustoMedicaoCompleto.idAutorizacaoFornecimentoSubitemCusto);
            setQuantidadeCliente(autorizacaoFornecimentoSubitemCustoMedicaoCompleto.quantidadeCliente);
            setQuantidadeFornecedor(autorizacaoFornecimentoSubitemCustoMedicaoCompleto.quantidadeFornecedor);
        }

    }, [autorizacaoFornecimentoSubitemCustoMedicaoCompleto])

    return (
        <>
            <Form>
                <div className="tamanhoTela">
                    <Row className="mb-3" type='hidden'>
                        <Form.Group as={Col} md={6} controlId="formNumeroMedicaoAF">
                            <Form.Label>Item</Form.Label>
                            <Form.Select
                                onChange={(e) => setIdSubItemCusto(e.target.value)}
                                value={idSubItemCusto}
                                disabled={idMedicaoSubItem ? true : false}>
                                <option value="">Selecione...</option>
                                {
                                    listaAutorizacaoFornecimentoSubitemCusto != undefined && listaAutorizacaoFornecimentoSubitemCusto.length > 0 ?
                                        listaAutorizacaoFornecimentoSubitemCusto.map(m => (
                                            <option value={m.id} key={m.id}>{m.subitemCusto.descricao}</option>
                                        ))
                                        :
                                        null
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={6} controlId="formData">
                            <Form.Label>Quantidade Fornecedor</Form.Label>
                            <Form.Control
                                type="number"
                                maxLength="10"
                                value={quantidadeFornecedor}
                                onChange={(e) => setQuantidadeFornecedor(e.target.value)}
                                disabled={idMedicaoSubItem ? true :
                                    usuarioCompleto != null
                                        && usuarioCompleto.usuarioFornecedor == true ? false : true}
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formUsuario">
                            <Form.Label>Quantidade Cliente</Form.Label>
                            <Form.Control
                                type="number"
                                maxLength="10"
                                value={quantidadeCliente}
                                onChange={(e) => setQuantidadeCliente(e.target.value)}
                                disabled={
                                    idMedicaoSubItem ?
                                        true :
                                        usuarioCompleto != null
                                            && usuarioCompleto.usuarioFornecedor == true ? false : true}
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            />
                        </Form.Group>
                    </Row>
                </div>
            </Form >
            <Col md={12}>
                {
                    idMedicao != undefined && listaMedicaoAutorizacaoFornecimentoStatus != undefined && listaStatusMedicaoAutorizacaoFornecimento != undefined
                        ?
                        // Verificação se o status atual é o cancelado
                        listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true).length > 0 &&
                            listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.cancelaMedicao == true)[0].id).length > 0
                            ?
                            <Col md={12}>
                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                    A Medição está Cancelada!
                                </Alert>
                            </Col>
                            :
                            // Verificação se o status atual é o finalizado
                            listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true).length > 0 &&
                                listaMedicaoAutorizacaoFornecimentoStatus.filter(x => x.idStatusMedicaoAutorizacaoFornecimento ==
                                    listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao == true)[0].id).length > 0
                                ?
                                <Col md={12}>
                                    <Alert variant={`success`} style={{ width: '100%' }}>
                                        A Medição está Finalizada!
                                    </Alert>
                                </Col>
                                :
                                ''
                        :
                        ''
                }
            </Col>
        </>
    )
}