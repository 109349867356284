export function ListarOrgaoRequest() {
  return {
    type: 'LISTAR_ORGAO_REQUEST'
  }
}

export function ListarOrgaoResponse(listaOrgao) {
  return {
    type: 'LISTAR_ORGAO_RESPONSE',
    listaOrgao
  }
}

export function InserirOrgaoRequest(data) {
  return {
    type: 'INSERIR_ORGAO_REQUEST',
    data
  }
}

export function AlterarOrgaoRequest(data) {
  return {
    type: 'ALTERAR_ORGAO_REQUEST',
    data
  }
}

export function ExcluirOrgaoRequest(id) {
  return {
    type: 'EXCLUIR_ORGAO_REQUEST',
    id
  }
}

export function BuscarOrgaoPorNomeRequest(orgao) {
  return {
    type: 'BUSCAR_ORGAO_POR_NOME_REQUEST',
    orgao
  }
}

export function BuscarOrgaoPorNomeResponse(listaOrgaoPorNome) {
  return {
    type: 'BUSCAR_ORGAO_POR_NOME_RESPONSE',
    listaOrgaoPorNome
  }
}

export function LimparlistaOrgaoPorNome() {
  return {
    type: 'LIMPAR_LISTA_ORGAO_POR_NOME'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}