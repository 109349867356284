import { Col, Alert, Form, Button, Row } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { InserirCoordenadasdSolicitacaoFornecimentoRequest, BuscarSolicitacaoFornecimentoPorIdRequest, BuscarCoordenadasPorEnderecoRequest } from "../../../store/modules/solicitacaoFornecimento/actions";
import { useParams } from "react-router-dom";
import { ListarStatusSolicitacaoFornecimentoRequest } from '../../../store/modules/statusSolicitacaoFornecimento/actions';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import './mapa.css';

const PinReactComponent = () =>
    <div className="circle">
    </div>

export default function Mapa() {

    const { id } = useParams();
    const dispatch = useDispatch();

    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const coordenadasPorEndereco = useSelector(state => state.solicitacaoFornecimento.coordenadasPorEndereco);
    const listaTipoSolicitacao = useSelector(state => state.tipoSolicitacao.listaTipoSolicitacao);
    const listaSolicitacaoFornecimentoVersaoStatus = useSelector(state =>
        state.solicitacaoFornecimentoVersaoStatus.listaSolicitacaoFornecimentoVersaoStatus);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);

    const [latitude, setLatitude] = useState('-23.5489');
    const [longitude, setLongitude] = useState('-46.6388233256');
    const [endereco, setEndereco] = useState("");
    const [zoom, setZoom] = useState(7);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    useEffect(() => {

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }

    }, [])

    useEffect(() => {
        //dispatch(ListarStatusSolicitacaoFornecimentoRequest());

    }, [])

    //AIzaSyAamdmy1AK-Hz9QS40toW0F6qAXgGAJi7E
    //AIzaSyDRLnbHWWWL5XQDwFi5aFxDiMY0d8INnSc
    useEffect(() => {

        if (solicitacaoFornecimento != null) {
            setLatitude(solicitacaoFornecimento.latitude == '0' ? '-23.5489' : solicitacaoFornecimento.latitude);
            setLongitude(solicitacaoFornecimento.longitude == '0' ? '-46.6388233256' : solicitacaoFornecimento.longitude);
            setEndereco(solicitacaoFornecimento.descricaoLocal)
        }

    }, [solicitacaoFornecimento])

    useEffect(() => {

        if (coordenadasPorEndereco != null) {
            setLatitude(coordenadasPorEndereco.latitude);
            setLongitude(coordenadasPorEndereco.longitude);
            setZoom(4);
        }

    }, [coordenadasPorEndereco])

    function SalvarCoordenada() {

        var data = {
            'IdSolicitacaoFornecimento': id,
            'Latitude': `${latitude}`,
            'Longitude': `${longitude}`
        }

        dispatch(InserirCoordenadasdSolicitacaoFornecimentoRequest(data));
    }

    function MudarPinoMapa(coordenadas) {
        setLatitude(coordenadas.lat);
        setLongitude(coordenadas.lng);
    }

    function BuscarEndereco(enderecoLocal) {

        if (enderecoLocal != '') {
            /* Geocode.setApiKey("AIzaSyDRLnbHWWWL5XQDwFi5aFxDiMY0d8INnSc");
             Geocode.setLanguage("pt-BR");
             Geocode.setRegion("br");
             Geocode.setLocationType("ROOFTOP");
             Geocode.enableDebug(true);    
             Geocode.fromAddress(enderecoLocal).then(
                 (response) => {
                     const { lat, lng } = response.results[0].geometry.location;
                     setLatitude(lat);
                     setLongitude(lng);
                 },
                 (error) => {
                     console.error(error);
                 }
             ); */
            dispatch(BuscarCoordenadasPorEnderecoRequest(enderecoLocal));
        }

    }


    return (
        <>

            <div>
                {solicitacaoFornecimento != null && listaTipoSolicitacao != undefined && listaTipoSolicitacao.length > 0 &&
                    listaTipoSolicitacao.filter((tipoSoli) => tipoSoli.id === solicitacaoFornecimento.idTipoSolicitacao).length > 0 &&
                    listaTipoSolicitacao.filter((tipoSoli) => tipoSoli.id === solicitacaoFornecimento.idTipoSolicitacao)[0].geografico

                    ?

                    <Form.Group as={Col} md={12} controlId="formContratoCompleto" >
                        <Col md={12}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={6}>
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={endereco}
                                        onChange={e => setEndereco(e.target.value)}
                                    />
                                    <br />
                                    <Button variant="success" onClick={() => BuscarEndereco(endereco)}>Buscar Endereço</Button>
                                    {/*}   <Button style={{ marginLeft: '10px' }} onClick={() => BuscarEndereco(solicitacaoFornecimento.descricaoLocal)}>Selecionar Local da Solicitação</Button> {*/}
                                </Form.Group>

                            </Row>

                        </Col>
                        <Col md={12}>
                            <br />
                            <div style={{ height: '60vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{
                                        key: 'AIzaSyAamdmy1AK-Hz9QS40toW0F6qAXgGAJi7E',
                                        language: "pt-br"
                                    }}
                                    center={{
                                        lat: parseInt(solicitacaoFornecimento.latitude) == 0 ? parseInt(latitude) : parseInt(solicitacaoFornecimento.latitude),
                                        lng: parseInt(solicitacaoFornecimento.longitude) == 0 ? parseInt(longitude) : parseInt(solicitacaoFornecimento.longitude)
                                    }}
                                    defaultZoom={zoom}
                                    onClick={cor => { MudarPinoMapa(cor) }}
                                >
                                    <PinReactComponent
                                        lat={latitude}
                                        lng={longitude}
                                        text="Meu marcador"
                                    />
                                </GoogleMapReact>
                            </div>
                        </Col>
                        <div className="alerta">
                            {
                                id != undefined && listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                    listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0
                                    ?
                                    <Col md={12}>
                                        <Alert variant={`danger`} style={{ width: '100%' }}>
                                            A Solicitação está Cancelada!
                                        </Alert>
                                    </Col>
                                    :
                                    listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                        listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                            listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0
                                        ?
                                        <Col md={12}>
                                            <Alert variant={`success`} style={{ width: '100%' }}>
                                                A Solicitação está Finalizada!
                                            </Alert>
                                        </Col>
                                        :
                                        listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null && listaSolicitacaoFornecimentoVersaoStatus
                                        [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == true ?
                                            <Col md={12}>
                                                <Alert variant={`success`} style={{ width: '100%' }}>
                                                    Status atual desta solicitação só permite leitura!
                                                </Alert>
                                            </Col>
                                            :
                                            inserir == true ?
                                                <Col md={12}>
                                                    <Button variant="success" onClick={SalvarCoordenada}>Salvar Coordenada</Button>
                                                </Col>
                                                :
                                                ''
                            }
                        </div>
                    </Form.Group>
                    :
                    <div>
                        Essa Solicitação não tem o tipo geográfico.
                    </div>
                }
            </div>
        </>
    )
}