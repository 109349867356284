export function ListarCaracteristicaRespostaRequest() {
    return {
        type: 'LISTAR_CARACTERISTICA_RESPOSTA_REQUEST'
    }
}

export function ListarCaracteristicaRespostaResponse(listaCaracteristicaResposta) {
    return {
        type: 'LISTAR_CARACTERISTICA_RESPOSTA_RESPONSE',
        listaCaracteristicaResposta
    }
}

export function InserirCaracteristicaRespostaRequest(data, idSolicitacaoFornecimento, idTipoSolicitacao) {
    return {
        type: 'INSERIR_CARACTERISTICA_RESPOSTA_REQUEST',
        data, idSolicitacaoFornecimento, idTipoSolicitacao
    }
}

export function AlterarCaracteristicaRespostaRequest(data) {
    return {
        type: 'ALTERAR_CARACTERISTICA_RESPOSTA_REQUEST',
        data
    }
}

export function ExcluirCaracteristicaRespostaRequest(id) {
    return {
        type: 'EXCLUIR_CARACTERISTICA_RESPOSTA_REQUEST',
        id
    }
}

export function BuscarCaracteristicaRespostaPorNomeRequest(descricao) {
    return {
        type: 'BUSCAR_CARACTERISTICA_RESPOSTA_POR_NOME_REQUEST',
        descricao
    }
}

export function BuscarCaracteristicaRespostaPorNomeResponse(listaCaracteristicaRespostaPorNome) {
    return {
        type: 'BUSCAR_CARACTERISTICA_RESPOSTA_POR_NOME_RESPONSE',
        listaCaracteristicaRespostaPorNome
    }
}

export function LimparlistaCaracteristicaRespostaPorNome() {
    return {
        type: 'LIMPAR_LISTA_CARACTERISTICA_RESPOSTA_POR_NOME'
    }
}

export function Carregando(status) {
    return {
        type: 'CARREGANDO',
        status
    }
}