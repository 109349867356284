import { Container, Row, Col, Form, Button, Modal, Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    BuscarEventoAdicionalPorIdRequest,
    InserirEventoAdicionalRequest, AlterarEventoAdicionalRequest, LimparEventoAdicional
} from '../../store/modules/eventoAdicional/actions';
import Select from 'react-select';

export default function ModalContratoVersao({ show, onHide, idEventoAdicional }) {

    const { id } = useParams();
    const dispatch = useDispatch();

    const listaTipoSolicitacao = useSelector(state => state.tipoSolicitacao.listaTipoSolicitacao);
    const listaUsuario = useSelector(state => state.usuario.listaUsuario);
    const listaOrgao = useSelector(state => state.orgao.listaOrgao);
    const eventoAdicionalCompleto = useSelector(state => state.eventoAdicional.eventoAdicionalCompleto);

    const [idTipoSolicitacao, setIdTipoSolicitacao] = useState('');
    const [idSolicitacaoFornecimento, setIdSolicitacaoFornecimento] = useState('');
    const [autorizador, setAutorizador] = useState('');
    const [destinatario, setDestinatario] = useState('');
    const [pagador, setPagador] = useState('');
    const [observacao, setObservacao] = useState('');
    const [cargaEstimada, setCargaEstimada] = useState('');
    const [numeroRefletores, setNumeroRefletores] = useState('');
    const [numeroOficio, setNumeroOficio] = useState('');
    const [validated, setValidated] = useState(false);

    useEffect(() => {

        if (idEventoAdicional != undefined) {
            dispatch(BuscarEventoAdicionalPorIdRequest(idEventoAdicional));
        }

    }, [idEventoAdicional])

    useEffect(() => {

        if (eventoAdicionalCompleto != null) {
            setIdTipoSolicitacao(eventoAdicionalCompleto.idTipoSolicitacao);
            setIdSolicitacaoFornecimento(eventoAdicionalCompleto.idSolicitacaoFornecimento);
            setAutorizador(eventoAdicionalCompleto.autorizador);
            setDestinatario(eventoAdicionalCompleto.destinatario);
            setPagador(eventoAdicionalCompleto.pagador);
            setObservacao(eventoAdicionalCompleto.observacao);
            setCargaEstimada(eventoAdicionalCompleto.cargaEstimada);
            setNumeroRefletores(eventoAdicionalCompleto.numeroRefletores);
            setNumeroOficio(eventoAdicionalCompleto.numeroOficio);
        }

    }, [eventoAdicionalCompleto])

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false || (idTipoSolicitacao == '' || autorizador == '' || destinatario == '' || pagador == '' ||
            idTipoSolicitacao == false || autorizador == false || destinatario == false || pagador == false)) {

            setValidated(true);
            event.preventDefault();
        } else {

            event.preventDefault();

            if (idEventoAdicional == undefined) {
                let data = {
                    'Id': 0,
                    'IdTipoSolicitacao': parseInt(idTipoSolicitacao),
                    'IdSolicitacaoFornecimento': parseInt(id),
                    'Autorizador': autorizador,
                    'Destinatario': destinatario,
                    'Pagador': pagador,
                    'Observacao': observacao,
                    'CargaEstimada': cargaEstimada,
                    'NumeroRefletores': numeroRefletores,
                    'NumeroOficio': numeroOficio
                }

                dispatch(InserirEventoAdicionalRequest(data, parseInt(id)));

            } else {
                let data = {
                    'Id': idEventoAdicional,
                    'IdTipoSolicitacao': parseInt(idTipoSolicitacao),
                    'IdSolicitacaoFornecimento': parseInt(id),
                    'Autorizador': autorizador,
                    'Destinatario': destinatario,
                    'Pagador': pagador,
                    'Observacao': observacao,
                    'CargaEstimada': cargaEstimada,
                    'NumeroRefletores': numeroRefletores,
                    'NumeroOficio': numeroOficio
                }

                dispatch(AlterarEventoAdicionalRequest(data, parseInt(id)));
            }

            setIdTipoSolicitacao('');
            setIdSolicitacaoFornecimento('');
            setAutorizador('');
            setDestinatario('');
            setPagador('');
            setObservacao('');
            setCargaEstimada('');
            setNumeroRefletores('');
            setNumeroOficio('');

            dispatch(LimparEventoAdicional())
            setValidated(false);
            onHide(true);
        }
    };

    function FecharModal() {

        setIdTipoSolicitacao('');
        setIdSolicitacaoFornecimento('');
        setAutorizador('');
        setDestinatario('');
        setPagador('');
        setObservacao('');
        setCargaEstimada('');
        setNumeroRefletores('');
        setNumeroOficio('');

        dispatch(LimparEventoAdicional())

        onHide(true);
        setValidated(false);
    }

    return (
        <>
            <Modal
                show={show}
                onHide={FecharModal}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Novo Evento Adicional</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Container className="divBody">
                            <Row>
                                <Col md={12}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={6} controlId="formIdTipoSolicitacao">
                                            <Form.Label>Tipo Solicitação</Form.Label>
                                            <Select

                                                styles={{
                                                    control: (styles) => ({ ...styles, borderColor: validated && idTipoSolicitacao === "" || idTipoSolicitacao === false ? 'red' : '#C0C0C0' }),
                                                    placeholder: (styles) => ({ ...styles, color: validated && idTipoSolicitacao === "" || idTipoSolicitacao === false ? 'red' : '#C0C0C0' })
                                                }}
                                                placeholder="Selecione..."
                                                isClearable={true}
                                                isSearchable={true}
                                                isDisabled={idEventoAdicional != undefined ? true : false}
                                                required
                                                value={listaTipoSolicitacao != undefined && idTipoSolicitacao && listaTipoSolicitacao.filter(m => m.id == idTipoSolicitacao).length > 0 ?
                                                    { value: idTipoSolicitacao, label: listaTipoSolicitacao.filter(m => m.id == idTipoSolicitacao)[0].descricao }
                                                    : null
                                                }
                                                options={
                                                    listaTipoSolicitacao != undefined ?
                                                        listaTipoSolicitacao.filter(x => x.eventoEspecial == true).sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                            value: m.id, label: m.descricao
                                                        }))
                                                        :
                                                        null
                                                }
                                                onChange={(e) => setIdTipoSolicitacao(e !== null ? e.value.toString() : false)}>

                                            </Select>
                                            <Form.Control.Feedback type="invalid">O campo Tipo Solicitacao é obrigatório.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="formAutorizador">
                                            <Form.Label>Autorizador</Form.Label>
                                            <Select

                                                styles={{
                                                    control: (styles) => ({ ...styles, borderColor: validated && autorizador === "" || autorizador === false ? 'red' : '#C0C0C0' }),
                                                    placeholder: (styles) => ({ ...styles, color: validated && autorizador === "" || autorizador === false ? 'red' : '#C0C0C0' })
                                                }}
                                                placeholder="Selecione..."
                                                isClearable={true}
                                                isSearchable={true}
                                                required
                                                value={listaUsuario != undefined && autorizador && listaUsuario.filter(m => m.nome == autorizador).length > 0 ?
                                                    { value: autorizador, label: listaUsuario.filter(m => m.nome == autorizador)[0].nome }
                                                    : null
                                                }
                                                options={
                                                    listaUsuario != undefined ?
                                                        [...listaUsuario].sort((a, b) => a.nome.localeCompare(b.nome)).map(m => ({
                                                            value: m.nome, label: m.nome
                                                        }))
                                                        :
                                                        null
                                                }
                                                onChange={(e) => setAutorizador(e !== null ? e.value.toString() : false)}>

                                            </Select>
                                            <Form.Control.Feedback type="invalid">O campo Autorizador é obrigatório.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={6} controlId="formIdDestinatario">
                                            <Form.Label>Destinatário</Form.Label>
                                            <Select

                                                styles={{
                                                    control: (styles) => ({ ...styles, borderColor: validated && destinatario === "" || destinatario === false ? 'red' : '#C0C0C0' }),
                                                    placeholder: (styles) => ({ ...styles, color: validated && destinatario === "" || destinatario === false ? 'red' : '#C0C0C0' })
                                                }}
                                                placeholder="Selecione..."
                                                isClearable={true}
                                                isSearchable={true}
                                                required
                                                value={listaUsuario != undefined && destinatario && listaUsuario.filter(m => m.nome == destinatario).length > 0 ?
                                                    { value: destinatario, label: listaUsuario.filter(m => m.nome == destinatario)[0].nome }
                                                    : null
                                                }
                                                options={
                                                    listaUsuario != undefined ?
                                                        [...listaUsuario].sort((a, b) => a.nome.localeCompare(b.nome)).map(m => ({
                                                            value: m.nome, label: m.nome
                                                        }))
                                                        :
                                                        null
                                                }
                                                onChange={(e) => setDestinatario(e !== null ? e.value.toString() : false)}>

                                            </Select>
                                            <Form.Control.Feedback type="invalid">O campo Destinatário é obrigatório.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="formPagador">
                                            <Form.Label>Pagador</Form.Label>
                                            <Select

                                                styles={{
                                                    control: (styles) => ({ ...styles, borderColor: validated && pagador === "" || pagador === false ? 'red' : '#C0C0C0' }),
                                                    placeholder: (styles) => ({ ...styles, color: validated && pagador === "" || pagador === false ? 'red' : '#C0C0C0' })
                                                }}
                                                placeholder="Selecione..."
                                                isClearable={true}
                                                isSearchable={true}
                                                required
                                                value={listaOrgao != undefined && pagador && listaOrgao.filter(m => m.descricao == pagador).length > 0 ?
                                                    { value: pagador, label: listaOrgao.filter(m => m.descricao == pagador)[0].descricao }
                                                    : null
                                                }
                                                options={
                                                    listaOrgao != undefined ?
                                                        [...listaOrgao].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                            value: m.descricao, label: m.descricao
                                                        }))
                                                        :
                                                        null
                                                }
                                                onChange={(e) => setPagador(e !== null ? e.value.toString() : false)}>

                                            </Select>
                                            <Form.Control.Feedback type="invalid">O campo Pagador é obrigatório.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    {
                                        idTipoSolicitacao == '' ?
                                            <div>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formEndereco">
                                                        <Form.Label>Observação</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            type="text"
                                                            value={observacao}
                                                            onChange={(e) => setObservacao(e.target.value)}
                                                            maxLength="500"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={6} controlId="formEndereco">
                                                        <Form.Label>Carga Estimada</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            type="text"
                                                            value={cargaEstimada}
                                                            onChange={(e) => setCargaEstimada(e.target.value)}
                                                            maxLength="200"
                                                            disabled={true}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Carga Estimada é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md={6} controlId="formEndereco">
                                                        <Form.Label>Número de Refletores</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            type="text"
                                                            value={numeroRefletores}
                                                            onChange={(e) => setNumeroRefletores(e.target.value)}
                                                            maxLength="200"
                                                            disabled={true}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Número de Refletores é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} md={6} controlId="formEndereco">
                                                        <Form.Label>Número Oficio</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            type="text"
                                                            value={numeroOficio}
                                                            disabled
                                                            onChange={(e) => setNumeroOficio(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            </div>
                                            :
                                            listaTipoSolicitacao != undefined && listaTipoSolicitacao.filter(x => x.id == parseInt(idTipoSolicitacao)).length > 0 &&
                                                listaTipoSolicitacao.filter(x => x.id == parseInt(idTipoSolicitacao))[0].servico ?
                                                <div>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Observação</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={observacao}
                                                                onChange={(e) => setObservacao(e.target.value)}
                                                                maxLength="500"
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Observação é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Carga Estimada</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={cargaEstimada}
                                                                onChange={(e) => setCargaEstimada(e.target.value)}
                                                                maxLength="200"
                                                                disabled={true}
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Carga Estimada é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Número de Refletores</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={numeroRefletores}
                                                                onChange={(e) => setNumeroRefletores(e.target.value)}
                                                                maxLength="200"
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Número de Refletores é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Número Oficio</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={numeroOficio}
                                                                disabled
                                                                onChange={(e) => setNumeroOficio(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                                :
                                                <div>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Observação</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={observacao}
                                                                onChange={(e) => setObservacao(e.target.value)}
                                                                maxLength="500"
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Observação é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Carga Estimada</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={cargaEstimada}
                                                                onChange={(e) => setCargaEstimada(e.target.value)}
                                                                maxLength="200"
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Carga Estimada é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Número de Refletores</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                disabled={true}
                                                                value={numeroRefletores}
                                                                onChange={(e) => setNumeroRefletores(e.target.value)}
                                                                maxLength="200"
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Número de Refletores é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Número Oficio</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={numeroOficio}
                                                                disabled
                                                                onChange={(e) => setNumeroOficio(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                    }

                                    {
                                        idEventoAdicional == undefined
                                            ?
                                            <Col md={6}>

                                                <Button type="submit"
                                                    variant="success" >Cadastrar</Button>
                                            </Col>

                                            : <Col md={6}>

                                                <Button type="submit"
                                                    variant="success" >Editar</Button>
                                            </Col>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}