import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { InserirNovaSenhaRequest } from '../../store/modules/usuario/actions';

export default function ModalEsqueceuSenha({show, onHide}) {

  const dispatch = useDispatch();
  
  const [email, setEmail] = useState("");

  function NovaSenha(){
   
    dispatch(InserirNovaSenhaRequest(email));
    setEmail("");
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="formTitulo">
        Esqueceu a senha
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control 
          type="text" 
          value={email}
          onChange={(e) => setEmail(e.target.value)} 
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
      <Button onClick={NovaSenha}>
        Enviar nova senha
      </Button>
      </Modal.Footer>
    </Modal>
  );
}