export function ListarGrupoCustoRequest() {
  return {
    type: 'LISTAR_GRUPO_CUSTO_REQUEST'
  }
}

export function ListarGrupoCustoResponse(listaGrupoCusto) {
  return {
    type: 'LISTAR_GRUPO_CUSTO_SUCCESS',
    listaGrupoCusto
  }
}

export function InserirGrupoCustoRequest(data, idContrato) {
  return {
    type: 'INSERIR_GRUPO_CUSTO_REQUEST',
    data, idContrato
  }
}

export function AlterarGrupoCustoRequest(data, idContrato) {
  return {
    type: 'ALTERAR_GRUPO_CUSTO_REQUEST',
    data, idContrato
  }
}

export function ExcluirGrupoCustoRequest(id) {
  return {
    type: 'EXCLUIR_GRUPO_CUSTO_REQUEST',
    id
  }
}

export function ListarGrupoCustoCompletoRequest(id) {
  return {
    type: 'LISTAR_GRUPO_CUSTO_COMPLETO_REQUEST',
    id
  }
}

export function ListarGrupoCustoCompletoResponse(listaCompletaGrupoCusto) {
  return {
    type: 'LISTAR_GRUPO_CUSTO_COMPLETO_RESPONSE',
    listaCompletaGrupoCusto
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}