import produce from 'immer';

const initialState = {
    listaFormularioCaracteristica: undefined,
    carregando: true
};

export default function formularioCaracteristica(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_FORMULARIO_CARACTERISTICA_RESPONSE':
            return produce(state, draft => {
                draft.listaFormularioCaracteristica = action.listaFormularioCaracteristica;
            });

        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });

        default:
            return state;
    }
}