import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { ListarItemCustoResponse, ListarItemCustoCompletoResponse } from './actions';
import { BuscarContratoPorIdRequest } from '../contrato/actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import {  Carregando } from '../contrato/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarItemCustoRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/ItemCusto`, { headers: headerParams.token });

    yield put(ListarItemCustoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    }  

    yield put(ListarItemCustoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* InserirItemCustoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/itemCusto`, model.data, { headers: headerParams.token });

    yield put(BuscarContratoPorIdRequest(model.idContrato));
    yield put(Carregando(true));    

    toast.success(`Item foi cadastrado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarItemCustoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

      yield call(api.put, `/api/itemCusto`, model.data, { headers: headerParams.token });

      

      //yield put(Carregando(true));
      yield put(BuscarContratoPorIdRequest(model.idContrato));
    toast.success(`Item foi atualizado com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ExcluirItemCustoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/item/${model.id}`, { headers: headerParams.token });
    yield put(Carregando(true));    

    toast.success(`Item id: ${model.id} foi excluido!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ListarItemCustoCompletoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.post, `/api/itemCusto/listarCompleto`, model.id, { headers: headerParams.token });

    if (result.data.result == null) {
      yield put(ListarItemCustoCompletoResponse([]));
    } else {
      yield put(ListarItemCustoCompletoResponse(result.data.result));
    }
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
        history.push(`/erro`);
    } else if (e.response.data.statusCode != 404) {
        toast.error(e.response.data.message);  
    } 
  
    yield put(ListarItemCustoCompletoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));      
  }
}

export default all([
  takeLatest('LISTAR_ITEM_CUSTO_REQUEST', ListarItemCustoRequest),
  takeLatest('INSERIR_ITEM_CUSTO_REQUEST', InserirItemCustoRequest),
  takeLatest('ALTERAR_ITEM_CUSTO_REQUEST', AlterarItemCustoRequest),
  takeLatest('EXCLUIR_ITEM_CUSTO_REQUEST', ExcluirItemCustoRequest),
  takeLatest('LISTAR_ITEM_CUSTO_COMPLETO_REQUEST', ListarItemCustoCompletoRequest)
])