import { useEffect, useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PatchedPagination } from "../PatchedPagination/patchedPagination";
import {
    AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest,
    BaixarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest,
    CarregarImagemMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest,
    ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest,
    InserirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest,
    ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest
} from "../../store/modules/medicaoAutorizacaoFornecimentoOcorrenciaRegFoto/actions";
import { toast } from "react-toastify";
import MaterialTable from 'material-table';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function ModalOcorrenciaMedicao({ showModal, onHide, idMedicao, data }) {

    const dispatch = useDispatch();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto =
        useSelector(state => state.medicaoAutorizacaoFornecimentoOcorrenciaRegFoto.listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto);

    const [listaFile, setListaFile] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showFoto, setShowFoto] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFotoClose = () => setShowFoto(false);
    const handleFotoShow = () => setShowFoto(true);

    useEffect(() => {

        moment.locale('pt-br');

        if (data != undefined && data != '') {
            dispatch(ListarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(data));
        }

    }, [data])

    function InserirRegistroFotograficoOcorrencia() {

        let formData = new FormData();

        if (listaFile.length > 0) {
            for (var x = 0; x < listaFile.length; x++) {
                formData.append('File', listaFile[x]);
                formData.append('IdMedicaoAutorizacaoFornecimentoOcorrencia', data);
                formData.append('IdUsuario', usuario.id);
                if (observacao != '')
                    formData.append('Observacao', observacao);
            }
            setObservacao('');
            setListaFile([]);
            dispatch(InserirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(formData, data));
            handleClose();
        } else {
            toast.error('Para continuar insira um documento');
        }
    }

    function AlterarRegistroFotograficoOcorrencia(registroFotograficoOcorrencia, novoValorCampoEditado, colunaEditada) {

        // Valida alteração do campo nome arquivo
        if (colunaEditada.field == "nomeRegistroFotografico") {

            if (novoValorCampoEditado == null || novoValorCampoEditado == undefined
                || novoValorCampoEditado == ' ' || novoValorCampoEditado == '') {

                toast.error(`Nome do registro fotográfico inválido`);
                return;
            }

            if (novoValorCampoEditado.length > 150) {
                toast.error(`O nome do registro fotográfico não pode conter mais que 150 caracteres`);
                return;
            }
        }

        // Valida alteração do campo observação
        if (colunaEditada.field == "observacao") {
            if (novoValorCampoEditado.length > 500) {
                toast.error(`A observação não pode conter mais que 500 caracteres`);
                return;
            }
        }

        if (colunaEditada != null) {

            let data = {
                'Id': registroFotograficoOcorrencia.id,
                'IdMedicaoAutorizacaoFornecimentoOcorrencia': registroFotograficoOcorrencia.idMedicaoAutorizacaoFornecimentoOcorrencia,
                'Latitude': registroFotograficoOcorrencia.latitude,
                'Longitude': registroFotograficoOcorrencia.longitude,
                'FotoGuid': registroFotograficoOcorrencia.fotoGuid,
                'DataInclusao': registroFotograficoOcorrencia.dataInclusaoApi,
                'IdUsuario': registroFotograficoOcorrencia.idUsuario,
                'NomeRegistroFotografico': colunaEditada.field == "observacao" ? registroFotograficoOcorrencia.nomeRegistroFotografico : novoValorCampoEditado,
                'DescricaoExtensaoArquivo': registroFotograficoOcorrencia.descricaoExtensaoArquivo,
                'Observacao': colunaEditada.field == "observacao" ? novoValorCampoEditado : registroFotograficoOcorrencia.observacao
            };

            dispatch(AlterarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(data, data.IdMedicaoAutorizacaoFornecimentoOcorrencia));
        }
    }

    function ExcluirRegistroFotograficoOcorrencia(idRegistroFotograficoOcorrencia) {
        dispatch(ExcluirMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(idRegistroFotograficoOcorrencia, data));
    }

    function BaixarRegistroFotograficoOcorrencia(idRegistroFotograficoOcorrencia) {
        dispatch(BaixarMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(idRegistroFotograficoOcorrencia));
    }

    function VisualizarRegistroFotograficoOcorrencia(registroFotograficoOcorrencia) {
        dispatch(CarregarImagemMedicaoAutorizacaoFornecimentoOcorrenciaRegFotoRequest(registroFotograficoOcorrencia.id));
        handleFotoShow();
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                data={data}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Foto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Col md={12}>
                            {
                                <MaterialTable
                                    components={{
                                        Pagination: PatchedPagination,
                                    }}
                                    title={
                                        <Button variant="success" onClick={handleShow} style={{ width: '100%' }}>Adicionar Fotos</Button>
                                    }
                                    columns={[
                                        {
                                            title: "Número do Registro", field: 'id', defaultSort: 'desc', editable: 'never', cellStyle: {
                                                width: '10%',
                                            }
                                        },
                                        {
                                            title: "Nome", field: 'nomeRegistroFotografico', cellStyle: {
                                                width: '22.5%'
                                            }
                                        },
                                        {
                                            title: "Usuário", field: 'usuario', editable: 'never', cellStyle: {
                                                width: '22.5%'
                                            }
                                        },
                                        {
                                            title: "Data", field: 'dataInclusao', editable: 'never', cellStyle: {
                                                width: '22.5%'
                                            }
                                        },
                                        {
                                            title: "Observação", field: 'observacao', cellStyle: {
                                                width: '22.5%'
                                            },
                                            render: rowData => rowData.observacao == '' ?
                                                <div className="spanVazio"></div>
                                                : rowData.observacao
                                        }
                                    ]}
                                    data={
                                        listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto != undefined ?
                                            listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto.map(lista => {
                                                return {
                                                    id: lista.id,
                                                    idMedicaoAutorizacaoFornecimentoOcorrencia: lista.idMedicaoAutorizacaoFornecimentoOcorrencia,
                                                    latitude: lista.latitude,
                                                    longitude: lista.longitude,
                                                    fotoGuid: lista.fotoGuid,
                                                    nomeRegistroFotografico: lista.nomeRegistroFotografico,
                                                    descricaoExtensaoArquivo: lista.descricaoExtensaoArquivo,
                                                    usuario: lista.usuario.nome,
                                                    dataInclusao: moment(lista.dataInclusao).format('L LT'),
                                                    observacao: lista.observacao,
                                                    dataInclusaoApi: lista.dataInclusao,
                                                    idUsuario: lista.idUsuario
                                                }
                                            })
                                            :
                                            []
                                    }
                                    cellEditable={{
                                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    AlterarRegistroFotograficoOcorrencia(rowData, newValue, columnDef);
                                                    resolve()
                                                }, 1000)
                                            });
                                        }
                                    }}
                                    editable={{
                                        onRowDelete:
                                            oldData =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        ExcluirRegistroFotograficoOcorrencia(oldData.id);
                                                        resolve()
                                                    }, 1000)
                                                })
                                    }}
                                    options={{
                                        minBodyHeight: 500,
                                        paging: true,
                                        pageSize: 10, // Início
                                        emptyRowsWhenPaging: false,
                                        pageSizeOptions: [10, 50, 200, 300],
                                        filterRowStyle: {
                                            backgroundColor: "#FAFAFA"
                                        },
                                        headerStyle: {
                                            backgroundColor: '#454545',
                                            color: '#FFF',
                                            fontWeight: "bold"
                                        },
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: 'visibility',
                                            onClick: (event, rowData) => { VisualizarRegistroFotograficoOcorrencia(rowData) }
                                        }),
                                        {
                                            icon: 'download',
                                            onClick: (event, rowData) => BaixarRegistroFotograficoOcorrencia(rowData.id)
                                        }
                                    ]}
                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum registro para exibir',
                                            editRow: {
                                                deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                            },
                                            deleteTooltip: ''
                                        },
                                        toolbar: {
                                            searchTooltip: '',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: "",
                                            exportCSVName: "Exportar como CSV",
                                            exportPDFName: "Exportar como PDF",
                                        },
                                        pagination: {
                                            labelRowsSelect: 'linhas',
                                            labelDisplayedRows: '{count} de {from}-{to}',
                                            firstTooltip: '',
                                            previousTooltip: '',
                                            nextTooltip: '',
                                            lastTooltip: ''
                                        },
                                        grouping: {
                                            placeholder: 'Arraste a coluna aqui para agrupar',
                                            groupedBy: 'Agrupado por:'
                                        }
                                    }}
                                />
                            }
                        </Col>
                    </div>

                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Fotos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formFoto">
                                        <div className="divModal">
                                            <DropzoneArea
                                                initialFiles={[listaMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto]}
                                                onChange={(files) => setListaFile(files)}
                                                filesLimit={10}
                                                acceptedFiles={['image/png, image/jpeg, image/jpg']}
                                                showPreviewsInDropzone={true}
                                                useChipsForPreview
                                                getFileLimitExceedMessage={() => `Só é permitido adicionar 10 arquivos`}
                                                getFileAddedMessage={(fileName) => `Arquivo ${fileName} foi adicionado com sucesso.`}
                                                getFileRemovedMessage={(fileName) => `Arquivo ${fileName} foi removido.`}
                                                disableRejectionFeedback={true}
                                                dropzoneText="Arraste ou clique aqui para selecionar os arquivos"
                                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                maxFileSize={30000000}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md={12} controlId="formDescricaoObjeto">
                                        <Form.Label>Observacao</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={observacao}
                                            onChange={e => setObservacao(e.target.value)} />
                                        <Form.Control.Feedback type="invalid">O campo Descrição Objeto é obrigatório.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={InserirRegistroFotograficoOcorrencia}>Salvar Foto</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showFoto} onHide={handleFotoClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={12} controlId="formFoto">
                                        <div id="divImagemFotoMedicaoAutorizacaoFornecimentoOcorrenciaRegFoto">
                                        </div>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Modal.Body>
                    </Modal>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
