import { useEffect, useState } from "react";
import { Form, Row, Col, Alert, ProgressBar, Button, InputGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BuscarCompletoAutorizacaoFornecimentoRequest, LimparAutorizacao } from "../../../store/modules/autorizacaoFornecimento/actions";
import { ListarStatusAutorizacaoFornecimentoRequest } from "../../../store/modules/statusAutorizacaoFornecimento/actions";
import { ListarAutorizacaoFornecimentoStatusRequest } from "../../../store/modules/autorizacaoFornecimentoStatus/actions";
import { ListarAutorizacaoFornecimentoSubitemCustoRequest } from "../../../store/modules/autorizacaoFornecimentoSubitemCusto/actions";
import { toast } from "react-toastify";
import {
    AlterarPrazoSolicitacaoFornecimentoRequest,
    BuscarSolicitacaoFornecimentoPorIdRequest
} from '../../../store/modules/solicitacaoFornecimento/actions';
import {
    ListarDadosExportarPDFAutorizacaoFornecimentoRequest,
    ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest
} from "../../../store/modules/relatorio/actions";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { RiCheckboxCircleLine } from "react-icons/ri";
import { BuscarUsuarioPorIdRequest } from '../../../store/modules/usuario/actions';
import { ListarContratoUsuarioRequest } from '../../../store/modules/contratoUsuario/actions';
import moment from 'moment';
import PdfAutorizacaoFornecimento from "../../../components/PdfAutorizacaoFornecimento/pdfAutorizacaoFornecimento";
import Loading from '../../../components/Loading/loading';
import 'moment/locale/pt-br';

export default function AutorizacaoFornecimento() {

    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const { idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const listaAutorizacaoFornecimentoStatus = useSelector(state => state.autorizacaoFornecimentoStatus.listaAutorizacaoFornecimentoStatus);
    const listaRelatorioAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.relatorio.listaRelatorioAutorizacaoFornecimentoSubitemCusto);
    const listaDadosExportarPDFAutorizacaoFornecimento = useSelector(state => state.relatorio.listaDadosExportarPDFAutorizacaoFornecimento);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const listaAutorizacaoFornecimentoSubitemCusto = useSelector(state => state.autorizacaoFornecimentoSubitemCusto.listaAutorizacaoFornecimentoSubitemCusto);
    const carregandoPDF = useSelector(state => state.relatorio.carregando);

    const [numeroAF, setNumeroAF] = useState("");
    const [numeroSolicitacao, setNumeroSolicitacao] = useState("");
    const [numeroContrato, setNumeroContrato] = useState("");
    const [observacao, setObservacao] = useState("");
    const [dataInclusao, setDataInclusao] = useState("");
    const [numeroOficio, setNumeroOficio] = useState('');
    const [local, setLocal] = useState("");
    const [assunto, setAssunto] = useState("");
    const [endereco, setEndereco] = useState("");
    const [bairro, setBairro] = useState("");
    const [regiao, setRegiao] = useState("");
    const [numeroProcesso, setNumeroProcesso] = useState("");
    const [numeroHoras, setNumeroHoras] = useState("");
    const [interessado, setInteressado] = useState("");
    const [numeroOrcamento, setNumeroOrcamento] = useState("");
    const [validated, setValidate] = useState(false);
    const [dados, setDados] = useState('');
    const [valorObra, setValorObra] = useState('');
    const [criador, setCriador] = useState('');
    const [empenhos, setEmpenhos] = useState([]);
    const [carregandoTime, setCarregandoTime] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Permissões
    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r === "AutorizacaoFornecimentoMenu").length === 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r === "AutorizacaoFornecimentoInserir").length === 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r === "AutorizacaoFornecimentoAlterar").length === 1) {
            setAlterar(true);
        }

    }, [])

    var resultado = 0;

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarContratoUsuarioRequest());
        dispatch(ListarStatusAutorizacaoFornecimentoRequest());
        if (idAutorizacaoFornecimento !== undefined) {
            dispatch(BuscarCompletoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento));
            dispatch(ListarAutorizacaoFornecimentoStatusRequest(idAutorizacaoFornecimento));
            dispatch(ListarRelatorioAutorizacaoFornecimentoSubItemCustoRequest(idAutorizacaoFornecimento));

            var data = {
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento)
            }

            dispatch(ListarAutorizacaoFornecimentoSubitemCustoRequest(data));
        }

        if (usuario != null) {
            dispatch(BuscarUsuarioPorIdRequest(usuario.id));
        }

    }, [])

    useEffect(() => {

        if (autorizacaoFornecimentoCompleto !== undefined && autorizacaoFornecimentoCompleto !== null) {
            dispatch(BuscarSolicitacaoFornecimentoPorIdRequest(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.id));
        }

        // Verifica se tem todos os dados da autorizacaoFornecimento, para poder carregar os states
        if (autorizacaoFornecimentoCompleto != null && listaAutorizacaoFornecimentoStatus !== undefined &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
            autorizacaoFornecimentoCompleto.id === parseInt(idAutorizacaoFornecimento) && listaAutorizacaoFornecimentoStatus !== undefined) {

            CarregarAutorizacaoFornecimento();
        }

    }, [autorizacaoFornecimentoCompleto, listaAutorizacaoFornecimentoStatus])

    useEffect(() => {

        var valorObraAtual = 0;

        if (listaAutorizacaoFornecimentoSubitemCusto !== undefined) {

            listaAutorizacaoFornecimentoSubitemCusto.map(lista => {

                valorObraAtual += (lista.subitemCusto.valorUnitario * lista.quantidade)
            })

        }
        setValorObra("R$" + valorObraAtual.toFixed(2));

        var empenhos = [];

        if (listaAutorizacaoFornecimentoSubitemCusto !== undefined &&
            listaAutorizacaoFornecimentoSubitemCusto !== null &&
            listaAutorizacaoFornecimentoSubitemCusto.length > 0 &&
            listaAutorizacaoFornecimentoSubitemCusto[0].descricaoEmpenho !== null &&
            listaAutorizacaoFornecimentoSubitemCusto[0].descricaoEmpenho !== undefined &&
            listaAutorizacaoFornecimentoSubitemCusto[0].descricaoEmpenho.length > 0) {
            empenhos = listaAutorizacaoFornecimentoSubitemCusto[0].descricaoEmpenho.map((item) => (
                ` ${item}`
            ))
        }

        setEmpenhos(empenhos);

    }, [listaAutorizacaoFornecimentoSubitemCusto])

    useEffect(() => () =>
        dispatch(LimparAutorizacao())
        , []);

    function ListarProjecao() {

        //calculo da projeção na barra de progresso, valorTotal = valor total da af; valorAtual = valor dos subitens ja finalizados 
        if (listaRelatorioAutorizacaoFornecimentoSubitemCusto !== undefined && listaRelatorioAutorizacaoFornecimentoSubitemCusto.length !== 0) {
            resultado = (listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorAtual / listaRelatorioAutorizacaoFornecimentoSubitemCusto.valorTotal) * 100;
        }

        return resultado;

    }

    function CarregarAutorizacaoFornecimento() {

        setNumeroAF(autorizacaoFornecimentoCompleto.numeroAf);
        setNumeroSolicitacao(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.id);
        setNumeroContrato(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.contrato.descricao);
        setObservacao(autorizacaoFornecimentoCompleto.observacaoAutorizacaoFornecimento);
        setDataInclusao(autorizacaoFornecimentoCompleto.dataInclusao);
        setNumeroOficio(autorizacaoFornecimentoCompleto.numeroOficio);
        setLocal(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.descricaoLocal);
        setAssunto(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.assunto);
        setNumeroProcesso(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.numeroProcesso);
        setEndereco(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.endereco);
        setBairro(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.bairro.descricao);
        setRegiao(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.regiao.descricao);
        setNumeroHoras(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.prazoDias);
        setNumeroOrcamento(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.numeroOrcamento);
        setInteressado(autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.interessado != null ?
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.interessado.nome : '');
        setCriador(listaAutorizacaoFornecimentoStatus !== undefined && listaAutorizacaoFornecimentoStatus !== null &&
            listaAutorizacaoFornecimentoStatus.filter(x => x.statusAutorizacaoFornecimento.iniciaAutorizacao == true)[0] !== undefined ? listaAutorizacaoFornecimentoStatus.filter(x =>
                x.statusAutorizacaoFornecimento.iniciaAutorizacao == true)[0].usuario.nome : '');

        setCarregandoTime(true);
    }

    function exportaFotos() {
        dispatch(ListarDadosExportarPDFAutorizacaoFornecimentoRequest({ 'IdAutorizacaoFornecimento': idAutorizacaoFornecimento }));
    }

    function MudarPrazo() {

        // Verifica permissões do usuário
        var lista = [];

        if (listaContratoUsuario != undefined && autorizacaoFornecimentoCompleto != null &&
            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null) {
            lista = listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)
        }

        if (lista.length > 0 && lista[0].editaPrazoAutorizacao === true) {

            if (numeroHoras != '' && solicitacaoFornecimento != undefined) {

                let solicitacaoCompleta = {
                    'Id': solicitacaoFornecimento == null ? 0 : parseInt(solicitacaoFornecimento.id),
                    'PrazoDias': parseInt(numeroHoras),
                }

                setDados(solicitacaoCompleta);

                dispatch(AlterarPrazoSolicitacaoFornecimentoRequest(solicitacaoCompleta));
            }
        } else {
            toast.error("Você não possui permissão para editar o prazo na autorização!");
        }

        //TODO montar o objeto alterando o prazoDias com o useState NumeroHoras
        //var solicitacao = autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento;

    }

    function MontarPDF() {

        if (idAutorizacaoFornecimento != undefined) {
            handleShow();
            dispatch(ListarDadosExportarPDFAutorizacaoFornecimentoRequest({ 'IdAutorizacaoFornecimento': idAutorizacaoFornecimento }));
        }
    }

    return (
        <>
            {
                carregandoTime ?

                    <div>
                        <div className="tamanhoTela">
                            <ProgressBar min={0} max={100} now={ListarProjecao()} animated label={`${parseFloat(resultado.toFixed(2))}%`} />
                        </div>
                        <Form>
                            <div className="tamanhoTela">

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={3} controlId="formNumeroAF">
                                        <Form.Label>Número da Autorização</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroAF}
                                            onChange={(e) => setNumeroAF(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={3} controlId="formNumeroSolicitacao">
                                        <Form.Label>Número da Solicitação</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroSolicitacao}
                                            onChange={(e) => setNumeroSolicitacao(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formNumeroSolicitacao">
                                        <Form.Label>Criador da Autorização</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={criador}
                                            onChange={(e) => setCriador(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formNumeroContrato">
                                        <Form.Label>Contrato</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroContrato}
                                            onChange={(e) => setNumeroContrato(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formNumeroSolicitacao">
                                        <Form.Label>Assunto</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={assunto}
                                            onChange={(e) => setAssunto(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                        <Form.Label>Número Oficio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroOficio}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formNumeroAF">
                                        <Form.Label>Número Processo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={numeroProcesso}
                                            onChange={(e) => setNumeroProcesso(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formNumeroSolicitacao">
                                        <Form.Label>Local</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={local}
                                            onChange={(e) => setLocal(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formNumeroSolicitacao">
                                        <Form.Label>Bairro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={bairro}
                                            onChange={(e) => setBairro(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formNumeroAF">
                                        <Form.Label>Região</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={regiao}
                                            onChange={(e) => setRegiao(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formPrazoDias">
                                        <Form.Label>Prazo Total (dias)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                value={numeroHoras}
                                                onChange={e => setNumeroHoras(e.target.value)}
                                                required
                                                disabled={
                                                    // Verifica permissões do usuário
                                                    listaContratoUsuario != undefined && autorizacaoFornecimentoCompleto != undefined &&
                                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null
                                                        && listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato).length > 0 ?
                                                        listaContratoUsuario.filter(x => x.idUsuario === usuario.id &&
                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato === x.idContrato)[0].editaPrazoAutorizacao === true ?
                                                            false : true
                                                        : true
                                                }
                                                maxLength="10"
                                                isInvalid={validated && numeroHoras === "" ? true : false}
                                                isValid={validated && numeroHoras !== "" ? true : false}
                                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                            />
                                            <Button variant='success' onClick={() => { MudarPrazo() }}>
                                                <RiCheckboxCircleLine size={20} />
                                            </Button>
                                            <Form.Control.Feedback type="invalid">O campo Prazo é obrigatório.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formData">
                                        <Form.Label>Data de Início</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                autorizacaoFornecimentoCompleto != undefined ?
                                                    moment(autorizacaoFornecimentoCompleto.dataInclusao).format('L LT')
                                                    :
                                                    ''
                                            }
                                            onChange={(e) => setDataInclusao(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    {
                                        autorizacaoFornecimentoCompleto != null && parseInt((moment.duration(moment(moment((autorizacaoFornecimentoCompleto.dataFinalizacao)).add('hours', 2)).diff(moment(autorizacaoFornecimentoCompleto.dataInclusao)
                                            .add('days', autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao
                                                .solicitacaoFornecimento.prazoDias))).asDays())) > 0 ?
                                            <Form.Group as={Col} md={6} controlId="formNumeroObservacao">
                                                <Form.Label>Dias de Atraso</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    maxLength="200"
                                                    value={autorizacaoFornecimentoCompleto != null ? parseInt((moment.duration(moment(moment((autorizacaoFornecimentoCompleto.dataFinalizacao)).add('hours', 2)).diff(moment(autorizacaoFornecimentoCompleto.dataInclusao)
                                                        .add('days', autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao
                                                            .solicitacaoFornecimento.prazoDias))).asDays())) + ' dias' : ''}
                                                    onChange={(e) => setObservacao(e.target.value)}
                                                    disabled
                                                    style={{ 'backgroundColor': '#F7D1D1', 'borderColor': 'red' }}
                                                />
                                            </Form.Group>
                                            :
                                            <Form.Group as={Col} md={6} controlId="formNumeroObservacao">
                                                <Form.Label>Dias Restantes</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    maxLength="200"
                                                    value={autorizacaoFornecimentoCompleto != null ? parseInt((moment.duration(moment(moment(autorizacaoFornecimentoCompleto.dataInclusao)
                                                        .add('days', autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao
                                                            .solicitacaoFornecimento.prazoDias)).diff(moment((autorizacaoFornecimentoCompleto.dataFinalizacao)).add('hours', 2))).asDays())) + ' dia(s) e ' +
                                                        (parseInt((moment.duration(moment(moment(autorizacaoFornecimentoCompleto.dataInclusao)
                                                            .add('days', autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao
                                                                .solicitacaoFornecimento.prazoDias)).diff(moment((autorizacaoFornecimentoCompleto.dataFinalizacao)).add('hours', 2))).asHours())) - parseInt((moment.duration(moment(moment(autorizacaoFornecimentoCompleto.dataInclusao)
                                                                    .add('days', autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao
                                                                        .solicitacaoFornecimento.prazoDias)).diff(moment((autorizacaoFornecimentoCompleto.dataFinalizacao)).add('hours', 2))).asDays())) * 24) + ' hora(s)' : ''}
                                                    onChange={(e) => setObservacao(e.target.value)}
                                                    disabled
                                                />
                                            </Form.Group>
                                    }
                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md={6} controlId="formNumeroObservacao">
                                        <Form.Label>Número Orçamento</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="200"
                                            value={numeroOrcamento}
                                            onChange={(e) => setNumeroOrcamento(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="formNumeroObservacao">
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="500"
                                            value={observacao}
                                            onChange={(e) => setObservacao(e.target.value)}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    {
                                        autorizacaoFornecimentoCompleto != null && autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.interessado != null &&
                                            interessado != '' && interessado != null ?


                                            <Form.Group as={Col} md={6} controlId="formNumeroAF">
                                                <Form.Label>Interessado</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={interessado}
                                                    onChange={(e) => setInteressado(e.target.value)}
                                                    disabled
                                                />
                                            </Form.Group>


                                            :
                                            ''
                                    }

                                    <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                        <Form.Label>Valor da Obra</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={valorObra}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} controlId="formNumeroOficio">
                                        <Form.Label>Empenhos que os itens dessa af pertence:</Form.Label>
                                        <Form.Control
                                            style={{ "overflow": "auto" }}
                                            type="text"
                                            value={empenhos}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row> <br />

                                <Row className="mb-3">
                                    <Col md={4}>
                                        <Button variant="success" onClick={MontarPDF} style={{ width: '100%' }}>
                                            Exportar Autorização Fornecimento por PDF
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>


                        <Col md={12}>
                            {
                                idAutorizacaoFornecimento == undefined ?
                                    ''
                                    :
                                    // Verifica se o status da autorização é igual a cancelado
                                    listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                        listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0 &&
                                        listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                            listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0
                                        ?
                                        <Col md={12}>
                                            <Alert variant={`danger`} style={{ width: '100%' }}>
                                                A Autorização está Cancelada!
                                            </Alert>
                                        </Col>
                                        :
                                        // Verifica se o status da autorização é igual a finalizado
                                        listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                            listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                            listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0
                                            ?
                                            <Col md={12}>
                                                <Alert variant={`success`} style={{ width: '100%' }}>
                                                    A Autorização está Finalizada!
                                                </Alert>
                                            </Col>
                                            :
                                            ''
                            }
                        </Col>

                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Deseja exportar a autorização?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Row>
                                    <Col md={12}>
                                        {
                                            carregandoPDF && listaDadosExportarPDFAutorizacaoFornecimento != null ?
                                                <PDFDownloadLink onClick={exportaFotos} document={
                                                    <PdfAutorizacaoFornecimento
                                                        data={listaDadosExportarPDFAutorizacaoFornecimento != null ? listaDadosExportarPDFAutorizacaoFornecimento : null}
                                                    />} fileName="autorizacaoFornecimento.pdf">
                                                    <Button
                                                        variant="success"
                                                        style={{ width: '100%' }}>
                                                        Exportar Autorização Fornecimento por PDF
                                                    </Button>
                                                    {({ blob, url, loading, error }) => (loading ? 'Carregando autorização fornecimento...' : 'Exportar como PDF')}
                                                </PDFDownloadLink>
                                                :
                                                <Loading />
                                        }

                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    :
                    <Loading />
            }
        </>
    )
}