import produce from 'immer';

const initialState = {
    listaTipoContratoVersao: undefined,
    carregando: true
};

export default function tipoContratoVersao(state = initialState, action) {

    switch (action.type) {

        case 'LISTAR_TIPO_CONTRATO_VERSAO_RESPONSE':
            return produce(state, draft => {
                draft.listaTipoContratoVersao = action.listaTipoContratoVersao
            });
        
        case 'CARREGANDO':
            return produce(state, draft => {
                draft.carregando = action.status
            });        

        default:
            return state;
    }
}