import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarTipoDocumentoArquivoResponse } from './actions';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarTipoDocumentoArquivoRequest(){

  try {
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/TipoDocumentoArquivo`, { headers:headerParams.token });

    yield put(ListarTipoDocumentoArquivoResponse(result.data.result)); 
    yield put(Carregando(true));    
    
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      } 
    
    yield put(ListarTipoDocumentoArquivoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* InserirTipoDocumentoArquivoRequest(model){

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/TipoDocumentoArquivo`, model.data, { headers:headerParams.token });

    const result = yield call(api.get, `/api/TipoDocumentoArquivo`, { headers:headerParams.token });

    yield put(ListarTipoDocumentoArquivoResponse(result.data.result)); 
    yield put(Carregando(true));    
  
    toast.success(`Tipo Documento foi cadastrado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));      
    yield put(Carregando(true));    
  }
}

function* AlterarTipoDocumentoArquivoRequest(model){

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/TipoDocumentoArquivo`, model.data, { headers:headerParams.token });

    const result = yield call(api.get, `/api/TipoDocumentoArquivo`, { headers:headerParams.token });

    yield put(ListarTipoDocumentoArquivoResponse(result.data.result)); 
    yield put(Carregando(true));    
  
    toast.success(`Tipo Documento foi atualizado com sucesso!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }
  
    yield put(ErrorResponse(e));      
    yield put(Carregando(true));    
  }
}

function* ExcluirTipoDocumentoArquivoRequest(model){

  try{

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/TipoDocumentoArquivo/${model.id}`, { headers:headerParams.token });

    const result = yield call(api.get, `/api/TipoDocumentoArquivo`, { headers:headerParams.token });

    yield put(ListarTipoDocumentoArquivoResponse(result.data.result)); 
    yield put(Carregando(true));    

    toast.success(`Tipo Documento id: ${model.id} foi excluido!`);
  
  }catch(e){

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));   
    yield put(Carregando(true));    
  }
}

export default all([
  takeLatest('LISTAR_TIPO_DOCUMENTO_ARQUIVO_REQUEST', ListarTipoDocumentoArquivoRequest),
  takeLatest('INSERIR_TIPO_DOCUMENTO_ARQUIVO_REQUEST', InserirTipoDocumentoArquivoRequest),
  takeLatest('EXCLUIR_TIPO_DOCUMENTO_ARQUIVO_REQUEST', ExcluirTipoDocumentoArquivoRequest),
  takeLatest('ALTERAR_TIPO_DOCUMENTO_ARQUIVO_REQUEST', AlterarTipoDocumentoArquivoRequest)
])