import produce from 'immer';

const initialState = {
  listaMedicaoAutorizacaoFornecimentoDocumentoArquivo: undefined,
  carregando: true
};

export default function medicaoAutorizacaoFornecimentoDocumento(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_MEDICAO_AUTORIZACAO_FORNECIMENTO_DOCUMENTO_ARQUIVO_RESPONSE':
      return produce(state, draft => {
        draft.listaMedicaoAutorizacaoFornecimentoDocumentoArquivo = action.listaMedicaoAutorizacaoFornecimentoDocumentoArquivo;
      });
    
    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}