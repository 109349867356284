import { Col, Card, Tabs, Tab } from "react-bootstrap";
import { MdAssignment } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { BuscarAutorizacaoFornecimentoPorIdRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import AutorizacaoFornecimento from "../AutorizacaoFornecimento/autorizacaoFornecimento";
import Subitem from "../SubItem/subitem";
import Status from "../Status/status";
import Medicoes from "../Medicoes/medicoes";
import Header from "../../../components/Header/header";
import HistoricoStatus from "../HistoricoStatus/historicoStatus";
import HistoricoEmpenho from "../HistoricoEmpenho/historicoEmpenho";
import Dashboard from "../Dashboard/dashboard";

export default function Abas() {

    const { idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;
    const autorizacaoFornecimento = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimento);

    useEffect(() => {
        dispatch(BuscarAutorizacaoFornecimentoPorIdRequest(idAutorizacaoFornecimento));
    }, [])

    useEffect(() => {

        if (autorizacaoFornecimento !== null &&
            autorizacaoFornecimento !== undefined &&
            usuario.usuarioFornecedor === true &&
            autorizacaoFornecimento.solicitacaoFornecimentoVersao.solicitacaoFornecimento.idFornecedor !== usuario.fornecedor.idFornecedor) {
            history.push('/');
        }

    }, [autorizacaoFornecimento])

    return (
        <>
            <Header />
            <div className="tamanhoTela">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="divHeader">
                            <span>
                                <MdAssignment size={25} color={"#000"} /> Autorização Fornecimento
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Tabs defaultActiveKey="Autorizacao" id="tab-fonte" className="mb-3">
                                <Tab eventKey="Autorizacao" title="Info">
                                    <AutorizacaoFornecimento />
                                </Tab>
                                <Tab eventKey="Subitem" title="Item" disabled={idAutorizacaoFornecimento ? false : true}>
                                    <Subitem />
                                </Tab>
                                <Tab eventKey="Medicoes" title="Medição" disabled={idAutorizacaoFornecimento ? false : true}>
                                    <Medicoes />
                                </Tab>
                                <Tab eventKey="Status" title="Status" disabled={idAutorizacaoFornecimento ? false : true}>
                                    <Status />
                                </Tab>
                                <Tab eventKey="Dashboard" title="Dashboard" disabled={idAutorizacaoFornecimento ? false : true}>
                                    <Dashboard />
                                </Tab>
                                <Tab eventKey="HistoricoStatus" title="Histórico Status" disabled={idAutorizacaoFornecimento ? false : true}>
                                    <HistoricoStatus />
                                </Tab>
                                <Tab eventKey="HistoricoAutorizacao" title="Histórico Empenho" disabled={idAutorizacaoFornecimento ? false : true}>
                                    <HistoricoEmpenho />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );
}