import produce from 'immer';

const initialState = {
  listaCategoriaItemSubitemCustoPrimario: undefined,
  listaCompletaCategoriaItemSubitemCustoPrimario: undefined,
  carregando: true
};

export default function categoriaItemSubitemCustoPrimario(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_RESPONSE':
      return produce(state, draft => {
        draft.listaCategoriaItemSubitemCustoPrimario = action.listaCategoriaItemSubitemCustoPrimario;
      });

    case 'LISTAR_COMPLETO_CATEGORIA_ITEM_SUBITEM_CUSTO_PRIMARIO_RESPONSE':
      return produce(state, draft => {
        draft.listaCompletaCategoriaItemSubitemCustoPrimario = action.listaCompletaCategoriaItemSubitemCustoPrimario;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });

    default:
      return state;
  }
}