import produce from 'immer';

const initialState = {
  listaTipoSolicitacaoTipoDocumentoArquivo: undefined,
  carregando: true
};

export default function tipoSolicitacaoTipoDocumentoArquivo(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_TIPO_SOLICITACAO_TIPO_DOCUMENTO_ARQUIVO_RESPONSE':
      return produce(state, draft => {
        draft.listaTipoSolicitacaoTipoDocumentoArquivo = action.listaTipoSolicitacaoTipoDocumentoArquivo;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}