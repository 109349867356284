import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { Carregando, ListarRegiaoResponse } from './actions';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarRegiaoRequest() {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    const result = yield call(api.get, `/api/Regiao`, { headers: headerParams.token });

    yield put(ListarRegiaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ListarRegiaoResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* InserirRegiaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/Regiao`, model.data, { headers: headerParams.token });

    toast.success(`A Regiao foi cadastrada com sucesso!`);

    const result = yield call(api.get, `/api/Regiao`, { headers: headerParams.token });

    yield put(ListarRegiaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* AlterarRegiaoRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/Regiao`, model.data, { headers: headerParams.token });

    toast.success(`Regiao foi atualizado com sucesso!`);

    const result = yield call(api.get, `/api/Regiao`, { headers: headerParams.token });

    yield put(ListarRegiaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

function* ExcluirRegiaoRequest(model) {

  try {
    
    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.delete, `/api/Regiao/${model.id}`, { headers: headerParams.token });

    toast.success(`Regiao id: ${model.id} foi excluida!`);

    const result = yield call(api.get, `/api/Regiao`, { headers: headerParams.token });

    yield put(ListarRegiaoResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

   if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }    
    

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_REGIAO_REQUEST', ListarRegiaoRequest),
  takeLatest('INSERIR_REGIAO_REQUEST', InserirRegiaoRequest),
  takeLatest('ALTERAR_REGIAO_REQUEST', AlterarRegiaoRequest),
  takeLatest('EXCLUIR_REGIAO_REQUEST', ExcluirRegiaoRequest)
])