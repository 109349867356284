import produce from 'immer';

const initialState = {
  listaContratoVersaoSubitemCustoPrimario: undefined,
  carregando: true
};

export default function contratoVersaoSubitemCustoPrimario(state = initialState, action) {

  switch (action.type) {

    case 'LISTAR_CONTRATO_VERSAO_SUBITEM_CUSTO_PRIMARIO_RESPONSE':
      return produce(state, draft => {
        draft.listaContratoVersaoSubitemCustoPrimario = action.listaContratoVersaoSubitemCustoPrimario;
      });

    case 'CARREGANDO':
      return produce(state, draft => {
        draft.carregando = action.status
      });
    
    default:
      return state;
  }
}