import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AtualizarToken, ErrorResponse } from '../global/actions';
import { ListarFonteReceitaResponse, BuscarFonteReceitaPorIdResponse, Carregando } from './actions';
import api from '../../../services/api';
import history from '../../../services/history';

function* ListarFonteReceitaRequest() {

  try {

    yield put(Carregando(false));
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FonteReceita`, { headers: headerParams.token });

    yield put(ListarFonteReceitaResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ListarFonteReceitaResponse([]));
    yield put(ErrorResponse(e));
    yield put(Carregando(true));
    
  }
}

function* BuscarFonteReceitaPorIdRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    const result = yield call(api.get, `/api/FonteReceita/${model.id}`, { headers: headerParams.token });

    yield put(BuscarFonteReceitaPorIdResponse(result.data.result));
    yield put(Carregando(true));    

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(BuscarFonteReceitaPorIdResponse(null));
    yield put(Carregando(true));
    
  }
}

function* InserirFonteReceitaRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.post, `/api/FonteReceita`, model.data, { headers: headerParams.token });
    
    const result = yield call(api.get, `/api/FonteReceita`, { headers: headerParams.token });

    yield put(ListarFonteReceitaResponse(result.data.result));

    yield put(Carregando(true));    

    toast.success(`Fonte foi cadastrada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* AlterarFonteReceitaRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);
    
    yield call(api.put, `/api/FonteReceita`, model.data, { headers: headerParams.token });

    const result = yield call(api.get, `/api/FonteReceita`, { headers: headerParams.token });

    yield put(ListarFonteReceitaResponse(result.data.result));

    yield put(Carregando(true));    

    toast.success(`Fonte Receita alterada com sucesso!`);

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
      history.push(`/erro`);
    } 
    else 
      if (e.response.data.errors != null && e.response.data.errors != undefined) {
        let erros = '';
        let apiErrors = e.response.data.errors;
        for (var modelProperty in apiErrors) {
            apiErrors[modelProperty].forEach(function (e) {
                erros = erros + e + ' ';
            });
        }
        toast.error(erros);
      } else {
          toast.error(e.response.data.message);
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));    
  }
}

function* ExcluirFonteReceitaRequest(model) {

  try {

    yield put(Carregando(false));    
    yield put(AtualizarToken());
    var headerParams = yield select((state) => state.global);

    yield call(api.delete, `/api/FonteReceita/${model.id}`, { headers: headerParams.token });
    
    toast.success(`A Fonte Receita foi excluida com sucesso!`);

    const result = yield call(api.get, `/api/FonteReceita`, { headers: headerParams.token });

    yield put(ListarFonteReceitaResponse(result.data.result));
    yield put(Carregando(true));   

  } catch (e) {

    if(e.response == undefined || e.response.data.statusCode == 500){
          history.push(`/erro`);
      } else if (e.response.data.statusCode != 404) {
          toast.error(e.response.data.message);  
      }

    yield put(ErrorResponse(e));
    yield put(Carregando(true));
  }
}

export default all([
  takeLatest('LISTAR_FONTE_RECEITA_REQUEST', ListarFonteReceitaRequest),
  takeLatest('BUSCAR_FONTE_RECEITA_POR_ID_REQUEST', BuscarFonteReceitaPorIdRequest),
  takeLatest('INSERIR_FONTE_RECEITA_REQUEST', InserirFonteReceitaRequest),
  takeLatest('ALTERAR_FONTE_RECEITA_REQUEST', AlterarFonteReceitaRequest),
  takeLatest('EXCLUIR_FONTE_RECEITA_REQUEST', ExcluirFonteReceitaRequest)
])